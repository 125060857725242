import { Box } from "@mui/material"
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import AdjustIcon from '@mui/icons-material/Adjust';

export const QuoteCircuitDragIcon = (props: {
	dragHandleProps : DraggableProvidedDragHandleProps | null | undefined,
	iconColor: "primary" | "success" | "error" | "warning" | "inherit" | "info" | "secondary"
	title?: JSX.Element | string
}) => {
	return (
		<Box {...props.dragHandleProps} display="flex" alignItems={"center"}>
			<AdjustIcon color={props.iconColor}/>
			{props.title}
		</Box>
	)
}