import { useCallback } from "react";
import { useDelete } from "../../stores/core/StoreCore";
import { useApi } from "../../stores/core/UseApi";
import { FolderChecktaskStatusEnum } from "../classes/ChecktaskModel";


const routeController = 'project-dahan/dahan/folder-checktasks';

export const useDeleteFolderChecktask = () => {
    return useDelete(routeController);
}

export const useInsertFolderChecktasks = () => {
    const [callCore, status] = useApi<undefined, { folder_id : number, checktask_ids: number[] }>(
        {
            route:  `${routeController}/insert-tasks`,
            instanceAxiosMethod: "POST"
        }
    );
    const call = useCallback((folder_id: number, checktask_ids: number[]) => {
        return callCore({ folder_id, checktask_ids } );
    }, [callCore]);
    return { call, status };
};

export const useUpdateFolderChecktaskStatus =  () => {
    const [callCore, status] = useApi<undefined, { id : number, status : FolderChecktaskStatusEnum }>(
        {
            route:  `${routeController}/update-status`,
            instanceAxiosMethod: "POST"
        }
    );
    const call = useCallback((id: number, status: FolderChecktaskStatusEnum) => {
        return callCore({ id, status } );
    }, [callCore]);
    return { call, status };
};