import { Container, Modal } from "@mui/material";
import { FormProvider } from "react-recoil-form";
import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { QuoteTravelValidateModalState } from "../../states/QuoteState";
import QuoteTravelValidate from "./QuoteTravelValidate";

const QuoteTravelValidateModal = (props: { disabled?: boolean }) => {

    const [modalState, setModalState] = useRecoilState(QuoteTravelValidateModalState);

    const handleClose = useCallback(() => {
        setModalState((a) => {
            return { ...a, open: false };
        });
    }, [setModalState]);


    const handleValidate = useCallback(() => {
        modalState.onValidate();
        setModalState((a) => {
            return { ...a, open: false };
        });
    }, [modalState, setModalState]);


    return (
        <Modal
            open={modalState.open}
            onClose={handleClose}
            aria-labelledby="quote-modal"
        >
            <Container component="main" maxWidth="xl" sx={{ height: "100%", py: 2 }}>
                <FormProvider options={{ formId: "quoteTravelForm", skipRecoilRoot: true }}>
                    {modalState.quoteId &&
                        <QuoteTravelValidate quoteId={modalState.quoteId} onValidate={handleValidate} onClose={handleClose} />
                    }
                </FormProvider>
            </Container>
        </Modal>
    );
}

export default QuoteTravelValidateModal;