import { Box, Paper, Tab, Tabs } from "@mui/material"
import { IsNotUserCommercialSelector, UserExtenalSelector } from "../../states/UserState";
import { useRecoilValue } from "recoil";
import { useEffect } from "react";
import { FolderTabEnum } from "../../classes/FolderModel";

interface FolderTabsProps {
    tabId: number,
    marginIsVisible: boolean,
    onClickEvent: (id: number) => void
}
const FolderTabs = (props: FolderTabsProps) => {

    const isNotUserCommercial = useRecoilValue(IsNotUserCommercialSelector);

    const { tabId, onClickEvent, marginIsVisible } = props;

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        onClickEvent(newValue);
    };

    return (
        <Box>
            <Paper>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs variant="scrollable" value={tabId} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Notes" value={FolderTabEnum.NOTES} />
                        <Tab label="Devis" value={FolderTabEnum.QUOTES} />
                        <Tab label="Proforma" value={FolderTabEnum.PROFORMA} />
                        <Tab label="Réglements" value={FolderTabEnum.PAYMENTS} />
                        {isNotUserCommercial &&
                            <Tab label="Infos voyages" value={FolderTabEnum.TRAVEL_INFO} />
                        }
                        <Tab label="Factures" value={FolderTabEnum.INVOICES} />
                        {marginIsVisible &&
                            <Tab label="Marges" value={FolderTabEnum.MARGINS} />
                        }
                    </Tabs>
                </Box>
            </Paper>
        </Box>
    )
}

export default FolderTabs;