import { useCallback } from "react";
import { useApi, GenericResponse, ReqStatus } from "./core/UseApi";
import { UserListModel } from "../classes/users/UserListModel";
import { UserModel } from "../classes/users/UserModel";
import { ProfileModel } from "../classes/users/ProfileModel";
import { useGetListCore } from "./core/StoreCore";

export const useGetUsersList = () => {
    return useGetListCore<UserListModel>('users/index');
};

interface GetUserResponse{
    user : UserModel,
    userGroups: {[groupId: number]: string },
    viewMode: string
}
export const useGetUser = function (): [(id?: number) => Promise<GenericResponse<GetUserResponse>>, ReqStatus] {
    const [callCore, status] = useApi<GetUserResponse, {id: number}>(
        {
            route: 'users/add-edit',
            instanceAxiosMethod: "GET"
        }
    );
    const call = useCallback((id? : number) => {
        return callCore( undefined, `/${id ?? ""}` );
    }, [callCore]);
    return [call, status];
};

export const useAddEditUser = function (): [(data: UserModel) => Promise<GenericResponse<GetUserResponse>>, ReqStatus] {
    const [callCore, status] = useApi<GetUserResponse, UserModel>(
        {
            route: 'users/add-edit',
            instanceAxiosMethod: "POST"
        }
    );
    const call = useCallback((data: UserModel) => {
        return callCore(data, data.id ? `/${data.id}` : '');
    }, [callCore]);
    return [call, status];
};

export const useGetProfile = function (): [() => Promise<GenericResponse<{ user : ProfileModel}>>, ReqStatus] {
    const [callCore, status] = useApi<{ user: ProfileModel }, undefined>(
        {
            route: 'users/profile',
            instanceAxiosMethod: "GET"
        }
    );
    const call = useCallback(() => {
        return callCore( undefined);
    }, [callCore]);
    return [call, status];
};

export const useEditProfile = function (): [(data: ProfileModel) => Promise<GenericResponse<{ user: ProfileModel }>>, ReqStatus] {
    const [callCore, status] = useApi<{ user: ProfileModel }, ProfileModel>(
        {
            route: 'users/profile',
            instanceAxiosMethod: "POST"
        }
    );
    const call = useCallback((data: ProfileModel) => {
        return callCore(data);
    }, [callCore]);
    return [call, status];
};

export const useDeleteUser = function (): [(id: number) => Promise<GenericResponse<{}>>, ReqStatus] {
    const [callCore, status] = useApi<{},  {id: number}>(
        {
            route: 'users/delete',
            instanceAxiosMethod: "POST"
        }
    );
    const call = useCallback((id: number) => {
        return callCore( undefined, `/${id}` );
    }, [callCore]);
    return [call, status];
};

export const useGetPasswordUser = function (): [(id: number) => Promise<GenericResponse<{user: UserModel}>>, ReqStatus] {
    const [callCore, status] = useApi<{user: UserModel}, undefined>(
        {
            route: 'users/edit-password',
            instanceAxiosMethod: "GET"
        }
    );
    const call = useCallback((id: number ) => {
        return callCore(undefined, `/${id}` );
    }, [callCore]);
    return [call, status];
};

export const useEditPasswordUser = function (): [(id:number, user: UserModel) => Promise<GenericResponse<{user: UserModel}>>, ReqStatus] {
    const [callCore, status] = useApi<{user: UserModel}, UserModel>(
        {
            route: 'users/edit-password',
            instanceAxiosMethod: "POST"
        }
    );
    const call = useCallback((id:number, user: UserModel ) => {
        return callCore(user, `/${id}` );
    }, [callCore]);
    return [call, status];
};