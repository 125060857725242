import { Box, Button, Card, IconButton, Typography } from '@mui/material';
import React, { useState, useEffect, useMemo, useCallback } from 'react';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import { useAddTimeTracker, useGetCurrentTimeTracker } from '../../stores/UserTimetrackersStore';
import { ReqStatus } from '../../../stores/core/UseApi';

const UserTimetracker: React.FC = () => {

  const getCurrentTimeTracker = useGetCurrentTimeTracker();
  const addTimetracker = useAddTimeTracker();

  const [seconds, setSeconds] = useState(0);
  const [ready, setReady] = useState(false);
  const [start, setStart] = useState<Date | null>(null);
  const isTracking = useMemo(() => {
    return !!start;
  }, [start]);

  const getCurrentAjax = useCallback(() => {
    getCurrentTimeTracker.call().then((res) => {
      if (res.status === ReqStatus.SUCCESS && res.data) {
        if (res.data.userTimetracker) {
          setStart(new Date(res.data.userTimetracker.start));
        } else {
          setStart(null);
        }
        setReady(true);
      }
    });
  }, [])

  useEffect(() => {
    getCurrentAjax();
  }, [])

  const handleStartStop = useCallback(() => {
    addTimetracker.call().then((res) => {
      if (res.status === ReqStatus.SUCCESS) {
        getCurrentAjax();
      }
    })
  }, [start]);

  useEffect(() => {
    if (!start) {
      setSeconds(0);
      return;
    }

    const updateSeconds = () => {
      const startTime = start.getTime();
      const currentTime = Date.now();
      const elapsedMilliseconds = currentTime - startTime;
      const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);
      setSeconds(elapsedSeconds);
    };

    // Appelez updateSeconds immédiatement pour calculer les secondes actuelles
    updateSeconds();

    // Définissez un intervalle pour mettre à jour les secondes chaque minute
    const intervalId = setInterval(updateSeconds, 1000); // 60 000 millisecondes = 1 minute
    // Nettoyez l'intervalle lorsque le composant est démonté
    return () => {
      clearInterval(intervalId);
    };
  }, [start]);


  const formatTime = useCallback((seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}`;
  }, []);

  return (
    <Box mx={2}>
      {ready &&
        <Card>
          <Box mr={1} p={1} display="flex" alignItems={"center"}>
            <IconButton onClick={handleStartStop} size='small' color="primary">
              {isTracking ? <StopIcon fontSize='small' /> : <PlayArrowIcon fontSize='small' />}
            </IconButton>
            <Box ml={1}><Typography variant="h6">{formatTime(seconds)}</Typography></Box>
          </Box>
        </Card>
      }
    </Box>
  );
};

export default UserTimetracker;
