import { FolderOrderSupplierContactModel } from "./FolderOrderSupplierContactModel";
import { FolderOrderSupplierPaymentModel } from "./FolderOrderSupplierPaymentModel";

export interface FolderOrderSupplierModel {
	id: number,
	folder_id: number,
	order_id: number,
	supplier_id: number,
	amount: number | null,
	status: EnumFolderOrderSupplierStatus,
	email_send: boolean;
	comment: string;
	reference: string;
	folder_order_supplier_contacts: FolderOrderSupplierContactModel[],
	folder_order_supplier_payments: FolderOrderSupplierPaymentModel[],
	order: any,
	supplier: any,
}

export enum EnumFolderOrderSupplierStatus {
	INIT = 0,
	VALIDATED = 1,
	CONFIRMED = 2,
	REFUSED = 3,
	CANCELED = 4,
}

export function getLabelOfFolderOrderSupplierStatus(status: number | EnumFolderOrderSupplierStatus) {
	let label = '';
	switch (status) {
		case EnumFolderOrderSupplierStatus.REFUSED:
			label = 'Refusé';
			break;
		case EnumFolderOrderSupplierStatus.CANCELED:
			label = 'Annulé';
			break;
		case EnumFolderOrderSupplierStatus.VALIDATED:
			label = 'Validé';
			break;
		case EnumFolderOrderSupplierStatus.CONFIRMED:
			label = 'BPA';
			break;
		case EnumFolderOrderSupplierStatus.INIT:
		default:
			label = '';
			break;
	}
	return label;
}

export function getColorOfFolderOrderSupplierStatus(status: number | EnumFolderOrderSupplierStatus) {
	if (status == EnumFolderOrderSupplierStatus.VALIDATED) { return { backgroundColor: "lightgreen" } };
	if (status == EnumFolderOrderSupplierStatus.CONFIRMED) { return { backgroundColor: "lightblue" } };
	if (status == EnumFolderOrderSupplierStatus.REFUSED) { return { backgroundColor: "lightgray" } };
	if (status == EnumFolderOrderSupplierStatus.CANCELED) { return { backgroundColor: "lightpink" } };
	return undefined;
}
