import { useField } from "react-recoil-form";
import { useGetAddressesOfCustomer, useGetCountryVat } from "../../stores/OrdersStore";
import { useEffect, useState } from "react";
import SelectField, { SelectOptionsProps } from "../common/form/SelectField";
import { ReqStatus } from "../../stores/core/UseApi";
import { createOptionsForSelect } from "../../utils/DataUtils";
import { Grid } from "@mui/material";

const OrderAddressesView = (props: { disabled?: boolean, required?: boolean, customer?: boolean}) => {

    const { disabled, required, customer } = props;
    const customerIdField = useField({ name: "customer_id" });
    const billindAddressIdField = useField({ name: "billing_address_id" });
    const shippingVatField = useField({ name: "shipping_vat" });
    const getAddresses = useGetAddressesOfCustomer({customer : customer ? customer : false});
    const [addresses, setAddresses] = useState<SelectOptionsProps[]>([]);

    const getCountryVat = useGetCountryVat({customUrl : customer ? "project-verger/verger/customer-orders/get-country-vat" : undefined});

    useEffect(() => {
        if (!customerIdField.fieldValue) {
            setAddresses([]);
            return;
        }

        getAddresses(customerIdField.fieldValue).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                setAddresses(createOptionsForSelect(res.data.addresses));
            }
        })
    }, [customerIdField.fieldValue]);

    useEffect(() => {
        if (!billindAddressIdField.fieldValue) {
            return;
        }

        const billindAddress = addresses.find(x => x.value == billindAddressIdField.fieldValue);
        if(!billindAddress){return;}

        const contryCode = billindAddress.label.slice(-2);

        getCountryVat(contryCode).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                shippingVatField.setFieldValue(res.data.vat);
            }
        })
    }, [billindAddressIdField.fieldValue]);

    return (<>

        <Grid item xs={12} lg={6}>
            <SelectField
                options={addresses}
                label={"Adresse de facturation"}
                name={`billing_address_id`}
                required={required !== undefined ? required : true}
                noChoice={required === false}
                noChoiceLabel={required === false ? "Aucune" : undefined}
                disabled={disabled}
                defaultIndex={customer ? 0 : undefined}
            />
        </Grid>
        <Grid item xs={12} lg={6}>
            <SelectField
                options={addresses}
                label={"Adresse de livraison"}
                name={`shipping_address_id`}
                required={required !== undefined ? required : true}
                noChoice={required === false}
                noChoiceLabel={required === false ? "Aucune" : undefined}
                disabled={disabled}
                defaultIndex={customer ? 0 : undefined}
            />
        </Grid>
    </>)
};

export default OrderAddressesView;
