import { useCallback, useMemo } from 'react';
import { Box } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useGetPaymentList } from "../../stores/FoldersStore";
import { useEffect, useState } from "react";
import { ReqStatus } from "../../../stores/core/UseApi";
import ListItemActions from "../../../components/common/list/ListItemActions";
import { datetimeFormat } from '../../../utils/DateUtils';
import FormattedAmount from '../../../components/common/formatted/FormattedAmount';

const FolderPaymentList = (props: { folderId: string }) => {
    const [forceRefresh, setForceRefresh] = useState(0);
    const { folderId } = props;
    const get = useGetPaymentList();
    const [rows, setRows] = useState<any[]>([]);

    const getDatas = useCallback(() => {
        if (isNaN(+folderId)) { return; }
        get(folderId).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                setRows(res.data.payments ?? [])
            }
        })
    }, [folderId]);

    const columns: GridColDef[] = useMemo(() => {
        return [
            {
                field: 'id',
                headerName: "N°",
                editable: false,
                filterable: false,
                minWidth: 100,
                valueGetter: ({row}) => `#${row.id}`
            },
            {
                field: 'order_id',
                headerName: "Devis N°",
                editable: false,
                filterable: false,
                minWidth: 100,
                valueGetter: ({row}) => `#${row.order_id}`
            },            {
                field: 'dahan_payment_date',
                headerName: "Date paiement",
                editable: false,
                filterable: false,
                width: 120,
                valueGetter: ({row}) => datetimeFormat(row.dahan_payment_date)
            },
            {
                field: 'payment_method.label',
                headerName: "Moyen de paiement",
                editable: false,
                filterable: false,
                width: 150,
                valueGetter: ({ row }) => row.payment_method.label
            },
            {
                field: 'reference',
                headerName: "Référence",
                editable: false,
                filterable: false,
                minWidth: 150,
            },
            {
                field: 'comment',
                headerName: "Commentaire",
                editable: false,
                filterable: false,
                minWidth: 250,
            },
            {
                field: 'total_amount',
                headerName: "Montant total",
                editable: false,
                filterable: false,
                minWidth: 100,
                renderCell: ({ row }) => <Box display={"flex"} justifyContent={"flex-end"} width={"100%"}><FormattedAmount amount={row.total_amount} symbol="€"/></Box>
            },
            {
                field: 'dahan_deposit_id',
                headerName: "Acompte lié",
                editable: false,
                filterable: false,
                minWidth: 100,
                valueGetter: ({row}) => row.dahan_deposit_id ? `#${row.dahan_deposit_id}` : ''
            },
        
            /*{
                field: 'actions',
                headerName: "Actions",
                editable: false,
                filterable: false,
                flex:1,
                renderCell: ({ row }) => <ListItemActions
                    defaultRoute={`commercial/payments`}
                    edit={{
                        enable: false,
                        icon: true,
                    }}
                    remove={{
                        enable: false,
                        route: "commercial/payments",
                        callback: () => { getDatas() },
                        icon: true,
                    }
                    }
                    row={row}
                    id={row.id}
                />
            }*/
        ]
    }, []); // [getDatas]);


    useEffect(() => {
        getDatas();
    }, [getDatas]);

    return (
        <Box height={371}>
            <DataGrid
                key={forceRefresh}
                disableColumnFilter
                disableRowSelectionOnClick
                //autoPageSize
                filterMode="client"
                rows={rows}
                columns={columns}
                getRowId={(row: any) => row.id}
                hideFooter
            />
        </Box >
    )
};
export default FolderPaymentList;