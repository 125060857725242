import { useCallback } from "react";
import { useApi, GenericResponse, ReqStatus } from "./core/UseApi";
import { UserGroupModel } from "../classes/user-groups/UserGroupModel";
import { GroupAuthorizationEntities } from "../classes/group-authorizations/GroupAuthorizationModel";
import { GroupAuthorizationEntityActionsRequest, GroupAuthorizationEntityRequest, GroupAuthorizationRequest } from "../classes/group-authorizations/GroupAuthorizationRequest";

interface GetGroupAuthorizationResponse{
    userGroup: UserGroupModel,
    entities: GroupAuthorizationEntities,
    mode: string
}
export const useGetGroupAuthorization = function (): [(id: number) => Promise<GenericResponse<GetGroupAuthorizationResponse>>, ReqStatus] {
    const [callCore, status] = useApi<GetGroupAuthorizationResponse, {id: number}>(
        {
            route: 'group-authorizations/view',
            instanceAxiosMethod: "POST"
        }
    );
    const call = useCallback((id: number) => {
        return callCore( undefined, `/${id}` );
    }, [callCore]);
    return [call, status];
};

export const useAddEditGroupAuthorization = function (): [(data: GroupAuthorizationRequest) => Promise<GenericResponse<{}>>, ReqStatus] {
    const [callCore, status] = useApi<{}, GroupAuthorizationEntityRequest | GroupAuthorizationEntityActionsRequest>(
        {
            route: 'group-authorizations/add-edit',
            instanceAxiosMethod: "POST"
        }
    );
    const call = useCallback((data: GroupAuthorizationRequest) => {
        return callCore(data.entities, `/${data.id}`);
    }, [callCore]);
    return [call, status];
};