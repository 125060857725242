import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import InputField from "../common/form/InputField";
import NumberField from "../common/form/NumberField";
import AddEditFormCard from "../common/form/AddEditFormCard";
import { useFormValues } from "react-recoil-form";

interface OrderTotalViewProps {
    title?: string,
    discount: boolean,
    shipping: boolean
}
const OrderTotalInfos = (props: OrderTotalViewProps) => {
    const { title, discount, shipping } = props;
    const values = useFormValues();
    return (
        <AddEditFormCard title={title ?? "Total"} withExpand>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell >HT</TableCell>
                        <TableCell >TVA</TableCell>
                        <TableCell >TTC</TableCell>
                        <TableCell >{(discount || shipping) && "%"}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(discount || shipping) &&
                        <TableRow>
                            <TableCell>Produits</TableCell>
                            <TableCell>
                                {values.total_tax_excl}
                            </TableCell>
                            <TableCell>
                                {values.total_tax}
                            </TableCell>
                            <TableCell>
                                {values.total_tax_incl}
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    }
                    {discount &&
                        <>
                            <TableRow>
                                <TableCell>Remise</TableCell>
                                <TableCell>
                                    {values.discount_tax_excl}

                                </TableCell>
                                <TableCell>
                                    {values.discount_tax}
                                </TableCell>
                                <TableCell>
                                    {values.discount_tax_incl}

                                </TableCell>
                                <TableCell>
                                    {values.discount_percent} %
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Total avec remise</TableCell>
                                <TableCell>
                                    {values.total_minus_discount_tax_excl}
                                </TableCell>
                                <TableCell>
                                    {values.total_minus_discount_tax}
                                </TableCell>
                                <TableCell>
                                    {values.total_minus_discount_tax_incl}
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </>
                    }
                    {shipping &&
                        <TableRow>
                            <TableCell>Livraison</TableCell>

                            <TableCell>
                                {values.shipping_tax_excl}
                            </TableCell>
                            <TableCell>
                                {values.shipping_tax}

                            </TableCell>
                            <TableCell>

                                {values.shipping_tax_incl}

                            </TableCell>
                            <TableCell>

                                TVA : {values.shipping_vat} %

                            </TableCell>
                        </TableRow>
                    }
                    <TableRow>
                        <TableCell>Total</TableCell>
                        <TableCell>
                            {values.total_tax_excl_plus_shipping_tax_excl}

                        </TableCell>
                        <TableCell>

                            {values.total_tax_plus_shipping_tax}

                        </TableCell>
                        <TableCell>

                            {values.total_tax_incl_plus_shipping_tax_incl}

                        </TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableBody>
            </Table>

        </AddEditFormCard>
    );
}

export default OrderTotalInfos;