import { Grid, Paper } from "@mui/material";
import { getLabelOfFolderOrigin, getlabelOfFolderPax, getlabelOfFolderTypeJourney } from "../../classes/FolderModel";
import { dateFormat } from "../../../utils/DateUtils";

const FolderDetails = (props: { folder: any, commercialName: string }) => {
    const { folder, commercialName } = props;
    return (<Paper>
        <Grid container p={1}>
            <Grid item xs={6}>Dossier</Grid> <Grid item xs={6}>#{folder.id}</Grid>
            <Grid item xs={6}>Origine</Grid> <Grid item xs={6}>{getLabelOfFolderOrigin(folder.origin)} {folder.webhook_origin}</Grid>
            <Grid item xs={6}>Type trajet</Grid> <Grid item xs={6}>{getlabelOfFolderTypeJourney(folder.type_journey)}</Grid>
            <Grid item xs={6}>Date de départ</Grid> <Grid item xs={6}>{dateFormat(folder.departure_date)}</Grid>
            <Grid item xs={6}>Lieu de départ</Grid> <Grid item xs={6}>{folder.departure_place}</Grid>
            <Grid item xs={6}>Lieu d'arrivée</Grid> <Grid item xs={6}>{folder.return_place}</Grid>
            <Grid item xs={6}>Date de retour</Grid> <Grid item xs={6}>{dateFormat(folder.return_date)}</Grid> 
            <Grid item xs={6}>PAX</Grid> <Grid item xs={6}>{getlabelOfFolderPax(folder.pax)}</Grid>  
            <Grid item xs={6}>Suivi par</Grid> <Grid item xs={6}>
                {folder.commercial ? `${folder.commercial?.first_name} ${folder.commercial?.last_name }` : 'Non défini'}
            </Grid>          
        </Grid>
    </Paper>);
}

export default FolderDetails;