import { Box, Button, Grid, IconButton, Typography, Divider } from "@mui/material";
import { PropsWithChildren, useCallback, useEffect, useMemo } from "react";
import { IAncestorInput, useFieldArray, useFieldArrayColumnWatch } from "react-recoil-form";
import AutocompleteField, { AutocompleteOption } from "./AutocompleteField";
import InputField from "./InputField";
import DeleteIcon from "@mui/icons-material/Delete";
import NumberField from "./NumberField";
import { ConfirmModalState } from "../../../states/ModalState";
import { useSetRecoilState } from "recoil";
import SelectField, { SelectOptionsProps } from "./SelectField";


interface AddEditFormListProps {
    fields: AddEditFormListField[],
    hiddenFields: {
        add: string[],
        edit: string[]
    },
    entity: string,
    newEntity?: string,
    title: string,
    defaultItem: any,
    disabled?: boolean,
    className?: string,
    spacingGrid?: number,
    buttonAdd?: {
        title: string
    },
    xsDelete?: number,
}



interface AddEditFormListField {
    name: string,
    xs: number,
    type: "text" | "number" | "autocomplete" | "select" | "custom",
    customElement?: (ancestors: IAncestorInput[]) => JSX.Element,
    label?: string,
    autocompleteOptions?: AutocompleteOption[],
    selectOptions?:  SelectOptionsProps[],
    required?: boolean,
    disabled?: boolean,
}

interface AddEditFormListItemProps {
    fields: (AddEditFormListField)[],
    hiddenFields?: string[],
    prefixeName: string,
    deleteEvent: () => void,
    id: number,
    disabled?: boolean,
    className?: string,
    xsDelete?: number,
}

const AddEditFormListItem = (props: AddEditFormListItemProps) => {
    const { fields, hiddenFields, prefixeName, deleteEvent, id, disabled, className, xsDelete } = props;

    const setConfirmModalState = useSetRecoilState(ConfirmModalState);

    const deleteConfirmEvent = useCallback(() =>
        setConfirmModalState({
            open: true,
            title: "Suppression",
            desc: "Êtes-vous sûr de vouloir supprimer cette ligne ?",
            validEvent: () => {
                deleteEvent();
            }
        }), [deleteEvent, setConfirmModalState]);


        const formFields = (
            <>
                {hiddenFields?.map((hiddenField, hiddenFieldKey) => (
                    <InputField key={hiddenFieldKey} ancestors={[{ name: prefixeName, rowId: id }]} name={`${prefixeName}.${hiddenField}`} type="hidden" />
                ))}
                {fields.map((field, fieldKey) => (
                    <Grid key={`${id}_${field.name}`} item xs={field.type ==="autocomplete" ? 12 : 6} sm={field.type ==="autocomplete" ? 12 : 6} md={field.type ==="autocomplete" ? 12 : 6} lg={field.xs}>

                        {field.type === "custom" && field.customElement && field.customElement([{ name: prefixeName, rowId: id }])}

                        {field.type === "autocomplete" &&
                            <AutocompleteField
                                ancestors={[{ name: prefixeName, rowId: id }]}
                                options={field.autocompleteOptions ?? []}
                                name={field.name}
                                required={field.required}
                                label={field.label}
                                disabled={field.disabled || disabled}
                            />
                        }

                        {field.type === "number" &&
                            <NumberField
                                ancestors={[{ name: prefixeName, rowId: id }]}
                                name={`${field.name}`}
                                required={field.required}
                                label={field.label}
                                disabled={field.disabled || disabled}
                            />
                        }

                        {(field.type === "text") &&
                            <InputField
                                ancestors={[{ name: prefixeName, rowId: id }]}
                                name={`${field.name}`}
                                required={field.required}
                                label={field.label}
                                disabled={field.disabled || disabled}
                                type={"text"}
                            />
                        }

                        {field.type === "select" &&
                            <SelectField
                                ancestors={[{ name: prefixeName, rowId: id }]}
                                options={field.selectOptions ?? []}
                                name={field.name}
                                required={field.required}
                                label={field.label}
                                disabled={field.disabled || disabled}
                            />
                        }
                    </Grid>
                ))}
                <Grid item xs={xsDelete ? xsDelete : 1}>
                    {!disabled &&
                        <IconButton color="error" onClick={() => deleteConfirmEvent()}><DeleteIcon /></IconButton>
                    }
                </Grid>
            </>
        );

    return (
        <>
            {className ? (
                <Box className={className}>
                    {formFields}
                </Box>
            ) : (
                <>
                    {formFields}
                </>
            )}
        </>
    )
}

const AddEditFormList = (props: AddEditFormListProps) => {

    const { entity, newEntity, fields, hiddenFields, title, buttonAdd, defaultItem, className, spacingGrid, disabled, xsDelete } = props;

    const fieldNames = useMemo(() => {
        return fields.map((field) => field.name);
    }, [fields]);

    const _newEntity = useMemo(() => {
        if(newEntity){ return newEntity; }
        const words = entity.split('_');
        const transformedWords = words.map((word) => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        });
        return 'new' + transformedWords.join('');
    }, [entity, newEntity])

    const entityListField = useFieldArray({ name: entity, fieldNames: [...fieldNames, ...hiddenFields.edit], });
    const newEntityListField = useFieldArray({ name: _newEntity, fieldNames: [...fieldNames, ...hiddenFields.add] });

    const spacingGrid2 = spacingGrid !== undefined ? spacingGrid : 2;


    return (
        <Box display={"flex"} flexDirection={"column"}>
            <Grid container spacing={spacingGrid2} display={"flex"} alignItems={"center"}>
                <>
                    {entityListField.fieldArrayProps.rowIds.map((rowId, rowIndex) => (
                        <AddEditFormListItem
                            key={rowId}
                            id={rowId}
                            prefixeName={`${entity}`}
                            fields={fields}
                            hiddenFields={hiddenFields.edit}
                            deleteEvent={() => entityListField.remove(rowIndex)}
                            disabled={disabled}
                            className={className}
                            xsDelete={xsDelete}
                        />
                    ))}
                    {!disabled && newEntityListField.fieldArrayProps.rowIds.map((rowId, rowIndex) => (
                        <AddEditFormListItem
                            key={rowId}
                            id={rowId}
                            prefixeName={`${_newEntity}`}
                            fields={fields}
                            hiddenFields={hiddenFields.add}
                            deleteEvent={() => newEntityListField.remove(rowIndex)}
                            className={className}
                            xsDelete={xsDelete}
                        />
                    ))}
                </>
            </Grid>
            {!disabled && buttonAdd &&
                <Box mt={2}>
                    <Button variant="contained" color="primary" onClick={() => newEntityListField.append(defaultItem)}>{buttonAdd.title}</Button>
                </Box>
            }
        </Box>
    )

}

export default AddEditFormList;
