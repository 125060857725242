
import { useCallback } from "react";
import { ListModelCore } from "../../classes/list/ListModel";
import { useGetListCore } from "../../stores/core/StoreCore";
import { useApi } from "../../stores/core/UseApi";
import { BillModel } from "../../classes/bills/BillModel";

interface BillListModel extends ListModelCore{
    bills: any[]
}
export const useGetBillList = () => {
    return useGetListCore<BillListModel>('commercial/bills/index');
};

export const useGetBill = (type: 'bill' | 'credit-note' | 'deposit' | 'deposit' | 'deposit-credit-note' ) =>{
    const [get, getStatus] = useApi<any, undefined>(
        {
            route: `commercial/bills/add`,
            instanceAxiosMethod: "GET"
        }
    );

    const getData = useCallback((id: string) => {
        return get( undefined, `/${id}${(type === 'bill' ? '' : `/${type}`)}` );
    }, [get]);

    return getData;
}

export const useAddBill = (type: 'bill' | 'credit-note' | 'deposit' | 'deposit' | 'deposit-credit-note') =>{
    const [get, getStatus] = useApi<any, BillModel>(
        {
            route: "commercial/bills/add",
            instanceAxiosMethod: "POST"
        }
    );

    const getData = useCallback((bill: BillModel, id: string) => {
        return get( bill, `/${id}${(type === 'bill' ? '' : `/${type}`)}` );
    }, [get]);

    return getData;
}