import { GridColDef } from "@mui/x-data-grid";
import { Box, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetListCore } from "../../stores/core/StoreCore";
import ListCore from "../common/list/ListCore";
import { PaymentMethodListModel, PaymentMethodModel } from "../../classes/payment-methods/PaymentMethodModel";
import { useEffect } from "react";

const columns: GridColDef[] = [
    {
        field: 'label',
        headerName: "Nom",
        minWidth: 100,
        flex: 1,
    }
];

const PaymentMethodList = () => {

    const routeController = "commercial/payment-methods";
    const [get] = useGetListCore<PaymentMethodListModel>(`${routeController}/index`);
   
    return (
        <ListCore<PaymentMethodListModel, PaymentMethodModel>
            getDatas={get}
            defineDatasToSet={(data) => data.paymentMethods}
            getRowId={(row) => row.id}
            columns={columns}
            actions={{
                defaultRoute: routeController,
                add: {
                    title: "Ajouter un moyen de paiement"
                },
                edit: true,
                remove: true
            }}
        />
    );
}

export default PaymentMethodList;

