import { useField } from "react-recoil-form";

import { useEffect, useState } from "react";

import { Grid } from "@mui/material";
import SelectField, { SelectOptionsProps } from "../../../components/common/form/SelectField";
import { ReqStatus } from "../../../stores/core/UseApi";
import { createOptionsForSelect } from "../../../utils/DataUtils";
import { useGetAddressesOfCustomer, useGetCountryVat } from "../../../stores/OrdersStore";

const CustomOrderAddressesView = (props: { disabled?: boolean, required?: boolean}) => {

    const { disabled, required } = props;
    const customerIdField = useField({ name: "customer_id" });
    const billindAddressIdField = useField({ name: "billing_address_id" });
    const shippingVatField = useField({ name: "shipping_vat" });
    const getAddresses = useGetAddressesOfCustomer();
    const [addresses, setAddresses] = useState<SelectOptionsProps[]>([]);

    const getCountryVat = useGetCountryVat();

    useEffect(() => {
        if (!customerIdField.fieldValue) {
            setAddresses([]);
            return;
        }

        getAddresses(customerIdField.fieldValue).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                setAddresses(createOptionsForSelect(res.data.addresses));
            }
        })
    }, [customerIdField.fieldValue]);

    useEffect(() => {
        if (!billindAddressIdField.fieldValue) {
            return;
        }

        const billindAddress = addresses.find(x => x.value == billindAddressIdField.fieldValue);
        if(!billindAddress){return;}

        const contryCode = billindAddress.label.slice(-2);

        getCountryVat(contryCode).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                shippingVatField.setFieldValue(res.data.vat);
            }
        })
    }, [billindAddressIdField.fieldValue]);

    return (<>

        <Grid item xs={12} lg={6}>
            <SelectField
                options={addresses}
                label={"Adresse de facturation"}
                name={`billing_address_id`}
                required={required !== undefined ? required : true}
                noChoice={required === false}
                noChoiceLabel={required === false ? "Aucune" : undefined}
                disabled={disabled}
            />
        </Grid>
    </>)
};

export default CustomOrderAddressesView;