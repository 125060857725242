import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import { Tooltip } from '@mui/material';
import { StringToColor } from '../../../utils/StringUtils';

function stringAvatar(name: string) {
  const name_splitted = name.split(' ');
  const name_value = (name_splitted.length > 1 ? `${name_splitted[0][0]} ${name_splitted[1][0]}` : name_splitted[0][0] );
  return {
    sx: {
      bgcolor: StringToColor(name),
    },
    children: name_value,
  };
}

const AvatarIcon = (props : { value: string }) => {
    const { value } = props;

    const valueUppered = React.useMemo(() =>{
      return (typeof value === "string") ? value.replace(/\s+/g, ' ').trim().toUpperCase() : "";
    },[value])

    return <Tooltip title={value}><Avatar {...stringAvatar(valueUppered)} /></Tooltip>
}

export default AvatarIcon;