import { Box } from "@mui/material"

const TooltipComment = (props: { value: string, fontSize?: number }) => {
	return (
		<Box fontSize={props.fontSize ?? 14}>
			{props.value && typeof(props.value) === "string" && props.value.split('\n').map((x: any) => <Box>{x}</Box> ?? "")}
		</Box>
	)
}

export default TooltipComment;

