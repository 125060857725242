import { useNavigate, useParams } from "react-router-dom";
import { useAddEditProductType, useGetProductType } from "../../stores/ProductTypesStore";
import AddEditFormPanel, { useAddEditForm } from "../common/form/AddEditFormPanel";
import AddEditFormSubmit from "../common/form/AddEditFormSubmit";
import { ReqStatus } from "../../stores/core/UseApi";
import { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import InputField from "../common/form/InputField";
import { useAddEditCore } from "../../stores/core/StoreCore";
import AddEditFormList from "../common/form/AddEditFormList";
import AddEditForm from "../common/form/AddEditForm";
import AddEditFormCard from "../common/form/AddEditFormCard";

const StatusTypeView = () => {
    const listRoute = "/commercial/status-types";
    const { getData, addEditData } = useAddEditCore<any, { statusType: any }>(`${listRoute}/add-edit`);

    let { id } = useParams();
    const navigate = useNavigate();

    const onSubmit = (request: any) => {
        let _request = JSON.parse(JSON.stringify(request)) as any;
        if (!_request.status) {
            delete _request.status;
        }
        if (!_request.newStatus) {
            delete _request.newStatus;
        }
        addEditData(_request, id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                navigate(listRoute);
            }
        })
    }

    const { handleSubmit, resetInitialValues, isDirty } = useAddEditForm({ onSubmit, initialValues: { label: "", unique_key: "", addElements: "1" } });
    useEffect(() => {
        getData(id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                if (id) {
                    const _data = res.data.statusType;
                    resetInitialValues({
                        label: _data.label,
                        unique_key: _data.unique_key,
                        status: _data.status?.map((p: any) => {
                            return {
                                id: p.id,
                                label: p.label,
                                unique_key: p.unique_key,
                                sort_order: p.sort_order
                            }
                        }),
                        addElements: "1"
                    });
                }
            } else {
                navigate(listRoute);
            }
        })
    }, [id]);

    return (

        <>

            <AddEditForm formId="statusTypeForm" formHandleCancel={resetInitialValues} formHandleSubmit={handleSubmit} isDirty={isDirty}>
                <AddEditFormCard title={(!id ? "Ajouter " : "Modifier") + " un status"}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <InputField name="label" label="Label" type="text" required />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputField name="unique_key" label="Clé type de statut" type="text" required disabled />
                        </Grid>
                    </Grid>
                </AddEditFormCard>
                <AddEditFormCard title={"Status"} withExpand>
                    <AddEditFormList
                        fields={[{
                            name: "label",
                            type: "text",
                            label: "Nom",
                            xs: 4,
                            required: true
                        },
                        {
                            name: "unique_key",
                            type: "text",
                            label: "Clef unique",
                            xs: 4,
                            required: true
                        },
                        {
                            name: "sort_order",
                            type: "number",
                            label: "Ordre",
                            xs: 3,
                        }]}
                        hiddenFields={{
                            add: [],
                            edit: ["id"]
                        }}
                        entity={"status"}
                        title={"Status"}
                        buttonAdd={{ title: "Ajouter un statut" }}
                        defaultItem={{
                            sort_order: 0
                        }}
                    />
                </AddEditFormCard>
            </AddEditForm>
        </>
    )
}

export default StatusTypeView;