import { RouteItem, SectionItem } from "../AppRoutes";
import PaymentMethodList from "../../components/payment-methods/PaymentMethodList";
import PaymentMethodView from "../../components/payment-methods/PaymentMethodView";
import ShippingMethodList from "../../components/shipping-methods/ShippingMethodList";
import ShippingMethodView from "../../components/shipping-methods/ShippingMethodView";
import StatusTypeList from "../../components/status-types/StatusTypeList";
import StatusTypeView from "../../components/status-types/StatusTypeView";
import CountryList from "../../components/countries/CountryList";
import CountryView from "../../components/countries/CountryView";
import GenderList from "../../components/genders/GenderList";
import GenderView from "../../components/genders/GenderView";
import SettingsView from "../../components/settings/SettingsView";

import PaymentIcon from '@mui/icons-material/Payment';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import WcIcon from '@mui/icons-material/Wc';
import BusinessIcon from '@mui/icons-material/Business';

export const PaymentMethodListRoute: RouteItem = {
    index: "PAYMENT_METHODS",
    path: "/commercial/payment-methods",
    title: "Moyens de paiement",
    element: () => <PaymentMethodList />,
    menuVisible: true,
    menuIcon: <PaymentIcon />,
    authorization: {
        controller: "PaymentMethods",
        action: "index"
    }
}
export const PaymentMethodViewRoute: RouteItem = {
    index: "PAYMENT_METHODS",
    path: "/commercial/payment-methods/add-edit/:id?",
    title: "Moyens de paiement",
    element: () => <PaymentMethodView />,
    menuVisible: false,
    authorization: {
        controller: "PaymentMethods",
        action: "addEdit"
    }
}

export const ShippingMethodListRoute: RouteItem = {
    index: "SHIPPING_METHODS",
    path: "/commercial/shipping-methods",
    title: "Moyens de livraison",
    element: () => <ShippingMethodList />,
    menuVisible: true,
    menuIcon: <LocalShippingIcon />,
    authorization: {
        controller: "ShippingMethods",
        action: "index"
    }
}
export const ShippingMethodViewRoute: RouteItem = {
    index: "SHIPPING_METHODS",
    path: "/commercial/shipping-methods/add-edit/:id?",
    title: "Moyens de livraison",
    element: () => <ShippingMethodView />,
    menuVisible: false,
    authorization: {
        controller: "ShippingMethods",
        action: "addEdit"
    }
}

export const StatusTypeListRoute: RouteItem = {
    index: "STATUS_TYPES",
    path: "/commercial/status-types",
    title: "Statuts",
    element: () => <StatusTypeList />,
    menuVisible: true,
    menuIcon: <MoreHorizIcon />,
    authorization: {
        controller: "StatusTypes",
        action: "index"
    }
}
export const StatusTypeViewRoute: RouteItem = {
    index: "STATUS_TYPES",
    path: "/commercial/status-types/add-edit/:id?",
    title: "Statuts",
    element: () => <StatusTypeView />,
    menuVisible: false,
    authorization: {
        controller: "StatusTypes",
        action: "addEdit"
    }
}

export const CountryListRoute: RouteItem = {
    index: "COUNTRIES",
    path: "/commercial/countries",
    title: "Pays",
    element: () => <CountryList />,
    menuVisible: true,
    menuIcon: <FlagCircleIcon />,
    authorization: {
        controller: "Countries",
        action: "index"
    }
}
export const CountryViewRoute: RouteItem = {
    index: "COUNTRIES",
    path: "/commercial/countries/add-edit/:id?",
    title: "Genres",
    element: () => <CountryView />,
    menuVisible: false,
    authorization: {
        controller: "Countries",
        action: "addEdit"
    }
}

export const GenderListRoute: RouteItem = {
    index: "GENDERS",
    path: "/commercial/genders",
    title: "Genres",
    element: () => <GenderList />,
    menuVisible: true,
    menuIcon: <WcIcon />,
    authorization: {
        controller: "Genders",
        action: "index"
    }
}
export const GenderViewRoute: RouteItem = {
    index: "GENDERS",
    path: "/commercial/genders/add-edit/:id?",
    title: "Genres",
    element: () => <GenderView />,
    menuVisible: false,
    authorization: {
        controller: "Genders",
        action: "addEdit"
    }
}

export const SettingsViewRoute: RouteItem = {
    index: "SETTING_ENTERPRISE",
    path: "/settings",
    title: "Informations entreprise",
    element: () => <SettingsView />,
    menuVisible: true,
    menuIcon: <BusinessIcon />,
    authorization: {
        controller: "Settings",
        action: "index"
    }
}

export const SettingsSectionRoutes = {
    title: "Paramètres",
    index: "SETTINGS",
    children: [
        PaymentMethodListRoute, PaymentMethodViewRoute,
        ShippingMethodListRoute, ShippingMethodViewRoute,
        StatusTypeListRoute, StatusTypeViewRoute,
        CountryListRoute, CountryViewRoute,
        GenderListRoute, GenderViewRoute,
        SettingsViewRoute
    ]
} as SectionItem;