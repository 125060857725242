import { useCallback, useMemo } from 'react';
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useGetNoteList, useGetReminderList } from "../../stores/FoldersStore";
import { useEffect, useState } from "react";
import { ReqStatus } from "../../../stores/core/UseApi";
import { datetimeFormat } from '../../../utils/DateUtils';
import AvatarIcon from '../../../components/common/icon/AvatarIcon';
import FolderReminderButtonConfirm from '../folder-reminders/FolderReminderButtonConfirm';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import FolderReminderModal from '../folder-reminders/FolderReminderModal';
import { useSetRecoilState } from 'recoil';
import { FolderReminderModalState } from '../../states/FolderReminderState';
import EditIcon from '@mui/icons-material/Edit';
import FolderNoteModal from './FolderNoteModal';
import { FolderNoteModalState } from '../../states/FolderNoteState';
import { CreateColumnAvatar } from '../../../components/common/list/column/ColumnAvatar';
import { CreateColumnDate } from '../../../components/common/list/column/ColumnDate';

export const useFolderNotesColumns = (editEvent: (id?: number) => void) => {

    

    const columns = useMemo(() => {
        let _columns = [];
        _columns.push({
            field: 'id',
            headerName: "N°",
            editable: false,
            filterable: false,
            width: 50,
            valueGetter: ({ row }) => `#${row.id}`
        } as GridColDef);

        _columns.push({
            field: 'comment',
            headerName: "Note",
            editable: false,
            filterable: false,
            width: 600,
            renderCell: ({ row }) => (
                <Tooltip title={<Box fontSize={14}>{row.comment?.split('\n').map((x: any) => <Box>{x}</Box> ?? "")}</Box>}>
                    <Box>
                       {row.title ? row.title : row.comment.substring(0, 90)}{row.comment.length > 60 && "..."}
                    </Box>
                </Tooltip>
            )
        } as GridColDef);

        _columns.push(
            CreateColumnAvatar({
                field: 'user_id',
                headerName: "Utilisateur",
                getId: (row) => row.user_id,
                getValue: (row) => `${row.user.first_name} ${row.user.last_name}`
            })
        );
        _columns.push(
            CreateColumnDate({
                field: 'created',
                headerName: "Créé le",
                type: "dateTime",
                getValue : (row) => row.created
            })
        );

        return _columns;
    }, []);

    const getActions = useCallback((row: any) => {
        return (
            <Box>
                <Tooltip title="Modifier"><IconButton onClick={() => { editEvent(row.id) }}><EditIcon /></IconButton></Tooltip>
            </Box>
        );
    }, []);

    return { columns, getActions };
}

const FolderNoteList = (props: { folderId: string }) => {
    const { folderId } = props;
    const get = useGetNoteList();
    const [rows, setRows] = useState<any[]>([]);

    const getDatas = useCallback(() => {
        if (isNaN(+folderId)) { return; }
        get(folderId).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                setRows(res.data.notes ?? [])
            }
        })
    }, [folderId]);

    const setReminderModal = useSetRecoilState(FolderNoteModalState);
    useEffect(() => {
        getDatas();
    }, []);

    const displayNoteForm = useCallback((id?: number) => {
        setReminderModal({
            open: true,
            id: id,
            onValidate: () => {
                getDatas();
            }
        })
    }, [getDatas]);

    const { columns, getActions } = useFolderNotesColumns(displayNoteForm);

    const columnsGrid = useMemo(() => {
        return [
            ...columns,
            {
                field: 'actionss',
                headerName: "",
                editable: false,
                filterable: false,
                width: 140,
                renderCell: getActions
            } as GridColDef
        ];
    }, [columns, getActions])

    return (
        <>
            <Box display="flex" justifyContent={"flex-end"} p={1}>
                <Button variant="contained" color="info" onClick={() => displayNoteForm()}>Ajouter une note</Button>
            </Box>
            <FolderNoteModal />
            <Box height={371}>
                <DataGrid
                    disableColumnFilter
                    disableRowSelectionOnClick
                    autoPageSize
                    filterMode="client"
                    rows={rows}
                    columns={columnsGrid}
                    getRowId={(row: any) => row.id}
                />
            </Box >
        </>
    )
};
export default FolderNoteList;