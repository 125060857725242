import { useNavigate, useParams } from "react-router-dom";
import { useAddEditSupplier, useGetSupplier } from "../../stores/SuppliersStore";
import { useEffect, useMemo, useState } from "react";
import { ReqStatus } from "../../stores/core/UseApi";
import { Box, Divider, Grid, TextField } from "@mui/material";
import InputField from "../common/form/InputField";
import TextAreaField from "../common/form/TextAreaField";
import AddEditFormPanel, { useAddEditForm } from "../common/form/AddEditFormPanel";
import AddEditFormSubmit from "../common/form/AddEditFormSubmit";
import AutocompleteField, { AutocompleteOption } from "../common/form/AutocompleteField";
import { SupplierModel } from "../../classes/suppliers/SupplierModel";
import { useField, useForm, useFormValues, useIsDirty } from "react-recoil-form";
import AddEditFormList from "../common/form/AddEditFormList";
import { useSetRecoilState } from "recoil";
import { NavigateState, NavigationBlockedState } from "../../states/MenuState";
import { useAddEditCore, useViewCore } from "../../stores/core/StoreCore";
import SelectField, { SelectOptionsProps } from "../common/form/SelectField";
import AddEditForm from "../common/form/AddEditForm";
import AddEditFormCard from "../common/form/AddEditFormCard";
import { mapArrayForForm } from "../../utils/DataUtils";
import ProductSupplierView from "./ProductSupplierView";
import ProductIntegratedView from "./ProductIntegratedView";

const ProductInfosView = (props: { productTypeOptions: SelectOptionsProps[], categoryOptions: SelectOptionsProps[], amountTaxIncl: number }) => {

    const { productTypeOptions, categoryOptions, amountTaxIncl } = props;

    return (
        <Grid container spacing={3} >
            <Grid item xs={12} sm={6}>
                <SelectField name="product_type_id" label="Type de produit" required options={productTypeOptions} />
            </Grid>

            <Grid item xs={12} sm={6}>
                <SelectField
                    name="categories"
                    label="Catégories"
                    options={categoryOptions}
                    multiple
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <InputField
                    name="name"
                    label="Nom"
                    required
                    type="text"
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <InputField
                    name="reference"
                    label="Référence"
                    type="text"
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <TextAreaField
                    name="description"
                    label="Description"
                />
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
            <Grid item xs={12} sm={6}>
                <InputField
                    name="amount_tax_excl"
                    label="Montant HT"
                    type="number"
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField label="Montant TTC" value={amountTaxIncl} disabled={true} fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
                <InputField
                    name="quantity_per_package"
                    label="Quantité par conditionnement"
                    type="number"
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <InputField
                    name="stock_quantity"
                    label="Stock"
                    type="number"
                />
            </Grid>
        </Grid>


    );
}

export default ProductInfosView;