import { Box, Grid } from "@mui/material";
import AddEditFormPanel, { useAddEditForm } from "../../../components/common/form/AddEditFormPanel";
import AddEditFormSubmit from "../../../components/common/form/AddEditFormSubmit";
import { useAddEditCore } from "../../../stores/core/StoreCore";
import InputField from "../../../components/common/form/InputField";
import TextAreaField from "../../../components/common/form/TextAreaField";
import SelectField, { SelectOptionsProps } from "../../../components/common/form/SelectField";
import { FolderOrderSupplierContactTypeOptions } from "../../classes/FolderOrderSupplierContactModel";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { FolderOrderSupplierListState, FolderOrderSupplierValidatedOptionsState } from "../../states/FolderOrderSuppliersState";
import { useEffect, useMemo } from "react";
import { ReqStatus } from "../../../stores/core/UseApi";
import { useAddEditFolderOrderSupplierContact } from "../../stores/FolderOrderSupplierContactsStore";
import { EnumFolderOrderSupplierStatus } from "../../classes/FolderOrderSupplierModel";
import { get } from "http";
import { connect } from "http2";

interface FolderOrderSupplierContactFormProps {
	id?: number | undefined,
	onClose: () => void,
	onValidate: () => void
}

const FolderOrderSupplierContactForm = (props: FolderOrderSupplierContactFormProps) => {

	const { id, onClose, onValidate } = props;

	const { getData, addEditData } = useAddEditFolderOrderSupplierContact();

	const setFolderOrderSupplierList = useSetRecoilState(FolderOrderSupplierListState);
	const folderOrderSupplierOptions = useRecoilValue(FolderOrderSupplierValidatedOptionsState);

	useEffect(() => {
		const stringId = id !== undefined ? id.toString() : undefined;
		getData(stringId).then((res) => {
			if (res.status === ReqStatus.SUCCESS && res.data) {
				resetInitialValues(
					{
						folder_order_supplier_id: res.data.folderOrderSupplierContact.folder_order_supplier_id,
						type: res.data.folderOrderSupplierContact.type,
						name: res.data.folderOrderSupplierContact.name,
						phone: res.data.folderOrderSupplierContact.phone,
						comment: res.data.folderOrderSupplierContact.comment
					}
				);
			}
		});
	}, []);

	const onSubmit = (request: any) => {
		addEditData(request, id?.toString()).then((res) => {
			if (res.status === ReqStatus.SUCCESS && Array.isArray(res.data) && res.data.length > 0) {
				const contact = res.data[0];
				setFolderOrderSupplierList((list) => {
					var newList = list.map((item) => {
						if (item.id === contact.folder_order_supplier_id) {
							return {
								...item,
								folder_order_supplier_contacts: contact.is_new 
									? [...item.folder_order_supplier_contacts, contact] 
									: item.folder_order_supplier_contacts.map((c) => {
										if(c.id !== contact.id) { return c; }
										return { ...c, ...contact };
									})
							};
						}
						return item;
					})
					return newList;
				})
				
				onValidate();
			}
		});
	}

	const { handleSubmit, resetInitialValues, isDirty } = useAddEditForm({
		onSubmit
	});

	return (
		<AddEditFormPanel
			title={id ? `Modifier un contact chauffeur #${id}` : "Ajouter un contact chauffeur"}
		>
			<form id={"folderOrderSupplierContactForm"} onSubmit={handleSubmit}>
				<Grid container spacing={3} mt={1}>
					<Grid item xs={12} >
						<SelectField
							name="folder_order_supplier_id"
							label="Demande fournisseur"
							required
							options={folderOrderSupplierOptions}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<SelectField
							name="type"
							label="Type"
							required
							options={FolderOrderSupplierContactTypeOptions}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<InputField
							name="name"
							label="Nom"
							required
							type="text"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<InputField
							name="phone"
							label="Téléphone"
							type="text"
							required
							isPhone
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextAreaField
							name="comment"
							label="Commentaire"
						/>
					</Grid>
				</Grid>
				<Box mt={2}>
					<AddEditFormSubmit
						returnAction={{disabled: true }}
						cancelEvent={() => { resetInitialValues(); onClose(); }}
						disabled={!isDirty}
					/>
				</Box>
			</form>
		</AddEditFormPanel >
	)
}

export default FolderOrderSupplierContactForm;