import { RecoilState, selector } from "recoil";
import { UserModel } from "../../classes/users/UserModel";
import { CurrentUserState } from "../../states/AuthState";

export const UserExtenalSelector = selector({
    key: 'UserExtenalSelector',
    get: ({get}) => {
        const currentUserState = get(CurrentUserState);
        return currentUserState?.external;
    },
});

export const IsNotUserCommercialSelector = selector({
    key: 'IsNotUserCommercialSelector',
    get: ({get}) => {
        const currentUserState = get(CurrentUserState);
        return currentUserState?.user_group_id === 3 ? false : true;
    },
});