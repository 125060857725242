import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { useField, useFieldArray } from "react-recoil-form";
import AutocompleteField, { AutocompleteOption } from "../common/form/AutocompleteField";
import DeleteIcon from "@mui/icons-material/Delete";
import SelectField, { SelectOptionsProps } from "../common/form/SelectField";
import InputField from "../common/form/InputField";
import NumberField from "../common/form/NumberField";
import TextAreaField from "../common/form/TextAreaField";
import DateField from "../common/form/DateField";
import { useEffect, useMemo, useState } from "react";
import { useGetShippingMethodAmountTaxExcl } from "../../stores/OrdersStore";
import { ReqStatus } from "../../stores/core/UseApi";
import { useOrderShippingCalcul } from "./OrderCalcul";

interface OrdershippingItem {
    prefixeName: string,
    disabled?: boolean,
    id: number,
    shippingMethodOptions: SelectOptionsProps[],
    methodShippingStatus: { id: string, options: SelectOptionsProps[] }[],
    deleteEvent: () => void
}
const OrderShippingItem = (props: OrdershippingItem) => {
    const { prefixeName, disabled, id, shippingMethodOptions, methodShippingStatus, deleteEvent } = props;

    const getShippingMethodAmountTaxExcl = useGetShippingMethodAmountTaxExcl();
    
    const shippingMethodIdField = useField({ ancestors: [{ name: prefixeName, rowId: id }], name: "shipping_method_id" });
    const statusIdField = useField({ ancestors: [{ name: prefixeName, rowId: id }], name: "status_id" });
    const amountTaxExclField = useField({ ancestors: [{ name: prefixeName, rowId: id }], name: "amount_tax_excl" });

    const statusOptions = useMemo(() => {
        if (!shippingMethodIdField.fieldValue) { 
            statusIdField.setFieldValue(undefined); 
            return []; 
        }
        const found = methodShippingStatus.find(x => x.id == shippingMethodIdField.fieldValue);

        if (found) {
            const optionFound = found.options.find(o => o.value == statusIdField.fieldValue);
            if (!optionFound) { statusIdField.setFieldValue(undefined); }
            return found.options;
        } else {
            return [];
        }
    }, [shippingMethodIdField.fieldValue, methodShippingStatus]);

    useEffect(() => {
        if(!amountTaxExclField.extraInfo){
            amountTaxExclField.setFieldValue(amountTaxExclField.fieldValue, { ready: true } );
            return;
        }
        if(! shippingMethodIdField.fieldValue ){ return; }
        getShippingMethodAmountTaxExcl(+shippingMethodIdField.fieldValue).then((res) => {
            if(res.status === ReqStatus.SUCCESS && res.data){
                amountTaxExclField.setFieldValue(res.data.amountTaxExcl, { ready: true } );
            }
        })
    },[shippingMethodIdField.fieldValue])

    return (
        <>
            <Grid key={`${prefixeName}_shipping_method_id_${id}`} item xs={1}>
                <SelectField
                    ancestors={[{ name: prefixeName, rowId: id }]}
                    options={shippingMethodOptions ?? []}
                    name={"shipping_method_id"}
                    required
                    label={"Moyen de livraison"}
                    disabled={disabled}
                />
            </Grid>
            <Grid key={`${prefixeName}_line_status_id_${id}`} item xs={2}>
                <SelectField
                    ancestors={[{ name: prefixeName, rowId: id }]}
                    options={statusOptions}
                    name={"status_id"}
                    required
                    label={"Statut"}
                    disabled={disabled}
                />
            </Grid>
            <Grid key={`${prefixeName}_line_reference_${id}`} item xs={2}>
                <InputField
                    ancestors={[{ name: prefixeName, rowId: id }]}
                    name={"reference"}
                    label={"Référence"}
                    type="text"
                    required
                    disabled={disabled}
                />
            </Grid>
            <Grid key={`${prefixeName}_line_amount_tax_excl_${id}`} item xs={2}>
                <NumberField
                    ancestors={[{ name: prefixeName, rowId: id }]}
                    name={"amount_tax_excl"}
                    label={"Montant HT"}
                    required
                    disabled={disabled}
                />
            </Grid>
            <Grid key={`${prefixeName}_line_comment_${id}`} item xs={2}>
                <TextAreaField
                    ancestors={[{ name: prefixeName, rowId: id }]}
                    name={"comment"}
                    label={"Commentaire"}
                    required
                    disabled={disabled}
                />
            </Grid>
            <Grid key={`${prefixeName}_line_created_${id}`} item xs={2}>
                <DateField
                    ancestors={[{ name: prefixeName, rowId: id }]}
                    name={"created"}
                    label={"Date"}
                    required
                    type="datetime"
                />
            </Grid>
            {!disabled &&
                <Grid item xs={1}>
                    <IconButton color="error" onClick={() => deleteEvent()}><DeleteIcon /></IconButton>
                </Grid>
            }
        </>
    )
}

interface OrderShippingsViewProps {
    shippingMethods: SelectOptionsProps[],
    methodShippingStatus: { id: string, options: SelectOptionsProps[] }[],
    disabled?: boolean
}

export const orderShippingFieldNames = ["id", "shipping_method_id", "status_id", "reference", "amount_tax_excl", "comment", "created"];

const OrderShippingsView = (props: OrderShippingsViewProps) => {
    const entityListField = useFieldArray({ name: "shippings", fieldNames: orderShippingFieldNames, });
    const newEntityListField = useFieldArray({ name: "newShippings", fieldNames: [...orderShippingFieldNames, 'status_history'] });

    const { disabled, shippingMethods, methodShippingStatus } = props;

    return (
        <Box display={"flex"} flexDirection={"column"} >
            <Grid container spacing={2} display={"flex"} alignItems={"flex-start"}>
                <>
                    {entityListField.fieldArrayProps.rowIds.map((rowId, rowIndex) => (
                        <OrderShippingItem
                            key={rowId}
                            id={rowId}
                            prefixeName={"shippings"}
                            deleteEvent={() => entityListField.remove(rowIndex)}
                            disabled={disabled}
                            shippingMethodOptions={shippingMethods}
                            methodShippingStatus={methodShippingStatus}
                        />
                    ))}

                    {!disabled && newEntityListField.fieldArrayProps.rowIds.map((rowId, rowIndex) => (
                        <OrderShippingItem
                            key={rowIndex}
                            id={rowId}
                            prefixeName={"newShippings"}
                            deleteEvent={() => newEntityListField.remove(rowIndex)}
                            disabled={disabled}
                            shippingMethodOptions={shippingMethods}
                            methodShippingStatus={methodShippingStatus}
                        />
                    ))}
                </>
            </Grid>
            {!disabled &&
                <Box mt={2}>
                    <Button variant="contained" color="primary" onClick={() => newEntityListField.append({status_history: ''})}>{"Ajouter une livraison"}</Button>
                </Box>
            }
        </Box>

    );
}

export default OrderShippingsView;