import { Box, Button, Paper } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Mail2ModalState } from "../../states/MailState";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { SmsModalState } from "../../states/SmsState";
import { FolderModel } from "../../classes/FolderModel";
import AddUserOnEntityButton from "../common/AddUserOnEntityButton";
import { addUserOnEntityType } from "../../states/FoldersState";
import AddUserOnEntityModal from "../common/AddUserOnEntityModal";
import { CurrentUserState } from "../../../states/AuthState";
import { useMemo } from "react";

interface FolderActionsProps {
    folder: FolderModel
}
const FolderActions = (props: FolderActionsProps) => {

    const { folder } = props;

    const { id } = useParams();
    const navigate = useNavigate();

    const setMail2ModalState = useSetRecoilState(Mail2ModalState);
    const setSmsModalState = useSetRecoilState(SmsModalState);
    
    const currentUserState = useRecoilValue(CurrentUserState);
    const isNotCommercial = useMemo(() => {
        return currentUserState?.user_group_id !== 3 ? true : false;
    }, [currentUserState]);

    return (
        <Paper>
            <Box display="flex" justifyContent={"space-between"} p={2}>
                {id && ( !folder.commercial_id || ( folder.commercial_id && isNotCommercial ) ) &&
                    <>
                        <AddUserOnEntityModal />
                        <AddUserOnEntityButton type={addUserOnEntityType.COMMERCIAL_FOLDER} ids={[+id]} onValidate={() => { window.location.reload(); }} />
                    </>
                }

                {id && folder.commercial_id && 
                    < Button
                        variant="contained"
                        color="warning"
                        onClick={() => setSmsModalState({ open: true })}
                    >
                        SMS
                    </Button>
                }

                {id && folder.commercial_id &&
                    <Button
                        variant="contained"
                        color="info"
                        onClick={() => setMail2ModalState({ open: true, folderId: +id })}
                    >
                        Mail
                    </Button>
                }

                {id && folder.commercial_id &&
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => navigate(`/folders/${id}/quotes/add-edit/`)}
                    >
                        Nouveau devis
                    </Button>
                }
            </Box>
        </Paper >
    );
}

export default FolderActions;