import { GridColDef } from "@mui/x-data-grid";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { PaymentListActions } from "../../../components/payments/PaymentList";
import { datetimeFormat } from "../../../utils/DateUtils";
import { useGetListCore } from "../../../stores/core/StoreCore";
import { PaymentListModel } from "../../../classes/payments/PaymentModel";
import ListCore from "../../../components/common/list/ListCore";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import InputSearchGlobal from "../search/InputSearchGlobal";
import { CreateColumnDate } from "../../../components/common/list/column/ColumnDate";
import FormattedAmount from "../../../components/common/formatted/FormattedAmount";

const Actions = (props: { row: any }) => {

    const { row } = props;
    const navigate = useNavigate();

    return (
        <Box display="flex">
            <Box>
                <Button variant="contained" color="info" onClick={() => navigate(`/folders/add-edit/${row.order.order_attribute.folder_id}`)}>Voir dossier</Button>
            </Box>
            <Box ml={2}>
                <Button variant="contained" color="success" onClick={() => navigate(`/commercial/customers/add-edit/${row.order.customer_id}`)}>Fiche client</Button>
            </Box>
        </Box>
    );
}

const PaymentCustomList = () => {
    const intl = useIntl();
    const columns = useCallback(() => {
        return [
            {
                field: 'order.id',
                headerName: "Devis",
                editable: false,
                filterable: false,
                width: 80,
                valueGetter: ({ row }) => `#${row.order.id}`
            },
            {
                field: 'invoice.id',
                headerName: "Facture",
                editable: false,
                filterable: false,
                width: 80,
                renderCell: ({ row }) => {
                    
                    let billId = "";
                    if(row.order.bills_principal && row.order.bills_principal.length > 0){
                        billId =  row.order.bills_principal[0].id;
                    }
                    return (
                        <>{billId && `#${billId}`}</>
                    )
                }
            },
            {
                field: 'dahan_deposit_id',
                headerName: "Facture acompte",
                editable: false,
                filterable: false,
                width: 130,
                renderCell: ({ row }) => {
                    return (
                        <>{row.dahan_deposit_id && `#${row.dahan_deposit_id}`}</>
                    )
                }
            },
            {
                field: 'reference',
                headerName: "Référence",
                editable: false,
                filterable: false,
                width: 150,
            },
            {
                field: 'comment',
                headerName: "Commentaire interne",
                editable: false,
                filterable: false,
                width: 200,
            },
            {
                field: 'billing_address',
                headerName: "Client",
                editable: false,
                filterable: false,
                width: 150,
                valueGetter: ({ row }) => `${row.order.billing_address.first_name} ${row.order.billing_address.last_name}`
            },
            {
                field: 'order.customer.dahan_auxiliaire',
                headerName: "Compte auxiliaire",
                editable: false,
                filterable: false,
                width: 150,
                valueGetter: ({ row }) => `${row.order.customer.dahan_auxiliaire}`
            },
            {
                field: 'payment_method.label',
                headerName: "Moyen de paiement",
                editable: false,
                filterable: false,
                width: 150,
                valueGetter: ({ row }) => row.payment_method.label
            },
            {
                field: 'status.label',
                headerName: "Statut",
                editable: false,
                filterable: false,
                width: 150,
                valueGetter: ({ row }) => row.status.label
            },
            {
                field: 'total_amount_euro',
                headerName: "Montant",
                editable: false,
                filterable: false,
                width: 100,
                renderCell: ({ row }) => <Box display={"flex"} justifyContent={"flex-end"} width={"100%"}><FormattedAmount amount={row.total_amount} symbol="€"/></Box>
            },
            CreateColumnDate({
                field: 'dahan_payment_date',
                headerName: "Date",
                getValue: (row) => row.dahan_payment_date,
                type: "dateTime"
            })
        ] as GridColDef[];
    }, [intl]);

    const routeController = "commercial/payments";
    const [get] = useGetListCore<PaymentListModel>(`/${routeController}/index`);

    return (
        <>
            <ListCore<PaymentListModel, any>
                getDatas={get}
                defineDatasToSet={(data) => data.payments}
                getRowId={(row) => row.id}
                columns={columns()}
                actions={{
                    defaultRoute: routeController,
                    edit: false,
                    others: (row) => <Actions row={row} />
                }}
                paginationOptionDefault={50}
            />
        </>
    );
}

export default PaymentCustomList;