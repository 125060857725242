import { RouteItem, SectionItem } from "../AppRoutes";

import SupplierOrderList from "../../components/supplier-orders/SupplierOrderList";
import SupplierOrderView from "../../components/supplier-orders/SupplierOrderView";
import SupplierView from "../../components/suppliers/SupplierView";
import ProductList from "../../components/products/ProductList";
import SupplierList from "../../components/suppliers/SupplierList";
import ProductView from "../../components/products/ProductView";
import ProductTypeView from "../../components/product-types/ProductTypeView";
import ProductTypeList from "../../components/product-types/ProductTypeList";

import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import KeyIcon from '@mui/icons-material/Key';
import InventoryIcon from '@mui/icons-material/Inventory';
import CategoryIcon from '@mui/icons-material/Category';
import CategoryList from "../../components/categories/CategoryList";
import CategoryView from "../../components/categories/CategoryView";

export const SupplierOrderListRoute: RouteItem = {
    index: "SUPPLIER_ORDERS",
    path: "/commercial/supplier-orders",
    title: "Commandes fournisseurs",
    element: () => <SupplierOrderList />,
    menuVisible: true,
    menuIcon: <LocalShippingIcon />,
    authorization: {
        controller: "SupplierOrders",
        action: "index"
    }
}

export const SupplierOrderViewRoute: RouteItem = {
    index: "SUPPLIER_ORDERS",
    path: "/commercial/supplier-orders/add-edit/:id?",
    title: "Commandes fournisseurs",
    element: () => <SupplierOrderView />,
    menuVisible: false,
    authorization: {
        controller: "SupplierOrders",
        action: "addEdit"
    }
}

export const SupplierListRoute: RouteItem = {
    index: "SUPPLIERS",
    path: "/commercial/suppliers",
    title: "Fournisseurs",
    element: () => <SupplierList />,
    menuVisible: true,
    menuIcon: <AccountBoxIcon />,
    authorization: {
        controller: "Suppliers",
        action: "index"
    }
}

export const SupplierViewRoute: RouteItem = {

    index: "SUPPLIERS",
    path: "/commercial/suppliers/add-edit/:id?",
    title: "Fournisseurs",
    element: () => <SupplierView />,
    menuVisible: false,
    authorization: {
        controller: "Suppliers",
        action: "addEdit"
    }
}

export const ProductListRoute: RouteItem = {
    index: "PRODUCTS",
    path: "/commercial/products",
    title: "Produits",
    element: () => <ProductList />,
    menuVisible: true,
    menuIcon: <InventoryIcon />,
    authorization: {
        controller: "Products",
        action: "index"
    }
}

export const ProductViewRoute: RouteItem = {
    index: "PRODUCTS",
    path: "/commercial/products/add-edit/:id?",
    title: "Produits",
    element: () => <ProductView />,
    menuVisible: false,
    menuIcon: <InventoryIcon />,
    authorization: {
        controller: "Products",
        action: "addEdit"
    }
}

export const ProductTypeListRoute: RouteItem = {
    index: "PRODUCT_TYPES",
    path: "/commercial/product-types",
    title: "Types de produits",
    element: () => <ProductTypeList />,
    menuVisible: true,
    menuIcon: <CategoryIcon />,
    authorization: {
        controller: "ProductTypes",
        action: "index"
    }
}

export const ProductTypeViewRoute: RouteItem = {
    index: "PRODUCT_TYPES",
    path: "/commercial/product-types/add-edit/:id?",
    title: "Types de produits",
    element: () => <ProductTypeView />,
    menuVisible: false,
    authorization: {
        controller: "ProductTypes",
        action: "addEdit"
    }
}

export const ProductCategoryListRoute: RouteItem = {
    index: "PRODUCT_CATEGORIES",
    path: "/commercial/categories",
    title: "Catégories de produits",
    element: () => <CategoryList />,
    menuVisible: true,
    menuIcon: <CategoryIcon />,
    authorization: {
        controller: "Categories",
        action: "index"
    }
}

export const ProductCategoryViewRoute: RouteItem = {
    index: "PRODUCT_CATEGORIES",
    path: "/commercial/categories/add-edit/:id?",
    title: "Catégories de  produits",
    element: () => <CategoryView />,
    menuVisible: false,
    authorization: {
        controller: "Categories",
        action: "addEdit"
    }
}

export const StockSectionRoutes = {
    title: "Stock",
    index: "STOCK",
    children: [
        SupplierOrderListRoute,
        SupplierOrderViewRoute,
        SupplierListRoute,
        SupplierViewRoute,
        ProductListRoute,
        ProductViewRoute,
        ProductTypeListRoute,
        ProductTypeViewRoute,
        ProductCategoryListRoute,
        ProductCategoryViewRoute
    ]
} as SectionItem;