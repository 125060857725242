import { useCallback } from "react";
import { useApi } from "../../stores/core/UseApi";
import { BrevoTemplateModel } from "../classes/BrevoApiModel";
import { MailHistoryEvent } from "../components/mails/MailHistoryModal";



const routeController = 'project-dahan/dahan/mails';

export const useGetTemplates = () => {
    const [callCore, status] = useApi<{ templates: BrevoTemplateModel[] }, undefined>(
        {
            route: `${routeController}/get-templates`,
            instanceAxiosMethod: "POST"
        }
    );
    const call = useCallback(() => {
        return callCore();
    }, [callCore]);
    return { call, status };
};

interface sendMailRequest {
    template_id: number,
    folder_id: number,
    entity_id: number | undefined,
    emails: string | undefined,
    subject: string
}
export const useSendMail = () => {
    const [callCore, status] = useApi<undefined, sendMailRequest>(
        {
            route: `${routeController}/send-mail`,
            instanceAxiosMethod: "POST"
        }
    );
    const call = useCallback((request: sendMailRequest) => {
        return callCore(request);
    }, [callCore]);
    return { call, status };
}

interface getHistoryMailRequest {
    messageId: string,
}

interface getHistoryMailResponse {
    events: MailHistoryEvent[],
    subject: string,
}
export const useHistoryMail = () => {
    const [callCore, status] = useApi<getHistoryMailResponse, getHistoryMailRequest>(
        {
            route: `${routeController}/history-mail`,
            instanceAxiosMethod: "POST"
        }
    );
    const call = useCallback((request: getHistoryMailRequest) => {
        return callCore(request);
    }, [callCore]);
    return { call, status };
}