import {  Container, Modal } from "@mui/material";
import { FormProvider } from "react-recoil-form";
import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { SmsModalState } from "../../states/SmsState";
import SendSmsForm from "./SendSmsForm";

const SendSmsModal = () => {
	
	const [modalState, setModalState] = useRecoilState(SmsModalState);

	const handleClose = useCallback(() => {
		setModalState((a) => {
			return { ...a, open: false };
		});
	}, [setModalState]);
	
	return (
		<Modal
			open={modalState.open}
			aria-labelledby="sms-modal"
			sx={{
				overflow: 'auto'
			}}
			onClose={handleClose}
		>
			<Container component="main" maxWidth="md" sx={{ height: "100%", py: 2 }}>
				<FormProvider options={{ formId: "smsForm", skipRecoilRoot: true }}>
					<SendSmsForm onCloseEvent={handleClose} />
				</FormProvider>
			</Container>
		</Modal>
	);
}

export default SendSmsModal;