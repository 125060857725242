import { Box, Container, Grid, Modal } from "@mui/material";
import { FormProvider } from "react-recoil-form";
import { useCallback, useEffect } from "react";
import { useRecoilState } from "recoil";
import { FolderChecktasksFormModalState } from "../../states/FolderChecktasksState";
import AddEditFormPanel, { useAddEditForm } from "../../../components/common/form/AddEditFormPanel";
import TextAreaField from "../../../components/common/form/TextAreaField";
import AddEditFormSubmit from "../../../components/common/form/AddEditFormSubmit";
import { useAddEditCore } from "../../../stores/core/StoreCore";
import { ReqStatus } from "../../../stores/core/UseApi";

interface FolderChecktaskFormProps {
	id: number,
	onClose: () => void,
	onValidate: (comment: string) => void
}
const FolderChecktaskForm = (props: FolderChecktaskFormProps) => {
    const { id, onClose, onValidate } = props;

	const { getData, addEditData } = useAddEditCore<any, any[]>(`project-dahan/dahan/folder-checktasks/add-edit`);

	useEffect(() => {
		if (id) {
			getData(id.toString()).then((res) => {
				if (res.status === ReqStatus.SUCCESS && res.data) {
					const _task = ( (res.data as any).folderChecktask);
					resetInitialValues({
						comment: _task.comment,
					});
				}
			})
		} else {
			resetInitialValues({
				comment: ""
			});
		}
	}, [])

	const onSubmit = (request: any) => {
		addEditData(request, id ? id.toString() : undefined).then((res) => {
			if (res.status === ReqStatus.SUCCESS) {
				onValidate(request.comment ?? "");
			}
		});
	}

	const { handleSubmit, resetInitialValues, isDirty } = useAddEditForm({
		onSubmit
	});

    return (
        <AddEditFormPanel
            title={"Modifier une tâche"}
        >
            <form id={"FolderChecktaskForm"} onSubmit={handleSubmit}>
                <Grid container spacing={3} mt={1}>
                    <Grid item xs={12} >
                        <TextAreaField
                            name="comment"
                            label="Note"
                            maxRows={25}
                        />
                    </Grid>
                </Grid>
                <Box mt={2}>
                    <AddEditFormSubmit
                        returnAction={{ disabled: true }}
                        cancelEvent={() => { resetInitialValues(); onClose(); }}
                        disabled={!isDirty}
                    />
                </Box>
            </form>
        </AddEditFormPanel >
    )
}

const FolderChecktaskFormModal = () => {

    const [modalState, setModalState] = useRecoilState(FolderChecktasksFormModalState);

    const handleClose = useCallback(() => {
        setModalState((a) => {
            return { ...a, open: false };
        });
    }, [setModalState]);

    const handleValidate = useCallback((comment: string) => {
        modalState.onValidate(modalState.folder_checktask_id, comment);
        
        handleClose();
    }, [handleClose, modalState.onValidate, modalState.folder_checktask_id]);

    return (
        <Modal
            open={modalState.open}
            aria-labelledby="folder-order-supplier-comment-modal"
            sx={{ overflow: 'auto' }}
        >
            <Container component="main" maxWidth="md" sx={{ height: "100%", py: 2 }}>
                <FormProvider options={{ formId: "FolderChecktaskForm", skipRecoilRoot: true }}>
                    <FolderChecktaskForm
                        id={modalState.folder_checktask_id}
                        onClose={handleClose}
                        onValidate={handleValidate}
                    />
                </FormProvider>
            </Container>
        </Modal>
    );
}

export default FolderChecktaskFormModal;