import { Box, Divider, Grid, Tooltip } from "@mui/material";
import AddEditFormPanel, { useAddEditForm } from "../../../components/common/form/AddEditFormPanel";
import AddEditFormSubmit from "../../../components/common/form/AddEditFormSubmit";
import { useEffect, useMemo, useState } from "react";
import { ReqStatus } from "../../../stores/core/UseApi";
import TextAreaField from "../../../components/common/form/TextAreaField";
import { useParams } from "react-router-dom";
import { useAddEditFolderNote } from "../../stores/FolderNotesStore";
import { FolderNoteModel } from "../../classes/FolderNoteModel";
import { useRecoilValue } from "recoil";
import { CurrentUserState } from "../../../states/AuthState";
import InputField from "../../../components/common/form/InputField";
import InfoIcon from '@mui/icons-material/Info';


interface FolderReminderFormProps {
	id?: number,
	onClose: () => void,
	onValidate: () => void
}

const FolderNoteForm = (props: FolderReminderFormProps) => {

	const { id, onClose, onValidate } = props;

	const { id: folder_id } = useParams();

	const { getData, addEditData } = useAddEditFolderNote();
	
	const currentUser = useRecoilValue(CurrentUserState);

	
	const [userId, setUserId] = useState<number | undefined>();
	const canModify = useMemo(() => {
		//on est en création, donc modification permise pour tous car ça sera le créateur
		if (!userId) { return true; }
		
		//si c'est l'admin ou super admin, modification permise
		if (currentUser && (currentUser.user_group_id === 1 || currentUser.user_group_id === 2)) {
			return true;
		}

		if (currentUser && currentUser.id === userId) {
			return true;
		}

		return false;

	}, [currentUser, userId])

	useEffect(() => {
		if (id) {
			getData(id.toString()).then((res) => {
				if (res.status === ReqStatus.SUCCESS && res.data && res.data) {
					const _note = ( (res.data as any).folderNote as FolderNoteModel);
					setUserId(_note.user_id);
					resetInitialValues({
						folder_id: _note.folder_id,
						title: _note.title,
						comment: _note.comment,
					});
				}
			})
		} else {
			resetInitialValues({
				comment: "",
				title: "",
				folder_id: folder_id
			});
		}
	}, [])

	const onSubmit = (request: FolderNoteModel) => {
		addEditData(request, id ? id.toString() : undefined).then((res) => {
			if (res.status === ReqStatus.SUCCESS && Array.isArray(res.data) && res.data.length > 0) {
				onValidate();
			}
		});
	}

	const { handleSubmit, resetInitialValues, isDirty } = useAddEditForm({
		onSubmit
	});

	return (
		<AddEditFormPanel
			title={id ? "Modifier une note" : "Ajouter une note"}
		>
			<form id={"folderNoteForm"} onSubmit={handleSubmit}>
				
				<Grid container spacing={3} mt={1}>
					<Grid item xs={12} >
						<InputField
							name="title"
							label="Titre"
							type="string"
						/>
					</Grid>
					<Grid item xs={12} >
					<TextAreaField
							name="comment"
							label="Note"
							maxRows={25}
							required
							disabled={!canModify}
						/>
					</Grid>
				</Grid>
				<Box mt={2}>
					<AddEditFormSubmit
						returnAction={{ disabled: true }}
						cancelEvent={() => { resetInitialValues(); onClose(); }}
						disabled={!isDirty || !canModify}
					/>
				</Box>
			</form>
		</AddEditFormPanel >
	)
}

export default FolderNoteForm;