import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment, { Moment, isMoment } from 'moment';
import { Box } from '@mui/system';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Card, IconButton, Skeleton, Tooltip, Typography, Button, Modal, Container, Paper, Divider, FormControl, InputLabel, Select, OutlinedInput, MenuItem } from '@mui/material';
import { useGetListCore } from '../../../stores/core/StoreCore';
import { ListModelCore, ListSearchModelCore } from '../../../classes/list/ListModel';
import { ReqStatus } from '../../../stores/core/UseApi';
import { EnumQuoteStatus } from '../../classes/QuoteModel';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useNavigate, useParams } from 'react-router-dom';
import { EnumFolderOrderSupplierStatus, getColorOfFolderOrderSupplierStatus } from '../../classes/FolderOrderSupplierModel';
import ListSearchServer from '../../../components/common/list/ListSearchServer';
import InfoIcon from '@mui/icons-material/Info';
import dayjs from 'dayjs';
import { folderStatusList, getColorOfStatus, getLabelOfStatus } from '../folders/FolderStepper';
import { blue } from '@mui/material/colors';
import { EnumFolderTags } from '../../classes/FolderTagModel';
import EuroIcon from '@mui/icons-material/Euro';
import FormattedAmount from '../../../components/common/formatted/FormattedAmount';
import FolderChecktaskButton from '../folder-checktasks/FolderChecktaskButton';
import FolderChecktasksModal from '../folder-checktasks/FolderChecktaskModal';

enum resaPaymentStatusEnum {
    ALL = 1,
    NO_SUPPLIER = 2,
    NOTHING = 3,
    PARTIAL = 4,
    PAID = 5,
}
const getLabelOfPaymentStatus = (status: resaPaymentStatusEnum) => {
    if (status === resaPaymentStatusEnum.ALL) { return "Tous"; }
    if (status === resaPaymentStatusEnum.NO_SUPPLIER) { return "Pas de transporteur"; }
    if (status === resaPaymentStatusEnum.NOTHING) { return "Rien n’a été payé"; }
    if (status === resaPaymentStatusEnum.PARTIAL) { return "Partiellement payé"; }
    return "Entierement payé";
}

const PaymentStatusInput = (props: {
    value: resaPaymentStatusEnum,
    setValue: React.Dispatch<React.SetStateAction<resaPaymentStatusEnum>>,
    label: string,
    id: string,
    supplier: boolean
}) => {
    return (
        <FormControl fullWidth>
            <InputLabel id={props.id} >{props.label} </InputLabel>
            <Select
                fullWidth
                labelId={props.id}
                id={props.id}
                value={props.value}
                onChange={(e) => { props.setValue((e.target.value as any)); }}
                input={<OutlinedInput label={props.label} />}
                renderValue={(selected) => getLabelOfPaymentStatus(selected)}
            >
                <MenuItem value={resaPaymentStatusEnum.ALL}>{getLabelOfPaymentStatus(resaPaymentStatusEnum.ALL)}</MenuItem>
                { props.supplier && <MenuItem value={resaPaymentStatusEnum.NO_SUPPLIER}>{getLabelOfPaymentStatus(resaPaymentStatusEnum.NO_SUPPLIER)}</MenuItem> }
                <MenuItem value={resaPaymentStatusEnum.NOTHING}>{getLabelOfPaymentStatus(resaPaymentStatusEnum.NOTHING)}</MenuItem>
                <MenuItem value={resaPaymentStatusEnum.PARTIAL}>{getLabelOfPaymentStatus(resaPaymentStatusEnum.PARTIAL)}</MenuItem>
                <MenuItem value={resaPaymentStatusEnum.PAID}>{getLabelOfPaymentStatus(resaPaymentStatusEnum.PAID)}</MenuItem>
            </Select>
        </FormControl>
    )
}

const createDateObjects = (date: Moment, weekOffset = 0) => {
    const startOfMonth = moment(date.toDate()).startOf('month');

    let diff = startOfMonth.weekday() - weekOffset;
    if (diff < 0) diff += 7;

    const prevMonthDays = [];
    for (let i = 0; i < diff; i++) {
        prevMonthDays.push({
            day: startOfMonth.clone().subtract(diff - i, 'days')
        });
    }

    const currentMonthDays = [];
    for (let i = 1; i < date.daysInMonth() + 1; i++) {
        currentMonthDays.push({
            day: moment([date.year(), date.month(), i])
        });
    }

    const daysAdded = prevMonthDays.length + currentMonthDays.length - 1;

    const nextMonthDays = [];
    let i = 1;
    while ((daysAdded + i) % 7 !== 0) {
        nextMonthDays.push({
            day: currentMonthDays[currentMonthDays.length - 1].day
                .clone()
                .add(i, 'days')
        });

        i = i + 1;
    }

    return [...prevMonthDays, ...currentMonthDays, ...nextMonthDays];
}

const QuoteResaCalendarItem = (props: {
    quote: any,
    is_departure: boolean,
    customerPaymentStatusVisible: resaPaymentStatusEnum,
    supplierPaymentStatusVisible: resaPaymentStatusEnum
}) => {
    const { quote, is_departure, customerPaymentStatusVisible, supplierPaymentStatusVisible } = props;

    const navigate = useNavigate();
    const colorFolderStatus = useMemo(() => {

        if (!quote || !quote.order_attribute || !quote.order_attribute.folder) {
            return;
        }

        switch (quote.order_attribute.folder.status) {
            case 4:
                return "gold";
            case 5:
                return "orange";
            case 6:
                return "lightblue";
            case 7:
                return "red";
            case 8:
                return "pink";
            case 9:
                return "lightgreen";
            case 10:
                return "green";
            case 11:
                return "gray";
            default:
                return "lightgray";
        }

    }, [quote]);

    const clientPaymentStatus = useMemo(() => {
        let payments_amount = 0;
        if (Array.isArray(quote.payments)) {
            quote.payments.forEach((payment: any) => {
                payments_amount += payment.total_amount;
            })
        }
        if (payments_amount === 0) {
            return resaPaymentStatusEnum.NOTHING
        }
        if (payments_amount >= quote.total_minus_discount_tax_incl) {
            return resaPaymentStatusEnum.PAID;
        }
        return resaPaymentStatusEnum.PARTIAL;
    },[quote])

    const clientColor = useMemo(() => {
        if (clientPaymentStatus === resaPaymentStatusEnum.NOTHING) {
            return "red";
        }
        if (clientPaymentStatus === resaPaymentStatusEnum.PAID) {
            return "lightgreen";
        }
        return "lightblue";
    }, [clientPaymentStatus]);

    const supplierPaymentStatus = useMemo(() => {
        let total_to_pay = 0;
        let total_paid = 0;
        quote.order_attribute.folder_order_suppliers?.forEach((f: any) => {
            if (f.status === EnumFolderOrderSupplierStatus.CONFIRMED) {
                total_to_pay += f.amount ?? 0;
            }

            f.folder_order_supplier_payments.forEach((p: any) => {
                if (f.status === EnumFolderOrderSupplierStatus.CONFIRMED) {
                    total_paid += p.amount ?? 0;
                }
            });
        });
        const total_to_rest = total_to_pay - total_paid;
        if (total_to_pay === 0) {
            return resaPaymentStatusEnum.NO_SUPPLIER;
        }

        if (total_to_pay === total_to_rest) {
            return resaPaymentStatusEnum.NOTHING;
        }

        if (total_paid >= total_to_pay) {
            return resaPaymentStatusEnum.PAID;
        }

        return resaPaymentStatusEnum.PARTIAL;
    },[quote])

    const folderOrderSupplierColor = useMemo(() => {
        if (supplierPaymentStatus === resaPaymentStatusEnum.NO_SUPPLIER) {
            return "lightgray";
        }
        if (supplierPaymentStatus === resaPaymentStatusEnum.NOTHING) {
            return "red";
        }
        if (supplierPaymentStatus === resaPaymentStatusEnum.PAID) {
            return "lightgreen";
        }
        return "lightblue";
    }, [supplierPaymentStatus]);

    const isFolderTagTransfert = useMemo(() => {
        if (Array.isArray(quote.order_attribute.folder.folder_tags)) {
            const tag = quote.order_attribute.folder.folder_tags.find((tag: any) => tag.tag_id === EnumFolderTags.TRANSFERED);
            return tag ? true : false;
        }
        return false;
    }, [quote]);

    const isVisible = useMemo(() => {

        if(
            customerPaymentStatusVisible != resaPaymentStatusEnum.ALL && 
            customerPaymentStatusVisible != clientPaymentStatus
        ){
            return false;
        }

        if(
            supplierPaymentStatusVisible != resaPaymentStatusEnum.ALL && 
            supplierPaymentStatusVisible != supplierPaymentStatus
        ){
            return false;
        }

        //ALLER
        if (is_departure) {
            return true;
        }
        //RETOUR
        else {
            if (isFolderTagTransfert) {
                return true;
            }
            else {
                return false;
            }
        }
    }, [
        is_departure, 
        isFolderTagTransfert, 
        customerPaymentStatusVisible, 
        supplierPaymentStatusVisible ,
        supplierPaymentStatus,
        clientPaymentStatus
    ]);

    const [open, setOpen] = React.useState(false);
    
    return (
        <>
            {isVisible &&
                <Box display="flex" my={0.2} alignItems={"center"} sx={{
                    fontWeight: "bold",
                    backgroundColor: quote.order_attribute.folder.status >= 11 ? "lightgray" : "white"
                }}>
                    {quote.tag_id}
                    <Box>{is_departure ? "A" : "R"}</Box>
                    -
                    <Box sx={{ color: colorFolderStatus, cursor:"pointer" }}
                        onClick={() => navigate(`/folders/add-edit/${quote.order_attribute.folder_id}`)}
                    >{quote.id}</Box>
                    -
                    <Box px={0.5} sx={{ backgroundColor: clientColor }}>C</Box>
                    -
                    <Box px={0.5} mr={0.5} sx={{ backgroundColor: folderOrderSupplierColor }} >T</Box>

                    {quote.order_attribute.folder.comment &&
                        <Box display="flex" alignItems={"center"} >
                            <Tooltip title={
                                <Box fontSize={13}>
                                    {quote.order_attribute.folder.comment?.split('\n').map((x: any) => <Box>{x}</Box>) ?? ""}
                                </Box>
                            }>
                                <InfoIcon color="primary" />
                            </Tooltip>
                        </Box>
                    }

                    <Box display="flex" alignItems="center">
                        <Tooltip title={
                            <Box fontSize={16}>
                                <Box>{quote.customer.user.last_name} {quote.customer.user.first_name} {quote.customer.dahan_company}</Box>
                                <Box>Devis {quote.id} : <FormattedAmount amount={quote.total_tax_incl} symbol='€' /></Box>

                                {Array.isArray(quote.order_attribute.folder_order_suppliers) && quote.order_attribute.folder_order_suppliers.length > 0 &&
                                    <Box>Fournisseurs : {quote.order_attribute.folder_order_suppliers.map((fos: any) =>
                                    (
                                        <>
                                            {
                                                (fos.status == EnumFolderOrderSupplierStatus.CONFIRMED ||
                                                    fos.status == EnumFolderOrderSupplierStatus.VALIDATED ||
                                                    fos.status == EnumFolderOrderSupplierStatus.CANCELED)
                                                &&
                                                <Box px={1} style={getColorOfFolderOrderSupplierStatus(fos.status)} color={"black"}>{fos.supplier.name} - <FormattedAmount amount={fos.amount} symbol='€' /></Box>
                                            }
                                        </>
                                    )
                                    )}
                                    </Box>
                                }
                            </Box>
                        }>
                            <Box>
                                <IconButton size="small" >
                                    <EuroIcon fontSize="small" />
                                </IconButton>
                            </Box>
                        </Tooltip>
                    </Box>
                    <FolderChecktaskButton id={quote.order_attribute.folder_id} small />
                </Box>
            }
        </>

    )
}

interface QuoteResaListModel extends ListModelCore {
    quotations: any[]
}

export function QuoteResaCalendarLegend() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            <Box><Button variant="contained" onClick={handleOpen}>Legende</Button></Box>
            <Modal open={open} onClose={handleClose}>
                <Container component="main" maxWidth="xs" sx={{ height: "100%", mt: 2, p: 0 }}>
                    <Paper>
                        <Box px={2} py={1}>
                            <Typography variant="h5" >
                                Légende
                            </Typography>
                        </Box>
                        <Divider />
                        <Box p={2}>
                            <Typography variant="h6" >
                                Couleur du n° de dossier :
                            </Typography>
                            <Typography sx={{ mt: 1 }}>
                                {folderStatusList.map((folderStatus) => (
                                    folderStatus.id > 3 &&
                                    <Box color={getColorOfStatus(folderStatus.id)}>
                                        {getLabelOfStatus(folderStatus.id)}
                                    </Box>
                                )
                                )}
                            </Typography>
                            <Box mt={2}>
                                <Divider />
                            </Box>
                            <Typography sx={{ mt: 2 }} variant="h6" component="h2">
                                Client et Transporteur C & T :
                            </Typography>
                            <Typography sx={{ mt: 1 }}>
                                <Box px={1} sx={{ backgroundColor: "lightgray" }}>Aucun transporteur</Box>
                                <Box mt={1} px={1} sx={{ backgroundColor: "red" }}>Rien n’a été payé</Box>
                                <Box mt={1} px={1} sx={{ backgroundColor: "lightblue" }} >Payé partiellement</Box>
                                <Box mt={1} px={1} sx={{ backgroundColor: "lightgreen" }} >Entièrement payé</Box>
                            </Typography>
                        </Box>
                    </Paper>
                </Container>
            </Modal>
        </div>
    );
}

const QuoteResaCalendar = () => {
    const [date, setDate] = useState(moment());

    const [range, setRange] = useState<{ day: moment.Moment; }[]>([]);
    const [dateRange, setDateRange] = useState<{ start: string, end: string } | undefined>(undefined);
    const [quotes, setQuotes] = useState<any[]>([]);

    const routeApiController = "project-dahan/dahan/quotations";
    const [getData] = useGetListCore<QuoteResaListModel>(`/${routeApiController}/index-resa-calendar`);
    const [search, setSearch] = useState<ListSearchModelCore | undefined>({} as any);
    const [loading, setLoading] = useState(false);
    const weekOffset = 1;


    const [customerPaymentStatusVisible, setCustomerPaymentStatusVisible] = useState(resaPaymentStatusEnum.ALL);
    const [supplierPaymentStatusVisible, setSupplierPaymentStatusVisible] = useState(resaPaymentStatusEnum.ALL);

    const renderDay = useCallback((day: Moment) => {

        if (quotes.length === 0) { return (<></>) }

        let quotesOfDayDeparture = quotes.filter((q) =>
            Array.isArray(q.travels) &&
            q.travels.length > 0 &&
            q.travels[0].order_product_attribute &&
            (
                moment(q.travels[0].order_product_attribute.departure_date).format('YYYY-MM-DD') === day.format('YYYY-MM-DD')
            )
        );

        let quotesOfDayReturn = quotes.filter((q) =>
            Array.isArray(q.travels) &&
            q.travels.length > 0 &&
            q.travels[0].order_product_attribute &&
            (
                moment(q.travels[0].order_product_attribute.return_date).format('YYYY-MM-DD') === day.format('YYYY-MM-DD')
            )
        );

        return (
            <Box>
                {quotesOfDayDeparture.map((q: any) => (
                    <Box key={`${q.id}_departure`}>
                        <QuoteResaCalendarItem
                            quote={q}
                            is_departure
                            customerPaymentStatusVisible={customerPaymentStatusVisible}
                            supplierPaymentStatusVisible={supplierPaymentStatusVisible}
                        />
                    </Box>
                ))}

                {quotesOfDayReturn.map((q: any) => (
                    <Box key={`${q.id}_return`}>
                        <QuoteResaCalendarItem
                            quote={q}
                            is_departure={false}
                            customerPaymentStatusVisible={customerPaymentStatusVisible}
                            supplierPaymentStatusVisible={supplierPaymentStatusVisible}
                        />
                    </Box>
                ))}
            </Box>
        )

    }, [quotes, customerPaymentStatusVisible, supplierPaymentStatusVisible]);

    const handleNextMonth = useCallback(() => {
        const newDate = date.clone().add(1, 'months');
        setDate(newDate);
    }, [date]);

    const handlePrevMonth = useCallback(() => {
        const newDate = date.clone().subtract(1, 'months');
        setDate(newDate);
    }, [date]);

    const refreshWithSearch = useCallback((search: ListSearchModelCore | undefined, dateRange?: { start: string, end: string }) => {
        setLoading(true); // Set loading to true before fetching data

        if (!dateRange || !search) {
            return;
        }

        search.date_calendar_start = {
            value: dateRange.start,
            html: { field: "date" }
        } as any;
        search.date_calendar_end = {
            value: dateRange.end,
            html: { field: "date" }
        } as any;


        getData(1000, 0, search, false, false)
            .then((res) => {
                if (res.status === ReqStatus.SUCCESS && res.data) {
                    setQuotes(res.data.quotations ?? []);
                    setSearch(res.data.search);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false); // Set loading to false after the data has been fetched
            });
    }, [getData, dateRange]);


    const refresh = useCallback((dateRange?: { start: string, end: string }) => {
        refreshWithSearch(search, dateRange);
    }, [search]);

    useEffect(() => {
        setDate(moment());
    }, [])

    useEffect(() => {
        const range = createDateObjects(date, weekOffset);
        if (Array.isArray(range) && range.length > 1) {
            setRange(range);
            setDateRange({
                start: range[0].day.format('YYYY-MM-DD'),
                end: range[range.length - 1].day.format('YYYY-MM-DD'),
            });
        }
    }, [date])

    useEffect(() => {
        if (dateRange) {
            refresh(dateRange);
        }
    }, [dateRange]);

    const getTitleDay = useCallback((day: moment.Moment) => {
        return dayjs(day.format('YYYY-MM-DD')).tz().format('dddd DD/MM/YY');
    }, [])

    const colorCase = (day: moment.Moment) => {
        if (day.isSame(moment(), 'day')) {
            return '#9acfff';
        }

        if (day.isAfter(moment().add(50, 'day'))) {
            return "#FFC0CB40";
        }

        return "transparent";
    }


    return (
        <Box height={"100%"} overflow={"auto"}>
            <FolderChecktasksModal />
            <Box m={2}>
                {
                    search &&
                    <ListSearchServer
                        search={search}
                        refreshWithSearch={(search: ListSearchModelCore | undefined, csv?: boolean, pdf?: boolean) => refreshWithSearch(search, dateRange)}
                        setSearch={setSearch}
                        refresh={(csv?: boolean | undefined, pdf?: boolean | undefined, reinit?: boolean | undefined) => refresh(dateRange)} />
                }


                <Box mt={2}>
                    <Card>
                        <Box display={"flex"}>
                            <Box display={"flex"} alignItems={"center"} m={2}>
                                <Box><QuoteResaCalendarLegend /></Box>
                                <Box ml={2} width={200}>
                                    <PaymentStatusInput
                                        value={customerPaymentStatusVisible}
                                        setValue={setCustomerPaymentStatusVisible}
                                        label='Client - état paiement'
                                        id='customer-payment-status'
                                        supplier={false}
                                    />
                                </Box>
                                <Box ml={2} width={200}>
                                    <PaymentStatusInput
                                        value={supplierPaymentStatusVisible}
                                        setValue={setSupplierPaymentStatusVisible}
                                        label='Fournisseur - état paiement'
                                        id='supplier-payment-status'
                                        supplier={true}
                                    />
                                </Box>
                            </Box>
                            <Box display={"flex"} ml={5}>
                                <Box display={"flex"} alignItems={"center"}>
                                    <Box><IconButton onClick={() => handlePrevMonth()}><KeyboardDoubleArrowLeftIcon fontSize='large' /></IconButton></Box>
                                    <Box><Typography variant="h5"> {date.format('MM/YYYY')}</Typography></Box>
                                    <Box><IconButton onClick={() => handleNextMonth()}><KeyboardDoubleArrowRightIcon fontSize='large' /></IconButton></Box>
                                </Box>
                            </Box>
                            <Box display={"flex"} width={120}></Box>
                        </Box>
                        {loading && <Skeleton variant="rectangular" height={600} />}
                        {!loading &&

                            <Box display="flex" flexWrap={"wrap"} m={2} border={"1px solid gray"}>

                                {range.map((day, i) =>
                                (
                                    <Box key={day.day.format('DD_MM_YY')} width="14.285%" display={"flex"} justifyContent={"flex-start"} alignItems={"top"} border={"1px solid gray"} px={1} style={{ backgroundColor: colorCase(day.day) }}>
                                        <Box width={"100%"}>
                                            <Box display={"flex"} justifyContent={"center"}>
                                                {getTitleDay(day.day)}
                                            </Box>
                                            <Box height={105} overflow={"auto"}>
                                                {renderDay(day.day)}
                                            </Box>
                                        </Box>
                                    </Box>
                                )
                                )}
                            </Box>
                        }
                    </Card>
                </Box>

            </Box>
        </Box >
    )
}

export default QuoteResaCalendar;