import {
    FormControl,
    FormHelperText,
    TextField,
    Autocomplete
} from "@mui/material";
import { useEffect, useState } from "react";
import { IAncestorInput, useField } from "react-recoil-form";
import AutocompleteCore from "../input/AutocompleteCore";
interface IFieldProps {
    name: string;
    ancestors?: IAncestorInput[];
    label?: string;
    required?: boolean;
    disabled?: boolean;
    options : AutocompleteOption[];
    className?: string;
    defaultIndex ?: number;
}

export interface AutocompleteOption {
    id: number | string,
    label: string
}

export default function AutocompleteField(props: IFieldProps) {
    const { name, ancestors, label, required, disabled, className, options, defaultIndex} = props;
    const { fieldValue, setFieldValue, error } = useField({
        ancestors,
        name,
        validate: (value) => {
            if (required && !value) {
                return `Le champ ${label} est requis`;
            }
        }
    });

    const [value, setValue] = useState<AutocompleteOption | null>(null);
    useEffect(() =>{
        const defaultValue = options.find( x => x.id === fieldValue);
        let defaultIndexValue = null;
        if (defaultIndex !== undefined && options[defaultIndex]) defaultIndexValue = options[defaultIndex];
        setValue(defaultValue ? defaultValue : defaultIndexValue ? defaultIndexValue : null);
        if ((fieldValue === null || fieldValue === undefined ) && defaultIndexValue) setFieldValue(defaultIndexValue.id);
    },[fieldValue, options, value])

    return (
        <FormControl error={!!error} fullWidth >
            <AutocompleteCore
                value={value}
                onChange={(newValue) => { setFieldValue(newValue?.id ?? null) }}
                options={options}
                label={label}
                required={required}
                disabled={disabled}
                className={className}
            />
            {error && <FormHelperText id="my-helper-text">{error}</FormHelperText>}
        </FormControl>
    );
}
