import { useNavigate, useParams } from "react-router-dom";
import { useAddEditForm } from "../common/form/AddEditFormPanel";
import { ReqStatus } from "../../stores/core/UseApi";
import { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import InputField from "../common/form/InputField";
import { useAddEditCore } from "../../stores/core/StoreCore";
import AddEditForm from "../common/form/AddEditForm";
import AddEditFormCard from "../common/form/AddEditFormCard";

const GenderView = () => {
    const listRoute = "/commercial/genders";
    const { getData, addEditData } = useAddEditCore<any, { gender: any }>(`${listRoute}/add-edit`);

    let { id } = useParams();
    const navigate = useNavigate();

    const onSubmit = (request: any) => {
        addEditData(request, id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                navigate(listRoute);
            }
        })
    }

    const { handleSubmit, resetInitialValues, isDirty } = useAddEditForm({ onSubmit, initialValues: { id: "", label: "", diminutive: "" } });
    useEffect(() => {
        getData(id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                if (id) {
                    const _gender = res.data.gender;
                    resetInitialValues({
                        id: _gender.id,
                        label: _gender.label,
                        diminutive: _gender.diminutive
                    });
                }
            } else {
                navigate(listRoute);
            }
        })
    }, [id]);

    return (
        <AddEditForm  formId={"genderForm"} formHandleSubmit={handleSubmit} formHandleCancel={resetInitialValues} isDirty={isDirty} >
            <AddEditFormCard title={(!id ? "Ajouter " : "Modifier") + " un genre"} >
                <Grid container spacing={3} >
                    <Grid item xs={12} sm={6}>
                        <InputField name="id" label="ID" type="text" required disabled={id ? true : false} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputField name="label" label="Label" type="text" required />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputField name="diminutive" label="Diminutif" type="text" required />
                    </Grid>
                </Grid>

            </AddEditFormCard>
        </AddEditForm>
    )
}

export default GenderView;