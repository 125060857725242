import { GridColDef } from "@mui/x-data-grid";
import { CustomerTypeListModel,  } from "../../stores/CustomersStore";

import { useGetListCore } from "../../stores/core/StoreCore";
import ListCore from "../common/list/ListCore";


const columns: GridColDef[] = [
    {
        field: 'id',
        headerName: "Id",
        editable: false,
        filterable: false,
        width: 50,
    },
    {
        field: 'label',
        headerName: "Nom",
        editable: false,
        filterable: false,
        width: 300,
    },
];


const CustomerTypeList = () => {

    const routeController = "commercial/customer-types";
    const [get] = useGetListCore<CustomerTypeListModel>(`/${routeController}/index`);

    return (
        <ListCore<CustomerTypeListModel, any>
            getDatas={get}
            defineDatasToSet={(data) => data.customerTypes}
            getRowId={(row) => row.id}
            columns={columns}
            actions={{
                defaultRoute: routeController,
                add: {
                    title: "Ajouter un type de client"
                },
                edit: true,
                remove: false
            }}
        />
    );
}

export default CustomerTypeList;