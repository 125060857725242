import { useCallback } from "react";
import { useDelete } from "../../stores/core/StoreCore";
import { useApi } from "../../stores/core/UseApi";


const routeController = 'project-dahan/dahan/folder-tags';

export const useDeleteFolderTag = () => {
    return useDelete(routeController);
}

export const useInsertFolderTags = () => {
    const [callCore, status] = useApi<undefined, { folder_id : number, tag_ids: number[] }>(
        {
            route:  `${routeController}/insert-tags`,
            instanceAxiosMethod: "POST"
        }
    );
    const call = useCallback((folder_id: number, tag_ids: number[]) => {
        return callCore({ folder_id, tag_ids } );
    }, [callCore]);
    return { call, status };
};
