import { useCallback, useMemo } from "react";
import { Box, Divider, Grid, IconButton, Tooltip } from "@mui/material";
import { EnumFolderOrderSupplierStatus, FolderOrderSupplierModel, getColorOfFolderOrderSupplierStatus } from "../../classes/FolderOrderSupplierModel";
import MailIcon from "@mui/icons-material/Mail";
import FolderOrderSupplierActions from "./FolderOrderSupplierActions";
import RefreshIcon from '@mui/icons-material/Refresh';
import SupplierTooltip from "../suppliers/SupplierTooltip";
import InfoIcon from '@mui/icons-material/Info';
import { useRecoilValue, useSetRecoilState } from "recoil";
import { FolderOrderSupplierCommentModalState } from "../../states/FolderOrderSuppliersState";
import FormattedAmount from "../../../components/common/formatted/FormattedAmount";
import { IsNotUserCommercialSelector, UserExtenalSelector } from "../../states/UserState";

interface FolderOrderSupplierItemProps {
	folderOrderSupplier: FolderOrderSupplierModel,
	deleteEvent: (id: number) => void,
	updateStatusEvent: (id: number, status: EnumFolderOrderSupplierStatus) => void,
	emailEvent: (id: number) => void,
}

const FolderOrderSupplierItem = (props: FolderOrderSupplierItemProps) => {

	const { folderOrderSupplier, deleteEvent, updateStatusEvent, emailEvent } = props;

	const updateStatus = useCallback((status: EnumFolderOrderSupplierStatus) => {
		updateStatusEvent(folderOrderSupplier.id, status);
	}, [updateStatusEvent, folderOrderSupplier.id]);

	const setCommentModal = useSetRecoilState(FolderOrderSupplierCommentModalState);

	const referenceCommentTooltip = useMemo(() => {

		if (!folderOrderSupplier.reference && !folderOrderSupplier.comment) {
			return "";
		}

		return (
			<Box>
				{folderOrderSupplier.reference && <Box mb={1.5}>{`Référence: ${folderOrderSupplier.reference}`}</Box>}

				<Box>{folderOrderSupplier.comment ? folderOrderSupplier.comment?.split('\n').map((x: any) => <Box>{x}</Box>) : ""}</Box>
			</Box>
		);
	}, [folderOrderSupplier.reference, folderOrderSupplier.comment]);

	const colorInfoIcon = useMemo(() => {
		if (folderOrderSupplier.comment && folderOrderSupplier.reference) {
			return "success";
		} else if (folderOrderSupplier.comment) {
			return "primary";
		} else if (folderOrderSupplier.reference) {
			return "secondary";
		} else {
			return "disabled"
		}
	}, [folderOrderSupplier.comment, folderOrderSupplier.reference]);

	const isNotUserCommercial = useRecoilValue(IsNotUserCommercialSelector);
	const isExternal = useRecoilValue(UserExtenalSelector);

	return (
		<Grid container display={"flex"} alignItems={"center"} my={1} sx={getColorOfFolderOrderSupplierStatus(folderOrderSupplier.status)}>

			<Grid lg={10} display="flex">

				{isNotUserCommercial &&
					<Box display="flex" mx={1} alignItems={"center"}>
						<Tooltip
							title={referenceCommentTooltip}
							onClick={() => {
								setCommentModal({
									id: folderOrderSupplier.id,
									open: true
								})
							}
							}>
							<InfoIcon color={colorInfoIcon} fontSize="large" />
						</Tooltip>
					</Box>
				}
				<Box display="flex" flexDirection={"column"} width={"100%"}>
					<Box>
						{isNotUserCommercial ?
							<SupplierTooltip
								supplier={folderOrderSupplier.supplier}
							>
								<Box>{folderOrderSupplier.supplier.name}</Box>
							</SupplierTooltip>
							:
							<Box>Fournisseur #{folderOrderSupplier.supplier.id}</Box>
						}
					</Box>
					<Grid container xs={12} lg={12} display="flex" justifyContent={"space-between"} >
						<Box>Devis #{folderOrderSupplier.order.id}</Box>
						{!isExternal &&
							<Box>{ folderOrderSupplier.amount !== null && <FormattedAmount amount={folderOrderSupplier.amount} symbol="€" />}</Box>
						}
					</Grid>
				</Box>

			</Grid>

			{folderOrderSupplier.status === EnumFolderOrderSupplierStatus.CONFIRMED && isNotUserCommercial &&
				<Grid item xs={3} lg={2} display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
					<Box mr={0.5}>
						<IconButton size="small" onClick={() => updateStatus(EnumFolderOrderSupplierStatus.INIT)} >
							<RefreshIcon fontSize="small" />
						</IconButton>
					</Box>
				</Grid>
			}
			{folderOrderSupplier.status !== EnumFolderOrderSupplierStatus.CONFIRMED && isNotUserCommercial &&
				<Grid item xs={3} lg={2} display={"flex"} alignItems={"center"} justifyContent={"flex-end"} flexWrap={"wrap"}>
					<IconButton size="small" onClick={() => emailEvent(folderOrderSupplier.id)} color={folderOrderSupplier.email_send ? "success" : "info"} ><MailIcon fontSize="small" /></IconButton>
					<FolderOrderSupplierActions
						updateStatus={updateStatus}
						deleteEvent={() => deleteEvent(folderOrderSupplier.id)}
					/>
				</Grid>
			}
		</Grid>
	)
}

export default FolderOrderSupplierItem;