import { Box, Button, Divider, Grid, Paper, Typography } from "@mui/material";
import { PropsWithChildren, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, useIsDirty } from "react-recoil-form";
import { useSetRecoilState } from "recoil";
import { NavigationBlockedState } from "../../../states/MenuState";
import AddEditFormHeader from "./AddEditFormHeader";
import { InitialValuesFormState } from "../../../states/FormState";

export const useAddEditForm = (
    formProps : { 
        onSubmit : (values: any, extraInfos?: any) => any,
        initialValues? : any,
    },
    isDirtyCallback? : (isDirty: boolean) => boolean
) => {
    const { handleSubmit, resetInitialValues } = useForm(formProps);

    const isDirty = useIsDirty();
    const setInitialValuesForm = useSetRecoilState(InitialValuesFormState);
    const setNavigationBlocked = useSetRecoilState(NavigationBlockedState);
    useEffect(() => {
        if(isDirtyCallback !== undefined){
            setNavigationBlocked(isDirtyCallback(isDirty));
        }else{
            setNavigationBlocked(isDirty);
        }
    },[isDirtyCallback, isDirty, setNavigationBlocked]);

    useEffect(() => {
        return function cleanup(){
            setNavigationBlocked(false);
        }
    },[setNavigationBlocked])

    useEffect(() => {
        //cleanup
        return () => {
            setInitialValuesForm(undefined);
        }
    },[]);

    const resetInitialValuesCore = useCallback((values?: any, extraInfos?: any)  => {
        setInitialValuesForm(values);
        resetInitialValues(values, extraInfos);
    },[resetInitialValues])

    return { 
        isDirty : isDirtyCallback ? isDirtyCallback(isDirty) : isDirty, 
        handleSubmit, 
        resetInitialValues: resetInitialValuesCore
    };
}

interface AddEditFormPanelProps extends PropsWithChildren {
    listRoute?: string,
    title: string
}

const AddEditFormPanel = (props: AddEditFormPanelProps) => {

    const { listRoute, title, children } = props;

    return (
        <Box m={2}>
            <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                <AddEditFormHeader listRoute={listRoute} title={title} />
                {children}
            </Paper>
        </Box>
    );
}

export default AddEditFormPanel;