import GroupAuthorizationView from "../../components/group-authorizations/GroupAuthorizationView";
import UserGroupList from "../../components/user-groups/UserGroupList";
import UserGroupView from "../../components/user-groups/UserGroupView";
import ProfileView from "../../components/users/ProfileView";
import UserList from "../../components/users/UserList";
import UserPasswordView from "../../components/users/UserPasswordView";
import UserView from "../../components/users/UserView";
import { RouteItem, SectionItem } from "../AppRoutes";

import GroupIcon from '@mui/icons-material/Group';
import KeyIcon from '@mui/icons-material/Key';

export const UserListRoute: RouteItem = {
    index: "USER_LIST",
    path: "/users",
    title: "Utilisateurs",
    element: () => <UserList />,
    menuVisible: true,
    menuIcon: <GroupIcon />,
    authorization: {
        controller: "Users",
        action: "index"
    }
}

export const UserGroupListRoute: RouteItem = {
    index: "USER_GROUP_LIST",
    path: "/user-groups",
    title: "Rôles",
    element: () => <UserGroupList />,
    menuVisible: true,
    menuIcon: <KeyIcon />,
    authorization: {
        controller: "UserGroups",
        action: "index"
    }
};

export const UserGroupViewRoute: RouteItem = {
    index: "USER_GROUP_LIST",
    path: "/user-groups/add-edit/:id?",
    title: "Rôles",
    element: () => <UserGroupView />,
    menuVisible: false,
    authorization: {
        controller: "UserGroups",
        action: "addEdit"
    }
};

export const GroupAuthorizationsViewRoute = {
    index: "USER_GROUP_LIST",
    path: "/group-authorizations/view/:id",
    title: "Rôles",
    element: () => <GroupAuthorizationView />,
    menuVisible: false,
    authorization: {
        controller: "GroupAuthorizations",
        action: "view"
    }
}

export const UserProfileRoute: RouteItem = {
    index: "USER_PROFILE",
    title: "Profil",
    path: "/users/profile",
    element: () => <ProfileView />,
    menuVisible: false,
    authorization: null
}

export const UserViewRoute: RouteItem = {
    index: "USER_ADDEDIT",
    title: "Utilisateurs",
    path: "/users/add-edit/:id?/:from?",
    element: () => <UserView />,
    menuVisible: false,
    authorization: {
        controller: "Users",
        action: "addEdit"
    }
}

export const UserEditPasswordViewRoute: RouteItem = {
    index: "USER_EDIT_PASSWORD",
    title: "Utilisateurs",
    path: "/users/edit-password/:id",
    element: () => <UserPasswordView />,
    menuVisible: false,
    authorization: {
        controller: "Users",
        action: "editPassword"
    }
};

export const UserSectionRoutes = {
    title: "Gestion des utilisateurs",
    index: "USER",
    children: [
        UserListRoute,
        UserGroupListRoute,
        UserGroupViewRoute,
        GroupAuthorizationsViewRoute,
        UserProfileRoute,
        UserViewRoute,
        UserEditPasswordViewRoute
    ]
} as SectionItem;
