import { Box, LinearProgress } from "@mui/material";
import Image from 'mui-image';
import { LogoPathState } from "../states/MenuState";
import { useRecoilValue } from "recoil";

const AppLoader = () => {

    const logoPath = useRecoilValue(LogoPathState);

    return (
        <Box sx={{ backgroundColor: "white" }} height={"100vh"} display="flex" justifyContent={"center"} alignItems="center">
            <Box>
                {logoPath && <Image width="300px" src={logoPath} errorIcon={null} />}
                <LinearProgress color='primary' />
            </Box>
        </Box>
    );
}

export default AppLoader;