import { unstable_useBlocker as useBlocker } from "react-router-dom";

import { useCallback, useEffect, useMemo } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";

const ConfirmNavigationModal = (props: { isBlocked: boolean} ) => {
    const {isBlocked} = props;

    let blocker = useBlocker(isBlocked);

    //surchage de l'évenement before unload pour etre declenché seulement quand la variable "when est true"
    const listener = function (e: BeforeUnloadEvent) {
        if (isBlocked) {
            // Cancel the event as stated by the standard.
            e.preventDefault();
            e.returnValue = '';
            return true;
        }
    };

     //detect quand le when change on change la fonction before unload
     useEffect(() => {
        window.onbeforeunload = listener;
    }, [isBlocked]);

    useEffect(() => {
        return function cleanup() {
            window.onbeforeunload = () => undefined;
        };
    }, []);

    useEffect(() => {
        if (blocker.state === "blocked" && !isBlocked) {
            blocker.reset();
        }
    }, [blocker, isBlocked]);

    const openModel = useMemo(() => {
        return blocker.state === "blocked";
    }, [blocker.state]);

    const handleClose = useCallback(() => {
        blocker.reset?.();
    }, [blocker]);

    const handleConfirm = useCallback(() => {
        blocker.proceed?.()
    }, [blocker]);

    return (
        <Dialog open={openModel} onClose={handleClose}>
            <DialogTitle>Modifications en cours</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Êtes-vous sûr de vouloir quitter la page ? Toutes les modifications
                    en cours seront perdues.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Annuler</Button>
                <Button onClick={handleConfirm} variant="contained" color="primary">
                    Valider
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmNavigationModal;