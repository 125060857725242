import { useCallback, useMemo } from 'react';
import { Box, IconButton } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useGetProformaList, useGetQuoteList } from "../../stores/FoldersStore";
import { useEffect, useState } from "react";
import { ReqStatus } from "../../../stores/core/UseApi";
import VisibilityIcon from '@mui/icons-material/Visibility';
import FormattedAmount from '../../../components/common/formatted/FormattedAmount';

const FolderProformaList = (props: { folderId: string }) => {
    const [forceRefresh, setForceRefresh] = useState(0);
    const { folderId } = props;
    const get = useGetProformaList();
    const [rows, setRows] = useState<any[]>([]);

    const getDatas = useCallback(() => {
        if (isNaN(+folderId)) { return; }
        get(folderId).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                setRows(res.data.quotes ?? [])
            }
        })
    }, [folderId]);

    const openPdf = useCallback((id: string) => {
        window.open(`${process.env.REACT_APP_API}project-dahan/dahan/quotations/view-proformat/${id}.pdf`, '_blank');
    }, []);

    const columns: GridColDef[] = useMemo(() => {
        return [
            {
                field: 'id',
                headerName: "Devis N°",
                editable: false,
                filterable: false,
                minWidth: 100,
                valueGetter: ({row}) => `#${row.id}`
            },
            {
                field: 'reference',
                headerName: "Titre",
                editable: false,
                filterable: false,
                minWidth: 300
            },
            {
                field: 'total_minus_discount_tax_excl',
                headerName: "Total HT",
                editable: false,
                filterable: false,
                minWidth: 100,
                renderCell: ({ row }) => <Box display={"flex"} justifyContent={"flex-end"} width={"100%"}><FormattedAmount amount={row.total_minus_discount_tax_excl} symbol="€"/></Box>
            },
            {
                field: 'total_minus_discount_tax_incl',
                headerName: "Total TTC",
                editable: false,
                filterable: false,
                minWidth: 100,
                renderCell: ({ row }) => <Box display={"flex"} justifyContent={"flex-end"} width={"100%"}><FormattedAmount amount={row.total_minus_discount_tax_incl} symbol="€"/></Box>
            },
            {
                field: 'actions',
                headerName: "Actions",
                editable: false,
                filterable: false,
                minWidth: 120,
                renderCell: ({ row }) => <Box><IconButton onClick={() => openPdf(row.id)}><VisibilityIcon /></IconButton></Box>
            }
        ]
    }, [openPdf]);


    useEffect(() => {
        getDatas();
    }, [getDatas]);

    return (
        <Box height={371}>
            <DataGrid
                key={forceRefresh}
                disableColumnFilter
                disableRowSelectionOnClick
                //autoPageSize
                filterMode="client"
                rows={rows}
                columns={columns}
                getRowId={(row: any) => row.id}
                hideFooter
            />
        </Box >
    )
};
export default FolderProformaList;