import { useNavigate, useParams } from "react-router-dom";
import { useAddEditCore } from "../../../stores/core/StoreCore";
import { ReqStatus } from "../../../stores/core/UseApi";
import { useAddEditForm } from "../../../components/common/form/AddEditFormPanel";
import { useEffect, useState } from "react";
import AddEditForm from "../../../components/common/form/AddEditForm";
import AddEditFormCard from "../../../components/common/form/AddEditFormCard";
import { Box, Grid } from "@mui/material";
import DateField from "../../../components/common/form/DateField";
import SelectField, { SelectOptionsProps } from "../../../components/common/form/SelectField";
import { UserTimetrackerModel } from "../../classes/UserTimetrackerModel";
import { createOptionsForSelect } from "../../../utils/DataUtils";

const UserTimetrackerView = () => {
    const listRoute = "/project-dahan/dahan/user-timetrackers";
    const { getData, addEditData } = useAddEditCore<any, { userTimetracker: UserTimetrackerModel, users: any }>(`${listRoute}/add-edit`);
    const [users, setUsers] = useState<SelectOptionsProps[]>([]);

    let { id } = useParams();
    const navigate = useNavigate();

    const onSubmit = (request: any) => {
        addEditData(request, id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                navigate('/user-timetrackers');
            }
        })
    }

    const { handleSubmit, resetInitialValues, isDirty } = useAddEditForm({ onSubmit });
    useEffect(() => {
        getData(id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                setUsers(createOptionsForSelect(res.data.users));
                if (id) {
                    resetInitialValues({ ...res.data.userTimetracker });
                }
            } else {
                navigate('/user-timetrackers');
            }
        })
    }, [id]);

    return (

        <AddEditForm
            formId="userTimetrackerForm"
            formHandleCancel={resetInitialValues}
            formHandleSubmit={handleSubmit}
            isDirty={isDirty}
        >
            <AddEditFormCard title={(!id ? "Ajouter " : "Modifier") + " un temps de travail"} maxWidth="md">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <SelectField
                            name="user_id"
                            label="Utilisateur"
                            required
                            options={users}
                            disabled={!!id}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex">
                            <DateField
                                name="start"
                                label="Début"
                                type="datetime"
                                required
                            />
                            <DateField
                                name="end"
                                label="Fin"
                                type="datetime"
                                required
                            />
                        </Box>
                    </Grid>
                </Grid>
            </AddEditFormCard>
        </AddEditForm>
    )
}

export default UserTimetrackerView;