import { atom, selector } from "recoil";

export enum addUserOnEntityType{
    COMMERCIAL_FOLDER,
    GESTIONNER_QUOTE,
    COMMERCIAL_QUOTE
}

export const AddUserOnEntityModalState = atom<{ open: boolean, ids: number[], onValidate: () => void, type: addUserOnEntityType | undefined }>({
    key: 'AddUserOnEntityModalKey',
    default: {
        open: false,
        ids: [],
        onValidate: () => { },
        type: undefined
    }
});

export const FolderCustomerState = atom<any>({
    key: 'FolderCustomerKey',
    default: undefined
});

export const FolderCustomerTypesState = atom<any>({
    key: 'FolderCustomerTypesKey',
    default: undefined
});

export const FolderCustomerEmailSelector = selector({
    key: 'FolderCustomerEmailSelector',
    get: ({get}) => get(FolderCustomerState)?.user.email ?? "",
});
  
export const FolderFinalStatusModalState = atom<{ open: boolean, onValidate: (is_closure: boolean, closure_pattern: string) => void}>({
    key: 'FolderFinalStatusModalKey',
    default: {
        open: false,
        onValidate: (is_closure: boolean, closure_pattern: string) => {}
    }
});

export const OthersCustomerFolderStatusGroupedState = atom<{
    ready: boolean,
    inProgress: number,
    onHold: number,
    finished: number
}>({
    key: 'OthersCustomerFolderStatusGroupedKey',
    default: {
        ready: false,
        inProgress: 0,
        onHold: 0,
        finished: 0
    }
});
