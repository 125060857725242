import { Box } from "@mui/material";
import AddEditFormPanel from "../../../components/common/form/AddEditFormPanel";
import AddEditFormSubmit from "../../../components/common/form/AddEditFormSubmit";
import { ReqStatus } from "../../../stores/core/UseApi";
import { useEffect, useMemo } from "react";
import { useSendMail } from "../../stores/MailsStore";
import { useField, useForm } from "react-recoil-form";
import { BrevoMailConfigsState } from "../../states/MailState";
import { useRecoilState, useRecoilValue } from "recoil";
import { FolderCustomerState } from "../../states/FoldersState";
import { BrevoMailConfigsListModel } from "../../stores/BrevoMailStore";
import { useGetListCore } from "../../../stores/core/StoreCore";
import SendMailFormEntity from "./SendMailFormEntity";
import SendMailFormRecipient from "./SendMailFormRecipient";
import SendMailFormContent from "./SendMailFormContent";
import SendMailFormTemplate from "./SendMailFormTemplate";

interface SendMailFormProps {
	folderId: number,
	templateIdDefault: number | undefined,
	entityIdDefault: number | undefined,
	emails: string[],
	onCloseEvent: () => void
	onValidateEvent?: () => void
}

const SendMailForm = (props: SendMailFormProps) => {

	//PROPS AND USE
	const { onValidateEvent, onCloseEvent, emails, folderId, templateIdDefault, entityIdDefault } = props;
	const customer = useRecoilValue(FolderCustomerState);
	const sendMailAjax = useSendMail();
	const templates = useRecoilValue(BrevoMailConfigsState);

	//TEMPLATE SELECTED
	const { fieldValue: templateIdValue } = useField({ name: "template_id" });
	const templateSelected = useMemo(() => {
		return templates.find((x) => x.id === templateIdValue);
	}, [templates, templateIdValue]);

	//SUBJECT
	const { setFieldValue: setSubject } = useField({ name: "subject" });
	useEffect(() => {
		if (!templateSelected) {
			setSubject("");
			return;
		}
		setSubject(templateSelected.subject);

	}, [templateSelected])

	//SUBMIT FORM
	const { handleSubmit, resetInitialValues } = useForm({
		onSubmit: (values: any) => {
			sendMailAjax.call(values).then((res) => {
				if (res.status === ReqStatus.SUCCESS) {
					if (onValidateEvent) { onValidateEvent(); }
					onCloseEvent();
				}
			});
			return true;
		}
	});

	//INITIALIZE
	useEffect(() => {
		const _emails = (Array.isArray(emails) && emails.length > 0) ? emails.join(';') : '';
		resetInitialValues({
			folder_id: folderId,
			template_id: templateIdDefault,
			entity_id: entityIdDefault,
			emails: _emails
		})
	}, []);

	return (
		<AddEditFormPanel
			title={"Envoi email"}
		>
			<form id={"mailForm"} onSubmit={handleSubmit} >
				<Box sx={{ overflowY: "auto", maxHeight: 600 }}>

					<SendMailFormTemplate
						templateIdDefault={templateIdDefault}
					/>

					<SendMailFormEntity
						folderId={folderId}
						templateIdDefault={templateIdDefault}
						templateSelected={templateSelected}
						entityIdDefault={entityIdDefault}
					/>

					<SendMailFormRecipient
						customer={customer}
						templateSelected={templateSelected}
					/>

					<SendMailFormContent
						templateSelected={templateSelected}
					/>

				</Box>
				<Box mt={2}>
					{sendMailAjax.status !== ReqStatus.LOADING &&
						<AddEditFormSubmit
							returnAction={{ disabled: true }}
							cancelEvent={() => { resetInitialValues(); onCloseEvent(); }}
							disabled={false}
						/>
					}
				</Box>
			</form>
		</AddEditFormPanel >
	)
}

export default SendMailForm;