import { Box, Grid } from "@mui/material";
import AddEditFormPanel, { useAddEditForm } from "../../../components/common/form/AddEditFormPanel";
import AddEditFormSubmit from "../../../components/common/form/AddEditFormSubmit";
import { useEffect, useState } from "react";
import { ReqStatus } from "../../../stores/core/UseApi";
import TextAreaField from "../../../components/common/form/TextAreaField";
import { useAddEditCore } from "../../../stores/core/StoreCore";
import { EnumFolderOrderSupplierStatus, FolderOrderSupplierModel } from "../../classes/FolderOrderSupplierModel";
import InputField from "../../../components/common/form/InputField";
import NumberField from "../../../components/common/form/NumberField";
import { useRecoilState } from "recoil";
import { FolderOrderSupplierCommentModalState, FolderOrderSupplierListState } from "../../states/FolderOrderSuppliersState";

interface FolderOrderSupplierFormProps {
	id: number,
	onClose: () => void
}

const FolderOrderSupplierForm = (props: FolderOrderSupplierFormProps) => {

	const { id, onClose } = props;
	const { getData, addEditData } = useAddEditCore<any, any>(`project-dahan/dahan/folder-order-suppliers/add-edit`);
	const [status, setStatus] = useState<boolean>(false);
	const [modalState, setModalState] = useRecoilState(FolderOrderSupplierCommentModalState);
	const [list, setList] = useRecoilState(FolderOrderSupplierListState);

	useEffect(() => {
		getData(id.toString()).then((res) => {
			if (res.status === ReqStatus.SUCCESS && res.data && res.data) {
				setStatus(res.data.folderOrderSupplier.status === EnumFolderOrderSupplierStatus.CONFIRMED);
				resetInitialValues({
					id: res.data.folderOrderSupplier.id,
					comment: res.data.folderOrderSupplier.comment,
					reference: res.data.folderOrderSupplier.reference,
					amount: res.data.folderOrderSupplier.amount,
				});
			}
		})

	}, [])

	const onSubmit = (request: any) => {
		addEditData(request, id.toString()).then((res) => {
			if (res.status === ReqStatus.SUCCESS && Array.isArray(res.data) && res.data.length > 0) {
				const data = res.data[0];
				setModalState({
					id: id,
					open: false
				});

				setList((l) => {
					return l.map((i) => i.id === id ? {
						...i,
						comment: data.comment,
						reference: data.reference,
						amount: data.amount
					} : i);
				});


			}
		});
	}

	const { handleSubmit, resetInitialValues, isDirty } = useAddEditForm({
		onSubmit
	});

	return (
		<AddEditFormPanel
			title={`Demande fournisseur #${id}`}
		>
			<form id={"FolderOrderSupplierForm"} onSubmit={handleSubmit}>
				<Grid container spacing={3} mt={1}>
					<Grid item xs={12} >
						<InputField
							name="reference"
							label="Référence"
							type="string"
						/>
					</Grid>
					<Grid item xs={12} >
						<NumberField
							name="amount"
							label="Montant"
							disabled={status}
						/>
					</Grid>

					<Grid item xs={12} >
						<TextAreaField
							name="comment"
							label="Note"
							maxRows={15}
						/>
					</Grid>

				</Grid>
				<Box mt={2}>
					<AddEditFormSubmit
						returnAction={{ disabled: true }}
						cancelEvent={() => { resetInitialValues(); onClose(); }}
						disabled={!isDirty}
					/>
				</Box>
			</form>
		</AddEditFormPanel >
	)
}

export default FolderOrderSupplierForm;