

import { Box,  Card, Divider, IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import GradeIcon from '@mui/icons-material/Grade';
import { useNavigate } from "react-router-dom";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { CurrentUserState } from "../../../states/AuthState";
import { useRecoilValue } from "recoil";
import FolderReminderButtonConfirm from "./FolderReminderButtonConfirm";
import TooltipComment from "../common/TooltipComment";


const FolderReminderItem = (props: { item: any, updateActive: (id: number, active: boolean) => void }) => {
    const navigate = useNavigate();
    const { item, updateActive } = props;
    const currentUser = useRecoilValue(CurrentUserState);

    return (
        <Box mb={1}>
            <Card>
                <Box p={1}>
                    <Box display="flex" justifyContent={"space-between"} alignItems="center">
                        <Box>
                            #{item.folder_id} - {moment(item.reminder_date).format('HH:mm')}
                        </Box>
                        <Box display="flex" alignItems={"center"}>
                            <FolderReminderButtonConfirm size="small" id={item.id} active={item.active} afterSuccess={() => { updateActive(item.id, !item.active); }} />
                            <Box mt={1}>
                                {item.important && <GradeIcon fontSize="small" style={{ color: "orange" }} />}
                            </Box>
                            <Box>
                                <IconButton size="small" onClick={() => navigate(`/folders/add-edit/${item.folder_id}`)}>
                                    <RemoveRedEyeIcon fontSize="small" />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                    <Box>

                    </Box>
                    <Divider />
                    <Tooltip title={<TooltipComment value={item.comment} />}>
                        <Box my={1} style={{
                            maxWidth: '100%',
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 3,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}>
                            {item.comment.split('\n').map((x: any) => <Box>{x}</Box>)}
                        </Box>
                    </Tooltip>

                    <Divider />
                    <Box mt={1}>
                        {item.folder.customer.user.first_name} {item.folder.customer.user.last_name}
                    </Box>

                    {item.folder_reminder_tags.map((frt: any, index: number) => (
                        <Box key={index}>
                            <Divider />
                            <Box mt={1}>
                                <Box
                                    p={0.5}
                                    sx={{
                                        backgroundColor: frt.reminder_tag.backcolor,
                                        color: frt.reminder_tag.color,
                                    }}
                                >
                                    {frt.reminder_tag.name}
                                </Box>
                            </Box>
                        </Box>
                    ))}
                    { /*
                    <Box>
                        {item.active ? <ToggleOnIcon style={{ color: "green" }} /> : <ToggleOffIcon />}
                    </Box>
                    */}
                    {currentUser && ( currentUser.user_group_id === 1 || currentUser.user_group_id === 2 ) && item.user &&
                        <>
                            <Divider />
                            <Box mt={1}>
                                {item.user.first_name} {item.user.last_name}
                            </Box>
                        </>
                    }
                </Box>
            </Card>
        </Box>
    )
}



export default FolderReminderItem;