import { Alert, Box, Button, Grid } from "@mui/material";
import { useSetRecoilState } from "recoil";
import { AddressModalState } from "../../../states/AddressState";
import AddEditFormCard from "../../../components/common/form/AddEditFormCard";
import CustomerCustomAddressItem from "./CustomerCustomAddressItem";
import AddressCustomModal from "../addresses/AddressCustomModal";


const CustomerCustomAddressesView = (props: { customerId: string, addresses: any[], disabled: boolean, onValidate: () => void, onDelete: (id: number) => void }) => {

    const { customerId, addresses, disabled, onValidate, onDelete } = props;
    const setAddressModalState = useSetRecoilState(AddressModalState);

    return (
        <AddEditFormCard title={"Adresses"}>
            <AddressCustomModal />
            <Box display={"flex"} flexDirection={"column"}>
                <Grid item xs={12}>
                    {addresses.map((address, key) => (
                        <CustomerCustomAddressItem key={key} address={address} disabled={disabled} customerId={customerId} onValidate={onValidate} onDelete={onDelete} />
                    ))}

                    <Box mt={2}>
                        <Button variant="contained" disabled={disabled} color="primary" onClick={() => setAddressModalState({
                            id: undefined,
                            open: true,
                            customerId: customerId,
                            onValidate: onValidate
                        })}>Ajouter une adresse</Button>
                    </Box>
                </Grid>    
            </Box >
            { disabled && <Box mt={2} display="flex"><Alert severity="warning">Enregistrer vos modifications pour pouvoir modifier les adresses</Alert></Box>}
        </AddEditFormCard>
    );
}

export default CustomerCustomAddressesView;