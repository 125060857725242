import { GridColDef } from "@mui/x-data-grid";
import { Box, Button, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { GenderListModel } from "../../classes/genders/GenderModel";
import { useGetListCore } from "../../stores/core/StoreCore";
import ListCore from "../common/list/ListCore";

const columns: GridColDef[] = [
    {
        field: 'id',
        headerName: "ID",
        minWidth: 100,
        flex: 1,
    },
    {
        field: 'label',
        headerName: "Label",
        minWidth: 100,
        flex: 1,
    },

    {
        field: 'diminutive',
        headerName: "Diminutif",
        minWidth: 100,
        flex: 1,
    }
];

const GenderList = () => {

    const routeController = "commercial/genders";
    const [get] = useGetListCore<GenderListModel>(`/${routeController}/index`);
    return (
        <ListCore<GenderListModel, any>
            getDatas={get}
            defineDatasToSet={(data) => data.genders}
            getRowId={(row) => row.id}
            columns={columns}
            actions={{
                defaultRoute: routeController,
                add: {
                    title: "Ajouter un genre"
                },
                edit: true,
                remove: false
            }}
        />
    );
}

export default GenderList;