import { Alert, Box, Button, Grid, IconButton, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddressModal from "../addresses/AddressModal";
import { AddressModalState } from "../../states/AddressState";
import { useSetRecoilState } from "recoil";
import AddEditFormCard from "../common/form/AddEditFormCard";
import { useDelete } from "../../stores/core/StoreCore";

const CustomerAddressesView = (props: { customerId: string, addresses: any[], disabled: boolean, onValidate: () => void, onDelete: (id: number) => void }) => {

    const { customerId, addresses, disabled, onValidate, onDelete } = props;
    const setAddressModalState = useSetRecoilState(AddressModalState);

    return (
        <AddEditFormCard title={"Adresses"}>
            <AddressModal />
            <Box display={"flex"} flexDirection={"column"}>
                <Grid item xs={12}>
                    {addresses.map((address, key) => (
                        <Grid key={key} container mb={2} display="flex" alignItems={"center"}>
                            <Grid item xs={8} sm={6}>
                                <Alert variant="outlined" severity="info" icon={false}>
                                    {address.label}
                                </Alert>
                            </Grid>
                            <Grid item xs={4} sm={6}>
                                <IconButton color="info" disabled={disabled} onClick={() => setAddressModalState({
                                    id: address.value,
                                    open: true,
                                    customerId: customerId,
                                    onValidate: onValidate
                                })}>
                                    <EditIcon />
                                </IconButton>
                                { key > 0 && <IconButton color="error" disabled={disabled} onClick={() => onDelete(address.value)}><DeleteIcon /></IconButton> }
                            </Grid>
                        </Grid>
                    ))}

                    <Box mt={2}>
                        <Button variant="contained" disabled={disabled} color="primary" onClick={() => setAddressModalState({
                            id: undefined,
                            open: true,
                            customerId: customerId,
                            onValidate: onValidate
                        })}>Ajouter une adresse</Button>
                    </Box>
                </Grid>    
            </Box >
            { disabled && <Box mt={2} display="flex"><Alert severity="warning">Enregistrer vos modifications pour pouvoir modifier les adresses</Alert></Box>}
        </AddEditFormCard>
    );
}

export default CustomerAddressesView;