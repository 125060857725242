export enum BrevoMailConfigEntityEnum{
	ENTITY_NONE,
    ENTITY_FOLDER,
    ENTITY_QUOTE,
    ENTITY_BILL,
	ENTITY_FOLDER_ORDER_SUPPLIER
}

export const BrevoMailConfigEntityOptions = [
	{value: BrevoMailConfigEntityEnum.ENTITY_NONE, label: "Non défini"},
	{value: BrevoMailConfigEntityEnum.ENTITY_FOLDER, label: "Dossier"},
	{value: BrevoMailConfigEntityEnum.ENTITY_QUOTE, label: "Devis"},
	{value: BrevoMailConfigEntityEnum.ENTITY_BILL, label: "Facture"},
	{value: BrevoMailConfigEntityEnum.ENTITY_FOLDER_ORDER_SUPPLIER, label: "Demande fournisseur"}
];

export enum BrevoMailConfigVisibility{
    NONE,
    ALL,
    COMMERCIAL,
	GESTIONNAIRE,
	ADMIN
}

export const BrevoMailConfigVisibilityOptions = [
	{value: BrevoMailConfigVisibility.NONE, label: "Non visible"},
	{value: BrevoMailConfigVisibility.ALL, label: "Tous les rôles"},
	{value: BrevoMailConfigVisibility.COMMERCIAL, label: "Commercial"},
	{value: BrevoMailConfigVisibility.GESTIONNAIRE, label: "Gestionnaire"},
	{value: BrevoMailConfigVisibility.ADMIN, label: "Administrateur"}
];

export enum BrevoMailConfigRecipientType {
    CUSTOMER,
    SUPPLIER,
    CUSTOM,
}

export const BrevoMailConfigRecipientTypeOptions = [
	{value: BrevoMailConfigRecipientType.CUSTOMER, label: "Client"},
	{value: BrevoMailConfigRecipientType.SUPPLIER, label: "Fournisseur"},
	{value: BrevoMailConfigRecipientType.CUSTOM, label: "Custom"}
];

export enum BrevoMailConfigQuoteStatusEnum {
    NONE,
    INPROGRESS,
    VALIDATED,
}


export enum BrevoTemplateEnum {
    BREVO_TPL_22_ENVOI_DEMANDE_FOURNISSEUR = 2,
	BREVO_TPL_21_ENVOI_DEVIS = 3,
	BREVO_TPL_XX_ENVOI_DEVIS_LIGNE = 63,

	BREVO_TPL_14_ENVOI_VALIDATION_INFOS_VOYAGE = 15,
	BREVO_TPL_19_ENVOI_INFOS_VOYAGE_COMPLETED_TO_SUPPLIER = 18,
	BREVO_TPL_15_ENVOI_INFOS_VOYAGE_COMPLETED_TO_CUSTOMER = 16,
	BREVO_TPL_24_ENVOI_ROADMAP_CUSTOMER = 21,

	BREVO_TPL_54_ENVOI_FACTURE_ACOMPTE = 50,
	BREVO_TPL_57_ENVOI_FACTURE = 53,
}