import { Box, Container, Modal } from "@mui/material";
import { FormProvider } from "react-recoil-form";
import { useCallback, useState } from "react";
import { useRecoilState } from "recoil";
import { QuoteTravelModalState } from "../../states/QuoteState";
import QuoteTravel from "./QuoteTravel";

const QuoteTravelModal = (props: { disabled? :boolean}) => {

    const [modalState, setModalState] = useRecoilState(QuoteTravelModalState);

    const handleClose = useCallback(() => {
        setModalState((a) => {
            return { ...a, open: false };
        });
    }, [setModalState]);


    const handleValidate = useCallback((request: any) => {
        modalState.onValidate(request);
        setModalState((a) => {
            return { ...a, open: false };
        });
    }, [modalState, setModalState]);


    return (
        <Modal
            open={modalState.open}
            //onClose={handleClose}
            aria-labelledby="quote-modal"
            sx={{
            }}
            
        >
            <Container component="main" maxWidth="lg" sx={{ height: "100%", py: 2, marginRight: 0 }}>
                <FormProvider options={{ formId: "quoteTravelForm", skipRecoilRoot: true }}>
                    <QuoteTravel
                        travel={modalState.travel ?? {}}
                        onValidate={handleValidate}
                        onClose={handleClose}
                        disabled={props.disabled}
                    />
                </FormProvider>
            </Container>
        </Modal>
    );
}

export default QuoteTravelModal;