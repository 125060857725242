import { Alert, Box, Button, Divider, Grid, IconButton, Tooltip } from "@mui/material";
import AddEditFormCard from "../../../components/common/form/AddEditFormCard";
import { useCallback, useEffect, useMemo } from "react";
import { RecoilValueReadOnly, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { FolderOrderSupplierListState } from "../../states/FolderOrderSuppliersState";
import { ReqStatus } from "../../../stores/core/UseApi";
import { ConfirmModalState } from "../../../states/ModalState";
import { FolderOrderSupplierPaymentModel } from "../../classes/FolderOrderSupplierPaymentModel";
import { FolderOrderSupplierPaymentModalState } from "../../states/FolderOrderSupplierPaymentState";
import { useDeleteFolderOrderSupplierPayment } from "../../stores/FolderOrderSupplierPaymentsStore";
import FolderOrderSupplierPaymentModal from "./FolderOrderSupplierPaymentModal";
import { dateFormat } from "../../../utils/DateUtils";
import DeleteIcon from "@mui/icons-material/Delete";
import { EnumFolderOrderSupplierStatus, FolderOrderSupplierModel } from "../../classes/FolderOrderSupplierModel";
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import FormattedAmount from "../../../components/common/formatted/FormattedAmount";
import { IsNotUserCommercialSelector, UserExtenalSelector } from "../../states/UserState";
import { CurrentUserState } from "../../../states/AuthState";
import TooltipComment from "../common/TooltipComment";

const FolderOrderSupplierContactList = () => {

	const setModalState = useSetRecoilState(FolderOrderSupplierPaymentModalState);

	const [folderOrderSupplierList, setFolderOrderSupplierList] = useRecoilState(FolderOrderSupplierListState);

	const deleteAjax = useDeleteFolderOrderSupplierPayment();
	const setConfirmModalState = useSetRecoilState(ConfirmModalState);

	const folderOrderSupplierWithPaymentsList = useMemo(() => {
		let results: FolderOrderSupplierModel[] = [];
		folderOrderSupplierList.forEach((folderOrderSupplier) => {
			if (Array.isArray(folderOrderSupplier.folder_order_supplier_payments) && folderOrderSupplier.folder_order_supplier_payments.length > 0) {
				results.push(folderOrderSupplier);
			}
		});
		return results;
	}, [folderOrderSupplierList]);

	const getOrderSupplierName = useCallback((folderOrderSupplierId: number) => {
		const item = folderOrderSupplierList.find((x) => x.id === folderOrderSupplierId);
		return item ? `Devis ${item.order.id} - ${item.supplier.name}` : "Erreur lien";
	}, [folderOrderSupplierList]);

	const getPaymentColor = useCallback((folderOrderSupplier: FolderOrderSupplierModel) => {
		if (!folderOrderSupplier) { return "error"; }
		switch (folderOrderSupplier.status) {
			case EnumFolderOrderSupplierStatus.CANCELED:
				return "error";
			case EnumFolderOrderSupplierStatus.INIT:
			case EnumFolderOrderSupplierStatus.VALIDATED:
				return "warning";
			case EnumFolderOrderSupplierStatus.CONFIRMED:
				return "info";
			default:
				return "error";
		}
	}, []);

	const getTotalRemaining = useCallback((folderOrderSupplier: FolderOrderSupplierModel) => {
		let totalRemaining = folderOrderSupplier.amount ?? 0;
		folderOrderSupplier.folder_order_supplier_payments.forEach((p) => {
			totalRemaining = totalRemaining - (p.amount ?? 0);
		});
		return <FormattedAmount amount={totalRemaining ?? 0} symbol="€" />
	}, []);


	const deleteEvent = useCallback((id: number) => {
		setConfirmModalState({
			open: true,
			title: "Suppression",
			desc: "Êtes-vous sûr de vouloir supprimer ce paiement ?",
			validEvent: () => {
				deleteAjax(id).then((res) => {
					if (res.status === ReqStatus.SUCCESS) {
						setFolderOrderSupplierList((list) => {
							return list.map((i) => {
								return {
									...i,
									folder_order_supplier_payments: i.folder_order_supplier_payments.filter(c => c.id !== id)
								}
							});
						})
					}
				})
			}
		})
	}, [deleteAjax, setConfirmModalState, setFolderOrderSupplierList]);

	const isNotUserCommercial = useRecoilValue(IsNotUserCommercialSelector);

	return (
		<>
			{isNotUserCommercial && (
				<AddEditFormCard title="Paiement Fournisseur">
					<FolderOrderSupplierPaymentModal />
					{folderOrderSupplierList.length === 0 &&
						<Alert variant="outlined" severity="info">Aucun fournisseur sélectionné</Alert>
					}
					{folderOrderSupplierList.length > 0 &&
						<Box mb={1}>
							<Button variant="contained" color="info" onClick={() => {
								setModalState({
									open: true, onValidate: () => {
									}
								})
							}}
							>Ajouter un paiement</Button>
						</Box>
					}

					{folderOrderSupplierWithPaymentsList.map((folderOrderSupplier, folderOrderSupplier_key) => (
						<Box key={folderOrderSupplier_key} mb={1} >
							<Alert variant="outlined" severity={getPaymentColor(folderOrderSupplier)} icon={false} >
								<Box mb={1} maxWidth={"100%"} width={"700px"} >Devis {folderOrderSupplier.order.id} {folderOrderSupplier.supplier.name} <br />  Total : <FormattedAmount amount={folderOrderSupplier.amount} symbol="€" /> - Reste à payer : {getTotalRemaining(folderOrderSupplier)}</Box>
								<Divider />
								<Box mt={1} width={"100%"} >
									{folderOrderSupplier.folder_order_supplier_payments.map((payment, payment_key) => (
										<Box key={payment_key} display="flex" justifyContent={"space-between"} alignItems={"center"} mt={1} >
											<Box display="flex">
												<Box>Réf: {payment.reference} <br />Le {dateFormat(payment.payment_date)} : payé <FormattedAmount amount={payment.amount} symbol="€" /></Box>
											</Box>
											<Box display={"flex"} alignItems={"center"}>
												<Box mt={0.5}>{payment.comment && <Tooltip title={<TooltipComment value={payment.comment} />}><InfoIcon /></Tooltip>}</Box>
												<Box>
													<IconButton color="primary"
														onClick={() => {
															setModalState({
																id: payment.id,
																open: true, onValidate: () => {
																}
															})
														}}
													><EditIcon /></IconButton>
												</Box>
												<Box><IconButton color="error" onClick={() => deleteEvent(payment.id)}><DeleteIcon /></IconButton></Box>
											</Box>
										</Box>
									))}
								</Box>
							</Alert>
						</Box>
					))
					}


				</AddEditFormCard >
			)}
		</>
	);
}

export default FolderOrderSupplierContactList;

function useRecoiValue(UserExtenalSelector: RecoilValueReadOnly<boolean | undefined>) {
	throw new Error("Function not implemented.");
}
