import { Autocomplete, Chip, FormControl, FormHelperText, TextField } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { IAncestorInput, useField, useForm, useFormContext } from "react-recoil-form";

interface AutocompleteMultipleFieldProps {
	name: string,
	ancestors?: IAncestorInput[];
	label: string,
	validateOption?: (value: string) => boolean,
	required?: boolean,
	delimiter?: string,
	options: string[],
	disabled?: boolean
}
const AutocompleteMultipleField = (props: AutocompleteMultipleFieldProps) => {

	const { ancestors, name, label, validateOption, required, delimiter, options, disabled } = props;
	const { fieldValue, setFieldValue, error } = useField<string>({
		ancestors,
		name,
		validate: (value) => {
			if (required && !value) {
				return `Le champ ${label} est requis`;
			}

			let hasErrors = false;

			if (validateOption && value) {
				value?.split(_delimiter).forEach((v) => {
					if (!validateOption(v)) {
						hasErrors = true;
					}
				});
			}

			if (hasErrors) {
				return `Le champ ${label} est erroné`;
			}
		}
	});

	const _delimiter = useMemo(() => delimiter ?? ';',[delimiter]);

	const value = useMemo(() => {
		return fieldValue ? fieldValue.split(_delimiter) : [];
	},[fieldValue, _delimiter])

	return (
		<FormControl error={!!error} fullWidth >
			<Autocomplete
				disabled={disabled}
				value={value}
				onChange={(event, newValue) => {
					setFieldValue((newValue ?? []).join(_delimiter));
				}}
				getOptionLabel={(option) => option}
				options={options}
				renderInput={(params) => (
					<TextField
						{...params}
						label={`${label}${required ? ' *' : ''}`}
						variant="outlined"
					/>
				)}
				freeSolo
				multiple
				renderTags={(value, getTagProps) =>
					value.map((option, index) => {
						let hasErrors = false;
						if (validateOption) {
							hasErrors = !validateOption(option);
						}
						return (
							<Chip
								label={option}
								{...getTagProps({ index })}
								style={{
									backgroundColor: hasErrors ? 'orangered' : undefined,
								}}
							/>
						);
					})
				}
			/>
			{error && <FormHelperText id="my-helper-text">{error}</FormHelperText>}
		</FormControl>
	)
}

export default AutocompleteMultipleField;