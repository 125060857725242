import { GridColDef } from "@mui/x-data-grid";
import { Box, Button, IconButton } from "@mui/material";
import { PaymentListModel } from "../../classes/payments/PaymentModel";
import { useGetListCore } from "../../stores/core/StoreCore";
import { useNavigate } from "react-router-dom";
import ListCore from "../common/list/ListCore";
import EditIcon from "@mui/icons-material/Edit";
import { datetimeFormat } from "../../utils/DateUtils";
import { useCallback } from "react";
import { useIntl } from "react-intl";



export const PaymentListActions = (props: { row: any }) => {

    const { row } = props;
    const navigate = useNavigate();

    return (
        <Box display="flex">
            <Box ml={2}>
                <Button variant="contained" color="info" onClick={() => navigate(`/commercial/customers/add-edit/${row.order.customer_id}`)}>Fiche client</Button>
            </Box>
        </Box>
    );
}

const PaymentList = () => {
    const intl = useIntl();
    const columns = useCallback(() => {
        return [
            {
                field: 'order.id',
                headerName: intl.formatMessage({ id: "Label_Order_Linked" }),
                editable: false,
                filterable: false,
                minWidth: 150,
                valueGetter: ({ row }) => `${intl.formatMessage({ id: "Label_Order_Number" })}${row.order.id}`
            },
            {
                field: 'billing_address',
                headerName: "Client",
                editable: false,
                filterable: false,
                minWidth: 250,
                valueGetter: ({ row }) => `${row.order.billing_address.first_name} ${row.order.billing_address.last_name}`
            },
            {
                field: 'payment_method.label',
                headerName: "Moyen de paiement",
                editable: false,
                filterable: false,
                minWidth: 150,
                valueGetter: ({ row }) => row.payment_method.label
            },
            {
                field: 'status.label',
                headerName: "Statut",
                editable: false,
                filterable: false,
                minWidth: 250,
                valueGetter: ({ row }) => row.status.label
            },
            {
                field: 'total_amount_euro',
                headerName: "Montant",
                editable: false,
                filterable: false,
                minWidth: 150,
                valueGetter: ({ row }) => `${row.total_amount} €`
            },
            {
                field: 'created',
                headerName: "Date",
                editable: false,
                filterable: false,
                minWidth: 150,
                valueGetter: ({ row }) => datetimeFormat(row.created)
            }
        ] as GridColDef[];
    }, [intl]);

    const routeController = "commercial/payments";
    const [get] = useGetListCore<PaymentListModel>(`/${routeController}/index`);

    return (
        <ListCore<PaymentListModel, any>
            getDatas={get}
            defineDatasToSet={(data) => data.payments}
            getRowId={(row) => row.id}
            columns={columns()}
            actions={{
                defaultRoute: routeController,
                add: {
                    title: "Ajouter un paiement"
                },
                edit: true,
                others: (row) => <PaymentListActions row={row} />
            }}
        />
    );
}

export default PaymentList;