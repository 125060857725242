import { Box, Button, Grid } from "@mui/material";
import AddEditFormList from "../common/form/AddEditFormList";
import AutocompleteField, { AutocompleteOption } from "../common/form/AutocompleteField";
import { useField, useFieldArray } from "react-recoil-form";
import { useEffect, useRef, useState } from "react";
import AutocompleteCore from "../common/input/AutocompleteCore";
import OrderProductAdd from "./OrderProductAdd";


export const OrderProductsFieldNames = [
    "id",
    "_joinData.id",
    "_joinData.product_id",
    "_joinData.quantity",
    "_joinData.unit_tax_excl",
    "_joinData.total_tax_excl",
    "_joinData.total_tax",
    "_joinData.total_tax_incl",
    "_joinData.vat"
];

interface OrderProductsViewProps{
    products: AutocompleteOption[],
    buttonAddTitle?: string,
    additionnalActions?: JSX.Element
}
const OrderProductsView = (props: OrderProductsViewProps) => {
    const { products, buttonAddTitle, additionnalActions } = props;
 
    return (
        <Box >

            {<AddEditFormList
                fields={[{
                    name: "_joinData.product_id",
                    type: "autocomplete",
                    label: "Nom produit",
                    autocompleteOptions: products,
                    xs: 2,
                    required: true,
                    disabled: true,
                },
                {
                    name: "_joinData.quantity",
                    type: "number",
                    label: "Quantité",
                    required: true,
                    xs: 1,
                },
                {
                    name: "_joinData.unit_tax_excl",
                    type: "number",
                    label: "Prix HT",
                    required: true,
                    xs: 2,
                },
                {
                    name: "_joinData.total_tax_excl",
                    type: "number",
                    label: "Total HT",
                    xs: 2,
                    disabled: true,
                },
                {
                    name: "_joinData.vat",
                    type: "number",
                    label: "TVA %",
                    xs: 2,
                    disabled: true
                },
                {
                    name: "_joinData.total_tax_incl",
                    type: "number",
                    label: "Total TTC",
                    xs: 2,
                    disabled: true
                }
                ]}

                hiddenFields={{
                    add: ["id"],
                    edit: ["id"]
                }}

                entity={"products"}
                title={"Produits associés"}
                defaultItem={{}}
                
            />}

            <Box mt={1} display="flex">
                <OrderProductAdd products={props.products} title={buttonAddTitle || "Ajouter un produit"} />
                {additionnalActions}
            </Box>
        </Box>
    )
}

export default OrderProductsView;