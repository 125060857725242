import { Box, Paper, Tab, Tabs } from "@mui/material"

interface FolderTabsProps {
    tabId: number,
    onClickEvent: (id: number) => void
}
const BrevoMailConfigsTabs = (props: FolderTabsProps) => {

    const { tabId, onClickEvent } = props;

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        onClickEvent(newValue);
    };

    return (
        <Box>
            <Paper>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs variant="scrollable" value={tabId} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Configuration" />
                        <Tab label="Email Brevo" />
                        <Tab label="Paramètres Brevo" />
                    </Tabs>
                </Box>
            </Paper>
        </Box>
    )
}

export default BrevoMailConfigsTabs;