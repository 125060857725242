import { useCallback } from "react";
import { useApi } from "../../stores/core/UseApi";
import { BrevoTemplateModel } from "../classes/BrevoApiModel";

const routeController = 'project-dahan/dahan/search';

interface searchRequest {
    search: string
}
export const useSearchApi = () => {
    const [callCore, status] = useApi<{ customers: any[], quotes: any[], folders: any[], bills: any[]}, searchRequest>(
        {
            route:  `${routeController}/index`,
            instanceAxiosMethod: "POST"
        }
    );
    const call = useCallback( (request: searchRequest) => {
        return callCore(request);
    }, [callCore]);
    return { call, status };
}