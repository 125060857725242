import { atom } from "recoil";

export const AddressModalState = atom<{id: string | undefined, customerId: string, open: boolean,  onValidate: () => void}>({
    key: 'AddressModalKey',
    default: {
        id: undefined,
        customerId: "",
        open: false,
        onValidate: () => {}
    }
});
