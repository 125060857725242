import { Box, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import CopyButton from "../../../components/common/button/CopyButton";
import { useCallback, useMemo, useState } from "react";

interface BrevoMailConfigDoc {
        key: string;
        value: string;
        type: string;
        safe? : boolean;
};



const data: BrevoMailConfigDoc[] = [
    {key: "additional_text", value: "Texte additionnel ( ce paramètre doit être ajouter dans le template pour que le champ apparaisse dans la modale mail )", type: "Chaîne de caractères", safe: true },
    {key: "internal.order_id", value: "Identifiant de commande ( ex: 10011 ) ", type: "Nombre"},
    {key: "internal.folder_id", value: "Identifiant de dossier ( ex: 14033 )", type: "Nombre"},

    {key: "folder_id", value: "Identifiant de dossier ( ex: #10011 ) ", type: "chaîne de caractères"},
    {key: "quote_id", value: "Identifiant de devis ( ex: #14033 ) ", type: "chaîne de caractères"},

    {key: "customer.full_name", value: "Nom complet du client", type: "Chaîne de caractères"},
    {key: "customer.phone", value: "Téléphone du client", type: "Numéro de téléphone"},
    {key: "customer.email", value: "Email du client", type: "Email"},
    {key: "customer.company", value: "Entreprise du client", type: "Chaîne de caractères"},
    {key: "customer.billing_full_name", value: "Nom complet de facturation du client", type: "Chaîne de caractères"},
    {key: "customer.billing_company_name", value: "Entreprise de facturation du client", type: "Chaîne de caractères"},

    {key: "quote.total_ttc", value: "Total TTC du devis", type: "Prix"},
    {key: "quote.payment_mode", value: "Mode de paiement du devis", type: "Chaîne de caractères"},
    {key: "quote.signed", value: "Devis signé", type: "Booléen"},

    {key: "travel.cars_count", value: "Voyage - Nombre de voitures", type: "Nombre"},
    {key: "travel.drivers_count", value: "Voyage - Nombre de conducteurs", type: "Nombre"},
    {key: "travel.is_roundtrip", value: "Voyage - Est-ce un trajet aller-retour", type: "Booléen"},
    {key: "travel.departure_date_date", value: "Voyage - Date de départ", type: "Date"},
    {key: "travel.departure_date_hour", value: "Voyage - Heure de départ", type: "Heure"},
    {key: "travel.departure_address", value: "Voyage - Adresse de départ", type: "Adresse"},
    {key: "travel.departure_arrival_address", value: "Voyage - Adresse d'arrivée au départ", type: "Adresse"},
    {key: "travel.departure_pax", value: "Voyage - Nombre de passagers au départ", type: "Nombre"},

    {key: "travel.customer_departure_date_date", value: "Voyage - Date de départ ( Validé client )", type: "Date"},
    {key: "travel.customer_departure_date_hour", value: "Voyage - Heure de départ ( Validé client )", type: "Heure"},
    {key: "travel.customer_departure_address", value: "Voyage - Adresse de départ ( Validé client )", type: "Adresse"},
    {key: "travel.customer_departure_arrival_address", value: "Voyage - Adresse d'arrivée au départ ( Validé client )", type: "Adresse"},
    {key: "travel.customer_departure_pax", value: "Voyage - Nombre de passagers au départ ( Validé client )", type: "Nombre"},

    {key: "travel.return_date_date", value: "Voyage - Date de retour", type: "Date"},
    {key: "travel.return_date_hour", value: "Voyage - Heure de retour", type: "Heure"},
    {key: "travel.return_arrival_address", value: "Voyage - Adresse d'arrivée au retour", type: "Adresse"},
    {key: "travel.return_address", value: "Voyage - Adresse de retour", type: "Adresse"},
    {key: "travel.return_pax", value: "Voyage - Nombre de passagers au retour", type: "Nombre"},

    {key: "travel.customer_return_date_date", value: "Voyage - Date de retour ( Validé client )", type: "Date"},
    {key: "travel.customer_return_date_hour", value: "Voyage - Heure de retour ( Validé client )", type: "Heure"},
    {key: "travel.customer_return_arrival_address", value: "Voyage - Adresse d'arrivée au retour ( Validé client )", type: "Adresse"},
    {key: "travel.customer_return_address", value: "Voyage - Adresse de retour ( Validé client )", type: "Adresse"},
    {key: "travel.customer_return_pax", value: "Voyage - Nombre de passagers au retour ( Validé client )", type: "Nombre"},

    {key: "travel.contact_last_name", value: "Voyage - Nom du contact", type: "Chaîne de caractères"},
    {key: "travel.contact_first_name", value: "Voyage - Prénom du contact", type: "Chaîne de caractères"},
    {key: "travel.contact_phone1", value: "Voyage - Téléphone du contact", type: "Numéro de téléphone"},
    {key: "travel.contact_phone2", value: "Voyage - Deuxième numéro de téléphone du contact", type: "Numéro de téléphone"},
    {key: "travel.contact_return_last_name", value: "Voyage - Nom du contact au retour", type: "Chaîne de caractères"},
    {key: "travel.contact_return_first_name", value: "Voyage - Prénom du contact au retour", type: "Chaîne de caractères"},
    {key: "travel.contact_return_phone1", value: "Voyage - Téléphone du contact au retour", type: "Chaîne de caractères"},
    {key: "travel.contact_return_phone2", value: "Voyage - Deuxième numéro de téléphone du contact au retour", type: "Chaîne de caractères"},
    {key: "travel.contact_details", value: "Voyage - Détails du contact", type: "Chaîne de caractères", safe: true },

    {key: "travel.comment", value: "Voyage - Commentaire sur le voyage", type: "Texte", safe: true },
    {key: "travel_circuit", value: "Voyage - Détails du circuit", type: "HTML", safe: true },
    {key: "travel_customer_circuit", value: "Voyage - Détails du circuit modifié dans les infos vouages", type: "HTML", safe: true },

    {key: "travel.customer_departure_date_date", value: "Voyage - Date de départ du client", type: "Date"},
    {key: "travel.customer_departure_date_hour", value: "Voyage - Heure de départ du client", type: "Heure"},

    {key: "commercial.full_name", value: "Nom complet du commercial", type: "Chaîne de caractères"},
    {key: "commercial.email", value: "Email du commercial", type: "Email"},
    {key: "commercial.signature", value: "Signature du commercial", type: "HTML", safe: true},

    {key: "url_quote_validate", value: "URL pour valider le devis ( Checkbox requis )", type: "URL"},
    {key: "url_travel_infos_confirm", value: "URL pour confirmer les informations de voyage ( Checkbox requis )", type: "URL"},
    {key: "url_app_folder", value: "URL de l'application pour le dossier", type: "URL"},
    {key: "url_app_quote", value: "URL de l'application pour le devis", type: "URL"},

    {key: "quote_supplier_id", value: "Identifiant du fournisseur de devis", type: "Chaîne de caractères"},
    {key: "quote_supplier_reference", value: "Référence du fournisseur de devis", type: "Chaîne de caractères"},
];

const BrevoMailConfigsParamsView = () => {
    const styles = {
        hover: {
            backgroundColor: '#f5f5f5'
        }
    };

    const [searchTerm, setSearchTerm] = useState<string>('');

    const filteredData = useMemo(() => {
        return data.filter((item: BrevoMailConfigDoc) => {
            return item.key.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.value.toLowerCase().includes(searchTerm.toLowerCase());
        });
    }, [data, searchTerm]);

    const getVariableByItem = useCallback((item: BrevoMailConfigDoc) => {
        return `{{ params.${item.key}${item.safe ? ' | safe' : ''} }}`;
    },[])

    return (
        <Box sx={{ background: "white"}}>
            <Box p={2} display="flex" justifyContent="flex-end">
                <TextField
                    label="Rechercher"
                    variant="outlined"
                    value={searchTerm}
                    size="small"
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell width={'30%'}>Clé</TableCell>
                        <TableCell width={'40%'}>Description</TableCell>
                        <TableCell width={'30%'}>Type</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredData.map((item: BrevoMailConfigDoc, index: number) => (
                        <TableRow
                            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = styles.hover.backgroundColor}
                            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = ''}
                            sx={{ height: '30px' }}
                            key={index}
                        >                        
                            <TableCell key={index} sx={{ padding: '0 10px' }}>
                                <Box display="flex" justifyContent={"space-between"} alignItems="center">        
                                    <span>{getVariableByItem(item)}</span>
                                    <CopyButton textToCopy={getVariableByItem(item)} /> 
                                </Box>                     
                            </TableCell>
                            <TableCell key={index} sx={{ padding: '0 10px' }}>
                                {item.value}
                            </TableCell>
                            <TableCell key={index} sx={{ padding: '0 10px' }}>
                                {item.type}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Box>
    );
};

export default BrevoMailConfigsParamsView;