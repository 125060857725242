import { useNavigate, useParams } from "react-router-dom";
import { useAddEditSupplier, useGetSupplier } from "../../stores/SuppliersStore";
import { useCallback, useEffect, useState } from "react";
import { ReqStatus } from "../../stores/core/UseApi";
import { Box, Divider, Grid, Skeleton } from "@mui/material";
import InputField from "../common/form/InputField";
import TextAreaField from "../common/form/TextAreaField";
import AddEditFormPanel, { useAddEditForm } from "../common/form/AddEditFormPanel";
import AddEditFormSubmit from "../common/form/AddEditFormSubmit";
import AutocompleteField, { AutocompleteOption } from "../common/form/AutocompleteField";
import SupplierProductsView from "./SupplierProductsView";
import { SupplierModel } from "../../classes/suppliers/SupplierModel";
import { useForm, useFormValues, useIsDirty } from "react-recoil-form";
import AddEditFormList from "../common/form/AddEditFormList";
import { useSetRecoilState } from "recoil";
import { NavigateState, NavigationBlockedState } from "../../states/MenuState";
import AddEditForm from "../common/form/AddEditForm";
import AddEditFormCard from "../common/form/AddEditFormCard";
import { mapArrayForForm } from "../../utils/DataUtils";


const SupplierView = () => {

    let { id } = useParams();

    const setNavigateState = useSetRecoilState(NavigateState);

    const listRoute = "/commercial/suppliers";
    const [products, setProducts] = useState<AutocompleteOption[]>([]);

    const [get] = useGetSupplier();
    const [addOrEdit] = useAddEditSupplier();


    const onSubmit = (request: SupplierModel) => {

        let _request = JSON.parse(JSON.stringify(request)) as SupplierModel;

        if (!_request.products_suppliers) {
            delete _request.products_suppliers;
        }
        if (!_request.newProductsSuppliers) {
            delete _request.newProductsSuppliers;
        }

        addOrEdit(_request, id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                setNavigateState("/commercial/suppliers/");
            }
        });
    }

    const { handleSubmit, resetInitialValues, isDirty } = useAddEditForm({
        onSubmit
    });

    useEffect(() => {
        get(id ? +id : undefined).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {

                const _supplier = res.data.supplier;

                if (res.data.products) {
                    let _products: AutocompleteOption[] = []
                    Object.entries(res.data.products).forEach(([product_id, product]) => {
                        _products.push({ id: +product_id, label: product });
                    })
                    setProducts(_products);
                }

                if (id) {
                    resetInitialValues({
                        name: _supplier.name,
                        email: _supplier.email,
                        phone: _supplier.phone,
                        address: _supplier.address,
                        addElements: "1",
                        
                        ...mapArrayForForm(
                            _supplier.products_suppliers,
                            "products_suppliers",
                            (p) => ({
                                id: p.id,
                                product_id: p.product_id,
                                supplier_reference: p.supplier_reference,
                                amount_tax_excl: p.amount_tax_excl,
                            })
                        )
                    });
                } else {
                    resetInitialValues({
                        name: "",
                        email: "",
                        phone: "",
                        address: "",
                        addElements: "1"
                    });
                }
            } else {
                setNavigateState(listRoute);
            }
        })
    }, [id]);

    return (

        <AddEditForm formId="supplierForm" formHandleCancel={resetInitialValues} formHandleSubmit={handleSubmit} isDirty={isDirty}>

            <AddEditFormCard title={`${(!id ? "Ajouter " : "Modifier")} un fournisseur`}>
                <Grid container spacing={3}>

                    <Grid item xs={12} sm={6}>
                        <InputField
                            name="name"
                            label="Nom"
                            required
                            type="text"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <InputField
                            name="email"
                            label="Email"
                            type="text"
                            isEmail
                        />
                    </Grid>


                    <Grid item xs={12} sm={6}>
                        <InputField
                            name="phone"
                            label="Téléphone"
                            type="text"
                            isPhone
                        />
                    </Grid>


                    <Grid item xs={12} sm={6}>
                        <TextAreaField
                            name="address"
                            label="Adresse"
                        />
                    </Grid>
                </Grid>
            </AddEditFormCard>
            <AddEditFormCard title="Produits associés">
                <AddEditFormList
                    fields={[{
                        name: "product_id",
                        type: "autocomplete",
                        label: "Nom produit",
                        autocompleteOptions: products,
                        xs: 4,
                        required: true
                    },
                    {
                        name: "supplier_reference",
                        type: "text",
                        label: "Référence produit",
                        xs: 3,
                    },
                    {
                        name: "amount_tax_excl",
                        type: "number",
                        label: "Prix HT",
                        xs: 3,
                    }]}

                    hiddenFields={{
                        add: ["supplier_id"],
                        edit: ["id"]
                    }}

                    entity={"products_suppliers"}
                    title={"Produits associés"}
                    buttonAdd={{ title: "Ajouter un produit" }}
                    defaultItem={{
                        supplier_id: id ?? 0,
                        supplier_reference: "",
                        amount_tax_excl: 0
                    }}
                />
            </AddEditFormCard>
        </AddEditForm>

    );
}

export default SupplierView;