import { Box, Card, Chip } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useGetCounterOfUser } from "../../stores/FoldersStore";
import { ReqStatus } from "../../../stores/core/UseApi";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { CurrentUserState } from "../../../states/AuthState";
import { UserGroupEnum } from "../../classes/UserGroupModel";


const FolderCounter = () => {
 
    const [countFolderNotAttributed, setCountFolderNotAttributed] = useState(0);
    const [countFolder, setCountFolder] = useState(0);
    const [countOrder, setCountOrder] = useState(0);
    const currentUser = useRecoilValue(CurrentUserState);

    const getData = useGetCounterOfUser();
    const navigate = useNavigate();

    useEffect(() => {

        const updateCounters = () => {
            getData().then((res) => {
                if (res.status === ReqStatus.SUCCESS && res.data) {
                    setCountFolder(res.data.count_folders)
                    setCountOrder(res.data.count_orders)
                    setCountFolderNotAttributed(res.data.count_folders_not_attributed)
                }
            })
        };

        updateCounters();
        const intervalId = setInterval(updateCounters, 60000);
        return () => {
            clearInterval(intervalId);
        }
    }, [])

    const counterFolderIsVisible = useMemo(() =>{
        if(!currentUser){ return false;}
        return [UserGroupEnum.SUPERADMIN, UserGroupEnum.ADMIN, UserGroupEnum.COMMERCIAL].includes(currentUser.user_group_id);      
    },[currentUser] )

    const counterOrderIsVisible = useMemo(() =>{
        if(!currentUser){ return false;}
        return [UserGroupEnum.SUPERADMIN, UserGroupEnum.ADMIN, UserGroupEnum.GESTIONNAIRE].includes(currentUser.user_group_id);
    },[currentUser] )

    const counterFolderNotAffectedIsVisible = useMemo(() =>{
        if(!currentUser){ return false;}
        return [UserGroupEnum.SUPERADMIN, UserGroupEnum.ADMIN].includes(currentUser.user_group_id);
    },[currentUser] )

    return (
        <Box mx={1}>
            <Card>
                
                <Box mr={1} p={1} display="flex" alignItems={"center"}>

                    {counterFolderNotAffectedIsVisible &&
                    <Box marginInline = {1}>
                        <Chip onClick={() => navigate(`/leads`)} label={countFolderNotAttributed} color={"secondary"} variant="filled" /> Lead{countFolderNotAttributed > 1 && 's'}
                    </Box>
                    }
                    
                    {counterFolderIsVisible &&
                    <Box marginInline = {1}>
                        <Chip onClick={() => navigate(`/folders`)} label={countFolder} color={"primary"} variant="filled" /> Blanc{countFolder > 1 && 's'}
                    </Box>
                    }

                    {counterOrderIsVisible &&
                    <Box marginInline = {1}>
                        <Chip onClick={() => navigate(`/quotes-resa`)} label={countOrder} color={"success"} variant="filled" /> Dossier{countOrder > 1 && 's'} Résa
                    </Box>
                    }
                </Box>
            </Card>
        </Box>
    );
};

export default FolderCounter;