import { useCallback, useEffect, useMemo, useState } from "react";
import { useGetQuoteList } from "../../stores/FoldersStore";
import { ReqStatus } from "../../../stores/core/UseApi";
import { SelectOptionsProps } from "../../../components/common/form/SelectField";
import { EnumQuoteStatus } from "../../classes/QuoteModel";

export const useQuoteList = (folderId: string) => {
	const get = useGetQuoteList();
	const [quotes, setQuotes] = useState<any[]>([]);
	const getQuotesAjax = useCallback(() => {
		if (isNaN(+folderId)) { return; }
		get(folderId).then((res) => {
			if (res.status === ReqStatus.SUCCESS && res.data) {
				setQuotes(res.data.quotes ?? []);
			}
		})
	}, [folderId]);

	const quoteOptions = useMemo(() => {
		let options: SelectOptionsProps[] = [];
		quotes.forEach((quote) => {
			if (quote.order_status.unique_key !== EnumQuoteStatus.CANCELED) {
				options.push({
					value: quote.id,
					label: `Devis ${quote.id} - ${quote.order_status.label}`
				});
			}
		})
		return options;
	}, [quotes]);

	useEffect(() => {
		getQuotesAjax();
	}, []);

	return { quoteOptions, quotes }
}