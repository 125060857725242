import { ListModelCore, ListSearchModelCore } from "../../../classes/list/ListModel";
import { useGetListCore } from "../../../stores/core/StoreCore";
import { useCallback, useEffect, useMemo, useState } from "react";
import ListSearchServer from "../../../components/common/list/ListSearchServer";
import { Box, Typography } from "@mui/material";
import { ReqStatus } from "../../../stores/core/UseApi";
import moment from "moment";
import FolderReminderItem from "./FolderReminderItem";
import ListSearchFilterDateRange from "../../../components/common/list/ListSearchFilterDateRange";

export interface FolderReminderListModel extends ListModelCore {
    folderReminders: any[]
}

export const FolderReminderGlobalList = () => {
    const routeApiController = "project-dahan/dahan/folder-reminders";
    const [get] = useGetListCore<FolderReminderListModel>(`/${routeApiController}/index`);

    const [search, setSearch] = useState<ListSearchModelCore | undefined>(undefined);
    const [datas, setDatas] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);

    const refresh = useCallback((csv?: boolean | undefined, pdf?: boolean | undefined, xls?: boolean | undefined, reinit?: boolean | undefined) => {
        refreshWithSearch(search, csv, pdf, xls, reinit);
    }, [search]);

    const refreshWithSearch = useCallback((search: ListSearchModelCore | undefined, csv?: boolean, pdf?: boolean, xls?: boolean | undefined, reinit?: boolean | undefined) => {
        setLoading(true); // Set loading to true before fetching data
        get(500, 0, search, csv, pdf, xls, reinit)
            .then((res) => {
                if (res.status === ReqStatus.SUCCESS && res.data) {
                    setDatas(res.data.folderReminders);
                    setSearch(res.data.search);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false); // Set loading to false after the data has been fetched
            });
    }, [get]);

    useEffect(() => {
        refresh();
    }, []);

    const reminderGroups = useMemo(() => {

        const groupedData: any[] = [];
        let currentGroup: any[] = [];

        for (let i = 0; i < datas.length; i++) {
            if (i === 0 || moment(datas[i].reminder_date).format('DD-MM-YY') === moment(datas[i - 1].reminder_date).format('DD-MM-YY')) {
                currentGroup.push(datas[i]);
            } else {
                groupedData.push(currentGroup);
                currentGroup = [datas[i]];
            }
        }
        groupedData.push(currentGroup);
        return groupedData;
    }, [datas]);

    const isToday = useCallback((reminder_date: string) => {
        return moment().format('DD-MM-YY') === moment(reminder_date).format('DD-MM-YY') ? { color: "blue" } : {};
    }, []);

    const updateActive = useCallback((id: number, active: boolean) => {
        setDatas((d) => {
            let data = d.find(x => x.id === id);
            if (data) {
                data.active = active;
            }
            return [...d];
        });
    }, [datas]);

    return (
        <Box height={"100%"} overflow={"auto"}>
            <Box m={2}>
                {search &&
                    <ListSearchServer
                        search={search}
                        refreshWithSearch={refreshWithSearch}
                        setSearch={setSearch}
                        refresh={refresh} />}

                <ListSearchFilterDateRange
                    period="week"
                    setSearch={setSearch}
                    refresh={refreshWithSearch}
                    loading={loading}
                    fieldName="reminder_date"
                />

            </Box>

            <Box display="flex" mx={2}>
                {reminderGroups.map((group: any[], index: number) => (
                    <>
                        {group.length > 0 &&
                            <Box mr={2} key={"reminder_" + index}>

                                <Box width={300} p={1}>
                                    <Box>
                                        <Typography variant="h4" style={isToday(group[0].reminder_date)}>{moment(group[0].reminder_date).format('DD-MM-YY')} ({group.length})</Typography>
                                    </Box>

                                    <Box>
                                        {group.map((item, index) => (
                                            <FolderReminderItem item={item} key={index} updateActive={updateActive} />
                                        ))}
                                    </Box>

                                </Box>
                            </Box>
                        }
                    </>
                ))}
            </Box>
        </Box>
    );
};

export default FolderReminderGlobalList;