import { useNavigate, useParams } from "react-router-dom";
import { useAddEditSupplier, useGetSupplier } from "../../stores/SuppliersStore";
import { useEffect, useMemo, useState } from "react";
import { ReqStatus } from "../../stores/core/UseApi";
import { Box, Divider, Grid, TextField } from "@mui/material";
import InputField from "../common/form/InputField";
import TextAreaField from "../common/form/TextAreaField";
import AddEditFormPanel, { useAddEditForm } from "../common/form/AddEditFormPanel";
import AddEditFormSubmit from "../common/form/AddEditFormSubmit";
import AutocompleteField, { AutocompleteOption } from "../common/form/AutocompleteField";
import { SupplierModel } from "../../classes/suppliers/SupplierModel";
import { useField, useForm, useFormValues, useIsDirty } from "react-recoil-form";
import AddEditFormList from "../common/form/AddEditFormList";
import { useSetRecoilState } from "recoil";
import { NavigateState, NavigationBlockedState } from "../../states/MenuState";
import { useAddEditCore, useViewCore } from "../../stores/core/StoreCore";
import SelectField, { SelectOptionsProps } from "../common/form/SelectField";
import AddEditForm from "../common/form/AddEditForm";
import AddEditFormCard from "../common/form/AddEditFormCard";
import { mapArrayForForm } from "../../utils/DataUtils";

interface ProductResponse {
    product: any,
    categories: { [id: string]: string },
    productTypes: { [id: string]: string },
    products: any[],
    suppliers: any[],
}

const ProductIntegratedView = (props: { id: string | undefined, products: AutocompleteOption[] }) => {
    const { id, products } = props;
    return (
        <AddEditFormList
            fields={[{
                name: "id",
                type: "autocomplete",
                label: "Produit",
                autocompleteOptions: products,
                xs: 4,
                required: true
            },
            {
                name: "_joinData.quantity",
                type: "text",
                label: "Quantité",
                xs: 3,
            },
            {
                name: "_joinData.sort_order",
                type: "number",
                label: "Ordre",
                xs: 3,
            }]}

            hiddenFields={{
                add: ["_joinData.package_id"],
                edit: []
            }}

            entity={"children_products"}
            title={"Produits intégrés"}
            buttonAdd={{ title: "Ajouter un produit" }}
            defaultItem={{
                _joinData: {
                    package_id: id ?? 0,
                    quantity: 1,
                    sort_order: 0
                }
            }}
        />
    );
}

export default ProductIntegratedView;