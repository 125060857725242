import { Box, Card, Chip, Typography } from "@mui/material";
import InputField from "../../../components/common/form/InputField";
import TextAreaField from "../../../components/common/form/TextAreaField";
import { useMemo } from "react";

interface SendMailFormContentProps {
	templateSelected: any
}

const SendMailFormContent = (props: SendMailFormContentProps) => {

	const { templateSelected } = props;

	const htmlContent = useMemo(() => {
		return templateSelected?.html_content ?? "";
	}, [templateSelected]);

	const additionnalTextVisible = useMemo(() => {
		if (!htmlContent) { return false; }
		return htmlContent.includes("{{ params.additional_text }}") || htmlContent.includes("{{ params.additional_text | safe }}");
	}, [htmlContent]);

	const pdfsInfos = useMemo(() => {

		return (
			<>
				<Box display="flex" mb={1}>
					{templateSelected?.pdf_quote && <Box mr={1}><Chip label="PDF Devis" color="primary" /></Box>}
					{templateSelected?.pdf_proforma && <Box mr={1}><Chip label="PDF Proforma" color="primary" /></Box>}
					{templateSelected?.pdf_travelinfos && <Box mr={1}><Chip label="PDF Infos voyage" color="secondary" /></Box>}
					{templateSelected?.pdf_roadmap && <Box mr={1}><Chip label="PDF Feuille de route" color="secondary" /></Box>}
					{templateSelected?.pdf_bill && <Box><Chip label="PDF Facture" color="success" /></Box>}
				</Box>
				<Box  display="flex" >
					{templateSelected?.link_quote_valide && <Box mr={1}><Chip label="Lien Signature Devis" color="primary" /></Box>}
					{templateSelected?.link_travelinfos_confirm && <Box><Chip label="Lien Confirmation Infos voyage" color="primary" /></Box>}
				</Box>
			</>
		)
	}, [templateSelected])

	return (
		<>
			{templateSelected &&
				<Box mt={2}>
					<Box my={1}>
						<InputField
							name="subject"
							type="text"
							label="Objet"
							required
						/>
					</Box>
					{additionnalTextVisible &&
						<Box my={1}>
							<TextAreaField
								name="additional_text"
								label="Message complémentaire"
								maxRows={20}
							/>
							<Typography variant="caption">Champs réservé au service exploitation</Typography><br />
							<Typography variant="caption">{"Ce message sera utilisé à la place {{ params.additional_text }}"}</Typography>
						</Box>
					}

					{pdfsInfos}
					<Box my={1}>
						<Typography variant="body1">Message</Typography>
						<Card variant="outlined"><Box m={1}><div dangerouslySetInnerHTML={{ __html: htmlContent }} /></Box></Card>
					</Box>
				</Box>
			}
		</>
	)
}

export default SendMailFormContent;