import { AppBar, Box, IconButton, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Theme } from "@mui/system";
import Image from "mui-image";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { DefaultRouteState, LogoPathState, MenuIndexState, MenuVisibleState } from "../states/MenuState";
import HeaderAvatar from "./HeaderAvatar";
import HeaderLoader from "./HeaderLoader";
import MenuIcon from '@mui/icons-material/Menu';
import { HeaderSearch } from "../custom/Routes";

const Header = () => {
    const menuIndex = useRecoilValue(MenuIndexState);
    const title = useMemo(() => {
        return menuIndex.title;
    }, [menuIndex]);

    const setMenuVisible = useSetRecoilState(MenuVisibleState);
    const logoPath = useRecoilValue(LogoPathState);
    const navigate = useNavigate();
    const defaultRoute = useRecoilValue(DefaultRouteState);
    const theme = useTheme<Theme>();
    const isWeb = useMediaQuery(theme.breakpoints.up('sm'));

    const handleMenuToggle = () => {
        setMenuVisible(prev => !prev);
    };

    // Utiliser useMediaQuery pour définir des tailles de texte différentes selon la taille de l'écran
    const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
    const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.between('xs', 'sm'));
    const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.between('sm', 'md'));
    const isLg = useMediaQuery((theme: Theme) => theme.breakpoints.between('md', 'lg'));
    const isXl = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

    let titleFontSize;
    if (isXs) {
        titleFontSize = '1.2rem';
    } else if (isSm) {
        titleFontSize = '1.5rem';
    } else if (isMd) {
        titleFontSize = '1.8rem';
    } else if (isLg) {
        titleFontSize = '2rem';
    } else if (isXl) {
        titleFontSize = '2.2rem';
    }

    return (
        <AppBar position="relative" elevation={0} sx={{ zIndex: { xs: 1500, md: 'inherit' } }}>
            <Toolbar>
                <Box display="flex" justifyContent={"space-between"} width={"100%"}>
                    <Box display="flex" alignItems={"center"} >
                        <Box mr={2} alignItems={"center"}>
                            <IconButton sx={{ color: "white" }} onClick={handleMenuToggle}>
                                <MenuIcon />
                            </IconButton>
                        </Box>
                        {logoPath &&
                            <Box display="flex" alignItems={"center"} mr={1} sx={{ display: { xs: 'none', sm: 'flex', cursor: "pointer" } }}
                                onClick={() => navigate(defaultRoute)}
                            >
                                <Image fit="revert" height={50} width={"auto"} src={logoPath} errorIcon={null} />
                            </Box>
                        }
                        <Box>
                            <Typography variant="h4" sx={{ fontSize: titleFontSize }} >
                                <Box display="flex" alignItems={"center"}>{title ? `- ${title}` : ''}</Box>
                            </Typography>
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent={"flex-end"} alignItems={"center"} >
                        <HeaderSearch />
                        <HeaderAvatar />
                    </Box>
                </Box>
            </Toolbar>
            <HeaderLoader />
        </AppBar>
    )
}

export default Header;
