import { useNavigate, useParams } from "react-router-dom";
import { useAddEditProductType, useGetProductType } from "../../stores/ProductTypesStore";
import AddEditFormPanel, { useAddEditForm } from "../common/form/AddEditFormPanel";
import AddEditFormSubmit from "../common/form/AddEditFormSubmit";
import { ReqStatus } from "../../stores/core/UseApi";
import { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import InputField from "../common/form/InputField";
import { useAddEditCore } from "../../stores/core/StoreCore";
import AddEditForm from "../common/form/AddEditForm";
import AddEditFormCard from "../common/form/AddEditFormCard";

const CountryView = () => {
    const listRoute = "/commercial/countries";
    const { getData, addEditData } = useAddEditCore<any, { country: any }>(`${listRoute}/add-edit`);

    let { id } = useParams();
    const navigate = useNavigate();

    const onSubmit = (request: any) => {
        addEditData(request, id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                navigate(listRoute);
            }
        })
    }

    const { handleSubmit, resetInitialValues, isDirty } = useAddEditForm({ onSubmit, initialValues: { id: "", label: "", vat: 0 } });
    useEffect(() => {
        getData(id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                if (id) {
                    const _country = res.data.country;
                    resetInitialValues({
                        id: _country.id,
                        label: _country.label,
                        vat: _country.vat
                    });
                }
            } else {
                navigate(listRoute);
            }
        })
    }, [id]);

    return (

        <AddEditForm
            formId="countryForm"
            formHandleCancel={resetInitialValues}
            formHandleSubmit={handleSubmit}
            isDirty={isDirty}
        >
            <AddEditFormCard title={(!id ? "Ajouter " : "Modifier") + " un pays"}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <InputField name="id" label="ID" type="text" required disabled={id ? true : false} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputField name="label" label="Label" type="text" required />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputField name="vat" label="TVA" type="number" required />
                    </Grid>
                </Grid>
            </AddEditFormCard>
        </AddEditForm>
    )
}

export default CountryView;