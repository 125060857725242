import { useNavigate, useParams } from "react-router-dom";
import { useAddEditProductType, useGetProductType } from "../../stores/ProductTypesStore";
import AddEditFormPanel, { useAddEditForm } from "../common/form/AddEditFormPanel";
import AddEditFormSubmit from "../common/form/AddEditFormSubmit";
import { ReqStatus } from "../../stores/core/UseApi";
import { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import InputField from "../common/form/InputField";
import AddEditForm from "../common/form/AddEditForm";
import AddEditFormCard from "../common/form/AddEditFormCard";

const ProductTypeView = () => {

    const listRoute = "/commercial/product-types";
    let { id } = useParams();
    const navigate = useNavigate();
    const [get] = useGetProductType();
    const [addOrEdit] = useAddEditProductType();

    const onSubmit = (request: any) => {
        addOrEdit(request, id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                navigate(listRoute);
            }
        })
    }

    const { handleSubmit, resetInitialValues, isDirty } = useAddEditForm({ onSubmit, initialValues: { label: "", vat: 0 } });

    useEffect(() => {
        get(id ? +id : undefined).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                if (id) {
                    resetInitialValues({
                        label: res.data.productType.label,
                        vat: res.data.productType.vat
                    });
                }
            } else {
                navigate(listRoute);
            }
        })
    }, [id]);

    return (
        <AddEditForm formId="productTypeForm" formHandleCancel={resetInitialValues} formHandleSubmit={handleSubmit} isDirty={isDirty}>
            <AddEditFormCard title={`${!id ? "Ajouter" : "Modifier"} un type de produit`} maxWidth="md">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <InputField
                            name="label"
                            label="Nom"
                            type="text"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputField name="vat" label="TVA %" type="number" required />
                    </Grid>
                </Grid>
            </AddEditFormCard>
        </AddEditForm>
    )
}

export default ProductTypeView;