import { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import AddEditFormPanel, { useAddEditForm } from "../../../components/common/form/AddEditFormPanel";
import { useAddEditCore } from "../../../stores/core/StoreCore";
import AutocompleteField, { AutocompleteOption } from "../../../components/common/form/AutocompleteField";
import { ReqStatus } from "../../../stores/core/UseApi";
import InputField from "../../../components/common/form/InputField";
import TextAreaField from "../../../components/common/form/TextAreaField";
import AddEditFormSubmit from "../../../components/common/form/AddEditFormSubmit";

interface AddressResponse {
    address: any,
    countries: { [id: string]: string }
}
const AddressCustomView = (props: { id: string | undefined, customerId: string, onClose: () => void, onValidate: () => void }) => {

    const { id, customerId, onClose, onValidate } = props;

    const listRoute = "/commercial/addresses";
    const { getData, addEditData } = useAddEditCore<any, AddressResponse>(`${listRoute}/add-edit`);
    const [countryOptions, setCountryOptions] = useState<AutocompleteOption[]>([]);

    const onSubmit = (request: any) => {
        let _request = JSON.parse(JSON.stringify(request)) as any;
        addEditData(_request, id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                onValidate();
            }
        });
    }

    const { handleSubmit, resetInitialValues, isDirty } = useAddEditForm({
        onSubmit
    });

    useEffect(() => {
        getData(id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                const _address = res.data.address;

                if (res.data.countries) {
                    let _countries: AutocompleteOption[] = [];
                    Object.entries(res.data.countries).forEach(([country_id, country]) => {
                        _countries.push({ id: country_id, label: country });
                    })
                    setCountryOptions(_countries);
                } else {
                    setCountryOptions([]);
                }

                if (id) {
                    resetInitialValues({
                        customer_id: customerId,
                        company_name: _address.company_name,
                        label: _address.label,
                        first_name: _address.first_name,
                        last_name: _address.last_name,
                        email: _address.email,
                        phone: _address.phone,
                        address_1: _address.address_1,
                        address_2: _address.address_2,
                        postcode: _address.postcode,
                        city: _address.city,
                        country_id: _address.country_id,
                        dahan_info_sup_1: _address.dahan_info_sup_1,
                        dahan_info_sup_2: _address.dahan_info_sup_2,
                        dahan_info_sup_3: _address.dahan_info_sup_3,
                        dahan_info_sup_4: _address.dahan_info_sup_4,
                    });
                } else {
                    resetInitialValues({
                        customer_id: customerId,
                        company_name: "",
                        label: "",
                        first_name: "",
                        last_name: "",
                        email: "",
                        phone: "",
                        address_1: "",
                        address_2: "",
                        postcode: "",
                        city: "",
                        country_id: "FR" ,
                        dahan_info_sup_1: "",
                        dahan_info_sup_2: "",
                        dahan_info_sup_3: "",
                        dahan_info_sup_4: "",
                    })
                }
            } else {
                onClose();
            }
        });
    }, [id]);


    return (
        <AddEditFormPanel
            title={(!id ? "Ajouter " : "Modifier") + " un adresse"}
        >
            <form id={"addressForm"} onSubmit={handleSubmit}>
                <Grid container spacing={3} mt={1}>
                    <Grid item xs={12} sm={6}>
                        <InputField
                            name="label"
                            label="Label"
                            type="text"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputField
                            name="company_name"
                            label="Société"
                            type="text"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <InputField
                            name="last_name"
                            label="Nom"
                            type="text"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputField
                            name="first_name"
                            label="Prénom"
                            type="text"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputField
                            name="email"
                            label="Email"
                            type="text"
                            isEmail
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputField
                            name="phone"
                            label="Téléphone"
                            type="text"
                            isPhone
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <InputField
                            name="dahan_info_sup_1"
                            label="Information complémentaire n°1"
                            type="text"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputField
                            name="dahan_info_sup_2"
                            label="Information complémentaire n°2"
                            type="text"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputField
                            name="dahan_info_sup_3"
                            label="Information complémentaire n°3"
                            type="text"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputField
                            name="dahan_info_sup_4"
                            label="Information complémentaire n°4"
                            type="text"
                        />
                    </Grid>

                    <Grid item xs={12} >
                        <TextAreaField
                            name="address_1"
                            label="Adresse"
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputField
                            name="address_2"
                            label="Complément d'adresse"
                            type="text"
                        />
                    </Grid>

                    <Grid item xs={12} display="flex">
                        <InputField
                            name="postcode"
                            label="Code postal"
                            required
                            type="text"
                        />
                        <InputField
                            name="city"
                            label="Ville"
                            required
                            type="text"
                        />
                    </Grid>

                    <Grid item xs={12} >
                        <AutocompleteField
                            name="country_id"
                            label="Pays"
                            required
                            options={countryOptions}
                        />
                    </Grid>
                </Grid>

                <Box mt={2}>
                    <AddEditFormSubmit
                        returnAction={{disabled: true }}
                        cancelEvent={() => { resetInitialValues(); onClose(); }}
                        disabled={!isDirty}
                    />
                </Box>

            </form>
        </AddEditFormPanel >
    );
}

export default AddressCustomView;