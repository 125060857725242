import { Box, Button, Grid, Paper, Skeleton, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useForm, useIsDirty } from "react-recoil-form";
import { ReqStatus } from "../../stores/core/UseApi";
import { useEffect, useState } from "react";
import { useGetPasswordUser, useEditPasswordUser } from "../../stores/UsersStore";
import { UserModel } from "../../classes/users/UserModel";
import PasswordField from "../common/form/PasswordField";
import { useNavigate, useParams } from "react-router";
import AddEditForm from "../common/form/AddEditForm";
import AddEditFormCard from "../common/form/AddEditFormCard";

const UserPasswordView = () => {
    let { id } = useParams();

    const [getAjax] = useGetPasswordUser();
    const [editAjax] = useEditPasswordUser();
    const navigate = useNavigate();
    const [user, setUser] = useState<UserModel>();
    const [ready, setReady] = useState(false);

    useEffect(() => {
        //TODO Erreur boundary
        if (!id) { return; }
        getAjax(+id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                setUser(res.data.user);
                setReady(true);
            }
        })
    }, [])

    const onSubmit = (request: UserModel) => {
        if (!id) { return; }
        editAjax(+id, request).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                navigate(`/users/add-edit/${id}`);
            }
        })
    }

    const { handleSubmit, resetInitialValues } = useForm({
        onSubmit,
    });

    const isDirty = useIsDirty();

    return (

        <AddEditForm formId="userPasswordForm" formHandleCancel={resetInitialValues} formHandleSubmit={handleSubmit} isDirty={isDirty}>

            <AddEditFormCard title={`Modifier le mot de passe - ${user?.first_name} ${user?.last_name}`}>
                <Grid container spacing={3} mt={1}>
                    <Grid item xs={12} sm={6}>
                        <PasswordField
                            name="new_password"
                            label="Nouveau mot de passe"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <PasswordField
                            required
                            name="new_password_confirm"
                            label="Confirmer nouveau mot de passe"
                        />
                    </Grid>
                </Grid>
            </AddEditFormCard>
         
        </AddEditForm>
    );
}

export default UserPasswordView;