
import { SettingsSectionRoutes } from './routes/SettingsSectionRoutes';
import { StockSectionRoutes } from './routes/StockSectionRoutes';
import { CustomerSectionRoutes } from './routes/CustomerSectionRoutes';
import { ComptaSectionRoutes } from './routes/ComptaSectionRoutes';
import { UserSectionRoutes } from './routes/UserSectionRoutes';

export const DefaultRoutes = [
    /* {
        title: "Dashboard",
        index: "DASHBOARD",
        children: [
            {
                index: "DASHBOARD",
                path: "/",
                title: "Tableau de bord",
                element: () => <></>,
                menuVisible: true,
                menuIcon: <DashboardIcon />
            }
        ]
    } as SectionItem, */

    /* Utilisateurs */
    UserSectionRoutes,
    
    /* Clients */
    CustomerSectionRoutes,
    
    /* Produits / Fournisseurs */
    StockSectionRoutes,
    
    /* Comptabilité */
    ComptaSectionRoutes,
    
    /* Paramètres */
    SettingsSectionRoutes
];