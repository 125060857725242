import { useNavigate, useParams } from "react-router-dom";
import { useAddEditCore } from "../../../stores/core/StoreCore";
import { ReqStatus } from "../../../stores/core/UseApi";
import { useAddEditForm } from "../../../components/common/form/AddEditFormPanel";
import { useEffect, useMemo, useState } from "react";
import AddEditForm from "../../../components/common/form/AddEditForm";
import AddEditFormCard from "../../../components/common/form/AddEditFormCard";
import { Box, Grid } from "@mui/material";
import InputField from "../../../components/common/form/InputField";
import { useField } from "react-recoil-form";
import { TagItem } from "../folders/FolderTagItem";
import CheckboxField from "../../../components/common/form/CheckboxField";
import TagForm from "../tags/TagForm";
const ReminderTagView = () => {
    const listRoute = "/project-dahan/dahan/reminder-tags";
    const listRouteCustom = '/reminder-tags';
    const { getData, addEditData } = useAddEditCore<any, { reminderTag: any }>(`${listRoute}/add-edit`);

    let { id } = useParams();
    const navigate = useNavigate();

    const onSubmit = (request: any) => {
        addEditData(request, id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                navigate(listRouteCustom);
            }
        })
    }

    const { handleSubmit, resetInitialValues, isDirty } = useAddEditForm({ onSubmit, initialValues: { name: "" } });
    useEffect(() => {
        getData(id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                if (id) {
                    const _data = res.data.reminderTag;

                    resetInitialValues({
                        name: _data.name,
                        color: _data.color,
                        backcolor: _data.backcolor,
                        systeme: _data.systeme,
                        commercial: _data.commercial,
                    });
                }
            } else {
                navigate(listRouteCustom);
            }
        })
    }, [id]);

    const nameField = useField({ name: 'name' });
    const colorField = useField({ name: 'color' });
    const backcolorField = useField({ name: 'backcolor' });

    const tag = useMemo(() => {
        return {
            name: nameField.fieldValue,
            color: colorField.fieldValue,
            backcolor: backcolorField.fieldValue
        };
    }, [nameField.fieldValue, colorField.fieldValue, backcolorField.fieldValue]);

    return (

        <AddEditForm
            formId="reminderTagForm"
            formHandleCancel={resetInitialValues}
            formHandleSubmit={handleSubmit}
            isDirty={isDirty}
        >
            <AddEditFormCard maxWidth="md" title={(!id ? "Ajouter " : "Modifier") + " un tag d'agenda"}>
                <TagForm
                    systeme
                    commercial
                />
            </AddEditFormCard>
        </AddEditForm>
    )
}

export default ReminderTagView;