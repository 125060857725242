import { Grid } from "@mui/material";
import AutocompleteField, { AutocompleteOption } from "../common/form/AutocompleteField";
import InputField from "../common/form/InputField";
import SelectField, { SelectOptionsProps } from "../common/form/SelectField";
import OrderAddressesView from "./OrderAddressesView";
import { useParams } from "react-router-dom";


interface OrderHeaderProps{
    customers: AutocompleteOption[],
    orderStatus: SelectOptionsProps[]
    paymentStatus:SelectOptionsProps[]
    shippingStatus:SelectOptionsProps[],
    disabled? :boolean,
    customer? : boolean,
}
const OrderHeader = (props: OrderHeaderProps) => {
    const { id } = useParams();
    const { customers, orderStatus, paymentStatus, shippingStatus, disabled, customer} = props;
    return (
        <Grid container spacing={3} >
            <Grid item xs={12} sm={6}>
                <AutocompleteField
                    options={customers}
                    name={`customer_id`}
                    required
                    label="Client"
                    disabled={!!id}
                    defaultIndex={customer ? 0 : undefined}
                />
            </Grid>

            {!customer &&
                <>
                <Grid item xs={12} sm={6}>
                    <InputField
                        name="reference"
                        label="reference"
                        type="text"
                    />
                </Grid>

                    <Grid item xs={12} sm={6}>
                        <SelectField
                            name="order_status_id"
                            label="Statut de commande"
                            options={orderStatus}
                        />
                    </Grid>

                <Grid item xs={12} sm={6}>
                    <SelectField
                        name="payment_status_id"
                        label="Statut de paiement"
                        options={paymentStatus}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <SelectField
                        name="shipping_status_id"
                        label="Statut de livraison"
                        options={shippingStatus}
                    />
                </Grid>
                 </>

            }

            <Grid item xs={12} sm={6}></Grid>

            <OrderAddressesView disabled={disabled} customer={customer} />
        </Grid>
    );
}

export default OrderHeader;
