import { atom } from "recoil";

export const SmsModalState = atom<{
        open: boolean
    }>({
    key: 'SmsModalState',
    default: {
        open: false
    }
});
