
import { Box, Button, Menu, MenuItem, TextField, Typography } from '@mui/material';
import React, { startTransition, useCallback, useMemo, useState } from 'react';
import { DragDropContext, Draggable, DraggableProvided, Droppable } from 'react-beautiful-dnd';
import AddEditFormCard from '../../../../components/common/form/AddEditFormCard';
import { QuoteCircuitDragIcon } from './QuoteCircuitCommon';
import { useRecoilState } from 'recoil';
import { QuoteCircuitDayStepSelector } from '../../../states/QuoteCircuitState';
import { v4 as uuidv4 } from 'uuid';
import { QuoteCircuitDayStepAddressModel } from '../../../classes/QuoteModel';
import QuoteCircuitDayStepAddress from './QuoteCircuitDayStepAddress';
import { transformStringToSlug } from '../../../utils/StringUtils';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MapIcon from '@mui/icons-material/Map';
import { openMappyEvent } from '../../tomtom/TomTomCalculateRoute';
import QuoteCircuitDayStepMappy from './QuoteCircuitDayStepMappy';

interface QuoteCircuitDayStepProps {
	id: string,
	dayId: string,
	provided: DraggableProvided,
	addCircuitDayStepEvent: () => void,
	duplicateCircuitDayStepEvent: () => void,
	deleteEvent: () => void,
	isExpandDefault?: boolean,
}

const QuoteCircuitDayStep = (props: QuoteCircuitDayStepProps) => {
	const { id, dayId, provided, deleteEvent, addCircuitDayStepEvent, duplicateCircuitDayStepEvent, isExpandDefault } = props;

	const [step, setStep] = useRecoilState(QuoteCircuitDayStepSelector({ id: id, dayId: dayId }));
	const [cardIsExpand, setCardIsExpand] = useState<boolean | undefined>(isExpandDefault ?? false);

	const addresses = useMemo(() => {
		if (!step) { return []; }
		return step.addresses;
	}, [step?.addresses]);

	const maxSort = useMemo(() => {
		return addresses.length > 0 ? addresses.reduce((max, currentDay) => currentDay.sort > max ? currentDay.sort : max, addresses[0].sort) : -1;
	}, [addresses])

	const onDragEnd = useCallback((result: any) => {
		if (!result.destination) {
			return;
		}

		setStep((s) => {
			if (!s) { return s; }
			const newAddresses = JSON.parse(JSON.stringify(s.addresses)) as QuoteCircuitDayStepAddressModel[];
			const startIndex = result.source.index;
			const endIndex = result.destination.index;
			// Trouver l'élément déplacé
			const [removed] = newAddresses.splice(startIndex, 1);
			// Insérer l'élément déplacé à sa nouvelle position
			newAddresses.splice(endIndex, 0, removed);
			// Mise à jour des valeurs de 'sort' pour refléter le nouvel ordre
			newAddresses.forEach((step, index) => {
				step.sort = index;
			});
			return { ...s, addresses: newAddresses };
		})
	}, []);

	const addCircuitDayStepAddressEvent = useCallback(() => {
		setStep((s) => {
			if (!s) { return s; }
			return {
				...s,
				addresses: [
					...s.addresses,
					{
						id: uuidv4(),
						sort: maxSort + 1,
						type: null,
						address: "",
					}
				]
			};
		});
		setCardIsExpand(true);
		//HACK - Pour que la valeur boolean puisse être de nouveau interprété
		setTimeout(() => {
			setCardIsExpand(undefined);
		}, 1000)
	}, [maxSort]);

	const deleteAddressEvent = useCallback((id: string) => {
		setStep((s) => {
			if (!s) { return s; }
			let newAddresses = JSON.parse(JSON.stringify(s.addresses)) as QuoteCircuitDayStepAddressModel[];
			newAddresses = newAddresses.filter((a) => a.id !== id);
			newAddresses.forEach((address, index) => {
				address.sort = index;
			});
			return {
				...s,
				addresses: newAddresses
			}
		})
	}, []);

	const onChangeStep = useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, property: "hour" | "pax" | "distance" | "cars") => {
		startTransition(() => {
			setStep((step) => {
				if (!step) { return step; }
				return {
					...step,
					[property]: event.target.value ?? ""
				};
			});
		});
	}, [])

	const onChangeHour = useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		onChangeStep(event, "hour");
	}, []);
	const onChangePax = useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		onChangeStep(event, "pax");
	}, []);
	const onChangeCars = useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		onChangeStep(event, "cars");
	}, []);
	const onChangeDistance = useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		onChangeStep(event, "distance");
	}, [])

	const calculDistanceEvent = useCallback(() => {
		if (addresses.length == 0) { return; }
		/*let url = "https://fr.distance.to";
		addresses.forEach((address) => {
			url += `/${transformStringToSlug(address.address)}`;
		})
		window.open(url, "_blank");
*/

		const addressPairs = [];
		for (let i = 0; i < addresses.length - 1; i++) {

			if (!addresses[i].address || !addresses[i + 1].address) {
				continue;
			}
			addressPairs.push({
				start: addresses[i].address,
				end: addresses[i + 1].address
			});
		}

		addressPairs.forEach((p) => {
			openMappyEvent(p.start, p.end);
		})

	}, [addresses]);

	const AddressesContent = useMemo(() => {
		return (
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="QuoteCircuitDayStep" direction="vertical">

					{(provided, snapshot) => (
						<Box {...provided.droppableProps} ref={provided.innerRef} display="flex" flexDirection={"column"} >

							{addresses.map((address, index) => (
								<Draggable key={address.id} draggableId={address.id} index={address.sort}>
									{(provided, snapshot) => (
										<QuoteCircuitDayStepAddress
											id={address.id}
											stepId={id}
											dayId={dayId}
											provided={provided}
											deleteEvent={() => deleteAddressEvent(address.id)}
										/>
									)}
								</Draggable>
							))}

							{provided.placeholder}
						</Box>
					)}
				</Droppable>
			</DragDropContext>
		);
	}, [id, dayId, addresses, deleteAddressEvent, onDragEnd])

	return (
		<Box
			ref={props.provided.innerRef}
			{...props.provided.draggableProps}
			mb={1}
		>
			<AddEditFormCard
				title={
					<Box display="flex" alignItems={"center"}>
						<QuoteCircuitDragIcon
							dragHandleProps={provided.dragHandleProps}
							iconColor='success'
						/>
						<Box display={"flex"} ml={1} alignItems={"center"}>
							<Box width={100} mr={2} >
								<TextField type="time" size="small" label="Heure" onBlur={onChangeHour} defaultValue={step?.hour} />
							</Box>
							<Box width={70} mr={2}>
								<TextField type="number" size="small" label="Pax" onBlur={onChangePax} defaultValue={step?.pax} />
							</Box>
							<Box width={70} mr={2}>
								<TextField type="number" size="small" label="Cars" onBlur={onChangeCars} defaultValue={step?.cars} />
							</Box>
							<Box width={90} mr={2}>
								<TextField type="number" size="small" label="KMs" onBlur={onChangeDistance} defaultValue={step?.distance} />
							</Box>
						</Box>

					</Box>
				}
				withExpand={true}
				withExpandByTitle={false}
				isExpandRecompileChildren
				isExpand={cardIsExpand}
				action={
					<Box display="flex">
						<Box>
							<Button size="small" variant="contained" color="secondary" onClick={() => addCircuitDayStepAddressEvent()}>+ adresse</Button>
						</Box>
						<Box ml={2}>
							<QuoteCircuitDayStepMappy addresses={addresses} id={id} />
						</Box>
						<Box ml={2}>
							<Button size="small" variant="contained" color="success" onClick={() => addCircuitDayStepEvent()}>+ étape</Button>
						</Box>
						<Box ml={2}>
							<Button size="small" variant="contained" color="success" onClick={() => duplicateCircuitDayStepEvent()}><ContentCopyIcon /> étape</Button>
						</Box>
						<Box ml={2}>
							<Button size="small" color="error" variant="outlined" onClick={() => deleteEvent()}>Supprimer</Button>
						</Box>
					</Box>
				}
				sx={{
					paper: {
						zIndex: 0,
						backgroundColor: "white"
					}
				}}
			>
				{AddressesContent}

			</AddEditFormCard >

		</Box>
	)
}

export default QuoteCircuitDayStep;


