import { useCallback, useState } from "react";
import { ListModelCore } from "../../../classes/list/ListModel";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { useIntl } from "react-intl";
import { useGetListCore } from "../../../stores/core/StoreCore";
import ListCore from "../../../components/common/list/ListCore";
import { BillListActions } from "../../../components/bills/BillList";
import { datetimeFormat } from "../../../utils/DateUtils";
import { GridColDef } from "@mui/x-data-grid";
import { getLabelOfBill } from "../../utils/BillUtil";
import { CreateColumnDate } from "../../../components/common/list/column/ColumnDate";
import FormattedAmount from "../../../components/common/formatted/FormattedAmount";


interface BillListModel extends ListModelCore {
    bills: any[]
}


const BillCustomList = () => {
    const intl = useIntl();
    
    const columns = useCallback(() => {
        return [
            {
                field: 'id',
                headerName: "N°",
                editable: false,
                filterable: false,
                minWidth: 150,
                renderCell: ({row}) => (
                    <Box>
                        <Box>{getLabelOfBill(row)}</Box>
                        <Box>{`#${row.id}`}</Box>
                    </Box>
                )
            },
            {
                field: 'comment',
                headerName: "Référence",
                editable: false,
                filterable: false,
                minWidth: 250,
            },
            {
                field: 'reference',
                headerName: "Commentaire interne",
                editable: false,
                filterable: false,
                minWidth: 150,
            },
            {
                field: 'customer',
                headerName: "Client facturé",
                editable: false,
                filterable: false,
                width: 200,
                renderCell: ({ row }) =>
                    <Box>{`${row.billing_first_name} ${row.billing_last_name}`}</Box>
            },
            {
                field: 'customer.dahan_auxiliaire',
                headerName: "Compte auxiliaire",
                editable: false,
                filterable: false,
                width: 150,
                valueGetter: ({ row }) => `${row.order.customer.dahan_auxiliaire}`
            },
            {
                field: 'total_minus_discount_tax_excl_euro',
                headerName: "Prix HT",
                editable: false,
                filterable: false,
                minWidth: 60,
                renderCell: ({ row }) => (
                    <Box display={"flex"} justifyContent={"flex-end"} width={"100%"}>
                        {row.is_credit_note && row.total_minus_discount_tax_excl !== 0 ? "-" : ""}<FormattedAmount amount={row.total_minus_discount_tax_excl} symbol="€"/>
                    </Box>
                )
            },
            {
                field: 'total_minus_discount_tax_incl_euro',
                headerName: "Prix TTC",
                editable: false,
                filterable: false,
                minWidth: 60,
                renderCell: ({ row }) => (
                    <Box display={"flex"} justifyContent={"flex-end"} width={"100%"}>
                        {row.is_credit_note && row.total_minus_discount_tax_incl !== 0 ? "-" : ""}<FormattedAmount amount={row.total_minus_discount_tax_incl} symbol="€"/>
                    </Box>
                )
            },
            CreateColumnDate({
                field: 'created',
                headerName: "Créé le",
                getValue: (row) => row.created,
                type: "dateTime"
            })
        ] as GridColDef[];
    },[intl]);
    
 

    const routeController = "commercial/bills";
    const [get] = useGetListCore<BillListModel>(`/${routeController}/index`);

    return (
        <ListCore<BillListModel, any>
            getDatas={get}
            defineDatasToSet={(data) => data.bills}
            getRowId={(row) => row.id}
            columns={columns()}
            actions={{
                defaultRoute: routeController,
                others: (row) => <BillListActions route={routeController} row={row} />
            }}
        />
    );
}

export default BillCustomList;