import React, { useCallback } from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const CopyButton = (props: { textToCopy: string | null | undefined }) => {
  const copyToClipboard = useCallback(async () => {
	if(!props.textToCopy){ return; }
    try {
      await navigator.clipboard.writeText(props.textToCopy);
      // Affichez un message de succès ou modifiez l'état ici si nécessaire
      console.log('Texte copié avec succès !');
    } catch (err) {
      // Gérez l'erreur ici si nécessaire
      console.error('Erreur lors de la copie du texte :', err);
    }
  },[props.textToCopy]);

  return (
    <Tooltip title="Copier le texte">
      <IconButton onClick={copyToClipboard}>
        <ContentCopyIcon />
      </IconButton>
    </Tooltip>
  );
}

export default CopyButton;