import { useCallback, useMemo } from 'react';
import { Alert, Box, Chip, IconButton, Tooltip } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useGetBillList } from "../../stores/FoldersStore";
import { useEffect, useState } from "react";
import { ReqStatus } from "../../../stores/core/UseApi";
import ListItemActions from "../../../components/common/list/ListItemActions";
import VisibilityIcon from '@mui/icons-material/Visibility';
import MailIcon from "@mui/icons-material/Mail";
import { Mail2ModalState } from '../../states/MailState';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useIntl } from 'react-intl';
import { datetimeFormat } from '../../../utils/DateUtils';
import { getLabelOfBill } from '../../utils/BillUtil';
import { FolderCustomerEmailSelector } from '../../states/FoldersState';
import ColumnRemainingAmount from '../common/column/ColumnRemainingAmount';
import FormattedAmount from '../../../components/common/formatted/FormattedAmount';
import { BrevoTemplateEnum } from '../../classes/BrevoMailConfig';

const FolderBillList = (props: { folderId: string }) => {
    const [forceRefresh, setForceRefresh] = useState(0);
    const { folderId } = props;
    const get = useGetBillList();
    const [rows, setRows] = useState<any[]>([]);

    const getDatas = useCallback(() => {
        if (isNaN(+folderId)) { return; }
        get(folderId).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                const payments = res.data.payments ?? [];
                const bills = res.data.bills ?? [];
                bills.forEach((bill) => {
                    bill.dahan_payments_total_amount = 0;
                    payments.forEach((payment) => {
                        if (bill.is_credit_note) {
                            return;
                        }
                        if (bill.order_id === payment.order_id &&
                            ((!bill.dahan_is_deposit) ||
                                (bill.dahan_is_deposit && payment.dahan_deposit_id === bill.id)
                            )
                        ) {
                            bill.dahan_payments_total_amount += payment.total_amount;
                        }
                    });
                    if (bill.is_credit_note) {
                        bill.dahan_left_to_pay = null;
                    } else {
                        bill.dahan_left_to_pay = bill.total_minus_discount_tax_incl - bill.dahan_payments_total_amount;
                    }
                });
                setRows(bills);
            }
        })
    }, [folderId]);

    const openPdf = useCallback((id: string) => {
        window.open(`${process.env.REACT_APP_API}commercial/bills/view-pdf/${id}.pdf`, '_blank');
    }, []);


    const setMailModalState = useSetRecoilState(Mail2ModalState);
    const customerEmail = useRecoilValue(FolderCustomerEmailSelector);
    const sendMail = useCallback((row: any) => {

        if (row.is_credit_note) {
            return;
        }

        const templateId = row.dahan_is_deposit ?
            BrevoTemplateEnum.BREVO_TPL_54_ENVOI_FACTURE_ACOMPTE :
            BrevoTemplateEnum.BREVO_TPL_57_ENVOI_FACTURE;

        const _emails = customerEmail ? [customerEmail] : [];
        setMailModalState({
            open: true,
            folderId: +folderId,
            templateIdDefault: templateId,
            entityId: row.id,
            emails: _emails
        });
    }, [setMailModalState, folderId]);

    const columns: GridColDef[] = useMemo(() => {

        return [
            {
                field: 'id',
                headerName: "N°",
                editable: false,
                filterable: false,
                width: 170,
                renderCell: ({ row }) => (
                    <Alert color={row.is_credit_note ? "error" : "success"} icon={false} sx={{ width: "100%" }}>
                        <Box>
                            <Box>
                                {getLabelOfBill(row)}
                            </Box>
                            <Box>{`#${row.id}`}</Box>
                        </Box>
                    </Alert>
                )
            },

            {
                field: 'order_id',
                headerName: "Devis N°",
                editable: false,
                filterable: false,
                width: 100,
                valueGetter: ({ row }) => `#${row.order_id}`
            },
            {
                field: 'reference',
                headerName: "Référence",
                editable: false,
                filterable: false,
                width: 150,
            },
            {
                field: 'comment',
                headerName: "Commentaire",
                editable: false,
                filterable: false,
                width: 150,
            },
            {
                field: 'customer',
                headerName: "Client facturé",
                editable: false,
                filterable: false,
                width: 150,
                renderCell: ({ row }) =>
                    <Box>
                        {`${row.billing_first_name} ${row.billing_last_name}`}
                    </Box>
            },
            {
                field: 'total_minus_discount_tax_incl_euro',
                headerName: "Prix TTC",
                editable: false,
                filterable: false,
                width: 120,
                renderCell: ({ row }) => (
                    <Box display={"flex"} justifyContent={"flex-end"} width={"100%"}>
                        <Chip
                            label={<>{row.is_credit_note && row.total_minus_discount_tax_incl !== 0 ? "- " : ""}<FormattedAmount amount={row.total_minus_discount_tax_incl} symbol="€" /></>}
                            color={row.is_credit_note ? "error" : "success"}
                            variant="filled"
                        />
                    </Box>
                )
            },
            {
                field: 'reste_a_regler',
                headerName: "Reste à régler",
                editable: false,
                filterable: false,
                width: 120,
                renderCell: ({ row }) => (<Box display={"flex"} justifyContent={"flex-end"} width={"100%"}><ColumnRemainingAmount remainingAmount={row.dahan_left_to_pay} /></Box>)
            },
            {
                field: 'actions',
                headerName: "Actions",
                editable: false,
                filterable: false,
                width: 100,
                renderCell: ({ row }) => <ListItemActions
                    defaultRoute={''}
                    others={(row) => (
                        <>
                            <Box><IconButton onClick={() => openPdf(row.id)}><VisibilityIcon /></IconButton></Box>
                            {
                                !row.is_credit_note &&
                                <Box ml={1}><Tooltip title="Mail"><IconButton onClick={() => sendMail(row)}><MailIcon /></IconButton></Tooltip></Box>
                            }
                        </>
                    )
                    }
                    row={row}
                    id={row.id}
                />
            }
        ]
    }, []);


    useEffect(() => {
        getDatas();
    }, [getDatas]);

    return (
        <Box height={371}>
            <DataGrid
                key={forceRefresh}
                disableColumnFilter
                disableRowSelectionOnClick
                //autoPageSize
                filterMode="client"
                rows={rows}
                columns={columns}
                getRowId={(row: any) => row.id}
                hideFooter
            />
        </Box >
    )
};
export default FolderBillList;