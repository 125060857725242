export interface FolderTag {
	id: number,
	folder_id:number,
	tag_id:number,
	tag : Tag
}

export interface Tag{
	id: number,
	name: string,
	color: string,
	backcolor: string,
	commercial: boolean
}

export enum EnumFolderTags {
	TRANSFERED = 35
}
