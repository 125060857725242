import { Box, Grid, Skeleton } from "@mui/material";
import AddEditFormPanel, { useAddEditForm } from "../../../components/common/form/AddEditFormPanel";
import AddEditFormSubmit from "../../../components/common/form/AddEditFormSubmit";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ReqStatus } from "../../../stores/core/UseApi";
import DateField, { getDatetimeNow } from "../../../components/common/form/DateField";
import TextAreaField from "../../../components/common/form/TextAreaField";
import { useAddEditFolderReminder, useGetReminderTagList } from "../../stores/FolderRemindersStore";
import CheckboxField from "../../../components/common/form/CheckboxField";
import { useParams } from "react-router-dom";
import { CurrentUserState } from "../../../states/AuthState";
import { useRecoilValue } from "recoil";
import { Tag } from "../../classes/FolderTagModel";
import FolderTagAutoComplete from "../folder-tags/FolderTagAutoComplete";
import { useField } from "react-recoil-form";
import JsonView from "../../../components/common/debug/JsonView";
import { cp } from "fs";

interface FolderReminderFormProps {
	id?: number,
	onClose: () => void,
	onValidate: () => void
}

const FolderReminderForm = (props: FolderReminderFormProps) => {

	const { id, onClose, onValidate } = props;

	const { id: folder_id } = useParams();

	const { getData, addEditData } = useAddEditFolderReminder();
	const reminderTagAjax = useGetReminderTagList();

	const currentUser = useRecoilValue(CurrentUserState);
	const [userId, setUserId] = useState<number | undefined>();
	const [tags, setTags] = useState<Tag[]>([]);
	const [tagsReady, setTagsReady] = useState(false);
	const [ready, setReady] = useState(false);
	const [tagsSelected, setTagsSelected] = useState<any[]>([]);

	const { setFieldValue: setFolderReminderTagsField } = useField({ name: "folder_reminder_tags"})

	const canModify = useMemo(() => {
		//on est en création, donc modification permise pour tous car ça sera le créateur
		if (!userId) { return true; }

		//si c'est l'admin ou super admin, modification permise
		if (currentUser && (currentUser.user_group_id === 1 || currentUser.user_group_id === 2)) {
			//return true;
		}

		if (currentUser && currentUser.id === userId) {
			return true;
		}

		return false;

	}, [currentUser, userId])

	useEffect(() => {
		reminderTagAjax().then((res) => {
			if (res.status === ReqStatus.SUCCESS && res.data && res.data) {
				setTags(res.data.tags ?? []);
				setTagsReady(true);
			}
		})
	}, [])

	const convertFolderReminderTagsForForm = useCallback((tagsSelected: any[]) => {
		let folderReminderTags: any[] = [];
		tagsSelected.forEach((tag) => {
			folderReminderTags.push({
				folder_reminder_id: id,
				reminder_tag_id: tag.id
			})
		})
		return folderReminderTags.length > 0 ? folderReminderTags : null
	},[]);

	const setFolderReminderTagsInForm = useCallback((tagsSelected: any[]) => {
		const folderReminderTags = convertFolderReminderTagsForForm(tagsSelected);
		if( Array.isArray(folderReminderTags) && folderReminderTags.length > 0){
			setFolderReminderTagsField(folderReminderTags);
		}else{
			setFolderReminderTagsField(null);
		}
	},[]);

	useEffect(() => {
		if (!tagsReady) { return; }

		setReady(false);
		if (id) {
			getData(id.toString()).then((res) => {
				if (res.status === ReqStatus.SUCCESS && res.data && res.data) {
					const _folderReminder = (res.data as any).folderReminder;
					setUserId(_folderReminder.user_id);

					let folder_reminder_tags_ids: number[] = [];
					if (Array.isArray(_folderReminder.folder_reminder_tags)) {
						folder_reminder_tags_ids = _folderReminder.folder_reminder_tags.map((x: any) => x.reminder_tag_id);
					}
					const tagsSelected = tags.filter(x => folder_reminder_tags_ids.includes(x.id));
					resetInitialValues({
						folder_id: _folderReminder.folder_id,
						reminder_date: _folderReminder.reminder_date,
						comment: _folderReminder.comment,
						important: _folderReminder.important,
						active: _folderReminder.active,
						folder_reminder_tags: convertFolderReminderTagsForForm(tagsSelected)
					});
					setTagsSelected(tagsSelected);
					setReady(true);
				}
			})
		} else {
			resetInitialValues({
				reminder_date: getDatetimeNow(),
				active: 1,
				important: 0,
				comment: "",
				folder_id: folder_id
			});
			setReady(true);
		}
	}, [tagsReady, id, folder_id, tags])



	useEffect(() => {
		setFolderReminderTagsInForm(tagsSelected)
	},[tagsSelected])

	const onSubmit = (request: any) => {
		/*if(tagsSelected.length > 0){
			request.folder_reminder_tags = [];
			tagsSelected.forEach((tag) => {
				request.folder_reminder_tags.push({
					folder_reminder_id: id,
					reminder_tag_id: tag.id
				})
			})
		}*/

		addEditData(request, id ? id.toString() : undefined).then((res) => {
			if (res.status === ReqStatus.SUCCESS && Array.isArray(res.data) && res.data.length > 0) {
				onValidate();
			}
		});
	}

	const { handleSubmit, resetInitialValues, isDirty } = useAddEditForm({
		onSubmit
	});

	return (
		<AddEditFormPanel
			title={id ? "Modifier un tâche" : "Ajouter un tâche"}
		>
			{ !ready && <Skeleton height={100} /> }
			{ready &&
				<form id={"folderReminderForm"} onSubmit={handleSubmit}>
					<Grid container spacing={3} mt={1}>
						<Grid item xs={12} >
							<DateField
								name="reminder_date"
								label="Date tâche"
								type="datetime"
								required
								disabled={!canModify}
							/>
						</Grid>

						<Grid item xs={12} >
							<TextAreaField
								name="comment"
								label="Note"
								maxRows={5}
								disabled={!canModify}
							/>
						</Grid>
						<Grid item xs={12}>
							<FolderTagAutoComplete
								initialValue={tagsSelected}
								options={tags}
								onChange={(tags) => setTagsSelected(tags) }
								disabled={!canModify}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<CheckboxField
								name="important"
								label="Important"
								disabled={!canModify}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<CheckboxField
								name="active"
								label="Actif"
								disabled={!canModify}
							/>
						</Grid>
					</Grid>
					<Box mt={2}>
						<AddEditFormSubmit
							returnAction={{ disabled: true }}
							cancelEvent={() => { resetInitialValues(); onClose(); }}
							disabled={!isDirty || !canModify}
						/>
					</Box>

				</form>
			}
		</AddEditFormPanel >
	)
}

export default FolderReminderForm;