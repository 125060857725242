import { useAddEditCore, useDelete } from "../../stores/core/StoreCore";
import { FolderOrderSupplierContactModel } from "../classes/FolderOrderSupplierContactModel";

const routeController = 'project-dahan/dahan/folder-order-supplier-contacts';
export const useAddEditFolderOrderSupplierContact = () => {
    return useAddEditCore<FolderOrderSupplierContactModel, { folderOrderSupplierContact : FolderOrderSupplierContactModel }>(`${routeController}/add-edit`);
};

export const useDeleteFolderOrderSupplierContact = () => {
    return useDelete(routeController);
}
