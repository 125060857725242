import { atom } from "recoil";

export const FolderOrderSupplierPaymentModalState = atom<{open: boolean, id?: number | undefined, onValidate: () => void}>({
    key: 'FolderOrderSupplierPaymentModalKey',
    default: {
        open: false,
        id: undefined,
        onValidate: () => {}
    }
});
