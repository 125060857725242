import { Alert, Box, Chip, FormControl, IconButton, InputLabel, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import SearchIcon from '@mui/icons-material/Search';
import AddEditFormCard from "../../../components/common/form/AddEditFormCard";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useGetFolderHistoryItems } from "../../stores/FoldersStore";
import { ReqStatus } from "../../../stores/core/UseApi";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { MailHistoryModalState } from "../../states/HistoryMailState";
import MessageIcon from '@mui/icons-material/Message';
import MailHistoryModal from "../mails/MailHistoryModal";
import NextWeekIcon from '@mui/icons-material/NextWeek';
import { datetimeFormat } from "../../../utils/DateUtils";
import { BrevoMailConfigsState } from "../../states/MailState";
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator, timelineOppositeContentClasses } from "@mui/lab";
import { folderFinalStatus, getLabelOfFinalStatus, getLabelOfStatus } from "./FolderStepper";
import AvatarIcon from "../../../components/common/icon/AvatarIcon";
import RefreshIcon from '@mui/icons-material/Refresh';
import { IsNotUserCommercialSelector } from "../../states/UserState";
import { BrevoMailConfigEntityEnum } from "../../classes/BrevoMailConfig";
import ListAltIcon from '@mui/icons-material/ListAlt';
import { CurrentUserState } from "../../../states/AuthState";
import { UserGroupEnum } from "../../classes/UserGroupModel";

enum FolderHistoryType {
	EMAILS = 1,
	FOLDER_STATUS = 2,
	MESSAGE = 3,
	CHECKLIST = 4
}

const FolderHistoryItemStatus = (props: { row: any, data: any }) => {
	const { data } = props;
	const status_id = useMemo(() => {
		return +data.status_id;
	}, [data])

	return (
		<>
			{status_id != folderFinalStatus && getLabelOfStatus(status_id)}
			{status_id == folderFinalStatus &&
				(typeof (data.is_closure) == "boolean" ?
					getLabelOfFinalStatus(data.is_closure) :
					getLabelOfStatus(status_id))
			}
			{status_id == folderFinalStatus && data.closure_pattern && <Box>Motif : {data.closure_pattern}</Box>}
		</>
	)
}

const FolderHistoryItemEmail = (props: { row: any }) => {

	const { row } = props;

	const templates = useRecoilValue(BrevoMailConfigsState);
	const data = useMemo(() => {
		return JSON.parse(row.json);
	}, [row.json])

	const hasTemplate = useMemo(() => {
		return templates?.length > 0 ?? false;
	}, [templates])

	const templateName = useMemo(() => {
		if (templates && data.template_id) {
			return templates.find(x => x.id === data.template_id)?.name ?? "Templates non chargé";
		}
		return "";
	}, [data, templates])

	const emails = useMemo(() => {
		const emailArray = Array.isArray(data.emails) ? data.emails : [data.emails];
		return emailArray.join(', ') ?? "";
	}, [data])

	const setMailHistoryState = useSetRecoilState(MailHistoryModalState);
	const handleSearchClick = (messageId: string) => {
		setMailHistoryState({ open: true, messageId: messageId })
	};

	return (
		<>
			{row.type === FolderHistoryType.EMAILS &&
				<Box display={"flex"} justifyContent={"space-between"}>
					<Box>

						<Box>
							{templateName}
						</Box>
						{hasTemplate &&
							<Box display={"flex"} alignItems={"center"}>
								<Box>
									{emails}
								</Box>
								<Box ml={2}>
									{data.message_id &&
										<IconButton onClick={() => handleSearchClick(data.message_id)}>
											<SearchIcon
												color="primary"
												style={{ cursor: 'pointer' }}
											/>
										</IconButton>
									}
								</Box>
							</Box>
						}
					</Box>

				</Box>
			}
		</>
	)
}
const FolderHistoryItem = (props: { row: any }) => {

	const { row } = props;
	const isNotUserCommercial = useRecoilValue(IsNotUserCommercialSelector);

	const data = useMemo(() => {
		return JSON.parse(row.json);
	}, [row.json])

	const templates = useRecoilValue(BrevoMailConfigsState);
	const itemIsVisible = useMemo(() => {

		if (isNotUserCommercial) {
			return true;
		}

		if (row.type !== FolderHistoryType.EMAILS) {
			return true;
		}

		const templateFind = templates.find((x) => x.id === data.template_id);

		return templateFind?.entity !== BrevoMailConfigEntityEnum.ENTITY_FOLDER_ORDER_SUPPLIER ?
			true :
			false;
	}, [data, row, templates]);

	return (
		<>
			{itemIsVisible && (
				<TimelineItem>
					<TimelineOppositeContent sx={{ py: '16px', px: 2 }} >
						<Box display="flex" >


							<Box>

								{datetimeFormat(row.created)}
							</Box>

							{data.user &&
								<Box ml={3} mt={-1}>
									<AvatarIcon value={data.user} />
								</Box>
							}
						</Box>

					</TimelineOppositeContent>
					<TimelineSeparator>
						<TimelineDot variant="outlined">
							{row.type === FolderHistoryType.EMAILS && <EmailIcon color="primary" />}
							{row.type === FolderHistoryType.FOLDER_STATUS && <NextWeekIcon color="secondary" />}
							{[FolderHistoryType.MESSAGE].includes(row.type) && <MessageIcon color="success" />}
							{[FolderHistoryType.CHECKLIST].includes(row.type) && <ListAltIcon color="error" />}
						</TimelineDot>
						<TimelineConnector />
					</TimelineSeparator>
					<TimelineContent sx={{ py: '16px', px: 2 }}>
						<Box display={"flex"} width={"100%"} justifyContent={"space-between"} >
							<Box display={"flex"}>
								<Box >
									{row.type === FolderHistoryType.EMAILS &&
										<FolderHistoryItemEmail row={row} />
									}

									{row.type === FolderHistoryType.FOLDER_STATUS && <FolderHistoryItemStatus row={row} data={data} />}

									{[FolderHistoryType.MESSAGE, FolderHistoryType.CHECKLIST].includes(row.type) && data.message}
								</Box>

							</Box>
						</Box>


					</TimelineContent>
				</TimelineItem>
			)}
		</>
	)
}

const FolderHistory = (props: { folderId: number, withExpand: boolean, title: string, ignoreFolderId?: number, buttonAdd?: boolean }) => {
	const { folderId, withExpand, title, buttonAdd, ignoreFolderId } = props;
	const [rows, setRows] = useState<any[]>([]);

	const get = useGetFolderHistoryItems();
	const getDatas = useCallback(() => {
		if (isNaN(+folderId)) { return; }
		get(folderId).then((res) => {
			if (res.status === ReqStatus.SUCCESS && res.data) {
				setRows(res.data.folderHistoryItems ?? [])
			}
		})
	}, [folderId]);
	useEffect(() => {
		getDatas();
	}, []);


	const currentUser = useRecoilValue(CurrentUserState);
	//const [search, setSearch] = useState("");
	const [typesSelected, setTypeSelected] = useState<number[]>([FolderHistoryType.MESSAGE, FolderHistoryType.EMAILS, FolderHistoryType.FOLDER_STATUS]);

	const checklistVisible = useMemo(() => {
		if(!currentUser){ return false; }
		return currentUser.user_group_id <= UserGroupEnum.ADMIN;
	},[currentUser]);

	const rowsVisible = useMemo(() => {

		let _rows = rows;

		if (!checklistVisible) {
			_rows = _rows.filter((x) => x.type != FolderHistoryType.CHECKLIST);
		}

		if(typesSelected){
			_rows = _rows.filter((x) => typesSelected.includes(x.type));
		}

		/*
		if (search) {
			_rows = _rows.filter((x) => x.json?.toLowerCase().includes(search.toLowerCase()));
		}
		*/

		return _rows;
	}, [rows, checklistVisible, typesSelected]);

	const getLabelByType = useCallback((value: number) => {
		if (value == FolderHistoryType.MESSAGE) { return "Message"; }
		if (value == FolderHistoryType.EMAILS) { return "Emails"; }
		if (value == FolderHistoryType.FOLDER_STATUS) { return "Statut dossier"; }
		if (value == FolderHistoryType.CHECKLIST) { return "Checklist"; }

		return "";
	}, [])

	return (
		<>
			<MailHistoryModal />
			<AddEditFormCard
				title={title}
				withExpand={withExpand}
				action={<Box><IconButton onClick={() => getDatas()}><RefreshIcon color="primary" /></IconButton></Box>}
			>
				<Box display="flex">
					<FormControl>
						<InputLabel id="history-type-select-label">Type</InputLabel>
						<Select
							sx={{ minWidth: 200}}
							label="Type"
							labelId="history-type-select-label"
							id="history-type-select"
							multiple
							value={typesSelected}
							onChange={(e) => { setTypeSelected((e.target.value as any)); }}
							input={<OutlinedInput label="Checklist" />}
							renderValue={(selected) => {
								return (
									<Box display="flex">
										{Array.isArray(selected) && selected.map((s) => (
											<Box mr={1} key={`history_type_option_${s}`}>
												<Chip label={getLabelByType(s)} />
											</Box>
										))}
									</Box>
								)
							}}
						>
							<MenuItem value={FolderHistoryType.MESSAGE}>{getLabelByType(FolderHistoryType.MESSAGE)}</MenuItem>
							<MenuItem value={FolderHistoryType.EMAILS}>{getLabelByType(FolderHistoryType.EMAILS)}</MenuItem>
							<MenuItem value={FolderHistoryType.FOLDER_STATUS}>{getLabelByType(FolderHistoryType.FOLDER_STATUS)}</MenuItem>
							{checklistVisible && <MenuItem value={FolderHistoryType.CHECKLIST}>{getLabelByType(FolderHistoryType.CHECKLIST)}</MenuItem> }
						</Select>
					</FormControl>
					{/*
					<Box ml={2}>
						<TextField label="Rechercher" type="search" value={search} onChange={(e) => setSearch(e.target.value ?? "")} />
					</Box>
					*/}
				</Box>

				{rows.length > 0 &&
					<Box maxHeight={500} overflow={"auto"}>
						<Timeline sx={{
							[`& .${timelineOppositeContentClasses.root}`]: {
								flex: 0.25,
							},
						}}>
							{rowsVisible.map((row, row_key) =>
								<FolderHistoryItem row={row} key={row_key} />)
							}
						</Timeline>
					</Box>
				}
				{rows.length == 0 && <Alert icon={false} color="info">Aucun historique</Alert>}
			</AddEditFormCard>
		</>
	)
}
export default FolderHistory;