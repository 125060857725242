

import { GridColDef } from "@mui/x-data-grid";
import { ListModelCore } from "../../../classes/list/ListModel";
import { useGetListCore } from "../../../stores/core/StoreCore";
import ListCore from "../../../components/common/list/ListCore";
import { getColorOfFolderOrderSupplierStatus, getLabelOfFolderOrderSupplierStatus } from "../../classes/FolderOrderSupplierModel";
import { CreateColumnDate } from "../../../components/common/list/column/ColumnDate";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import FormattedAmount from "../../../components/common/formatted/FormattedAmount";
import TooltipCommentIcon from "../common/TooltipCommentIcon";
import { datetimeFormat, datetimeTicks } from "../../../utils/DateUtils";
const columns: GridColDef[] = [
    {
        field: 'id',
        headerName: "N° paiement",
        editable: false,
        filterable: false,
        minWidth: 30,
        valueGetter: ({ row }) => `#${row.id}`
    },
    {
        field: 'folder_order_supplier.folder_id',
        headerName: "N° Dossier",
        editable: false,
        filterable: false,
        minWidth: 30,
        valueGetter: ({ row }) => `#${row.folder_order_supplier.folder_id}`
    },
    {
        field: 'folder_order_supplier.order_id',
        headerName: "N° Devis",
        editable: false,
        filterable: false,
        minWidth: 30,
        valueGetter: ({ row }) => `#${row.folder_order_supplier.order_id}`
    },

    {
        field: 'travels',
        headerName: "Date du voyage",
        editable: false,
        filterable: false,
        width: 140,
        renderCell: ({ row }) => {
            let departure_date = "";
            let return_date = "";
            const _travels = row.folder_order_supplier.order.travels;
            if (Array.isArray(_travels) && _travels.length > 0 && _travels[0].order_product_attribute && _travels[0].order_product_attribute.departure_date) {
                departure_date = datetimeFormat(_travels[0].order_product_attribute.departure_date);
            }
            if (Array.isArray(_travels) && _travels.length > 0 && _travels[0].order_product_attribute && _travels[0].order_product_attribute.return_date) {
                return_date = datetimeFormat(_travels[0].order_product_attribute.return_date);
            }
            return (
                <Box>
                    {departure_date && <Box>{return_date ? 'du' : 'le'} {departure_date}</Box>}
                    {return_date && <Box>au {return_date}</Box>}
                </Box>
            )

        },
        sortComparator: (v1, v2) => {
            let v1_ticks: number = 0;
            let v2_ticks: number = 0;
            if (Array.isArray(v1) && v1.length > 0 && v1[0].order_product_attribute && v1[0].order_product_attribute.departure_date) {
                v1_ticks = datetimeTicks(v1[0].order_product_attribute.departure_date);
            }

            if (Array.isArray(v2) && v2.length > 0 && v2[0].order_product_attribute && v2[0].order_product_attribute.departure_date) {
                v2_ticks = datetimeTicks(v2[0].order_product_attribute.departure_date);
            }

            if (v1_ticks == v2_ticks) {
                return 0;
            }

            return (v1_ticks > v2_ticks) ? 1 : -1;
        },
    },
    {
        field: 'folder_order_supplier.order.customer.user',
        headerName: "Client facturé / Société",
        editable: false,
        filterable: false,
        minWidth: 200,
        renderCell: ({ row }) => (
            <Box>
                <Box>{`${row.folder_order_supplier.order.billing_address?.company_name ?? ''}`}</Box>
                <Box>{`${row.folder_order_supplier.order.billing_address?.first_name ?? ''} ${row.folder_order_supplier.order.billing_address?.last_name ?? ''}`}</Box>
            </Box>
        )
    },
    {
        field: 'folder_order_supplier.supplier.name',
        headerName: "Fournisseur (Statut)",
        editable: false,
        filterable: false,
        width: 200,
        renderCell: ({ row }) => (
            <Box width={160} overflow={"hidden"} textOverflow={"ellipsis"} my={0.1} px={0.5} style={getColorOfFolderOrderSupplierStatus(row.folder_order_supplier.status)}>
                {`${row.folder_order_supplier.supplier.name}`}
            </Box>
        )
    },
    {
        field: 'folder_order_supplier.amount',
        headerName: "Prix fournisseur",
        editable: false,
        filterable: false,
        width: 150,
        renderCell: ({ row }) => <Box display={"flex"} justifyContent={"flex-end"} width={"100%"}><FormattedAmount amount={row.folder_order_supplier.amount} symbol="€" /></Box>
    },
    {
        field: 'amount',
        headerName: "Montant réglé",
        editable: false,
        filterable: false,
        width: 150,
        renderCell: ({ row }) => <Box display={"flex"} justifyContent={"flex-end"} width={"100%"}><FormattedAmount amount={row.amount} symbol="€" /></Box>
    },
    CreateColumnDate({
        field: 'payment_date',
        headerName: "Date règlément",
        getValue: (row) => row.payment_date,
        type: "date"
    }),
    {
        field: 'comment',
        headerName: "Note",
        editable: false,
        filterable: false,
        minWidth: 100,
        renderCell: ({ row }) => (
            <TooltipCommentIcon value={row.comment} />
        )
    },
    {
        field: 'reference',
        headerName: "Référence règlement",
        editable: false,
        filterable: false,
        minWidth: 200,
    },
];

const Actions = (props: { row: any }) => {

    const { row } = props;
    const navigate = useNavigate();

    return (
        <Box display="flex">
            <Button variant="contained" color="info" onClick={() => navigate(`/folders/add-edit/${row.folder_order_supplier.folder_id}`)}>Voir dossier</Button>
        </Box>
    );
}


interface FolderOrderSupplierPaymentListModel extends ListModelCore {
    folderOrderSupplierPayments: any[]
}

const FolderOrderSupplierPaymentGlobalList = () => {

    const routeApiController = "project-dahan/dahan/folder-order-supplier-payments";
    const [get] = useGetListCore<FolderOrderSupplierPaymentListModel>(`/${routeApiController}/index`);
    return (
        <ListCore<FolderOrderSupplierPaymentListModel, any>
            getDatas={get}
            defineDatasToSet={(data) => data.folderOrderSupplierPayments}
            getRowId={(row) => row.id}
            columns={columns}
            paginationOptionDefault={50}
            actions={{
                defaultRoute: routeApiController,
                edit: false,
                others: (row) => <Actions row={row} />
            }}
        />
    );
}

export default FolderOrderSupplierPaymentGlobalList;