import { useRecoilState } from "recoil";
import { FolderChecktasksModalState } from "../../states/FolderChecktasksState";
import { useCallback } from "react";
import { Box, Container, Grid, Modal, Paper, Button, IconButton, Typography } from "@mui/material";
import FolderChecktasks from "./FolderChecktasks";
import { FormattedMessage } from "react-intl";

const FolderChecktasksModal = () => {
    const [modalState, setModalState] = useRecoilState(FolderChecktasksModalState);
    const handleClose = useCallback(() => {
        setModalState((a) => {
            return { ...a, open: false };
        });
    }, [setModalState]);

    return (
        <Modal open={modalState.open} onClose={handleClose}>
            <Container component="main" sx={{ mt: 2, py: 2 }}>
                <Paper>
                   
                    <Box py={1}>
                        <Box mx={2}><Typography variant="h5">Dossier #{modalState.folder_id}</Typography></Box>
                        <FolderChecktasks folderId={modalState.folder_id} />
                        <Box display="flex" justifyContent={"flex-end"} px={2}>
                            <Button color="info" variant="outlined" onClick={() => handleClose()}>
                                Fermer
                            </Button>
                        </Box>
                    </Box>
                </Paper>

            </Container>
        </Modal>
    )
}

export default FolderChecktasksModal;