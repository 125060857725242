import moment from 'moment';

const dateParseCore = (date: Date, format: string) =>{
    const dateParsed = moment(date);
    return dateParsed.isValid() ? dateParsed.format(format) : "";
}

export const datetimeTicks = (date: Date) =>{
    const dateParsed = moment(date);
    return dateParsed.isValid() ? dateParsed.unix() : 0;
}

export const datetimeFormat = (date: Date) =>{
    return dateParseCore(date, "DD/MM/YY HH:mm");
}

export const dateFormat = (date: Date) =>{
    return dateParseCore(date, "DD/MM/YY");
}