import { Alert, CircularProgress, IconButton, List, ListItem, ListItemText, Paper, Popper } from "@mui/material"
import { useCallback, useEffect, useMemo, useState } from "react";
import axios from 'axios';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CopyButton from '../../../components/common/button/CopyButton';

export interface TomTomAddressOptionModel {
	label: string; coordinates: [number, number];
}

export function useAddressDebounce(value: string, delay: number) {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [value, delay]);

	return debouncedValue;
}


export const useTomTomAddressApi = () => {

	const [loading, setLoading] = useState(false);
	const [noResult, setNoResult] = useState(false);
	const [options, setOptions] = useState<TomTomAddressOptionModel[]>([]);
	const [open, setOpen] = useState(false);
	const apiKey = process.env.REACT_APP_TOMTOM_API_KEY;

	const searchEvent = useCallback((searchValue: string) => {

		if(!apiKey){
			alert("La clé d'api n'est pas défini");
			return;
		}

		if (!searchValue || searchValue.length <= 4) {
			return;
		}

		setLoading(true);
		setNoResult(false);
		axios.get(`https://api.tomtom.com/search/2/search/${encodeURIComponent(searchValue)}.json?view=Unified&relatedPois=all&extendedPostalCodesFor=POI,Addr&language=FR-fr&limit=6&key=${apiKey}`, {
			responseType: "json",
		})
			.then(function (response) {
				const results = response.data.results;
				if (results.length === 0) {
					setOptions([]);
					setNoResult(true);
					return;
				}

				console.log(results);
				const newOptions = results.map((res: any) => ({
					label: `${res.poi ? `${res.poi.name}, ` : ''}${res.address.freeformAddress}, ${res.address.country}`,
					coordinates: [res.position.lat, res.position.lon],
				}));
				setOptions(newOptions);
				setOpen(true);
			})
			.catch(function (error) {
				console.log(error);
			}).finally(() => {
				setLoading(false);
			});
	}, []);

	return {
		loading,
		noResult,
		options,
		open,
		setOpen,
		searchEvent
	}
}

interface TomTomAddressActionsProps {
	disabled?: boolean,
	loading: boolean,
	open: boolean,
	optionsLength: number,
	setOpen: React.Dispatch<React.SetStateAction<boolean>>,
	value: string,
	searchEvent: (searchValue: string) => void
}
export const TomTomAddressActions = (props: TomTomAddressActionsProps) => {

	const { disabled, loading, open, setOpen, optionsLength, value, searchEvent } = props;

	const copyAddress = useMemo(() => {
		return (
			<CopyButton textToCopy={value} />
		)
	}, [value])

	return (
		<>
			{!disabled &&
				<>
					{loading && <CircularProgress />}
					{!loading && optionsLength > 0 &&
						<IconButton onClick={() => setOpen((v) => !v)}>
							{open && <ArrowDropUpIcon />}
							{!open && <ArrowDropDownIcon />}
						</IconButton>
					}
					{!loading && <IconButton disabled={!value || value.length <= 4} onClick={() => searchEvent(value)}><SearchIcon /></IconButton>}
					{copyAddress}
				</>
			}
		</>
	);
}

interface TomTomAddressOptionsProps {
	disabled?: boolean,
	options: TomTomAddressOptionModel[],
	open: boolean,
	anchorRef: React.RefObject<HTMLDivElement>,
	noResult: boolean,
	selectOption: (option: TomTomAddressOptionModel) => void
}
export const TomTomAddressOptions = (props: TomTomAddressOptionsProps) => {
	const { disabled, options, open, anchorRef, noResult, selectOption } = props;

	return (
		<>
			{noResult && 
			<Alert icon={false} color="warning">Aucun résultat</Alert>
			}
			{!disabled && options.length > 0 && open &&
				<Popper open={!disabled && open && options.length > 0} anchorEl={anchorRef.current} placement="bottom-start" disablePortal sx={{ zIndex: 2 }}>
					<Paper elevation={3}>
						<List>
							{options.map((option, index) => (
								<ListItem onClick={() => selectOption(option)} key={index} sx={{ cursor: "pointer", borderBottom: "1px solid lightgray" }}>
									<ListItemText primary={<>{option.label}</>} />
								</ListItem>
							))}
						</List>
					</Paper>
				</Popper>
			}
		</>
	)
}