import { Alert, Autocomplete, Box, Button, CircularProgress, Container, Divider, IconButton, InputBase, List, ListItemButton, ListItemText, Modal, Paper, TextField, Typography } from "@mui/material";
import { PropsWithChildren, useCallback, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import AvatarIcon from "../../../components/common/icon/AvatarIcon";
import FolderIcon from "@mui/icons-material/Folder";
import EuroIcon from '@mui/icons-material/Euro';
import { useSearchApi } from "../../stores/SearchStore";
import { ReqStatus } from "../../../stores/core/UseApi";
import { getLabelOfStatusByFolder } from "../folders/FolderStepper";
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from "react-router-dom";

interface InputSearchResultSectionProps extends PropsWithChildren {
	title: string
}

const InputSearchSection = (props: InputSearchResultSectionProps) => {
	const { title } = props;
	return (
		<Box sx={{ width: '400px' }} mt={1} mr={1}>
			<Paper>
				<List component="nav">
					<Box mx={2} mb={1}><Typography variant="h5">{title}</Typography></Box>
					<Divider />
					<Box sx={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
						{props.children}
					</Box>
				</List>
			</Paper>
		</Box>
	)
}

const InputSearchGlobal = () => {
	const [search, setSearch] = useState("");
	const { call, status } = useSearchApi();

	const [customers, setCustomers] = useState<any[]>([]);
	const [quotes, setQuotes] = useState<any[]>([]);
	const [folders, setFolders] = useState<any[]>([]);
	const [bills, setBills] = useState<any[]>([]);
	const [ready, setReady] = useState(true);
	const [open, setOpen] = useState(false);

	const navigate = useNavigate();

	const navigateToCustomers = useCallback((customerId: number) => {
		navigate(`/commercial/customers/add-edit/${customerId}`);
		setOpen(false);
	}, [navigate]);

	const navigateToFolders = useCallback((folderId: number) => {
		navigate(`/folders/add-edit/${folderId}`);
		setOpen(false);
	}, [navigate]);

	const searchEvent = useCallback(() => {
		if (search.length <= 0) {
			return;
		}

		setReady(false);
		setCustomers([]);
		setQuotes([]);
		setFolders([]);
		setBills([]);
		call({ search: search }).then((res) => {
			if (res.status === ReqStatus.SUCCESS && res.data) {
				setCustomers(res.data.customers ?? []);
				setQuotes(res.data.quotes ?? []);
				setFolders(res.data.folders ?? []);
				setBills(res.data.bills ?? []);
				setOpen(true);
			}
		}).finally(() => setReady(true))
	}, [search]);

	const handleKeyDown = useCallback((event: any) => {
		if (!ready || search.length < 1) {
			return;
		}

		if (event.key === 'Enter') {
			searchEvent();
		}
	}, [ready, search]);

	return (
		<>

			<Box m={1} display="flex" justifyContent={"center"}>

				<Paper
					sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 300 }}
				>
					<InputBase
						sx={{ ml: 1, flex: 1 }}
						placeholder="Rechercher..."
						inputProps={{ 'aria-label': 'Rechercher un client, dossier, devis...' }}
						value={search}
						onChange={(e) => setSearch(e.target.value ?? "")}
						disabled={!ready}
						onKeyDown={handleKeyDown}
					/>
					<IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={() => setSearch("")} disabled={!ready}>
						<ClearIcon />
					</IconButton>
					<IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={searchEvent} disabled={!ready || search.length < 1}>
						{ready && <SearchIcon />}
						{!ready && <CircularProgress size="10px" />}
					</IconButton>
				</Paper>

				{ /*<Box ml={1}>
						{search.length < 1 && <Alert color="info">Il faut au moins 1 caractère pour lancer une recherche</Alert>}
					</Box>
					*/}
			</Box>

			{ready &&
				<Modal
					open={open}
					onClose={() => setOpen(false)}
				>
					<Container component="main" maxWidth="xl" sx={{ height: "100%", mt: 2, p: 0 }}>
						<Paper>
							<Box display="flex" justifyContent="space-between" p={1}>
								<Typography variant="h5">Recherche : {search}</Typography>
								<Button variant="contained" color="info" onClick={() => setOpen(false)}>Fermer</Button>
							</Box>
							<Divider />
							<Box display="flex" mx={1} pb={1}>
								<InputSearchSection title="Clients">
									<>
										{customers.length === 0 && <Box mx={1} mt={1}><Alert color="info">Aucun résultat</Alert></Box>}
										{customers.map((customer, customer_key) => (
											<ListItemButton
												key={customer_key}
												onClick={() => navigateToCustomers(customer.user_id)}
											>
												<AvatarIcon value={`${customer?.user?.first_name} ${customer?.user?.last_name}`} />
												<Box ml={1}>
													<Box>
														{`#${customer.user_id} - ${customer?.user?.first_name} ${customer?.user?.last_name}`}
													</Box>
													<Box>
														{customer?.user?.email}  - {customer?.user?.phone}
													</Box>
												</Box>
											</ListItemButton>
										))}
									</>

								</InputSearchSection>

								<InputSearchSection title="Dossiers">
									<>
										{folders.length === 0 && <Box mx={1} mt={1}><Alert color="info">Aucun résultat</Alert></Box>}
										{folders.map((folder, folder_key) => (
											<ListItemButton
												key={folder_key}
												onClick={() => navigateToFolders(folder.id)}
											>
												{folder.customer &&
													<AvatarIcon value={`${folder.customer?.user?.first_name} ${folder.customer?.user?.last_name}`} />
												}
												{!folder.customer &&
													<FolderIcon color="info" fontSize="large" />
												}
												<Box ml={2}>
													<Box>
														#{folder.id} - {`${folder.customer?.user?.first_name} ${folder.customer?.user?.last_name}`}
													</Box>
													<Box>
														{getLabelOfStatusByFolder(folder)} - {folder.commercial ? `${folder.commercial?.first_name} ${folder.commercial?.last_name}` : 'N/A'}
													</Box>
												</Box>
											</ListItemButton>
										))}
									</>

								</InputSearchSection>

								<InputSearchSection title="Devis">
									<>
										{quotes.length === 0 && <Box mx={1} mt={1}><Alert color="info">Aucun résultat</Alert></Box>}
										{quotes.map((quote, quote_key) => (
											<ListItemButton
												key={quote_key}
												onClick={() => navigateToFolders(quote.order_attribute.folder_id)}
											>
												{quote.customer &&
													<AvatarIcon value={`${quote.customer?.user?.first_name} ${quote.customer?.user?.last_name}`} />
												}
												{!quote.customer &&
													<EuroIcon color="info" fontSize="large" />
												}
												<Box ml={2}>
													<Box>
														#{quote.id} - {`${quote.customer?.user?.first_name} ${quote.customer?.user?.last_name}`}
													</Box>
													<Box>
														Commercial : {quote.order_attribute.commercial?.first_name} {quote.order_attribute.commercial?.last_name}
													</Box>
												</Box>
											</ListItemButton>
										))}
									</>

								</InputSearchSection>

								<InputSearchSection title="Factures">
									<>
										{bills.length === 0 && <Box mx={1} mt={1}><Alert color="info">Aucun résultat</Alert></Box>}
										{bills.map((bill, bill_key) => (
											<ListItemButton
												key={bill_key}
												onClick={() => navigateToFolders(bill.order.order_attribute.folder_id)}
											>
										
												<AvatarIcon value={`${bill.shipping_first_name} ${bill.shipping_last_name}`} />
											
												<Box ml={2}>
													<Box>
														#{bill.id} - {`${bill.shipping_first_name} ${bill.shipping_last_name}`}
													</Box>
													<Box>
													Commercial : {bill.order.order_attribute.commercial?.first_name} {bill.order.order_attribute.commercial?.last_name}
													</Box>
												</Box>
											</ListItemButton>
										))}
									</>

								</InputSearchSection>
								
							</Box>

						</Paper>
					</Container>
				</Modal>
			}
		</>
	)
}

export default InputSearchGlobal;