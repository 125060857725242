import { Box, Container, Modal } from "@mui/material";
import { FormProvider } from "react-recoil-form";
import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { Mail2ModalState } from "../../states/MailState";
import SendMailForm from "./SendMailForm";

const SendMailModal = () => {

	const [modalState, setModalState] = useRecoilState(Mail2ModalState);

	const handleClose = useCallback(() => {
		setModalState((a) => {
			return { ...a, open: false };
		});
	}, [setModalState]);

	return (
		<Modal
			open={modalState.open}
			aria-labelledby="mail-modal"
			sx={{
				overflow: 'auto'
			}}
			onClose={handleClose}
		>
			<Container component="main" maxWidth="md" sx={{ height: "100%", py: 2 }}>
				<FormProvider options={{ formId: "mailForm", skipRecoilRoot: true }}>
					<Box>
						<SendMailForm 
							folderId={modalState.folderId} 
							templateIdDefault={modalState.templateIdDefault}
							entityIdDefault={modalState.entityId}
							emails={modalState.emails ?? []}
							onCloseEvent={handleClose}
							onValidateEvent={modalState.onValidate}
						/>
					</Box>
				</FormProvider>
			</Container>
		</Modal>
	);
}

export default SendMailModal;