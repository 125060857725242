
import { Divider, IconButton, Menu, MenuItem } from "@mui/material";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useState } from "react";
import { EnumFolderOrderSupplierStatus } from "../../classes/FolderOrderSupplierModel";

interface FolderOrderSupplierActionsProps {
	updateStatus: (status: EnumFolderOrderSupplierStatus) => void,
	deleteEvent: () => void
}

const FolderOrderSupplierActionStatut = (props: { onClickEvent: (status: EnumFolderOrderSupplierStatus) => void, status: EnumFolderOrderSupplierStatus }) => {


	return (
		<MenuItem onClick={() => props.onClickEvent(props.status)} disableRipple>
			BPA
		</MenuItem>
	)
}

const FolderOrderSupplierActions = (props: FolderOrderSupplierActionsProps) => {

	const { updateStatus, deleteEvent } = props;

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const updateStatusCore = (status: EnumFolderOrderSupplierStatus) => {
		updateStatus(status);
		handleClose();
	}

	return (
		<>
			<IconButton
				onClick={handleClick}
			>
				<MoreHorizIcon />
			</IconButton>
			<Menu
				elevation={0}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				<MenuItem onClick={() => updateStatusCore(EnumFolderOrderSupplierStatus.CONFIRMED)} disableRipple>
					BPA
				</MenuItem>
				<MenuItem onClick={() => updateStatusCore(EnumFolderOrderSupplierStatus.VALIDATED)} disableRipple>
					Validé
				</MenuItem>
				<MenuItem onClick={() => updateStatusCore(EnumFolderOrderSupplierStatus.INIT)} disableRipple>
					Initial
				</MenuItem>
				<Divider sx={{ my: 0.5 }} />
				<MenuItem onClick={() => updateStatusCore(EnumFolderOrderSupplierStatus.REFUSED)} disableRipple>
					Refusé
				</MenuItem>
				<MenuItem onClick={() => updateStatusCore(EnumFolderOrderSupplierStatus.CANCELED)} disableRipple>
					Annulé
				</MenuItem>
				<Divider sx={{ my: 0.5 }} />
				<MenuItem onClick={() => { deleteEvent(); handleClose(); }} disableRipple>
					Supprimer
				</MenuItem>
			</Menu>
		</>
	)
}

export default FolderOrderSupplierActions;