import { Box, Button, Checkbox, Grid, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import CheckboxField from "../common/form/CheckboxField";
import { useAddEditGroupAuthorization, useGetGroupAuthorization } from "../../stores/GroupAuthorizationsStore";
import { useEffect, useMemo, useState } from "react";
import { ReqStatus } from "../../stores/core/UseApi";
import { GroupAuthorizationEntities } from "../../classes/group-authorizations/GroupAuthorizationModel";
import { UserGroupModel } from "../../classes/user-groups/UserGroupModel";
import { useForm, useFormValues } from "react-recoil-form";
import { FormattedMessage } from "react-intl";
import { GroupAuthorizationEntityActionsRequest, GroupAuthorizationEntityRequest } from "../../classes/group-authorizations/GroupAuthorizationRequest";
import { useNavigate, useParams } from "react-router";
import GroupAuthorizationTableDefault from "./GroupAuthorizationTableDefault";
import GroupAuthorizationTableActions from "./GroupAuthorizationTableActions";
import { useAddEditForm } from "../common/form/AddEditFormPanel";
import AddEditForm from "../common/form/AddEditForm";
import AddEditFormCard from "../common/form/AddEditFormCard";

const GroupAuthorizationView = () => {

    let { id } = useParams();
    const navigate = useNavigate();
    const [getGroupAuthorization] = useGetGroupAuthorization();
    const [addEditGroupAuthorization] = useAddEditGroupAuthorization();
    const [mode, setMode] = useState("");
    const [userGroup, setUserGroup] = useState<UserGroupModel>();
    const [entities, setEntities] = useState<GroupAuthorizationEntities | undefined>(undefined);
    const [ready, setReady] = useState(false);

    useEffect(() => {
        if (!id) {
            navigate("/user-groups");
            return;
        }
        setReady(false);
        getGroupAuthorization(+id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                setMode(res.data.mode ?? "");
                setUserGroup(res.data.userGroup);
                setEntities(res.data.entities ?? undefined);

                if (res.data.entities) {
                    if (res.data.mode === "actions") {
                        let _initialValues: GroupAuthorizationEntityActionsRequest = {};
                        Object.entries(res.data.entities).forEach(([key, entity]) => {
                            Array.isArray(entity.actions) && entity.actions.forEach((action) => {
                                if (!action) { return; }
                                if (!_initialValues[key]) { _initialValues[key] = {}; }
                                _initialValues[key][action] = entity.authorizations?.includes(action) ?? false;
                            });
                        });
                        resetInitialValues(_initialValues);
                    } else {
                        let _initialValues: GroupAuthorizationEntityRequest = {};
                        Object.entries(res.data.entities).forEach(([key, entity]) => {
                            _initialValues[key] = {
                                write: entity.write?.authorized ?? false,
                                read: entity.read?.authorized ?? false,
                            }
                        });
                        resetInitialValues(_initialValues);
                    }
                }
            } else {
                navigate("/user-groups");
            }
        }).finally(() => setReady(true))
    }, [id])

    const userGroupName = useMemo(() => {
        return userGroup?.name ?? "";
    }, [userGroup]);

    const onSubmit = (request: GroupAuthorizationEntityRequest | GroupAuthorizationEntityActionsRequest) => {
        if (!id) {
            return;
        }

        addEditGroupAuthorization({ id: +id, entities: request }).then((res) => {
            if (res.status === ReqStatus.SUCCESS) {
                navigate("/user-groups");
            }
        });
    }

    const { handleSubmit, resetInitialValues, isDirty } = useAddEditForm({ onSubmit, initialValues: {} });

    return (
        
        <AddEditForm formId="groupAuthorizationForm" formHandleCancel={resetInitialValues} formHandleSubmit={handleSubmit} isDirty={true}>

            <AddEditFormCard title={`Permissions - ${userGroupName}`}>

                {!ready && <Skeleton  variant="rectangular" height={200} />}
                {ready && entities && <>
                    <TableContainer>
                        {mode === "actions" ?
                            <GroupAuthorizationTableActions
                                entities={entities}
                                resetInitialValues={resetInitialValues}
                            />
                            :
                            <GroupAuthorizationTableDefault
                                entities={entities}
                                resetInitialValues={resetInitialValues}
                            />
                        }
                    </TableContainer>
                </>
                }
            </AddEditFormCard>
        </AddEditForm>
    )
}

export default GroupAuthorizationView;