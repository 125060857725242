import { ListModelCore } from "../../classes/list/ListModel";
import { GridColDef } from "@mui/x-data-grid";
import { useGetListCore } from "../../stores/core/StoreCore";
import { useNavigate } from "react-router-dom";
import ListCore from "../common/list/ListCore";
import { Box, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import FormattedAmount from "../common/formatted/FormattedAmount";

const columns: GridColDef[] = [
    {
        field: 'name',
        headerName: "Nom",
        editable: false,
        filterable: false,
        minWidth: 100,
        flex: 1,
    },
    {
        field: 'reference',
        headerName: "Référence",
        editable: false,
        filterable: false,
        minWidth: 100,
        flex: 1
    },
    {
        field: 'product_type.label',
        headerName: "Type",
        editable: false,
        filterable: false,
        minWidth: 50,
        flex: 1,
        valueGetter: ({ row }) => row?.product_type?.label
    },
    {
        field: 'amount_tax_excl',
        headerName: "Prix HT",
        editable: false,
        filterable: false,
        minWidth: 100,
        flex: 1,
        renderCell: ({ row }) => <Box display={"flex"} justifyContent={"flex-end"} width={"100%"}><FormattedAmount amount={row.amount_tax_excl} symbol="€"/></Box>
    },
    {
        field: 'stock_quantity',
        headerName: "Quantité",
        editable: false,
        filterable: false,
        minWidth: 100,
        flex: 1,
    }
];

interface ProductListModel extends ListModelCore {
    products: any[]
}

const ProductList = () => {

    const routeController = "commercial/products";
    const [get] = useGetListCore<ProductListModel>(`/${routeController}/index`);

    return (
        <ListCore<ProductListModel, any>
            getDatas={get}
            defineDatasToSet={(data) => data.products}
            getRowId={(row) => row.id}
            columns={columns}
            actions={{
                defaultRoute: routeController,
                add: {
                    title: "Ajouter un produit"
                },
                edit: true,
                remove: {
                    enable: true,
                    getTextModal: (row) => `Êtes-vous sûr de vouloir supprimer le produit ${row.name} ?`
                }
            }}
        />
    );
}

export default ProductList;