import { useCallback, useMemo } from 'react';
import { Alert, Badge, Box, IconButton, Tooltip } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useGetTravelInformationList } from "../../stores/FoldersStore";
import { useEffect, useState } from "react";
import { ReqStatus } from "../../../stores/core/UseApi";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import VisibilityIcon from '@mui/icons-material/Visibility';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { QuoteTravelValidateModalState } from '../../states/QuoteState';
import QuoteTravel from '../quotes/QuoteTravel';
import QuoteTravelValidateModal from '../quotes/QuoteTravelValidateModal';
import { EnumTravelInfosStatus } from '../../classes/QuoteModel';
import { datetimeFormat } from '../../../utils/DateUtils';
import MailIcon from "@mui/icons-material/Mail";
import { Mail2ModalState } from '../../states/MailState';
import { FolderCustomerEmailSelector } from '../../states/FoldersState';
import { BrevoTemplateEnum } from '../../classes/BrevoMailConfig';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useGetLinkTravelInfosConfirm } from '../../stores/QuotationsStore';

const FolderTravelInfosList = (props: { folderId: string }) => {
    const [forceRefresh, setForceRefresh] = useState(0);
    const { folderId } = props;
    const get = useGetTravelInformationList();
    const [rows, setRows] = useState<any[]>([]);

    const getDatas = useCallback(() => {
        if (isNaN(+folderId)) { return; }
        get(folderId).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                setRows(res.data.quotes ?? [])
            }
        })
    }, [folderId]);

    const setModalState = useSetRecoilState(QuoteTravelValidateModalState);

    const validateTravelEvent = useCallback((quoteId: string) => {
        setModalState({
            quoteId: quoteId,
            open: true,
            onValidate: () => { getDatas() }
        })
    }, [setModalState, getDatas]);

    const setMailModalState = useSetRecoilState(Mail2ModalState);
    const customerEmail = useRecoilValue(FolderCustomerEmailSelector);

    const sendQuoteMail = useCallback((id: number) => {
        const _emails = customerEmail ? [customerEmail] : [];
        setMailModalState({
            open: true,
            folderId: +folderId,
            templateIdDefault: BrevoTemplateEnum.BREVO_TPL_14_ENVOI_VALIDATION_INFOS_VOYAGE,
            entityId: id,
            emails: _emails
        });
    }, [setMailModalState, folderId, customerEmail]);

    const sendTravelInfosMail = useCallback((id: number, isSupplier: boolean) => {
        setMailModalState({
            open: true,
            folderId: +folderId,
            templateIdDefault: isSupplier ?
                BrevoTemplateEnum.BREVO_TPL_19_ENVOI_INFOS_VOYAGE_COMPLETED_TO_SUPPLIER:
                BrevoTemplateEnum.BREVO_TPL_15_ENVOI_INFOS_VOYAGE_COMPLETED_TO_CUSTOMER,
            entityId: isSupplier ? undefined: id
        });
    }, [setMailModalState, folderId]);

    const sendRoadmapMail = useCallback((id: number) => {
        const _emails = customerEmail ? [customerEmail] : [];
        setMailModalState({
            open: true,
            folderId: +folderId,
            templateIdDefault: BrevoTemplateEnum.BREVO_TPL_24_ENVOI_ROADMAP_CUSTOMER,
            entityId: id,
            emails: _emails
        });
    }, [setMailModalState, folderId, customerEmail]);

    const openPdfTravelInfos = useCallback((id: string) => {
        window.open(`${process.env.REACT_APP_API}project-dahan/dahan/quotations/view-travel-infos/${id}.pdf`, '_blank');
    }, []);

    const openPdfRoadmap = useCallback((id: string) => {
        window.open(`${process.env.REACT_APP_API}project-dahan/dahan/quotations/view-roadmap/${id}.pdf`, '_blank');
    }, []);

    const getLinkTravelInfosConfirm = useGetLinkTravelInfosConfirm();
    const openLinkTravelInfosConfirm = useCallback((id: string) => {
        getLinkTravelInfosConfirm(+id).then((res) => {
            if(res.status === ReqStatus.SUCCESS && res.data && res.data.link_travel_infos_confirm ){
                window.open(res.data.link_travel_infos_confirm);
            }
        })
    },[]);

    const columns: GridColDef[] = useMemo(() => {
        return [
            {
                field: 'id',
                headerName: "Devis N°",
                editable: false,
                filterable: false,
                minWidth: 100,
            },
            {
                field: 'order_attribute.travel_infos_status',
                headerName: "Statut",
                editable: false,
                filterable: false,
                minWidth: 200,
                renderCell: ({ row }) => (
                    <>
                        {row.order_attribute.travel_infos_status === EnumTravelInfosStatus.INIT && <Alert color="warning" icon={false}>Non rempli</Alert>}
                        {row.order_attribute.travel_infos_status === EnumTravelInfosStatus.CUSTOMER && <Alert color="info" icon={false}>Validé par le client</Alert>}
                        {row.order_attribute.travel_infos_status === EnumTravelInfosStatus.INTERNAL && <Alert color="success" icon={false}>Validé en interne</Alert>}
                    </>
                )
            },
            {
                field: 'order_attribute.travel_infos_date',
                headerName: "Date",
                editable: false,
                filterable: false,
                minWidth: 200,
                renderCell: ({ row }) => <>{row.order_attribute.travel_infos_date ? datetimeFormat(row.order_attribute.travel_infos_date) : ""}</>
            },
            {
                field: 'actions',
                headerName: "Actions",
                editable: false,
                filterable: false,
                minWidth: 300,
                renderCell: ({ row }) => <Box display="flex">

                    <Box>
                        <Tooltip title="Lien confirmation infos voyage">
                            <IconButton onClick={() => openLinkTravelInfosConfirm(row.id)}>
                                <OpenInNewIcon/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                    

                    {row.order_attribute.travel_infos_status === EnumTravelInfosStatus.INIT &&
                        <IconButton onClick={() => sendQuoteMail(row.id)}><MailIcon /></IconButton>
                    }
                    {row.order_attribute.travel_infos_status === EnumTravelInfosStatus.CUSTOMER &&
                        <IconButton onClick={() => validateTravelEvent(row.id)}><CheckCircleOutlineIcon /></IconButton>
                    }
                    {row.order_attribute.travel_infos_status === EnumTravelInfosStatus.INTERNAL &&

                        <Box display="flex">
                            <IconButton onClick={() => validateTravelEvent(row.id)}><CheckCircleOutlineIcon /></IconButton>
                            <Tooltip title="PDF Infos voyage">
                                <IconButton onClick={() => openPdfTravelInfos(row.id)}>
                                    <VisibilityIcon color="info" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Mail Infos voyage pour fournisseur">
                                <IconButton onClick={() => sendTravelInfosMail(row.id, true)}>
                                    <MailIcon color="info" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Mail Infos voyage pour client">
                                <IconButton onClick={() => sendTravelInfosMail(row.id, false)}>
                                    <MailIcon color="secondary" />
                                </IconButton>
                            </Tooltip>
                            <Box ml={1}>
                                <Tooltip title="PDF Feuille de route">
                                    <IconButton onClick={() => openPdfRoadmap(row.id)}>
                                        <VisibilityIcon color="success" />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            <Tooltip title="Mail Feuille de route">
                                <IconButton onClick={() => sendRoadmapMail(row.id)}>
                                    <MailIcon color="success" />
                                </IconButton>
                            </Tooltip>
                        </Box>


                    }
                </Box>
            }
        ]
    }, [validateTravelEvent, openPdfTravelInfos, openPdfRoadmap, sendTravelInfosMail, sendRoadmapMail, sendQuoteMail]);

    useEffect(() => {
        getDatas();
    }, [getDatas]);

    return (
        <>
            <QuoteTravelValidateModal />
            <Box height={371}>
                <DataGrid
                    key={forceRefresh}
                    disableColumnFilter
                    disableRowSelectionOnClick
                    //autoPageSize
                    filterMode="client"
                    rows={rows}
                    columns={columns}
                    getRowId={(row: any) => row.id}
                    hideFooter
                />
            </Box >
        </>
    )
};
export default FolderTravelInfosList;