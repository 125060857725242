export enum EnumQuoteStatus {
    INIT = "quote_inprogress",
    CONFIRMED = "quote_validate",
    CANCELED = "quote_cancel",
}

export enum EnumTravelInfosStatus {
    INIT = 0,
    CUSTOMER = 1,
    INTERNAL = 2,
}

export interface QuoteCircuitModel {
    days: QuoteCircuitDayModel[]
}

export interface QuoteCircuitDayModel {
    id: string;
    date: string | undefined,
    title: string,
    steps: QuoteCircuitDayStepModel[],
    sort: number
}

export interface QuoteCircuitDayStepModel {
    id: string,
    hour: string,
    pax: number | null,
    cars: number | null,
    distance: number | null,
    addresses: QuoteCircuitDayStepAddressModel[],
    sort: number
}

export interface QuoteCircuitDayStepAddressModel {
    id: string,
    type: number | null,
    address: string,
    sort: number
}

export const getLastMarginOfQuote = (quote: any) => {
    let lastMargin: any = null;
    if (quote.order_margins && quote.order_margins.length > 0) {
        lastMargin = quote.order_margins.reduce((latest: any, current: any) => {
            // Compare les IDs directement
            return latest.id > current.id ? latest : current;
        });
    };

    if(lastMargin){
        return {
            ...lastMargin,
            already_com : (lastMargin.margin_net_diff == 0 && lastMargin.margin_net != 0)
        };
    }else{
        return null;
    }
}