import { Alert, Box, Button, Checkbox, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Modal, OutlinedInput, Paper, Select, Tooltip, Typography, Chip, rgbToHex } from "@mui/material";
import { useGetChecktaskList } from "../../stores/FoldersStore";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ReqStatus } from "../../../stores/core/UseApi";
import AddEditFormCard from "../../../components/common/form/AddEditFormCard";
import { TagItem } from "../folders/FolderTagItem";
import DeleteIcon from "@mui/icons-material/Delete";
import FolderChecktasksSelectModal from "./FolderChecktasksSelectModal";
import { useSetRecoilState } from "recoil";
import { FolderChecktasksFormModalState, FolderChecktasksSelectModalState } from "../../states/FolderChecktasksState";
import { FolderChecktaskStatusEnum, FolderChecktaskStatusOptions } from "../../classes/ChecktaskModel";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import CloseIcon from '@mui/icons-material/Close';
import SyncIcon from '@mui/icons-material/Sync';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useDeleteFolderChecktask, useInsertFolderChecktasks, useUpdateFolderChecktaskStatus } from "../../stores/FolderChecktasksStore";
import { ConfirmModalState } from "../../../states/ModalState";
import TooltipComment from "../common/TooltipComment";
import InfoIcon from '@mui/icons-material/Info';
import FolderChecktaskFormModal from "./FolderChecktaskFormModal";

const FolderChecktasks = (props: { folderId: string }) => {

	const { folderId } = props;

	const getChecktaskList = useGetChecktaskList();
	const [checktasks, setChecktasks] = useState<any[]>([]);
	const [statusSelected, setStatusSelected] = useState<FolderChecktaskStatusEnum | undefined>(undefined);
	const [folderChecktasks, setFolderChecktasks] = useState<any[]>([]);

	const folderChecktasksVisible = useMemo(() => {
		return [...(
			statusSelected === undefined) ?
			folderChecktasks :
			folderChecktasks.filter((x) => x.status == statusSelected)
		].sort((a, b) => a.checktask.sort_order - b.checktask.sort_order);
	}, [statusSelected, folderChecktasks])

	const insertChecktasks = useInsertFolderChecktasks();
	const deleteChecktask = useDeleteFolderChecktask();
	const updateFolderChecktaskStatus = useUpdateFolderChecktaskStatus();

	const getData = useCallback((folderId: string) => {
		setChecktasks([]);
		setFolderChecktasks([]);
		getChecktaskList(folderId).then((res) => {
			if (res.status === ReqStatus.SUCCESS && res.data) {
				setChecktasks(res.data.checktasks);
				setFolderChecktasks(res.data.folder_checktasks);
			}

		})
	}, [])

	useEffect(() => {
		getData(folderId);
	}, [folderId]);



	const setConfirmModalState = useSetRecoilState(ConfirmModalState);

	const deleteChecktaskEvent = useCallback((id: number, name: string) => {
		setConfirmModalState({
			open: true,
			title: "Suppression de la tâche " + name,
			desc: "Confirmez-vous la suppression de la tâche ?",
			validEvent: () => {
				deleteChecktask(id).then((res) => {
					if (res.status === ReqStatus.SUCCESS) {
						setFolderChecktasks((fct) => {
							return fct.filter((x) => x.id != id);
						})
					}
				})
			}
		})
	}, []);


	const updateFolderChecktaskStatusEvent = useCallback((id: number, status: FolderChecktaskStatusEnum) => {
		updateFolderChecktaskStatus.call(id, status).then((res) => {
			if (res.status === ReqStatus.SUCCESS) {
				setFolderChecktasks((fct) => {
					const _fct = [...fct];
					_fct.forEach((item) => {
						if (item.id === id) {
							item.status = status;
						}
					});
					return _fct;
				})
			}
		})
	}, []);

	const setSelectModal = useSetRecoilState(FolderChecktasksSelectModalState);
	const setFormModal = useSetRecoilState(FolderChecktasksFormModalState);

	const setCommentInList = useCallback((id: number, comment: string) => {
		setFolderChecktasks((fct) => {
			const _fct = [...fct];
			_fct.forEach((item) => {
				if (item.id === id) {
					item.comment = comment;
				}
			});
			return _fct;
		});
	}, [])

	const columns = useMemo(() => {
		let _columns = [];

		_columns.push({
			field: 'id',
			headerName: "Tâche",
			editable: false,
			filterable: false,
			width: 300,
			renderCell: ({ row }) => (<Box width="100%" mt={1}><TagItem tag={row.checktask} /></Box>)
		} as GridColDef);

		_columns.push({
			field: 'comment',
			headerName: "Commentaire",
			editable: false,
			filterable: false,
			width: 100,
			align: "center",
			renderCell: ({ row }) => (
				<Tooltip
					title={<TooltipComment value={row.comment} />}
					onClick={() => {
						setFormModal({
							open: true,
							folder_checktask_id: row.id,
							onValidate: setCommentInList
						});
					}} >
					<InfoIcon color={row.comment ? "primary" : "disabled"} fontSize="large" />
				</Tooltip>
			)
		} as GridColDef);

		_columns.push({
			field: 'status',
			headerName: "Statut",
			editable: false,
			filterable: false,
			width: 150,
			renderCell: ({ row }) => <>
				{row.status == FolderChecktaskStatusEnum.UNTREATED && <CloseIcon color="action" fontSize="large" />}
				{row.status == FolderChecktaskStatusEnum.INPROGRESS && <SyncIcon color="primary" fontSize="large" />}
				{row.status == FolderChecktaskStatusEnum.WARNING && <WarningAmberIcon color="warning" fontSize="large" />}
				{row.status == FolderChecktaskStatusEnum.CHECKED && <CheckBoxIcon color="success" fontSize="large" />}
				{FolderChecktaskStatusOptions.find((x) => x.value == row.status)?.label ?? ""}
			</>
		} as GridColDef);

		_columns.push({
			field: 'actionss',
			headerName: "Actions",
			editable: false,
			filterable: false,
			flex: 1,
			//headerAlign: "right",
			//align: "right",
			//width: 250,
			renderCell: ({ row }) => (
				<Box display="flex" justifyContent={"space-between"} width={"100%"}>
					<Box display="flex">
						<Box>
							<Tooltip title={FolderChecktaskStatusOptions.find((x) => x.value == FolderChecktaskStatusEnum.UNTREATED)?.label ?? ""}>
								<IconButton onClick={() => updateFolderChecktaskStatusEvent(row.id, FolderChecktaskStatusEnum.UNTREATED)} >
									<CloseIcon color="action" />
								</IconButton>
							</Tooltip>
						</Box>
						<Box ml={1}>
							<Tooltip title={FolderChecktaskStatusOptions.find((x) => x.value == FolderChecktaskStatusEnum.INPROGRESS)?.label ?? ""}>
								<IconButton onClick={() => updateFolderChecktaskStatusEvent(row.id, FolderChecktaskStatusEnum.INPROGRESS)} >
									<SyncIcon color="primary" />
								</IconButton>
							</Tooltip>
						</Box>
						<Box ml={1}>
							<Tooltip title={FolderChecktaskStatusOptions.find((x) => x.value == FolderChecktaskStatusEnum.WARNING)?.label ?? ""}>
								<IconButton onClick={() => updateFolderChecktaskStatusEvent(row.id, FolderChecktaskStatusEnum.WARNING)} >
									<WarningAmberIcon color="warning" />
								</IconButton>
							</Tooltip>
						</Box>
						<Box ml={1}>
							<Tooltip title={FolderChecktaskStatusOptions.find((x) => x.value == FolderChecktaskStatusEnum.CHECKED)?.label ?? ""}>
								<IconButton onClick={() => updateFolderChecktaskStatusEvent(row.id, FolderChecktaskStatusEnum.CHECKED)} >
									<CheckBoxIcon color="success" />
								</IconButton>
							</Tooltip>
						</Box>

					</Box>
					<Box ml={3}><IconButton><DeleteIcon color="error" onClick={() => deleteChecktaskEvent(row.id, row.checktask.name)} /></IconButton></Box>
				</Box>
			)
		} as GridColDef);

		return _columns;
	}, [checktasks]);

	const taskCount = useMemo(() => {
		const taskCheckedCount = folderChecktasks.filter((x) => x.status === FolderChecktaskStatusEnum.CHECKED).length;
		const taskUntreatedCount = folderChecktasks.filter((x) => x.status === FolderChecktaskStatusEnum.UNTREATED).length;
		const taskInprogressCount = folderChecktasks.filter((x) => x.status === FolderChecktaskStatusEnum.INPROGRESS).length;
		const taskWarningCount = folderChecktasks.filter((x) => x.status === FolderChecktaskStatusEnum.WARNING).length;
		return {
			checked: taskCheckedCount,
			untreated: taskUntreatedCount,
			inprogress: taskInprogressCount,
			warning: taskWarningCount,
			total: folderChecktasks.length
		}
	}, [folderChecktasks])

	const title = useMemo(() => {
		return (
			<Box>
				<Typography variant="h5">Check-List {taskCount.total > 0 && <>{taskCount.checked}/{taskCount.total}</>}</Typography>
			</Box>
		)
	}, [taskCount]);

	const [selectedItems, setSelectedItems] = useState<any[]>([]);


	const deleteChecktaskSelection = useCallback(() => {
		setConfirmModalState({
			open: true,
			title: `Suppression de ${selectedItems.length} tâches`,
			desc: "Confirmez-vous la suppression des tâches ?",
			validEvent: () => {
				selectedItems.forEach((id) => {
					deleteChecktask(id).then((res) => {
						if (res.status === ReqStatus.SUCCESS) {
							setFolderChecktasks((fct) => {
								return fct.filter((x) => x.id != id);
							})
						}
					})
				});
			}
		})
	},[selectedItems])

	return (
		<>
			<FolderChecktasksSelectModal />
			<FolderChecktaskFormModal />

			<AddEditFormCard
				title={title}
				action={
					<Box display="flex" alignItems="center" justifyContent={"flex-end"} py={1}>

						<Box mr={2}>
							<IconButton onClick={() => setStatusSelected(undefined)}
								sx={statusSelected == undefined ? { border: "1px solid gray" } : undefined}
							>
								<Chip label={"Tous"} color={"secondary"} variant="filled" />
							</IconButton>

							<IconButton onClick={() => setStatusSelected(FolderChecktaskStatusEnum.UNTREATED)}
								sx={statusSelected == FolderChecktaskStatusEnum.UNTREATED ? { border: "1px solid gray" } : undefined}
							>
								<Chip label={taskCount.untreated} color={"default"} variant="filled" />
							</IconButton>

							<IconButton onClick={() => setStatusSelected(FolderChecktaskStatusEnum.INPROGRESS)}
								sx={statusSelected == FolderChecktaskStatusEnum.INPROGRESS ? { border: "1px solid gray" } : undefined}
							>
								<Chip label={taskCount.inprogress} color={"primary"} variant="filled" />
							</IconButton>

							<IconButton onClick={() => setStatusSelected(FolderChecktaskStatusEnum.WARNING)}
								sx={statusSelected == FolderChecktaskStatusEnum.WARNING ? { border: "1px solid gray" } : undefined}
							>
								<Chip label={taskCount.warning} color={"warning"} variant="filled" />
							</IconButton>

							<IconButton
								onClick={() => setStatusSelected(FolderChecktaskStatusEnum.CHECKED)}
								sx={statusSelected == FolderChecktaskStatusEnum.CHECKED ? { border: "1px solid gray" } : undefined}>
								<Chip label={taskCount.checked} color={"success"} variant="filled" />
							</IconButton>
						</Box>
						<Box ml={1}>
							<Button
								disabled={folderChecktasks.length > 0 && folderChecktasks.length === checktasks.length}
								variant="contained"
								color="info"
								onClick={() => {
									const checktask_used_ids = folderChecktasks.map((x) => x.checktask_id);
									setSelectModal({
										checktasks: checktasks,
										checktask_used_ids: checktask_used_ids,
										onValidate: (s) => {
											insertChecktasks.call(+folderId, s).then((res) => {
												if (res.status === ReqStatus.SUCCESS) {
													getData(folderId);
												}
											});
										},
										open: true
									});
								}
								}>Sélectionner</Button>
						</Box>
						<Box ml={1}>
							<Button disabled={selectedItems.length == 0} variant="contained" color="error"
								onClick={deleteChecktaskSelection}
							>
								Supprimer { selectedItems.length > 0 && `(${selectedItems.length})`}
							</Button>
						</Box>
					</Box>
				}
			>

				<Box maxHeight={400} overflow="auto">
					{folderChecktasksVisible.length > 0 ? (
						< DataGrid
							sx={{
								'& .MuiDataGrid-row:hover': {
									backgroundColor: 'lightblue', // Changez cette couleur selon vos besoins
								},
							}}
							checkboxSelection={true}
							rowSelectionModel={selectedItems}
							onRowSelectionModelChange={(newSelectedItems) => setSelectedItems(newSelectedItems)}
							disableColumnFilter
							disableRowSelectionOnClick
							rowHeight={35}
							//autoPageSize
							filterMode="client"
							rows={folderChecktasksVisible}
							columns={columns}
							getRowId={(row: any) => row.id}
							hideFooter
						/>
					) : (
						<>
							{
								taskCount.total > 0 && taskCount.checked === taskCount.total &&
								<Alert icon={false} color="success">La check-list a été traitée</Alert>
							}
							{
								folderChecktasksVisible.length == 0 &&
								<Alert icon={false} color="info">La checklist n'a pas de tâches pour le statut "{FolderChecktaskStatusOptions.find((x) => x.value == statusSelected)?.label ?? ""}"</Alert>
							}
						</>
					)}
				</Box >

			</AddEditFormCard>
		</>
	)
}

export default FolderChecktasks;