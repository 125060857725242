import { Box } from "@mui/material";
import AddEditFormCard from "../../../components/common/form/AddEditFormCard";
import AddEditFormSubmit from "../../../components/common/form/AddEditFormSubmit";
import TextAreaField from "../../../components/common/form/TextAreaField";
import { useAddEditForm } from "../../../components/common/form/AddEditFormPanel";
import { useCommentFolder } from "../../stores/FoldersStore";
import { useCallback, useEffect } from "react";
import InputField from "../../../components/common/form/InputField";
import { useRecoilValue } from "recoil";
import { IsNotUserCommercialSelector } from "../../states/UserState";

const FolderGlobalNoteForm = (props: { folderId: string, comment_commercial: string, comment: string, title: string }) => {

    const { folderId, comment_commercial, comment, title } = props;
    const { getData, addEditData } = useCommentFolder();

    const onSubmit = useCallback((request: any) => {
        addEditData(request, folderId);
    }, [addEditData, folderId]);

    const { handleSubmit, resetInitialValues, isDirty } = useAddEditForm({
        onSubmit
    });

    useEffect(() => {
        resetInitialValues({ comment_commercial, comment: comment, title: title });
    }, [comment, title])

    const isNotUserCommercial = useRecoilValue(IsNotUserCommercialSelector);

    return (
        <AddEditFormCard title="Informations dossier" withExpand>
            <form id={"folderGlobalNoteForm"} onSubmit={handleSubmit}>
                <InputField
                    label="Titre dossier"
                    name="title"
                    type="text"
                />
                <Box mt={2}>
                    <TextAreaField
                        label="À retenir commercial"
                        name="comment_commercial"
                        maxRows={8}
                    />
                </Box>
                {isNotUserCommercial && 
                    <Box mt={2}>
                        <TextAreaField
                            label="À retenir exploitation"
                            name="comment"
                            maxRows={8}
                        />
                    </Box>
                }
                <AddEditFormSubmit
                    cancelEvent={() => resetInitialValues()}
                    returnAction={{ disabled: true }}
                    disabled={!isDirty}
                />
            </form>
        </AddEditFormCard>
    )
}

export default FolderGlobalNoteForm;