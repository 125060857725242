import { Box, Button, Grid, MenuItem, Paper, TextField } from "@mui/material";
import {DataGrid, GridColDef, GridRowClassNameParams, GridRowParams} from "@mui/x-data-grid";
import { useCallback, useEffect, useMemo, useState, ReactNode } from "react";
import { ListExportButtonsModelCore, ListSearchModelCore } from "../../../classes/list/ListModel";
import ListSearchServer from "./ListSearchServer";
import { useGetListCore } from "../../../stores/core/StoreCore";
import { Skeleton } from "@mui/material";
import { Alert } from "@mui/material";
import ListSearchFilterDateRange from "./ListSearchFilterDateRange";
import ListCard from "./ListCard";

interface ListServerProps<T> {
    columns: GridColDef[],
    getDatas: (paginationLimit: number, page: number, csv?: boolean, pdf?: boolean, xls?: boolean, reinit? : boolean) => void,
    getDatasWithSearch: (search: ListSearchModelCore | undefined, paginationLimit: number, page: number, csv?: boolean, pdf?: boolean, xls?: boolean, reinit? : boolean) => void,
    getDatasWithoutRefresh: (paginationLimit: number, page: number, csv?: boolean, pdf?: boolean, xls?: boolean, reinit? : boolean) => void,
    datas: T[],
    datasItems?: any[],
    search?: ListSearchModelCore,
    setSearch: (callback: (value: ListSearchModelCore | undefined) => ListSearchModelCore | undefined) => void,
    page?: number,
    setPage: (page:number) => void,
    exportButtons?: ListExportButtonsModelCore,
    count: number,
    getRowId?: (row: T) => number | string,
    forceRefresh?: number,
    loading?: boolean,
    selection?: {
        component: (selections: any[]) => JSX.Element
    },
    rowHeight?: number,
    paginationOptionDefault? : number,
    paginationsOptions? : number[],
    filterDateRange?: {
        period: 'week' | 'month'
        fieldName: string
    },
    getRowClassName?: (params: GridRowClassNameParams) => string,
    cardItemBody?: any[],
    cardItemHeader?: any[],
    cardItem?: string[],
    clickCardCondition?: ((item: any) => boolean)[];
    clickCardAction?: (item: any) => void;
    headerActionCard?: (item: any) => ReactNode;

}

const ListServer = <T extends any>(props: ListServerProps<T>) => {

    const { columns,  getDatas, getDatasWithSearch, datas, datasItems, search, setSearch, count, getRowId, exportButtons,
            forceRefresh, loading, selection, rowHeight, paginationOptionDefault, paginationsOptions,
            filterDateRange, page, setPage, getRowClassName, cardItem, cardItemHeader, cardItemBody, clickCardCondition,
        getDatasWithoutRefresh,
        clickCardAction, headerActionCard
    } = props;
    const [width, setWidth] = useState(window.innerWidth);

    const paginationsOptionsCore = useMemo(() => {
        return paginationsOptions ?? [10, 50, 100];
    },[paginationsOptions]);

    const [paginationLimit, setPaginationLimit] = useState(
        paginationOptionDefault ??
        ( (paginationsOptions && paginationsOptions.length > 0) ?
            paginationsOptions[0] :
            10
        )
    );

    const refresh = useCallback((csv?: boolean, pdf?: boolean, xls?: boolean, reinit? : boolean) => {
        getDatas(paginationLimit, 0, csv, pdf, xls, reinit);
    }, [getDatas, paginationLimit])

    const refreshWithSearch = useCallback((search: any, csv?: boolean, pdf?: boolean, xls?: boolean, reinit? : boolean) => {
        getDatasWithSearch(search, paginationLimit, 0, csv, pdf, xls, reinit);
    }, [getDatasWithSearch, paginationLimit])

    useEffect(() => {
        if (!forceRefresh) { return; }
        getDatas(paginationLimit, page ?? 0);
    }, [forceRefresh]);

    useEffect(() => {
        getDatas(paginationLimit, page ?? 0, false, false, false, true);
    }, []);
    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const [selectedItems, setSelectedItems] = useState<any[]>([]);

    const ListSearchFilterDateRangeMemo = useMemo(() => {
        if (!filterDateRange) {
            return undefined;
        }
        return (
            <ListSearchFilterDateRange
                period={filterDateRange.period}
                setSearch={setSearch}
                refresh={(search) => refreshWithSearch(search)}
                loading={loading ?? false}
                fieldName={filterDateRange.fieldName}
            />
        )
    }, [filterDateRange])

    return (<>
        {search ? (
            <Box m={2} >
                <ListSearchServer
                    search={search}
                    setSearch={setSearch}
                    refresh={refresh}
                    refreshWithSearch={refreshWithSearch}
                    exportButtons={exportButtons}
                    datasCount={count}

                />

                {ListSearchFilterDateRangeMemo}

                <Box mt={2} >
                    {datas.length === 0 ? (
                        <Alert severity="info">La sélection est vide.</Alert>
                    ) : (
                        <Paper>
                            {loading ? (
                                <Box p={2}>
                                    <Skeleton variant="rectangular" height="300px" animation="wave" />
                                </Box>
                            ) : (
                                <>
                                    {selection?.component(selectedItems)}
                                    {width < 1000 && cardItem && datasItems ? (
                                        <ListCard
                                            arrayItem={datasItems}
                                            cardItem={cardItem}
                                            cardItemBody={cardItemBody}
                                            cardItemHeader={cardItemHeader}
                                            clickCardCondition={clickCardCondition}
                                            clickCardAction={clickCardAction}
                                            headerActionCard={headerActionCard}
                                            count={count}
                                            getDatasWithoutRefresh={getDatasWithoutRefresh}
                                        />

                                    ) : (
                                        <DataGrid
                                        autoHeight
                                        paginationMode="server"
                                        rows={datas}
                                    rowHeight={rowHeight ?? 44}
                                    columns={columns}
                                    rowCount={count}
                                    getRowClassName={getRowClassName}
                                    paginationModel={{
                                        page: page ?? 0,
                                        pageSize: paginationLimit,
                                    }}
                                    pageSizeOptions={paginationsOptionsCore}
                                    onPaginationModelChange={(model) => {
                                        setPaginationLimit(model.pageSize);
                                        setPage(model.page);
                                        getDatas(model.pageSize, model.page);
                                    }}
                                    getRowId={(row) => (getRowId ? getRowId(row) : row.id)}
                                    disableRowSelectionOnClick
                                    sx={{
                                        ".highlight": {
                                            bgcolor: "#DCDCDC",
                                            "&:hover": {
                                                bgcolor: "#C4C4C4",
                                            },
                                        },
                                        ".bluelight": {
                                            bgcolor: "#DEF4FF",
                                            "&:hover": {
                                                bgcolor: "#B0E5FF",
                                            },
                                        },
                                        }}
                                        {...(selection ?
                                            {
                                                checkboxSelection: true,
                                                rowSelectionModel:selectedItems,
                                                onRowSelectionModelChange:(newSelection) => { setSelectedItems(newSelection); }
                                            }:
                                            {}
                                    )}
                                />
                                    )}

                                </>
                            )}
                        </Paper>
                    )}
                </Box>
            </Box>
        ) : (
            <Box p={2}>
                <Skeleton variant="rectangular" height="300px" animation="wave" />
            </Box>)
        }
    </>


    );
};

export default ListServer;
