import { GridColDef } from "@mui/x-data-grid";
import { useGetListCore } from "../../stores/core/StoreCore";
import ListCore from "../common/list/ListCore";
import { ShippingMethodListModel } from "../../classes/shipping-methods/ShippingMethodModel";
import FormattedAmount from "../common/formatted/FormattedAmount";

const columns : GridColDef[] = [
    {
        field: 'label',
        headerName: "Nom",
        minWidth: 100,
        flex: 1,
    },
    {
        field: 'amount_tax_excl',
        headerName: "Montant HT",
        minWidth: 100,
        flex: 1,
        renderCell: ({ row }) => <FormattedAmount amount={row.amount_tax_excl} symbol="€"/>
    }
];


const ShippingMethodList = () => {
    const routeController = "commercial/shipping-methods";
    const [get] = useGetListCore<ShippingMethodListModel>(`/${routeController}/index`);

    return (
        <ListCore<ShippingMethodListModel, any>
            getDatas={get}
            defineDatasToSet={(data) => data.shippingMethods}
            getRowId={(row) => row.id}
            columns={columns}
            actions={{
                defaultRoute: routeController,
                add: {
                    title: "Ajouter un moyen de livraison"
                },
                edit: true,
                remove: true
            }}
        />
    );
}

export default ShippingMethodList;