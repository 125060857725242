import { atom } from "recoil";

export const FolderChecktasksSelectModalState = atom<{ open: boolean, checktask_used_ids: number[], checktasks:any[], onValidate: (selected: number[]) => void}>({
    key: 'FolderChecktasksSelectModalKey',
    default: {
        open: false,
        checktask_used_ids: [],
        checktasks: [],
        onValidate: () => {}
    }
});

export const FolderChecktasksFormModalState = atom<{ open: boolean, folder_checktask_id: number, onValidate: (id: number, comment: string) => void}>({
    key: 'FolderChecktasksFormModalKey',
    default: {
        open: false,
        folder_checktask_id: 0,
        onValidate: () => {}
    }
});

export const FolderChecktasksModalState = atom<{ open: boolean, folder_id: string}>({
    key: 'FolderChecktasksModalKey',
    default: {
        open: false,
        folder_id: "",
    }
});
