import { useCallback, useMemo } from 'react';
import { Box, Button, IconButton, Tooltip, Alert, FormControlLabel, Checkbox } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useGetReminderList } from "../../stores/FoldersStore";
import { useEffect, useState } from "react";
import { ReqStatus } from "../../../stores/core/UseApi";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { datetimeFormat } from '../../../utils/DateUtils';
import AvatarIcon from '../../../components/common/icon/AvatarIcon';
import FolderReminderButtonConfirm from '../folder-reminders/FolderReminderButtonConfirm';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import FolderReminderModal from '../folder-reminders/FolderReminderModal';
import { useSetRecoilState } from 'recoil';
import { FolderReminderModalState } from '../../states/FolderReminderState';
import EditIcon from '@mui/icons-material/Edit';

import GradeIcon from '@mui/icons-material/Grade';
import AddEditFormCard from '../../../components/common/form/AddEditFormCard';
import JsonView from '../../../components/common/debug/JsonView';
import CheckboxField from '../../../components/common/form/CheckboxField';
import { CreateColumnAvatar } from '../../../components/common/list/column/ColumnAvatar';
export const useFolderReminderColumns = (global: boolean, afterSuccess?: () => void, editEvent?: (id: number) => void) => {
    const navigate = useNavigate();
    const columns = useMemo(() => {

        let _columns = [];

        _columns.push({
            field: 'id',
            headerName: "N°",
            editable: false,
            filterable: false,
            width: 50,
            valueGetter: ({ row }) => `#${row.id}`
        } as GridColDef);

        if (global) {
            _columns.push({
                field: 'folder_id',
                headerName: "N° Dossier",
                editable: false,
                filterable: false,
                width: 100,
                valueGetter: ({ row }) => `#${row.folder_id}`
            } as GridColDef);
        }

        _columns.push({
            field: 'reminder_date',
            headerName: "Date tâche",
            editable: false,
            filterable: false,
            width: 120,
            valueGetter: ({ row }) => datetimeFormat(row.reminder_date)
        } as GridColDef);

        _columns.push({
            field: 'reminder_tags',
            headerName: "Tags",
            editable: false,
            filterable: false,
            width: 250,
            renderCell: ({ row }) => (
                <Box>
                    {row.folder_reminder_tags.map((frt: any, index: number) => (
                        <Box key={index}>
                            <Box>
                                <Box
                                    p={0.1}
                                    sx={{
                                        backgroundColor: frt.reminder_tag.backcolor,
                                        color: frt.reminder_tag.color,
                                    }}
                                >
                                    {frt.reminder_tag.name}
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Box>
            )
        } as GridColDef);

        _columns.push({
            field: 'comment',
            headerName: "Tâche",
            editable: false,
            filterable: false,
            width: 200
        } as GridColDef);

        _columns.push({
            field: 'important',
            headerName: "Important",
            editable: false,
            filterable: false,
            width: 100,
            renderCell: ({ row }) => <>{row.important && <GradeIcon style={{ color: "orange" }} />}</>
        } as GridColDef);

        if (!global) {
            _columns.push(
                CreateColumnAvatar({
                    field: 'user_id',
                    headerName: "Utilisateur",
                    getId: (row) => row.user_id,
                    getValue : (row) => `${row.user.first_name} ${row.user.last_name}`
                })
            );
        }

        return _columns;
    }, [global]);

    const getActions = useCallback(({ row }: any) => {
        return (<>
            <Box ml={1}>
                <FolderReminderButtonConfirm id={+row.id} active={row.active} afterSuccess={() => { if (afterSuccess) { afterSuccess(); } }} />
            </Box>
            {!global && <Box ml={1}><Tooltip title="Modifier tâche"><IconButton onClick={() => { if (row.id && editEvent) { editEvent(row.id); } }}><EditIcon /></IconButton></Tooltip></Box>}
            {global && <Box ml={1}><Tooltip title="Voir dossier"><IconButton onClick={() => navigate(`/folders/add-edit/${row.folder_id}`)}><RemoveRedEyeIcon /></IconButton></Tooltip></Box>}
        </>);
    }, [afterSuccess, editEvent, navigate, global]);

    return { columns, getActions };
}

const FolderReminderList = (props: { folderId: string }) => {
    const { folderId } = props;
    const get = useGetReminderList();
    const [rows, setRows] = useState<any[]>([]);

    const getDatas = useCallback(() => {
        if (isNaN(+folderId)) { return; }
        get(folderId).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                const newRows = res.data.reminders ?? [];
                setRows(newRows);
            }
        })
    }, [folderId]);

    const setReminderModal = useSetRecoilState(FolderReminderModalState);
    useEffect(() => {
        getDatas();
    }, []);

    const displayReminderForm = useCallback((id?: number) => {
        setReminderModal({
            open: true,
            id: id,
            onValidate: () => {
                getDatas();
            }
        })
    }, [getDatas]);

    const { columns, getActions } = useFolderReminderColumns(false, getDatas, displayReminderForm);

    const columnsGrid = useMemo(() => {
        return [
            ...columns,
            {
                field: 'actionss',
                headerName: "",
                editable: false,
                filterable: false,
                width: 140,
                renderCell: getActions
            } as GridColDef
        ];
    }, [columns, getActions])

    const [isChecked, setIsChecked] = useState(false);
    const handleCheckboxChange = (event: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) => {
        setIsChecked(event.target.checked);
    };

    const rowsFiltered = useMemo(() => {
        return isChecked ? rows : rows.filter((row) => row.active);
    }, [rows, isChecked]);

    return (
        <AddEditFormCard
            title="Agenda"
            action={<Box display="flex" justifyContent={"flex-end"} py={1}>
                <FormControlLabel
                    name="name"
                    label="afficher les tâches terminées"
                    control={
                        <Checkbox
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                        />
                    }
                />
                <Button variant="contained" color="info" onClick={() => displayReminderForm()}>Ajouter une tâche</Button>
            </Box>}
        >
            <FolderReminderModal />
            <Box maxHeight={400} overflow="auto">
                {rowsFiltered.length > 0 ? (
                    <DataGrid
                        rowHeight={70}
                        disableColumnFilter
                        disableRowSelectionOnClick
                        //autoPageSize
                        filterMode="client"
                        rows={rowsFiltered}
                        columns={columnsGrid}
                        getRowId={(row: any) => row.id}
                        hideFooter
                    />
                ) : (
                    <Alert icon={false} color="info">Aucune tâche associée à ce dossier</Alert>
                )}
            </Box >
        </AddEditFormCard>
    )
};
export default FolderReminderList;