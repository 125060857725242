let FR = {
    /* ORDER */
    Label_Order: "Devis",
    Label_Orders: "Devis",
    Label_Order_Linked: "Devis associée",
    Label_Order_Number: "Devis n°",
    Label_Order_Status : "Statut devis",
    Label_Order_Date : "Date devis",
    Label_Order_NoResults : "Aucun devis pour ce client",
    Label_Order_Add : "Ajouter un devis",

    /* BILL */
    Label_Bill_Reference: "Commentaire interne",
    Label_Bill_Comment : "Référence",
}

export default FR;