import { Box, Container, Modal } from "@mui/material";
import { FormProvider, useField } from "react-recoil-form";
import { useCallback, useEffect, useMemo } from "react";
import { useRecoilState } from "recoil";
import { FolderReminderModalState } from "../../states/FolderReminderState";
import { FolderFinalStatusModalState } from "../../states/FoldersState";
import { useAddEditForm } from "../../../components/common/form/AddEditFormPanel";
import AddEditFormCard from "../../../components/common/form/AddEditFormCard";
import InputField from "../../../components/common/form/InputField";
import AddEditFormSubmit from "../../../components/common/form/AddEditFormSubmit";
import CheckboxField from "../../../components/common/form/CheckboxField";
import AutocompleteField from "../../../components/common/form/AutocompleteField";
import { FolderClosurePatterns } from "../../classes/FolderModel";

interface FolderFinalStatusFormProps {
	onClose: () => void,
	onValidate: (is_closure: boolean, closure_pattern: string) => void
}
const FolderFinalStatusForm = (props: FolderFinalStatusFormProps) => {

	const { onClose, onValidate } = props;

	const onSubmit = useCallback((request: any) => {
		onValidate(request.is_closure ?? false, request.closure_pattern ?? "");
	}, []);

	const { handleSubmit, resetInitialValues, isDirty } = useAddEditForm({
		onSubmit
	});

	useEffect(() => {
		resetInitialValues({ is_closure: false, closure_pattern: "" });
	}, [])

	const patternOptions = useMemo(() => {
		return FolderClosurePatterns.map((pattern: string) => {
			return { id: pattern, label: pattern }
		})
	}, [FolderClosurePatterns])

	const { fieldValue: isClosureValue } = useField({ name: "is_closure" })

	return (
		<AddEditFormCard title="Finalisation dossier" withExpand>
			<form id={"folderFinalStatusForm"} onSubmit={handleSubmit}>
				<CheckboxField
					label="Clôture ?"
					name="is_closure"
					
				/>
				{isClosureValue &&
					<Box mt={2}>
						<AutocompleteField
							label="Motif de clôture"
							name="closure_pattern"
							options={patternOptions}
							required
						/>
					</Box>
				}
				<AddEditFormSubmit
					cancelEvent={() => { resetInitialValues(); onClose(); }}
					returnAction={{ disabled: true }}
					disabled={false}
				/>
			</form>
		</AddEditFormCard>
	)
}

const FolderFinalStatusModal = () => {

	const [modalState, setModalState] = useRecoilState(FolderFinalStatusModalState);

	const handleClose = useCallback(() => {
		setModalState((a) => {
			return { ...a, open: false };
		});
	}, [setModalState]);


	const handleValidate = useCallback((is_closure: boolean, closure_pattern: string) => {
		modalState.onValidate(is_closure, closure_pattern);
		setModalState((a) => {
			return { ...a, open: false };
		});
	}, [modalState, setModalState]);

	return (
		<Modal
			open={modalState.open}
			aria-labelledby="folder-final-status-modal"
			sx={{
				overflow: 'auto'
			}}
		>
			<Container component="main" maxWidth="sm" sx={{ height: "100%", py: 2 }}>
				<FormProvider options={{ formId: "folderFinalStatusForm", skipRecoilRoot: true }}>

					<FolderFinalStatusForm
						onClose={handleClose}
						onValidate={handleValidate}
					/>

				</FormProvider>
			</Container>
		</Modal>
	);
}

export default FolderFinalStatusModal;