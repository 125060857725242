import { useCallback } from "react";
import { ListModelCore } from "../../classes/list/ListModel";
import { useApi } from "../../stores/core/UseApi";

const routeController = 'project-dahan/dahan/brevo-mail-configs';

export interface BrevoMailConfigsListModel extends ListModelCore{
    brevoMailConfigs: any[]
}

export const useGetSynchronisation = () => {
    const [callCore] = useApi<any, undefined>(
        {
            route:  `${routeController}/get-synchronisation`,
            instanceAxiosMethod: "GET"
        }
    );
    const call = useCallback(() => {
        return callCore();
    }, [callCore]);
    return call;
};