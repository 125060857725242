import { Alert, Box, Divider, Grid, Paper, Skeleton } from "@mui/material";
import CustomerCard from "../customers/CustomerCard";
import FolderStepper, { folderFinalStatus } from "./FolderStepper";
import { useCallback, useEffect, useMemo, useState } from "react";
import FolderTabs from "./FolderTabs";
import FolderActions from "./FolderActions";
import { NavigateState } from "../../../states/MenuState";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useAddEditCore, useGetListCore } from "../../../stores/core/StoreCore";
import { useParams } from "react-router-dom";
import { ReqStatus } from "../../../stores/core/UseApi";
import FolderQuoteList from "./FolderQuoteList";
import { useUpdateFolderStatus } from "../../stores/FoldersStore";
import FolderPaymentList from "./FolderPaymentList";
import FolderDetails from "./FolderDetails";
import FolderOrderSupplierContactList from "../folder-order-supplier-contacts/FolderOrderSupplierContactList";
import FolderOrderSupplierList from "../folder-order-suppliers/FolderOrderSupplierList";
import { createOptionsForSelect } from "../../../utils/DataUtils";
import { SelectOptionsProps } from "../../../components/common/form/SelectField";
import FolderOrderSupplierPaymentList from "../folder-order-supplier-payments/FolderOrderSupplierPaymentList";
import FolderProformaList from "./FolderProformaList";
import FolderTags from "../folder-tags/FolderTags";
import FolderTravelInfosList from "./FolderTravelInfosList";
import FolderBillList from "./FolderBillList";
import { useSnackbar } from "notistack";
import { FormProvider } from "react-recoil-form";
import FolderGlobalNoteForm from "./FolderGlobalNoteForm";
import FolderReminderList from "./FolderReminderList";
import FolderNoteList from "../folder-notes/FolderNoteList";
import FolderMargins from "./FolderMargins";
import CustomerFoldersView from "../customers/CustomerFoldersView";
import SendSmsModal from "../sms/SendSmsModal";
import { FolderModel, FolderTabEnum } from "../../classes/FolderModel";
import FolderHistory from "./FolderHistory";
import FolderFinalStatusModal from "./FolderFinalStatusModal";
import { FolderFinalStatusModalState } from "../../states/FoldersState";
import SendMailModal from "../mails/SendMailModal";
import { IsNotUserCommercialSelector, UserExtenalSelector } from "../../states/UserState";
import { BrevoMailConfigsListModel } from "../../stores/BrevoMailStore";
import { BrevoMailConfigsState } from "../../states/MailState";
import { QuoteCircuitTypesState } from "../../states/QuoteCircuitState";
import FolderChecktasks from "../folder-checktasks/FolderChecktasks";
import { CurrentUserState } from "../../../states/AuthState";

interface FolderResponse {
    folder: FolderModel,
    commercials: { [id: string]: string },
    circuitTypes: { [id: string]: string },
}

export const useFolderView = (id: string | undefined) => {

    const routeApiController = "project-dahan/dahan/folders";
    const { getData } = useAddEditCore<any, FolderResponse>(`${routeApiController}/add-edit`);
    const setNavigateState = useSetRecoilState(NavigateState);
    const [statusId, setStatusId] = useState<number>(1);
    const [folder, setFolder] = useState<FolderModel>();
    const [commercialName, setCommercialName] = useState("");
    const [folderReady, setFolderReady] = useState(false);
    const updateFolderStatus = useUpdateFolderStatus();
    const { enqueueSnackbar } = useSnackbar();
    const setCircuitTypes = useSetRecoilState(QuoteCircuitTypesState);

    const [getBrevoMailConfigs] = useGetListCore<BrevoMailConfigsListModel>(`project-dahan/dahan/brevo-mail-configs/index`);
    const [templates, setTemplates] = useRecoilState(BrevoMailConfigsState);

    useEffect(() => {
        if ( templates.length > 0) { return; }
        getBrevoMailConfigs(500, 0).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data && res.data.brevoMailConfigs) {
                setTemplates(res.data.brevoMailConfigs ?? []);
            }
        });
    }, [templates])

    useEffect(() => {
        if (!id) { setNavigateState("/folders/"); return; }
        setFolder(undefined);
        getData(id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                const _folder = res.data.folder;

                setStatusId(_folder.status);
                setFolder(_folder);
                setCircuitTypes(createOptionsForSelect(res.data.circuitTypes ?? []));
            } else {
                setNavigateState("/folders/"); return;
            }
            setFolderReady(true);
        })
    }, [id])

    const setFolderFinalStatusModal = useSetRecoilState(FolderFinalStatusModalState);
    const updateFolderStatusEvent = useCallback((statusId: number) => {
        if (!id) { return; }
        if (statusId === folderFinalStatus) {
            setFolderFinalStatusModal({
                open: true,
                onValidate: (is_closure, closure_pattern) => {
                    updateFolderStatus(+id, statusId, is_closure, closure_pattern).then((res) => {
                        if (res.status === ReqStatus.SUCCESS) {
                            setStatusId(statusId);
                            setFolder((f) => {
                                if (!f) { return; }
                                return {
                                    ...f,
                                    is_closure: is_closure,
                                    closure_pattern: closure_pattern
                                }
                            })
                        }
                    })
                }
            })
        } else {
            updateFolderStatus(+id, statusId).then((res) => {
                if (res.status === ReqStatus.SUCCESS) {
                    setStatusId(statusId);
                }
            })
        }
    }, [id]);

    return {
        folder,
        statusId,
        updateFolderStatusEvent,
        commercialName,
        folderReady
    };
}

const FolderView = () => {

    let { id } = useParams();

    const [tabId, setTabId] = useState(FolderTabEnum.QUOTES);
    useEffect(() => {
        setTabId(FolderTabEnum.QUOTES);
    }, [id])

    const { folder, statusId, updateFolderStatusEvent, commercialName } = useFolderView(id);
    const isNotUserCommercial = useRecoilValue(IsNotUserCommercialSelector);
    const isExternal = useRecoilValue(UserExtenalSelector);
    const currentUser = useRecoilValue(CurrentUserState);

    const sectionMarginIsVisible = useMemo(() => {
        if(!currentUser){ return false; }
        if(isExternal){ return false; }
        return (currentUser.user_group_id === 1 || currentUser.user_group_id === 2 || currentUser.user_group_id === 3 );
    },[isExternal, currentUser])

    return (
        <Box height={"100%"} overflow={"auto"}>

            {!folder && <Box m={2}><Skeleton height={300} /></Box>}

            {folder && <>
                <SendMailModal />
                <SendSmsModal />
                <FolderFinalStatusModal />
                <FolderStepper
                    stepId={statusId}
                    folder={folder}
                    onClickEvent={(statusId) => { updateFolderStatusEvent(statusId); }}
                />

                <Grid container>
                    <Grid item sm={4}>
                        <Box m={2}>


                            <CustomerCard customerId={folder.customer_id} />
                            <Divider />
                            <FolderDetails folder={folder} commercialName={commercialName} />
                            <Divider />
                            <FolderActions folder={folder} />
                            <Divider />
                            {/* Fournisseurs */}


                        </Box>

                        {id &&
                            <Box>
                                <FolderTags folderId={id} />
                            </Box>
                        }

                        <Box>
                            {id && folder.commercial_id &&
                                <>
                                    <FolderOrderSupplierList folderId={id} />
                                    <FolderOrderSupplierContactList />
                                    <FolderOrderSupplierPaymentList />
                                </>
                            }
                        </Box>
                    </Grid>
                    <Grid item sm={8} >
                        {!folder.commercial_id &&
                            <Box p={2}>
                                <Alert color="warning" icon={false}>Veuillez affecter un commercial pour pouvoir saisir dans ce dossier</Alert>
                            </Box>
                        }

                        {folder.commercial_id && <>
                            <Box m={2}>
                                <FolderTabs tabId={tabId} onClickEvent={(id) => setTabId(id)} marginIsVisible={sectionMarginIsVisible} />
                                <Box>
                                    <Paper>
                                        {id && tabId === FolderTabEnum.NOTES && <FolderNoteList folderId={id} />}
                                        {id && tabId === FolderTabEnum.QUOTES && <FolderQuoteList folderId={id} />}
                                        {id && tabId === FolderTabEnum.PROFORMA && <FolderProformaList folderId={id} />}
                                        {id && tabId === FolderTabEnum.PAYMENTS && <FolderPaymentList folderId={id} />}
                                        {id && tabId === FolderTabEnum.TRAVEL_INFO && isNotUserCommercial && <FolderTravelInfosList folderId={id} />}
                                        {id && tabId === FolderTabEnum.INVOICES && <FolderBillList folderId={id} />}
                                        {id && sectionMarginIsVisible && tabId === FolderTabEnum.MARGINS && <FolderMargins folderId={id} />}
                                    </Paper>
                                </Box>
                            </Box>

                            {id && isNotUserCommercial &&
                                <FolderChecktasks folderId={id} />

                            }
                            
                            {id &&
                                <FolderReminderList folderId={id} />
                            }

                            {id &&
                                <Box mt={2}>
                                    <FormProvider options={{ formId: "folderGlobalNoteForm", skipRecoilRoot: true }} >
                                        <FolderGlobalNoteForm folderId={id} comment_commercial={folder.comment_commercial} comment={folder.comment} title={folder.title} />
                                    </FormProvider>
                                </Box>
                            }

                            {id &&
                                <CustomerFoldersView title="Autres dossiers" customerId={folder.customer_id} withExpand={true} ignoreFolderId={+id} />
                            }
                            {id &&
                                <FolderHistory title="Historique" folderId={+id} withExpand={true} />
                            }
                        </>}

                    </Grid>
                </Grid>

            </>}

        </Box>
    )
};

export default FolderView;