import { Alert, Box, Button, Grid, Paper, Typography } from "@mui/material";
import InputField from "../common/form/InputField";
import { FormattedMessage } from "react-intl";
import { useField, useForm, useIsDirty } from "react-recoil-form";
import { ReqStatus } from "../../stores/core/UseApi";
import { UserGroupModel } from "../../classes/user-groups/UserGroupModel";
import { useEffect, useMemo, useState } from "react";
import SelectField, { SelectOptionsProps } from "../common/form/SelectField";
import { useGetUser, useAddEditUser } from "../../stores/UsersStore";
import { UserModel } from "../../classes/users/UserModel";
import { CheckBox } from "@mui/icons-material";
import CheckboxField from "../common/form/CheckboxField";
import { useNavigate, useParams } from "react-router";
import AddEditForm from "../common/form/AddEditForm";
import AddEditFormCard from "../common/form/AddEditFormCard";
import { useAddEditCore } from "../../stores/core/StoreCore";
import { useAddEditForm } from "../common/form/AddEditFormPanel";
import { createOptionsForSelect } from "../../utils/DataUtils";
import { datetimeFormat } from "../../utils/DateUtils";

interface GetUserResponse {
    user: UserModel,
    userGroups: { [groupId: number]: string },
    viewMode: string
}
const UserView = () => {
    let { id, from } = useParams();
    const { getData, addEditData } = useAddEditCore<UserModel, GetUserResponse>(`users/add-edit`);

    const [roles, setRoles] = useState<SelectOptionsProps[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        getData(id ?? undefined).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                if (id) {
                    const _user = res.data.user;
                    resetInitialValues({
                        user_group_id : _user.user_group_id,
                        last_name : _user.last_name,
                        first_name : _user.first_name,
                        email : _user.email,
                        phone : _user.phone,
                        active : _user.active,
                        last_login : _user.last_login
                    });
                }
                setRoles(createOptionsForSelect(res.data.userGroups));
            } else {
                navigate("/users");
            }
        })
    }, [id]);

    const onSubmit = (request: UserModel) => {
        addEditData(request, id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                if (id) {
                    if (Array.isArray(res.data)) {
                        const _user = res.data[0];
                        if (_user && _user.user_group_id && _user.last_name && _user.first_name && _user.email && _user.phone && _user.active && _user.last_login) {
                            resetInitialValues({
                                user_group_id : _user.user_group_id,
                                last_name : _user.last_name,
                                first_name : _user.first_name,
                                email : _user.email,
                                phone : _user.phone,
                                active : _user.active,
                                last_login : _user.last_login
                            });
                        }
                    }
                } else {
                    navigate("/users");
                }
            }
        })
    }

    const { handleSubmit, resetInitialValues, isDirty } = useAddEditForm({ onSubmit, initialValues: {} });
    const lastLoginField = useField({ name: 'last_login' });

    return (

        <AddEditForm formId={"userForm"} formHandleCancel={resetInitialValues} formHandleSubmit={handleSubmit} isDirty={isDirty}>
            <AddEditFormCard title={(!id ? "Ajouter " : "Modifier") + " un utilisateur"} >
                <Grid container spacing={3} mt={1}>
                    <Grid item xs={12} sm={6} lg={4}>
                        <InputField
                            name="last_name"
                            label="Nom"
                            required
                            type="text"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                        <InputField
                            name="first_name"
                            label="Prénom"
                            required
                            type="text"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                        <InputField
                            name="email"
                            label="Email"
                            required
                            type="text"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                        <InputField
                            name="phone"
                            label="Téléphone"
                            type="text"
                        />
                    </Grid>

                    {from !== "customer" &&
                        <Grid item xs={12} sm={6} lg={4}>

                            <SelectField
                                name="user_group_id"
                                label="Rôle"
                                options={roles}
                                required
                            />
                        </Grid>
                    }


                    {id &&
                        <Grid item xs={12} sm={6} lg={4}>
                            <CheckboxField
                                name="active"
                                label="Actif"
                            />
                        </Grid>
                    }

                    {id && !lastLoginField.fieldValue &&
                        <Box mx={3} mt={2}>
                            <Alert severity="warning">Cet utilisateur ne s'est pas encore connecté.</Alert>
                        </Box>
                    }

                    {id && lastLoginField.fieldValue &&
                        <Box mx={3} mt={2}>
                            <Alert severity="info">Dernière connexion le {datetimeFormat(lastLoginField.fieldValue)}</Alert>
                        </Box>
                    }

                    <Grid display="flex" justifyContent="space-between" item xs={12}>
                        <Box>
                            {id &&
                                <Button color="info" variant="contained" onClick={() => {
                                    navigate(`/users/edit-password/${id}`);
                                }}>
                                    Modifier le mot de passe
                                </Button>
                            }
                        </Box>
                    </Grid>
                </Grid>
            </AddEditFormCard>


        </AddEditForm>
    );
}

export default UserView;
