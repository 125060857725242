import { Box } from "@mui/material";
import AddEditFormPanel from "../../../components/common/form/AddEditFormPanel";
import AddEditFormSubmit from "../../../components/common/form/AddEditFormSubmit";
import { useEffect, useMemo } from "react";
import AutocompleteField, { AutocompleteOption } from "../../../components/common/form/AutocompleteField";
import { useForm } from "react-recoil-form";
import { useRecoilValue } from "recoil";
import InputField from "../../../components/common/form/InputField";
import { CurrentUserState } from "../../../states/AuthState";
import TextAreaField from "../../../components/common/form/TextAreaField";
import { phonesCodes } from "./PhoneCode";
import { useParams } from "react-router-dom";
import { FolderCustomerState } from "../../states/FoldersState";

interface SendSmsFormProps {
	onCloseEvent: () => void
}

const SendSmsForm = (props: SendSmsFormProps) => {
	let { id } = useParams();
	const { onCloseEvent } = props;

	const customer = useRecoilValue(FolderCustomerState);
	const currentUser = useRecoilValue(CurrentUserState);

	const { handleSubmit, resetInitialValues } = useForm({
		onSubmit: (values: any) => {
			const phoneEncoded = encodeURIComponent(`${values.pref_phone ?? ""}${values.phone}`);
			const contentEncoded = encodeURIComponent(values.content);
			const whatappsUrl = `https://wa.me/${phoneEncoded}?text=${contentEncoded}`;
			window.open(whatappsUrl, "_blank");
			onCloseEvent();
		}
	});

	const prefPhoneOptions = useMemo(() => {
		const options: AutocompleteOption[] = phonesCodes.map((phoneCode) => {
			return {
				id: phoneCode.pref,
				label: `${phoneCode.code} - ${phoneCode.pref}`
			};
		});
		return [...options];
	}, []);

	useEffect(() => {
		const prefDefault = (phonesCodes.find(x => x.code === "FR")?.pref ?? null);

		resetInitialValues({
			phone: customer?.user.phone ?? "",
			pref_phone: prefDefault,
			content: 
`Bonjour, J'ai reçu votre demande concernant un transfert en autocar.
Pourriez-vous me rappeler lorsque vous aurez ce message, j'ai besoin de détails concernant votre transfert afin d'établir votre devis correctement.
Je vous souhaite une belle journée.
${currentUser?.first_name ?? ''} ${currentUser?.last_name ?? ''}
COMPAGNIE DES AUTOCARS
Tél : ${currentUser?.phone ?? ''}
Port : 06 44 64 84 31
${currentUser?.email ?? ''}
www.compagniedesautocars.fr
* Pour toute correspondance, merci de nous indiquer votre numéro de demande : ${id}`
		})

	}, [])



	return (
		<AddEditFormPanel
			title={"Envoi SMS"}
		>
			<form id={"smsForm"} onSubmit={handleSubmit} >
				<Box sx={{ overflowY: "auto", maxHeight: 700 }}>

					<Box mt={2} display="flex">
						<AutocompleteField
							name="pref_phone"
							label="Préfixe"
							options={prefPhoneOptions}
						/>

						<InputField
							name="phone"
							label="Téléphone"
							type="text"
							required
						/>
					</Box>

					<Box mt={2}>
						<TextAreaField
							name="content"
							label="Message"
							required
							maxRows={12}
						/>
					</Box>
				</Box>
				<Box mt={2}>
					<AddEditFormSubmit
						returnAction={{ disabled: true }}
						cancelEvent={() => { resetInitialValues(); onCloseEvent(); }}
						disabled={false}
					/>
				</Box>
			</form>


		</AddEditFormPanel >
	)
}

export default SendSmsForm;