import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useSetRecoilState } from "recoil";
import OrderBillCustomRow from "./OrderBillCustomRow";
import { NavigateState } from "../../../states/MenuState";

interface OrderBillsViewProps {
    id: string,
    billed: boolean,
    bills: any[],
    creditNotes: any[],
    sendPdf: boolean,
    disabled?: boolean
}
const OrderBillsCustomView = (props: OrderBillsViewProps) => {

    const { id, bills, creditNotes, billed, sendPdf, disabled } = props;
    const setNavigateState = useSetRecoilState(NavigateState);

    return (
        <Box display={"flex"} flexDirection={"column"}>
            {!disabled &&
                <Box display="flex">
                    {!billed &&
                        <Box mr={2}>
                            <Button variant="contained" color="info" onClick={() => setNavigateState(`commercial/bills/add/${id}`)}>Créer une facture</Button>
                        </Box>
                    }
                    {!disabled && <>
                            <Box mr={2}><Button variant="contained" color="warning" onClick={() => setNavigateState(`commercial/bills/add/${id}/credit-note`)}>Créer un avoir</Button></Box>
                            <Box mr={2}><Button variant="contained" color="info" onClick={() => setNavigateState(`commercial/bills/add/${id}/deposit`)}>Créer une facture d'acompte</Button></Box>
                            <Box><Button variant="contained" color="warning" onClick={() => setNavigateState(`commercial/bills/add/${id}/deposit-credit-note`)}>Créer un avoir d'acompte</Button></Box>
                        </>
                    }
                    
                </Box>
            }
            {(bills.length > 0 || creditNotes.length > 0) &&
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>N°</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell >Total HT</TableCell>
                            <TableCell >Total TTC</TableCell>
                            <TableCell >Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {bills.map((bill, key) => (
                            <OrderBillCustomRow key={`bill_${key}`} data={bill} sendPdf={sendPdf} />
                        ))}
                        {creditNotes.map((creditNote, key) => (
                            <OrderBillCustomRow key={`credit_note_${key}`} data={creditNote} sendPdf={sendPdf} />
                        ))}
                    </TableBody>
                </Table>
            }
        </Box>
    );
}

export default OrderBillsCustomView;