
import { GridColDef } from "@mui/x-data-grid";
import { ListModelCore } from "../../../classes/list/ListModel";
import { useGetListCore } from "../../../stores/core/StoreCore";
import ListCore from "../../../components/common/list/ListCore";
import { getLabelOfStatusByFolder } from "./FolderStepper";
import { getLabelOfFolderOrigin, getlabelOfFolderPax, getlabelOfFolderTypeJourney } from "../../classes/FolderModel";
import AvatarIcon from "../../../components/common/icon/AvatarIcon";
import { useMemo, useState } from "react";
import { Box, Tooltip } from "@mui/material";
import FolderTagItem from "./FolderTagItem";
import { FolderTag } from "../../classes/FolderTagModel";
import { dateFormat, datetimeFormat } from "../../../utils/DateUtils";
import AddUserOnEntityButton from "../common/AddUserOnEntityButton";
import { addUserOnEntityType } from "../../states/FoldersState";
import AddUserOnEntityModal from "../common/AddUserOnEntityModal";
import { useRecoilValue } from "recoil";
import { CurrentUserState } from "../../../states/AuthState";
import { CreateColumnDate } from "../../../components/common/list/column/ColumnDate";
import { CreateColumnAvatar } from "../../../components/common/list/column/ColumnAvatar";

export const useFolderListcolumns = (is_lead?: boolean) => {

    if (is_lead) {
        return [
            {
                field: 'id',
                headerName: "N°",
                editable: false,
                filterable: false,
                width: 70,
                renderCell: ({ row }) => (<>#{row.id}<br />{getLabelOfFolderOrigin(row.origin)}</>)
            },
            {
                field: 'webhook_origin',
                headerName: "Origine",
                editable: false,
                filterable: false,
                width: 120,
            },
            {
                field: 'customer_id',
                headerName: "Client",
                editable: false,
                filterable: false,
                width: 150,
                renderCell: ({ row }) => (<>{row.customer_id &&
                    <Box display="flex" flexDirection={"column"} justifyContent="center" alignItems="center">
                        <Box>{row.customer.user.first_name}<br />{row.customer.user.last_name}</Box>
                    </Box>}
                </>)
            },
            {
                field: 'customer_email',
                headerName: "Email",
                editable: false,
                filterable: false,
                width: 200,
                renderCell: ({ row }) => (
                    <Box>
                        {row?.customer.user.email}
                        {row?.customer.dahan_emails_compl.split(';').map((x: string) => <><br />{x}</>) ?? <></>}
                    </Box>
                )
            },
            {
                field: 'customer_phone',
                headerName: "Téléphone",
                editable: false,
                filterable: false,
                width: 110,
                renderCell: ({ row }) => (
                    <Box>
                        {row?.customer.user.phone}
                        {row?.customer.dahan_phones_compl.split(';').map((x: string) => <><br />{x}</>) ?? <></>}
                    </Box>
                )
            },
            {
                field: 'departure_date',
                headerName: "Départ",
                editable: false,
                filterable: false,
                maxWidth: 100,
                renderCell: ({ row }) => (<Box>
                    <Box>{dateFormat(row.departure_date)}</Box>
                    <Box>{row.departure_place}</Box>
                </Box>)
            },
            {
                field: 'return_date',
                headerName: "Retour",
                editable: false,
                filterable: false,
                maxWidth: 100,
                renderCell: ({ row }) => (<Box>
                    <Box>{dateFormat(row.return_date)}</Box>
                    <Box>{row.return_place}</Box>
                </Box>)
            },
            {
                field: 'pax',
                headerName: "Pax",
                editable: false,
                filterable: false,
                width: 160,
                renderCell: ({ row }) => <Box display="flex">{getlabelOfFolderPax(row.pax)}</Box>
            },
            {
                field: 'type_journey',
                headerName: "Type",
                editable: false,
                filterable: false,
                width: 120,
                valueGetter: ({ row }) => getlabelOfFolderTypeJourney(row.type_journey)
            },
            CreateColumnAvatar({
                field: 'commercial_suggested_id',
                headerName: "Com. suggéré",
                minWidth: 120,
                getId: (row) => (row.commercial_suggested_id),
                getValue: (row) => `${row.suggested_commercial?.first_name} ${row.suggested_commercial?.last_name}`
            }),
            CreateColumnDate({
                field: 'created',
                headerName: "Créé le",
                getValue: (row) => row.created,
                type: "dateTime"
            })
        ] as GridColDef[];
    } else {
        return [
            {
                field: 'id',
                headerName: "N°",
                editable: false,
                filterable: false,
                maxWidth: 100,
                renderCell: ({ row }) => (<>#{row.id}<br />{getLabelOfFolderOrigin(row.origin)}</>)
            },
            {
                field: 'title',
                headerName: "Titre",
                editable: false,
                filterable: false,
                width: 120,
            },
            {
                field: 'customer_id',
                headerName: "Client",
                editable: false,
                filterable: false,
                width: 150,
                renderCell: ({ row }) => (<>{row.customer_id &&
                    <Box display="flex" flexDirection={"column"} justifyContent="center" alignItems="flex-start">
                        <Box>{row.customer.user.first_name} {row.customer.user.last_name}</Box>
                        <Box>{row.customer.dahan_company}</Box>
                    </Box>}
                </>)
            },
            {
                field: 'status',
                headerName: "Statut",
                editable: false,
                filterable: false,
                width: 150,
                renderCell: ({ row }) => <Box width={150} display="flex" flexWrap="wrap">
                    <p style={{ flex: 1, overflowWrap: 'break-word', whiteSpace: 'normal' }}>
                        {getLabelOfStatusByFolder(row)}
                    </p>
                </Box>
            },
            {
                field: 'tags',
                headerName: "Tags",
                editable: false,
                filterable: false,
                width: 200,
                renderCell: ({ row }) => (
                    <Box display="flex" flexDirection="column">
                        {row.folder_tags?.map((folderTag: FolderTag, tag_key: number) => (<>
                            <FolderTagItem folderTag={folderTag} key={tag_key} /></>
                        ))}
                    </Box>
                )
            },
            {
                field: 'departure',
                headerName: "Départ",
                editable: false,
                filterable: false,
                width: 100,
                renderCell: ({ row }) =>
                    <Box display="flex" flexDirection="column">
                        <Box>
                            {dateFormat(row.departure_date)}
                        </Box>
                        <Tooltip title={row.departure_place ?? " "}>
                            <Box textOverflow="ellipsis" width={140} overflow="hidden" whiteSpace="nowrap">
                                {row.departure_place}
                            </Box>
                        </Tooltip>
                    </Box>
            },
            {
                field: 'pax',
                headerName: "Pax",
                editable: false,
                filterable: false,
                width: 90,
                renderCell: ({ row }) => < Box width={100} display="flex" flexWrap="wrap" >
                    <p style={{ flex: 1, overflowWrap: 'break-word', whiteSpace: 'normal' }}>
                        {getlabelOfFolderPax(row.pax)}
                    </p>
                </Box >
            },
            {
                field: 'type_journey',
                headerName: "Type",
                editable: false,
                filterable: false,
                width: 110,
                valueGetter: ({ row }) => getlabelOfFolderTypeJourney(row.type_journey)
            },
            CreateColumnAvatar({
                field: 'commercial_id',
                headerName: "Commercial",
                minWidth: 120,
                getId: (row) => row.commercial_id,
                getValue: (row) => `${row.commercial.first_name} ${row.commercial.last_name}`
            }),
            {
                field: 'travels.order_product_attribute.departure_date',
                headerName: "Départ le",
                editable: false,
                filterable: false,
                minWidth: 120,
                renderCell: ({ row }) => <Box>
                    {row.order_attributes.map((order_attribute: any) => {
                        const travels = order_attribute.order.travels;
                        return (
                            <>
                                {
                                    Array.isArray(travels) &&
                                    travels.length > 0 &&
                                    travels[0].order_product_attribute &&
                                    travels[0].order_product_attribute.departure_date &&
                                    <Box>
                                        {datetimeFormat(travels[0].order_product_attribute.departure_date)}
                                    </Box>
                                }
                            </>
                        );
                    })}
                </Box>
            },
            {
                field: 'order_attributes.quote_validated_date',
                headerName: "Signé le",
                editable: false,
                filterable: false,
                width: 120,
                renderCell: ({ row }) => <Box>
                    {row.order_attributes.map((order_attribute: any) => (
                        <>
                            {order_attribute.quote_validated_date &&
                                <Box>
                                    {datetimeFormat(order_attribute.quote_validated_date)}
                                </Box>
                            }
                        </>
                    ))}
                </Box>
            }
        ] as GridColDef[];
    }
}


export interface FolderListModel extends ListModelCore {
    folders: any[]
}

const FolderList = (props: { is_lead?: boolean, is_forgotten?: boolean }) => {
    const { is_lead, is_forgotten } = props;
    const [forceRefresh, setForceRefresh] = useState(0);
    const routeApiController = "project-dahan/dahan/folders";
    const folderListcolumns = useFolderListcolumns(is_lead);
    const [get] = useGetListCore<FolderListModel>(`/${routeApiController}/${is_lead ? "lead" : (is_forgotten ? "forgotten" : "index")}`, (is_lead ? "only_lead=true" : undefined));
    const currentUser = useRecoilValue(CurrentUserState);

    const addUserOnEntityButtonVisible = useMemo(() => {
        return currentUser && (currentUser.user_group_id === 1 || currentUser.user_group_id === 2);
    }, [currentUser]);

    return (
        <>
            <AddUserOnEntityModal />
            <ListCore<FolderListModel, any>
                key={forceRefresh}
                getDatas={get}
                defineDatasToSet={(data) => data.folders}
                getRowId={(row) => row.id}
                rowHeight={100}
                columns={folderListcolumns}
                actions={{
                    defaultRoute: "folders",
                    add: !is_lead ? {
                        title: "Ajouter un dossier",
                    } : undefined,
                    edit: true,
                    others: (row) => (
                        <>
                            {addUserOnEntityButtonVisible && <Box ml={1}>
                                <AddUserOnEntityButton
                                    type={addUserOnEntityType.COMMERCIAL_FOLDER}
                                    ids={[row.id]}
                                    onValidate={() => setForceRefresh((v) => v + 1)}
                                />

                            </Box>
                            }
                        </>
                    )
                }}
                selection={addUserOnEntityButtonVisible ? {
                    component: (selectionItems) => (
                        <Box m={1} pt={1}>
                            <AddUserOnEntityButton
                                type={addUserOnEntityType.COMMERCIAL_FOLDER}
                                disabled={selectionItems.length === 0}
                                ids={selectionItems}
                                onValidate={() => setForceRefresh((v) => v + 1)}
                            />
                        </Box>
                    )
                } : undefined}
            />
        </>
    );
}

export default FolderList;

function getCommercialSuggestedId(commercial_id: any): any {
    throw new Error("Function not implemented.");
}
