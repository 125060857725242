import { Box, Collapse, Divider, List, ListItem, ListItemIcon, ListItemText, Paper, useMediaQuery, useTheme, Slide, Drawer } from "@mui/material";
import { useAppRoutes, SectionItem } from "./AppRoutes";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { MenuIndexState, MenuVisibleState } from "../states/MenuState";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo } from "react";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from "react";

const MenuSection = (props: { section: SectionItem }) => {
    const { section } = props;
    const menuIndex = useRecoilValue(MenuIndexState);
    const setMenuVisible = useSetRecoilState(MenuVisibleState);
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        if (menuIndex.index === section.index) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [menuIndex.index, section.index])

    const theme = useTheme();
    const isWeb = useMediaQuery(theme.breakpoints.up('sm'));

    const displayChildren = useCallback(() => {
        return (
            section.children.map((route, index) =>
                (route.menuVisible && route.enabled &&
                    <ListItem
                        key={index}
                        style={{
                            cursor: "pointer",
                            borderLeft: "4px solid " + ((section.index === menuIndex.index && route.index === menuIndex.subIndex) ? "blue" : "transparent")
                        }}
                        onClick={() => { navigate(route.path); if (!isWeb) { setMenuVisible(false); } }}
                    >
                        <ListItemIcon >
                            {route.menuIcon}
                        </ListItemIcon>
                        <ListItemText sx={{ marginLeft: "-20px" }} primary={route.title} />
                    </ListItem>
                ))
        )
    }, [section.children, section.index, navigate, menuIndex, setMenuVisible, isWeb]);

    const atLeastOneChildrenEnabled = useMemo(() => {
        return (section.children.find(r => r.enabled && r.menuVisible)) ? true : false;
    }, [section])

    return (
        <>
            {atLeastOneChildrenEnabled &&
                <>
                    <ListItem onClick={() => setOpen(!open)} sx={{ cursor: "pointer", }}>
                        <ListItemText primary={section.title} />
                        {!open && <ExpandMoreIcon />}
                        {open && <ExpandLessIcon />}
                    </ListItem>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {displayChildren()}
                        </List>
                    </Collapse>
                    <Divider />
                </>
            }
        </>
    )
}

const Menu = () => {
    const menuVisible = useRecoilValue(MenuVisibleState);
    const setMenuVisible = useSetRecoilState(MenuVisibleState);
    const { routes } = useAppRoutes();
    const theme = useTheme();
    const isWeb = useMediaQuery(theme.breakpoints.up('sm'));

    // Fermer le menu par défaut sur mobile
    useEffect(() => {
        if (!isWeb) {
            setMenuVisible(false);
        }
    }, [isWeb, setMenuVisible]);

    const handleMenuToggle = () => {
        setMenuVisible(!menuVisible);
    };

    return (
        <Box sx={{ display: 'flex'}}>
            {isWeb ? (
                <Drawer
                    variant="persistent"
                    anchor="left"
                    open={menuVisible}
                    sx={{
                        '& .MuiDrawer-paper': {
                            width: 240,
                            boxSizing: 'border-box',
                            height: 'calc(100vh - 65px)',
                            backgroundColor: 'white',
                            top: '0',
                            position: 'relative',
                        }
                    }}
                >
                    <Paper sx={{ height: "100%", backgroundColor: 'white', overflowY: "auto" }}>
                        <List disablePadding >
                            {routes.map((section, key) => (
                                <MenuSection key={key} section={section} />
                            ))}
                        </List >
                    </Paper>
                </Drawer>
            ) : (
                <Slide direction="down" in={menuVisible} mountOnEnter unmountOnExit>
                    <Box
                        height={"calc(100vh - 65px)"}
                        sx={{
                            position: "absolute",
                            width: "100%",
                            backgroundColor: 'white',
                            zIndex: 1000,
                        }}
                    >
                        <Paper sx={{ height: "inherit", backgroundColor: 'white', overflowY: "auto" }}>
                            <List disablePadding >
                                {routes.map((section, key) => (
                                    <MenuSection key={key} section={section} />
                                ))}
                            </List >
                        </Paper>
                    </Box>
                </Slide>
            )}
            <Box component="main" sx={{ flexGrow: 1, transition: 'margin-left 0.3s ease', marginLeft: isWeb && menuVisible ? '0' : '-240px'}} > </Box>
        </Box>
    );
}

export default Menu;
