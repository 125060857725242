import { Chip } from "@mui/material";
import { useMemo } from "react";
import FormattedAmount from "../../../../components/common/formatted/FormattedAmount";

const ColumnRemainingAmount = (props : { remainingAmount: number | null | undefined}) => {
	const { remainingAmount } = props;

	const color = useMemo(() => {
		let color: 'error' | 'info' | 'warning' ;

		if(remainingAmount == 0 || !remainingAmount){
			return 'info';
		}
		if(remainingAmount > 0){
			return "error";
		}
			
		//Si trop perçu
		return "warning";
	},[remainingAmount])

	return (
		<>{
			remainingAmount !== null && remainingAmount !== undefined && !isNaN(remainingAmount) &&
			<Chip
				label={<FormattedAmount amount={remainingAmount} symbol="€"/>}		
				color={color}
				variant="filled"
			/>
		}
		</>
	);
}

export default ColumnRemainingAmount;