import { LinearProgress } from '@mui/material';
import { makeStyles, Theme } from '@mui/material/styles'
import { Box, SxProps } from '@mui/system';
import React from 'react';
import { usePromiseTracker } from "react-promise-tracker";

const lpStyle = {
    position: 'absolute',
    width: '100%',
    bottom: 0
};

const boxStyle = { 
    position: 'relative' 
};

const HeaderLoader = () => {
    const { promiseInProgress } = usePromiseTracker();
    return (
        <Box sx={boxStyle}>
            { promiseInProgress && <LinearProgress sx={lpStyle} color="secondary" /> }
        </Box>
    );
}

export default HeaderLoader;