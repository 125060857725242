import { Box, Card, Tooltip, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useGetMargins } from "../../stores/FoldersStore";
import { ReqStatus } from "../../../stores/core/UseApi";
import { FolderOrderSupplierListState } from "../../states/FolderOrderSuppliersState";
import { useRecoilValue } from "recoil";
import { EnumFolderOrderSupplierStatus, FolderOrderSupplierModel, getColorOfFolderOrderSupplierStatus } from "../../classes/FolderOrderSupplierModel";
import FormattedAmount from "../../../components/common/formatted/FormattedAmount";
import { IsNotUserCommercialSelector } from "../../states/UserState";
import { datetimeFormat } from "../../../utils/DateUtils";
import { getLastMarginOfQuote } from "../../classes/QuoteModel";
import { last } from "lodash";

const FolderMarginItem = (props: { quote: any, folderOrderSuppliers: FolderOrderSupplierModel[] }) => {

	const { quote, folderOrderSuppliers } = props;

	const lastMargin = useMemo(() => {
		return getLastMarginOfQuote(quote);
	}, [quote]);

	const tooltipMarginsDetail = useMemo(() => {
		if (!quote.order_margins || quote.order_margins.length === 0) {
			return null; // ou une valeur par défaut appropriée
		}
		return (
			<>
				<Box>Date | BRUT | NET | DIFF</Box>
				{quote.order_margins.map((m: any) => (
					<Box>{datetimeFormat(m.created)} | {m.margin_brut}€ | {m.margin_net}€ | {m.margin_net_diff}€</Box>
				))}

			</>
		)
	}, [quote.order_margins]);

	const isNotUserCommercial = useRecoilValue(IsNotUserCommercialSelector);
	const suppliers = useMemo(() => {
		return folderOrderSuppliers.filter((x => x.status === EnumFolderOrderSupplierStatus.CONFIRMED || x.status === EnumFolderOrderSupplierStatus.VALIDATED || x.status === EnumFolderOrderSupplierStatus.CANCELED)).map(f => (
			<Box px={0.2} mt={0.2} sx={getColorOfFolderOrderSupplierStatus(f.status)}>{isNotUserCommercial ? f.supplier.name : `#${f.supplier.id}`}</Box>
		));
	}, [folderOrderSuppliers, isNotUserCommercial])

	return (
		<Box p={1}>
			<Card>
				<Box p={1}>
					<Box>
						<Typography fontWeight="bold">Devis #{quote.id} - {quote.order_status.label}</Typography>
					</Box>
					<Box width={220}>
						<Box display="flex" justifyContent="space-between">
							<Typography fontWeight="bold">PV HT</Typography> <Box><FormattedAmount amount={quote.total_minus_discount_tax_excl} symbol="€" /></Box>
						</Box>

						<>
							<Box display="flex" justifyContent="space-between">
								<Typography fontWeight="bold">Marge brut</Typography> <Box><FormattedAmount amount={lastMargin?.margin_brut ?? 0} symbol="€" /></Box>
							</Box>
							<Box display="flex" justifyContent="space-between">
								<Typography fontWeight="bold">Marge net</Typography> <Box><FormattedAmount amount={lastMargin?.margin_net ?? 0} symbol="€" /></Box>
							</Box>
							<Box display="flex" justifyContent="space-between">
								<Typography fontWeight="bold">Marge net diff</Typography> <Box><FormattedAmount amount={lastMargin?.margin_net_diff ?? 0} symbol="€" /></Box>
							</Box>
						</>
						
						<Tooltip title={tooltipMarginsDetail} >
							<Box>Détails historique</Box>
						</Tooltip>

						<Box>
							<Typography fontWeight="bold">Fournisseur(s)</Typography>
							<Box>{suppliers}</Box>
						</Box>
					</Box>
				</Box>
			</Card>
		</Box>
	)

}

const FolderMargins = (props: { folderId: string }) => {
	const { folderId } = props;
	const [quotes, setQuotes] = useState<any[]>([]);
	const get = useGetMargins();

	const folderOrderSupplierList = useRecoilValue(FolderOrderSupplierListState);

	const getFolderOrderSupplier = useCallback((orderId: number) => {
		const list = folderOrderSupplierList.filter((v) => v.order_id === orderId);
		return list ?? [];
	}, [folderOrderSupplierList])

	const getDatas = useCallback(() => {
		if (isNaN(+folderId)) { return; }
		get(folderId).then((res) => {
			if (res.status === ReqStatus.SUCCESS && res.data) {
				setQuotes(res.data.quotes ?? [])
			}
		})
	}, [folderId]);

	const margeTotal = useMemo(() => {
		let _m = 0;
		quotes.forEach((q) => {
			const lastMargin = getLastMarginOfQuote(q);
			if(lastMargin){
				_m += lastMargin.margin_net;
			}
		})
		return (_m).toFixed(2);
	}, [folderOrderSupplierList, quotes])

	useEffect(() => {
		getDatas();
	}, [])

	return (
		<>
			<Box>
				<Box display={"flex"} flexWrap={"wrap"}>
					{quotes.map((quote, quote_key) => (
						<FolderMarginItem key={quote_key} quote={quote} folderOrderSuppliers={getFolderOrderSupplier(quote.id)} />
					))}

				</Box>

				<Box p={1}>
					<Box display="flex">
						<Typography fontWeight="bold">Marge du dossier</Typography>&nbsp;<Box><FormattedAmount amount={margeTotal} symbol="€" /></Box>
					</Box>
					{/* 
					<Box display="flex">
						<Typography fontWeight="bold">Marge réel du dossier</Typography>&nbsp;<Box>{margeReelTotal} €</Box>
					</Box>
					*/}
				</Box>
			</Box>
		</>
	)
}

export default FolderMargins;