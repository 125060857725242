import { useEffect, useMemo, useState } from "react";
import { useAddBill, useGetBill } from "../../stores/BillsStore";
import { useNavigate, useParams } from "react-router-dom";
import { BillModel } from "../../../classes/bills/BillModel";
import { ReqStatus } from "../../../stores/core/UseApi";
import { useField, useForm, useIsDirty } from "react-recoil-form";
import { mapStringValueForForm } from "../../../utils/DataUtils";
import { initOrderTotalAdditional } from "../../../components/orders/OrderView";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import AddEditForm from "../../../components/common/form/AddEditForm";
import BillCustomerInfo from "../../../components/bills/BillCustomerInfo";
import AddEditFormCard from "../../../components/common/form/AddEditFormCard";
import SelectField, { SelectOptionsProps } from "../../../components/common/form/SelectField";
import NumberField from "../../../components/common/form/NumberField";
import InputField from "../../../components/common/form/InputField";
import TextAreaField from "../../../components/common/form/TextAreaField";
import { useIntl } from "react-intl";

const DepositCreditNoteView = (props: { modeCalcul: "HT" | "TTC", type: "credit-note" | "deposit" | "deposit-credit-note" }) => {

	const { id } = useParams();
	const navigate = useNavigate();
	const getOrderInfos = useGetBill(props.type);
	const addCreditNote = useAddBill(props.type);

	const intl = useIntl();

	const [ready, setReady] = useState(false);

	const [response, setResponse] = useState<any>({});

	const [bills, setBills] = useState<any[]>([]);

	const onSubmit = (bill: BillModel) => {
		if (!id) { return; }
		addCreditNote(bill, id).then((res) => {
			if (res.status === ReqStatus.SUCCESS && res.data) {
				navigate(-1);
			}
		});
	}
	const { handleSubmit, resetInitialValues } = useForm({
		onSubmit
	});
	const isDirty = useIsDirty();

	useEffect(() => {
		if (!id) { return; }

		getOrderInfos(id).then((res) => {
			if (res.status === ReqStatus.SUCCESS && res.data) {
				setResponse(res.data)

				const _order = res.data.bill.order;

				setBills(_order.bills);

				resetInitialValues({
					billing_first_name: mapStringValueForForm(_order.billing_address.first_name, _order.customer.user.first_name),
					billing_last_name: mapStringValueForForm(_order.billing_address.last_name, _order.customer.user.last_name),
					billing_email: mapStringValueForForm(_order.billing_address.email, _order.customer.user.email),
					billing_address_1: _order.billing_address.address_1,
					billing_address_2: _order.billing_address.address_2,
					billing_postcode: _order.billing_address.postcode,
					billing_city: _order.billing_address.city,
					billing_country_id: _order.billing_address.country_id,

					shipping_first_name: mapStringValueForForm(_order.shipping_address.first_name, _order.customer.user.first_name),
					shipping_last_name: mapStringValueForForm(_order.shipping_address.last_name, _order.customer.user.last_name),
					shipping_email: mapStringValueForForm(_order.shipping_address.email, _order.customer.user.email),
					shipping_address_1: _order.shipping_address.address_1,
					shipping_address_2: _order.shipping_address.address_2,
					shipping_postcode: _order.shipping_address.postcode,
					shipping_city: _order.shipping_address.city,
					shipping_country_id: _order.shipping_address.country_id,

					...initOrderTotalAdditional(id, _order),

					credit_note_amount_choice: props.type === "credit-note" ? 'full' : 'custom',
					reference: '',
					comment: '',
				})

				setReady(true);
			}
			else {
				navigate("/commercial/bills/");
			}
		})


	}, [id])

	const modeField = useField({ name: 'credit_note_amount_choice' });
	const montantHtField = useField({ name: 'total_minus_discount_tax_excl' });
	const tvaPctField = useField({ name: 'credit_note_custom_vat' });
	const montantTvaField = useField({ name: 'total_minus_discount_tax' });
	const montantTTCField = useField({ name: 'total_minus_discount_tax_incl' });


	useEffect(() => {
		if (modeField.fieldValue !== "custom") { return; }

		if (props.modeCalcul === "HT") {
			const montantHt = parseFloat(montantHtField.fieldValue) || 0;
			const tvaPct = parseFloat(tvaPctField.fieldValue) || 0;
			montantTvaField.setFieldValue(
				(montantHt * tvaPct / 100).toFixed(2)
			);
			montantTTCField.setFieldValue(
				(montantHt * (1 + (tvaPct / 100))).toFixed(2)
			);
		} else {
			const montantTTC = parseFloat(montantTTCField.fieldValue) || 0;
			const tvaPct = parseFloat(tvaPctField.fieldValue) || 0;
			const montantHT = (montantTTC / (1 + (tvaPct / 100))).toFixed(2);
			montantHtField.setFieldValue(
				montantHT
			);
			montantTvaField.setFieldValue(
				montantTTC - +montantHT
			);
		}


	}, [tvaPctField.fieldValue, montantHtField.fieldValue, montantTTCField.fieldValue, modeField.fieldValue, props.modeCalcul]);


	useEffect(() => {
		if (!ready || !response?.bill?.order) { return; }

		if (modeField.fieldValue === "full") {
			const totalAdditionnal = initOrderTotalAdditional(id, response.bill.order);
			montantHtField.setFieldValue(totalAdditionnal.total_tax_excl_plus_shipping_tax_excl);
			montantTvaField.setFieldValue(totalAdditionnal.total_tax_plus_shipping_tax);
			montantTTCField.setFieldValue(totalAdditionnal.total_tax_incl_plus_shipping_tax_incl);
		} else {
			montantHtField.setFieldValue(0);
			tvaPctField.setFieldValue(props.type === "credit-note" ? 0 : 10);
		}
	}, [ready, modeField.fieldValue]);

	const title = useMemo(() => {
		if (props.type === "credit-note") {
			return "Avoir";
		}
		if (props.type === "deposit-credit-note") {
			return "Avoir d'acompte";
		}
		if (props.type === "deposit") {
			return "Acompte";
		}

		return "";
	}, [props.type])

	const modeOptions: SelectOptionsProps[] = useMemo(() => {
		let options: SelectOptionsProps[] = [];
		if (props.type === "credit-note") {
			options.push({ label: "Total de la commande", value: "full" });
		}
		options.push({ label: "Montant personnalisé", value: "custom" });
		return options;
	}, [props.type])


	const deposits = useMemo(() => {
		return [
			...bills.filter((x) => x.dahan_is_deposit)
		]
	}, [bills]);

	const depositOptions: SelectOptionsProps[] = useMemo(() => {
		const deposits = [
			...bills.filter((x) => x.dahan_is_deposit)
		];
		return deposits.map((d: any) => {
			const label = `${d.is_credit_note ? "Avoir d'acompte n° " : "Facture d'acompte n° "} ${d.id} - ${d.total_minus_discount_tax_incl} € TTC`;
			return {
				value: d.id,
				label: label
			}
		})
	}, [deposits])

	return (
		<>
			{!ready && <Box m={2}><Skeleton variant="rectangular" height={300} /></Box>}
			{ready &&
				<AddEditForm formId="creditNoteForm" formHandleCancel={resetInitialValues} formHandleSubmit={handleSubmit} isDirty={isDirty}>
					<BillCustomerInfo />
					<AddEditFormCard title={'Devis'}>
						<Typography>Total TTC : {response.bill.order.total_tax_incl} €</Typography>
					</AddEditFormCard>
					<AddEditFormCard title={title}>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>

								<SelectField
									label="Mode"
									name="credit_note_amount_choice"
									required
									options={modeOptions}
									disabled={props.type !== "credit-note"}
								/>

								<Grid container spacing={2} mt={0} mb={2}>
									<Grid item xs={3}>
										<NumberField
											label="Montant HT"
											name="total_minus_discount_tax_excl"
											required
											disabled={modeField.fieldValue === 'full' || props.modeCalcul === "TTC"}
										/>
									</Grid>
									{modeField.fieldValue === 'custom' &&
										<Grid item xs={3}>
											<NumberField

												label="TVA %"
												name="credit_note_custom_vat"
												required

											/>
										</Grid>
									}
									<Grid item xs={3}>
										<NumberField
											label="Montant TVA"
											name="total_minus_discount_tax"
											required
											disabled
										/>
									</Grid>
									<Grid item xs={3}>
										<NumberField

											label="Montant TTC"
											name="total_minus_discount_tax_incl"
											required
											disabled={modeField.fieldValue === 'full' || props.modeCalcul === "HT"}
										/>
									</Grid>
									{props.type === "deposit-credit-note" &&
										<Grid item xs={12}>
											<SelectField
												name="dahan_deposit_id"
												options={depositOptions}
												label={"Lié à facture d'acompte"}
												required
											/>
										</Grid>
									}
								</Grid>
							</Grid>
							<Grid item xs={12} sm={6}>
								<InputField
									label={intl.formatMessage({id: "Label_Bill_Reference"})}
									name="reference"
									type="text"
								/>
								<Box mt={2}>
									<TextAreaField
										label={intl.formatMessage({id: "Label_Bill_Comment"})}
										name="comment"
										required
									/>
									<Box><Typography variant="subtitle2" mb={2}>Ce texte sera visible sur le document pdf<br/>Il permet de détailler la ligne.</Typography></Box>	
								</Box>

							</Grid>
						</Grid>


					</AddEditFormCard>
				</AddEditForm>
			}
		</>

	);
}

export default DepositCreditNoteView;