import { Box, Checkbox, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import CheckboxField from "../common/form/CheckboxField";
import { useFormValues } from "react-recoil-form";
import { GroupAuthorizationEntities } from "../../classes/group-authorizations/GroupAuthorizationModel";
import { useCallback, useMemo } from "react";
import { GroupAuthorizationEntityActionsRequest } from "../../classes/group-authorizations/GroupAuthorizationRequest";

interface GroupAuthorizationTableActionsProps {
    entities: GroupAuthorizationEntities,
    resetInitialValues: (values?: any, extraInfos?: any) => void
}

const GroupAuthorizationTableActions = (props: GroupAuthorizationTableActionsProps) => {

    const { entities, resetInitialValues } = props;
    const values = useFormValues();

    const allChecked = useMemo(() => {
        if (!values) {
            return false;
        }

        let check = true;
        Object.entries(values as GroupAuthorizationEntityActionsRequest).forEach(([key, action]) => {
            Object.entries(action).forEach(([action_key, authorization]) => {
                if (values[key][action_key] === false) {
                    check = false;
                }
            });
        });
        return check;
    }, [values]);

    const setAllChecked = useCallback(() => {
        if (!values) { return; }
        const check = !allChecked;
        let _values: GroupAuthorizationEntityActionsRequest = {};
        Object.entries(values as GroupAuthorizationEntityActionsRequest).forEach(([key, action]) => {
            Object.entries(action).forEach(([action_key, authorization]) => {
                if (!_values[key]) { _values[key] = {}; }
                _values[key][action_key] = check;
            });
        });
        resetInitialValues(_values);
    }, [allChecked, resetInitialValues, values]);

    return (
        <Table sx={{ minWidth: 650 }} >
            <TableHead>
                <TableRow>
                    <TableCell>Entité</TableCell>
                    <TableCell >Action</TableCell>
                    <TableCell >Autorisation</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell><strong>Tout sélectionner / désélectionner</strong></TableCell>
                    <TableCell>
                    </TableCell>
                    <TableCell>
                        <Box display="flex" >
                            <Checkbox
                                checked={Boolean(allChecked)}
                                onChange={(e) => setAllChecked()}
                            />
                        </Box>
                    </TableCell>
                </TableRow>
                {entities && Object.entries(entities).map(([cle, entity]) => (
                    Array.isArray(entity.actions) && entity.actions.map((action) => (
                        action &&
                        <TableRow key={`${cle}_${action}`}>
                            <TableCell>{entity.plugin && `${entity.plugin} - `}{entity.name}</TableCell>
                            <TableCell>
                                <Box display="flex" >
                                    {action}
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Box display="flex">
                                    <CheckboxField
                                        name={`${cle}[${action}]`}
                                        label={""}
                                        withoutLabel
                                    />
                                </Box>
                            </TableCell>
                        </TableRow>
                        
                    ))))}
            </TableBody>
        </Table>
    );
}

export default GroupAuthorizationTableActions;