import { Alert, Box, Button } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ReqStatus } from "../../../stores/core/UseApi";
import ListItemActions from "../../../components/common/list/ListItemActions";
import { useGetFoldersByCustomerId } from "../../stores/FoldersStore";
import { datetimeFormat } from "../../../utils/DateUtils";
import AddEditFormCard from "../../../components/common/form/AddEditFormCard";
import { NavigateState } from "../../../states/MenuState";
import { useSetRecoilState } from "recoil";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { OthersCustomerFolderStatusGroupedState } from "../../states/FoldersState";
import { cleanup } from "@testing-library/react";
import { getLabelOfStatusByFolder } from "../folders/FolderStepper";

const CustomerFoldersView = (props: { customerId: number, withExpand: boolean, title: string, ignoreFolderId?: number, buttonAdd? : boolean }) => {
	const { customerId, withExpand, title, buttonAdd, ignoreFolderId } = props;
	const get = useGetFoldersByCustomerId();
	const [rows, setRows] = useState<any[]>([]);
	const [folderStatus, setFolderStatus] = useState<any[]>([]);
	const setNavigateState = useSetRecoilState(NavigateState);

	const setOthersCustomerFolderStatusGroupedState = useSetRecoilState(OthersCustomerFolderStatusGroupedState);

	const getDatas = useCallback(() => {
		if (isNaN(+customerId)) { return; }
		get(customerId).then((res) => {
			if (res.status === ReqStatus.SUCCESS && res.data) {

				if(Array.isArray(res.data.folders)){
					let statusInProgressCount = 0;
					let statusOnHoldCount = 0;
					let statusFinishedCount = 0;
					res.data.folders.forEach((folder) => {
						if(ignoreFolderId && ignoreFolderId == folder.id){
							return;
						}

						if(folder.status >= 1 && folder.status <= 3){
							statusInProgressCount++;
						}else if(folder.status >= 4 && folder.status <= 10){
							statusOnHoldCount++;
						}else if(folder.status == 11 && !folder.is_closure){
							statusFinishedCount++;
						}
					});
					setOthersCustomerFolderStatusGroupedState({
						ready: true,
						inProgress: statusInProgressCount,
						onHold: statusOnHoldCount,
						finished: statusFinishedCount
					})
				}

				setRows(res.data.folders ?? [])
				setFolderStatus(res.data.folderStatus ?? [])
			}
		})
	}, [customerId]);

	const rowsFiltered = useMemo(() => {
		if(!ignoreFolderId){ return rows; }
		return rows.filter((r) => r.id !== ignoreFolderId);
	},[rows]);

	const columns: GridColDef[] = useMemo(() => {
		return [
			{
				field: 'id',
				headerName: "N°",
				editable: false,
				filterable: false,
				width: 80,
				renderCell: ({ row }) => `#${row.id}`
			},
			{
				field: 'customer.full_name',
				headerName: "Client",
				editable: false,
				filterable: false,
				width: 150,
				renderCell: ({ row }) => `${row.customer.user.last_name} ${row.customer.user.first_name}`
			},
			{
				field: 'title',
				headerName: "Titre",
				editable: false,
				filterable: false,
				width: 150
			},
			{
				field: 'status',
				headerName: "Statut",
				editable: false,
				filterable: false,
				width: 150,
				renderCell: ({ row }) => getLabelOfStatusByFolder(row)
			},
			{
				field: 'commercial.full_name',
				headerName: "Commercial",
				editable: false,
				filterable: false,
				width: 150,
				renderCell: ({ row }) => (row.commercial) ? `${row.commercial.last_name} ${row.commercial.first_name}` : 'Non affecté'
			},
			{
				field: 'created',
				headerName: "Création",
				editable: false,
				filterable: false,
				width: 120,
				renderCell: ({ row }) => datetimeFormat(row.created)
			},
			{
				field: 'actions',
				headerName: "Actions",
				editable: false,
				filterable: false,
				width: 60,
				renderCell: ({ row }: any) => <ListItemActions
					defaultRoute={`folders`}
					edit={{
						enable: true,
						icon: true
					}}
					row={row}
					id={row.id}
				/>
			}
		];
	}, [folderStatus]);


	useEffect(() => {
		getDatas();

		return () => {
			setOthersCustomerFolderStatusGroupedState( (v) => { return {...v, ready: false }; });
		}
	}, [getDatas]);
	  

	return (
		<AddEditFormCard title={title} withExpand={withExpand} >
			{buttonAdd && <Box mb={1}>
				<Button variant="contained" color="info" onClick={() => setNavigateState(`/folders/add/${customerId}`)}>Créer un dossier</Button>
			</Box>
			}
			{rows.length > 0 &&
				<Box maxHeight={480} overflow="auto">
					<DataGrid
						disableRowSelectionOnClick
						autoHeight
						//autoPageSize
						
						filterMode="client"
						rows={rowsFiltered}
						columns={columns}
						getRowId={(row: any) => row.id} 
						localeText={{
							noRowsLabel: "Aucun dossier associé à ce client"
						}}
					/>
				</Box >
			}
			{rows.length == 0 && <Alert icon={false} color="info">Aucun dossier associé à ce client</Alert>}
		</AddEditFormCard>
	)
};

export default CustomerFoldersView;