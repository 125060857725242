import { useCallback, useMemo } from 'react';
import { Box, Chip, IconButton, Tooltip } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useGetQuoteList } from "../../stores/FoldersStore";
import { useEffect, useState } from "react";
import { ReqStatus } from "../../../stores/core/UseApi";
import ListItemActions from "../../../components/common/list/ListItemActions";
import VisibilityIcon from '@mui/icons-material/Visibility';
import MailIcon from "@mui/icons-material/Mail";
import { Mail2ModalState } from '../../states/MailState';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { ConfirmModalState } from '../../../states/ModalState';
import { useDuplicateQuote } from '../../stores/QuotationsStore';
import { FolderCustomerEmailSelector, addUserOnEntityType } from '../../states/FoldersState';
import { dateFormat, datetimeFormat } from '../../../utils/DateUtils';
import AvatarIcon from '../../../components/common/icon/AvatarIcon';
import AddUserOnEntityModal from '../common/AddUserOnEntityModal';
import AddUserOnEntityButton from '../common/AddUserOnEntityButton';
import { CreateColumnAvatar } from '../../../components/common/list/column/ColumnAvatar';
import ColumnRemainingAmount from '../common/column/ColumnRemainingAmount';
import FormattedAmount from '../../../components/common/formatted/FormattedAmount';
import { BrevoTemplateEnum } from '../../classes/BrevoMailConfig';

const FolderQuoteList = (props: { folderId: string }) => {
    const [forceRefresh, setForceRefresh] = useState(0);
    const { folderId } = props;
    const get = useGetQuoteList();
    const [rows, setRows] = useState<any[]>([]);

    const getDatas = useCallback(() => {
        if (isNaN(+folderId)) { return; }
        get(folderId).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {

                const quotes = res.data.quotes ?? [];
                quotes.forEach((quote) => {
                    quote.dahan_payments_total_amount = 0;
                    quote.dahan_left_to_pay = null;
                    if (Array.isArray(quote.payments)) {
                        quote.payments.forEach((payment: any) => {
                            quote.dahan_payments_total_amount += payment.total_amount;
                        });
                        quote.dahan_left_to_pay = (+(quote.total_minus_discount_tax_incl - quote.dahan_payments_total_amount)).toFixed(2);
                    }
                });
                setRows(quotes)
            }
        })
    }, [folderId]);

    const openPdf = useCallback((id: string) => {
        window.open(`${process.env.REACT_APP_API}project-dahan/dahan/quotations/view-quotation/${id}.pdf`, '_blank');
    }, []);

    const setConfirmModalState = useSetRecoilState(ConfirmModalState);
    const duplicateQuoteAjax = useDuplicateQuote();
    const duplicateQuoteEvent = useCallback((id: number) => {
        setConfirmModalState({
            open: true,
            title: "Duplication devis",
            desc: `Confirmez-vous la duplication du devis #${id}`,
            validEvent: () => {
                duplicateQuoteAjax(id).then((res) => {
                    if (res.status === ReqStatus.SUCCESS) {
                        getDatas();
                    }
                })
            }
        })
    }, [duplicateQuoteAjax, setConfirmModalState]);

    const setModalState = useSetRecoilState(Mail2ModalState);
    const customerEmail = useRecoilValue(FolderCustomerEmailSelector);
    const sendQuoteMail = useCallback((row: any) => {
        const templateId = (Array.isArray(row.travels) && row.travels.length > 0) ?
            BrevoTemplateEnum.BREVO_TPL_21_ENVOI_DEVIS :
            BrevoTemplateEnum.BREVO_TPL_XX_ENVOI_DEVIS_LIGNE;

        const _emails = customerEmail ? [customerEmail] : [];
        setModalState({
            open: true,
            folderId: +folderId,
            templateIdDefault: templateId,
            entityId: row.id,
            emails: _emails
        });
    }, [setModalState, folderId, customerEmail]);

    const columns: GridColDef[] = useMemo(() => {
        return [
            {
                field: 'id',
                headerName: "Devis N°",
                editable: false,
                filterable: false,
                width: 80,
                valueGetter: ({ row }) => `#${row.id}`
            },
            {
                field: 'order_attribute.quote_date',
                headerName: "Date",
                editable: false,
                filterable: false,
                width: 80,
                valueGetter: ({ row }) => dateFormat(row.order_attribute.quote_date)
            },
            {
                field: 'reference',
                headerName: "Titre",
                editable: false,
                filterable: false,
                width: 180
            },
            /*{
                field: 'total_minus_discount_tax_excl',
                headerName: "Total HT",
                editable: false,
                filterable: false,
                minWidth: 100
            },*/
            {
                field: 'total_minus_discount_tax_incl',
                headerName: "Total TTC",
                editable: false,
                filterable: false,
                width: 100,
                renderCell: ({ row }) => <Box display={"flex"} justifyContent={"flex-end"} width={"100%"}><FormattedAmount amount={row.total_minus_discount_tax_incl} symbol="€" /></Box>
            },
            {
                field: 'order_status_id',
                headerName: "Statut",
                editable: false,
                filterable: false,
                width: 100,
                renderCell: ({ row }) => <Box display="flex" alignContent="center">
                    <Chip label={row.order_status.label} color={
                        row.order_status.unique_key === "quote_inprogress" ? "primary" :
                            row.order_status.unique_key === "quote_validate" ? "success" :
                                "error"
                    }
                        variant="filled" />
                </Box>
            },
            {
                field: 'reste_a_regler',
                headerName: "Reste à régler",
                editable: false,
                filterable: false,
                width: 120,
                renderCell: ({ row }) => (
                    <Box display={"flex"} justifyContent={"flex-end"} width={"100%"}>
                        {row.order_status.unique_key === "quote_validate" && <ColumnRemainingAmount remainingAmount={row.dahan_left_to_pay} />}
                    </Box>
                )
            },
            {
                field: 'travels.order_product_attribute.departure_date',
                headerName: "Départ le",
                editable: false,
                filterable: false,
                width: 120,
                valueGetter: ({ row }) => {
                    var departure_date = "";
                    if (Array.isArray(row.travels) && row.travels.length > 0 && row.travels[0].order_product_attribute && row.travels[0].order_product_attribute.departure_date) {
                        departure_date = datetimeFormat(row.travels[0].order_product_attribute.departure_date);
                    }
                    return departure_date;
                }
            },
            {
                field: 'order_attribute.quote_validated_date',
                headerName: "Signé le",
                editable: false,
                filterable: false,
                width: 120,
                valueGetter: ({ row }) => datetimeFormat(row.order_attribute.quote_validated_date)
            },
            CreateColumnAvatar({
                field: 'user_id',
                headerName: "Commercial",
                getId: (row) => row.order_attribute.user_id,
                getValue: (row) => `${row.order_attribute.user.first_name} ${row.order_attribute.user.last_name}`
            }),
            {
                field: 'actions',
                headerName: "Actions",
                editable: false,
                filterable: false,
                minWidth: 250,
                renderCell: ({ row }) => <ListItemActions
                    defaultRoute={`folders/${row.order_attribute.folder_id}/quotes`}
                    edit={{
                        enable: true,
                        icon: true
                    }}
                    others={(row) => (
                        <>
                            <Box ml={1}><Tooltip title="Pdf devis"><IconButton onClick={() => openPdf(row.id)}><VisibilityIcon /></IconButton></Tooltip></Box>
                            <Box ml={1}><Tooltip title="Duplication devis"><IconButton onClick={() => duplicateQuoteEvent(row.id)}><FileCopyIcon /></IconButton></Tooltip></Box>
                            <Box ml={1}><Tooltip title="Mail validation devis"><IconButton onClick={() => sendQuoteMail(row)}><MailIcon /></IconButton></Tooltip></Box>
                            <Box ml={1}>
                                <AddUserOnEntityButton
                                    type={addUserOnEntityType.COMMERCIAL_QUOTE}
                                    ids={[row.id]}
                                    onValidate={() => getDatas()}
                                />
                            </Box>
                        </>
                    )}
                    row={row}
                    id={row.id}
                />
            }
        ]
    }, [sendQuoteMail, openPdf, duplicateQuoteEvent]);


    useEffect(() => {
        getDatas();
    }, []);

    return (
        <Box height={371}>
            <AddUserOnEntityModal />
            <DataGrid
                key={forceRefresh}
                disableColumnFilter
                disableRowSelectionOnClick
                //autoPageSize
                filterMode="client"
                rows={rows}
                columns={columns}
                getRowId={(row: any) => row.id}
                hideFooter
            />
        </Box >
    )
};
export default FolderQuoteList;