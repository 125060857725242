import React, {ReactNode, useEffect, useState} from 'react';
import {
    Grid,
    Divider,
    Card,
    CardContent,
    CardHeader,
    Box,
    Chip,
    Button,
} from '@mui/material';
import {datetimeFormat} from '../../../utils/DateUtils';


interface ArrayItem {
    arrayItem?: any[],
    cardItemBody?: any[],
    cardItemHeader?: any[],
    cardItem?: string[],
    clickCardCondition?: ((item: any) => boolean)[];
    clickCardAction?: (item: any) => void;
    headerActionCard?: (item: any) => ReactNode;
    count?: number;
    getDatasWithoutRefresh?: (paginationLimit: number, page: number, csv?: boolean, pdf?: boolean, xls?: boolean, reinit? : boolean) => void,
}

const missingLabel = "NC";

// Fonction pour obtenir la valeur par clé
const getValueByKey = (obj: any, key: string, type?: string): any => {
    const keys = key.split(' ');
    let value = obj;
    for (let i = 0; i < keys.length; i++) {
        if (value && value.hasOwnProperty(keys[i])) {
            if (type === "dateTime") {
                value = datetimeFormat(value[keys[i]]);
            } else {
                value = value[keys[i]];
            }
        } else {
            value = null;
            break;
        }
    }
    return (value !== null && value !== "") ? value : missingLabel;
};

// Fonction pour formater l'étiquette avec ou sans label
const formatLabel = (itemCard: any, value: any) => {
    if (itemCard.icon) {
        return (
            <span>
                <strong>{value} {itemCard.addValue && ` ${itemCard.addValue}`}</strong>
            </span>
        );
    } else {
        return (
            <span>
                {itemCard.label} : <strong>{value} {itemCard.addValue && ` ${itemCard.addValue}`}</strong>
            </span>
        );
    }
};

const ListCard: React.FC<ArrayItem> = (props) => {
    const { arrayItem, cardItem, cardItemHeader,
        cardItemBody, clickCardCondition,
        clickCardAction, headerActionCard, count, getDatasWithoutRefresh } = props;
    const [saveArrayItem, setSaveArrayItem] = useState(arrayItem);
    const [saveArrayItemId, setSaveArrayItemId] = useState<number[]>([]);
    const [page, setPage] = useState(0);

    const handleClickCard = (item: any) => {
        if (clickCardCondition && Array.isArray(clickCardCondition)) {
            for (let i = 0; i < clickCardCondition.length; i++) {
                if (!clickCardCondition[i](item)) {
                    return false;
                }
            }
        }
        if (clickCardAction) {
            clickCardAction(item);
        }
    };

    const loadMoreData = () => {
        if (getDatasWithoutRefresh && count && ((page+1) * 10  < count) ) {
            getDatasWithoutRefresh(10, page + 1, false, false, false, false);
            setPage(page + 1);

        }
    }

    useEffect(() => {
        if (arrayItem && arrayItem.length > 0 && saveArrayItem) {
            const newItems = arrayItem.filter(item => !saveArrayItemId.includes(item.id));
            if (page !==0) {
                setSaveArrayItem(prevArray => [...(prevArray || []), ...newItems]);
            }
            setSaveArrayItemId(prevArray => [...(prevArray || []), ...newItems.map(item => item.id)]);
        }
    }, [arrayItem]);



    return (
        <Grid container spacing={2} sx={{ padding: 1, mb:10 }}>
            {cardItemBody && Array.isArray(cardItem) && saveArrayItem && Array.isArray(saveArrayItem) && saveArrayItem.map((item, index) => (
                <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                    <Card raised onClick={() => handleClickCard(item)}>
                        {cardItemHeader && Array.isArray(cardItemHeader) && cardItemHeader.length === 2 && (
                            <>
                                <CardHeader
                                    action={
                                        headerActionCard && headerActionCard(item)
                                    }
                                    title={getValueByKey(item, cardItemHeader[0].key) !== missingLabel ? getValueByKey(item, cardItemHeader[0].key, cardItemHeader[0].type) : `${cardItemHeader[0].label} ${missingLabel}`}
                                    subheader={getValueByKey(item, cardItemHeader[1].key, cardItemHeader[1].type)}
                                />
                                <Divider />
                            </>
                        )}
                        <CardContent>
                            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                                {cardItemBody.map((itemCard, idx) => {
                                    if (itemCard.display) {
                                        const value = getValueByKey(item, itemCard.key);
                                        const formattedLabel = formatLabel(itemCard, value);

                                        return (
                                            <Box sx={{ marginRight: 1, marginBottom: 1 }} key={idx}>
                                                <Chip
                                                    label={formattedLabel}
                                                    color={itemCard.color ? itemCard.color : "primary"}
                                                    variant="outlined"
                                                    size={itemCard.size ? itemCard.size : "medium"}
                                                    icon={itemCard.icon ? itemCard.icon : null}
                                                />
                                            </Box>
                                        );
                                    }
                                    return null;
                                })}
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
            {getDatasWithoutRefresh && count && saveArrayItem && saveArrayItem.length < count && (
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button variant="contained" onClick={loadMoreData}>Voir plus</Button>
                </Grid>
            )}
        </Grid>
    );
};

export default ListCard;
