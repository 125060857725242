import { useMemo } from "react";

interface FormattedAmountProps {
    amount: number | string | undefined | null,
    symbol?: string,
    thousandsSeparator?: string,
    decimalSeparator?: string,
    decimalNumber?: number
}

const FormattedAmount = ({amount, symbol="", thousandsSeparator=" ", decimalSeparator=".", decimalNumber=2}: FormattedAmountProps) => {

    const formattedString = useMemo(() => {
        if (amount === null || amount === undefined) {
            return "";
        };
        let numberToFormate: string = (+amount).toFixed(decimalNumber);
        let parties: string[] = numberToFormate.split('.');
   
        parties[0] = parties[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);

        //let formattedString: string = ((+parties[1]) === 0) ? parties[0] : parties.join(decimalSeparator); // Ne pas afficher lorsque decimal = 00
        let formattedString: string = parties.join(decimalSeparator);

        return formattedString;
    }, [amount, thousandsSeparator, decimalSeparator, decimalNumber]);

    return <>{formattedString}{symbol && ` ${symbol}`}</>;
};

export default FormattedAmount;