import { useNavigate, useParams } from "react-router-dom";
import { useAddEditCore } from "../../../stores/core/StoreCore";
import { ReqStatus } from "../../../stores/core/UseApi";
import { useAddEditForm } from "../../../components/common/form/AddEditFormPanel";
import { useEffect } from "react";
import AddEditForm from "../../../components/common/form/AddEditForm";
import AddEditFormCard from "../../../components/common/form/AddEditFormCard";
import { Grid } from "@mui/material";
import InputField from "../../../components/common/form/InputField";
const CircuitTypeView = () => {
    const listRoute = "/project-dahan/dahan/circuit-types";
    const listRouteCustom = '/circuit-types';
    const { getData, addEditData } = useAddEditCore<any, { circuitType: any }>(`${listRoute}/add-edit`);

    let { id } = useParams();
    const navigate = useNavigate();

    const onSubmit = (request: any) => {
        addEditData(request, id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                navigate(listRouteCustom);
            }
        })
    }

    const { handleSubmit, resetInitialValues, isDirty } = useAddEditForm({ onSubmit, initialValues: { name: "" } });
    useEffect(() => {
        getData(id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                if (id) {
                    const _data = res.data.circuitType;
                    resetInitialValues({
                        name: _data.name,
                    });
                }
            } else {
                navigate(listRouteCustom);
            }
        })
    }, [id]);

    return (

        <AddEditForm
            formId="circuitTypeForm"
            formHandleCancel={resetInitialValues}
            formHandleSubmit={handleSubmit}
            isDirty={isDirty}
        >
            <AddEditFormCard maxWidth="md" title={(!id ? "Ajouter " : "Modifier") + " un type de circuit"}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <InputField name="name" label="Label" type="text" required />
                    </Grid>
                </Grid>
            </AddEditFormCard>
        </AddEditForm>
    )
}

export default CircuitTypeView;