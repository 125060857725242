import { useParams } from "react-router-dom";
import { useCustomerView } from "../../../components/customer/CustomerView";
import AddEditForm from "../../../components/common/form/AddEditForm";
import CustomerFoldersView from "./CustomerFoldersView";
import CustomerCustomInfosView from "./CustomerCustomInfosView";
import CustomerCustomAddressesView from "./CustomerCustomAddressesView";

const CustomerCustomView = () => {
    let { id } = useParams();
    const {
        resetInitialValues,
        handleSubmit,
        isDirty,
        refresh,
        deleteAddress,
        genderOptions,
        customerTypesOptions,
        addresses,
        countryOptions
    } = useCustomerView(["comment", "dahan_emails_compl", "dahan_phones_compl", "dahan_company", "dahan_auxiliaire"]);

    return (
        <AddEditForm formId="customerForm" formHandleCancel={resetInitialValues} formHandleSubmit={handleSubmit} isDirty={isDirty}>
            <CustomerCustomInfosView
                id={id}
                genderOptions={genderOptions}
                customerTypesOptions={customerTypesOptions}
                countryOptions={countryOptions}
            />
            {id &&
                <CustomerCustomAddressesView
                    customerId={id}
                    addresses={addresses}
                    disabled={isDirty}
                    onValidate={() => refresh(id)}
                    onDelete={(addressId: number) => { deleteAddress(addressId) }}
                />
            }
            {id &&
                <CustomerFoldersView title="Dossiers" withExpand={false} customerId={+id} buttonAdd />
            }
        </AddEditForm>
    );
}

export default CustomerCustomView;