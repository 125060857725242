export const phonesCodes = [
	{ code: "ZW", pref: "263" },
	{ code: "ZM", pref: "260" },
	{ code: "YE", pref: "967" },
	{ code: "EH", pref: "212" },
	{ code: "WF", pref: "681" },
	{ code: "VI", pref: "1-340" },
	{ code: "VN", pref: "84" },
	{ code: "VE", pref: "58" },
	{ code: "VA", pref: "379" },
	{ code: "VU", pref: "678" },
	{ code: "UZ", pref: "998" },
	{ code: "US", pref: "1" },
	{ code: "UY", pref: "598" },
	{ code: "UA", pref: "380" },
	{ code: "GB", pref: "44" },
	{ code: "UG", pref: "256" },
	{ code: "AE", pref: "971" },
	{ code: "TV", pref: "688" },
	{ code: "TC", pref: "1-649" },
	{ code: "TM", pref: "993" },
	{ code: "TR", pref: "90" },
	{ code: "TN", pref: "216" },
	{ code: "TT", pref: "1-868" },
	{ code: "TO", pref: "676" },
	{ code: "TK", pref: "690" },
	{ code: "TG", pref: "228" },
	{ code: "TH", pref: "66" },
	{ code: "TZ", pref: "255" },
	{ code: "TJ", pref: "992" },
	{ code: "TW", pref: "886" },
	{ code: "SY", pref: "963" },
	{ code: "CH", pref: "41" },
	{ code: "SE", pref: "46" },
	{ code: "SZ", pref: "268" },
	{ code: "SJ", pref: "47" },
	{ code: "SR", pref: "597" },
	{ code: "SD", pref: "249" },
	{ code: "VC", pref: "1-784" },
	{ code: "PM", pref: "508" },
	{ code: "MF", pref: "590" },
	{ code: "LC", pref: "1-758" },
	{ code: "KN", pref: "1-869" },
	{ code: "SH", pref: "290" },
	{ code: "LK", pref: "94" },
	{ code: "ES", pref: "34" },
	{ code: "SS", pref: "211" },
	{ code: "KR", pref: "82" },
	{ code: "ZA", pref: "27" },
	{ code: "SO", pref: "252" },
	{ code: "SB", pref: "677" },
	{ code: "SI", pref: "386" },
	{ code: "SK", pref: "421" },
	{ code: "SX", pref: "1-721" },
	{ code: "SG", pref: "65" },
	{ code: "SL", pref: "232" },
	{ code: "SC", pref: "248" },
	{ code: "RS", pref: "381" },
	{ code: "SN", pref: "221" },
	{ code: "SA", pref: "966" },
	{ code: "ST", pref: "239" },
	{ code: "SM", pref: "378" },
	{ code: "WS", pref: "685" },
	{ code: "BL", pref: "590" },
	{ code: "RW", pref: "250" },
	{ code: "RU", pref: "7" },
	{ code: "RO", pref: "40" },
	{ code: "RE", pref: "262" },
	{ code: "QA", pref: "974" },
	{ code: "PR", pref: "1-787" },
	{ code: "PR", pref: "1-939" },
	{ code: "PT", pref: "351" },
	{ code: "PL", pref: "48" },
	{ code: "PN", pref: "64" },
	{ code: "PH", pref: "63" },
	{ code: "PE", pref: "51" },
	{ code: "PY", pref: "595" },
	{ code: "PG", pref: "675" },
	{ code: "PA", pref: "507" },
	{ code: "PS", pref: "970" },
	{ code: "PW", pref: "680" },
	{ code: "PK", pref: "92" },
	{ code: "OM", pref: "968" },
	{ code: "NO", pref: "47" },
	{ code: "KP", pref: "850" },
	{ code: "MP", pref: "1-670" },
	{ code: "NU", pref: "683" },
	{ code: "NG", pref: "234" },
	{ code: "NE", pref: "227" },
	{ code: "NI", pref: "505" },
	{ code: "NZ", pref: "64" },
	{ code: "NC", pref: "687" },
	{ code: "AN", pref: "599" },
	{ code: "NL", pref: "31" },
	{ code: "NP", pref: "977" },
	{ code: "NR", pref: "674" },
	{ code: "NA", pref: "264" },
	{ code: "MZ", pref: "258" },
	{ code: "MA", pref: "212" },
	{ code: "MS", pref: "1-664" },
	{ code: "ME", pref: "382" },
	{ code: "MN", pref: "976" },
	{ code: "MC", pref: "377" },
	{ code: "MD", pref: "373" },
	{ code: "FM", pref: "691" },
	{ code: "MX", pref: "52" },
	{ code: "YT", pref: "262" },
	{ code: "MU", pref: "230" },
	{ code: "MR", pref: "222" },
	{ code: "MH", pref: "692" },
	{ code: "MT", pref: "356" },
	{ code: "ML", pref: "223" },
	{ code: "MV", pref: "960" },
	{ code: "MY", pref: "60" },
	{ code: "MW", pref: "265" },
	{ code: "MG", pref: "261" },
	{ code: "MK", pref: "389" },
	{ code: "MO", pref: "853" },
	{ code: "LU", pref: "352" },
	{ code: "LT", pref: "370" },
	{ code: "LI", pref: "423" },
	{ code: "LY", pref: "218" },
	{ code: "LR", pref: "231" },
	{ code: "LS", pref: "266" },
	{ code: "LB", pref: "961" },
	{ code: "LV", pref: "371" },
	{ code: "LA", pref: "856" },
	{ code: "KG", pref: "996" },
	{ code: "KW", pref: "965" },
	{ code: "XK", pref: "383" },
	{ code: "KI", pref: "686" },
	{ code: "KE", pref: "254" },
	{ code: "KZ", pref: "7" },
	{ code: "JO", pref: "962" },
	{ code: "JE", pref: "44-1534" },
	{ code: "JP", pref: "81" },
	{ code: "JM", pref: "1-876" },
	{ code: "CI", pref: "225" },
	{ code: "IT", pref: "39" },
	{ code: "IL", pref: "972" },
	{ code: "IM", pref: "44-1624" },
	{ code: "IE", pref: "353" },
	{ code: "IQ", pref: "964" },
	{ code: "IR", pref: "98" },
	{ code: "ID", pref: "62" },
	{ code: "IN", pref: "91" },
	{ code: "IS", pref: "354" },
	{ code: "HU", pref: "36" },
	{ code: "HK", pref: "852" },
	{ code: "HN", pref: "504" },
	{ code: "HT", pref: "509" },
	{ code: "GY", pref: "592" },
	{ code: "GW", pref: "245" },
	{ code: "GN", pref: "224" },
	{ code: "GG", pref: "44-1481" },
	{ code: "GT", pref: "502" },
	{ code: "GU", pref: "1-671" },
	{ code: "GD", pref: "1-473" },
	{ code: "GL", pref: "299" },
	{ code: "GR", pref: "30" },
	{ code: "GI", pref: "350" },
	{ code: "GH", pref: "233" },
	{ code: "DE", pref: "49" },
	{ code: "GE", pref: "995" },
	{ code: "GM", pref: "220" },
	{ code: "GA", pref: "241" },
	{ code: "PF", pref: "689" },
	{ code: "FR", pref: "33" },
	{ code: "FI", pref: "358" },
	{ code: "FJ", pref: "679" },
	{ code: "FO", pref: "298" },
	{ code: "FK", pref: "500" },
	{ code: "ET", pref: "251" },
	{ code: "EE", pref: "372" },
	{ code: "ER", pref: "291" },
	{ code: "GQ", pref: "240" },
	{ code: "SV", pref: "503" },
	{ code: "EG", pref: "20" },
	{ code: "EC", pref: "593" },
	{ code: "TL", pref: "670" },
	{ code: "DO", pref: "1-809" },
	{ code: "DO", pref: "1-829" },
	{ code: "DO", pref: "1-849" },
	{ code: "DM", pref: "1-767" },
	{ code: "DJ", pref: "253" },
	{ code: "DK", pref: "45" },
	{ code: "CZ", pref: "420" },
	{ code: "CY", pref: "357" },
	{ code: "CW", pref: "599" },
	{ code: "CU", pref: "53" },
	{ code: "HR", pref: "385" },
	{ code: "CR", pref: "506" },
	{ code: "CK", pref: "682" },
	{ code: "CD", pref: "243" },
	{ code: "CG", pref: "242" },
	{ code: "KM", pref: "269" },
	{ code: "CO", pref: "57" },
	{ code: "CL", pref: "56" },
	{ code: "TD", pref: "235" },
	{ code: "CF", pref: "236" },
	{ code: "CA", pref: "1" },
	{ code: "CM", pref: "237" },
	{ code: "KH", pref: "855" },
	{ code: "BI", pref: "257" },
	{ code: "MM", pref: "95" },
	{ code: "BF", pref: "226" },
	{ code: "BG", pref: "359" },
	{ code: "BN", pref: "673" },
	{ code: "VG", pref: "1-284" },
	{ code: "IO", pref: "246" },
	{ code: "BR", pref: "55" },
	{ code: "BW", pref: "267" },
	{ code: "BA", pref: "387" },
	{ code: "BO", pref: "591" },
	{ code: "BT", pref: "975" },
	{ code: "BM", pref: "1-441" },
	{ code: "BJ", pref: "229" },
	{ code: "BZ", pref: "501" },
	{ code: "BE", pref: "32" },
	{ code: "BY", pref: "375" },
	{ code: "BB", pref: "1-246" },
	{ code: "BD", pref: "880" },
	{ code: "BH", pref: "973" },
	{ code: "AZ", pref: "994" },
	{ code: "AT", pref: "43" },
	{ code: "AU", pref: "61" },
	{ code: "AW", pref: "297" },
	{ code: "AM", pref: "374" },
	{ code: "AR", pref: "54" },
	{ code: "AG", pref: "1-268" },
	{ code: "AQ", pref: "672" },
	{ code: "AI", pref: "1-264" },
	{ code: "AO", pref: "244" },
	{ code: "AD", pref: "376" },
	{ code: "AS", pref: "1-684" },
	{ code: "DZ", pref: "213" },
	{ code: "AL", pref: "355" },
	{ code: "AF", pref: "93" }
];