import { atom } from 'recoil';

export const MenuIndexState = atom<{ index: string, subIndex: string, title: string}>({
    key: 'MenuIndexKey',
    default: { index: "", subIndex: "", title: ""}
});

export const MenuVisibleState = atom<boolean>({
    key: 'MenuVisibleKey',
    default: true
});

export const DefaultRouteState = atom<string>({
    key: 'DefaultRouteKey',
    default: '/'
});

export const NavigationBlockedState = atom<boolean>({
    key: 'NavigationBlockedKey',
    default: false
});

export const LogoPathState = atom<string>({
    key: 'LogoPathKey',
    default: ''
})

export const CompanyNameState = atom<string>({
    key: 'CompanyNameKey',
    default: ''
})

export const NavigateState = atom<string | number>({
    key: "NavigateKey",
    default: ""
})

export const WebHeightState = atom<number>({
    key: 'WebHeightKey',
    default: 64
});

export const MobileHeightState = atom<number>({
    key: 'MobileHeightKey',
    default: 120
});

export const LayoutHeightState = atom<boolean>({
    key: 'LayoutHeightState',
    default: true
})