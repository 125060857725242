import { useSetRecoilState } from "recoil";
import { FolderChecktasksModalState } from "../../states/FolderChecktasksState";
import { useCallback } from "react";
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import { Button, IconButton, Tooltip } from "@mui/material";

interface FolderChecktaskButtonProps {
	id: string,
	disabled?: boolean,
	small?:boolean
}

const FolderChecktaskButton = (props: FolderChecktaskButtonProps) => {

	const { id, disabled, small } = props;
	const setModalState = useSetRecoilState(FolderChecktasksModalState);
	const onClickEvent = useCallback(() => {
		setModalState({
			folder_id: id,
			open: true,
		})
	}, [setModalState, id]);

	return (
		<Tooltip title={"Checklist"}>
			<IconButton
				size={small ? "small" : undefined}
				disabled={disabled}
				onClick={() => onClickEvent()}>
				<LibraryAddCheckIcon fontSize={small ? "small" : undefined} />
			</IconButton>
		</Tooltip>
	)
}

export default FolderChecktaskButton;