import { useCallback, useState } from "react";
import { ListExportButtonsModelCore, ListModelCore, ListSearchModelCore } from "../../classes/list/ListModel";
import { GridColDef } from "@mui/x-data-grid";
import { ReqStatus } from "../../stores/core/UseApi";
import { Box, Button } from "@mui/material";
import ListServer from "../common/list/ListServer";
import { useGetSupplierOrderList } from "../../stores/SupplierOrdersStore";
import { useGetListCore } from "../../stores/core/StoreCore";
import { useGetBillList } from "../../stores/BillsStore";
import ListCore from "../common/list/ListCore";
import { useNavigate } from "react-router-dom";
import { datetimeFormat } from "../../utils/DateUtils";
import { useIntl } from "react-intl";




interface BillListModel extends ListModelCore {
    bills: any[]
}

export const BillListActions = (props: { row: any, route: string }) => {

    const { row, route } = props;

    const navigate = useNavigate();
    const openOtherWindow = (url: string) => {
        window.open(`${process.env.REACT_APP_API}${url}`, '_blank');
    }

    return (
        <Box display="flex">
            <Box>
                <Button variant="contained" color="info" onClick={() => openOtherWindow(`${route}/view-pdf/${row.id}.pdf`)}>Voir</Button>
            </Box>
            <Box ml={2}>
                <Button variant="contained" color="success" onClick={() => openOtherWindow(`${route}/download-pdf/${row.id}.pdf`)}>Télécharger</Button>
            </Box>
            <Box ml={2}>
                <Button variant="contained" color="info" onClick={() => navigate(`/commercial/customers/add-edit/${row.customer_id}`)}>Fiche client</Button>
            </Box>
        </Box>
    );
}

const BillList = () => {
    const intl = useIntl();
    
    const columns = useCallback(() => {
        return [
            {
                field: 'id',
                headerName: "N°",
                editable: false,
                filterable: false,
                minWidth: 150,
                valueGetter: ({row}) => `${(row.is_credit_note ? "Avoir" : "Facture")} n°${row.id}`
            },
            {
                field: 'reference',
                headerName: "Référence",
                editable: false,
                filterable: false,
                minWidth: 250,
            },
            {
                field: 'customer',
                headerName: "Client",
                editable: false,
                filterable: false,
                width: 300,
                renderCell: ({ row }) =>
                    <Box>
                        <Box>Livré : {`${row.shipping_first_name} ${row.shipping_last_name}`}</Box>
                        <Box>Facturé : {`${row.billing_first_name} ${row.billing_last_name}`}</Box>
                    </Box>
            },
            {
                field: 'total_minus_discount_tax_excl_euro',
                headerName: "Prix HT",
                editable: false,
                filterable: false,
                minWidth: 60,
                valueGetter: ({ row }) => `${row.total_minus_discount_tax_excl} €`
            },
            {
                field: 'total_minus_discount_tax_incl_euro',
                headerName: "Prix TTC",
                editable: false,
                filterable: false,
                minWidth: 60,
                valueGetter: ({ row }) => `${row.total_minus_discount_tax_incl} €`
            },
            {
                field: 'created',
                headerName: intl.formatMessage({id: "Label_Order_Date"}),
                editable: false,
                filterable: false,
                minWidth: 150,
                valueGetter: ({row}) => datetimeFormat(row.created)
            },
        ] as GridColDef[];
    },[intl]);
    
 

    const routeController = "commercial/bills";
    const [get] = useGetListCore<BillListModel>(`/${routeController}/index`);

    return (
        <ListCore<BillListModel, any>
            getDatas={get}
            defineDatasToSet={(data) => data.bills}
            getRowId={(row) => row.id}
            columns={columns()}
            actions={{
                defaultRoute: routeController,
                others: (row) => <BillListActions route={routeController} row={row} />
            }}
        />
    );
}

export default BillList;