import React,{ Box, Step, StepLabel, Stepper } from "@mui/material";
import { useMemo } from "react";
import { FolderModel } from "../../classes/FolderModel";


interface FolderStatus { id: number, label: string, color: string }

export const folderFinalStatus = 11;

export const folderStatusList: FolderStatus[] = [
    {
        id: 1,
        label: "Blanc",
        color: "black"
    },
    {
        id: 2,
        label: "En attente de contact",
        color: "black"
    },
    {
        id: 3,
        label: "En cours",
        color: "black"
    },
    {
        id: 4,
        label: "En attente de paiement",
        color: "gold"
    },
    {
        id: 5,
        label: "En attente de résa",
        color: "orange"
    },
    {
        id: 6,
        label: "En attente informations voyage",
        color: "lightblue"
    },
    {
        id: 7,
        label: "Voir si solde reçu",
        color:  "red"
    },
    {
        id: 8,
        label: "En attente du contact chauffeur",
        color:  "pink"
    },
    {
        id: 9,
        label: "Envoi de la feuille de route",
        color: "lightgreen"
    },
    {
        id: 10,
        label: "En attente de facture fournisseur",
        color: "green"
    },
    {
        id: folderFinalStatus,
        label: "Terminer / Clôturer",
        color: "gray"
    },
]

export const getLabelOfStatus = (status: number) => {
    return folderStatusList.find((s) => s.id === status)?.label;
}

export const getColorOfStatus = (status : number) => {
    return folderStatusList.find((s) =>  s.id === status)?.color ?? 'black';
}

// export const getDetailsStatus = (status : number) => {
//     const details = folderStatusList.find((s) => s.id === status) 
//     return {
//         label: details?.label,
//         color: details?.color ?? 'black'
//     }  
// }

export const getLabelOfStatusByFolder = (folder: FolderModel) => {
    if(folder.status === folderFinalStatus){
        return getLabelOfFinalStatus(folder.is_closure);
    }
    return folderStatusList.find((s) => s.id === folder.status)?.label ?? '';
}

export const getLabelOfFinalStatus = ( is_closure: boolean) => {
    if (is_closure) {
        return "Clôturer"
    } else {
        return "Terminer"
    }
}

interface FolderStepperProps {
    stepId: number,
    onClickEvent: (stepId: number) => void,
    folder: FolderModel
}

const FolderStepper = (props: FolderStepperProps) => {

    const { stepId, onClickEvent, folder } = props;

    const stepActive = useMemo(() => {
        return (stepId === folderFinalStatus) ? stepId : stepId - 1;
    }, [stepId]);

    return (
        <Box mt={2}>
            <Stepper activeStep={stepActive} alternativeLabel>
                {folderStatusList.map((step) => (
                    <Step key={step.id} onClick={() => onClickEvent(step.id)} >
                        <StepLabel>
                            { step.id === folderFinalStatus && step.id === stepActive ?
                                getLabelOfFinalStatus(folder.is_closure)
                                :
                                step.label
                            }
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    )
}

export default FolderStepper;