import { useAddEditCore, useDelete } from "../../stores/core/StoreCore";
import { FolderOrderSupplierPaymentModel } from "../classes/FolderOrderSupplierPaymentModel";

const routeController = 'project-dahan/dahan/folder-order-supplier-payments';
export const useAddEditFolderOrderSupplierPayment = () => {
    return useAddEditCore<FolderOrderSupplierPaymentModel, {folderOrderSupplierPayment : FolderOrderSupplierPaymentModel}>(`${routeController}/add-edit`);
};

export const useDeleteFolderOrderSupplierPayment = () => {
    return useDelete(routeController);
}
