import { FormControl, MenuItem, FormHelperText, TextField, Checkbox, FormControlLabel } from "@mui/material";
import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { IAncestorInput, useField } from "react-recoil-form";


interface IFieldProps {
  name: string;
  ancestors?: IAncestorInput[];
  label?: string;
  disabled?: boolean;
  withoutLabel?: boolean;
  fullWidth? : boolean;
}

const CheckboxField = (props: IFieldProps) => {
  const { name, ancestors, label, disabled, withoutLabel, fullWidth } = props;
  const { fieldValue, setFieldValue, error } = useField<boolean>({
    ancestors,
    name
  });

  return (
    <FormControl error={!!error} size="medium" variant="outlined" fullWidth={fullWidth}>
      { withoutLabel ?
        <Checkbox
          checked={Boolean(fieldValue)}
          onChange={(e) => setFieldValue(e.target.checked)}
          inputProps={{ 'aria-label': 'controlled' }}
          disabled={disabled}
        />
        :
        <FormControlLabel control={
          <Checkbox
            checked={Boolean(fieldValue)}
            onChange={(e) => setFieldValue(e.target.checked)}
            inputProps={{ 'aria-label': 'controlled' }}
            disabled={disabled}
          />
        } label={label} />
      }
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default CheckboxField;
