import { Box, Button, Checkbox, Container, FormControl, InputLabel, MenuItem, Modal, OutlinedInput, Paper, Select } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { TagItem } from "../folders/FolderTagItem";
import { useRecoilState } from "recoil";
import { FolderChecktasksSelectModalState } from "../../states/FolderChecktasksState";
import { FormattedMessage } from "react-intl";
import AddEditFormPanel from "../../../components/common/form/AddEditFormPanel";

const FolderChecktasksSelectModal = () => {

	const [modalState, setModalState] = useRecoilState(FolderChecktasksSelectModalState);

	const [selected, setSelected] = useState<number[]>([]);

	const handleClose = useCallback(() => {
		setModalState((a) => {
			return { ...a, open: false };
		});
	}, [setModalState]);

	const handleValidate = useCallback(() => {
		modalState.onValidate(selected);
		setSelected([]);

		setModalState((a) => {
			return { ...a, open: false };
		});
	}, [modalState, setModalState, selected]);

	const availableChecktasks = useMemo(() => {
		return modalState.checktasks.filter((ct) => !modalState.checktask_used_ids.includes(ct.id)).sort((a, b) => a.sort_order - b.sort_order);
	}, [modalState.checktasks, modalState.checktask_used_ids]);

	return (
		<Modal
			open={modalState.open}
			aria-labelledby="folder-reminder-modal"
			sx={{
				overflow: 'auto'
			}}
		>
			<Container component="main" maxWidth="md" sx={{ height: "100%", py: 2 }}>
				<AddEditFormPanel
					title={"Sélectionner des tâches pour la check-list"}
				>
					<Box my={2} width={"70%"}>
						<FormControl fullWidth>
							<InputLabel id="checktask-select-label">Tâches</InputLabel>
							<Select
								fullWidth
								labelId="checktask-select-label"
								id="checktask-select"
								multiple
								value={selected}
								onChange={(e) => { setSelected((e.target.value as any)); }}
								input={<OutlinedInput label="Checklist" />}
								renderValue={(selected) => {
									if (!Array.isArray(selected) || selected.length == 0) { return <></>; }
									const checktasksSelected = availableChecktasks.filter(x => selected.includes(x.id));
									return (
										<Box display="flex" flexWrap={"wrap"}>										{
											checktasksSelected.map((ct: any) => (
												<Box ml={1}>
													<TagItem key={ct.id} tag={ct} />
												</Box>
											))
										}
										</Box>
									)
								}}
							>
								{availableChecktasks.map((ct: any) => (
									<MenuItem key={ct.id} value={ct.id}>
										<Box display="flex" alignItems={"center"}>
											<Checkbox checked={Array.isArray(selected) && selected.includes(ct.id)} />
											<Box mt={1}>
												<TagItem tag={ct} />
											</Box>
										</Box>
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<Box mt={1} display="flex">
							<Button variant="outlined" onClick={() => setSelected([]) }>Tout déselectionner</Button>
							<Box ml={1}>
								<Button color="success" variant="contained" onClick={() => setSelected(availableChecktasks?.map((x) => x.id) ?? []) }>Tout sélectionner</Button>
							</Box>
						</Box>
					</Box>
					<Box display="flex" justifyContent={"flex-end"}>
						<Button color="info" variant="outlined" onClick={() => handleClose()}>
							<FormattedMessage id={"Common_Cancel"} />
						</Button>
						<Button color="info" variant="contained" style={{ marginLeft: "10px" }} onClick={() => handleValidate()}>
							<FormattedMessage id={"Common_Validate"} />
						</Button>
					</Box>
				</AddEditFormPanel>
			</Container>
		</Modal>
	)
}

export default FolderChecktasksSelectModal;