
import { useMemo, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { Box, Divider, IconButton, Menu, MenuItem, Typography } from "@mui/material";

import { AuthState, CurrentUserState } from '../states/AuthState';
import AvatarIcon from "../components/common/icon/AvatarIcon";
import { FormattedMessage } from "react-intl";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { useNavigate } from "react-router-dom";
import { useLogout } from "../stores/AuthStore";
import { ReqStatus } from "../stores/core/UseApi";


const HeaderAvatar = () => {
    const navigate = useNavigate();
    const [logout] = useLogout();
    const setIsAuth = useSetRecoilState(AuthState);
    const currentUser = useRecoilValue(CurrentUserState);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const logOff = () => {
        handleCloseUserMenu();
        
        logout().then((res) => {
            if(res.status === ReqStatus.SUCCESS && res.data){
                setIsAuth(false);
            }
        });
    };

    const redirectProfile = () => {
        handleCloseUserMenu();
        navigate("/users/profile",  { state: { title: "Profil"} });
    }

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleCloseUserMenu = () => {
        setAnchorEl(null);
    };
    

    const fullName = useMemo(() => {
        return currentUser ? `${currentUser.first_name} ${currentUser.last_name}` : "X X";
    },[currentUser]);
    
    return (
        <Box sx={{ flexGrow: 0 }}>
            <IconButton onClick={handleOpenUserMenu}>
                <AvatarIcon value={fullName} />
            </IconButton>
            <Menu
                id="menu-profil"
                sx={{ marginTop: "50px" }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseUserMenu}
            >
                <MenuItem>
                    <Typography >
                        {fullName}
                    </Typography>
                </MenuItem>
                <Divider />
                <MenuItem onClick={redirectProfile}>
                    <AccountCircleIcon />
                    <Typography textAlign="center" ml={1}>
                        <FormattedMessage id={"Profile_View_Title"} />
                    </Typography>
                </MenuItem>
                <Divider />
                <MenuItem onClick={logOff}>
                    <PowerSettingsNewIcon />
                    <Typography textAlign="center" ml={1}>
                        <FormattedMessage id={"Common_SignOut"} />
                    </Typography>
                </MenuItem>

            </Menu>
        </Box>
    );
}

export default HeaderAvatar;