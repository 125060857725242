import { useNavigate, useParams } from "react-router-dom";
import { useAddEditSupplier, useGetSupplier } from "../../stores/SuppliersStore";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ReqStatus } from "../../stores/core/UseApi";
import { Alert, Box, Divider, Grid, TextField } from "@mui/material";
import InputField from "../common/form/InputField";
import TextAreaField from "../common/form/TextAreaField";
import AddEditFormPanel, { useAddEditForm } from "../common/form/AddEditFormPanel";
import AddEditFormSubmit from "../common/form/AddEditFormSubmit";
import AutocompleteField, { AutocompleteOption } from "../common/form/AutocompleteField";
import { SupplierModel } from "../../classes/suppliers/SupplierModel";
import { useField, useFieldArray, useForm, useFormValues, useIsDirty } from "react-recoil-form";
import AddEditFormList from "../common/form/AddEditFormList";
import { useSetRecoilState } from "recoil";
import { NavigateState, NavigationBlockedState } from "../../states/MenuState";
import { useAddEditCore, useViewCore } from "../../stores/core/StoreCore";
import SelectField, { SelectOptionsProps } from "../common/form/SelectField";
import DateField from "../common/form/DateField";
import { useGetProductsOfSupplier } from "../../stores/SupplierOrdersStore";
import AddEditForm from "../common/form/AddEditForm";
import AddEditFormCard from "../common/form/AddEditFormCard";
import { mapArrayForForm } from "../../utils/DataUtils";
interface SupplierOrderResponse {
    supplierOrder: any,
    suppliers: any[],
    productsSuppliers: any[]
}

const SupplierOrderView = () => {

    let { id } = useParams();

    const setNavigateState = useSetRecoilState(NavigateState);
    const listRoute = "/commercial/supplier-orders";

    const { getData, addEditData } = useAddEditCore<any, SupplierOrderResponse>(`${listRoute}/add-edit`);
    const getProductsOfSupplier = useGetProductsOfSupplier()
        ;
    const [supplierOptions, setSupplierOptions] = useState<SelectOptionsProps[]>([]);
    const [products, setProducts] = useState<AutocompleteOption[]>([]);



    const onSubmit = useCallback((request: any) => {
        let _request = JSON.parse(JSON.stringify(request)) as any;
        if (!_request.products_suppliers) {
            delete _request.products_suppliers;
        }
        if (!_request.newProductsSuppliers) {
            delete _request.newProductsSuppliers;
        }
        addEditData(_request, id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                setNavigateState(listRoute);
            }
        });
    },[]);

    const { handleSubmit, resetInitialValues, isDirty } = useAddEditForm({
        onSubmit
    });

    useEffect(() => {
        getData(id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {

                const _order = res.data.supplierOrder;

                if (res.data.suppliers) {
                    const _supplierOptions = Object.entries(res.data.suppliers).map(([key, value]) => {
                        return { label: value, value: key ?? "" } as SelectOptionsProps;
                    });
                    setSupplierOptions(_supplierOptions ?? []);
                } else {
                    setSupplierOptions([]);
                }

                if (res.data.productsSuppliers) {
                    let _products: AutocompleteOption[] = []
                    Object.entries(res.data.productsSuppliers).forEach(([product_id, product]) => {
                        _products.push({ id: +product_id, label: product });
                    })
                    setProducts(_products);
                } else {
                    setProducts([]);
                }

                if (id) {
                    resetInitialValues({
                        supplier_id: _order.supplier_id,
                        reference: _order.reference,
                        comment: _order.comment,
                        order_date: _order.order_date,
                        delivery_date: _order.delivery_date ?? undefined,
                        received: _order.received ? 1 : 0,

                        ...mapArrayForForm(
                            _order.products_suppliers,
                            "products_suppliers",
                            (ps: any) => ({
                                id: ps.id,
                                _joinData: {
                                    quantity: ps._joinData.quantity
                                }
                            })
                        ),
                        
                        addElements: "1"
                    });
                } else {
                    resetInitialValues({
                        supplier_id: null,
                        reference: "",
                        comment: "",
                        order_date: undefined,
                        delivery_date: undefined,
                        received: 0,
                        addElements: "1"
                    })
                }
            } else {
                setNavigateState(listRoute);
            }
        });
    }, [id]);


    const supplierField = useField({ name: "supplier_id" });
    const newProductsSupplierFieldArray = useFieldArray({ name: "newProductsSuppliers", fieldNames: [] });
    const receivedField = useField({ name: "received" });

    const [supplierIdCurrent, setSupplierIdCurrent] = useState<string>();

    useEffect(() => {
        if (id || supplierIdCurrent === supplierField.fieldValue) { return; }

        if (!supplierField.fieldValue) {
            return;
        } else {
            getProductsOfSupplier(supplierField.fieldValue).then((res) => {
                newProductsSupplierFieldArray.removeAll();
                setProducts([]);
                setSupplierIdCurrent(supplierField.fieldValue);

                if (res.status === ReqStatus.SUCCESS && res.data) {
                    if (res.data.productsSuppliers) {
                        let _products: AutocompleteOption[] = []
                        Object.entries(res.data.productsSuppliers).forEach(([product_id, product]) => {
                            _products.push({ id: +product_id, label: product });
                        })
                        setProducts(_products);
                    }
                }
            })
        }
    }, [supplierIdCurrent, supplierField.fieldValue, id])


    return (
        <AddEditForm formId="supplierOrderForm" formHandleCancel={resetInitialValues} formHandleSubmit={handleSubmit} isDirty={isDirty}>

            <AddEditFormCard title={`${(!id ? "Ajouter " : "Modifier")} une commande fournisseur`}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>

                        <SelectField
                            name="supplier_id"
                            label="Fournisseur"
                            required
                            disabled={!!id}
                            options={supplierOptions} />
                    </Grid>
                    <Grid item xs={12} sm={6}>

                        <InputField
                            name="reference"
                            label="Référence"
                            required
                            type="text"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextAreaField
                            name="comment"
                            label="Commentaire"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DateField
                            name="order_date"
                            label="Date de commande"
                            required
                            disabled={!!id}
                            type="date"
                        />
                    </Grid>

                    {id &&
                        <>
                            <Grid item xs={12} sm={6}>
                                <SelectField
                                    name="received"
                                    label="Statut"
                                    required
                                    options={[{ value: 0, label: "En cours" }, { value: 1, label: "Reçue" }]} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <DateField
                                    name="delivery_date"
                                    label="Date de réception"
                                    type="date"
                                //disabled={receivedField.fieldValue === 1}
                                />
                            </Grid>
                        </>
                    }
                </Grid>
            </AddEditFormCard>

            <AddEditFormCard title="Produits associés">
                {!supplierField.fieldValue &&
                    <Alert color="warning" icon={false}>Il faut définir un fournisseur pouvoir ajouter des produits à la commande</Alert>
                }
                
                    <AddEditFormList
                        fields={[{
                            name: "id",
                            type: "autocomplete",
                            label: "Produit",
                            autocompleteOptions: products,
                            xs: 4,
                            required: true,
                        },
                        {
                            name: "_joinData.quantity",
                            type: "number",
                            label: "Quantité",
                            xs: 4,
                            required: true,
                        }]}

                        hiddenFields={{
                            add: ["_joinData.package_id"],
                            edit: []
                        }}

                        entity={"products_suppliers"}
                        title={"Produits associés"}
                        buttonAdd={ supplierField.fieldValue  ? { title: "Ajouter un produit" } : undefined}
                        defaultItem={{
                            _joinData: {
                                package_id: id ?? 0,
                                quantity: 1,
                                sort_order: 0
                            }
                        }}
                        disabled={receivedField.fieldValue === 1}
                    />
            </AddEditFormCard>
        </AddEditForm>
    );
}

export default SupplierOrderView;