import { Alert, Box, Button, Container, Modal, Paper, Skeleton, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import { MailHistoryModalState } from "../../states/HistoryMailState";
import { useHistoryMail } from "../../stores/MailsStore";
import { ReqStatus } from "../../../stores/core/UseApi";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import AddEditFormCard from "../../../components/common/form/AddEditFormCard";
import { datetimeFormat } from "../../../utils/DateUtils";
import MailHistoryEventTypeCell from "./MailHistoryEventTypeCell";

export interface MailHistoryEvent {
	id: number;
	email: string;
	event: string;
	subject: string,
	date: string;
}
const MailHistoryModal = () => {

	const [modalState, setModalState] = useRecoilState(MailHistoryModalState);
	const getHistory = useHistoryMail();

	const [events, setEvents] = useState<MailHistoryEvent[]>([]);
	const [subject, setSubject] = useState("");
	const [ready, setReady] = useState(false);

	const handleClose = useCallback(() => {
		setModalState((a) => {
			return { ...a, open: false };
		});
	}, [setModalState]);

	useEffect(() => {
		if (!modalState.messageId) { return; }

		setReady(false);

		getHistory.call({ messageId: modalState.messageId }).then((res) => {
			if (res.status === ReqStatus.SUCCESS && res.data) {
				const _events = res.data.events.map((event, index) => ({ ...event, id: index }));
				setEvents(_events);
				setSubject(res.data.subject ? res.data.subject : "Aucun évènement pour cet email");
			}
		}).finally(() => setReady(true));

	}, [modalState.messageId]);

	const columns: GridColDef[] = useMemo(() => {
		return [
			{
				field: 'historyMailModal.email',
				headerName: "Email",
				editable: false,
				filterable: false,
				flex: 1,
				renderCell: ({ row }) => (row.email)
			},
			{
				field: 'historyMailModal.event',
				headerName: "Event",
				editable: false,
				filterable: false,
				flex: 1,
				renderCell: ({ row }) => <MailHistoryEventTypeCell row={row} />
			},
			{
				field: 'historyMailModal.date',
				headerName: "Date",
				editable: false,
				filterable: false,
				flex: 1,
				renderCell: ({ row }) => datetimeFormat(row.date)
			}
		];
	}, [events]);

	return (
		<Modal
			open={modalState.open}
			aria-labelledby="mail-modal"
			sx={{
				overflow: 'auto'
			}}
			onClose={handleClose}
		>
			<Container component="main" maxWidth="md" sx={{ height: "100%", py: 2 }}>
				<AddEditFormCard title="Mail - Évènements" withExpand={false} action={
					<Button variant="contained" onClick={handleClose}>Fermer</Button>
				}>
					{!ready && <Skeleton variant="rectangular" height={300} />}

					{ready && <>
						<Box mb={1}>
							<Typography variant="body1">Sujet: {subject}</Typography>
						</Box>
						{events.length > 0 &&
							<Box maxHeight={480} overflow="auto">
								<DataGrid
									disableRowSelectionOnClick
									autoHeight
									filterMode="client"
									rows={events}
									columns={columns}
									getRowId={(row: any) => row.id}
									localeText={{
										noRowsLabel: "Aucun email associé à ce client"
									}}
								/>
							</Box >
						}
					</>}

				</AddEditFormCard>
			</Container>
		</Modal>
	);
}

export default MailHistoryModal;