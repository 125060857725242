import { Box, Button, Chip, Divider, Grid, IconButton, Paper, Tooltip, Typography } from "@mui/material"
import AvatarIcon from "../../../components/common/icon/AvatarIcon"
import { Email, EmailOutlined, HomeOutlined, PhoneOutlined } from "@mui/icons-material";
import { useEffect, useMemo, useState } from "react";
import { useAddEditCore } from "../../../stores/core/StoreCore";
import { ReqStatus } from "../../../stores/core/UseApi";
import { FolderCustomerState, FolderCustomerTypesState, OthersCustomerFolderStatusGroupedState } from "../../states/FoldersState";
import { useRecoilState, useRecoilValue } from "recoil";
import CustomerFoldersView from "./CustomerFoldersView";
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from "react-router-dom";


const CustomerCard = (props: { customerId: number }) => {

    const { customerId } = props;

    const { getData } = useAddEditCore<any, any>(`/commercial/customers/add-edit`);

    const [customer, setCustomer] = useRecoilState(FolderCustomerState);
    const [customerTypes, setCustomerTypes] = useRecoilState(FolderCustomerTypesState);
    const OthersCustomerFolderStatusGrouped = useRecoilValue(OthersCustomerFolderStatusGroupedState);

    useEffect(() => {
        getData(customerId.toString()).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                setCustomer(res.data.customer);
                setCustomerTypes(res.data.customerTypes);
            }
        })
    }, [customerId])

    const customerName = useMemo(() => {
        return `${customer?.user?.first_name} ${customer?.user?.last_name}`;
    }, [customer])

    const email = useMemo(() => {
        return customer?.user?.email ?? "N/C";
    }, [customer])

    const customerTypeName = useMemo(() => {
        const _customer_type_id: any = customer?.customer_type_id;
        const _customerTypes: any = customerTypes;
        return (_customerTypes && _customer_type_id) ? _customerTypes[_customer_type_id] : "";
    }, [customer, customerTypes])

    const emails_compl = useMemo(() => {
        return customer?.dahan_emails_compl?.split(';') ?? '';
    }, [customer])

    const phone = useMemo(() => {
        return customer?.user?.phone ?? "N/C";
    }, [customer])


    const phones_compl = useMemo(() => {
        return customer?.dahan_phones_compl?.split(';') ?? '';
    }, [customer])

    const navigate = useNavigate();
    
    return (
        <Paper>
            {customer &&
                <>
                    <Box p={2} display="flex" alignItems={"center"} justifyContent="space-between">
                        <Box display="flex" alignItems={"center"}>
                            <AvatarIcon value={customerName} />
                            <Box ml={2}>
                                <Box><Typography variant="subtitle1" >{customerName}</Typography></Box>
                                <Box>{customerTypeName}</Box>
                            </Box>
                        </Box>
                        <Box ml={2}>
                            <IconButton  color="primary"
                                onClick={() => navigate(`/commercial/customers/add-edit/${customerId}`)}
                            >
                                <EditIcon />
                            </IconButton>
                        </Box>

                    </Box>
                    <Divider />
                        <Grid container spacing={1} m={1} pb={1}>
                            <Grid item xs={12}>
                                <Box display="flex" alignItems={"center"}>
                                    <EmailOutlined />
                                    <Box ml={2}>{email}</Box>
                                </Box>

                                {emails_compl.map((email: any) => (
                                    email &&
                                    <Box display="flex" alignItems={"center"}>
                                        <EmailOutlined />
                                        <Box ml={2}>{email}</Box>
                                    </Box>

                                ))}
                            </Grid>

                            <Grid item xs={12} >

                                <Box display="flex" alignItems={"center"}>
                                    <PhoneOutlined />
                                    <Box ml={2}>{phone}</Box>
                                </Box>
                                {phones_compl && phones_compl !== "" && phones_compl.map((phone: any) => (
                                    phone && <Box display="flex" alignItems={"center"}>
                                        <PhoneOutlined />
                                        <Box ml={2}>{phone}</Box>
                                    </Box>
                                ))}
                            </Grid>

                            <Grid item xs={12}>
                                {customer.addresses.map((address: any) => (
                                    <Box mb={0} display="flex" alignItems={"center"}>
                                        <HomeOutlined />
                                        <Box ml={2}>{address.address_1} {address.postcode} {address.city} {address.country_id}</Box>
                                    </Box>
                                ))}
                            </Grid>
                            <Box ml={1}>
                                <Tooltip title={customer?.comment ?? ""}>
                                    <InfoIcon color={ customer?.comment ? "primary" : "disabled" }/>
                                </Tooltip>    
                            </Box>                              
                        </Grid>
                    {OthersCustomerFolderStatusGrouped.ready &&
                        <>
                            <Divider />
                            <Box p={1} >
                                <Typography>Autres Dossiers</Typography>
                            </Box>
                            <Divider />
                            <Box p={1} >
                                <Box display="flex">
                                    <Box display="flex" alignItems={"center"}>
                                        <Typography>En cours</Typography>
                                        <Box ml={1}>
                                            <Chip label={OthersCustomerFolderStatusGrouped.inProgress} color="warning" variant="filled" />
                                        </Box>
                                    </Box>
                                    <Box ml={2} display="flex" alignItems={"center"}>
                                        <Typography>Validés</Typography>
                                        <Box ml={1}>
                                            <Chip label={OthersCustomerFolderStatusGrouped.onHold} color="primary" variant="filled" />
                                        </Box>
                                    </Box>

                                    <Box ml={2} display="flex" alignItems={"center"}>
                                        <Typography>Terminés</Typography>
                                        <Box ml={1}>
                                            <Chip label={OthersCustomerFolderStatusGrouped.finished} color="default" variant="filled" />
                                        </Box>
                                    </Box>
                                </Box>

                            </Box>
                        </>
                    }

                </>
            }
        </Paper>
    )
}

export default CustomerCard;