import { Alert, Box, Grid, IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSetRecoilState } from "recoil";
import { AddressModalState } from "../../../states/AddressState";
import { useGetOrderByAddressId } from "../../stores/QuotationsStore";
import { useEffect, useState } from "react";
import InfoIcon from "@mui/icons-material/Info";

type CustomerCustomAddressItemProps = {
    address: any;
    disabled: boolean;
    customerId: string;
    onValidate: () => void;
    onDelete: (id: number) => void;
}

const CustomerCustomAddressItem = (props: CustomerCustomAddressItemProps) => {
    const { address, disabled, customerId, onValidate, onDelete } = props;
    const setAddressModalState = useSetRecoilState(AddressModalState);

    const orders = useGetOrderByAddressId();
    const [ordersState, setOrdersState] = useState<any[]>([]);
    useEffect(() => {
        orders(address.value).then((res) => {
            if (res.data.orders) {
                setOrdersState(res.data.orders);
            }
        });
    }, [orders]);

    return (
        <Grid container mb={2} alignItems="center">
            <Grid item xs={8} sm={6}>
                <Alert variant="outlined" severity="info" icon={false}>
                    {address.label}
                    {address.id}
                </Alert>
            </Grid>
            <Grid item xs={4} sm={6} display="flex" justifyContent="flex-start" alignItems="center">
                <Box display="flex" m={1} height={30} width={30}>

                    <Tooltip title={
                        <Box>
                            <Box>
                                Devis attaché à cette adresse:
                            </Box>
                            <Box>
                                {ordersState.map((order, key) => (
                                    <Box key={key}>
                                        #{order.id}
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    }>
                        <Box
                            bgcolor={ordersState.length > 0 ? "primary.main" : "success.main"}
                            color="white"
                            borderRadius={"100%"}
                            width={100}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            {ordersState.length}
                        </Box>
                    </Tooltip>

                </Box>
                <Box>
                    <IconButton color="info" disabled={disabled} onClick={() => setAddressModalState({
                        id: address.value,
                        open: true,
                        customerId: customerId,
                        onValidate: onValidate
                    })}>
                        <EditIcon />
                    </IconButton>
                </Box>
                <Box>
                    <IconButton color="error" disabled={disabled} onClick={() => onDelete(address.value)}>
                        <DeleteIcon />
                    </IconButton>
                </Box>
            </Grid>
        </Grid>
    )
}

export default CustomerCustomAddressItem;
