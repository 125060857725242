import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useField, useFieldArray } from "react-recoil-form"
import { useSetRecoilState } from "recoil";
import { WaitingModalState } from "../../states/ModalState";
import { orderShippingFieldNames } from "./OrderShippingView";

const areProductsPropertiesEgal = (arr1: any[], arr2: any[]) => {
    if (arr1.length !== arr2.length) {
        return false;
    }

    // Vérifier si les propriétés quantity et tax sont identiques pour chaque objet
    return arr1.every((item, index) => {
        return item._joinData.quantity == arr2[index]._joinData.quantity &&
            item._joinData.unit_tax_excl == arr2[index]._joinData.unit_tax_excl &&
            item._joinData.total_tax_excl == arr2[index]._joinData.total_tax_excl;
    });
};

const calculLines = ( modeCalcul : "HT" | "TTC", _products: any[], productsMemo? : any[]) => {
    /*if (productsMemo && areProductsPropertiesEgal(_products, productsMemo) ) {
        return false; 
    }*/

    _products.forEach((product: any) => {
        if(modeCalcul === "HT"){
            const _unit_tax_excl = parseFloat(product._joinData.unit_tax_excl) || 0;
            const _quantity = (parseFloat(product._joinData.quantity) || 0);
            const _vat = (parseFloat(product._joinData.vat) || 0);
    
            let unitTax = (_unit_tax_excl * _vat / 100);
    
            product._joinData.total_tax_excl = +(_quantity * _unit_tax_excl).toFixed(2);
            product._joinData.total_tax = +(unitTax * _quantity).toFixed(2);
            product._joinData.total_tax_incl = +(product._joinData.total_tax_excl + product._joinData.total_tax).toFixed(2);
        }else{
            const _total_tax_incl = parseFloat(product._joinData.total_tax_incl) || 0;
            const _quantity = (parseFloat(product._joinData.quantity) || 0);
            const _vat = (parseFloat(product._joinData.vat) || 0);

            let _total_tax_excl = (_total_tax_incl / (1 + (_vat > 0 ? _vat / 100 : 0))).toFixed(2);
            let _unit_tax_excl = (+_total_tax_excl / _quantity).toFixed(2);
            
            product._joinData.unit_tax_excl = +_unit_tax_excl;   
            product._joinData.total_tax_excl = +_total_tax_excl;
            product._joinData.total_tax = +((+product._joinData.total_tax_incl - +product._joinData.total_tax_excl).toFixed(2));
        }
    });

    return true;
};

export const useOrderCalculLines = (ready: boolean, fieldNames: string[], modeCalcul : "HT" | "TTC") => {

    const productsFieldArray = useFieldArray({
        name: "products",
        fieldNames: fieldNames,
    });
    

    const newProductsFieldArray = useFieldArray({
        name: "newProducts",
        fieldNames: fieldNames,
    });

    const [productJson, setProductJson] = useState<string>();
    const [newProductJson, setNewProductJson] = useState<string>();

    useEffect(() => {

        if(!ready){ return; }

        const _json = JSON.stringify(productsFieldArray.getFieldArrayValue());
        if(_json !== productJson){
            setProductJson(_json);
        }
    },[productJson, productsFieldArray.getFieldArrayValue()])

    useEffect(() => {

        if(!ready){ return; }

        const _json = JSON.stringify(newProductsFieldArray.getFieldArrayValue());
        if(_json !== newProductJson){
            setNewProductJson(_json);
        }
    },[newProductJson, newProductsFieldArray.getFieldArrayValue()])

    useEffect(() => {

        if(!ready){ return; }

        let _products = productJson ? JSON.parse(productJson) : [];      
        let _newProducts = newProductJson ? JSON.parse(newProductJson) : [];       

        calculLines(modeCalcul, _products);
        calculLines(modeCalcul, _newProducts);

        let _totalTaxExcl = 0;
        let _totalTax = 0;
        let _totalTaxIncl = 0;

        _products.forEach((product: any) => {
            _totalTaxExcl += product._joinData.total_tax_excl;
            _totalTax += product._joinData.total_tax;
            _totalTaxIncl += product._joinData.total_tax_incl;
        });

        _newProducts.forEach((product: any) => {
            _totalTaxExcl += product._joinData.total_tax_excl;
            _totalTax += product._joinData.total_tax;
            _totalTaxIncl += product._joinData.total_tax_incl;
        });

        totalTaxExcl.setFieldValue(+_totalTaxExcl.toFixed(2));
        totalTax.setFieldValue(+_totalTax.toFixed(2));
        totalTaxIncl.setFieldValue(+_totalTaxIncl.toFixed(2));

        productsFieldArray.setFieldArrayValue(_products);
        newProductsFieldArray.setFieldArrayValue(_newProducts);
        setProductJson(JSON.stringify(_products));
        setNewProductJson(JSON.stringify(_newProducts));

    },[productJson, newProductJson])

    const totalTaxExcl = useField({ name: "total_tax_excl" });
    const totalTax = useField({ name: "total_tax" });
    const totalTaxIncl = useField({ name: "total_tax_incl" });
}

export const useOrderShippingCalcul = (ready: boolean) => {
    const entityListField = useFieldArray({ name: "shippings", fieldNames: orderShippingFieldNames, });
    const newEntityListField = useFieldArray({ name: "newShippings", fieldNames: [...orderShippingFieldNames, 'status_history'] });
    const shippingTaxExcl = useField({ name: "shipping_tax_excl" });

    const _shippingTaxExcl = useMemo(() => {
        let _amount = 0;
        entityListField.getFieldArrayValue().forEach((liv) => {
            _amount += liv.amount_tax_excl || 0;
        });
        newEntityListField.getFieldArrayValue().forEach((liv) => {
            _amount += liv.amount_tax_excl || 0;
        });
        return _amount;
    },[entityListField.getFieldArrayValue(), newEntityListField.getFieldArrayValue()])

    useEffect(() => {
        if(!ready){ return; }
        if(_shippingTaxExcl === parseFloat(shippingTaxExcl.fieldValue)){
            return;
        }
        shippingTaxExcl.setFieldValue(+_shippingTaxExcl.toFixed(2));
    },[_shippingTaxExcl, shippingTaxExcl.fieldValue, ready])
}

export const useOrderCalcul = (ready: boolean) => {

    const totalTaxExcl = useField({ name: "total_tax_excl" });
    const totalTax = useField({ name: "total_tax" });
    const totalTaxIncl = useField({ name: "total_tax_incl" });

    const discountTaxExcl = useField({ name: "discount_tax_excl" });
    const discountTax = useField({ name: "discount_tax" });
    const discountTaxIncl = useField({ name: "discount_tax_incl" });
    const discountPercent = useField({ name: "discount_percent" });

    const totalMinusDiscountTaxExcl = useField({ name: "total_minus_discount_tax_excl" });
    const totalMinusDiscountTax = useField({ name: "total_minus_discount_tax" });
    const totalMinusDiscountTaxIncl = useField({ name: "total_minus_discount_tax_incl" });

    const shippingTaxExcl = useField({ name: "shipping_tax_excl" });
    const shippingTax = useField({ name: "shipping_tax" });
    const shippingTaxIncl = useField({ name: "shipping_tax_incl" });
    const shippingVat = useField({ name: "shipping_vat" });

    const totalTaxExclPlusShippingTaxExcl = useField({ name: "total_tax_excl_plus_shipping_tax_excl" });
    const totalTaxPlusShippingTax = useField({ name: "total_tax_plus_shipping_tax" });
    const totalTaxInclPlusShippingTaxIncl = useField({ name: "total_tax_incl_plus_shipping_tax_incl" });

    //% TVA livraison
    useEffect(() => {
        if(!ready){ return; }
        const _shippingVat = parseFloat(shippingVat.fieldValue || 0);
        const _shippingTaxExcl = parseFloat(shippingTaxExcl.fieldValue || 0);
        shippingTax.setFieldValue(
            +((Math.round(_shippingTaxExcl * _shippingVat) / 100).toFixed(2))
        );
        shippingTaxIncl.setFieldValue(
           +((Math.round((_shippingTaxExcl * (1 + _shippingVat / 100)) * 100) / 100).toFixed(2))
        );
    }, [shippingTaxExcl.fieldValue, shippingVat.fieldValue, ready]);

    useEffect(() => {
        if(!ready){ return; }

        const _totalTaxExcl = parseFloat(totalTaxExcl.fieldValue || 0 );
        const _totalTax = parseFloat(totalTax.fieldValue || 0 );
        const _totalTaxIncl = parseFloat(totalTaxIncl.fieldValue || 0 );

        const _discountPercent = discountPercent.fieldValue || 0;

        // Changement remise
        const _discountTaxExcl =  (Math.round(_totalTaxExcl * _discountPercent) / 100 );
        const _discountTax =  (Math.round(_totalTax * _discountPercent) / 100 );
        const _discountTaxIncl =  (Math.round(_totalTaxIncl * _discountPercent) / 100 );
        
        discountTaxExcl.setFieldValue(+_discountTaxExcl.toFixed(2));
        discountTax.setFieldValue(+_discountTax.toFixed(2));
        discountTaxIncl.setFieldValue(+_discountTaxIncl.toFixed(2));

        // Changement total - remise
        const _totalMinusDiscountTaxExcl = (_totalTaxExcl - _discountTaxExcl).toFixed(2);
        const _totalMinusDiscountTax = (_totalTax - _discountTax).toFixed(2);
        const _totalMinusDiscountTaxIncl = (_totalTaxIncl - _discountTaxIncl).toFixed(2);

        totalMinusDiscountTaxExcl.setFieldValue(+_totalMinusDiscountTaxExcl);
        totalMinusDiscountTax.setFieldValue(+_totalMinusDiscountTax);
        totalMinusDiscountTaxIncl.setFieldValue(+_totalMinusDiscountTaxIncl);

        // Changement total avec remise et livraison

        var _shippingTaxExcl = parseFloat(shippingTaxExcl.fieldValue || 0);
        var _shippingTax = parseFloat(shippingTax.fieldValue || 0);
        var _shippingTaxIncl = parseFloat(shippingTaxIncl.fieldValue || 0);

        totalTaxExclPlusShippingTaxExcl.setFieldValue(
            +((parseFloat(_totalMinusDiscountTaxExcl) + _shippingTaxExcl).toFixed(2))
        );
        totalTaxPlusShippingTax.setFieldValue(
            +((parseFloat(_totalMinusDiscountTax) + _shippingTax).toFixed(2))
        );
        totalTaxInclPlusShippingTaxIncl.setFieldValue(
            +((parseFloat(_totalMinusDiscountTaxIncl) + _shippingTaxIncl).toFixed(2))
        );
    }, [totalTaxExcl.fieldValue, totalTaxIncl.fieldValue, shippingTaxIncl.fieldValue, discountPercent.fieldValue])
}
