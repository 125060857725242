import { Grid } from "@mui/material";
import AddEditForm from "../../../components/common/form/AddEditForm";
import AddEditFormCard from "../../../components/common/form/AddEditFormCard";
import SelectField, { SelectOptionsProps } from "../../../components/common/form/SelectField";
import AutocompleteField, { AutocompleteOption } from "../../../components/common/form/AutocompleteField";
import InputField from "../../../components/common/form/InputField";
import DateField from "../../../components/common/form/DateField";
import { useAddEditCore } from "../../../stores/core/StoreCore";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { NavigateState } from "../../../states/MenuState";
import { useEffect, useMemo, useState } from "react";
import { ReqStatus } from "../../../stores/core/UseApi";
import { createOptionsForAutocomplete } from "../../../utils/DataUtils";
import { useAddEditForm } from "../../../components/common/form/AddEditFormPanel";
import { CurrentUserState } from "../../../states/AuthState";
import { useParams } from "react-router-dom";
import { folderOriginEnum, folderPaxOptions, folderTypeJourneyOptions, getLabelOfFolderOrigin } from "../../classes/FolderModel";
import { useField } from "react-recoil-form";

interface FolderResponse {
    folder: {
        id?: number
    }
    customers: { [id: string]: string },
    commercials: { [id: string]: string },
}
const FolderCreateView = () => {

    const { customerId } = useParams();

    const routeApiController = "project-dahan/dahan/folders";
    const setNavigateState = useSetRecoilState(NavigateState);
    const { getData, addEditData } = useAddEditCore<any, FolderResponse>(`${routeApiController}/add-edit`);

    const [customers, setCustomers] = useState<AutocompleteOption[]>([]);
    const [commercials, setCommercials] = useState<AutocompleteOption[]>([]);
    const currentUser = useRecoilValue(CurrentUserState);

    const originOptions = useMemo(() => {
        return [
            { value: folderOriginEnum.DIRECT, label: getLabelOfFolderOrigin(folderOriginEnum.DIRECT) },
            { value: folderOriginEnum.LEAD, label: getLabelOfFolderOrigin(folderOriginEnum.LEAD) },
            { value: folderOriginEnum.MAIL, label:  getLabelOfFolderOrigin(folderOriginEnum.MAIL) },
            { value: folderOriginEnum.PRESTABUS, label:  getLabelOfFolderOrigin(folderOriginEnum.PRESTABUS) },
        ]
    },[])

    useEffect(() => {
        getData(undefined).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                setCustomers(createOptionsForAutocomplete(res.data.customers));
                setCommercials(createOptionsForAutocomplete(res.data.commercials));
                let customerIdForm: number | undefined = undefined;
                if (customerId) {
                    if (!isNaN(+customerId) && res.data.customers[customerId]) {
                        customerIdForm = +customerId;
                    } else {
                        setNavigateState("/folders/")
                    }
                }
                resetInitialValues({
                    commercial_id: currentUser?.id === 1 ? undefined : currentUser?.id,
                    customer_id: customerIdForm
                });
            } else {

            }
        })
    }, [])


    const onSubmit = (request: any) => {
        request.status = 1;
        addEditData(request, undefined).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                if (Array.isArray(res.data) && res.data.length === 1 && res.data[0].id) {
                    setNavigateState(`/folders/add-edit/${res.data[0].id}`);
                } else {
                    setNavigateState(`/folders/`);
                }
            }
        })
        return request;
    }
    const { handleSubmit, resetInitialValues, isDirty } = useAddEditForm({
        onSubmit
    });

    const { fieldValue: typeJourneyValue } = useField({ name: 'type_journey' });

    return (
        <AddEditForm formId="folderCreateId" formHandleCancel={resetInitialValues} formHandleSubmit={handleSubmit} isDirty={isDirty}>

            <AddEditFormCard title="Créer un dossier" maxWidth="lg">
                <Grid container spacing={3}>

                    <Grid item xs={12} sm={6}>
                        <AutocompleteField
                            name="customer_id"
                            label="Client"
                            required
                            options={customers}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <AutocompleteField
                            name="commercial_id"
                            label="Commercial"
                            required
                            options={commercials}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <SelectField
                            name="type_journey"
                            label="Type de trajet"
                            options={folderTypeJourneyOptions}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <SelectField
                            name="pax"
                            label="Nombre de personnes"
                            options={folderPaxOptions}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <SelectField
                            name="origin"
                            label="Origine"
                            required
                            options={originOptions}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>

                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <InputField
                            name="departure_place"
                            label="Lieu de départ"
                            type="text"

                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <DateField
                            name="departure_date"
                            label="Date de départ"
                            type="date"

                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <InputField
                            name="return_place"
                            label="Lieu d'arrivée"
                            type="text"
                        />
                    </Grid>

                    {typeJourneyValue > 1 &&
                        <Grid item xs={12} sm={6}>
                            <DateField
                                name="return_date"
                                label="Date de retour"
                                type="date"
                            />
                        </Grid>
                    }

                    

                </Grid>

            </AddEditFormCard>


        </AddEditForm>
    );
}

export default FolderCreateView;