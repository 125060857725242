import { useCallback, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { AuthState, AuthorizationsState, CurrentUserState } from "../states/AuthState";
import { DefaultRoutes } from "./Routes";
import { Routes as CustomRoutes } from "../custom/Routes";
import { checkAutorization } from "../utils/AuthorizationUtils";

export interface SectionItem {
    index: string,
    title: string,
    //menuIcon?: JSX.Element,
    children: RouteItem[]
}

export interface AuthorizationItem {
    controller: string,
    action: string
}

export interface RouteItem {
    title: string,
    index: string,
    path: string,
    element: () => JSX.Element,
    menuVisible: boolean,
    menuIcon?: JSX.Element,
    enabled?: boolean,
    authorization: AuthorizationItem | null
}

const formatStringToCamelCase = (str: string, upperCaseFirstLetter: boolean) => {
    const words = str.split('-');

    const capitalizedWords = words.map((word, index) => {
        if (index === 0) {
            return (upperCaseFirstLetter ? word.charAt(0).toUpperCase() : word.charAt(0)) + word.slice(1);
        }
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });

    return capitalizedWords.join('');
}

export const useAppRoutes = () => {
    const is_auth = useRecoilValue(AuthState);
    const user_current = useRecoilValue(CurrentUserState);
    const user_autorizations = useRecoilValue(AuthorizationsState);

    const routeIsOk = useCallback((route: RouteItem) => {
        /*
        const [authorizedData, setAuthorizedData] = useRecoilState(authorizedButtonPathController);
        setAuthorizedData((prevData) => ({
            ...prevData,
            [route.path]: route.authorization?.controller
        }));
        
        */
        return checkAutorization(route.authorization, user_current, user_autorizations);
    }, [user_autorizations, user_current]);

    const routes: SectionItem[] = useMemo(() => {

        if (!is_auth || !user_current) { return []; }

        let _routes: SectionItem[] = [];

        if (CustomRoutes.length > 0) {
            _routes = CustomRoutes;
        } else {
            _routes = DefaultRoutes;
        }

        _routes.forEach(s => {
            s.children.forEach(r => {
                r.enabled = routeIsOk(r);
            });
        })
        return _routes;
    }, [is_auth, user_current, routeIsOk]);
    return { routes };
}