import React, { ErrorInfo, ReactNode } from 'react';
import { Button, Typography, Paper, Container, Box } from '@mui/material';
import AddEditFormCard from '../form/AddEditFormCard';

interface Props {
    title?: string,
    children: ReactNode;
}

interface State {
    hasError: boolean;
    error?: Error;
    errorInfo?: ErrorInfo;
}

class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(_: Error): State {
        // Mettre à jour l'état pour que le prochain rendu affiche l'UI de repli.
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // Vous pouvez aussi enregistrer l'erreur vers un service de reporting d'erreurs.
        console.error("Une erreur a été attrapée dans ErrorBoundary :", error, errorInfo);
        this.setState({ error, errorInfo });
    }

    // Méthode pour réinitialiser l'état de l'ErrorBoundary
    resetErrorBoundary = () => {
        this.setState({ hasError: false, error: undefined, errorInfo: undefined });
    };

    render() {
        if (this.state.hasError) {
            return (
                <AddEditFormCard
                    title={<Box>{this.props.title && `${this.props.title} - `} Quelque chose s'est mal passé</Box>}
                    withExpand={true}
                    isExpand={true}
                    action={<Button variant="contained" color="primary" onClick={this.resetErrorBoundary}>Réessayer</Button>}
                >
                    <Box height={300} overflow={"auto"}>
                        <Typography variant="body1" gutterBottom>
                            {this.state.error?.message}
                        </Typography>

                        <Typography variant="body2" gutterBottom>
                            {this.state.errorInfo?.componentStack}
                        </Typography>
                    </Box>
                </AddEditFormCard>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
