import { UserModel } from "../classes/users/UserModel";
import { AuthorizationItem } from "../layout/AppRoutes";
import { AuthorizationsUser } from "../states/AuthState";

export const checkAutorization = (authorization: AuthorizationItem | null, user_current: UserModel | null, user_autorizations: AuthorizationsUser | null) => {
    if (user_current?.user_group_id === 1) {
        return true;
    }
    if (!user_autorizations) {
        return false;
    }
    if (authorization && typeof (authorization) === 'object') {
        const _controller = authorization.controller;
        const _action = authorization.action;
        if (user_autorizations && user_autorizations[_controller] && Array.isArray(user_autorizations[_controller]) && user_autorizations[_controller].length > 0) {
            const actionExist = user_autorizations[_controller].find(v => v === _action);
            if (actionExist) {
                return true;
            }
        }
        return false;
    } else {
        return true;
    }
};