import { atom } from "recoil";

export const QuoteTravelModalState = atom<{travel: any | undefined, open: boolean,  onValidate: (travel:any) => void}>({
    key: 'QuoteTravelModalKey',
    default: {
        travel: undefined,
        open: false,
        onValidate: (travel:any) => {}
    }
});

export const QuoteTravelValidateModalState = atom<{quoteId: string | undefined, open: boolean,  onValidate: () => void}>({
    key: 'QuoteTravelValidateModalKey',
    default: {
        quoteId: undefined,
        open: false,
        onValidate: () => {}
    }
});

export const QuoteTravelHasAddedState = atom<boolean>({
    key: 'QuoteTravelHasAddedKey',
    default: false
});

export const QuoteFormSubmitForcedState = atom<boolean>({
    key: 'QuoteFormSubmitForcedKey',
    default: false
});