let FR = {
    /* COMMON */
    Common_Email : "Email",
    Common_Password : "Mot de passe",
    Common_Confirm_Password : "Confirmer mot de passe",
    Common_Name: "Nom",
    Common_FirstName: "Prénom",
    Common_Tel: "Téléphone",
    Common_Fax: "Fax",
    Common_Search: "Rechercher...",
    Common_Cancel : "Annuler",
    Common_Validate : "Valider",
    Common_SignOut: "Se déconnecter",

    /* PROFILE */    
    Profile_View_Title : "Profil",

    /* ORDER */
    Label_Order: "Commande",
    Label_Orders: "Commandes",
    Label_Order_Linked: "Commande associée",
    Label_Order_Number: "Commande n°",
    Label_Order_Status : "Statut commande",
    Label_Order_Date : "Date commande",
    Label_Order_NoResults : "Aucune commande pour ce client",
    Label_Order_Add : "Ajouter une commande",
    Label_Order_Edit : "Modifier une commande",

    /* BILL / CREDIT_NOTE */
    Label_Bill_Reference: "Référence",
    Label_Bill_Comment : "Commentaire",
}

export default FR;