import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { useField, useFieldArray } from "react-recoil-form";
import AutocompleteField, { AutocompleteOption } from "../common/form/AutocompleteField";
import DeleteIcon from "@mui/icons-material/Delete";
import SelectField, { SelectOptionsProps } from "../common/form/SelectField";
import InputField from "../common/form/InputField";
import NumberField from "../common/form/NumberField";
import TextAreaField from "../common/form/TextAreaField";
import DateField from "../common/form/DateField";
import { useMemo } from "react";

interface OrderPaymentItemProps {
    prefixeName: string,
    disabled?: boolean,
    id: number,
    paymentMethodOptions: SelectOptionsProps[],
    methodPaymentStatus: { id: string, options: SelectOptionsProps[] }[],
    deleteEvent: () => void,
}
const OrderPaymentItem = (props: OrderPaymentItemProps) => {
    const { prefixeName, disabled, id, paymentMethodOptions, methodPaymentStatus, deleteEvent } = props;

    const paymentMethodIdField = useField({ ancestors: [{ name: prefixeName, rowId: id }], name: "payment_method_id" });
    const statusIdField = useField({ ancestors: [{ name: prefixeName, rowId: id }], name: "status_id" });

    const statusOptions = useMemo(() => {
        if (!paymentMethodIdField.fieldValue) { 
            statusIdField.setFieldValue(undefined);
            return [];
        }
        const found = methodPaymentStatus.find(x => x.id == paymentMethodIdField.fieldValue);

        if (found) {
            const optionFound = found.options.find(o => o.value == statusIdField.fieldValue);
            if (!optionFound) { statusIdField.setFieldValue(undefined); }
            return found.options;
        } else {
            return [];
        }
    }, [paymentMethodIdField.fieldValue, methodPaymentStatus, statusIdField.fieldValue]);

    return (
        <>
            <Grid key={`${prefixeName}_payment_method_id_${id}`} item xs={1}>
                <SelectField
                    ancestors={[{ name: prefixeName, rowId: id }]}
                    options={paymentMethodOptions ?? []}
                    name={"payment_method_id"}
                    required
                    label={"Moyen de paiement"}
                    disabled={disabled}
                />
            </Grid>
            <Grid key={`${prefixeName}_line_status_id_${id}`} item xs={2}>
                <SelectField
                    ancestors={[{ name: prefixeName, rowId: id }]}
                    options={statusOptions}
                    name={"status_id"}
                    required
                    label={"Statut de paiement"}
                    disabled={disabled}
                />
            </Grid>
            <Grid key={`${prefixeName}_line_reference_${id}`} item xs={2}>
                <InputField
                    ancestors={[{ name: prefixeName, rowId: id }]}
                    name={"reference"}
                    label={"Référence"}
                    type="text"
                    disabled={disabled}
                />
            </Grid>
            <Grid key={`${prefixeName}_line_total_amount_${id}`} item xs={2}>
                <NumberField
                    ancestors={[{ name: prefixeName, rowId: id }]}
                    name={"total_amount"}
                    label={"Montant total"}
                    required
                    disabled={disabled}
                />
            </Grid>
            <Grid key={`${prefixeName}_line_comment_${id}`} item xs={2}>
                <TextAreaField
                    ancestors={[{ name: prefixeName, rowId: id }]}
                    name={"comment"}
                    label={"Commentaire"}
                    disabled={disabled}
                />
            </Grid>
            <Grid key={`${prefixeName}_line_created_${id}`} item xs={2}>
                <DateField
                    ancestors={[{ name: prefixeName, rowId: id }]}
                    name={"created"}
                    label={"Date"}
                    required
                    type="datetime"
                />
            </Grid>
            {!disabled ?
                <Grid item xs={1}>
                    <IconButton color="error" onClick={() => deleteEvent()}><DeleteIcon /></IconButton>
                </Grid>
            : <Grid item xs={1}></Grid> }
        </>
    )
}

interface OrderPaymentsViewProps {
    paymentMethods: SelectOptionsProps[],
    methodPaymentStatus: { id: string, options: SelectOptionsProps[] }[],
    disabled?: boolean
}

const fieldNames = ["id", "order_id", "payment_method_id", "status_id", "reference", "total_amount", "comment", "created"];

const OrderPaymentsView = (props: OrderPaymentsViewProps) => {
    const entityListField = useFieldArray({ name: "payments", fieldNames: fieldNames, });
    const newEntityListField = useFieldArray({ name: "newPayments", fieldNames: [...fieldNames, 'status_history'] });

    const { disabled, paymentMethods, methodPaymentStatus } = props;

    return (


        <Box display={"flex"} flexDirection={"column"} >
            <Grid container spacing={2} display={"flex"} alignItems={"flex-start"}>
                <>
                    {entityListField.fieldArrayProps.rowIds.map((rowId, rowIndex) => (
                        <OrderPaymentItem
                            key={rowId}
                            id={rowId}
                            prefixeName={"payments"}
                            deleteEvent={() => entityListField.remove(rowIndex)}
                            disabled={disabled}
                            paymentMethodOptions={paymentMethods}
                            methodPaymentStatus={methodPaymentStatus}
                        />
                    ))}
                    {!disabled && newEntityListField.fieldArrayProps.rowIds.map((rowId, rowIndex) => (
                        <OrderPaymentItem
                            key={rowId}
                            id={rowId}
                            prefixeName={"newPayments"}
                            deleteEvent={() => newEntityListField.remove(rowIndex)}
                            disabled={disabled}
                            paymentMethodOptions={paymentMethods}
                            methodPaymentStatus={methodPaymentStatus}
                        />
                    ))}
                </>
            </Grid>
            {!disabled &&
                <Box mt={2}>
                    <Button variant="contained" color="primary" onClick={() => newEntityListField.append({status_history: '',})}>{"Ajouter un paiement"}</Button>
                </Box>
            }
        </Box>

    );
}

export default OrderPaymentsView;