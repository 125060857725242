import { Box, Button, CircularProgress } from "@mui/material"
import { usePromiseTracker } from "react-promise-tracker";

interface SubmitButtonProps {
    label: string | JSX.Element,
    size?: "small" | "medium" | "large"
}

const SubmitButton = (props: SubmitButtonProps) => {
    const { label, size } = props;
    const { promiseInProgress } = usePromiseTracker();
    return (
        <Button
            size={ size ?? "medium" }
            type="submit"
            color="primary"
            variant="contained"
            disabled={promiseInProgress}
        >
            {label}
            {promiseInProgress &&
                <Box ml={1} display="flex" alignItems="center">
                    <CircularProgress size={15} />
                </Box>
            }
        </Button>
    )
}

export default SubmitButton;