import { Box } from "@mui/material";
import { AutocompleteOption } from "../../../components/common/form/AutocompleteField";
import OrderProductAdd from "../../../components/orders/OrderProductAdd";
import { useFormValues } from "react-recoil-form";
import { useMemo } from "react";
import { useSetRecoilState } from "recoil";
import { QuoteTravelHasAddedState } from "../../states/QuoteState";

const QuoteProductsActions = (props: { products: AutocompleteOption[], disabled?: boolean }) => {

    const formValues = useFormValues();

    const productIdUsed = useMemo(() => {
        let productIdUsed: Number[] = [];

        if (Array.isArray(formValues.products)) {
            productIdUsed = [...productIdUsed, ...formValues.products.map((p: any) => +p.id)];
        }
        if (Array.isArray(formValues.newProducts)) {
            productIdUsed = [...productIdUsed, ...formValues.newProducts.map((p: any) => +p.id)];
        }
        return productIdUsed;
    }, [formValues])

    const productsLigne = useMemo(() => {
        return props.products.filter((product: any) => product.id !== 1 && !productIdUsed.includes(product.id));
    }, [props.products, productIdUsed]);

    const productsTravel = useMemo(() => {
        return props.products.filter((product: any) => product.id === 1 && !productIdUsed.includes(product.id));
    }, [props.products, productIdUsed]);

    const setQuoteTravelHasAdded = useSetRecoilState(QuoteTravelHasAddedState);

    return (
        <>
            {!props.disabled &&
                <Box mt={1} >
                    <Box mt={1}>
                        <OrderProductAdd products={productsLigne} title={"Ajouter une ligne"} />
                    </Box>

                    <Box mt={1} >
                        <OrderProductAdd products={productsTravel} title={"Ajouter un voyage"} afterSuccessAdd={() => setQuoteTravelHasAdded(true)} />
                    </Box>
                </Box>
            }
        </>
    )
}

export default QuoteProductsActions;