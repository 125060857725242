import { Box, Grid } from "@mui/material";
import AddEditFormPanel, { useAddEditForm } from "../../../components/common/form/AddEditFormPanel";
import AddEditFormSubmit from "../../../components/common/form/AddEditFormSubmit";
import SelectField, { SelectOptionsProps } from "../../../components/common/form/SelectField";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { FolderOrderSupplierListState, FolderOrderSupplierValidatedOptionsState } from "../../states/FolderOrderSuppliersState";
import { useEffect, useMemo } from "react";
import { ReqStatus } from "../../../stores/core/UseApi";
import DateField from "../../../components/common/form/DateField";
import NumberField from "../../../components/common/form/NumberField";
import { useAddEditFolderOrderSupplierPayment } from "../../stores/FolderOrderSupplierPaymentsStore";
import { EnumFolderOrderSupplierStatus } from "../../classes/FolderOrderSupplierModel";
import TextAreaField from "../../../components/common/form/TextAreaField";
import InputField from "../../../components/common/form/InputField";

interface FolderOrderSupplierContactFormProps {
	id?: number | undefined,
	onClose: () => void,
	onValidate: () => void
}

const FolderOrderSupplierPaymentForm = (props: FolderOrderSupplierContactFormProps) => {

	const { id, onClose, onValidate } = props;

	const { getData, addEditData } = useAddEditFolderOrderSupplierPayment();


	const setFolderOrderSupplierList = useSetRecoilState(FolderOrderSupplierListState);
	const folderOrderSupplierOptions = useRecoilValue(FolderOrderSupplierValidatedOptionsState);

	useEffect(() => {
		const stringId = id !== undefined ? id.toString() : undefined;
		getData(stringId).then((res) => {
			if (res.status === ReqStatus.SUCCESS && res.data) {
				if(stringId){
					resetInitialValues(
						{
							folder_order_supplier_id: res.data.folderOrderSupplierPayment.folder_order_supplier_id,
							amount: res.data.folderOrderSupplierPayment.amount,
							payment_date: res.data.folderOrderSupplierPayment.payment_date,
							reference: res.data.folderOrderSupplierPayment.reference,
							comment: res.data.folderOrderSupplierPayment.comment
						}
					);
				}else{
					resetInitialValues(
						{
							reference: "",
						}
					);
				}
				
			}
		});
	}, []);

	const onSubmit = (request: any) => {
		addEditData(request, id?.toString()).then((res) => {
			  if (res.status === ReqStatus.SUCCESS && Array.isArray(res.data) && res.data.length > 0) {
		
				const payment = res.data[0];
				setFolderOrderSupplierList((list) => {
					var newList = list.map((item) => {
						if(item.id === payment.folder_order_supplier_id){
							return {
								...item,
								folder_order_supplier_payments: payment.is_new 
									? [...item.folder_order_supplier_payments, payment] 
									: item.folder_order_supplier_payments.map((p) => {
										if(p.id !== payment.id) { return p; }
										return { 
											...p,  
											...payment
										};
									})
							};
						}
						return item;
					})
					return newList;
				})
				onValidate();
			  }
		});
	}
	
	const { handleSubmit, resetInitialValues, isDirty } = useAddEditForm({
		onSubmit
	});

	return (
		<AddEditFormPanel
			title={id ? `Modifier un paiement fournisseur #${id}` : "Ajouter un paiement fournisseur"}
		>
			<form id={"folderOrderSupplierPayment"} onSubmit={handleSubmit}>
				<Grid container spacing={3} mt={1}>
					<Grid item xs={12} >
						<SelectField
							name="folder_order_supplier_id"
							label="Demande fournisseur"
							required
							options={folderOrderSupplierOptions}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<NumberField
							name="amount"
							label="Montant"
							required
							minDecimals={2}
							maxDecimals={2}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<DateField
							name="payment_date"
							label="Date paiement"
							type="date"
							required
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<InputField
							name="reference"
							label="Référence"
							type="text"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextAreaField
							name="comment"
							label="Note"
						/>
					</Grid>
				</Grid>
				<Box mt={2}>
					<AddEditFormSubmit
						returnAction={{disabled: true }}
						cancelEvent={() => { resetInitialValues(); onClose(); }}
						disabled={!isDirty}
					/>
				</Box>
			</form>
		</AddEditFormPanel >
	)
}

export default FolderOrderSupplierPaymentForm;