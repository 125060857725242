import { ListModelCore } from "../../classes/list/ListModel"
import { SelectOptionsProps } from "../../components/common/form/SelectField";
import { Tag } from "./TagModel"

export interface ChecktaskListModel extends ListModelCore{
    checktasks: Tag[]
}

export enum FolderChecktaskStatusEnum {
	UNTREATED = 0,
	INPROGRESS = 1,
	WARNING = 2,
	CHECKED = 3
}

export const FolderChecktaskStatusOptions : SelectOptionsProps[] = [
	{ value: FolderChecktaskStatusEnum.UNTREATED, label: "Non traité" },
	{ value: FolderChecktaskStatusEnum.INPROGRESS, label: "En cours" },
	{ value: FolderChecktaskStatusEnum.WARNING, label: "Problème" },
	{ value: FolderChecktaskStatusEnum.CHECKED, label: "Fait" },
];