import { Box, Checkbox, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import CheckboxField from "../common/form/CheckboxField";
import { GroupAuthorizationEntityRequest } from "../../classes/group-authorizations/GroupAuthorizationRequest";
import { useMemo } from "react";
import { useFormValues } from "react-recoil-form";
import { GroupAuthorizationEntities } from "../../classes/group-authorizations/GroupAuthorizationModel";

interface GroupAuthorizationDefaultViewProps {
    resetInitialValues: (values?: any, extraInfos?: any) => void,
    entities : GroupAuthorizationEntities,
}

const GroupAuthorizationTableDefault = (props: GroupAuthorizationDefaultViewProps) => {

    const values = useFormValues();
    const { entities, resetInitialValues } = props;

    const allReadChecked = useMemo(() => {
        if (!values) {
            return false;
        }

        let check = true;
        const valuesArr = Object.entries(values as GroupAuthorizationEntityRequest)
        if(Array.isArray(valuesArr)){
            valuesArr.forEach(([key, entity]) => {
                if (!entity || !entity.read) {
                    check = false;
                }
            });
        }else{
            check = false;
        }
       
        return check;
    }, [values]);

    const setAllReadChecked = (values: any, isChecked: boolean) => {
        if (!values) { return; }
        let _values = {} as GroupAuthorizationEntityRequest;
        const check = !isChecked;
        Object.entries(values as GroupAuthorizationEntityRequest).forEach(([key, entity]) => {
            _values[key] = {
                write: entity.write,
                read: check,
            }
        });
        resetInitialValues(_values);
    }

    const allWriteChecked = useMemo(() => {
        if (!values) {
            return false;
        }

        let check = true;
        const valuesArr = Object.entries(values as GroupAuthorizationEntityRequest)
        if(Array.isArray(valuesArr)){
            valuesArr.forEach(([key, entity]) => {
                if ( !entity || !entity.write) {
                    check = false;
                }
            });
        }else{
            check = false;
        }
        return check;
    }, [values]);

    const setAllWriteChecked = (values: any, isChecked: boolean) => {
        if (!values) { return; }
        let _values = {} as GroupAuthorizationEntityRequest;
        const check = !isChecked;
        Object.entries(values as GroupAuthorizationEntityRequest).forEach(([key, entity]) => {
            _values[key] = {
                write: check,
                read: entity.read
            }
        });
        resetInitialValues(_values);
    }

    return (
        <Table sx={{ minWidth: 650 }} >
            <TableHead>
                <TableRow>
                    <TableCell>Entité</TableCell>
                    <TableCell align="center">Lecture</TableCell>
                    <TableCell align="center">Ecriture</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell><strong>Tout sélectionner / désélectionner</strong></TableCell>
                    <TableCell>
                        <Box display="flex" justifyContent="center">
                            <Checkbox
                                checked={Boolean(allReadChecked)}
                                onChange={(e) => setAllReadChecked(values, allReadChecked)}
                            />
                        </Box>
                    </TableCell>
                    <TableCell>
                        <Box display="flex" justifyContent="center">
                            <Checkbox
                                checked={Boolean(allWriteChecked)}
                                onChange={(e) => setAllWriteChecked(values, allWriteChecked)}
                            />
                        </Box>
                    </TableCell>
                </TableRow>
                {entities && Object.entries(entities).map(([cle, entity]) => (
                    <TableRow key={cle}>
                        <TableCell>{entity.plugin && `${entity.plugin} - `}{entity.name}</TableCell>
                        <TableCell>
                            <Box display="flex" justifyContent="center">
                                <CheckboxField
                                    name={`${cle}.read`}
                                    label={""}
                                    withoutLabel
                                />
                            </Box>
                            
                        </TableCell>
                        <TableCell>
                            <Box display="flex" justifyContent="center">
                                <CheckboxField
                                    name={`${cle}.write`}
                                    label={""}
                                    withoutLabel
                                />
                            </Box>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>

        </Table>
    )
}

export default GroupAuthorizationTableDefault;