import { useCallback, useEffect, useMemo, useState } from "react";
import { ReqStatus } from "../../../stores/core/UseApi";
import { SelectOptionsProps } from "../../../components/common/form/SelectField";
import { useGetBillList } from "../../stores/FoldersStore";
import { getLabelOfBill } from "../../utils/BillUtil";

export const useBillList = (folderId: string) => {
	const get = useGetBillList();
	const [bills, setBills] = useState<any[]>([]);
	const getBillsAjax = useCallback(() => {
		if (isNaN(+folderId)) { return; }
		get(folderId).then((res) => {
			if (res.status === ReqStatus.SUCCESS && res.data) {
				setBills(res.data.bills ?? []);
			}
		})
	}, [folderId]);

	const billOptions = useMemo(() => {
		let options: SelectOptionsProps[] = [];
		bills.forEach((bill) => {
			options.push({
				value: bill.id,
				label: `${getLabelOfBill(bill)} #${bill.id}`
			});
		})
		return options;
	}, [bills]);

	useEffect(() => {
		getBillsAjax();
	}, []);

	return { billOptions, bills }
}