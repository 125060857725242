import { useNavigate, useParams } from "react-router-dom";
import { UserModel } from "../../../classes/users/UserModel";
import { useEffect, useState } from "react";
import { useAddEditCore } from "../../../stores/core/StoreCore";
import SelectField, { SelectOptionsProps } from "../../../components/common/form/SelectField";
import { ReqStatus } from "../../../stores/core/UseApi";
import { createOptionsForSelect } from "../../../utils/DataUtils";
import { useAddEditForm } from "../../../components/common/form/AddEditFormPanel";
import { useField } from "react-recoil-form";
import AddEditForm from "../../../components/common/form/AddEditForm";
import AddEditFormCard from "../../../components/common/form/AddEditFormCard";
import { Alert, Box, Button, Checkbox, FormControl, FormControlLabel, Grid } from "@mui/material";
import InputField from "../../../components/common/form/InputField";
import CheckboxField from "../../../components/common/form/CheckboxField";
import { datetimeFormat } from "../../../utils/DateUtils";


interface GetUserResponse {
    user: UserModel,
    userGroups: { [groupId: number]: string },
    viewMode: string
}
const UserView = () => {
    let { id } = useParams();

    const { getData, addEditData } = useAddEditCore<UserModel, GetUserResponse>(`users/add-edit`);

    const [roles, setRoles] = useState<SelectOptionsProps[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        getData(id ?? undefined).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                if (id) {
                    const _user = res.data.user;
                    resetInitialValues({
                        user_group_id: _user.user_group_id,
                        last_name: _user.last_name,
                        first_name: _user.first_name,
                        email: _user.email,
                        phone: _user.phone,
                        active: _user.active,
                        last_login: _user.last_login,
                        external: _user.external,
                        dahan_phone2: _user.dahan_phone2
                    });
                }
                setRoles(createOptionsForSelect(res.data.userGroups));
            } else {
                navigate("/users");
            }
        })
    }, [id]);

    const onSubmit = (request: UserModel) => {
        addEditData(request, id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                navigate("/users");
            }
        })
    }

    const { handleSubmit, resetInitialValues, isDirty } = useAddEditForm({ onSubmit, initialValues: {} });
    const lastLoginField = useField({ name: 'last_login' });


    const [externalChecked, setExternalChecked] = useState(false);
    const handleExternalCheckboxChange = (event: any) => {
        setExternalChecked(event.target.checked);
    };

    return (

        <AddEditForm formId={"userForm"} formHandleCancel={resetInitialValues} formHandleSubmit={handleSubmit} isDirty={isDirty}>
            <AddEditFormCard title={(!id ? "Ajouter " : "Modifier") + " un utilisateur"} >
                <Grid container spacing={3} mt={1}>
                    <Grid item xs={12} sm={6} lg={4}>
                        <InputField
                            name="last_name"
                            label="Nom"
                            required
                            type="text"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                        <InputField
                            name="first_name"
                            label="Prénom"
                            required
                            type="text"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                        <InputField
                            name="email"
                            label="Email"
                            required
                            type="text"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                        <InputField
                            name="phone"
                            label="Téléphone"
                            type="text"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                        <InputField
                            name="dahan_phone2"
                            label="Téléphone 2"
                            type="text"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                        <SelectField
                            name="user_group_id"
                            label="Rôle"
                            options={roles}
                            required
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4} container justifyContent="flex-start" alignItems="center">
                        <CheckboxField name="external" label="Externe" />
                    </Grid>


                    {id &&
                        <Grid item xs={12} sm={6} lg={4}>
                            <CheckboxField
                                name="active"
                                label="Actif"
                            />
                        </Grid>
                    }

                    {id && !lastLoginField.fieldValue &&
                        <Box mx={3} mt={2}>
                            <Alert severity="warning">Cet utilisateur ne s'est pas encore connecté.</Alert>
                        </Box>
                    }

                    {id && lastLoginField.fieldValue &&
                        <Box mx={3} mt={2}>
                            <Alert severity="info">Dernière connexion le {datetimeFormat(lastLoginField.fieldValue)}</Alert>
                        </Box>
                    }

                    <Grid display="flex" justifyContent="space-between" item xs={12}>
                        <Box>
                            {id &&
                                <Button color="info" variant="contained" onClick={() => {
                                    navigate(`/users/edit-password/${id}`);
                                }}>
                                    Modifier le mot de passe
                                </Button>
                            }
                        </Box>
                    </Grid>
                </Grid>
            </AddEditFormCard>


        </AddEditForm>
    );
}

export default UserView;