import { useCallback, useState } from "react";
import { ListExportButtonsModelCore, ListModelCore, ListSearchModelCore } from "../../classes/list/ListModel";
import { GridColDef } from "@mui/x-data-grid";
import { ReqStatus } from "../../stores/core/UseApi";
import { Box, Button, IconButton } from "@mui/material";
import ListServer from "../common/list/ListServer";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { useGetProductTypeList } from "../../stores/ProductTypesStore";
import { useGetListCore } from "../../stores/core/StoreCore";
import ListCore from "../common/list/ListCore";

const columns: GridColDef[] = [
    {
        field: 'name',
        headerName: "Nom",
        editable: false,
        filterable: false,
        minWidth: 100,
        flex: 1,
    },
    {
        field: 'parent_categories',
        headerName: "Catégorie(s) parente(s)",
        editable: false,
        filterable: false,
        minWidth: 100,
        flex: 1,
        renderCell: ({row}) => <Box>{row.parent_categories?.map((cat : any) => <Box>{cat.name}</Box>)}</Box>
    }
];


export interface CategoryListModel extends ListModelCore {
    categories: any[]
}

const CategoryList = () => {

    const routeController = "commercial/categories";
    const [get] = useGetListCore<CategoryListModel>(`/${routeController}/index`);

    return (
        <ListCore<CategoryListModel, any>
            getDatas={get}
            defineDatasToSet={(data) => data.categories}
            getRowId={(row) => row.id}
            columns={columns}
            actions={{
                defaultRoute: routeController,
                add: {
                    title: "Ajouter une categorie"
                },
                edit: true,
                remove: false
            }}
        />
    );
}

export default CategoryList;