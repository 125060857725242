import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import '@geoapify/geocoder-autocomplete/styles/round-borders.css';
import axios from 'axios';
import { Alert, Autocomplete, Box, CircularProgress, Divider, IconButton, List, ListItem, ListItemText, Menu, MenuItem, Paper, Popper, TextField } from '@mui/material';
import { useField } from 'react-recoil-form';
import InputField from '../../../components/common/form/InputField';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CopyButton from '../../../components/common/button/CopyButton';
import { TomTomAddressActions, TomTomAddressOptionModel, TomTomAddressOptions, useAddressDebounce, useTomTomAddressApi } from './TomTomAddressCommon';

interface SelectedOption {
	label: string;
	coordinates: [number, number];
}
interface TomTomAddressFieldProps {
	label: string,
	name: string,
	disabled?: boolean,
	required?: boolean,
	onCoordinatesSelect?: (coords: [number, number]) => void;
}

const TomTomAddressField = (props: TomTomAddressFieldProps) => {
	const { onCoordinatesSelect, name, label, disabled, required } = props;

	const { fieldValue, setFieldValue } = useField({ name: name });
	const { fieldValue: geoloc, setFieldValue: setGeoloc } = useField({ name: name+"_geoloc" });
	const [ready, setReady] = useState(false);
	const anchorRef = useRef<HTMLDivElement>(null);
	const { 
		open,
		setOpen,
		options,
		loading,
		noResult,
		searchEvent
	} = useTomTomAddressApi();

	const [isSelecting, setIsSelecting] = useState(false);
	
	const selectOption = useCallback((option: any) => {
		setIsSelecting(true);
		setFieldValue(option.label);
		if(Array.isArray(option.coordinates) && option.coordinates.length == 2){
			setGeoloc(option.coordinates[0]+","+option.coordinates[1]);
		}
		setOpen(false);
		setTimeout(() =>{
			setIsSelecting(false);
		}, 1000);
	}, []);

	useEffect(() => {
		setTimeout(() =>{
			setReady(true);
		}, 1000);
	},[])

	/*
	//Evenement de recherche après avoir saisi une adresse
	const debouncedFieldValue = useAddressDebounce(fieldValue, 500);
	useEffect(() => {
		if(!ready){ return; }
		if(!isSelecting && debouncedFieldValue){
			searchEvent(debouncedFieldValue);
		}
	},[debouncedFieldValue]);
	*/

	return (
		<Box width={"100%"} ref={anchorRef}>
			<InputField
				name={name}
				label={label}
				type="text"
				required={required}
				disabled={disabled}
				endAdornment={<TomTomAddressActions loading={loading} open={open} setOpen={setOpen} optionsLength={options.length} searchEvent={searchEvent} value={fieldValue} disabled={disabled} />}
			/>
			<TomTomAddressOptions 
				disabled={disabled}
				options={options}
				open={open}
				anchorRef={anchorRef}
				noResult={noResult}
				selectOption={selectOption}
			/>
		</Box>
	);
};
export default TomTomAddressField;