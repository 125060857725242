import { useCallback, useState } from "react";
import { ListSearchModelCore } from "../../classes/list/ListModel";
import { GridColDef } from "@mui/x-data-grid";
import { ReqStatus } from "../../stores/core/UseApi";
import { Box, Button, IconButton } from "@mui/material";
import ListServer from "../common/list/ListServer";
import { CustomerListModel, useGetCustomerList } from "../../stores/CustomersStore";

import EditIcon from "@mui/icons-material/Edit";
import { useGetListCore } from "../../stores/core/StoreCore";
import { useNavigate } from "react-router-dom";
import ListCore from "../common/list/ListCore";


const columns: GridColDef[] = [
    {
        field: 'user.email',
        headerName: "Email",
        editable: false,
        filterable: false,
        minWidth: 100,
        flex: 1,
        valueGetter: ({ row }) => row?.user?.email
    },
    {
        field: 'user.last_name',
        headerName: "Nom",
        editable: false,
        filterable: false,
        minWidth: 100,
        flex: 1,
        valueGetter: ({ row }) => row?.user?.last_name
    },
    {
        field: 'user.first_name',
        headerName: "Prénom",
        editable: false,
        filterable: false,
        minWidth: 100,
        flex: 1,
        valueGetter: ({ row }) => row?.user?.first_name
    },
    {
        field: 'user.phone',
        headerName: "Téléphone",
        editable: false,
        filterable: false,
        minWidth: 100,
        flex: 1,
        valueGetter: ({ row }) => row?.user?.phone
    }

];


const CustomerList = () => {

    const routeController = "commercial/customers";
    const [get] = useGetListCore<CustomerListModel>(`/${routeController}/index`);

    return (
        <ListCore<CustomerListModel, any>
            getDatas={get}
            defineDatasToSet={(data) => data.customers}
            getRowId={(row) => row.user_id}
            columns={columns}
            actions={{
                defaultRoute: routeController,
                add: {
                    title: "Ajouter un client"
                },
                edit: true,
                remove: false
            }}
        />
    );
}

export default CustomerList;