import CustomerTypeList from "../../components/customer-types/CustomerTypeList";
import CustomerTypeView from "../../components/customer-types/CustomerTypeView";
import CustomerList from "../../components/customer/CustomerList";
import CustomerView from "../../components/customer/CustomerView";
import { RouteItem, SectionItem } from "../AppRoutes";

import ContactsIcon from '@mui/icons-material/Contacts';
import BadgeIcon from '@mui/icons-material/Badge';

export const CustomerListRoute: RouteItem = {
    index: "CUSTOMERS",
    path: "/commercial/customers",
    title: "Clients",
    element: () => <CustomerList />,
    menuVisible: true,
    menuIcon: <ContactsIcon />,
    authorization: {
        controller: "Customers",
        action: "index"
    }
}
export const CustomerViewRoute: RouteItem = {
    index: "CUSTOMERS",
    path: "/commercial/customers/add-edit/:id?",
    title: "Clients",
    element: () => <CustomerView />,
    menuVisible: false,
    authorization: {
        controller: "Customers",
        action: "addEdit"
    }
}

export const CustomerTypeListRoute: RouteItem = {
    index: "CUSTOMER_TYPES",
    path: "/commercial/customer-types",
    title: "Types de client",
    element: () => <CustomerTypeList />,
    menuVisible: true,
    menuIcon: <BadgeIcon />,
    authorization: {
        controller: "CustomerTypes",
        action: "index"
    }
}
export const CustomerTypeViewRoute: RouteItem = {
    index: "CUSTOMER_TYPES",
    path: "/commercial/customer-types/add-edit/:id?",
    title: "Types de client",
    element: () => <CustomerTypeView />,
    menuVisible: false,
    authorization: {
        controller: "CustomerTypes",
        action: "addEdit"
    }
}

export const CustomerSectionRoutes = {
    title: "Clients",
    index: "CUSTOMERS",
    children: [
        CustomerListRoute, CustomerViewRoute,
        CustomerTypeListRoute, CustomerTypeViewRoute
    ]
} as SectionItem;