import { Box, Button, Grid } from "@mui/material";
import { FormattedMessage } from "react-intl";
import JsonFormView from "../debug/JsonFormView";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { InitialValuesFormState } from "../../../states/FormState";
import JsonView from "../debug/JsonView";
import { useCallback } from "react";

interface AddEditFormSubmitProps {
    disabled: boolean,
    cancelEvent: () => void,
    returnAction ? : {
        disabled?: boolean,
        event? : () => void,
        title? : string
    }
}

const AddEditFormSubmit = (props: AddEditFormSubmitProps) => {
    const { disabled, cancelEvent, returnAction } = props;
    const navigate = useNavigate();
    const initialValuesForm = useRecoilValue(InitialValuesFormState);

    return (
        <>
            <Grid display="flex" justifyContent="space-between" p={2} item xs={12} bgcolor="white">
                <Grid item>
                    { (returnAction === undefined || !returnAction.disabled ) &&
                        <Button color="info" variant="contained" onClick={() => returnAction?.event ? returnAction?.event() : navigate(-1)}>
                            {returnAction?.title ? returnAction?.title : "Retour"}
                        </Button>
                    }   
                </Grid>
                <Grid item display={"flex"}>
                    {process.env.REACT_APP_DEBUG === "1" && <Box display="flex" mr={2}><JsonView title={"Initial Values"} value={initialValuesForm} /></Box>}
                    {process.env.REACT_APP_DEBUG === "1" && <Box display="flex" mr={2}><JsonFormView /></Box>}
                    <Box>
                        <Button color="info" variant="outlined" onClick={() => cancelEvent()}>
                            <FormattedMessage id={"Common_Cancel"} />
                        </Button>
                        <Button disabled={disabled} color="info" variant="contained" style={{ marginLeft: "10px" }} type="submit">
                            <FormattedMessage id={"Common_Validate"} />
                        </Button>
                    </Box>
                </Grid>

            </Grid>

        </>
    )
}

export default AddEditFormSubmit;