import { useCallback } from "react";
import { ListModelCore, ListSearchModelCore, getParamsForListRequest } from "../classes/list/ListModel";
import { GenericResponse, ReqStatus, useApi } from "./core/UseApi";
import { useGetListCore } from "./core/StoreCore";
import { SupplierModel } from "../classes/suppliers/SupplierModel";

interface SupplierListModel extends ListModelCore{
    suppliers: SupplierModel[],
    products: {[productId: string]: string};
}
export const useGetSupplierList = () => {
    return useGetListCore<SupplierListModel>('commercial/suppliers/index');
};

interface GetSupplierResponse {
    supplier: SupplierModel,
    products: {[productId: string]: string};
}
export const useGetSupplier = function (): [(id?: number) => Promise<GenericResponse<GetSupplierResponse>>, ReqStatus] {
    const [callCore, status] = useApi<GetSupplierResponse, undefined>(
        {
            route: 'commercial/suppliers/add-edit',
            instanceAxiosMethod: "GET"
        }
    );
    const call = useCallback((id? : number) => {
        return callCore( undefined, `/${id ?? ""}` );
    }, [callCore]);
    return [call, status];
};

export const useAddEditSupplier = function (): [(data: any, id : string | undefined) => Promise<GenericResponse<any>>, ReqStatus] {
    const [callCore, status] = useApi<any, any>(
        {
            route: 'commercial/suppliers/add-edit',
            instanceAxiosMethod: "POST"
        }
    );
    const call = useCallback((data: any, id : string | undefined) => {
        return callCore(data, id ? `/${id}` : '');
    }, [callCore]);
    return [call, status];
};