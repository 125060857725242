import { IconButton, InputAdornment } from "@mui/material";
import InputField from "./InputField";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useState } from "react";

const PasswordField = (props : { name: string, label: string, required? :boolean}) => {

    const { name, label, required } = props;    

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <InputField
            name={name}
            label={label}
            required={required}
            type={showPassword ? 'text' : 'password'}
            endAdornment={
                <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                    >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                </InputAdornment>
            }
        />
    )
}

export default PasswordField;