
import { RouteItem, SectionItem } from "../AppRoutes";

import OrderList from "../../components/orders/OrderList";
import OrderView from "../../components/orders/OrderView";
import PaymentList from "../../components/payments/PaymentList";
import PaymentView from "../../components/payments/PaymentView";
import CreditNoteView from "../../components/bills/CreditNoteView";
import BillView from "../../components/bills/BillView";
import BillList from "../../components/bills/BillList";

import CreditCardIcon from '@mui/icons-material/CreditCard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ReceiptIcon from '@mui/icons-material/Receipt';


export const OrderListRoute: RouteItem = {
    index: "ORDERS",
    path: "/commercial/orders",
    title: "Commandes",
    element: () => <OrderList />,
    menuVisible: true,
    menuIcon: <ShoppingCartIcon />,
    authorization: {
        controller: "Orders",
        action: "index"
    }
}

export const OrderViewRoute: RouteItem = {
    index: "ORDERS",
    path: "/commercial/orders/add-edit/:id?",
    title: "Commandes",
    element: () => <OrderView />,
    menuVisible: false,
    authorization: {
        controller: "Orders",
        action: "addEdit"
    }
};

export const PaymentListRoute: RouteItem = {

    index: "PAYMENTS",
    path: "/commercial/payments",
    title: "Paiements",
    element: () => <PaymentList />,
    menuVisible: true,
    menuIcon: <CreditCardIcon />,
    authorization: {
        controller: "Payments",
        action: "index"
    }
}

export const PaymentViewRoute: RouteItem = {
    index: "PAYMENTS",
    path: "/commercial/payments/add-edit/:id?",
    title: "Paiements",
    element: () => <PaymentView />,
    menuVisible: false,
    authorization: {
        controller: "Payments",
        action: "addEdit"
    }
};

export const BillListRoute: RouteItem = {
    index: "BILLS",
    path: "/commercial/bills",
    title: "Factures",
    element: () => <BillList />,
    menuVisible: true,
    menuIcon: <ReceiptIcon />,
    authorization: {
        controller: "Bills",
        action: "index"
    }
}

export const BillViewRoute: RouteItem = {

    index: "BILLS",
    path: "/commercial/bills/add/:id",
    title: "Factures",
    element: () => <BillView />,
    menuVisible: false,
    authorization: {
        controller: "Bills",
        action: "add"
    }
};

export const CreditNoteViewRoute: RouteItem = {
    index: "BILLS",
    path: "/commercial/bills/add/:id/credit-note",
    title: "Ajouter un avoir",
    element: () => <CreditNoteView modeCalcul="HT" />,
    menuVisible: false,
    authorization: {
        controller: "Bills",
        action: "add"
    }
};

export const ComptaSectionRoutes = {
    title: "Comptabilité",
    index: "ACCOUNTING",
    children: [
        OrderListRoute, OrderViewRoute,
        PaymentListRoute, PaymentViewRoute,
        BillListRoute, BillViewRoute, CreditNoteViewRoute
    ]
} as SectionItem;