export interface BrevoTemplateModel{
	id: number,
	name: string,
	subject: string,
	isActive: string,
	replyTo: string,
	toField: string,
	tag: string,
	htmlContent: string
}

export const brevoEventTypes = [
    { label: 'Envoyé', code: 'requests', backgroundColor: '#98A0AB' },
    { label: 'Envoyé', code: 'sent', backgroundColor: '#98A0AB' },
    { label: 'Délivré', code: 'delivered', backgroundColor: '#4388AE' },
    { label: '1ère ouverture', code: 'opening', backgroundColor: '#66B2DC' },
    { label: 'Ouvert', code: 'opened', backgroundColor: '#66D2DC' },
    { label: 'Chargé par proxy', code: 'accepted', backgroundColor: '#A0C0E4' },
    { label: 'Cliqué', code: 'clicked', backgroundColor: '#9CC980' },
    { label: 'Bounce', code: 'bounce', backgroundColor: '#E6735C' },
    { label: 'Bloqué', code: 'rejected', backgroundColor: '#E53935' },
    { label: 'Plainte', code: 'spamreport', backgroundColor: '#B87E73' },
    { label: 'Désinscrit', code: 'unsubscribe', backgroundColor: '#F3BB94' },
    { label: 'Email invalide', code: 'injection', backgroundColor: '#CD7867' },
    { label: 'Différé', code: 'deferred', backgroundColor: '#EFA381' },
    { label: 'Error', code: 'error', backgroundColor: '#EFA381' }
];