import { atom } from "recoil";
import { BrevoTemplateModel } from "../classes/BrevoApiModel";

export const Mail2ModalState = atom<{
    open: boolean, 
    folderId: number, 
    templateIdDefault?: number, 
    entityId?: number, 
    emails?: string[],
    onValidate?: () => void
}>({
key: 'Mail2ModalState',
default: {
    open: false,
    folderId: 0,
    emails: []
}
});

export const BrevoTemplatesState = atom<BrevoTemplateModel[]>({
    key: 'BrevoTemplatesKey',
    default: []
});

export const BrevoMailConfigsState = atom<any[]>({
    key: 'BrevoMailConfigsKey',
    default: []
});