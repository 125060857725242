import React from 'react';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import Header from './Header';
import Menu from './Menu';
import { useRecoilValue } from 'recoil';
import WaitingModal from '../components/common/modal/WaitingModal';
import { Outlet } from 'react-router-dom';
import { NavigationBlockedState, WebHeightState, MobileHeightState, LayoutHeightState } from '../states/MenuState';
import ConfirmModal from './ConfirmModal';
import ConfirmNavigationModal from './ConfirmNavigationModal';

const Layout = () => {
    const theme = useTheme();
    const isWeb = useMediaQuery(theme.breakpoints.up('sm'));
    const isBlocked = useRecoilValue(NavigationBlockedState);

    const layoutHeight = useRecoilValue(LayoutHeightState);
    const webHeight = useRecoilValue(WebHeightState);
    const mobileHeight = useRecoilValue(MobileHeightState);

    return (
        <Box id={"app"} sx={{ backgroundColor: 'whitesmoke', height: '100%', overflowY: 'auto' }}>
            <Box>
                <Header />
                <WaitingModal />
                <ConfirmModal />
                <ConfirmNavigationModal isBlocked={isBlocked} />
            </Box>
            <Box height={layoutHeight ? `calc(100% - ${isWeb ? webHeight : mobileHeight}px)` : 'auto'} sx={{ overflowY: layoutHeight ? 'auto' : 'none' }}>
                <Grid container height='100%'>
                    <Grid item xs={"auto"} height='100%' >
                        <Menu />
                    </Grid>
                    <Grid item xs sx={{ overflowY: 'hidden' }} height='100%'>
                        <Outlet />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default Layout;