import { Alert, Box, Button, Grid, IconButton, Tooltip } from "@mui/material";
import AddEditFormCard from "../../../components/common/form/AddEditFormCard";
import { useCallback, useMemo } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { FolderOrderSupplierContactModalState } from "../../states/FolderOrderSupplierContactState";
import FolderOrderSupplierContactModal from "./FolderOrderSupplierContactModal";
import { FolderOrderSupplierListState } from "../../states/FolderOrderSuppliersState";
import { EnumFolderOrderSupplierContactType, FolderOrderSupplierContactModel } from "../../classes/FolderOrderSupplierContactModel";
import { useDeleteFolderOrderSupplierContact } from "../../stores/FolderOrderSupplierContactsStore";
import { ReqStatus } from "../../../stores/core/UseApi";
import { ConfirmModalState } from "../../../states/ModalState";
import InfoIcon from '@mui/icons-material/Info';
import { IsNotUserCommercialSelector, UserExtenalSelector } from "../../states/UserState";
import TooltipComment from "../common/TooltipComment";

const FolderOrderSupplierContactList = () => {

	const setModalState = useSetRecoilState(FolderOrderSupplierContactModalState);

	const [folderOrderSupplierList, setFolderOrderSupplierList] = useRecoilState(FolderOrderSupplierListState);

	const deleteAjax = useDeleteFolderOrderSupplierContact();
	const setConfirmModalState = useSetRecoilState(ConfirmModalState);

	const contacts = useMemo(() => {
		let contacts: FolderOrderSupplierContactModel[] = [];
		folderOrderSupplierList.forEach((folderOrderSupplier) => {
			if (Array.isArray(folderOrderSupplier.folder_order_supplier_contacts)) {
				folderOrderSupplier.folder_order_supplier_contacts.forEach((contact) => {
					contacts.push(contact);
				});
			}
		});
		return contacts;
	}, [folderOrderSupplierList]);

	const getTypeLabel = useCallback((type: number) => {
		if (type === EnumFolderOrderSupplierContactType.GO) { return "Aller"; }
		else if (type === EnumFolderOrderSupplierContactType.BACK) { return "Retour"; }
		else { return "Aller-Retour"; }
	}, []);

	const getOrderSupplierName = useCallback((folderOrderSupplierId: number) => {
		const item = folderOrderSupplierList.find((x) => x.id == folderOrderSupplierId);
		return item ? `Devis ${item.order.id} - ${item.supplier.name}` : "Erreur lien";
	}, [folderOrderSupplierList]);

	const deleteEvent = useCallback((id: number) => {
		setConfirmModalState({
			open: true,
			title: "Suppression",
			desc: "Êtes-vous sûr de vouloir supprimer ce contact ?",
			validEvent: () => {
				deleteAjax(id).then((res) => {
					if (res.status === ReqStatus.SUCCESS) {
						setFolderOrderSupplierList((list) => {
							return list.map((i) => {
								return {
									...i,
									folder_order_supplier_contacts: i.folder_order_supplier_contacts.filter(c => c.id !== id)
								}
							});
						})
					}
				})
			}
		})
	}, [deleteAjax, setConfirmModalState, setFolderOrderSupplierList]);

	const isNotUserCommercial = useRecoilValue(IsNotUserCommercialSelector);

	return (	
		<>
			{isNotUserCommercial && (
				<>
					<AddEditFormCard title="Contact chauffeur">
						<FolderOrderSupplierContactModal />
						{folderOrderSupplierList.length === 0 &&
							<Alert variant="outlined" severity="info">Aucun fournisseur sélectionné</Alert>
						}
						{folderOrderSupplierList.length > 0 &&
							<Box mb={1}>
								<Button variant="contained" color="info" onClick={() => {
									setModalState({
										open: true, onValidate: () => { }
									})
								}}
								>Ajouter un contact</Button>
							</Box>
						}
						{contacts.map((contact, key) => (
							<Grid key={key} container display="flex" alignItems={"center"} mt={1}>
								<Grid item xs={6} sm={10}>
									<Alert variant="outlined" severity="info" icon={false}>
										<Box>
											{getOrderSupplierName(contact.folder_order_supplier_id)}
										</Box>
										<Box>
											Chauffeur Trajet {getTypeLabel(contact.type)}
										</Box>
										<Box display="flex">
											{contact.name} {contact.phone && `(${contact.phone})`} 
													{contact.comment && <Box ml={2}><Tooltip title={<TooltipComment value={contact.comment} />}><InfoIcon /></Tooltip></Box>}
										</Box>
									</Alert>
								</Grid>
								<Grid xs={2} sm={2}>
									<Box display={"flex"}>
										<Box>
											<IconButton color="primary"
												onClick={() => {
													setModalState({
														open: true,
														id: contact.id,
														onValidate: () => { }
													})
												}}
											><EditIcon /></IconButton>
										</Box>

										<Box>
											<IconButton color="error" onClick={() => deleteEvent(contact.id)}><DeleteIcon /></IconButton>
										</Box>
									</Box>
								</Grid>
							</Grid>
						))}

					</AddEditFormCard>
				</>
			)}
		</>
	);
}

export default FolderOrderSupplierContactList;