import { Autocomplete, Box, FilterOptionsState, TextField } from "@mui/material";
import { AutocompleteOption } from "../form/AutocompleteField";
import { useCallback } from "react";

interface AutocompleteCoreProps{
    value: AutocompleteOption | null
    onChange: ( newValue: AutocompleteOption | null) => void,
    options: AutocompleteOption[],
    required? :boolean,
    disabled? :boolean,
    label? : string
    className? : string
}
const AutocompleteCore = (props: AutocompleteCoreProps) => {

    const { value, onChange, options, required, label, disabled, className} = props;

    const customFilterOptions = useCallback((options: AutocompleteOption[], state: FilterOptionsState<AutocompleteOption>) => {
        const filteredOptions = options.filter((option) =>
            option.label?.toLowerCase().includes(state.inputValue.toLowerCase())
        );    
        return filteredOptions;
    },[options]);

    return (

        <Autocomplete<AutocompleteOption>
            value={value}
            onChange={(event, newValue) => {
                if(disabled){ return; }
                onChange(newValue);
            }}
            isOptionEqualToValue={(o, v) => o.id === v.id}
            getOptionLabel={(option) => `${option?.label.toLocaleUpperCase() ?? ""}`}
            renderOption={(props, option) => (
                <li {...props} key={option.id}>
                    {option.label?.toLocaleUpperCase()}
                </li>
            )}
            filterOptions={customFilterOptions}
            options={options}
            open={disabled ? false : undefined}
            disabled={disabled ?? false}       
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    variant="outlined"
                    required={required}
                    disabled={disabled ?? false}
                    className={className}
                />
            )}
        />
    )
}

export default AutocompleteCore;