import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Chart } from "react-google-charts";
import { ReqStatus } from "../../../stores/core/UseApi";
import { UserTimetrackerModel } from "../../classes/UserTimetrackerModel";
import { dateFormat } from "../../../utils/DateUtils";
import ListSearchServer from "../../../components/common/list/ListSearchServer";
import { ListModelCore, ListSearchModelCore } from "../../../classes/list/ListModel";
import { useGetListCore } from "../../../stores/core/StoreCore";
import { Alert, Box, Button } from "@mui/material";
import ListSearchFilterDateRange from "../../../components/common/list/ListSearchFilterDateRange";

interface UserTimetrackerListModel extends ListModelCore {
	userTimetrackers: UserTimetrackerModel[]
}

const UserTimetrackerStats = () => {

	const [datas, setDatas] = useState<{ [dateKey: string]: { [userId: number]: number } }>({});
	const [userIdList, setUserIdList] = useState<number[]>([]);
	const [search, setSearch] = useState<ListSearchModelCore | undefined>(undefined);
	const [dayGraph, setDayGraph] = useState<boolean>(true);
	const [graphData, setGraphData] = useState<any>([]);
	const [goToMonthAtPageLogin, setGoToMonthAtPageLogin] = useState<boolean>(false);

	const routeApiController = "project-dahan/dahan/user-timetrackers";
	const [get] = useGetListCore<UserTimetrackerListModel>(`/${routeApiController}/index`);

	const refresh = useCallback((csv?: boolean | undefined, pdf?: boolean | undefined) => {
		refreshWithSearch(search, csv, pdf);
	}, [search])

	const refreshWithSearch = useCallback((search: any, csv?: boolean, pdf?: boolean) => {
		get(500, 0, search, csv, pdf)
			.then((res) => {
				if (res.status === ReqStatus.SUCCESS && res.data) {
					//Récupération des valeurs distinctes des users et des dates
					const uniqueUserIds = new Set<number>();
					const uniqueDates = new Set<string>();
					res.data.userTimetrackers.forEach(obj => {
						uniqueUserIds.add(obj.user_id);
						uniqueDates.add((new Date(obj.start)).toDateString());
					});
					const distinctUserIds = Array.from(uniqueUserIds);
					const distinctDates = Array.from(uniqueDates);

					//Initialisation
					const totalsByDateAndUser: { [dateKey: string]: { [userId: number]: number } } = {};
					distinctDates.forEach((dateKey) => {
						distinctUserIds.forEach((userId) => {
							if (!totalsByDateAndUser[dateKey]) {
								totalsByDateAndUser[dateKey] = {};
							}
							if (!totalsByDateAndUser[dateKey][userId]) {
								totalsByDateAndUser[dateKey][userId] = 0;
							}
						})
					})

					// Parcourez le tableau d'objets pour calculer les totaux
					res.data.userTimetrackers.forEach(obj => {
						if (!obj.end) { return; }

						const userId = obj.user_id;
						const dateKey = (new Date(obj.start)).toDateString();
						const timeSpent = (new Date(obj.end).getTime()) - (new Date(obj.start).getTime());

						if (!totalsByDateAndUser[dateKey]) {
							totalsByDateAndUser[dateKey] = {};
						}

						if (!totalsByDateAndUser[dateKey][userId]) {
							totalsByDateAndUser[dateKey][userId] = 0;
						}

						totalsByDateAndUser[dateKey][userId] += +((timeSpent / 1000 / 3600).toFixed(2));
					});

					setUserIdList(Array.from(uniqueUserIds));

					setDatas(totalsByDateAndUser);
					setSearch(res.data.search);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	}, [get]);

	useEffect(() => {
		refresh();
	}, [])

	// *** Création d'un tooltip personnalisé ***
	const createCustomTooltipHtml = (value: number, name: string) => {
		let convertTime: string;
		if (Number.isInteger(value)) {
			convertTime = `${value}h`;
		} else {
			const hours: number = Math.floor(value);
			const minutes: number = Math.round((value - hours) * 60);
			convertTime = `${hours}h ${minutes < 10 ? `0${minutes}m` : `${minutes}m`}`;
		}
		return `<div style="padding: 5px; border-radius: 5px; font-size: 16px;">${name}<br/><b>${convertTime}</b></div>`;
	}

	// *** Création d'un tableau pour tooltip ***
	const createTooltipTab = (TabWithoutTooltip: (string | number)[]) => {
		const userLstTooltip: any = [];
		TabWithoutTooltip.forEach((item) => {
			userLstTooltip.push(item, { type: 'string', role: 'tooltip', p: { 'html': true } });
			//userLstTooltip.push({ type: 'string', role: 'tooltip', p: { 'html': true } });
		});
		return userLstTooltip;
	}

	// *** Récupération des Ids utilisateurs par ordre croissant ***
	const getSortedUserIdList = (userIdList: number[]) => {
		return userIdList.sort((a, b) => a - b);
	};

	// *** Récupération des noms des utilisateurs ***
	const getUserList = (userIdList: number[], search: any) => {
		return userIdList.map(x => search.user_id.datasource[x]);
	};

	// *** Formatage des données pour le graphique (par jour) ***
	const datasChart = useMemo(() => {
		if (!search || !datas || Object.entries(datas).length == 0) { return []; }

		const sortedUserIdList = getSortedUserIdList(userIdList);
		const userLst = getUserList(sortedUserIdList, search);

		const _datasChart: (string | number | { type: string, role: string, p: { 'html': boolean } })[][] = [
			["Jour", ...createTooltipTab(userLst)]
		];

		Object.entries(datas).forEach(([dateKey, value]) => {
			const item: (string | number)[] = [];
			item.push(dateFormat(new Date(dateKey)));

			Object.entries(value).forEach(([k, v]) => {

				item.push(v, createCustomTooltipHtml(v, userLst[sortedUserIdList.indexOf(+k)]));
			});
			_datasChart.push(item);
		});

		setGraphData(_datasChart);
		if (!goToMonthAtPageLogin) {
			setGoToMonthAtPageLogin(true);
		}
		return _datasChart;

	}, [datas, userIdList, dayGraph]);


	// ***	Formatage des données pour le graphique (par mois) ***
	const handleMonthClick = () => {
		if (datasChart.length === 0) { return []; }

		const usersLstTooltip = datasChart[0].slice(0);
		const usersData: any = {};
		for (let i = 0; i < usersLstTooltip.length - 1; i++) {
			usersData[i] = 0;
		}
		datasChart.slice(1).forEach((items) => {
			items.slice(1).forEach((item, index) => {
				usersData[index] += item;
			});
		});

		let sumTimeLst: (number | string)[] = [];
		Object.entries(usersData).forEach(([item, value]) => {
			if (typeof value === 'number') {
				sumTimeLst.push(value);
			}
		});

		let sumTimeLstTooltip: (number | string)[] = [];
		const usersLst = getUserList(getSortedUserIdList(userIdList), search);
		sumTimeLst.map((item, index) => {
			if (typeof item === 'number') {
				sumTimeLstTooltip.push(item, createCustomTooltipHtml(item, usersLst[index]));
			}
		});
		sumTimeLstTooltip.unshift(`${datasChart.slice(1)[0][0]} - ${datasChart.slice(-1)[0][0]}`); // Ajout de la première colonne avec les dates
		setGraphData([usersLstTooltip, sumTimeLstTooltip]);
	};

	// *** Changement du graphique (par jour) ***
	const handleDayClick = () => {
		setDayGraph(!dayGraph);
	};

	// ***	Rendu du graphique lié à la recherche et au composant <Chart /> ***
	const data = useMemo(() => {
		return graphData;
	}, [datasChart, graphData]);

	// *** Lors du charement de la page, executer le chargement du graphique (par mois)  ***
	useEffect(() => {
		handleMonthClick();
	}, [goToMonthAtPageLogin]);

	return (
		<Box m={2}>
			{search &&
				<ListSearchServer
					search={search}
					refreshWithSearch={refreshWithSearch}
					setSearch={setSearch}
					refresh={refresh}
				/>
			}

			<ListSearchFilterDateRange
				period={"month"}
				setSearch={setSearch}
				refresh={refreshWithSearch}
				loading={false}
				fieldName={'start'}
			/>

			<Box display="flex" justifyContent="center" gap={2} my={1}>
				<Button variant="contained" color="secondary" onClick={handleDayClick}>Jour</Button>
				<Button variant="contained" color="success" onClick={handleMonthClick}>Période</Button>
			</Box>
			
			{datasChart.length > 0 &&
				<>
					<Chart
						chartType="ColumnChart"
						width="100%"
						height="400px"
						data={data}
						options={{
							title: "Temps de travail",
							tooltip: { isHtml: true },
						}}
					/>
				</>
			}

			{datasChart.length == 0 &&
				<Box m={1}>
					<Alert color="warning">Il n'y a pas de saisie de temps pour cette recherche</Alert>
				</Box>
			}
		</Box>
	);
}

export default UserTimetrackerStats;