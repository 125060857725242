import { Alert, Box, Button, Container, Divider, Grid, IconButton, InputBase, Modal, Paper, Typography } from "@mui/material";
import { FormProvider, useField } from "react-recoil-form";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { FolderOrderSupplierListState, FolderOrderSupplierSelectionModalState } from "../../states/FolderOrderSuppliersState";
import AddEditFormPanel, { useAddEditForm } from "../../../components/common/form/AddEditFormPanel";
import { FormattedMessage } from "react-intl";
import SearchIcon from '@mui/icons-material/Search';
import SelectField, { SelectOptionsProps } from "../../../components/common/form/SelectField";
import { useGetQuoteList } from "../../stores/FoldersStore";
import { ReqStatus } from "../../../stores/core/UseApi";
import AddEditFormSubmit from "../../../components/common/form/AddEditFormSubmit";
import { useInsertOrderSupplierByIds, useSearchSupplier } from "../../stores/FolderOrderSuppliersStore";
import { EnumQuoteStatus } from "../../classes/QuoteModel";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Height } from "@mui/icons-material";
import { useQuoteList } from "../quotes/useQuote";
import SupplierTooltip from "../suppliers/SupplierTooltip";

interface SupplierInfo { name: string, id: number }

const FolderOrderSupplierSelectionForm = (props: { folderId: string }) => {

	const { folderId } = props;

	const [modalState, setModalState] = useRecoilState(FolderOrderSupplierSelectionModalState);


	const handleClose = useCallback(() => {
		setModalState((a) => {
			return { ...a, open: false };
		});
	}, [setModalState]);

	const handleValidate = useCallback(() => {
		modalState.onValidate();
		setModalState((a) => {
			return { ...a, open: false };
		});
	}, [modalState, setModalState]);

	const { quoteOptions } = useQuoteList(folderId);

	const [search, setSearch] = useState<string>('');
	const [searchHasBeen, setSearchHasBeen] = useState(false);
	const searchSupplierAjax = useSearchSupplier();

	const insertSelection = useInsertOrderSupplierByIds();

	const onSubmit = (values: any) => {
		insertSelection.call(values.order_id, values.supplier_ids).then((res) => {
			if (res.status === ReqStatus.SUCCESS) {
				handleValidate();
				handleClose();
			}
		})
	}
	const { handleSubmit, resetInitialValues, isDirty } = useAddEditForm({
		onSubmit
	});

	const orderIdField = useField({ name: 'order_id' });
	const orderSupplierIdsField = useField({ name: 'supplier_ids' });

	const [supplierSelected, setSupplierSelected] = useState<SupplierInfo[]>([]);
	const [supplierSearched, setSupplierSearched] = useState<SupplierInfo[]>([]);

	useEffect(() => {
		setSupplierSelected([]);
		setSupplierSearched([]);
		setSearch("");
		setSearchHasBeen(false);
	}, [orderIdField.fieldValue])

	useEffect(() => {
		orderSupplierIdsField.setFieldValue(supplierSelected.map((s) => s.id));
	}, [supplierSelected])

	const searchEvent = useCallback(() => {
		if (!orderIdField.fieldValue || !search || search.length < 2) { return; }

		setSearchHasBeen(false);
		searchSupplierAjax.call(+orderIdField.fieldValue, "name", search).then((res) => {
			if (res.status === ReqStatus.SUCCESS && res.data) {
				const suppliers = Object.entries(res.data.suppliers).map(([key, value]: [string, any]) => {
					return { 
						supplierId: key,
						...value 
					};
				});

				setSupplierSearched(suppliers);
				setSearchHasBeen(true);
			}
		})
	}, [search, searchSupplierAjax, orderIdField.fieldValue]);

	const addSupplier = useCallback((supplier: SupplierInfo) => {
		setSupplierSelected((l) => {
			if (l.find((x) => x.id === supplier.id)) {
				return l;
			}
			return [...l, supplier];
		});
	}, []);

	const removeSupplier = useCallback((supplier: SupplierInfo) => {
		setSupplierSelected((l) => {
			return l.filter(x => x.id !== supplier.id)
		});
	}, []);
	return (
		<AddEditFormPanel
			title={"Selection des fournisseurs"}
		>
			<form id={"folderOrderSupplierSelectionForm"} onSubmit={handleSubmit}>
				<Box mt={2}>

					<Box mt={2}>
						<SelectField
							label="Devis"
							name="order_id"
							options={quoteOptions}
							required
						/>
					</Box>

					<Box mt={2}>
						{orderIdField.fieldValue > 0 &&
							<Paper
								component="form"
								sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
							>
								<InputBase
									sx={{ ml: 1, flex: 1 }}
									placeholder="Rechercher des fournisseurs"
									value={search}
									onChange={(e) => setSearch(e.target.value)}
								/>
								<IconButton disabled={(!search || search.length < 2) || searchSupplierAjax.status === ReqStatus.LOADING} type="button" sx={{ p: '10px' }} aria-label="search" onClick={() => searchEvent()}>
									<SearchIcon />
								</IconButton>
							</Paper>
						}
					</Box>

					<Grid container mt={2} spacing={2}>
						<Grid item xs={6} >
							<Box><Typography variant="body1">Recherche</Typography></Box>
							<Box sx={{ maxHeight: "400px", overflowY: "auto" }}>
								{supplierSearched.map((s, s_key) => (
									
									<Box key={s_key} m={1} px={1} display="flex" justifyContent={"space-between"} sx={{ backgroundColor: "lightgray" }}>
										<SupplierTooltip
											supplier={s}
										>
											<Box>{s.id} - {s.name}</Box>
										</SupplierTooltip>	
										<Box ml={2}><IconButton onClick={() => addSupplier(s)}><AddIcon /></IconButton></Box>
									</Box>

								))}
								
							</Box>
							{searchHasBeen && supplierSearched.length === 0 && <Alert color="warning" icon={false}>Aucun fournisseur n'a été trouvée</Alert>}
						</Grid>

						<Grid item xs={6}>
							<Box><Typography variant="body1">Sélection</Typography></Box>
							<Box sx={{ maxHeight: "400px", overflowY: "auto" }}>
								{supplierSelected.map((s, s_key) => (
									<Box key={s_key} m={1} px={1} display="flex" justifyContent={"space-between"} sx={{ backgroundColor: "cornflowerblue" }}>
										<Box my={1}>{s.id} - {s.name}</Box>
										<Box ml={2}><IconButton onClick={() => removeSupplier(s)}><RemoveIcon /></IconButton></Box>
									</Box>
								))}
							</Box>
							{supplierSelected.length === 0 && <Alert color="info" icon={false}>Aucun fournisseur de sélectionné</Alert>}
						</Grid>

						<Grid item xs={6}>
							{supplierSearched.length > 0 &&
								<Button variant="contained" color="info" onClick={() => setSupplierSelected(supplierSearched)}>Tout sélectionner</Button>
							}
						</Grid>
						<Grid item xs={6}>
							{supplierSelected.length > 0 &&
								<Button variant="contained" color="warning" onClick={() => setSupplierSelected([])} >Tout Déselectionner</Button>
							}
						</Grid>
					</Grid>

				</Box>
				<Box mt={2}>
					<AddEditFormSubmit
						returnAction={{disabled: true }}
						cancelEvent={() => { resetInitialValues(); handleClose(); }}
						disabled={!isDirty || supplierSelected.length === 0}
					/>
				</Box>
			</form>
		</AddEditFormPanel >
	)
}

const FolderOrderSupplierSelection = (props: { folderId: string }) => {

	const { folderId } = props;
	const modalState = useRecoilValue(FolderOrderSupplierSelectionModalState);

	return (
		<Modal
			open={modalState.open}
			aria-labelledby="folder-order-supplier-selection-modal"
			sx={{
				overflow: 'auto'
			}}
		>
			<Container component="main" maxWidth="md" sx={{ height: "100%", py: 2 }}>
				<FormProvider options={{ formId: "folderOrderSupplierSelectionForm", skipRecoilRoot: true }}>


					<FolderOrderSupplierSelectionForm folderId={folderId} />

				</FormProvider>
			</Container>
		</Modal >
	);
}

export default FolderOrderSupplierSelection;