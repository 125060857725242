import { Box, Button } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-recoil-form";
import { LoginRequest } from "../../classes/users/LoginRequest";
import InputField from "../common/form/InputField";
import { useLogin, useLoginConfig } from "../../stores/AuthStore";
import { ReqStatus } from "../../stores/core/UseApi";
import { useRecoilState, useSetRecoilState } from 'recoil';
import { AuthState } from "../../states/AuthState";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import PasswordField from "../common/form/PasswordField";
import CheckboxField from "../common/form/CheckboxField";
import SubmitButton from "../common/form/SubmitButton";
import LoginCard from "./LoginCard";
import { toast } from "react-toastify";
import { DefaultRouteState, MenuVisibleState } from "../../states/MenuState";

const LoginForm = () => {
    const [getLoginConfig] = useLoginConfig();
    const [callLogin] = useLogin();
    const navigate = useNavigate();

    const [isAuth, setIsAuth] = useRecoilState(AuthState);
    const [rememberMeVisible, setRememberMeVisible] = useState(false);
    const setMenuVisible = useSetRecoilState(MenuVisibleState);
    const setDefaultRoute = useSetRecoilState(DefaultRouteState);

    useEffect(() => {
        //si déjà logué, on redirige vers l'accueil
        if (isAuth) {
            navigate("/users/profile", { state: { title: 'Profil' } });
        } else {
            getLoginConfig().then(res => {
                if (res.status === ReqStatus.SUCCESS && res.data) {
                    setRememberMeVisible((res.data.cookieSettings) ? !res.data.cookieSettings.hide : false);
                    resetInitialValues({
                        email: "",
                        password: "",
                        remember_me: res.data.cookieSettings?.checked ?? false
                    })
                }
            });
        }
    }, [])

    
    const onSubmit = useCallback((loginRequest: LoginRequest) => {
        callLogin(loginRequest).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                toast("Authentification réussie", { type: "success" });
                setIsAuth(true);

                if(res.redirection){
                    setDefaultRoute(res.redirection);
                    navigate(res.redirection);
                    setMenuVisible(false);
                }else{
                    setDefaultRoute("/users/profile");
                    navigate("/users/profile");
                }
            }
        });
    },[callLogin]);

    const { handleSubmit, resetInitialValues } = useForm({
        onSubmit,
        initialValues: {
            email: "",
            password: "",
            remember_me: false
        } as LoginRequest
    })

    const intl = useIntl();

    return (
        <LoginCard>
            <form onSubmit={handleSubmit}>

                <Box pt={1}>
                    <InputField
                        name="email"
                        label={intl.formatMessage({ id: "Common_Email" })}
                        required
                        isEmail
                        type="text"
                    />
                </Box>
                <Box pt={2}>
                    <PasswordField
                        name="password"
                        label={intl.formatMessage({ id: "Common_Password" })}
                        required
                    />
                </Box>

                {rememberMeVisible &&
                    <Box pt={2}>
                        <CheckboxField
                            name="remember_me"
                            label="Se souvenir de moi"
                        />
                    </Box>
                }

                <Box pt={2}>
                    <Button onClick={() => navigate("/forgot-password")}>
                        Mot de passe oublié
                    </Button>
                </Box>

                <Box display="flex" justifyContent="flex-end" pt={2}>
                    <SubmitButton
                        label={<FormattedMessage id="Common_Validate" />}
                    />
                </Box>
            </form>
        </LoginCard>
    )
}

export default LoginForm;