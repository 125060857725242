import { Box, IconButton } from "@mui/material";
import { FolderTag } from "../../classes/FolderTagModel";
import DeleteIcon from "@mui/icons-material/Delete";
import { Tag } from "../../classes/TagModel";

const TagItem = (props: { tag: any }) => {
	const { tag } = props;
 	return (
		<>
		<Box
			px={1}
			mb={1}
			sx={{
				backgroundColor: tag.backcolor,
				color: tag.color,
 				}}
			display="flex"
			justifyContent={"space-between"}
			alignItems="center">
			<Box>
				{tag.name}
			</Box>
		</Box>
		</>
 	);
}

const FolderTagItem = (props: { folderTag: FolderTag, deleteEvent? : (id: number) => void }) => {

	const { folderTag, deleteEvent } = props;

	return (
		<>
			<Box
				px={1}
				mb={1}
				sx={{
					backgroundColor: folderTag.tag.backcolor,
					color: folderTag.tag.color,
				}}
				display="flex"
				justifyContent={"space-between"}
				alignItems="center">
				<Box>
					{folderTag.tag.name}</Box>
				{deleteEvent && <Box>
					<IconButton onClick={() => deleteEvent(folderTag.id)}><DeleteIcon /></IconButton>
				</Box>}
			</Box>
		</>
	);
}
export { FolderTagItem, TagItem };
export default FolderTagItem;