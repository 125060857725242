import { Alert, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-recoil-form";
import { useGetNewPassword, usePostNewPassword } from "../../stores/AuthStore";
import { ReqStatus } from "../../stores/core/UseApi";
import { FormattedMessage, useIntl } from "react-intl";
import SubmitButton from "../common/form/SubmitButton";
import PasswordField from "../common/form/PasswordField";
import { NewPasswordRequest } from "../../classes/users/NewPasswordRequest";
import { useNavigate, useParams } from "react-router-dom";
import LoginCard from "./LoginCard";

const NewPasswordForm = (props: { route : "activate" | "new-password" }) => {
    const { route } = props;
    let { guid } = useParams();
    const [getNewPassword] = useGetNewPassword(route);
    const [postNewPassword] = usePostNewPassword(route);
    const navigate = useNavigate();

    const [ready, setReady] = useState(false);
    const [linkValid, setLinkValid] = useState(false);

    useEffect(() => {
        if (!guid) {
            alert("Ce lien n'est plus valide");
            return;
        }
        getNewPassword(guid).then(res => {
            if (res.status === ReqStatus.SUCCESS  && res.data && res.data.user) {
                setLinkValid(true);
            }
        }).finally(() => setReady(true))
    }, [])

    const onSubmit = (request: NewPasswordRequest) => {
        if (!guid) {
            return;
        }
        postNewPassword(request, guid).then(res => {
            if (res.status === ReqStatus.SUCCESS) {
                navigate("/login");
            }
        })
    }

    const { handleSubmit } = useForm({
        onSubmit,
        initialValues: {
            password: "",
            password_confirm: "",
        } as NewPasswordRequest
    })

    const intl = useIntl();

    return (

        <LoginCard>
            <>
                {ready && !linkValid && 
                    <Alert severity="error" sx={{ marginTop: "5px"}}>Le lien est invalide</Alert>
                }
                {ready && linkValid &&
                    <form onSubmit={handleSubmit}>

                        <Box pt={2}>
                            Choisissez un nouveau mot de passe de connexion
                        </Box>

                        <Box pt={2}>
                            <PasswordField
                                name="password"
                                label={intl.formatMessage({ id: "Common_Password" })}
                                required
                            />
                        </Box>

                        <Box pt={2}>
                            <PasswordField
                                name="password_confirm"
                                label={intl.formatMessage({ id: "Common_Confirm_Password" })}
                                required
                            />
                        </Box>

                        <Box display="flex" justifyContent="flex-end" pt={2}>
                            <SubmitButton
                                label={<FormattedMessage id="Common_Validate" />}
                            />
                        </Box>
                    </form>
                }

            </>

        </LoginCard>
    )
}

export default NewPasswordForm;