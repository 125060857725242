import { Box } from "@mui/material";
import QuoteTravelModal from "./QuoteTravelModal";
import { AutocompleteOption } from "../../../components/common/form/AutocompleteField";
import AddEditFormList from "../../../components/common/form/AddEditFormList";
import QuoteProductsTravelOrLabelField from "./QuoteProductsTravelOrLabelField";
import QuoteProductsActions from "./QuoteProductsActions";

const QuoteProducts = (props: { products: AutocompleteOption[], disabled?: boolean }) => {
    return (
        <>
            <QuoteTravelModal disabled={props.disabled} />
            <Box >
                {<AddEditFormList
                    fields={[{
                        name: "_joinData.product_id",
                        type: "autocomplete",
                        label: "Nom produit",
                        autocompleteOptions: props.products,
                        xs: 1,
                        required: true,
                        disabled: true,
                    },
                    {
                        name: " _joinData.travel",
                        type: "custom",
                        xs: 4,
                        customElement: (ancestors) => <QuoteProductsTravelOrLabelField ancestors={ancestors} disabled={props.disabled}  />
                    },
                    {
                        name: "_joinData.unit_tax_excl",
                        type: "number",
                        label: "Total HT",
                        disabled: true,
                        xs: 2,
                    },
                    {
                        name: "_joinData.vat",
                        type: "number",
                        label: "TVA %",
                        xs: 2,
                        disabled: false
                    },
                    {
                        name: "_joinData.total_tax_incl",
                        type: "number",
                        label: "Total TTC",
                        xs: 2,
                        disabled: false,
                        required: true,
                    }
                    ]}

                    hiddenFields={{
                        add: ["_joinData.order_product_attribute", "_joinData.label"],
                        edit: ["_joinData.order_product_attribute", "_joinData.label"]
                    }}

                    entity={"products"}
                    title={"Produits associés"}
                    defaultItem={{}}
                    disabled={props.disabled}
                />}

                <QuoteProductsActions products={props.products} disabled={props.disabled} />

        
            </Box>
        </>
    )
}

export default QuoteProducts;