import { useCallback } from "react";
import { useApi } from "../../stores/core/UseApi";
import { UserTimetrackerModel, UserWorked } from "../classes/UserTimetrackerModel";

const routeController = 'project-dahan/dahan/user-timetrackers';

export const useGetCurrentTimeTracker = () => {
    const [callCore, status] = useApi<{ userTimetracker: UserTimetrackerModel }, undefined>(
        {
            route:  `${routeController}/current`,
            instanceAxiosMethod: "GET"
        }
    );
    const call = useCallback( () => {
        return callCore(undefined);
    }, [callCore]);
    return { call, status };
}

export const useAddTimeTracker = () => {
    const [callCore, status] = useApi<undefined, undefined>(
        {
            route:  `${routeController}/add-tracking`,
            instanceAxiosMethod: "POST"
        }
    );
    const call = useCallback( () => {
        return callCore(undefined);
    }, [callCore]);
    return { call, status };
}

export const useGetUsersWorked = () => {
    const [callCore, status] = useApi<{ usersWorked : UserWorked[] }, undefined>(
        {
            route:  `${routeController}/get-users-worked`,
            instanceAxiosMethod: "GET"
        }
    );
    const call = useCallback( () => {
        return callCore(undefined);
    }, [callCore]);
    return { call, status };
}
