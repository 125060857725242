import { Chip, FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from "@mui/material";
import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { useField } from "react-recoil-form";
import { SelectOptionsProps } from "./SelectField";

interface IFieldProps {
    name: string;
    label?: string;
    options: SelectOptionsProps[];
    chips?: boolean;
    required?: boolean,
    disabled?: boolean,
    optionType? : "string" | "number"
}

export default function RadioField(props: IFieldProps) {
    const { name, label, options, chips, required, disabled, optionType } = props;
    const { fieldValue, setFieldValue, error } = useField({
        name,
        validate: (value) => {
            if (value === 0) { return; }
            if (required && ( value === undefined || value === null || value === "" )) {
                return `Le champ ${label} est requis`;
            }
        }
    });
    const intl = useIntl();
    const control = useCallback((label: string) => {
        return (chips ?
            <Radio
                disabled={disabled}
                required={required}
                checkedIcon={
                    <Chip
                        sx={{ cursor: "pointer" }}
                        color="primary"
                        label={label}
                        variant={"filled"}
                    />
                }
                icon={
                    <Chip
                        sx={{ cursor: "pointer" }}
                        color={disabled ? "default" : "primary" }
                        label={label}
                        variant={"outlined"}
                        //style={disabled ? {visibility: "hidden"} : {}}
                    />
                }
            /> :
            <Radio />);
    }, [chips,disabled, required]);

    const getLabelOfOption = useCallback((label: string, formatMessage? : boolean) => {
        return (formatMessage === true) ? intl.formatMessage({id : label}) : label;
    },[intl]);

    return (
        <FormControl error={!!error}>
            <FormLabel component="legend">{label} {required && "*"}</FormLabel>
            <RadioGroup
                row
                name={name}                  
                value={fieldValue === 0 ? fieldValue : ( fieldValue ?? "")}
                onChange={(e) => {
                    if(disabled){ return; }
                    const v = (optionType === "number") ? +e.target.value : e.target.value;
                    setFieldValue(v);
                }}
            >
                {options.map((option, i) => (
                    <FormControlLabel
                        key={i}
                        value={option.value}
                        control={control(getLabelOfOption(option.label, option.formatMessage))}
                        label={chips ? "" : getLabelOfOption(option.label, option.formatMessage)}
                    />
                ))}
            </RadioGroup>
            {error && <FormHelperText id="my-helper-text">{error}</FormHelperText>}
        </FormControl>
    );
}
