import { Alert, Box, Button, Divider, Grid, IconButton, Paper, TextField, Tooltip } from "@mui/material";

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import AddEditFormCard from "../../../../components/common/form/AddEditFormCard";
import { QuoteCircuitDayModel, QuoteCircuitDayStepAddressModel, QuoteCircuitDayStepModel, QuoteCircuitModel } from "../../../classes/QuoteModel";
import { v4 as uuidv4 } from 'uuid';
import QuoteCircuitDay from "./QuoteCircuitDay";
import { QuoteCircuitState } from "../../../states/QuoteCircuitState";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import dayjs from "dayjs";
import { ConfirmModalState } from "../../../../states/ModalState";
import QuoteCircuitPreview from "./QuoteCircuitPreview";
import { useField } from "react-recoil-form";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import SaveIcon from '@mui/icons-material/Save';
// Composant Liste


const QuoteCircuitButtonFormUpdate = (props: { disabled? : boolean, entityName?: string }) => {
	const { setFieldValue } = useField({ name: props.entityName ?? 'circuit' });
	const circuit = useRecoilValue(QuoteCircuitState);
	const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

	const saveJson = useCallback(() => {
		setFieldValue(circuit && circuit.days.length > 0 ? JSON.stringify(circuit) : null);
	}, [circuit]);

	useEffect(() => {
		if (timeoutId) {
			clearTimeout(timeoutId);
		}
		const timeOutInstance = global.setTimeout(() => {
			saveJson();
		}, 500);
		setTimeoutId(timeOutInstance);
	}, [circuit, saveJson]);

	return ( <>{ props.disabled !== true && <IconButton color="success" onClick={() => saveJson()}><SaveIcon /></IconButton> } </>);
};

const QuoteCircuit = (props: { disabled?: boolean, circuitJson: any, title?: string, entityName? : string }) => {

	const { disabled, circuitJson, title, entityName } = props;
	const [circuit, setCircuit] = useRecoilState(QuoteCircuitState);

	const circuitHasGuidDoublon = useMemo(() => {
		let guids: string[] = [];
		let hasDoublons = false;
		circuit.days.forEach((d) => {

			if (!guids.includes(d.id)) {
				guids.push(d.id);
			} else {
				hasDoublons = true;
			}

			d.steps.forEach((s) => {

				if (!guids.includes(s.id)) {
					guids.push(s.id);
				} else {
					hasDoublons = true;
				}

				s.addresses.forEach((a) => {
					if (!guids.includes(a.id)) {
						guids.push(a.id);
					} else {
						hasDoublons = true;
					}
				});
			});
			
		})
		return hasDoublons;
	}, [circuit.days])

	const circuitFixEvent = useCallback(() => {
		setCircuit((c) => {
			if(!c){ return c; }
			const circuit = (JSON.parse(JSON.stringify(c))) as QuoteCircuitModel;
			circuit.days.forEach((d) => {
				d.id =  uuidv4();
				d.steps.forEach((s) => {
					s.id =  uuidv4();
					s.addresses.forEach((a) => {
						a.id = uuidv4();
					});
				});
			});
			return circuit;
		})
	}, [])

	useEffect(() => {
		setCircuit(circuitJson ? JSON.parse(circuitJson) : { days: [] });
		return function cleanUp() {
			setCircuit({ days: [] });
		}
	}, [])

	const [editMode, setEditMode] = useState(disabled !== true);
	const [expandMode, setExpandMode] = useState<boolean | undefined>(undefined);
	const [refresh, setRefresh] = useState(0);


	const days = useMemo(() => {
		return circuit.days;
	}, [circuit.days]);

	const maxSort = useMemo(() => {
		return days.length > 0 ? days.reduce((max, currentDay) => currentDay.sort > max ? currentDay.sort : max, days[0].sort) : -1;
	}, [circuit.days])

	const { fieldValue: departuredateValue } = useField({ name: 'departure_date' });
	const { fieldValue: departureAddressValue } = useField({ name: 'departure_address' });
	const { fieldValue: departureArrivalAddressValue } = useField({ name: 'departure_arrival_address' });
	const { fieldValue: departurePaxValue } = useField({ name: 'departure_pax' });

	const addCircuitDayEvent = useCallback((index?: number, duplicate?: boolean) => {

		const addDayJustAfter = (index !== undefined && !isNaN(index) && index !== maxSort);

		setCircuit((c) => {
			// Récupération de la dernière adresse
			let lastStep: QuoteCircuitDayStepModel | undefined;
			let lastAddress: QuoteCircuitDayStepAddressModel | undefined;
			if (c.days.length === 0) {
				// Premier jour du circuit, on ne fait rien
			} else {

				let lastDay: QuoteCircuitDayModel
				// Dernier jour du circuit
				if (index === undefined) {
					lastDay = c.days[c.days.length - 1];
				}
				// Jour ciblé
				else {
					lastDay = c.days[index];
				}

				if (lastDay?.steps.length > 0) {
					lastStep = lastDay?.steps[lastDay.steps.length - 1];
					if (lastStep?.addresses.length > 0) {
						lastAddress = {
							...lastStep.addresses[lastStep.addresses.length - 1],
							id: uuidv4() 
						};
					}
				}
			}

			const days = JSON.parse(JSON.stringify(c.days)) as QuoteCircuitDayModel[];

			let dayDate: string | undefined;

			//Premier jour du circuit et la date de départ est bien défini
			if (departuredateValue && (days.length == 0 || index === undefined)) {
				if (days.length > 0 && days[days.length - 1].date) {
					dayDate = dayjs(days[days.length - 1].date).tz().add(1, 'day').format("YYYY-MM-DD");
				} else {
					dayDate = dayjs(departuredateValue).tz().format("YYYY-MM-DD");
				}
			}
			//Le jour a été ajouté à la suite
			else if (index !== undefined && !isNaN(index) && days.length > index && days[index].date) {
				dayDate = dayjs(days[index].date).tz().add(1, 'day').format("YYYY-MM-DD")
			} else {
				dayDate = undefined;
			}

			let newDay: QuoteCircuitDayModel;
			if (index !== undefined && !isNaN(index) && duplicate) {
				newDay = JSON.parse(JSON.stringify(days[index])) as QuoteCircuitDayModel;
				newDay.sort = (addDayJustAfter) ? index + 0.5 : maxSort + 1;
				newDay.id = uuidv4();
				newDay.date = dayDate;
				newDay.steps?.forEach((step) => {
					step.id = uuidv4();
					step.addresses?.forEach((address) => {
						address.id = uuidv4();
					});
				});
			} else {
				if (days.length == 0) {
					newDay = {
						id: uuidv4(),
						date: dayDate,
						sort: 0,
						title: "",
						steps: [{
							id: uuidv4(),
							sort: 0,
							pax: departurePaxValue ?? 0,
							distance: 0,
							cars: 0,
							hour: departuredateValue ? dayjs(departuredateValue).tz().format("HH:mm") : '',
							addresses: [
								{
									id: uuidv4(),
									sort: 0,
									type: 3,//Ramassage
									address: departureAddressValue ?? '',
								},
								{
									id: uuidv4(),
									sort: 1,
									type: null,
									address: departureArrivalAddressValue ?? '',
								},
							],
						}]
					}
				} else {
					newDay = {
						id: uuidv4(),
						date: dayDate,
						sort: (addDayJustAfter) ? index + 0.5 : maxSort + 1,
						title: "",
						steps: lastAddress !== undefined ? [{
							
							/*
							hour: lastStep?.hour ?? '',
							pax: lastStep?.pax ?? 0,
							cars: lastStep?.cars ?? 0,
							distance: lastStep?.distance ?? 0,
							*/
							id: uuidv4(),
							hour:'',
							pax: 0,
							cars:  0,
							distance: 0,
							addresses: [
								{
									...lastAddress,
									type: 3 //Ramassage
								}
							],
							sort: 0
						}] : []
					}
				}


			}

			const newDays = [
				...JSON.parse(JSON.stringify(c.days)) as QuoteCircuitDayModel[],
				newDay
			];

			if (addDayJustAfter) {
				newDays.sort((a, b) => a.sort - b.sort);
				newDays.forEach((day, index) => {
					day.sort = index;
				});
			}

			return {
				...c,
				days: newDays
			};
		})
	}, [maxSort, departuredateValue, departureAddressValue, departureArrivalAddressValue]);

	const setConfirmModalState = useSetRecoilState(ConfirmModalState);
	const deleteCircuitDayEvent = useCallback((id: string) => {
		setConfirmModalState({
			open: true,
			title: "Suppression",
			desc: "Êtes-vous sûr de vouloir supprimer ce jour ?",
			validEvent: () => {
				setCircuit((c) => {
					let newDays = JSON.parse(JSON.stringify(c.days)) as QuoteCircuitDayModel[];
					newDays = newDays.filter((d) => d.id !== id);
					newDays.forEach((day, index) => {
						day.sort = index;
					});
					return {
						days: newDays
					}
				});
			}
		})
	}, []);


	const onDragEnd = useCallback((result: any) => {
		if (!result.destination) {
			return;
		}

		setCircuit((c) => {
			const newDays = JSON.parse(JSON.stringify(c.days)) as QuoteCircuitDayModel[];
			const startIndex = result.source.index;
			const endIndex = result.destination.index;
			// Trouver l'élément déplacé
			const [removed] = newDays.splice(startIndex, 1);
			// Insérer l'élément déplacé à sa nouvelle position
			newDays.splice(endIndex, 0, removed);
			// Mise à jour des valeurs de 'sort' pour refléter le nouvel ordre
			newDays.forEach((day, index) => {
				day.sort = index;
			});
			return { days: newDays };
		})
	}, []);

	const DaysContent = useMemo(() => {

		if (disabled || !editMode) {
			return <></>;
		}

		return (
			<Box>
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId="QuoteCircuit" direction="vertical">
						{(provided, snapshot) => (
							<Box {...provided.droppableProps} ref={provided.innerRef} display="flex" flexDirection={"column"} >
								{days.map((day, key) => (
									<Draggable key={day.id} draggableId={day.id} index={day.sort}>

										{(provided, snapshot) => (
											<QuoteCircuitDay
												id={day.id}
												provided={provided}
												addCircuitDayEvent={() => addCircuitDayEvent(day.sort)}
												duplicateCircuitDayEvent={() => addCircuitDayEvent(day.sort, true)}
												deleteEvent={() => deleteCircuitDayEvent(day.id)}
												isExpandDefault={expandMode}
											/>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</Box>
						)}
					</Droppable>
				</DragDropContext>
			</Box>
		)
	}, [disabled, editMode, days, onDragEnd, addCircuitDayEvent, deleteCircuitDayEvent, expandMode])

	return (
		<AddEditFormCard
			title={<Box display="flex" alignItems="center">
				<Box>{title ?? "Circuit"}</Box>
				<Box ml={2}>
					<IconButton color="primary" onClick={() => { setExpandMode(v => !v); setRefresh(r => r + 1); }}>
						{!expandMode ? <KeyboardDoubleArrowDownIcon /> : <KeyboardDoubleArrowUpIcon />}
					</IconButton>
				</Box>

				<Box ml={2}>
					<QuoteCircuitButtonFormUpdate disabled={disabled} entityName={entityName} />
				</Box>
				{circuitHasGuidDoublon &&
					<Box ml={2}>
						<Button variant="contained" color="error" onClick={() => circuitFixEvent()}>Fix Erreurs</Button>
					</Box>
				}
			</Box>
			}
			action={
				<Box display="flex">

					{editMode &&
						<Box mr={2}>
							<Button size="small" variant="contained" onClick={() => addCircuitDayEvent()}>Nouveau jour</Button>
						</Box>
					}

					{disabled !== true &&
						<Button size="small" variant="contained" color="success" onClick={() => setEditMode((e) => !e)}>{editMode ? "Aperçu" : "Modifier"}</Button>
					}
				</Box>
			}
			sx={{
				paper: {
					margin: 0
				}
			}}
			isExpand={true}
			withExpand={true}
			withExpandByTitle={false}
			key={`circuit_bloc_${refresh}`}
		>
			{ editMode && days.length == 0 && <Alert color="info">Le circuit est vide</Alert>}

			{DaysContent}

			{!editMode &&
				<QuoteCircuitPreview circuit={circuit} />
			}
		</AddEditFormCard>
	)
}

export default QuoteCircuit;