import { useCallback, useState } from "react";
import { ListExportButtonsModelCore, ListModelCore, ListSearchModelCore } from "../../classes/list/ListModel";
import { GridColDef } from "@mui/x-data-grid";
import { ReqStatus } from "../../stores/core/UseApi";
import { Box, Button, IconButton } from "@mui/material";
import ListServer from "../common/list/ListServer";
import { useGetSupplierOrderList } from "../../stores/SupplierOrdersStore";
import { useGetListCore } from "../../stores/core/StoreCore";
import { SupplierOrderListModel } from "../../classes/supplier-orders/SupplierOrderModel";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import ListCore from "../common/list/ListCore";
import { dateFormat } from "../../utils/DateUtils";

const columns: GridColDef[] = [
    {
        field: 'Suppliers.supplier_id',
        headerName: "Fournisseur",
        editable: false,
        filterable: false,
        minWidth: 100,
        flex: 1,
        valueGetter: ({ row }) => row?.supplier?.name
    },
    {
        field: 'reference',
        headerName: "Référence",
        editable: false,
        filterable: false,
        minWidth: 100,
        flex: 1
    },
    {
        field: 'Suppliers.received',
        headerName: "Statut",
        editable: false,
        filterable: false,
        minWidth: 100,
        flex: 1,
        valueGetter: ({ row }) => (row?.received ? 'Reçue' : 'Non reçue')
    },
    {
        field: 'order_date',
        headerName: "Date commande",
        editable: false,
        filterable: false,
        minWidth: 100,
        flex: 1,
        valueGetter: ({row}) => dateFormat(row.order_date)
    },
    {
        field: 'delivery_date',
        headerName: "Date réception",
        editable: false,
        filterable: false,
        minWidth: 100,
        flex: 1,
        valueGetter: ({row}) => dateFormat(row.delivery_date)
    }
];



const SupplierOrderList = () => {

    const routeController = "commercial/supplier-orders";
    const [get] = useGetListCore<SupplierOrderListModel>(`/${routeController}/index`);

    return (
        <ListCore<SupplierOrderListModel, any>
            getDatas={get}
            defineDatasToSet={(data) => data.supplierOrders}
            getRowId={(row) => row.id}
            columns={columns}
            actions={{
                defaultRoute: routeController,
                add: {
                    title: "Ajouter une commande fournisseur"
                },
                edit: true,
                remove: {
                    enable:true,
                    
                    getTextModal: (row) => `Êtes-vous sûr de vouloir supprimer la commande ${row.reference} du fournisseur ${row.supplier?.name} ?`
                }
            }}
        />
    );
}

export default SupplierOrderList;