import { Container, Modal } from "@mui/material";
import { FormProvider } from "react-recoil-form";
import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { FolderReminderModalState } from "../../states/FolderReminderState";
import FolderReminderForm from "./FolderReminderForm";

const FolderReminderModal = () => {

    const [modalState, setModalState] = useRecoilState(FolderReminderModalState);

    const handleClose = useCallback(() => {
        setModalState((a) => {
            return { ...a, open: false };
        });
    }, [setModalState]);


    const handleValidate = useCallback(() => {
        modalState.onValidate();
        setModalState((a) => {
            return { ...a, open: false };
        });
    }, [modalState, setModalState]);


    return (
        <Modal
            open={modalState.open}
            aria-labelledby="folder-reminder-modal"
            sx={{
                overflow: 'auto'
            }}
        >
            <Container component="main" maxWidth="sm" sx={{ height: "100%", py: 2 }}>
                <FormProvider options={{ formId: "folderReminderForm", skipRecoilRoot: true }}>
                    <FolderReminderForm
                        id={modalState.id}
                        onClose={handleClose}
                        onValidate={handleValidate}
                    />
					
                </FormProvider>
            </Container>
        </Modal>
    );
}

export default FolderReminderModal;