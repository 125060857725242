import { atom } from "recoil";

export const WaitingModalState = atom<{ open: boolean, text: string}>({
    key: 'WaitingModalKey',
    default: {
        open: false,
        text: ""
    }
});

export const ConfirmModalState = atom<{ open: boolean, title: string, desc: string, validEvent: () => void}>({
    key: 'ConfirmModalKey',
    default: {
        open: false,
        title: "",
        desc: "",
        validEvent: () => {}
    }
});