
import { useCallback, useEffect, useState } from "react";
import { useDeleteUser, useGetUsersList } from "../../stores/UsersStore";
import { Box, Button, IconButton } from "@mui/material";
import { ReqStatus } from "../../stores/core/UseApi";
import { GridColDef } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ListServer from "../common/list/ListServer";
import { UserModel } from "../../classes/users/UserModel";
import { ListSearchModelCore } from "../../classes/list/ListModel";
import { useNavigate } from "react-router";
import ListCore from "../common/list/ListCore";
import { UserListModel } from "../../classes/users/UserListModel";
import { useGetListCore } from "../../stores/core/StoreCore";

const columns: GridColDef[] = [
    {
        field: 'email',
        headerName: "Email",
        editable: false,
        filterable: false,
        minWidth: 100,
        flex: 1,
    },
    {
        field: 'last_name',
        headerName: "Nom",
        editable: false,
        filterable: false,
        minWidth: 100,
        flex: 1,
    },
    {
        field: 'first_name',
        headerName: "Prénom",
        editable: false,
        filterable: false,
        minWidth: 100,
        flex: 1,
    },
    {
        field: 'user_group_name',
        headerName: "Rôle",
        editable: false,
        filterable: false,
        minWidth: 100,
        flex: 1,
        valueGetter: ({ row }) => row.user_group.name
    }
];

const UserList = () => {
    const routeController = "users";
    const [get] = useGetListCore<UserListModel>(`/${routeController}/index`);

    return (
        <ListCore<UserListModel, any>
            getDatas={get}
            defineDatasToSet={(data) => data.users}
            getRowId={(row) => row.id}
            columns={columns}
            actions={{
                defaultRoute: routeController,
                add: {
                    title: "Ajouter un utilisateur"
                },
                edit: true,
                remove: {
                    enable:true,
                    getTextModal: (row) => `Êtes-vous sûr de vouloir supprimer l'utilisateur ${row.email} ?`
                }
            }}
        />
    );
}

export default UserList;

