import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import InputField from "../../../components/common/form/InputField";
import NumberField from "../../../components/common/form/NumberField";
import DateField from "../../../components/common/form/DateField";
import MovingIcon from '@mui/icons-material/Moving';
import { useCallback, useMemo, useState, useEffect } from "react";
import { useField } from "react-recoil-form";
import { transformStringToSlug } from "../../utils/StringUtils";
import CopyButton from "../../../components/common/button/CopyButton";
import TomTomAddressField from "../tomtom/TomTomAddressField";
import TomTomCalculateRoute from "../tomtom/TomTomCalculateRoute";

interface QuoteTravelDetailsProps {
    prefix: "departure" | "return",
    disabled?: boolean,
    minDate?: Date
}
const QuoteTravelDetails = (props: QuoteTravelDetailsProps) => {

    const { prefix, disabled, minDate } = props;
    const title = useMemo(() => {
        return "Détails " + (prefix === "departure" ? "de l'aller" : "du retour");
    }, [prefix])

    const subtitle = useMemo(() => {
        return `Indiquez les détails ${(prefix === "departure" ? "de l'aller" : "du retour")}, la date de départ, l'adresse de départ et d'arrivée`;
    }, [prefix])

    const { fieldValue: addressValue } = useField({ name: `${prefix}_address` });
    const { fieldValue: arrivalAddressValue } = useField({ name: `${prefix}_arrival_address` });
    const { fieldValue: addressGeolocValue } = useField({ name: `${prefix}_address_geoloc` });
    const { fieldValue: arrivalAddressGeolocValue } = useField({ name: `${prefix}_arrival_address_geoloc` });

    const coords = useMemo(() => {
        if (!addressGeolocValue || !arrivalAddressGeolocValue) {
            return [];
        }

        return [
            addressGeolocValue,
            arrivalAddressGeolocValue
        ]
    }, [addressGeolocValue, arrivalAddressGeolocValue])

    return (
        <Box my={1}>
            <Box><Typography variant="h6">{title}</Typography></Box>
            <Box mb={1}><Typography variant="subtitle2">{subtitle}</Typography></Box>


            <Grid container spacing={3} >

                <Grid item xs={6} sm={3} display="flex" justifyContent={"space-between"}>
                    <DateField
                        name={`${prefix}_date`}
                        label="Date de départ"
                        type="datetime"
                        disabled={disabled}
                        minDate={minDate}
                    />
                </Grid>
                <Grid item xs={12} sm={9} display="flex" justifyContent={"space-between"}>
                    <TomTomAddressField
                        name={`${prefix}_address`}
                        label="Point de départ"
                        disabled={disabled}
                        required
                    />
                </Grid>

                <Grid item xs={6} sm={3} display="flex" justifyContent={"space-between"}>
                    <NumberField
                        name={`${prefix}_pax`}
                        label="Pax"
                        required
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12} sm={9} display="flex" justifyContent={"space-between"}>
                    <TomTomAddressField
                        name={`${prefix}_arrival_address`}
                        label="Point d'arrivée"
                        disabled={disabled}
                        required
                    />
                </Grid>
            </Grid>

            <TomTomCalculateRoute
                address={addressValue}
                arrivalAddress={arrivalAddressValue}
                coords={coords}
                disabled={disabled}
            />
        </Box>
    )
}
export default QuoteTravelDetails;