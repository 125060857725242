import { Container, Modal } from "@mui/material";
import { FormProvider } from "react-recoil-form";
import { useCallback } from "react";
import { useRecoilState } from "recoil";
import FolderOrderSupplierPaymentForm from "./FolderOrderSupplierPaymentForm";
import { FolderOrderSupplierPaymentModalState } from "../../states/FolderOrderSupplierPaymentState";

const FolderOrderSupplierPaymentModal = () => {

    const [modalState, setModalState] = useRecoilState(FolderOrderSupplierPaymentModalState);

    const handleClose = useCallback(() => {
        setModalState((a) => {
            return { ...a, open: false };
        });
    }, [setModalState]);


    const handleValidate = useCallback(() => {
        modalState.onValidate();
        setModalState((a) => {
            return { ...a, open: false };
        });
    }, [modalState, setModalState]);


    return (
        <Modal
            open={modalState.open}
            aria-labelledby="folder-order-supplier-payment-modal"
            sx={{
                overflow: 'auto'
            }}
        >
            <Container component="main" maxWidth="md" sx={{ height: "100%", py: 2 }}>
                <FormProvider options={{ formId: "folderOrderSupplierPaymentForm", skipRecoilRoot: true }}>
                    
                    <FolderOrderSupplierPaymentForm
                        id={modalState.id}
                        onClose={handleClose}
                        onValidate={handleValidate}
                    />
					
                </FormProvider>
            </Container>
        </Modal>
    );
}

export default FolderOrderSupplierPaymentModal;