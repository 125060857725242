import { GridColDef } from "@mui/x-data-grid";
import { ListModelCore } from "../../../classes/list/ListModel";
import { useGetListCore } from "../../../stores/core/StoreCore";
import ListCore from "../../../components/common/list/ListCore";
import { Box, Chip } from "@mui/material";

const columns: GridColDef[] = [
    {
        field: 'id',
        headerName: "Id",
        editable: false,
        filterable: false,
        width: 80,
        valueGetter: ({ row }) => `#${row.id}`
    },
    {
        field: 'name',
        headerName: "Nom",
        editable: false,
        filterable: false,
        width: 250,
        renderCell: ({row}) => (
            <Box>
                <Box>{row.name}</Box>
                <Box>{row.dahan_siret}</Box>
            </Box>
        )
    },
    {
        field: 'email',
        headerName: "Emails",
        editable: false,
        filterable: false,
        width: 250,
        renderCell: ({ row }) => (
            <Box>
                {row?.email}
                {row?.dahan_emails_compl?.length > 0 && <br />}
                {row?.dahan_emails_compl}
            </Box>
        )
    },
    {
        field: 'phone',
        headerName: "Téléphones",
        editable: false,
        filterable: false,
        width: 250,
        renderCell: ({ row }) => (
            <Box>
                {row?.phone}
                {row?.dahan_phones_compl?.length > 0 && <br />}
                {row?.dahan_phones_compl}
            </Box>
        )
    },
    {
        field: 'dahan_phone_astreinte',
        headerName: "Tél astreinte",
        editable: false,
        filterable: false,
        width: 100,
    },
    {
        field: 'address',
        headerName: "Adresse",
        editable: false,
        filterable: false,
        width: 350,
        valueGetter: ({ row }) => row?.address
    },
    {
        field: 'dahan_categories',
        headerName: "Catégories",
        editable: false,
        filterable: false,
        width: 200,
        valueGetter: ({ row }) => row?.dahan_categories?.replace(";", " ")
    },
    {
        field: 'active',
        headerName: "Actif",
        editable: false,
        filterable: false,
        width: 80,
        renderCell: ({ row }) => row.active ? <Chip color="success" label="Oui" /> : <Chip color="default" label="Non" />
    },
    {
        field: 'dahan_frais',
        headerName: "Frais",
        editable: false,
        filterable: false,
        width: 80,
        renderCell: ({ row }) => row.dahan_frais ? <Chip color="primary" label="Oui" /> : <Chip color="default" label="Non" />
    }
];

export interface SupplierListModel extends ListModelCore {
    suppliers: any[]
}

const SupplierCustomList = () => {

    const routeController = "commercial/suppliers";
    const [get] = useGetListCore<SupplierListModel>(`/${routeController}/index`);

    return (
        <ListCore<SupplierListModel, any>
            getDatas={get}
            defineDatasToSet={(data) => data.suppliers}
            getRowId={(row) => row.id}
            columns={columns}
            actions={{
                defaultRoute: routeController,
                add: {
                    title: "Ajouter un fournisseur"
                },
                edit: true,
                remove: {
                    enable:false,    
                    getTextModal: (row) => `Êtes-vous sûr de vouloir supprimer le fournisseur ${row.name} ?`
                }
            }}
        />
    );
}

export default SupplierCustomList;