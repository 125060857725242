import { Box, Button } from "@mui/material";
import axios from 'axios';
import { useCallback, useMemo, useState } from "react";
import { transformStringToSlug } from "../../utils/StringUtils";

export const openMappyEvent = (addressStart: string, addressEnd: string) => {
	// Encoder les adresses pour l'URL
	const encodedStart = encodeURIComponent(addressStart);
	const encodedEnd = encodeURIComponent(addressEnd);

	// Construire l'URL de Mappy
	const url = `https://fr.mappy.com/itineraire#/recherche/${encodedStart}/${encodedEnd}`;

	// Ouvrir l'URL dans un nouvel onglet
	window.open(url, '_blank');
};

interface TomTomCalculateRouteProps {
	address: string,
	arrivalAddress: string,
	disabled?: boolean,
	coords: string[]
}
const TomTomCalculateRoute = (props: TomTomCalculateRouteProps) => {

	const { coords, disabled, address, arrivalAddress } = props;
	const [duration, setDuration] = useState(0);
	const [distance, setDistance] = useState(0);
	const [loading, setLoading] = useState(false);
	const apiKey = process.env.REACT_APP_TOMTOM_API_KEY;

	const coordsString = useMemo(() => {
		if (coords.length < 2) {
			return "";
		}
		return coords.join(':');
	}, [coords])

	const calculateRouteEvent = () => {

		if (!apiKey) {
			alert("La clé d'api n'est pas défini");
			return;
		}

		if (!coordsString) { return; }

		setLoading(true);
		axios.get(`https://api.tomtom.com/routing/1/calculateRoute/${coordsString}/json?routeRepresentation=summaryOnly&travelMode=bus&vehicleNumberOfAxles=2&vehicleEngineType=combustion&key=${apiKey}&vehicleLength=10&vehicleWidth=3&vehicleHeight=3.8&vehicleWeight=20000&vehicleMaxSpeed=87`, {
			responseType: "json",
		})
			.then(function (response) {

				if (Array.isArray(response.data.routes) && response.data.routes.length > 0) {
					setDistance(response.data.routes[0].summary.lengthInMeters);
					setDuration(response.data.routes[0].summary.travelTimeInSeconds)
				} else {
					setDistance(0);
					setDuration(0)
				}
			})
			.catch(function (error) {
				console.log(error);
			}).finally(() => {
				setLoading(false);
			});
	}

	const distanceKms = useMemo(() => {
		if (!distance) { return ""; }
		return `${Math.round(distance / 1000)}`;
	}, [distance]);

	const durationHHMM = useMemo(() => {
		if (!duration) { return ""; }

		const hours = Math.floor(duration / 3600); // Convertit les secondes en heures
		const minutes = Math.floor((duration % 3600) / 60); // Convertit le reste en minutes

		// Retourne les heures et les minutes formatées
		return `${hours}h ${minutes}m`;
	}, [duration]);

	const openDistanceToEvent = useCallback(() => {
        window.open(`https://fr.distance.to/${transformStringToSlug(address)}/${transformStringToSlug(arrivalAddress)}`, "_blank");
    }, [address, arrivalAddress]);

	const openMappyInternalEvent = useCallback(() => {
        openMappyEvent(address, arrivalAddress)
    }, [address, arrivalAddress]);	

	return (
		<Box display="flex" flexDirection="column" alignItems="center">
			<Box m={2} display="flex">
				<Box >
					<Button variant="contained" color="info" onClick={() => openDistanceToEvent()}>Distance.to</Button>
				</Box>

				<Box ml={2}>
					<Button variant="contained" color="secondary" onClick={() => openMappyInternalEvent()}>Mappy</Button>
				</Box>

				<Box ml={2}>
					<Button disabled={coords.length < 2 || loading || disabled} variant="contained" color="success" onClick={() => calculateRouteEvent()}>TOMTOM</Button>
				</Box>
			</Box>
			{distanceKms && durationHHMM &&
				<Box display="flex">
					Temps de trajet de
					<Box mx={1} sx={{ color: "blue" }}>{distanceKms} km</Box>
					pour une distance de
					<Box mx={1} sx={{ color: "blue" }}>{durationHHMM}</Box>
				</Box>
			}
		</Box>
	)
}

export default TomTomCalculateRoute;