import { SelectOptionsProps } from "../../components/common/form/SelectField";

export interface FolderModel {
    id?: number,
    customer_id: number,
    commercial_id: number,
    departure_date: string,
    return_date: string,
    departure_place: string,
    return_place: string,
    origin: number,
    status: number,
    comment: string,
    comment_commercial: string,
    title: string,
    webhook_origin : string,
    is_closure: boolean,
    closure_pattern: string,
}

export const folderTypeJourneyOptions : SelectOptionsProps[] = [
	{ value: 1, label: "Aller" },
	{ value: 2, label: "Aller / Retour" },
	{ value: 3, label: "Circuit" },
];

export const folderPaxOptions : SelectOptionsProps[] = [
	{ value: 1, label: "de 10 à 20 Personnes" },
	{ value: 2, label: "de 20 à 50 Personnes" },
	{ value: 3, label: "de 50 à 60 Personnes" },
	{ value: 4, label: "de 60 à 70 Personnes" },
	{ value: 5, label: "de 70 à 90 Personnes" },
	{ value: 6, label: "+ de 100 Personnes" },
];

export const getlabelOfFolderPax = (id: number) => {
	if(isNaN(id)){ return ""; }
	return folderPaxOptions.find( x => x.value === id)?.label ?? "";
}

export const getlabelOfFolderTypeJourney = (id: number) => {
	if(isNaN(id)){ return ""; }
	return folderTypeJourneyOptions.find( x => x.value === id)?.label ?? "";
}

export enum folderOriginEnum {
	DIRECT = 1,
	LEAD = 2,
	MAIL = 3,
	PRESTABUS = 4
}

export const getLabelOfFolderOrigin = (id: number) => {
	if(isNaN(id)){ return ""; }
	return folderOriginEnum[id];
}

export const FolderClosurePatterns = [
	"Faux numéro",
	"1 ou 2 pax",
	"Non joint (en attente de contact)",
	"Projet abandonné",
	"A signé avec autre compagnie",
	"Prix (trop cher)",
	"Non joint (avec devis en cours)",
	"Autre solution (voiture, train...)",
	"Doublon",
	"Pas de disponibilité"
]

export enum  FolderTabEnum{
    NOTES,
    QUOTES,
    PROFORMA,
	PAYMENTS,
	TRAVEL_INFO,
	INVOICES,
	MARGINS
}









