import { Box, Button, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { useDelete } from "../../../stores/core/StoreCore";
import { ReqStatus } from "../../../stores/core/UseApi";
import { useSetRecoilState } from "recoil";
import { ConfirmModalState } from "../../../states/ModalState";

interface ListItemActionsProps {
    defaultRoute: string,
    edit?: {
        route?: string,
        enable: boolean,
        icon?: boolean,
    },
    remove?: {
        route?: string,
        enable: boolean,
        icon?: boolean,
        getTextModal? : (row: any) => string,
        callback: () => void,
    },
    others?: (row: any) => JSX.Element,
    othersLeft?: (row: any) => JSX.Element,
    row: any,
    id: string | number
}

const ListItemRemove = (props: { route: string, id: number | string, callback: () => void, icon?: boolean, textModal?: string }) => {

    const { route, id, icon, textModal, callback } = props;
    const deleteAjax = useDelete(route);
    const setConfirmModalState = useSetRecoilState(ConfirmModalState);

    const removeItem = useCallback((id: number | string) => {
        if (!id) { return; }

        setConfirmModalState({
            open: true,
            title: "Suppression",
            desc: ( textModal ? textModal : "Êtes-vous sûr de vouloir supprimer cette donnée ?"),
            validEvent: () => {
                deleteAjax(id).then((res) => {
                    if (res.status === ReqStatus.SUCCESS && res.data) {
                        callback();
                    }
                });
            }
        })
    }, [deleteAjax, callback, setConfirmModalState]);

    return (
        <Box sx={{mr:1}}>
            {icon ?
                <IconButton color="error" onClick={() => removeItem(id)} ><DeleteIcon /></IconButton> :
                <Button variant="contained" color="error" onClick={() => removeItem(id)} >Supprimer</Button>
            }
        </Box>
    );
}

const ListItemActions = (props: ListItemActionsProps) => {
    const navigate = useNavigate();

    const { defaultRoute, edit, remove, id, row, others, othersLeft } = props;

    return (
        <Box display="flex" justifyContent={"flex-start"} sx={{ width: "100%" }}>

            {othersLeft && othersLeft(row)}
            {edit && edit.enable && !edit.icon &&
                <Button sx={{mr:1}} variant="contained" color="info" onClick={() => navigate(`/${edit.route ? edit.route : defaultRoute}/add-edit/${id}`)}>Modifier</Button>
            }
            {edit && edit.enable && edit.icon &&
                <IconButton sx={{mr:1}} color="primary" ><EditIcon onClick={() => navigate(`/${edit.route ? edit.route : defaultRoute}/add-edit/${id}`)} /></IconButton>
            }

            {remove && remove.enable &&
                <ListItemRemove
                    route={remove.route ? remove.route : defaultRoute}
                    id={id}
                    callback={remove.callback}
                    icon={remove.icon}
                    textModal={remove.getTextModal ? remove.getTextModal(row) : ""}
                />
            }
            {others && others(row)}
        </Box>
    )
};

export default ListItemActions;
