import { GridColDef } from "@mui/x-data-grid";
import { Box, Button, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { useGetListCore } from "../../../stores/core/StoreCore";
import ListCore from "../../../components/common/list/ListCore";
import { CircuitTypeListModel } from "../../classes/CircuitTypeModel";

const columns: GridColDef[] = [
    {
        field: 'id',
        headerName: "Id",
        width: 100,
    },
    {
        field: 'name',
        headerName: "Nom",
        width: 300,
    }
];


const CircuitTypeList = () => {

    const routeController = "project-dahan/dahan/circuit-types";
    const [get] = useGetListCore<CircuitTypeListModel>(`${routeController}/index`);

    return (
        <ListCore<CircuitTypeListModel, any>
            getDatas={get}
            defineDatasToSet={(data) => data.circuitTypes}
            getRowId={(row) => row.id}
            columns={columns}
            actions={{
                defaultRoute: 'circuit-types',
                add: {
                    title: "Ajouter un type"
                },
                edit: true,
                remove: false
            }}
        />
    );
}

export default CircuitTypeList;