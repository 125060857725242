import { GridColDef } from "@mui/x-data-grid";
import { Box, Button, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { useGetListCore } from "../../stores/core/StoreCore";
import ListCore from "../common/list/ListCore";
import { StatusTypeListModel } from "../../classes/status-types/StatusTypeModel";

const columns: GridColDef[] = [
    {
        field: 'label',
        headerName: "Nom",
        minWidth: 100,
        flex: 1,
    }
];


const StatusTypeList = () => {

    const routeController = "commercial/status-types";
    const [get] = useGetListCore<StatusTypeListModel>(`${routeController}/index`);

    return (
        <ListCore<StatusTypeListModel, any>
            getDatas={get}
            defineDatasToSet={(data) => data.statusTypes}
            getRowId={(row) => row.id}
            columns={columns}
            actions={{
                defaultRoute: routeController,
                edit: true,
                remove: false
            }}
        />
    );
}

export default StatusTypeList;