import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ReqStatus } from "../../stores/core/UseApi";
import { useAddEditForm } from "../common/form/AddEditFormPanel";
import { AutocompleteOption } from "../common/form/AutocompleteField";
import { useSetRecoilState } from "recoil";
import { NavigateState } from "../../states/MenuState";
import { useAddEditCore } from "../../stores/core/StoreCore";
import { SelectOptionsProps } from "../common/form/SelectField";
import { createOptionsForAutocomplete, createOptionsForSelect, mapArrayForForm } from "../../utils/DataUtils";
import OrderTotalView from "./OrderTotalView";
import { useOrderCalcul, useOrderCalculLines, useOrderShippingCalcul } from "./OrderCalcul";
import OrderProductsView, { OrderProductsFieldNames } from "./OrderProductsView";
import OrderPaymentsView from "./OrderPaymentsView";
import OrderShippingView from "./OrderShippingView";
import OrderBillsView from "./OrderBillsView";
import AddEditForm from "../common/form/AddEditForm";
import AddEditFormCard from "../common/form/AddEditFormCard";
import OrderTotalInfos from "./OrderTotalInfo";
import { StatusHistoryItem } from "./OrderHistory";
import OrderHeader from "./OrderHeader";

export interface OrderResponse {
    order: any,
    customers: { [id: string]: string },
    products: { [id: string]: string },
    products_enabled?: { [id: string]: string },
    statusLists: {
        orders: {
            order: { [id: string]: string },
            payment: { [id: string]: string },
            shipping: { [id: string]: string }
        },
        payment_methods: { [id: string]: { [id: string]: string } },
        shipping_methods: { [id: string]: { [id: string]: string } }
    },
    paymentMethods: { [id: string]: string },
    shippingMethods: { [id: string]: string },
    bills: any[],
    billed: boolean
}

export const initOrderTotalAdditional = (id: string | undefined, order: any) => {
    return {
        total_tax_excl_plus_shipping_tax_excl: id ? (order.total_minus_discount_tax_excl + order.shipping_tax_excl) : 0,
        total_tax_plus_shipping_tax: id ? (order.total_minus_discount_tax + order.shipping_tax) : 0,
        total_tax_incl_plus_shipping_tax_incl: id ? (order.total_minus_discount_tax_incl + order.shipping_tax_incl) : 0,
    }
}

export const initOrderTotal = (id: string | undefined, order: any) => {
    const totalAddiontional = initOrderTotalAdditional(id, order);
    return {
        total_tax_excl: id ? order.total_tax_excl : 0,
        total_tax: id ? order.total_tax : 0,
        total_tax_incl: id ? order.total_tax_incl : 0,
        discount_percent: id ? order.discount_percent : 0,
        discount_tax_excl: id ? order.discount_tax_excl : 0,
        discount_tax: id ? order.discount_tax : 0,
        discount_tax_incl: id ? order.discount_tax_incl : 0,
        total_minus_discount_tax_excl: id ? order.total_minus_discount_tax_excl : 0,
        total_minus_discount_tax: id ? order.total_minus_discount_tax : 0,
        total_minus_discount_tax_incl: id ? order.total_minus_discount_tax_incl : 0,
        shipping_vat: id ? order.shipping_vat : 0,
        shipping_tax_excl: id ? order.shipping_tax_excl : 0,
        shipping_tax: id ? order.shipping_tax : 0,
        shipping_tax_incl: id ? order.shipping_tax_incl : 0,
        ...totalAddiontional
    }
}

export const useOrderView = (
    orderProductsCustomFieldNames?: string[],
    customFields?: string[]
) => {
    const { id } = useParams();
    const [ready, setReady] = useState(false);

    const setNavigateState = useSetRecoilState(NavigateState);
    const listRoute = "/commercial/orders";

    useOrderCalculLines(ready, orderProductsCustomFieldNames ? orderProductsCustomFieldNames : OrderProductsFieldNames, "HT");
    useOrderShippingCalcul(ready);
    useOrderCalcul(ready);

    const { getData, addEditData } = useAddEditCore<any, OrderResponse>(`${listRoute}/add-edit`);
    const [customers, setCustomers] = useState<AutocompleteOption[]>([]);
    const [products, setProducts] = useState<AutocompleteOption[]>([]);

    //Status lié à l'entête de commande
    const [orderStatus, setOrderStatus] = useState<SelectOptionsProps[]>([]);
    const [paymentStatus, setPaymentStatus] = useState<SelectOptionsProps[]>([]);
    const [shippingStatus, setShippingStatus] = useState<SelectOptionsProps[]>([]);

    //Section Paiement
    const [paymentMethods, setPaymentMethods] = useState<SelectOptionsProps[]>([]);
    const [methodPaymentStatus, setMethodPaymentStatus] = useState<{ id: string, options: SelectOptionsProps[] }[]>([]);


    //Section Livraison
    const [shippingMethods, setShippingMethods] = useState<SelectOptionsProps[]>([]);
    const [methodShippingStatus, setMethodShippingStatus] = useState<{ id: string, options: SelectOptionsProps[] }[]>([]);

    //Section Historique
    const [historyStatus, setHistoryStatus] = useState<StatusHistoryItem[]>([]);
    const [shippingHistoryStatus, setShippingHistoryStatus] = useState<any[]>([]);
    const [paymentsHistoryStatus, setPaymentHistoryStatus] = useState<any[]>([]);

    const [billed, setBilled] = useState(false);
    const [bills, setBills] = useState<any[]>([]);
    const [creditNotes, setCreditNotes] = useState<any[]>([]);

    const onSubmit = (request: any) => {
        let _request = JSON.parse(JSON.stringify(request)) as any;

        addEditData(_request, id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                setNavigateState(listRoute);
            }
        });
    }

    const { handleSubmit, resetInitialValues, isDirty } = useAddEditForm({
        onSubmit
    });

    useEffect(() => {
        getData(id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {

                setCustomers(createOptionsForAutocomplete(res.data.customers));
                setProducts(createOptionsForAutocomplete(res.data.products));
                const _statusLists = res.data.statusLists;
                if (_statusLists) {
                    if (_statusLists.orders) {
                        setOrderStatus(createOptionsForSelect(_statusLists.orders.order));
                        setPaymentStatus(createOptionsForSelect(_statusLists.orders.payment));
                        setShippingStatus(createOptionsForSelect(_statusLists.orders.shipping));
                    }

                    if (_statusLists.payment_methods) {
                        let _methodPaymentStatusList: { id: string, options: SelectOptionsProps[] }[] = [];
                        Object.entries(_statusLists.payment_methods).forEach(([key, value]) => {
                            _methodPaymentStatusList.push({
                                id: key,
                                options: createOptionsForSelect(value)
                            })
                        });
                        setMethodPaymentStatus(_methodPaymentStatusList);
                    }

                    if (_statusLists.shipping_methods) {
                        let _methodShippingStatusList: { id: string, options: SelectOptionsProps[] }[] = [];
                        Object.entries(_statusLists.shipping_methods).forEach(([key, value]) => {
                            _methodShippingStatusList.push({
                                id: key,
                                options: createOptionsForSelect(value)
                            })
                        });
                        setMethodShippingStatus(_methodShippingStatusList);
                    }
                }
                setPaymentMethods(createOptionsForSelect(res.data.paymentMethods));
                setShippingMethods(createOptionsForSelect(res.data.shippingMethods));
                setCreditNotes(res.data.order.credit_notes ?? []);
                setBills(res.data.order.bills ?? []);
                setBilled(res.data.billed);

                //Historique
                setHistoryStatus(res.data.order.status_history as StatusHistoryItem[]);
                setShippingHistoryStatus(res.data.order.shippings);
                setPaymentHistoryStatus(res.data.order.payments);

                const _order = res.data.order;

                let _initialValues : any = {

                    reference: id ? _order.reference : "",
                    customer_id: id ? _order.customer_id : undefined,
                    billing_address_id: id ? _order.billing_address_id : undefined,
                    shipping_address_id: id ? _order.shipping_address_id : undefined,
                    order_status_id: id ? _order.order_status_id : undefined,
                    payment_status_id: id ? _order.payment_status_id : undefined,
                    shipping_status_id: id ? _order.shipping_status_id : undefined,

                    ...initOrderTotal(id, _order),

                    ...(id ? mapArrayForForm(
                        res.data.order.products,
                        "products",
                        (p: any) => {
                            const _p = {
                                id: p._joinData.product_id,
                                _joinData: p._joinData
                            };

                            delete p._joinData.id;
                            delete p._joinData.order_id;

                            return _p;
                        }
                    ) : {}),

                    ...(id ? mapArrayForForm(
                        res.data.order.shippings,
                        "shippings",
                        (s: any) => ({
                            id: s.id,
                            shipping_method_id: s.shipping_method_id,
                            status_id: s.status_id,
                            reference: s.reference,
                            amount_tax_excl: s.amount_tax_excl,
                            comment: s.comment,
                            created: s.created,
                        })
                    ) : {}),

                    ...(id ? mapArrayForForm(
                        res.data.order.payments,
                        "payments",
                        (p: any) => ({
                            id: p.id,
                            order_id: p.order_id,
                            payment_method_id: p.payment_method_id,
                            status_id: p.status_id,
                            reference: p.reference,
                            total_amount: p.total_amount,
                            comment: p.comment,
                            created: p.created,
                        })
                    ) : {}),

                    addElements: "1"
                };

                customFields?.forEach((customField) => {
                    _initialValues[customField] = _order[customField];
                })

                resetInitialValues(_initialValues);

                setReady(true);
            } else {
                setNavigateState(listRoute);
            }
        });
    }, [id]);

    return {
        form: {
            resetInitialValues,
            handleSubmit,
            isDirty
        },
        datas: {
            customers,
            products,

            orderStatus,
            paymentStatus,
            shippingStatus,

            paymentMethods,
            methodPaymentStatus,

            shippingMethods,
            methodShippingStatus,

            billed,
            bills,
            creditNotes
        }

    };
}

const OrderView = () => {
    const { id } = useParams();
    const { form, datas } = useOrderView();

    return (
        <AddEditForm formId="orderForm" formHandleCancel={form.resetInitialValues} formHandleSubmit={form.handleSubmit} isDirty={form.isDirty}>
            <AddEditFormCard title={`${(!id ? "Ajouter " : "Modifier")} une commande`} withExpand>
                <OrderHeader
                    customers={datas.customers}
                    orderStatus={datas.orderStatus}
                    paymentStatus={datas.paymentStatus}
                    shippingStatus={datas.shippingStatus}
                />
            </AddEditFormCard>

            <AddEditFormCard title="Produits" withExpand>
                <OrderProductsView products={datas.products} />
            </AddEditFormCard>

            <AddEditFormCard title="Livraison" withExpand>
                <OrderShippingView shippingMethods={datas.shippingMethods} methodShippingStatus={datas.methodShippingStatus} />
            </AddEditFormCard>


            {datas.billed && <OrderTotalInfos discount shipping />}
            {!datas.billed && <OrderTotalView discount shipping />}


            <AddEditFormCard title="Paiements" withExpand>
                <OrderPaymentsView paymentMethods={datas.paymentMethods} methodPaymentStatus={datas.methodPaymentStatus} />
            </AddEditFormCard>

            {id &&
                <AddEditFormCard title="Facture / Avoirs" withExpand>
                    <OrderBillsView id={id} billed={datas.billed} bills={datas.bills} creditNotes={datas.creditNotes} sendPdf={true} />
                </AddEditFormCard>

            }

            {/*
            {id && <OrderHistory statusHistory={historyStatus} shippingStatusHistory={shippingHistoryStatus} paymentStatusHistory={paymentsHistoryStatus} />}
          */}
        </AddEditForm >
    );
}

export default OrderView;
