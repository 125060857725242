import { useMemo } from "react";
import { brevoEventTypes } from "../../classes/BrevoApiModel";

const MailHistoryEventTypeCell = (props: { row: any }) => {
    const { row } = props;

    const eventType = useMemo(() => row.event.toLowerCase(), [row]);
    const matchedEvent = useMemo(() =>
        brevoEventTypes.find(event => event.code === eventType)
    , [brevoEventTypes, eventType]);

    return (
        <div style={{
            backgroundColor: matchedEvent?.backgroundColor  ?? 'gray',
            borderRadius: '30px',
            padding: '5px 15px',
            color: 'white'
        }}>
            {matchedEvent?.label ?? eventType}
        </div>
    );
}

export default MailHistoryEventTypeCell;