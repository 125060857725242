import { useCallback } from "react";
import { ListModelCore } from "../classes/list/ListModel";
import {  useApi } from "./core/UseApi";
import { useGetListCore } from "./core/StoreCore";

const controllerRoute = 'commercial/supplier-orders';

interface SupplierOrderListModel extends ListModelCore{
    supplierOrders: any[]
}
export const useGetSupplierOrderList = () => {
    return useGetListCore<SupplierOrderListModel>(`${controllerRoute}/index`);
};

interface getProductsOfSupplierResponse{
    productsSuppliers: { [id: string]: string };
}
export const useGetProductsOfSupplier = () => {
    const [callCore, status] = useApi<getProductsOfSupplierResponse, undefined>(
        {
            route:  `${controllerRoute}/get-products-suppliers`,
            instanceAxiosMethod: "GET"
        }
    );
    const call = useCallback((id? : number) => {
        return callCore( undefined, `/${id ?? ""}` );
    }, [callCore]);
    return call;
};