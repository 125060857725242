import React from 'react';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow, useMediaQuery, useTheme, Box, Typography } from "@mui/material";
import InputField from "../common/form/InputField";
import NumberField from "../common/form/NumberField";
import AddEditFormCard from "../common/form/AddEditFormCard";

interface OrderTotalViewProps {
    title?: string;
    discount: boolean;
    shipping: boolean;
    disabled?: boolean;
}

const OrderTotalView = (props: OrderTotalViewProps) => {
    const { title, discount, shipping, disabled } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <AddEditFormCard title={title ?? "Total"} withExpand>
            {!isMobile ? (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>HT</TableCell>
                            <TableCell>TVA</TableCell>
                            <TableCell>TTC</TableCell>
                            <TableCell>{(discount || shipping) && "%"}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(discount || shipping) &&
                            <TableRow>
                                <TableCell>Produits</TableCell>
                                <TableCell>
                                    <NumberField
                                        name="total_tax_excl"
                                        disabled={true}
                                        minDecimals={2}
                                    />
                                </TableCell>
                                <TableCell>
                                    <NumberField
                                        name="total_tax"
                                        minDecimals={2}
                                        disabled={true}
                                    />
                                </TableCell>
                                <TableCell>
                                    <NumberField
                                        name="total_tax_incl"
                                        minDecimals={2}
                                        disabled={true}
                                    />
                                </TableCell>
                                <TableCell />
                            </TableRow>
                        }
                        {discount &&
                            <>
                                <TableRow>
                                    <TableCell>Remise</TableCell>
                                    <TableCell>
                                        <NumberField
                                            name="discount_tax_excl"
                                            minDecimals={2}
                                            disabled={true}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <NumberField
                                            name="discount_tax"
                                            minDecimals={2}
                                            disabled={true}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <NumberField
                                            name="discount_tax_incl"
                                            minDecimals={2}
                                            disabled={true}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <NumberField
                                            name="discount_percent"
                                            label="% Remise"
                                            disabled={disabled}
                                        />
                                    </TableCell>
                                </TableRow>
                                {shipping &&
                                    <TableRow>
                                        <TableCell>Total avec remise</TableCell>
                                        <TableCell>
                                            <NumberField
                                                name="total_minus_discount_tax_excl"
                                                minDecimals={2}
                                                disabled={true}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <NumberField
                                                name="total_minus_discount_tax"
                                                minDecimals={2}
                                                disabled={true}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <NumberField
                                                name="total_minus_discount_tax_incl"
                                                minDecimals={2}
                                                disabled={true}
                                            />
                                        </TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                }
                            </>
                        }
                        {shipping &&
                            <TableRow>
                                <TableCell>Livraison</TableCell>

                                <TableCell>
                                    <NumberField
                                        name="shipping_tax_excl"
                                        minDecimals={2}
                                        disabled={true}
                                    />
                                </TableCell>
                                <TableCell>
                                    <NumberField
                                        name="shipping_tax"
                                        minDecimals={2}
                                        disabled={true}
                                    />
                                </TableCell>
                                <TableCell>
                                    <NumberField
                                        name="shipping_tax_incl"
                                        minDecimals={2}
                                        disabled={true}
                                    />
                                </TableCell>
                                <TableCell>
                                    <NumberField
                                        name="shipping_vat"
                                        label={"% TVA Livraison"}
                                        disabled={disabled}
                                    />
                                </TableCell>
                            </TableRow>
                        }
                        <TableRow>
                            <TableCell>Total</TableCell>
                            <TableCell>
                                <NumberField
                                    name="total_tax_excl_plus_shipping_tax_excl"
                                    minDecimals={2}
                                    disabled={true}
                                />
                            </TableCell>
                            <TableCell>
                                <NumberField
                                    name="total_tax_plus_shipping_tax"
                                    minDecimals={2}
                                    disabled={true}
                                />
                            </TableCell>
                            <TableCell>
                                <NumberField
                                    name="total_tax_incl_plus_shipping_tax_incl"
                                    minDecimals={2}
                                    disabled={true}
                                />
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            ) : (
                <Grid container spacing={2} sx={{mb:6}}>
                    {(discount || shipping) && (
                        <React.Fragment>
                            <Grid item xs={6} sm={4}>
                                <Typography variant="body1">Produits HT</Typography>
                                <NumberField name="total_tax_excl" disabled={true} minDecimals={2} />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <Typography variant="body1">Produits TVA</Typography>
                                <NumberField name="total_tax" disabled={true} minDecimals={2} />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <Typography variant="body1">Produits TTC</Typography>
                                <NumberField name="total_tax_incl" disabled={true} minDecimals={2} />
                            </Grid>
                        </React.Fragment>
                    )}
                    {discount && (
                        <React.Fragment>
                            <Grid item xs={6} sm={3}>
                                <Typography variant="body1">Remise HT</Typography>
                                <NumberField name="discount_tax_excl" disabled={true} minDecimals={2} />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant="body1">Remise TVA</Typography>
                                <NumberField name="discount_tax" disabled={true} minDecimals={2} />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant="body1">Remise TTC</Typography>
                                <NumberField name="discount_tax_incl" disabled={true} minDecimals={2} />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant="body1">% Remise</Typography>
                                <NumberField name="discount_percent" disabled={disabled} />
                            </Grid>
                            {shipping && (
                                <React.Fragment>
                                    <Grid item xs={6} sm={4}>
                                        <Typography variant="body1">Total avec remise HT</Typography>
                                        <NumberField name="total_minus_discount_tax_excl" disabled={true} minDecimals={2} />
                                    </Grid>
                                    <Grid item xs={6} sm={4}>
                                        <Typography variant="body1">Total avec remise TVA</Typography>
                                        <NumberField name="total_minus_discount_tax" disabled={true} minDecimals={2} />
                                    </Grid>
                                    <Grid item xs={6} sm={4}>
                                        <Typography variant="body1">Total avec remise TTC</Typography>
                                        <NumberField name="total_minus_discount_tax_incl" disabled={true} minDecimals={2} />
                                    </Grid>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                    {shipping && (
                        <React.Fragment>
                            <Grid item xs={6} sm={3}>
                                <Typography variant="body1">Livraison HT</Typography>
                                <NumberField name="shipping_tax_excl" disabled={true} minDecimals={2} />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant="body1">Livraison TVA</Typography>
                                <NumberField name="shipping_tax" disabled={true} minDecimals={2} />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant="body1">Livraison TTC</Typography>
                                <NumberField name="shipping_tax_incl" disabled={true} minDecimals={2} />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant="body1">% TVA Livraison</Typography>
                                <NumberField name="shipping_vat" label="% TVA Livraison" disabled={disabled} />
                            </Grid>
                        </React.Fragment>
                    )}
                    <Grid item xs={6} sm={4}>
                        <Typography variant="body1">Total HT</Typography>
                        <NumberField name="total_tax_excl_plus_shipping_tax_excl" disabled={true} minDecimals={2} />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <Typography variant="body1">Total TVA</Typography>
                        <NumberField name="total_tax_plus_shipping_tax" disabled={true} minDecimals={2} />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <Typography variant="body1">Total TTC</Typography>
                        <NumberField name="total_tax_incl_plus_shipping_tax_incl" disabled={true} minDecimals={2} />
                    </Grid>
                </Grid>
            )}
        </AddEditFormCard>
    );
}

export default OrderTotalView;
