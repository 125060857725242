import { atom, selectorFamily } from "recoil";
import { QuoteCircuitModel } from "../classes/QuoteModel";
import { SelectOptionsProps } from "../../components/common/form/SelectField";

export const QuoteCircuitTypesState = atom<SelectOptionsProps[]>({
    key: 'QuoteCircuitTypesKey',
    default: []
});

export const QuoteCircuitState = atom<QuoteCircuitModel>({
    key: 'QuoteCircuitKey',
    default: { days: []}
});

export const QuoteCircuitDaySelector = selectorFamily({
    key: 'QuoteCircuitDaySelector',
    get: (id) => ({ get }) => {
        const circuit = get(QuoteCircuitState);
        return circuit?.days.find(day => day.id === id);
    },
    set: (id) => ({ get, set }, newValue) => {
        const circuit = get(QuoteCircuitState);
        if(!circuit){ return; }

        const daysUpdated = circuit.days.map(day => {
            if (day.id === id) {
                return { ...day, ...newValue };
            }
            return day;
        });
        set(QuoteCircuitState, { ...circuit, days: daysUpdated });
    },
});

export const QuoteCircuitDayStepSelector = selectorFamily({
    key: 'QuoteCircuitDayStepSelector',
    get: (params: { id: string, dayId: string}) => ({ get }) => {
        const day = get(QuoteCircuitDaySelector(params.dayId));
        return day?.steps.find(step => step.id === params.id);
    },
    set: (params: { id: string, dayId: string}) => ({ get, set }, newValue) => {
        const state = QuoteCircuitDaySelector(params.dayId);
        const day = get(QuoteCircuitDaySelector(params.dayId));
        if(!day){ return; }

        const stepsUpdated = day.steps.map(step => {
            if (step.id === params.id) {
                return { ...step, ...newValue };
            }
            return step;
        });
        set(state, { ...day, steps: stepsUpdated });
    },
});


export const QuoteCircuitDayStepAddressSelector = selectorFamily({
    key: 'QuoteCircuitDayStepAddressSelector',
    get: (params: { id: string, dayId: string, stepId: string}) => ({ get }) => {
        const step = get(QuoteCircuitDayStepSelector({dayId: params.dayId, id: params.stepId}));
        return step?.addresses.find(address => address.id === params.id);
    },
    set: (params: { id: string, dayId: string, stepId: string}) => ({ get, set }, newValue) => {
        const state = QuoteCircuitDayStepSelector({dayId: params.dayId, id: params.stepId});
        const step = get(state);
        if(!step){ return; }

        const addressesUpdated = step.addresses.map(address => {
            if (address.id === params.id) {
                return { ...address, ...newValue };
            }
            return address;
        });
        set(state, { ...step, addresses: addressesUpdated });
    },
});
