import { useNavigate, useParams } from "react-router-dom";
import { useAddEditProductType, useGetProductType } from "../../stores/ProductTypesStore";
import AddEditFormPanel, { useAddEditForm } from "../common/form/AddEditFormPanel";
import AddEditFormSubmit from "../common/form/AddEditFormSubmit";
import { ReqStatus } from "../../stores/core/UseApi";
import { useEffect, useMemo, useState } from "react";
import { Box, Grid, Input } from "@mui/material";
import InputField from "../common/form/InputField";
import { useSetRecoilState } from "recoil";
import { NavigateState } from "../../states/MenuState";
import { useAddEditCore } from "../../stores/core/StoreCore";
import { createOptionsForSelect } from "../../utils/DataUtils";
import SelectField, { SelectOptionsProps } from "../common/form/SelectField";
import TextAreaField from "../common/form/TextAreaField";
import { useField } from "react-recoil-form";
import DateField from "../common/form/DateField";
import AddEditForm from "../common/form/AddEditForm";
import AddEditFormCard from "../common/form/AddEditFormCard";
import { useIntl } from "react-intl";

interface PaymentResponse {
    payment: any,
    orders: { [id: string]: string },
    paymentMethods: { [id: string]: string },
    paymentMethodsStatusLists: { [id: string]: { [id: string]: string } }
}

const PaymentView = () => {

    let { id } = useParams();
    const intl = useIntl();

    const setNavigateState = useSetRecoilState(NavigateState);
    const listRoute = "/commercial/payments";

    const { getData, addEditData } = useAddEditCore<any, PaymentResponse>(`${listRoute}/add-edit`);

    const [orders, setOrders] = useState<SelectOptionsProps[]>([]);
    const [paymentMethods, setPaymentMethods] = useState<SelectOptionsProps[]>([]);
    const [paymentMethodsStatus, setPaymentMethodsStatus] = useState<{ [id: string]: { [id: string]: string } }>({});

    const paymentMethodField = useField({ name: "payment_method_id" });
    const statusField = useField({ name: "status_id" });
    const [ready, setReady] = useState(false)

    const status = useMemo(() => {
        if (!paymentMethodField.fieldValue || !paymentMethodsStatus[paymentMethodField.fieldValue]) {
            return [];
        }
        return createOptionsForSelect(paymentMethodsStatus[paymentMethodField.fieldValue]);
    }, [paymentMethodField.fieldValue, paymentMethodsStatus])

    useEffect(() => {
        if (!ready || !statusField.fieldValue) { return; }

        if (status.length === 0) {
            statusField.setFieldValue(null);
            return;
        }

        let found = status.find((s) => +s.value == statusField.fieldValue);
        if (!found) {
            statusField.setFieldValue(null);
        }
    }, [status, statusField, ready]);

    const onSubmit = (request: any) => {
        addEditData(request, id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                setNavigateState(listRoute);
            }
        });
    }

    const { handleSubmit, resetInitialValues, isDirty } = useAddEditForm({
        onSubmit
    });

    useEffect(() => {
        getData(id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                setPaymentMethods(createOptionsForSelect(res.data.paymentMethods));
                setPaymentMethodsStatus(res.data.paymentMethodsStatusLists ?? {});

                let _orders = createOptionsForSelect(res.data.orders);
                if('Commande' !== intl.formatMessage({ id: "Label_Order" })){
                    _orders = _orders.map((x) => {
                        x.label = x.label.replace('Commande', intl.formatMessage({ id: "Label_Order" }));
                        return x;
                    })
                }
                setOrders(_orders);

                const _payment = res.data.payment;
                if (id) {
                    resetInitialValues({
                        id: _payment.id,
                        reference: _payment.reference,
                        created: _payment.created ?? undefined,
                        payment_method_id: _payment.payment_method_id,
                        status_id: _payment.status_id,
                        order_id: _payment.order_id,
                        total_amount: _payment.total_amount,
                        comment: _payment.comment,
                    });
                } else {
                    resetInitialValues({
                        reference: "",
                        created: undefined,
                        payment_method_id: undefined,
                        status_id: undefined,
                        order_id: undefined,
                        total_amount: undefined,
                        comment: "",
                    });
                }
                setReady(true);
            } else {
                setNavigateState(listRoute);
            }
        });
    }, [id]);

    return (
        <AddEditForm formId="paymentForm" formHandleCancel={resetInitialValues} formHandleSubmit={handleSubmit} isDirty={isDirty}>
            <AddEditFormCard title={`${(!id ? "Ajouter " : "Modifier")} un paiement`}>
                <Grid container spacing={3} >

                    <Grid item xs={12} sm={6}>

                        <InputField
                            name="reference"
                            label="Référence"
                            required
                            type="text"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <DateField
                            name="created"
                            label="Date de paiement"
                            type="date"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>

                        <SelectField
                            name="payment_method_id"
                            label="Mode de paiement"
                            options={paymentMethods}
                            required
                        />

                    </Grid>

                    <Grid item xs={12} sm={6}>

                        <SelectField
                            name="status_id"
                            label="Statut de paiement"
                            options={status}
                            required
                        />

                    </Grid>

                    <Grid item xs={12} sm={6}>

                        <SelectField
                            name="order_id"
                            label={intl.formatMessage({ id: "Label_Order" })}
                            options={orders}
                            required
                            disabled={!!id}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <InputField
                            name="total_amount"
                            label="Montant"
                            type="number"
                            required
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>

                        <TextAreaField
                            name="comment"
                            label="Commentaire"
                        />
                    </Grid>


                </Grid>
            </AddEditFormCard>
        </AddEditForm>
    );
}

export default PaymentView;