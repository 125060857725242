import { atom } from 'recoil';
import { UserModel } from '../classes/users/UserModel';

export const AuthState = atom<boolean>({
    key: 'AuthKey',
    default: false
});

export const CsrfTokenState = atom<string>({
    key: 'CsrfTokenKey',
    default: ""
});

export const CurrentUserState = atom<UserModel | null>({
    key: 'CurrentUserKey',
    default: null
});

export interface AuthorizationsUser{
    [controller: string]: string[]
}

export const AuthorizationsState = atom<AuthorizationsUser | null>({
    key: 'AuthorizationsKey',
    default: null
});