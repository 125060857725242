import { Box, Button, Card, CircularProgress, Grid, Link, styled, Typography } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-recoil-form";
import InputField from "../common/form/InputField";
import { useForgotPassword } from "../../stores/AuthStore";
import { ReqStatus } from "../../stores/core/UseApi";
import { useRecoilValue } from 'recoil';
import { AuthState } from "../../states/AuthState";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { ForgotPasswordRequest } from "../../classes/users/ForgotPasswordRequest";
import SubmitButton from "../common/form/SubmitButton";
import LoginCard from "./LoginCard";


const ForgotPasswordForm = () => {
    const navigate = useNavigate();
    const [callForgetPassword] = useForgotPassword();

    const isAuth = useRecoilValue(AuthState);

    useEffect(() => {
        //si déjà logué, on redirige vers l'accueil
        if (isAuth) {
            navigate("/users/profile", { state: { title: 'Profil' } });
        }
    }, [])

    const onSubmit = (request: ForgotPasswordRequest) => {
        callForgetPassword(request).then(res => {
            if (res.status === ReqStatus.SUCCESS) {
                navigate("/login");
            }
        })
    }

    const { handleSubmit } = useForm({
        onSubmit,
        initialValues: {
            email: ""
        } as ForgotPasswordRequest
    })

    const intl = useIntl();

    return (
        <LoginCard>
            <form onSubmit={handleSubmit}>

                <Box pt={1}>
                    <InputField
                        name="email"
                        label={intl.formatMessage({ id: "Common_Email" })}
                        required
                        isEmail
                        type="text"
                    />
                </Box>

                <Box pt={2}>
                    <Button onClick={() => navigate("/login")}>
                        Se connecter
                    </Button>
                </Box>

                <Box display="flex" justifyContent="flex-end" pt={2}>
                    <SubmitButton
                        label={"Réinitialiser mon mot de passe"}
                    />
                </Box>
            </form>
        </LoginCard>
    )
}

export default ForgotPasswordForm;