import { useCallback, useState } from "react";
import { ListExportButtonsModelCore, ListModelCore, ListSearchModelCore } from "../../classes/list/ListModel";
import { GridColDef } from "@mui/x-data-grid";
import { ReqStatus } from "../../stores/core/UseApi";
import { Box, Button, IconButton } from "@mui/material";
import ListServer from "../common/list/ListServer";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { useGetProductTypeList } from "../../stores/ProductTypesStore";
import { useGetListCore } from "../../stores/core/StoreCore";
import ListCore from "../common/list/ListCore";

const columns: GridColDef[] = [
    {
        field: 'label',
        headerName: "Nom",
        editable: false,
        filterable: false,
        minWidth: 100,
        flex: 1,
    },
    {
        field: 'vat',
        headerName: "TVA %",
        editable: false,
        filterable: false,
        minWidth: 100,
        flex: 1,
    }
];


export interface ProductTypeListModel extends ListModelCore {
    productTypes: any[]
}

const ProductTypeList = () => {

    const routeController = "commercial/product-types";
    const [get] = useGetListCore<ProductTypeListModel>(`/${routeController}/index`);

    return (
        <ListCore<ProductTypeListModel, any>
            getDatas={get}
            defineDatasToSet={(data) => data.productTypes}
            getRowId={(row) => row.id}
            columns={columns}
            actions={{
                defaultRoute: routeController,
                add: {
                    title: "Ajouter un type de produit"
                },
                edit: true,
                remove: false
            }}
        />
    );
}

export default ProductTypeList;