import { Box,Typography } from "@mui/material";
import { useMemo } from "react";
import AutocompleteField, { AutocompleteOption } from "../../../components/common/form/AutocompleteField";
import { useField } from "react-recoil-form";
import { useRecoilValue } from "recoil";
import { FolderOrderSupplierListState, FolderOrderSupplierValidatedOptionsState } from "../../states/FolderOrderSuppliersState";
import { useQuoteList } from "../quotes/useQuote";
import SelectField from "../../../components/common/form/SelectField";
import { BrevoMailConfigEntityEnum, BrevoMailConfigQuoteStatusEnum } from "../../classes/BrevoMailConfig";
import { useBillList } from "../bills/useBill";

interface SendMailFormEntityProps {
	folderId: number,
	templateSelected: any,
	templateIdDefault: number | undefined,
	entityIdDefault : number | undefined,
}

const SendMailFormEntity = (props: SendMailFormEntityProps) => {

	const { folderId, templateSelected, templateIdDefault, entityIdDefault } = props;
	const { fieldValue: entityId } = useField({ name: 'entity_id'});

	const folderOrderSuppliers = useRecoilValue(FolderOrderSupplierListState);
	const folderOrderSuppliersValidated = useRecoilValue(FolderOrderSupplierValidatedOptionsState);
 

	const { quoteOptions } = useQuoteList(folderId.toString());
	const { billOptions } = useBillList(folderId.toString());

	const folderOrderSupplierSelectVisible = useMemo(() => {
		return templateSelected?.entity === BrevoMailConfigEntityEnum.ENTITY_FOLDER_ORDER_SUPPLIER ?? false;
	}, [templateSelected]);

	const folderOrderSupplierOptions: AutocompleteOption[] = useMemo(() => {
		if(!folderOrderSupplierSelectVisible){ return []; }
		if(templateSelected.quote_status_required === BrevoMailConfigQuoteStatusEnum.VALIDATED){
			return folderOrderSuppliersValidated.map((s) => ({ id: s.value, label: s.label }));
		}else{
			return folderOrderSuppliers.map((s) => ({ id: s.id, label: `${s.supplier.name} - Devis ${s.order.id}` }));
		}
	}, [folderOrderSupplierSelectVisible, folderOrderSuppliers,folderOrderSuppliersValidated, templateSelected])

	const quoteSelectVisible = useMemo(() => {
		return templateSelected?.entity === BrevoMailConfigEntityEnum.ENTITY_QUOTE ?? false;
	}, [templateSelected]);

	const BillVisible = useMemo(() => {
		return templateSelected?.entity === BrevoMailConfigEntityEnum.ENTITY_BILL ?? false;
	}, [templateSelected]);

	return (
		<>
			{quoteSelectVisible &&
				<Box mt={2}>
					<SelectField
						name="entity_id"
						label={"Devis"}
						options={quoteOptions}
						required
						disabled={(!!(templateIdDefault) && !!(entityIdDefault))}
					/>
				</Box>
			}

			{ folderOrderSupplierSelectVisible &&
				<Box mt={2}>
					<AutocompleteField
						name="entity_id"
						label={"Demande fournisseur"}
						options={folderOrderSupplierOptions}
						required
						disabled={(!!(templateIdDefault) && !!(entityIdDefault))}
					/>
				</Box>
			}
			
			{BillVisible &&
				<Box mt={2}>
					<SelectField
						name="entity_id"
						label={"Facture"}
						options={billOptions}
						required
						disabled={(!!(templateIdDefault) && !!(entityIdDefault))}
					/>
				</Box>
			}
		</>
	)
}

export default SendMailFormEntity;