
import { useMemo } from "react";
import { Box, Grid, Typography } from "@mui/material";
import InputField from "../../../components/common/form/InputField";
import { useField } from "react-recoil-form";
import { TagItem } from "../folders/FolderTagItem";
import CheckboxField from "../../../components/common/form/CheckboxField";
import ColorPickerField from "../../../components/common/form/ColorPickerField";
import NumberField from "../../../components/common/form/NumberField";

const TagForm = (props: { commercial: boolean, systeme: boolean, sort_order?: boolean }) => {
    const nameField = useField({ name: 'name' });
    const colorField = useField({ name: 'color' });
    const backcolorField = useField({ name: 'backcolor' });

    const tag = useMemo(() => {
        return {
            name: nameField.fieldValue,
            color: colorField.fieldValue,
            backcolor: backcolorField.fieldValue
        };
    }, [nameField.fieldValue, colorField.fieldValue, backcolorField.fieldValue])


    return (
        <Grid container spacing={2} >
            <Grid item xs={props.sort_order ? 6 : 12}>
                <InputField name="name" label="Label" type="text" required />
            </Grid>
            {props.sort_order &&
                <Grid item xs={6}>
                    <NumberField
                        name="sort_order"
                        label="Ordre"
                    />
                </Grid>
            }
            {props.commercial &&
                <Grid item xs={6}>
                    <CheckboxField name="commercial" label="Commercial" />
                </Grid>
            }
            {props.systeme &&
                <Grid item xs={6}>
                    <CheckboxField name="systeme" label="Système" />
                </Grid>
            }
            <Grid item xs={12}>
                <Box><Typography>Rendu TAG</Typography></Box>
                <Box width="50%"><TagItem tag={tag} /></Box>
            </Grid>
            <Grid item xs={6}>
                <ColorPickerField name="color" label="Couleur texte" required />
            </Grid>
            <Grid item xs={6}>
                <ColorPickerField name="backcolor" label="Couleur de fond" required />
            </Grid>
        </Grid>
    )
}

export default TagForm;