import { useNavigate, useParams } from "react-router-dom";
import { useAddEditCore } from "../../../stores/core/StoreCore";
import { useCallback, useEffect } from "react";
import { ReqStatus } from "../../../stores/core/UseApi";
import { useSnackbar } from "notistack";

const OrderRedirect = () => {

    let { id } = useParams();

    const { enqueueSnackbar } = useSnackbar();
    const listRoute = "/commercial/orders";
    const { getData } = useAddEditCore<any, { order: any }>(`${listRoute}/add-edit`);

    const navigate = useNavigate();

    const navigationBack = useCallback(() => {
        enqueueSnackbar(`Erreur lors de la navigation`, { variant: "error" });
        navigate(-1); 
    },[enqueueSnackbar, navigate]);

    useEffect(() => {
        if(!id){ 
            navigationBack();
        }
        getData(id).then((res) => {
            if(res.status === ReqStatus.SUCCESS){
                const folder_id = res.data?.order?.order_attribute?.folder_id ?? undefined;
                const quote_id = res.data?.order?.id;
                if(folder_id && quote_id){
                    navigate(`/folders/${folder_id}/quotes/add-edit/${quote_id}`, { replace: true });
                }else{
                    navigationBack();
                }
            }else{
                navigationBack();
            }
        });
    },[])

    return (<></>);
}

export default OrderRedirect;