import { Box, Button, Dialog, DialogContent, LinearProgress, Typography } from "@mui/material";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import { WaitingModalState } from "../../../states/ModalState";


const WaitingModal = () => {
    const waitingModalState = useRecoilValue(WaitingModalState);
    return (
        <Dialog
            disableEscapeKeyDown
            open={waitingModalState.open}
            PaperProps={{
                style: { width: '100%' }
            }}>
            <DialogContent>
                <Box display="flex" justifyContent={"center"} alignItems="center" mb={2}>
                    <Typography variant="h6">{waitingModalState.text}</Typography>
                </Box>
                <LinearProgress color='primary' />

            </DialogContent>
        </Dialog>
    );
}

export default WaitingModal;