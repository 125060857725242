import { useCallback, useState } from "react";
import { ListExportButtonsModelCore, ListModelCore, ListSearchModelCore } from "../../classes/list/ListModel";
import { GridColDef } from "@mui/x-data-grid";
import { ReqStatus } from "../../stores/core/UseApi";
import { Box, Button } from "@mui/material";
import ListServer from "../common/list/ListServer";
import { useGetSupplierOrderList } from "../../stores/SupplierOrdersStore";
import { useGetListCore } from "../../stores/core/StoreCore";
import { useGetOrderList } from "../../stores/OrdersStore";
import ListCore from "../common/list/ListCore";
import { datetimeFormat } from "../../utils/DateUtils";
import { useIntl } from "react-intl";

const useColumns = () => {
    const intl = useIntl();
    return [
        {
            field: 'id',
            headerName: "N°",
            editable: false,
            filterable: false,
            minWidth: 30,
        },
        {
            field: 'reference',
            headerName: "Référence",
            editable: false,
            filterable: false,
            minWidth: 60,
        },
        {
            field: 'Suppliers.received',
            headerName: "Client",
            editable: false,
            filterable: false,
            width: 300,
            renderCell: ({ row }) => (
                <Box>
                    <Box>Livré : {`${row.shipping_address?.first_name ?? ""} ${row.shipping_address?.last_name ?? ""}`}</Box>
                    <Box>Facturé : {`${row.billing_address?.first_name ?? ""} ${row.billing_address?.last_name ?? ""}`}</Box>
                </Box>
            )
        },
        {
            field: 'total_minus_discount_tax_excl_euro',
            headerName: "Prix HT",
            editable: false,
            filterable: false,
            minWidth: 60,
            valueGetter: ({ row }) => `${row.total_minus_discount_tax_excl} €`
        },
        {
            field: 'total_minus_discount_tax_incl_euro',
            headerName: "Prix TTC",
            editable: false,
            filterable: false,
            minWidth: 60,
            valueGetter: ({ row }) => `${row.total_minus_discount_tax_incl} €`
        },
        {
            field: 'order_status.label',
            headerName: intl.formatMessage({id: "Label_Order_Status"}),
            editable: false,
            filterable: false,
            minWidth: 60,
            valueGetter: ({ row }) => `${row.order_status.label}`
        },
        {
            field: 'payment_status.label',
            headerName: "Statut paiement",
            editable: false,
            filterable: false,
            minWidth: 60,
            valueGetter: ({ row }) => `${row.payment_status.label}`
        },
        {
            field: 'shipping_status.label',
            headerName: "Statut livraison",
            editable: false,
            filterable: false,
            minWidth: 60,
            valueGetter: ({ row }) => `${row.shipping_status.label}`
        },
        {
            field: 'created',
            headerName: intl.formatMessage({id: "Label_Order_Date"}),
            editable: false,
            filterable: false,
            width: 200,
            valueGetter: ({ row }) => datetimeFormat(row.created)
        }
    ] as GridColDef[];
};


interface OrderListModel extends ListModelCore {
    orders: any[]
}

const OrderList = () => {

    const routeController = "commercial/orders";
    const [get] = useGetListCore<OrderListModel>(`/${routeController}/index`);
    const columns = useColumns();
    const intl= useIntl();
    return (
        <ListCore<OrderListModel, any>
            getDatas={get}
            defineDatasToSet={(data) => data.orders}
            getRowId={(row) => row.id}
            columns={columns}
            actions={{
                defaultRoute: routeController,
                add: {
                    title: intl.formatMessage({id: "Label_Order_Add"})
                },
                edit: true,
                remove: true
            }}
        />
    );
}

export default OrderList;