import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, Typography, Paper, Divider, IconButton, SxProps, Theme } from "@mui/material";
import { Collapse } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


interface AddEditFormCardProps {
  title: JSX.Element | string;
  action?: React.ReactNode;
  children: React.ReactNode;
  withExpand?: boolean,
  withExpandByTitle?: boolean,
  isExpand?: boolean,
  maxWidth?: "sm" | "md" | "lg" | "xl" | "xxl" | string,
  sx?: { paper?: SxProps<Theme> },
  isExpandRecompileChildren?: boolean,
  className?: string,
  paddingContent?: number,
}

const AddEditFormCard = (props: AddEditFormCardProps) => {
  const { title, children, withExpand, withExpandByTitle, isExpand, isExpandRecompileChildren, maxWidth, action, sx, className, paddingContent } = props;
  const [isExpanded, setIsExpanded] = useState(isExpand ?? true);

  useEffect(() => {
    if (isExpand === undefined) { return; }
    setIsExpanded(isExpand);
  }, [isExpand])

  const handleToggleExpand = useCallback(() => {
    setIsExpanded((v) => !v);
  }, []);

  const titleCanHandleExpand = useMemo(() => {
    return withExpand && (withExpandByTitle === undefined || withExpandByTitle === true)
  }, [withExpand, withExpandByTitle])

  return (
    <Box maxWidth={(!maxWidth || maxWidth === "xxl") ? "100%" : maxWidth} mx={"auto"}>
      <Paper variant="outlined" sx={sx?.paper ?? { m: 2 }}  >
        <Box display="flex" alignItems="center" sx={{ px: 2, minHeight: "50px" }} justifyContent={"space-between"}>
          <Box flexGrow={1} onClick={titleCanHandleExpand ? handleToggleExpand : undefined} style={titleCanHandleExpand ? { cursor: "pointer" } : undefined}>
            <Typography variant="h5">
              {title}
            </Typography>
          </Box>
          <Box display="flex" alignItems={"center"}>
            {action}
            <Box ml={1}>
              {withExpand &&
                <IconButton color="info" onClick={handleToggleExpand}>
                  {isExpanded ? <KeyboardArrowUpIcon fontSize="large" /> : <KeyboardArrowDownIcon fontSize="large" />}
                </IconButton>
              }
            </Box>
          </Box>
        </Box>
        <Divider />
        {withExpand ?
          <>
            {/* Recréé le contenu à chaque ouverture du bloc */}
            {isExpandRecompileChildren && isExpanded &&
              <Collapse in={isExpanded}>
                <Box sx={{ p: paddingContent ?? 2 }}>{children}</Box>
              </Collapse>
            }

            {/* Réaffiche le contenu à chaque ouverture du bloc */}
            {!isExpandRecompileChildren && <Collapse in={isExpanded}>
              <Box sx={{ p: paddingContent ?? 2 }}>{children}</Box>
            </Collapse>}
          </>
          :
          <Box sx={{ p: paddingContent ?? 2 }} className={className}>{children}</Box>
        }
      </Paper>
    </Box>
  );
};

export default AddEditFormCard;
