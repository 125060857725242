import { useCallback, useEffect, useMemo, useState } from "react";
import { useGetTagList } from "../../stores/FoldersStore";
import { FolderTag, Tag } from "../../classes/FolderTagModel";
import { ReqStatus } from "../../../stores/core/UseApi";
import { Box } from "@mui/material";
import AddEditFormCard from "../../../components/common/form/AddEditFormCard";
import { useDeleteFolderTag, useInsertFolderTags } from "../../stores/FolderTagsStore";
import FolderTagItem from "../folders/FolderTagItem";
import FolderTagAutoComplete from "./FolderTagAutoComplete";

const FolderTags = (props: { folderId: string }) => {

	const { folderId } = props;

	const getTagsAjax = useGetTagList();

	const [tags, setTags] = useState<Tag[]>([]);
	const [folderTags, setFolderTags] = useState<FolderTag[]>([]);

	const tagsOptions = useMemo(() => {
		const exclude_tagids = folderTags.map((x) => x.tag_id);

		if (exclude_tagids.length === 0) { return tags; }

		return tags.filter((tag) => {
			return !exclude_tagids.includes(tag.id);
		})
	}, [tags, folderTags])

	const getTags = useCallback(() => {
		if (!folderId) { return; }
		getTagsAjax(folderId).then((res) => {
			if (res.status === ReqStatus.SUCCESS && res.data) {
				setTags(res.data.tags ?? []);
				setFolderTags(res.data.folderTags ?? []);
			}
		});
	}, [folderId, getTagsAjax]);

	useEffect(() => {
		getTags();
	}, []);

	const insertTagsAjax = useInsertFolderTags();
	const insertFolderTag = useCallback((tags: Tag[]) => {
		insertTagsAjax.call(+folderId, tags.map((x) => x.id)).then((res) => {
			if (res.status === ReqStatus.SUCCESS) {
				getTags();
			}
		});
	}, [getTags, insertTagsAjax, folderId]);

	const deleteAjax = useDeleteFolderTag();
	const deleteFolderTag = useCallback((id: number) => {
		deleteAjax(id).then((res) => {
			if (res.status === ReqStatus.SUCCESS) {
				setFolderTags((fts) => {
					return fts.filter((x) => x.id !== id);
				})
			}
		})
	}, [deleteAjax]);

	return (
		<AddEditFormCard title="Tags">
			{folderTags.map((folderTag, tag_key) => (
				<FolderTagItem folderTag={folderTag} key={tag_key} deleteEvent={deleteFolderTag} />
			))}

			<Box mt={1}>
				<FolderTagAutoComplete options={tagsOptions} onValidate={insertFolderTag} />
			</Box>
		</AddEditFormCard>
	);
}

export default FolderTags;