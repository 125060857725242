import { Container, Modal } from "@mui/material";
import { FormProvider } from "react-recoil-form";
import { useCallback, useState } from "react";
import { AddressModalState } from "../../../states/AddressState";
import { useRecoilState } from "recoil";
import AddressCustomView from "./AddressCustomView";

const AddressCustomModal = () => {

    const [addressModalState, setAddressModalState] = useRecoilState(AddressModalState);

    const handleClose = useCallback(() => {
        setAddressModalState((a) => {
            return { ...a, open: false };
        });
    }, [setAddressModalState]);


    const handleValidate = useCallback(() => {
        addressModalState.onValidate();
        setAddressModalState((a) => {
            return { ...a, open: false };
        });
    }, [addressModalState, setAddressModalState]);


    return (
        <Modal
            open={addressModalState.open}
            //onClose={handleClose}
            aria-labelledby="address-modal"
            sx={{
                overflow: 'auto'
            }}
        >
            <Container component="main" maxWidth="md" sx={{ height: "100%", py: 2 }}>
                <FormProvider options={{ formId: "addressForm", skipRecoilRoot: true }}>
                    <AddressCustomView
                        id={addressModalState.id}
                        customerId={addressModalState.customerId}
                        onClose={handleClose}
                        onValidate={handleValidate}    
                    />
                </FormProvider>
            </Container>
        </Modal>
    );
}

export default AddressCustomModal;