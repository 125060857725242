import { Container, Modal } from "@mui/material";
import { FormProvider } from "react-recoil-form";
import { useCallback, useEffect } from "react";
import { useRecoilState } from "recoil";
import { FolderOrderSupplierContactModalState } from "../../states/FolderOrderSupplierContactState";
import FolderOrderSupplierContactForm from "./FolderOrderSupplierContactForm";

const FolderOrderSupplierContactModal = () => {

    const [modalState, setModalState] = useRecoilState(FolderOrderSupplierContactModalState);

    const handleClose = useCallback(() => {
        setModalState((a) => {
            return { ...a, open: false };
        });
    }, [setModalState]);


    const handleValidate = useCallback(() => {
        modalState.onValidate();
        setModalState((a) => {
            return { ...a, open: false };
        });
    }, [modalState, setModalState]);

    return (
        <Modal
            open={modalState.open}
            aria-labelledby="folder-order-supplier-contact-modal"
            sx={{
                overflow: 'auto'
            }}
        >
            <Container component="main" maxWidth="md" sx={{ height: "100%", py: 2 }}>
                <FormProvider options={{ formId: "folderOrderSupplierContactForm", skipRecoilRoot: true }}>
                    
                    <FolderOrderSupplierContactForm
                        id={modalState.id}
                        onClose={handleClose}
                        onValidate={handleValidate}
                    />
					
                </FormProvider>
            </Container>
        </Modal>
    );
}

export default FolderOrderSupplierContactModal;