import { useNavigate, useParams } from "react-router-dom";
import { useAddEditSupplier, useGetSupplier } from "../../stores/SuppliersStore";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ReqStatus } from "../../stores/core/UseApi";
import { Alert, Box, Button, Divider, Grid, IconButton, TextField, Typography } from "@mui/material";
import InputField from "../common/form/InputField";
import TextAreaField from "../common/form/TextAreaField";
import AddEditFormPanel, { useAddEditForm } from "../common/form/AddEditFormPanel";
import AddEditFormSubmit from "../common/form/AddEditFormSubmit";
import AutocompleteField, { AutocompleteOption } from "../common/form/AutocompleteField";
import { SupplierModel } from "../../classes/suppliers/SupplierModel";
import { useField, useForm, useFormValues, useIsDirty } from "react-recoil-form";
import AddEditFormList from "../common/form/AddEditFormList";
import { useSetRecoilState } from "recoil";
import { NavigateState, NavigationBlockedState } from "../../states/MenuState";
import { useAddEditCore, useDelete, useViewCore } from "../../stores/core/StoreCore";
import SelectField, { SelectOptionsProps } from "../common/form/SelectField";
import DateField from "../common/form/DateField";
import RadioField from "../common/form/RadioField";
import JsonFormView from "../common/debug/JsonFormView";
import { DataGrid } from "@mui/x-data-grid";
import CustomerOrdersView from "./CustomerOrdersView";
import CustomerAddressesView from "./CustomerAddressesView";
import AddEditForm from "../common/form/AddEditForm";
import AddEditFormCard from "../common/form/AddEditFormCard";
import { useIntl } from "react-intl";
import CustomerInfosView from "./CustomerInfosView";
import { ConfirmModalState } from "../../states/ModalState";
import { createOptionsForAutocomplete, createOptionsForSelect } from "../../utils/DataUtils";

interface CustomerResponse {
    customer: any,
    customerTypes: { [id: string]: string },
    userGroups: { [id: string]: string },
    genders: { [id: string]: string },
    countries: { [id: string]: string },
    addresses: { [id: string]: string },
}

export const useCustomerView = (customFields?: string[], dataForNew?: any, prepareDataForUpdate?: (data: any) => any) => {
    let { id } = useParams();

    const setNavigateState = useSetRecoilState(NavigateState);
    const listRoute = "/commercial/customers";

    const { getData, addEditData } = useAddEditCore<any, CustomerResponse>(`${listRoute}/add-edit`);
    const deleteAddressAjax = useDelete("/commercial/addresses");

    const [customerTypesOptions, setCustomerTypesOptions] = useState<SelectOptionsProps[]>([]);
    const [userGroupsOptions, setUserGroupsOptions] = useState<SelectOptionsProps[]>([]);
    const [genderOptions, setGenderOptions] = useState<SelectOptionsProps[]>([]);
    const [countryOptions, setCountryOptions] = useState<AutocompleteOption[]>([]);
    const [addresses, setAddresses] = useState<SelectOptionsProps[]>([]);
    const [data, setData] = useState<any>();

    const [orders, setOrders] = useState<any[]>([]);

    const setConfirmModalState = useSetRecoilState(ConfirmModalState);

    const onSubmit = (request: any) => {
        let _request = JSON.parse(JSON.stringify(request)) as any;
        addEditData(_request, id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                if (!id) {
                    if (Array.isArray(res.data) && res.data.length === 1 && res.data[0].user_id) {
                        setNavigateState(`${listRoute}/add-edit/${res.data[0].user_id}`);
                    } else {
                        setNavigateState(`${listRoute}`);
                    }
                } else {
                    refresh(id);
                }
            }
        });
    }

    const { handleSubmit, resetInitialValues, isDirty } = useAddEditForm({
        onSubmit,
        initialValues: {
            addElements: "1"
        }
    });

    const refresh = useCallback((id: string | undefined) => {
        getData(id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                const _customer = res.data.customer;

                setData(res.data);

                setCustomerTypesOptions(createOptionsForSelect(res.data.customerTypes));
                setGenderOptions(createOptionsForSelect(res.data.genders));
                setAddresses(createOptionsForSelect(res.data.addresses));
                setUserGroupsOptions(createOptionsForSelect(res.data.userGroups));
                setCountryOptions(createOptionsForAutocomplete(res.data.countries,"string"));
                
                setOrders(_customer.orders ?? []);

                if (id) {

                    let _initialValues = {
                        gender_id: _customer.gender_id,
                        birthday: _customer.birthday,
                        customer_type_id: _customer.customer_type_id,
                        user: {
                            first_name: _customer.user.first_name,
                            last_name: _customer.user.last_name,
                            email: _customer.user.email,
                            phone: _customer.user.phone,
                            user_group_id: _customer.user.user_group_id,
                        },
                        addElements: "1"
                    } as any;

                    customFields?.forEach((customField) => {
                        _initialValues[customField] = _customer[customField];
                    })
                    resetInitialValues(prepareDataForUpdate ? prepareDataForUpdate(_initialValues) : _initialValues);
                } else {
                    if (dataForNew) {
                        resetInitialValues(dataForNew);
                    }
                }
            } else {
                setNavigateState(listRoute);
            }
        });
    }, []);

    const deleteAddress = useCallback((addressId: number) => {
        setConfirmModalState({
            open: true,
            title: "Suppression",
            desc: "Êtes-vous sûr de vouloir supprimer cette adresse ?",
            validEvent: () => {
                deleteAddressAjax(addressId).then((res) => {
                    if (res.status === ReqStatus.SUCCESS) {
                        refresh(id);
                    }
                })
            }
        })
    }, [deleteAddressAjax, id, refresh, setConfirmModalState]);

    useEffect(() => {
        refresh(id);
    }, [id]);

    return {
        resetInitialValues,
        handleSubmit,
        isDirty,
        refresh,
        deleteAddress,
        genderOptions,
        customerTypesOptions,
        userGroupsOptions,
        countryOptions,
        addresses,
        orders,
        data
    }
}

const CustomerView = () => {
    let { id } = useParams();
    const {
        resetInitialValues,
        handleSubmit,
        isDirty,
        refresh,
        deleteAddress,
        genderOptions,
        customerTypesOptions,
        userGroupsOptions,
        countryOptions,
        addresses,
        orders
    } = useCustomerView();

    const intl = useIntl();

    return (
        <AddEditForm formId="customerForm" formHandleCancel={resetInitialValues} formHandleSubmit={handleSubmit} isDirty={isDirty}>
            <CustomerInfosView
                id={id}
                genderOptions={genderOptions}
                customerTypesOptions={customerTypesOptions}
                userGroupsOptions={userGroupsOptions}
                countryOptions={countryOptions}
            />
            {id &&
                <CustomerAddressesView
                    customerId={id}
                    addresses={addresses}
                    disabled={isDirty}
                    onValidate={() => refresh(id)}
                    onDelete={(addressId: number) => { deleteAddress(addressId) }}
                />
            }
            {id &&
                <AddEditFormCard title={intl.formatMessage({ id: "Label_Orders" })} withExpand={orders && orders.length > 0}>
                    <CustomerOrdersView orders={orders} />
                </AddEditFormCard>
            }
        </AddEditForm>
    );
}

export default CustomerView;