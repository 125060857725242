
import { Box, Button, Menu, MenuItem } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { QuoteCircuitDayStepAddressModel } from '../../../classes/QuoteModel';
import MapIcon from '@mui/icons-material/Map';
import { openMappyEvent } from '../../tomtom/TomTomCalculateRoute';

interface QuoteCircuitDayStepMappyProps {
	id: string,
	addresses: QuoteCircuitDayStepAddressModel[]
}

const QuoteCircuitDayStepMappy = (props: QuoteCircuitDayStepMappyProps) => {

	const { id, addresses } = props;

	const pairs = useMemo(() => {
		const _pairs = [];
		for (let i = 0; i < addresses.length - 1; i++) {

			if (!addresses[i].address || !addresses[i + 1].address) {
				continue;
			}
			_pairs.push({
				start: addresses[i].address,
				end: addresses[i + 1].address
			});
		}
		return _pairs;
	},[addresses]);

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
	  setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
	  setAnchorEl(null);
	};

	return (
		<>
			<Button
				size="small" color="primary" variant="contained"
				disabled={pairs.length == 0}

				id={`mappy-${id}`}
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
			>
				<MapIcon />
			</Button>
			<Menu
				id={`mappy-${id}`}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				<>
					{pairs.map((pair) => (
						<MenuItem onClick={() => { 
							openMappyEvent(pair.start, pair.end); 
							handleClose();
						} 
					}>{pair.start} - {pair.end}</MenuItem>
					))}
				</>
			</Menu>
		</>
	)
}

export default QuoteCircuitDayStepMappy;


