import { GridColDef } from "@mui/x-data-grid";
import { dateFormat, datetimeFormat } from "../../../../utils/DateUtils";

interface CreateColumnDateProps {
	headerName: string
	field: string,
	type: "dateTime" | "date",
	minWidth?: number,
	getValue: (row: any) => any
}
export const CreateColumnDate = (props : CreateColumnDateProps) => {
	return  {
		field: props.field,
		headerName: props.headerName,
		editable: false,
		filterable: false,
		minWidth: props.minWidth ?? 120,
		type: props.type,
		valueGetter: ({ row }) => {
			const value = props.getValue(row);
			return value ? new Date(value) : null;
		},
		valueFormatter: (params) => {
			return props.type === "dateTime" ? datetimeFormat(params.value) : dateFormat(params.value);
		},
	} as GridColDef
}