
import { GridColDef } from "@mui/x-data-grid";
import { ListModelCore } from "../../../classes/list/ListModel";
import { useGetListCore } from "../../../stores/core/StoreCore";
import ListCore from "../../../components/common/list/ListCore";
import React, { useCallback, useState } from "react";
import { Box, Button, Tooltip, IconButton, Modal, Container, Paper } from "@mui/material";
import { FolderTag } from "../../classes/FolderTagModel";
import { datetimeFormat, datetimeTicks } from "../../../utils/DateUtils";
import { getLabelOfStatusByFolder } from "../folders/FolderStepper";
import FolderTagItem, { TagItem } from "../folders/FolderTagItem";
import { EnumFolderOrderSupplierStatus, getColorOfFolderOrderSupplierStatus } from "../../classes/FolderOrderSupplierModel";
import { useNavigate } from "react-router-dom";
import AddUserOnEntityButton from "../common/AddUserOnEntityButton";
import { addUserOnEntityType } from "../../states/FoldersState";
import AddUserOnEntityModal from "../common/AddUserOnEntityModal";
import { CreateColumnDate } from "../../../components/common/list/column/ColumnDate";
import { CreateColumnAvatar } from "../../../components/common/list/column/ColumnAvatar";
import InfoIcon from '@mui/icons-material/Info';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import FolderChecktasksModal from "../folder-checktasks/FolderChecktaskModal";
import FolderChecktaskButton from "../folder-checktasks/FolderChecktaskButton";

export const useListcolumns = (is_lead?: boolean) => {
    return [
        {
            field: 'id',
            headerName: "Devis N°",
            editable: false,
            filterable: false,
            width: 80,
            renderCell: ({ row }) => (
                <Box>
                    <Box>#{row.id}</Box>
                    <Box>{row.order_status?.label}</Box>
                </Box>
            )
        },
        {
            field: 'customer_id',
            headerName: "Client",
            editable: false,
            filterable: false,
            width: 150,
            renderCell: ({ row }) => (<>{row.customer_id &&
                <Box display="flex" flexDirection={"column"} justifyContent="center" alignItems="center">
                    <Box>{row.customer.user.first_name}<br />{row.customer.user.last_name}</Box>
                </Box>}
            </>)
        },
        {
            field: 'order_attribute.folder.status',
            headerName: "Statut",
            editable: false,
            filterable: false,
            width: 150,
            renderCell: ({ row }) => <Box width={150} display="flex" flexWrap="wrap">
                <p style={{ flex: 1, overflowWrap: 'break-word', whiteSpace: 'normal' }}>
                    {getLabelOfStatusByFolder(row.order_attribute.folder)}
                </p>
            </Box>
        },
        {
            field: 'tags',
            headerName: "Tags",
            editable: false,
            filterable: false,
            width: 200,
            renderCell: ({ row }) => (
                <Box display="flex" flexDirection="column">
                    {row.order_attribute.folder.folder_tags?.map((folderTag: FolderTag, tag_key: number) => (<>
                        <FolderTagItem folderTag={folderTag} key={tag_key} /></>
                    ))}
                </Box>
            )
        },
        {
            field: 'travels',
            headerName: "Date du voyage",
            editable: false,
            filterable: false,
            width: 140,
            renderCell: ({ row }) => {
                let departure_date = "";
                let return_date = "";
                if (Array.isArray(row.travels) && row.travels.length > 0 && row.travels[0].order_product_attribute && row.travels[0].order_product_attribute.departure_date) {
                    departure_date = datetimeFormat(row.travels[0].order_product_attribute.departure_date);
                }
                if (Array.isArray(row.travels) && row.travels.length > 0 && row.travels[0].order_product_attribute && row.travels[0].order_product_attribute.return_date) {
                    return_date = datetimeFormat(row.travels[0].order_product_attribute.return_date);
                }
                return (
                    <Box>
                        {departure_date && <Box>{return_date ? 'du' : 'le'} {departure_date}</Box>}
                        {return_date && <Box>au {return_date}</Box>}
                    </Box>
                )

            },
            sortComparator: (v1, v2) => {
                let v1_ticks: number = 0;
                let v2_ticks: number = 0;
                if (Array.isArray(v1) && v1.length > 0 && v1[0].order_product_attribute && v1[0].order_product_attribute.departure_date) {
                    v1_ticks = datetimeTicks(v1[0].order_product_attribute.departure_date);
                }

                if (Array.isArray(v2) && v2.length > 0 && v2[0].order_product_attribute && v2[0].order_product_attribute.departure_date) {
                    v2_ticks = datetimeTicks(v2[0].order_product_attribute.departure_date);
                }

                if (v1_ticks == v2_ticks) {
                    return 0;
                }

                return (v1_ticks > v2_ticks) ? 1 : -1;
            },
        },
        CreateColumnAvatar({
            field: 'gestionnaire_id',
            headerName: "Gestionnaire",
            getId: (row) => row.order_attribute.gestionnaire_id,
            getValue: (row) => `${row.order_attribute.gestionnaire.first_name} ${row.order_attribute.gestionnaire.last_name}`
        }),
        CreateColumnAvatar({
            field: 'commercial_id',
            headerName: "Commercial",
            getId: (row) => row.order_attribute.user_id,
            getValue: (row) => `${row.order_attribute.commercial.first_name} ${row.order_attribute.commercial.last_name}`
        }),
        {
            field: 'supplier',
            headerName: "Fournisseur",
            editable: false,
            filterable: false,
            width: 200,
            renderCell: ({ row }) => (
                <Box height={70} width={200} overflow={"auto"} >
                    {row.order_attribute.folder_order_suppliers?.map((fos: any) => (
                        <>
                            {(fos.status === EnumFolderOrderSupplierStatus.CONFIRMED ||
                                fos.status === EnumFolderOrderSupplierStatus.VALIDATED ||
                                fos.status === EnumFolderOrderSupplierStatus.CANCELED
                            ) &&
                                <Box width={160} overflow={"hidden"} textOverflow={"ellipsis"} my={0.1} px={0.5} style={getColorOfFolderOrderSupplierStatus(fos.status)}>
                                    {fos.supplier.name}
                                </Box>
                            }
                        </>
                    ))}
                </Box>
            )
        },
        {
            field: 'comment',
            headerName: "Checklist / note",
            editable: false,
            filterable: false,
            width: 120,
            renderCell: ({ row }) => (
                <Box display="flex" mx={1} alignItems={"center"}>
                    <Box mr={1}>
                        <FolderChecktaskButton id={row.order_attribute.folder_id} />
                    </Box>
                    <Tooltip
                        title={
                            <Box fontSize={13}>
                                {row.order_attribute.folder.comment?.split('\n').map((x: any) => <Box>{x}</Box>) ?? ""}
                            </Box>
                        }
                    >
                        <InfoIcon color={row.order_attribute.folder.comment.length > 0 ? "primary" : "disabled"} fontSize="large" />
                    </Tooltip>
                </Box>
            )
        },
        CreateColumnDate({
            field: 'order_attribute.quote_validated_date',
            headerName: "Signé le",
            type: "dateTime",
            getValue: (row) => {
                return row.order_attribute.quote_validated_date;
            }
        }),
    ] as GridColDef[];
}

interface QuoteResaListModel extends ListModelCore {
    quotations: any[]
}

const QuoteResaList = () => {
    const [forceRefresh, setForceRefresh] = useState(0);
    const routeApiController = "project-dahan/dahan/quotations";
    const listcolumns = useListcolumns();

    const [get] = useGetListCore<QuoteResaListModel>(`/${routeApiController}/index-resa`);

    const navigate = useNavigate();
    const seeFolderEvent = useCallback((id: number) => {
        navigate(`/folders/add-edit/${id}`)
    }, [])

    return (
        <>
            <FolderChecktasksModal />
            <AddUserOnEntityModal />
            <ListCore<QuoteResaListModel, any>
                key={forceRefresh}
                getDatas={get}
                defineDatasToSet={(data) => data.quotations}
                getRowId={(row) => row.id}
                rowHeight={100}
                paginationOptionDefault={100}
                paginationsOptions={[100]}
                columns={listcolumns}
                actions={{
                    defaultRoute: "quotations",
                    others: (row) => (
                        <>
                            <Button variant="contained" color="info" onClick={() => seeFolderEvent(row.order_attribute.folder_id)}>Voir</Button>
                            <Box ml={1}>
                                <AddUserOnEntityButton ids={[row.id]} onValidate={() => setForceRefresh(forceRefresh + 1)} type={addUserOnEntityType.GESTIONNER_QUOTE} />
                            </Box>
                        </>
                    )
                }}
                selection={{
                    component: (selectionItems) => (
                        <>
                            <Box p={1}>
                                <AddUserOnEntityButton disabled={selectionItems.length === 0} ids={selectionItems} onValidate={() => setForceRefresh(forceRefresh + 1)} type={addUserOnEntityType.GESTIONNER_QUOTE} />
                            </Box>
                        </>
                    )
                }}
            />
        </>
    );
}

export default QuoteResaList;