import { atom } from "recoil";

export const MailHistoryModalState = atom<{
        open: boolean,
        messageId: string
    }>({
    key: 'MailHistoryModalState',
    default: {
        open: false,
        messageId: ''
    }
});
