import { Box, Divider, Tooltip } from "@mui/material";
import { PropsWithChildren, useMemo } from "react";

interface SupplierTooltipProps extends PropsWithChildren {
    supplier: any,
}

const SupplierTooltip = (props: SupplierTooltipProps) => {

    const { supplier, children } = props;

    const supplier_details = useMemo(() => {
        return (
            <Box style={{ fontSize: "14px" }}>
                <Box>
                    <Box>#{supplier.id}</Box>
                    <Box>
                        {supplier.name}
                    </Box>
                    <Divider />
                    <Box>
                        {supplier.email}
                        {supplier.dahan_emails_compl?.split(';').map((x: string) => <><br />{x}</>) ?? <></>}
                    </Box>
                    <Divider />
                    <Box>
                        <Box>Principal : {supplier.phone}</Box>
                        { supplier.dahan_phone_astreinte && <Box>Astreinte : {supplier.dahan_phone_astreinte}</Box> }
                        {supplier.dahan_phones_compl?.split(';').map((x: string) => <Box>{x}</Box>) ?? <></>}
                    </Box>
                    <Divider />
                    <Box mt={1}>
                        {supplier.comment?.split('\n').map((x: any) => <Box>{x}</Box>)}
                    </Box>
                </Box>
            </Box >
        )
    }, [supplier])


    return (
        <Tooltip title={supplier_details} >
            <Box display={"flex"} alignItems={"center"}>
                {children}
            </Box>
        </Tooltip>
    )
}
export default SupplierTooltip;