
import { AutocompleteOption } from "../common/form/AutocompleteField";
import AddEditFormList from "../common/form/AddEditFormList";

const ProductSupplierView = (props: { id: string|undefined, suppliers: AutocompleteOption[]}) => {
    const { id, suppliers } = props;
    return (
        <AddEditFormList
            fields={[{
                name: "supplier_id",
                type: "autocomplete",
                label: "Fournisseur",
                autocompleteOptions: suppliers,
                xs: 4,
                required: true
            },
            {
                name: "supplier_reference",
                type: "text",
                label: "Référence produit",
                xs: 3,
            },
            {
                name: "amount_tax_excl",
                type: "number",
                label: "Prix HT",
                xs: 3,
            }]}

            hiddenFields={{
                add: ["product_id"],
                edit: ["id"]
            }}

            entity={"products_suppliers"}
            title={"Fournisseurs associés"}
            buttonAdd={{ title: "Ajouter un fournisseur" }}
            defaultItem={{
                product_id: id ?? 0,
                supplier_reference: "",
                amount_tax_excl: 0
            }}
        />
    );
}

export default ProductSupplierView;