import { GridColDef } from "@mui/x-data-grid";
import { Box, Button, Chip, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { useGetListCore } from "../../../stores/core/StoreCore";
import ListCore from "../../../components/common/list/ListCore";
import { CircuitTypeListModel } from "../../classes/CircuitTypeModel";
import { ReminderTagListModel } from "../../classes/ReminderTagModel";
import {TagItem} from "../folders/FolderTagItem";
import FolderTagItem from "../folders/FolderTagItem";


const columns: GridColDef[] = [
    {
        field: 'id',
        headerName: "Id",
        width: 100,
    },
    {
        field: 'name',
        headerName: "Nom",
        width: 300,
        renderCell: ({ row }) => (
            <Box display="flex" flexDirection="column">
                <TagItem tag={row}/>
            </Box>
        )
    },
    {
        field: 'commercial',
        headerName: "Commercial",
        width: 100,
        renderCell: ({ row }) => row.commercial ? <Chip color="primary" label="Oui" /> : <Chip color="default" label="Non" />
    },
    {
        field: 'systeme',
        headerName: "Système",
        width: 100,
        renderCell: ({ row }) => row.systeme ? <Chip color="success" label="Oui" /> : <Chip color="default" label="Non" />
    }
];

const RemiderTagList = () => {

    const routeController = "project-dahan/dahan/reminder-tags";
    const [get] = useGetListCore<ReminderTagListModel>(`${routeController}/index`);

    return (
        <ListCore<ReminderTagListModel, any>
            getDatas={get}
            defineDatasToSet={(data) => data.reminderTags}
            getRowId={(row) => row.id}
            columns={columns}
            actions={{
                defaultRoute: 'reminder-tags',
                add: {
                    title: "Ajouter un tag"
                },
                edit: true,
                remove: {
                    enable: true,
                    route: routeController
                }
            }}
            paginationsOptions={[100]}
        />
    );
}

export default RemiderTagList;