import { SelectOptionsProps } from "../../components/common/form/SelectField";

export enum EnumFolderOrderSupplierContactType {
    GO = 0,
    BACK = 1,
    GO_BACK = 2,
}

export const FolderOrderSupplierContactTypeOptions: SelectOptionsProps[] = [
    { value: EnumFolderOrderSupplierContactType.GO , label: "Aller" },
    { value: EnumFolderOrderSupplierContactType.BACK , label: "Retour" },
    { value: EnumFolderOrderSupplierContactType.GO_BACK , label: "Aller Retour" }
];

export interface FolderOrderSupplierContactModel {
	id: number,
	folder_order_supplier_id: number,
    type: EnumFolderOrderSupplierContactType,
	name: string,
	phone: string,
	comment: string,
}