import { Box, Button, Divider, Grid, Paper, Skeleton, TextField, Typography } from "@mui/material";
import AddEditFormSubmit from "../../../components/common/form/AddEditFormSubmit";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAddEditCore } from "../../../stores/core/StoreCore";
import { ReqStatus } from "../../../stores/core/UseApi";
import moment from "moment";
import { datetimeFormat } from "../../../utils/DateUtils";
import { FormattedMessage } from "react-intl";
import { useTravelInfosConfirmInternal } from "../../stores/QuotationsStore";
import { useAddEditForm } from "../../../components/common/form/AddEditFormPanel";
import { useField } from "react-recoil-form";
import DateField from "../../../components/common/form/DateField";
import InputField from "../../../components/common/form/InputField";
import NumberField from "../../../components/common/form/NumberField";
import TextAreaField from "../../../components/common/form/TextAreaField";
import QuoteCircuit from "./circuit/QuoteCircuit";
import ErrorBoundary from "../../../components/common/error/ErrorBoundary";
import QuoteCircuitPreview from "./circuit/QuoteCircuitPreview";
import AddEditFormCard from "../../../components/common/form/AddEditFormCard";

interface QuoteTravelProps {
    quoteId: string,
    onClose: () => void,
    onValidate: () => void
}

const QuoteTravelValidate = (props: QuoteTravelProps) => {

    const { quoteId, onClose, onValidate } = props;

    const spacing = 1;

    const { getData } = useAddEditCore<any, { order: any }>(`/commercial/orders/add-edit`);
    const [travel, setTravel] = useState<any>();
    const [ready, setReady] = useState(false);

    const travelInfosConfirmInternalAjax = useTravelInfosConfirmInternal();

    const onSubmit = useCallback((request: any) => {
        if (!quoteId) { return; }
        travelInfosConfirmInternalAjax(quoteId, request).then((res) => {
            if (res.status === ReqStatus.SUCCESS) {
                onValidate();
            }
        })
    }, []);


    const { handleSubmit, resetInitialValues, isDirty } = useAddEditForm({
        onSubmit
    });

    const hasChanges = useCallback((valueInitial: any, valueCustomer: any) => {
        return {
            "& .MuiInputBase-input:disabled": {
                WebkitTextFillColor: "rgba(0, 0, 0, 0.87)"
            },
            "& .MuiOutlinedInput-notchedOutline": (valueInitial == valueCustomer) ? {} : { borderColor: "red !important", color: "black !important" },
            "& .MuiInputLabel-root": {
                color: "rgba(0, 0, 0, 0.87)"
            }
        };
    }, []);

    useEffect(() => {
        setReady(false);
        getData(quoteId).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data && res.data.order && Array.isArray(res.data.order.travels) && res.data.order.travels.length > 0) {
                resetInitialValues(res.data.order.travels[0].order_product_attribute);
                setTravel(res.data.order.travels[0].order_product_attribute);
                setReady(true);
            } else {
                onClose();
            }
        })
    }, [quoteId]);

    const circuitInitial = useMemo(() => {
        return travel && travel.circuit ? JSON.parse(travel.circuit) : { days: [] }
    }, [travel?.circuit])

    return (
        <form id="quoteTravelModal" onSubmit={handleSubmit} >
            <Paper variant="outlined" sx={{ overflow: 'auto', height: "100%", maxHeight: (ready && travel.is_roundtrip) ? "750px" : "750px" }} >
                <Box px={1} height="90%">
                    <Box mt={1}><Typography variant="h5" mb={2}>Validation devis</Typography></Box>
                    <Divider />
                    {!ready && <Skeleton variant="rectangular" height={200} />}
                    {ready && <>
                        <Grid container my={1} spacing={1}>
                            <Grid item xs={6}>
                                <>
                                    <Box><Typography variant="h6" >Devis initial</Typography></Box>
                                    <Box><Typography variant="h6" mb={1}>Aller</Typography></Box>
                                    <Box mt={spacing}>
                                        <DateField
                                            name="departure_date"
                                            label="Date de départ"
                                            disabled={true}
                                            size="small"
                                        />
                                    </Box>

                                    <Box mt={spacing}>
                                        <NumberField
                                            name="departure_pax"
                                            label="Nombre de passager départ"
                                            disabled={true}
                                            size="small"
                                        />
                                    </Box>

                                    <Box mt={spacing}>
                                        <InputField
                                            name="departure_address"
                                            label="Adresse de prise en charge aller"
                                            type="text"
                                            disabled={true}
                                            size="small"
                                        />
                                    </Box>
                                    <Box mt={spacing}>
                                        <InputField
                                            name="departure_arrival_address"
                                            label="Adresse de destination aller"
                                            type="text"
                                            disabled={true}
                                            size="small"
                                        />
                                    </Box>

                                    {travel.is_roundtrip &&
                                        <>
                                            <Box><Typography variant="h6" my={1}>Retour</Typography></Box>
                                            <Box mt={spacing}>
                                                <DateField
                                                    name="return_date"
                                                    label="Date de retour"
                                                    disabled={true}
                                                    size="small"
                                                />
                                            </Box>

                                            <Box mt={spacing}>
                                                <NumberField
                                                    name="return_pax"
                                                    label="Nombre de passager retour"
                                                    disabled={true}
                                                    size="small"
                                                />
                                            </Box>

                                            <Box mt={spacing}>
                                                <InputField
                                                    name="return_address"
                                                    label="Adresse de prise en charge retour"
                                                    type="text"
                                                    disabled={true}
                                                    size="small"
                                                />
                                            </Box>
                                            <Box mt={spacing}>
                                                <InputField
                                                    name="return_arrival_address"
                                                    label="Adresse de destination retour"
                                                    type="text"
                                                    disabled={true}
                                                    size="small"
                                                />
                                            </Box>
                                        </>}

                                    <Box><Typography variant="h6" my={1}>Contact Aller</Typography></Box>
                                    <Box mt={spacing}>
                                        <TextField
                                            label="Nom du contact"
                                            type="text"
                                            disabled={true}
                                            value={""}
                                            fullWidth
                                            size="small"
                                        />
                                    </Box>

                                    <Box mt={spacing}>
                                        <TextField
                                            label="Prénom du contact"
                                            type="text"
                                            disabled={true}
                                            value={""}
                                            fullWidth
                                            size="small"
                                        />
                                    </Box>

                                    <Box mt={spacing}>
                                        <TextField
                                            label="Téléphone 1"
                                            type="text"
                                            disabled={true}
                                            value={""}
                                            fullWidth
                                            size="small"
                                        />
                                    </Box>

                                    <Box mt={spacing}>
                                        <TextField
                                            label="Téléphone 2"
                                            type="text"
                                            disabled={true}
                                            value={""}
                                            fullWidth
                                            size="small"
                                        />
                                    </Box>

                                    {travel.is_roundtrip &&
                                        <>
                                            <Box><Typography variant="h6" my={1}>Contact Retour</Typography></Box>
                                            <Box mt={spacing}>
                                                <TextField
                                                    label="Nom du contact"
                                                    type="text"
                                                    disabled={true}
                                                    value={""}
                                                    fullWidth
                                                    size="small"
                                                />
                                            </Box>

                                            <Box mt={spacing}>
                                                <TextField
                                                    label="Prénom du contact"
                                                    type="text"
                                                    disabled={true}
                                                    value={""}
                                                    fullWidth
                                                    size="small"
                                                />
                                            </Box>

                                            <Box mt={spacing}>
                                                <TextField
                                                    label="Téléphone 1"
                                                    type="text"
                                                    disabled={true}
                                                    value={""}
                                                    fullWidth
                                                    size="small"
                                                />
                                            </Box>

                                            <Box mt={spacing}>
                                                <TextField
                                                    label="Téléphone 2"
                                                    type="text"
                                                    disabled={true}
                                                    value={""}
                                                    fullWidth
                                                    size="small"
                                                />
                                            </Box>
                                        </>
                                    }

                                    <Box><Typography variant="h6" my={1}>Détails</Typography></Box>
                                    <Box mt={spacing}>
                                        <TextAreaField
                                            name="comment"
                                            label="Commentaire du devis"
                                            disabled={true}
                                            size="small"
                                            maxRows={12}
                                        />
                                    </Box>
                                </>
                            </Grid>
                            <Grid item xs={6}>
                                <>
                                    <Box><Typography variant="h6" >Modification devis</Typography></Box>

                                    <Box><Typography variant="h6" mb={1}>Aller</Typography></Box>
                                    <Box mt={spacing} sx={hasChanges(travel.departure_date, travel.customer_departure_date)}>
                                        <DateField
                                            name="customer_departure_date"
                                            label="Date de départ"
                                            size="small"
                                        />
                                    </Box>

                                    <Box mt={spacing} sx={hasChanges(travel.departure_pax, travel.customer_departure_pax)}>
                                        <NumberField
                                            name="customer_departure_pax"
                                            label="Nombre de passager départ"
                                            size="small"
                                        />
                                    </Box>

                                    <Box mt={spacing} sx={hasChanges(travel.departure_address, travel.customer_departure_address)}>
                                        <InputField
                                            name="customer_departure_address"
                                            label="Adresse de prise en charge aller"
                                            type="text"
                                            size="small"
                                        />
                                    </Box>
                                    <Box mt={spacing} sx={hasChanges(travel.departure_arrival_address, travel.customer_departure_arrival_address)}>
                                        <InputField
                                            name="customer_departure_arrival_address"
                                            label="Adresse de destination aller"
                                            type="text"
                                            size="small"
                                        />
                                    </Box>

                                    {travel.is_roundtrip && <>
                                        <Box><Typography variant="h6" my={1}>Retour</Typography></Box>
                                        <Box mt={spacing} sx={hasChanges(travel.return_date, travel.customer_return_date)}>
                                            <DateField
                                                name="customer_return_date"
                                                label="Date de retour"
                                                size="small"
                                            />
                                        </Box>

                                        <Box mt={spacing} sx={hasChanges(travel.return_pax, travel.customer_return_pax)}>
                                            <NumberField
                                                name="customer_return_pax"
                                                label="Nombre de passager retour"
                                                size="small"
                                            />
                                        </Box>

                                        <Box mt={spacing} sx={hasChanges(travel.return_address, travel.customer_return_address)}>
                                            <InputField
                                                name="customer_return_address"
                                                label="Adresse de prise en charge retour"
                                                type="text"
                                                size="small"
                                            />
                                        </Box>
                                        <Box mt={spacing} sx={hasChanges(travel.return_arrival_address, travel.customer_return_arrival_address)}>
                                            <InputField
                                                name="customer_return_arrival_address"
                                                label="Adresse de destination retour"
                                                type="text"
                                                size="small"
                                            />
                                        </Box>
                                    </>}
                                    <Box><Typography variant="h6" my={1}>Contact Aller</Typography></Box>

                                    <Box mt={spacing} sx={hasChanges("", travel.customer_contact_last_name)}>
                                        <InputField
                                            name="customer_contact_last_name"
                                            label="Nom du contact"
                                            type="text"
                                            size="small"
                                        />
                                    </Box>

                                    <Box mt={spacing} sx={hasChanges("", travel.customer_contact_first_name)}>
                                        <InputField
                                            name="customer_contact_first_name"
                                            label="Prénom du contact"
                                            type="text"
                                            size="small"
                                        />
                                    </Box>



                                    <Box mt={spacing} sx={hasChanges("", travel.customer_contact_phone1)}>
                                        <InputField
                                            name="customer_contact_phone1"
                                            label="Téléphone 1"
                                            type="text"
                                            size="small"
                                        />
                                    </Box>

                                    <Box mt={spacing} sx={hasChanges("", travel.customer_contact_phone2)}>
                                        <InputField
                                            name="customer_contact_phone2"
                                            label="Téléphone 2"
                                            type="text"
                                            size="small"
                                        />
                                    </Box>

                                    <Box><Typography variant="h6" my={1}>Contact Retour</Typography></Box>

                                    <Box mt={spacing} sx={hasChanges("", travel.customer_return_contact_last_name)}>
                                        <InputField
                                            name="customer_return_contact_last_name"
                                            label="Nom du contact"
                                            type="text"
                                            size="small"
                                        />
                                    </Box>

                                    <Box mt={spacing} sx={hasChanges("", travel.customer_return_contact_first_name)}>
                                        <InputField
                                            name="customer_return_contact_first_name"
                                            label="Prénom du contact"
                                            type="text"
                                            size="small"
                                        />
                                    </Box>



                                    <Box mt={spacing} sx={hasChanges("", travel.customer_return_contact_phone1)}>
                                        <InputField
                                            name="customer_return_contact_phone1"
                                            label="Téléphone 1"
                                            type="text"
                                            size="small"
                                        />
                                    </Box>

                                    <Box mt={spacing} sx={hasChanges("", travel.customer_return_contact_phone2)}>
                                        <InputField
                                            name="customer_return_contact_phone2"
                                            label="Téléphone 2"
                                            type="text"
                                            size="small"
                                        />
                                    </Box>

                                    <Box><Typography variant="h6" my={1}>Détails</Typography></Box>

                                    <Box mt={spacing} sx={hasChanges(travel.comment, travel.customer_contact_details)}>
                                        <TextAreaField
                                            name="customer_contact_details"
                                            label="Détails"
                                            size="small"
                                            maxRows={12}
                                        />
                                    </Box>
                                </>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <AddEditFormCard title="Circuit initial" isExpand={false}  withExpand={true} sx={{ paper: { p: 0 }}} >
                                <QuoteCircuitPreview circuit={circuitInitial} />
                            </AddEditFormCard>
                        </Grid>

                        <Grid item xs={12} mt={1}>
                            <ErrorBoundary title='Circuit modifié'>
                                <QuoteCircuit title="Circuit modifié" circuitJson={travel.customer_circuit} entityName={"customer_circuit"} />
                            </ErrorBoundary>
                        </Grid>

                        <Divider />
                        <Box mt={1}>
                            <AddEditFormSubmit
                                returnAction={{ disabled: true }}
                                cancelEvent={() => { resetInitialValues(); onClose(); }}
                                disabled={false}
                            />
                        </Box>
                    </>}
                </Box>
            </Paper>
        </form>
    );
}

export default QuoteTravelValidate;