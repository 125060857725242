import { Box, Tooltip } from "@mui/material"
import TooltipComment from "./TooltipComment";
import InfoIcon from '@mui/icons-material/Info';

const TooltipCommentIcon = (props: { value: string, fontSize?: number }) => {
	return (
		<Tooltip title={<TooltipComment value={props.value} fontSize={props.fontSize} />}>
			<InfoIcon color={props.value && props.value.length > 0 ? "primary" : "disabled"} fontSize="large" />
		</Tooltip>
	)
}

export default TooltipCommentIcon;

