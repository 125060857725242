import { useCallback } from "react";
import { useApi } from "../../stores/core/UseApi";
import { useAddEditCore, useDelete } from "../../stores/core/StoreCore";
import { FolderReminderModel } from "../classes/FolderReminderModel";
import { Tag } from "../classes/FolderTagModel";

const routeController = 'project-dahan/dahan/folder-reminders';

export const useFolderReminderConfirm = () => {
    const [callCore] = useApi<undefined, undefined>(
        {
            route:  `${routeController}/confirm`,
            instanceAxiosMethod: "POST"
        }
    );
    const call = useCallback((id : number) => {
        return callCore(undefined, `/${id}` );
    }, [callCore]);
    return call;
};

export const useAddEditFolderReminder = () => {
    return useAddEditCore<FolderReminderModel, FolderReminderModel[]>(`${routeController}/add-edit`);
};

interface GetTagListResponse{
    tags: Tag[]
}
export const useGetReminderTagList = () => {
    const [callCore] = useApi<GetTagListResponse, undefined>(
        {
            route:  `${routeController}/get-tag-list`,
            instanceAxiosMethod: "GET"
        }
    );
    const call = useCallback(() => {
        return callCore();
    }, [callCore]);
    return call;
};