import { useCallback, useState } from "react";
import { useGetListCore } from "../../../stores/core/StoreCore";
import { ReqStatus } from "../../../stores/core/UseApi";
import { GridColDef } from "@mui/x-data-grid";
import { Box, Button, Chip, Tooltip } from "@mui/material";
import ListCore from "../../../components/common/list/ListCore";
import { BrevoMailConfigsListModel, useGetSynchronisation } from "../../stores/BrevoMailStore";
import { useSetRecoilState } from "recoil";
import { WaitingModalState } from "../../../states/ModalState";
import { BrevoMailConfigsState } from "../../states/MailState";
import { BrevoMailConfigEntityOptions, BrevoMailConfigVisibilityOptions } from "../../classes/BrevoMailConfig";
import InfoIcon from "@mui/icons-material/Info";


const BrevoMailConfigPdfsAndLinks = (props: { template: any }) => {
    const { template } = props;
    return (
        <Box height={"100%"} width={"100%"} sx={{ overflowY: "auto" }} >
            <Box display="flex" flexDirection={"column"}>
                {template?.pdf_quote && <Box>PDF Devis</Box>}
                {template?.pdf_proforma && <Box>PDF Proforma</Box>}
                {template?.pdf_travelinfos && <Box>PDF Infos voyage</Box>}
                {template?.pdf_roadmap && <Box>PDF Feuille de route</Box>}
                {template?.pdf_bill && <Box>PDF Facture</Box>}
                {template?.link_quote_valide && <Box>Lien Sign Devis</Box>}
                {template?.link_travelinfos_confirm && <Box>Lien Infos voyage</Box>}
            </Box>
        </Box>
    )
}

const columns: GridColDef[] = [
    {
        field: 'id',
        headerName: "Id",
        editable: false,
        filterable: false,
        width: 30,
    },
    {
        field: 'name',
        headerName: "Nom / sujet",
        editable: false,
        filterable: false,
        width: 600,
        renderCell: ({ row }) => (
            <Box display="flex" alignItems="center">
                

                <Tooltip title={<Box><Box>{row.name}</Box><Box>Sujet : {row.subject}</Box></Box>}>
                    <InfoIcon color="primary" />
                </Tooltip>

                <Box pl={1}>{row.name_internal ? row.name_internal : row.name}</Box>
            </Box>
        )
    },
    {
        field: 'is_active',
        headerName: "Actif",
        editable: false,
        filterable: false,
        width: 80,
        renderCell: ({ row }) => row.is_active ? <Chip color="primary" label="Oui" /> : <Chip color="default" label="Non" />
    },
    {
        field: 'entity',
        headerName: "Entité",
        editable: false,
        filterable: false,
        width: 180,
        renderCell: ({ row }) => {
            const label = BrevoMailConfigEntityOptions.find((x) => x.value == row.entity)?.label ?? "";
            const color = row.entity > 0 ? "primary" : "warning";
            return (
                <>
                    <Chip color={color} label={label} />
                </>
            );
        }
    },
    {
        field: 'visibility_by_role',
        headerName: "Visibilité par rôle",
        editable: false,
        filterable: false,
        width: 180,
        valueGetter: ({ row }) => BrevoMailConfigVisibilityOptions.find((x) => x.value == row.visibility_by_role)?.label ?? ""
    },
    {
        field: 'pdfs_and_links',
        headerName: "PDFS / LIENS",
        editable: false,
        filterable: false,
        width: 180,
        renderCell: ({ row }) => (
            <BrevoMailConfigPdfsAndLinks template={row} />
        )
    }
];

const BrevoMailConfigsList = () => {
    const routeProject = "project-dahan/dahan/";
    const routeController = "brevo-mail-configs";
    const routeListe = `${routeProject}${routeController}`;
    const [get] = useGetListCore<BrevoMailConfigsListModel>(`${routeListe}/index`);
    const getSynchronisation = useGetSynchronisation();
    const setWaitingModalState = useSetRecoilState(WaitingModalState);
    const [forceRefresh, setForceRefresh] = useState(0);
    const [synchronisating, setSynchronisating] = useState(false);
    const setTemplates = useSetRecoilState(BrevoMailConfigsState);

    const handleSubmit = useCallback(() => {
        setWaitingModalState({
            open: true,
            text: "Synchronisation des templates en cours..."
        });
        setSynchronisating(true);
        getSynchronisation().then((res: any) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                //Reload la liste
                setForceRefresh((v) => v + 1);

                get(500, 0).then((res) => {
                    if (res.status === ReqStatus.SUCCESS && res.data && res.data.brevoMailConfigs) {
                        setTemplates([]);
                    }
                });
            }
        }).finally(() => {
            setWaitingModalState({
                open: false,
                text: ""
            });
            setSynchronisating(false);
        })
    }, [getSynchronisation])

    return (
        <Box height={"100%"}>
            <Box display="flex" justifyContent="flex-end" m={2} mb={1}>
                <Button color="info" variant="contained" onClick={handleSubmit} disabled={synchronisating}>Synchronisation</Button>
            </Box>
            <ListCore<BrevoMailConfigsListModel, any>
                getDatas={get}
                key={forceRefresh}
                paginationOptionDefault={10}
                defineDatasToSet={(data) => data.brevoMailConfigs}
                getRowId={(row) => row.id}
                columns={columns}
                actions={{
                    defaultRoute: routeController,
                    edit: true,
                    remove: false
                }}
            />
        </Box>
    );
}

export default BrevoMailConfigsList;