
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { DragDropContext, Draggable, DraggableProvided, Droppable } from 'react-beautiful-dnd';
import AddEditFormCard from '../../../../components/common/form/AddEditFormCard';
import { QuoteCircuitDragIcon } from './QuoteCircuitCommon';
import { useRecoilState, useRecoilValue } from 'recoil';
import { QuoteCircuitDayStepAddressSelector, QuoteCircuitDayStepSelector, QuoteCircuitTypesState } from '../../../states/QuoteCircuitState';
import { v4 as uuidv4 } from 'uuid';
import { QuoteCircuitDayStepAddressModel } from '../../../classes/QuoteModel';
import CloseIcon from '@mui/icons-material/Close';
import SelectField, { SelectOptionsProps } from '../../../../components/common/form/SelectField';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CopyButton from '../../../../components/common/button/CopyButton';
import TomTomAddressInput from '../../tomtom/TomTomAddressInput';

interface QuoteCircuitDayStepAddressProps {
	id: string,
	stepId: string
	dayId: string,
	provided: DraggableProvided,
	deleteEvent: () => void,
}

const QuoteCircuitDayStepAddress = (props: QuoteCircuitDayStepAddressProps) => {
	const { id, stepId, dayId, provided, deleteEvent } = props;
	const types = useRecoilValue(QuoteCircuitTypesState);

	const [address, setAddress] = useRecoilState(QuoteCircuitDayStepAddressSelector({ id: id, dayId: dayId, stepId: stepId }));

	return (
		<Box
			ref={props.provided.innerRef}
			{...props.provided.draggableProps}
			mb={1}
		>
			<Box display="flex" alignItems={"center"} /* p={0} border={"1px solid black"} borderRadius={"10px"} */>

				<QuoteCircuitDragIcon
					iconColor='secondary'
					dragHandleProps={provided.dragHandleProps}
				/>

				<Box width={300} ml={1}>
					<FormControl fullWidth>
						<InputLabel size={"small"}>Type</InputLabel>
						<Select
							size={"small"}
							label={"Type"}
							defaultValue={address?.type}
							onBlur={(e) => {
								const _value = e.target.value !== null && !isNaN(+e.target.value) ? +e.target.value : null;
								setAddress((address) => {
									if (!address) { return address; }
									return {
										...address,
										type: _value
									};
								})
							}}
						>

							<MenuItem value={null as any}></MenuItem>
							{types.map((option, i) => (
								<MenuItem key={i} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Box>

				<TomTomAddressInput
					label="Lieu"
					size="small"
					value={address?.address ?? ""}
					onChange={(newValue) =>
						setAddress((address) => {
							if (!address) { return address; }
							return {
								...address,
								address: newValue
							};
						})}
				/>
				<Box ml={1}>
					<IconButton sx={{ width: "20px", height: "20px" }} onClick={() => deleteEvent()}>
						<CloseIcon color="error" />
					</IconButton>
				</Box>


			</Box>
		</Box >
	)
}

export default QuoteCircuitDayStepAddress;


