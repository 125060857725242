import { Box, Button } from "@mui/material";
import moment from "moment";
import { useCallback, useMemo } from "react";
import { ListSearchModelCore } from "../../../classes/list/ListModel";

interface ListSearchFilterDateRangeProps {
	loading: boolean,
	period: "week" | "month",
	setSearch: (callback: (value: ListSearchModelCore | undefined) => ListSearchModelCore | undefined) => void
	refresh: (search: any) => void,
	fieldName: string
}
const ListSearchFilterDateRange = (props: ListSearchFilterDateRangeProps) => {

	const { loading, period, setSearch, refresh, fieldName } = props;

	const formatDate = "YYYY-MM-DD";
	const fieldNameEnd = useMemo(() => {
		return "_-_"+fieldName;
	},[fieldName])

	const setValueAndRefresh = useCallback((search: any, newDateStart: string, newDateEnd: string) => {
		const searchUpdated = {
			...search,
			[fieldName]: {
				...(search[fieldName]),
				value: newDateStart
			},
			[fieldNameEnd]: {
				...(search[fieldNameEnd]),
				value: newDateEnd
			}
		}

		refresh(searchUpdated);
		return searchUpdated;
	},[refresh]);

	const changeDaysEvent = useCallback((days: number) => {
		setSearch((search: any) => {
			if (!search[fieldName].value || !search[fieldNameEnd].value) {
				return search;
			}
			const newDateStart = moment(search[fieldName].value, formatDate).add(days, 'days').format(formatDate);
			const newDateEnd = moment(newDateStart, formatDate).add(6, 'days').format(formatDate);

			return setValueAndRefresh(search, newDateStart, newDateEnd);
		});
	}, [setSearch, refresh])

	const changeMonthEvent = useCallback((month: number) => {
		setSearch((search: any) => {
			if (!search[fieldName].value || !search[fieldNameEnd].value) {
				return search;
			}

			const dateStart = moment(search[fieldName].value, formatDate);
			const monthTarget = dateStart.clone().add(month, 'month');
			const newDateStart = monthTarget.clone().startOf('month').format(formatDate)
			const newDateEnd = monthTarget.clone().endOf('month').format(formatDate);

			return setValueAndRefresh(search, newDateStart, newDateEnd);
		});
	}, [setSearch, refresh])

	return (
		<Box mt={1} display="flex" justifyContent="center">

			{period === "week" &&
				<>
					<Button variant="contained" disabled={loading} onClick={() => { changeDaysEvent(-7); }}>Semaine précédente</Button>
					<Box ml={1}>
						<Button variant="contained" disabled={loading} onClick={() => { changeDaysEvent(7); }}>Semaine suivante</Button>
					</Box>
				</>
			}

			{period === "month" &&
				<>
					<Button variant="contained" disabled={loading} onClick={() => { changeMonthEvent(-1); }}>Mois précédent</Button>
					<Box ml={1}>
						<Button variant="contained" disabled={loading} onClick={() => { changeMonthEvent(1); }}>Mois suivant</Button>
					</Box>
				</>
			}
		</Box>
	)
}

export default ListSearchFilterDateRange;