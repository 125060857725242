import { GridColDef } from "@mui/x-data-grid";
import { Box, Button, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { useGetListCore } from "../../stores/core/StoreCore";
import ListCore from "../common/list/ListCore";
import { CountryListModel } from "../../classes/countries/CountryModel";

const columns: GridColDef[] = [
    {
        field: 'id',
        headerName: "Code",
        minWidth: 100,
        flex: 1,
    },
    {
        field: 'label',
        headerName: "Nom",
        minWidth: 100,
        flex: 1,
    },

    {
        field: 'vat',
        headerName: "TVA",
        minWidth: 100,
        flex: 1,
        valueGetter: ({ row }) => `${row.vat} %`
    }
];


const CountryList = () => {

    const routeController = "commercial/countries";
    const [get] = useGetListCore<CountryListModel>(`${routeController}/index`);

    return (
        <ListCore<CountryListModel, any>
            getDatas={get}
            defineDatasToSet={(data) => data.countries}
            getRowId={(row) => row.id}
            columns={columns}
            actions={{
                defaultRoute: routeController,
                add: {
                    title: "Ajouter un pays"
                },
                edit: true,
                remove: false
            }}
        />
    );
}

export default CountryList;