import {
    FormControl,
    FormHelperText,
    TextField
} from "@mui/material";
import { useMemo } from "react";
import { useField } from "react-recoil-form";

interface IFieldProps {
    name: string;
    label?: string;
    required?: boolean;
    disabled?: boolean;
}

export default function FileField(props: IFieldProps) {
    const { name, label, required, disabled } = props;
    const { setFieldValue, error } = useField<File>({
        name,
        validate: (value) => {
            if (required && !value) {
                return `Le champ ${label} est requis`;
            }
        }
    });
    return (
        <FormControl error={!!error} >
            <TextField
                type="file"
                name={name}
                disabled={disabled ?? false}
                required={required}
                label={label}
                onChange={(e: any) => {
                    if (e.target.files && e.target.files.length === 1) {
                        setFieldValue(e.target.files[0]);
                    }
                }}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            {error && <FormHelperText id="my-helper-text">{error}</FormHelperText>}
        </FormControl>
    );
}
