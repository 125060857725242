import { useCallback } from "react";
import { LoginRequest } from "../classes/users/LoginRequest";
import { useApi, GenericResponse, ReqStatus } from "./core/UseApi";
import { UserModel } from "../classes/users/UserModel";
import { ForgotPasswordRequest } from "../classes/users/ForgotPasswordRequest";
import { NewPasswordRequest } from "../classes/users/NewPasswordRequest";

interface LoginConfig{
    cookieSettings: {
        hide?: boolean,
        checked?: boolean
    }
}
export const useLoginConfig = function (): [() => Promise<GenericResponse<LoginConfig>>, ReqStatus] {
    const [callCore, status] = useApi<LoginConfig, undefined>(
        {
            route: 'users/login',
            instanceAxiosMethod : 'GET'
        }
    );
    const call = useCallback(() => {
        return callCore();
    }, [callCore]);

    return [call, status];
};

export const useLogin = function (): [(data : LoginRequest) => Promise<GenericResponse<{}>>, ReqStatus] {
    const [callCore, status] = useApi<{}, LoginRequest>(
        {
            route: 'users/login',
            instanceAxiosMethod : 'POST'
        }
    );
    const call = useCallback((data : LoginRequest) => {
        return callCore(data);
    }, [callCore]);

    return [call, status];
};

interface IsAuthResponse {
    auth:boolean, 
    token: string, 
    user: UserModel | null,
    authorizations: {[controller: string]: string[]} | null
}
export const useIsAuth = function (): [() => Promise<GenericResponse<IsAuthResponse>>, ReqStatus] {
    const [callCore, status] = useApi<IsAuthResponse, undefined>(
        {
            route: 'users/logged',
            instanceAxiosMethod : 'GET'
        }
    );
    const call = useCallback(() => {
        return callCore();
    }, [callCore]);

    return [call, status];
};

export const useLogout = function (): [() => Promise<GenericResponse<boolean>>, ReqStatus] {
    const [callCore, status] = useApi<boolean, undefined>(
        {
            route: 'users/logout',
            instanceAxiosMethod : 'POST'
        }
    );
    const call = useCallback(() => {
        return callCore();
    }, [callCore]);

    return [call, status];
};

export const useForgotPassword = function (): [(request: ForgotPasswordRequest) => Promise<GenericResponse<{}>>, ReqStatus] {
    const [callCore, status] = useApi<{}, ForgotPasswordRequest>(
        {
            route: 'users/forgot-password',
            instanceAxiosMethod : 'POST'
        }
    );
    const call = useCallback((request: ForgotPasswordRequest) => {
        return callCore(request);
    }, [callCore]);

    return [call, status];
};

interface NewPasswordResponse{
    user: UserModel
}
export const useGetNewPassword = function (route : "activate" | "new-password"): [(guid: string) => Promise<GenericResponse<NewPasswordResponse>>, ReqStatus] {
    const [callCore, status] = useApi<NewPasswordResponse, undefined>(
        {
            route: `users/${route}`,
            instanceAxiosMethod : 'GET'
        }
    );
    const call = useCallback((guid: string) => {
        return callCore(undefined, `/${guid}`);
    }, [callCore]);
    return [call, status];
};

export const usePostNewPassword = function (route : "activate" | "new-password"): [(request: NewPasswordRequest, guid: string) => Promise<GenericResponse<NewPasswordResponse>>, ReqStatus] {
    const [callCore, status] = useApi<NewPasswordResponse, NewPasswordRequest>(
        {
            route: `users/${route}`,
            instanceAxiosMethod : 'POST'
        }
    );
    const call = useCallback((request: NewPasswordRequest, guid: string) => {
        return callCore(request, `/${guid}`);
    }, [callCore]);
    return [call, status];
};
