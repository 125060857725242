import { Alert, Box, Grid } from "@mui/material";

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { QuoteCircuitDayModel, QuoteCircuitDayStepAddressModel, QuoteCircuitDayStepModel, QuoteCircuitModel } from "../../../classes/QuoteModel";
import { QuoteCircuitState, QuoteCircuitTypesState } from "../../../states/QuoteCircuitState";
import { useRecoilValue } from "recoil";
import { TreeItem, TreeView } from "@mui/lab";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { StringToColor } from "../../../../utils/StringUtils";
import dayjs from "dayjs";

// Composant Liste
const QuoteCircuitPreview = (props: { circuit: QuoteCircuitModel }) => {

	const { circuit } = props
	const [defaultExpanded, setDefaultExpanded] = useState<string[] | null>(null);
	const types = useRecoilValue(QuoteCircuitTypesState);

	useEffect(() => {
		const ids: string[] = [];
		circuit.days.forEach((day) => {
			ids.push(day.id);
			day.steps.forEach((step) => {
				ids.push(step.id);
			})
		});
		setDefaultExpanded(ids);
	},[circuit.days])

	const getLabelOfDay = useCallback((day: QuoteCircuitDayModel) => {

		const date = day.date ? dayjs(day.date).tz().format("DD-MM-YYYY") : '';

		let circuit_day_kms = 0;

		day.steps.forEach((step) => {
			if(step.distance){
				circuit_day_kms += step.distance * ( (step.cars && step.cars > 1) ? step.cars : 1);
			}
		});

		return (
			<Box>
				{date} - {day.title} - {circuit_day_kms} km
			</Box>
		);
	}, []);

	const getLabelOfStep = useCallback((step: QuoteCircuitDayStepModel) => {
		return (
			<Box>
				{`${step?.hour ? `${step.hour} - ` : ''} ${step?.pax ? `pax : ${step.pax} - ` : ''} ${step?.cars ? `${step.cars} cars - ` : ''} ${step?.distance ? `${step.distance} km` : ''} - ${step?.distance ? `Total : ${step.distance * (( step && step.cars && step.cars > 1) ? step.cars : 1)} km` : ''}`}
			</Box>
		);
	}, []);

	const getLabelOfAddress = useCallback((address: QuoteCircuitDayStepAddressModel) => {

		const typeLabel = types.find((t) => t.value == address.type)?.label ?? null;
		return (
			<Box display="flex">
				<Box minWidth={150} fontWeight={"bold"} color={ typeLabel ? StringToColor(typeLabel) : 'warning'}>({ typeLabel ?? "Non défini" })</Box> {address.address}
			</Box>
		);
	}, [types]);

	return (
		<Grid container>
			{ circuit && circuit.days.length > 0 && defaultExpanded !== null ?
				<TreeView
					defaultCollapseIcon={<ExpandMoreIcon />}
					defaultExpandIcon={<ChevronRightIcon />}
					defaultExpanded={defaultExpanded}
				>
					{circuit.days.map((day) => (
						<TreeItem key={day.id} nodeId={day.id} label={getLabelOfDay(day)}>
							{day.steps.map((step) => (
								<TreeItem key={step.id} nodeId={step.id} label={getLabelOfStep(step)}>
									{step.addresses.map((address) => (
										<TreeItem key={address.id} nodeId={address.id} label={getLabelOfAddress(address)} />
									))}
								</TreeItem>
							))}
						</TreeItem>
					))}
				</TreeView>
				:
				<Alert color="info">Le circuit n'a pas été défini dans le devis</Alert>
			}
		</Grid>
	)
}

export default QuoteCircuitPreview;