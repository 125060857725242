import { Box, Button, CircularProgress, Divider, Grid, Paper, Typography } from "@mui/material";
import AddEditFormPanel, { useAddEditForm } from "../../../components/common/form/AddEditFormPanel";
import AddEditFormSubmit from "../../../components/common/form/AddEditFormSubmit";
import { Suspense, useCallback, useEffect, useState } from "react";
import InputField from "../../../components/common/form/InputField";
import DateField from "../../../components/common/form/DateField";
import NumberField from "../../../components/common/form/NumberField";
import QuoteTravelDetails from "./QuoteTravelDetails";
import RadioField from "../../../components/common/form/RadioField";
import { SelectOptionsProps } from "../../../components/common/form/SelectField";
import TextAreaField from "../../../components/common/form/TextAreaField";
import { CheckBox } from "@mui/icons-material";
import CheckboxField from "../../../components/common/form/CheckboxField";
import { FormProvider, useField } from "react-recoil-form";
import { QuoteCircuitState } from "../../states/QuoteCircuitState";
import { useRecoilState } from "recoil";
import QuoteTravelGlobalNoteCommercialForm from "./QuoteTravelGlobalNoteCommercialForm";
import React from "react";
import QuoteCircuit from "./circuit/QuoteCircuit";
import ErrorBoundary from "../../../components/common/error/ErrorBoundary";

interface QuoteTravelProps {
    travel: any | undefined,
    onClose: () => void,
    onValidate: (request: any) => void,
    disabled?: boolean,
}

const tarifOptions: SelectOptionsProps[] = [
    { value: 0, label: "Non affiché" },
    { value: 1, label: "Non compris" },
    { value: 2, label: "Compris" },
]

const isNotMad: SelectOptionsProps[] = [
    { value: 0, label: "Non affiché" },
    { value: 1, label: "Affiché" },
]

const cutOff: SelectOptionsProps[] = [
    {value: 0, label: "Non affiché"},
    {value: 1, label:"Afiché"},
]

const useCopyTravelFields = () => {

    const { fieldValue: departureAddress } = useField({ name: 'departure_address' });
    const { fieldValue: departureArrivalAddress } = useField({ name: 'departure_arrival_address' });
    const { fieldValue: departurePax } = useField({ name: 'departure_pax' });

    const { setFieldValue: setReturnAddress } = useField({ name: 'return_address' });
    const { setFieldValue: setReturnArrivalAddress } = useField({ name: 'return_arrival_address' });
    const { setFieldValue: setReturnPax } = useField({ name: 'return_pax' });

    const copyTravelFields = useCallback(() => {
        setReturnAddress(departureArrivalAddress);
        setReturnArrivalAddress(departureAddress);
        setReturnPax(departurePax);
    }, [departureAddress, departureArrivalAddress, departurePax]);

    return copyTravelFields;
}

const QuoteTravel = (props: QuoteTravelProps) => {

    const { travel, onClose, onValidate, disabled } = props;

    const onSubmit = useCallback((request: any) => {
        onValidate(request);
        return request;
    }, [onValidate]);

    const { handleSubmit, resetInitialValues, isDirty } = useAddEditForm({
        onSubmit
    });

    const copyTravelFields = useCopyTravelFields();

    useEffect(() => {
        resetInitialValues(
            {
                ...travel,
                toll: travel.toll ?? 2,
                lodging: travel.lodging ?? 1,
                parking: travel.parking ?? 1,
                driver_meal: travel.driver_meal ?? 1,
                is_roundtrip: travel.is_roundtrip ?? false,
            }
        );
    }, [travel])

    const { fieldValue: isRoundTripValue } = useField({ name: "is_roundtrip" });
    const { fieldValue: departureDate } = useField({ name: 'departure_date' });

    return (
        <Paper variant="outlined" sx={{ p: { xs: 2, md: 2 }, overflow: 'auto', height: "100%" }} >
            <Box><Typography variant="h5" mb={2}>{(!travel ? "Ajouter " : "Modifier") + " un trajet"}</Typography></Box>
            <form id={"quoteTravelForm"} onSubmit={handleSubmit}>

                <Grid container spacing={2} mb={2} display="flex" alignItems="center">
                    <Grid item xs={12} sm={3}>
                        <CheckboxField
                            name="is_roundtrip"
                            label="Trajet Aller / Retour"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <InputField
                            label="Titre devis"
                            name="reference"
                            type="text"
                        />
                    </Grid>
                </Grid>
                <Divider />
                <QuoteTravelGlobalNoteCommercialForm />
                <Divider />
                <QuoteTravelDetails
                    prefix="departure"
                    disabled={disabled}
                />

                {isRoundTripValue &&
                    <>
                        {!disabled &&
                            <Box display="flex" justifyContent="center" pb={2}>
                                <Button variant="contained" color="secondary" onClick={() => copyTravelFields()}>Copier aller dans retour</Button>
                            </Box>
                        }
                        <Divider />
                        <QuoteTravelDetails
                            prefix="return"
                            disabled={disabled}
                            minDate={departureDate}
                        />
                    </>
                }

                <Divider />

                <Grid item xs={12}>
                    <ErrorBoundary title='Circuit'>
                        <QuoteCircuit disabled={disabled} circuitJson={travel.circuit} />
                    </ErrorBoundary>
                </Grid>

                <Divider />
                <Box my={2}>
                    <Grid container spacing={3} >
                        <Grid item xs={12} sm={6} display="flex" justifyContent={"space-between"}>
                            <RadioField
                                label="Pas de MAD"
                                name="is_not_mad"
                                options={isNotMad}
                                optionType="number"
                            />
                        </Grid>
                        
                        <Grid item xs={12} sm={6} display="flex" justifyContent={"space-between"}>
                            <RadioField
                                label="Coupure 09h"
                                name="cut_off"
                                options={cutOff}
                                optionType="number"
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Divider />
                <Box my={2}>

                    <Grid container spacing={3} >
                        <Grid item xs={12} sm={6} display="flex" justifyContent={"space-between"}>
                            <RadioField
                                label="Hébergement"
                                name="lodging"
                                options={tarifOptions}
                                optionType="number"
                                disabled={disabled}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} display="flex" justifyContent={"space-between"}>
                            <RadioField
                                label="Repas chauffeur"
                                name="driver_meal"
                                options={tarifOptions}
                                optionType="number"
                                disabled={disabled}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} display="flex" justifyContent={"space-between"}>
                            <RadioField
                                label="Péages"
                                name="toll"
                                options={tarifOptions}
                                optionType="number"
                                disabled={disabled}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} display="flex" justifyContent={"space-between"}>
                            <RadioField
                                label="Parking"
                                name="parking"
                                options={tarifOptions}
                                optionType="number"
                                disabled={disabled}
                            />
                        </Grid>

                    </Grid>
                </Box>
                <Divider />
                <Box my={2}>
                    <Box><Typography variant="h6" mb={2}>Informations divers</Typography></Box>
                    <Grid container spacing={3} >
                        <Grid item xs={12} sm={6} display="flex" justifyContent={"space-between"}>
                            <NumberField
                                name={"cars_count"}
                                label="Nombre de cars"
                                required
                                disabled={disabled}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} display="flex" justifyContent={"space-between"}>
                            <NumberField
                                name="drivers_count"
                                label="Nombre de chauffeurs"
                                required
                                disabled={disabled}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Divider />
                <Box my={2}>
                    <Box><Typography variant="h6" mb={2}>Informations de ramassage</Typography></Box>
                    <Box><Typography variant="subtitle2" mb={2}>Indiquez les adresses de ramassage pour l'aller et le retour</Typography></Box>
                    <Grid container spacing={3} >

                        <Grid item xs={12} sm={6} display="flex" justifyContent={"space-between"}>
                            <InputField
                                label="Adresse de ramassage du trajet aller"
                                name="departure_address"
                                type="text"
                                disabled={disabled}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} display="flex" justifyContent={"space-between"}>
                            <InputField
                                label="Adresse de destination du trajet aller"
                                name="departure_arrival_address"
                                type="text"
                                disabled={disabled}
                            />
                        </Grid>

                        {isRoundTripValue &&
                            <><Grid item xs={12} sm={6} display="flex" justifyContent={"space-between"}>
                                <InputField
                                    label="Adresse de ramassage du trajet retour"
                                    name="return_address"
                                    type="text"
                                    disabled={disabled}
                                />
                            </Grid>
                                <Grid item xs={12} sm={6} display="flex" justifyContent={"space-between"}>
                                    <InputField
                                        label="Adresse de destination du trajet retour"
                                        name="return_arrival_address"
                                        type="text"
                                        disabled={disabled}
                                    />
                                </Grid>
                            </>
                        }

                    </Grid>
                </Box>
                <Divider />
                <Box my={2}>
                    <TextAreaField
                        name="comment"
                        label="Descriptif des trajets"
                        maxRows={8}
                        disabled={disabled}
                    />
                    <Typography variant="caption">Attention, ce texte est vu par le fournisseur et le client</Typography>
                </Box>
                <Divider />
                <Box><Typography variant="h6" mb={2}>Tarifs</Typography></Box>
                <Grid item xs={12} lg={6}>
                    <NumberField
                        name="amount_tax_incl"
                        label={"Montant TTC"}
                        disabled={disabled}
                    />
                </Grid>
                <Box mt={1}>
                    <AddEditFormSubmit
                        returnAction={{ disabled: true }}
                        cancelEvent={() => { resetInitialValues(); onClose(); }}
                        disabled={!isDirty}
                    />
                </Box>

            </form>
        </Paper>
    );
}

export default QuoteTravel;