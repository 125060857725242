import { useNavigate, useParams } from "react-router-dom";
import { useAddEditSupplier, useGetSupplier } from "../../stores/SuppliersStore";
import { useEffect, useMemo, useState } from "react";
import { ReqStatus } from "../../stores/core/UseApi";
import { Box, Divider, Grid, TextField } from "@mui/material";
import InputField from "../common/form/InputField";
import TextAreaField from "../common/form/TextAreaField";
import AddEditFormPanel, { useAddEditForm } from "../common/form/AddEditFormPanel";
import AddEditFormSubmit from "../common/form/AddEditFormSubmit";
import AutocompleteField, { AutocompleteOption } from "../common/form/AutocompleteField";
import { SupplierModel } from "../../classes/suppliers/SupplierModel";
import { useField, useForm, useFormValues, useIsDirty } from "react-recoil-form";
import AddEditFormList from "../common/form/AddEditFormList";
import { useSetRecoilState } from "recoil";
import { NavigateState, NavigationBlockedState } from "../../states/MenuState";
import { useAddEditCore, useViewCore } from "../../stores/core/StoreCore";
import SelectField, { SelectOptionsProps } from "../common/form/SelectField";
import AddEditForm from "../common/form/AddEditForm";
import AddEditFormCard from "../common/form/AddEditFormCard";
import { mapArrayForForm } from "../../utils/DataUtils";
import ProductSupplierView from "./ProductSupplierView";
import ProductIntegratedView from "./ProductIntegratedView";
import ProductInfosView from "./ProductInfosView";

interface ProductResponse {
    product: any,
    categories: { [id: string]: string },
    productTypes: { [id: string]: string },
    products: any[],
    suppliers: any[],
}

export const useProductView = (customFields ?: string[]) => {
    let { id } = useParams();

    const setNavigateState = useSetRecoilState(NavigateState);
    const listRoute = "/commercial/products";

    const { getData, addEditData } = useAddEditCore<any, ProductResponse>(`${listRoute}/add-edit`);
    const getProductTypeData = useViewCore<any>(`/commercial/product-types/add-edit`);

    const [productTypeOptions, setProductTypeOptions] = useState<SelectOptionsProps[]>([]);
    const [categoryOptions, setCategoryOptions] = useState<SelectOptionsProps[]>([]);
    const [suppliers, setSuppliers] = useState<AutocompleteOption[]>([]);
    const [products, setProducts] = useState<AutocompleteOption[]>([]);
    const [data, setData] = useState<any>();

    const onSubmit = (request: any) => {
        let _request = JSON.parse(JSON.stringify(request)) as any;
        if (!_request.products_suppliers) {
            delete _request.products_suppliers;
        }
        if (!_request.newProductsSuppliers) {
            delete _request.newProductsSuppliers;
        }
        addEditData(_request, id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                setNavigateState(listRoute);
            }
        });
    }

    const amontTaxExclField = useField({ name: "amount_tax_excl" });
    const productTypeIdField = useField({ name: "product_type_id" });
    const [vat, setVat] = useState(0);

    useEffect(() => {
        if (!productTypeIdField.fieldValue) {
            setVat(0);
        } else {
            getProductTypeData(productTypeIdField.fieldValue).then((res) => {
                if (res.status === ReqStatus.SUCCESS && res.data && res.data.productType) {
                    setVat(res.data.productType.vat);
                } else {
                    setVat(0);
                }
            })
        }
    }, [productTypeIdField.fieldValue])

    const amountTaxIncl = useMemo(() => {
        if (!amontTaxExclField.fieldValue) { return 0 };
        const amountTaxExcl = amontTaxExclField.fieldValue;
        return Math.round(amountTaxExcl * (1 + vat / 100) * 100) / 100;
    }, [amontTaxExclField, vat]);

    const { handleSubmit, resetInitialValues, isDirty } = useAddEditForm({ onSubmit });

    useEffect(() => {
        getData(id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {

                const _product = res.data.product;

                setData(res.data);

                if (res.data.productTypes) {
                    const _productTypesOptions = Object.entries(res.data.productTypes).map(([key, value]) => {
                        return { label: value, value: key ?? "" } as SelectOptionsProps;
                    });
                    setProductTypeOptions(_productTypesOptions ?? []);
                } else {
                    setProductTypeOptions([]);
                }

                if (res.data.categories) {
                    const _categoryOptions = Object.entries(res.data.categories).map(([key, value]) => {
                        return { label: value, value: key ?? "" } as SelectOptionsProps;
                    });
                    setCategoryOptions(_categoryOptions ?? []);
                } else {
                    setCategoryOptions([]);
                }

                if (res.data.suppliers) {
                    let _suppliers: AutocompleteOption[] = []
                    Object.entries(res.data.suppliers).forEach(([supplier_id, supplier]) => {
                        _suppliers.push({ id: +supplier_id, label: supplier });
                    })
                    setSuppliers(_suppliers);
                } else {
                    setSuppliers([]);
                }

                if (res.data.products) {
                    let _products: AutocompleteOption[] = []
                    Object.entries(res.data.products).forEach(([product_id, product]) => {
                        _products.push({ id: +product_id, label: product });
                    })
                    setProducts(_products);
                } else {
                    setProducts([]);
                }

                if (id) {
                    let _initialValues = {
                        product_type_id: _product.product_type_id,
                        name: _product.name,
                        reference: _product.reference,
                        description: _product.description,
                        amount_tax_excl: _product.amount_tax_excl,
                        quantity_per_package: _product.quantity_per_package,
                        stock_quantity: _product.stock_quantity,
                        categories: _product.categories.map((c: any) => c.id.toString()),

                        ...mapArrayForForm(
                            _product.products_suppliers,
                            "products_suppliers",
                            (p: any) => ({
                                id: p.id,
                                supplier_id: p.supplier_id,
                                supplier_reference: p.supplier_reference,
                                amount_tax_excl: p.amount_tax_excl
                            })
                        ),
                        ...mapArrayForForm(
                            _product.children_products,
                            "children_products",
                            (cp: any) => ({
                                id: cp.id,
                                _joinData: {
                                    quantity: cp._joinData.quantity,
                                    sort_order: cp._joinData.sort_order
                                }
                            })
                        ),

                        addElements: "1"
                    } as any;

                    customFields?.forEach((customField) => {
                        _initialValues[customField] = _product[customField];
                    })  

                    resetInitialValues(_initialValues)
                } else {
                    resetInitialValues({
                        product_type_id: null,
                        name: "",
                        reference: "",
                        description: "",
                        amount_tax_excl: 0,
                        quantity_per_package: 0,
                        stock_quantity: 0,
                        categories: [],
                        addElements: "1"
                    });
                }
            } else {
                setNavigateState(listRoute);
            }
        });
    }, [id]);

    return {
        resetInitialValues,
        handleSubmit,
        isDirty,
        id,
        amountTaxIncl,
        categoryOptions,
        productTypeOptions,
        products,
        suppliers,
        vat,
        data
    };
}

const ProductView = () => {

    const {
        resetInitialValues,
        handleSubmit,
        isDirty,
        id,
        amountTaxIncl,
        categoryOptions,
        productTypeOptions,
        products,
        suppliers
    } = useProductView();

    return (
        <AddEditForm formId="productForm" formHandleCancel={resetInitialValues} formHandleSubmit={handleSubmit} isDirty={isDirty}>

            <AddEditFormCard title={`${(!id ? "Ajouter " : "Modifier")} un produit`}>
                <ProductInfosView amountTaxIncl={amountTaxIncl} categoryOptions={categoryOptions} productTypeOptions={productTypeOptions} />
            </AddEditFormCard>

            <AddEditFormCard title="Produits intégrés">
                <ProductIntegratedView id={id} products={products} />
            </AddEditFormCard>
            <AddEditFormCard title="Fournisseurs associés">
                <ProductSupplierView id={id} suppliers={suppliers} />
            </AddEditFormCard>
        </AddEditForm>
    );
}

export default ProductView;