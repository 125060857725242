
import { GridColDef } from "@mui/x-data-grid";
import { useGetListCore } from "../../../stores/core/StoreCore";
import { UserListModel } from "../../../classes/users/UserListModel";
import ListCore from "../../../components/common/list/ListCore";
import { Box, Chip } from "@mui/material";


const columns: GridColDef[] = [
    {
        field: 'email',
        headerName: "Email",
        editable: false,
        filterable: false,
        minWidth: 100,
        flex: 1,
    },
    {
        field: 'last_name',
        headerName: "Nom",
        editable: false,
        filterable: false,
        minWidth: 100,
        flex: 1,
    },
    {
        field: 'first_name',
        headerName: "Prénom",
        editable: false,
        filterable: false,
        minWidth: 100,
        flex: 1,
    },
    {
        field: 'phone',
        headerName: "Téléphones",
        editable: false,
        filterable: false,
        width: 250,
        renderCell: ({ row }) => (
            <Box>
                {row?.phone}
                {row?.dahan_phone2?.length > 0 && <br />}
                {row?.dahan_phone2}
            </Box>
        )
    },
    {
        field: 'user_group_name',
        headerName: "Rôle",
        editable: false,
        filterable: false,
        minWidth: 100,
        flex: 1,
        valueGetter: ({ row }) => row.user_group.name
    },
    {
        field: 'external',
        headerName: "Externe",
        editable: false,
        filterable: false,
        minWidth: 80,
        flex: 1,
        renderCell: ({ row }) => row.external ? <Chip color="primary" label="Oui" /> : <Chip color="default" label="Non" />
    }
];


const UserList = () => {
    const routeController = "users";
    const [get] = useGetListCore<UserListModel>(`/${routeController}/index`);

    return (
        <ListCore<UserListModel, any>
            getDatas={get}
            defineDatasToSet={(data) => data.users}
            getRowId={(row) => row.id}
            columns={columns}
            actions={{
                defaultRoute: routeController,
                add: {
                    title: "Ajouter un utilisateur"
                },
                edit: true,
                remove: {
                    enable:true,
                    getTextModal: (row) => `Êtes-vous sûr de vouloir supprimer l'utilisateur ${row.email} ?`
                }
            }}
        />
    );
}

export default UserList;

