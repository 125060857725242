import { useCallback } from "react";
import { useApi } from "../../stores/core/UseApi";
import { useDelete } from "../../stores/core/StoreCore";
import { EnumFolderOrderSupplierStatus, FolderOrderSupplierModel } from "../classes/FolderOrderSupplierModel";

const routeController = 'project-dahan/dahan/folder-order-suppliers';
export const useGetFolderOrderSupplierList = () => {
    const [callCore] = useApi<{folderOrderSuppliers: FolderOrderSupplierModel[]}, undefined>(
        {
            route:  `${routeController}/get-all-by-folder-id`,
            instanceAxiosMethod: "GET"
        }
    );
    const call = useCallback((id? : string) => {
        return callCore( undefined, `/${id ?? ""}` );
    }, [callCore]);
    return call;
};

export const useDeleteFolderOrderSupplier = () => {
    return useDelete(routeController);
}

export const useUpdateFolderOrderSupplierStatus = () => {
    const [callCore] = useApi<undefined, { status: EnumFolderOrderSupplierStatus }>(
        {
            route:  `${routeController}/update-status`,
            instanceAxiosMethod: "POST"
        }
    );
    const call = useCallback((id : number, status: EnumFolderOrderSupplierStatus) => {
        return callCore({ status }, `/${id}` );
    }, [callCore]);
    return call;
};

export const useUpdateFolderOrderSupplierAmount = () => {
    const [callCore] = useApi<undefined, { amount: number|null }>(
        {
            route:  `${routeController}/update-amount`,
            instanceAxiosMethod: "POST"
        }
    );
    const call = useCallback((id : number, amount: number| null) => {
        return callCore({ amount }, `/${id}` );
    }, [callCore]);
    return call;
};

interface searchSupplierResponse {
    suppliers: {[id: string]: string},
    folderOrderSuppliers : {[id: string]: string}
}
export const useSearchSupplier = () => {
    const [callCore, status] = useApi<searchSupplierResponse, { order_id: number, search_type : 'name' | 'category', search: string }>(
        {
            route:  `${routeController}/search-supplier`,
            instanceAxiosMethod: "POST"
        }
    );
    const call = useCallback((order_id: number, search_type : 'name' | 'category', search: string) => {
        return callCore({ order_id, search_type, search } );
    }, [callCore]);
    return { call, status };
};

export const useInsertOrderSupplierByIds = () => {
    const [callCore, status] = useApi<undefined, { order_id: number, supplier_ids: number[] }>(
        {
            route:  `${routeController}/insert-order-supplier-by-ids`,
            instanceAxiosMethod: "POST"
        }
    );
    const call = useCallback((order_id: number, supplier_ids: number[]) => {
        return callCore({ order_id, supplier_ids } );
    }, [callCore]);
    return { call, status };
};

