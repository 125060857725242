import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAddBill, useGetBill } from "../../stores/BillsStore";
import { ReqStatus } from "../../stores/core/UseApi";
import JsonView from "../common/debug/JsonView";
import { BillItemModel, BillModel } from "../../classes/bills/BillModel";
import { Button, Grid } from "@mui/material";
import { mapStringValueForForm } from "../../utils/DataUtils";
import BillCustomerInfo from "./BillCustomerInfo";
import { useForm, useFormValues, useIsDirty } from "react-recoil-form";
import AddEditForm from "../common/form/AddEditForm";
import BillProductsInfo from "./BillProductsInfo";
import OrderTotalView from "../orders/OrderTotalView";
import { initOrderTotal } from "../orders/OrderView";
import OrderTotalInfos from "../orders/OrderTotalInfo";
import AddEditFormCard from "../common/form/AddEditFormCard";
import InputField from "../common/form/InputField";
import TextAreaField from "../common/form/TextAreaField";
import { useIntl } from "react-intl";

const BillView = () => {

    const { id } = useParams();
    const getBill = useGetBill('bill');
    const addBill = useAddBill('bill');
    const navigate = useNavigate();
    const intl = useIntl();

    const onSubmit = (bill: BillModel) => {
        if (!bill || !id) { return; }
        addBill(bill, id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                navigate(-1);
            }
        })
    }
    const { handleSubmit, resetInitialValues } = useForm({
        onSubmit
    });
    const isDirty = useIsDirty();

    const values = useFormValues();

    useEffect(() => {
        if (!id) { return; }

        getBill(id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                const _order = res.data.bill.order;

                let payment_method = '';
                if (Array.isArray(_order.payments) && _order.payments.length > 0) {
                    _order.payments.forEach((payment: any) => {
                        if (payment_method !== "") { payment_method += " + " }
                        payment_method += payment.payment_method.label;
                    })
                } else {
                    payment_method = 'N.C.';
                }

                let shipping_method = '';
                if (Array.isArray(_order.shippings) && _order.shippings.length > 0) {
                    _order.shippings.forEach((shipping: any) => {
                        if (shipping_method !== "") { shipping_method += " + " }
                        shipping_method += shipping.shipping_method.label;
                    })
                } else {
                    shipping_method = 'N.C.';
                }

                const bill: BillModel = {
                    billing_first_name: mapStringValueForForm(_order.billing_address.first_name, _order.customer.user.first_name),
                    billing_last_name: mapStringValueForForm(_order.billing_address.last_name, _order.customer.user.last_name),
                    billing_email: mapStringValueForForm(_order.billing_address.email, _order.customer.user.email),
                    billing_address_1: _order.billing_address.address_1,
                    billing_address_2: _order.billing_address.address_2,
                    billing_postcode: _order.billing_address.postcode,
                    billing_city: _order.billing_address.city,
                    billing_country_id: _order.billing_address.country_id,

                    shipping_first_name: mapStringValueForForm(_order.shipping_address.first_name, _order.customer.user.first_name),
                    shipping_last_name: mapStringValueForForm(_order.shipping_address.last_name, _order.customer.user.last_name),
                    shipping_email: mapStringValueForForm(_order.shipping_address.email, _order.customer.user.email),
                    shipping_address_1: _order.shipping_address.address_1,
                    shipping_address_2: _order.shipping_address.address_2,
                    shipping_postcode: _order.shipping_address.postcode,
                    shipping_city: _order.shipping_address.city,
                    shipping_country_id: _order.shipping_address.country_id,

                    payment_method: payment_method,
                    shipping_method: shipping_method,
                    bill_items: _order.products.map((p: any) => {
                        return {
                            name: p.name,
                            reference: p._joinData.label ? p._joinData.label : p.reference,
                            unit_tax_excl: p._joinData.unit_tax_excl,
                            quantity: p._joinData.quantity,
                            vat: p._joinData.vat,
                            total_tax_excl: p._joinData.total_tax_excl,
                            total_tax: p._joinData.total_tax,
                            total_tax_incl: p._joinData.total_tax_incl,

                        } as BillItemModel
                    }),

                    ...initOrderTotal(id, _order),

                    reference: "",
                    comment: ""
                };

                resetInitialValues(bill);
            }
            else {
                navigate("/commercial/bills/");
            }
        })
    }, [id])

    return (
        <AddEditForm formId="billForm" formHandleCancel={resetInitialValues} formHandleSubmit={handleSubmit} isDirty={isDirty}>
            <BillCustomerInfo />

            <AddEditFormCard title="Commentaire">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} >
                        <InputField
                            label={intl.formatMessage({id: "Label_Bill_Reference"})}
                            name="reference"
                            type="text"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} >
                        <TextAreaField
                            label={intl.formatMessage({id: "Label_Bill_Comment"})}
                            name="comment"
                            required
                        />
                    </Grid>
                </Grid>
            </AddEditFormCard>

            <BillProductsInfo items={values?.bill_items ?? []} />

            <OrderTotalInfos discount shipping />

            
        </AddEditForm>
    )
}

export default BillView;