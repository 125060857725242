

import { RouteItem, SectionItem } from "../layout/AppRoutes";

import SupplierCustomView from "./components/suppliers/SupplierCustomView";
import CustomerCustomView from "./components/customers/CustomerCustomView";
import SupplierCustomList from "./components/suppliers/SupplierCustomList";

import FolderOrderSupplierPaymentGlobalList from "./components/folder-order-supplier-payments/FolderOrderSupplierPaymentGlobalList";
import FolderLeadList from "./components/folders/FolderLeadList";
import FolderCreateView from "./components/folders/FolderCreateView";
import FolderView from "./components/folders/FolderView";
import QuoteView from "./components/quotes/QuoteView";
import FolderList from "./components/folders/FolderList";
import OrderRedirect from "./components/orders/OrderRedirect";

import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import AddIcon from '@mui/icons-material/Add';
import FolderIcon from "@mui/icons-material/Folder";
import SearchIcon from "@mui/icons-material/Search";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import TagIcon from '@mui/icons-material/Tag';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { SettingsSectionRoutes } from "../layout/routes/SettingsSectionRoutes";
import { ComptaSectionRoutes } from "../layout/routes/ComptaSectionRoutes";
import { CustomerSectionRoutes } from "../layout/routes/CustomerSectionRoutes";
import { StockSectionRoutes } from "../layout/routes/StockSectionRoutes";
import FolderReminderGlobalList from "./components/folder-reminders/FolderReminderGlobalList";
import NotificationsIcon from '@mui/icons-material/Notifications';
import CreditNoteView from "../components/bills/CreditNoteView";
import DepositCreditNoteView from "./components/bills/DepositCreditNoteView";
import BillCustomList from "./components/bills/BillCustomList";
import PaymentCustomList from "./components/payments/PaymentCustomList";
import DashboardFolders from "./components/stats/DashboardOrders";
import DashboardOrders from "./components/stats/DashboardOrders";
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import InputSearchGlobal from "./components/search/InputSearchGlobal";
import PageviewIcon from '@mui/icons-material/Pageview';
import QuoteResaList from "./components/quotes/QuoteResaList";
import UserTimetracker from "./components/user-timetrackers/UserTimetracker";
import { Box } from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import UserTimetrackerStats from "./components/user-timetrackers/UserTimetrackerStats";
import UserTimetrackerList from "./components/user-timetrackers/UserTimetrackerList";
import UserTimetrackerView from "./components/user-timetrackers/UserTimetrackerView";
import UsersWorked from "./components/user-timetrackers/UsersWorked";
import WorkIcon from '@mui/icons-material/Work';
import FolderForgottenList from "./components/folders/FolderForgottenList";
import CircuitTypeList from "./components/circuit-types/CircuitTypeList";
import CircuitTypeView from "./components/circuit-types/CircuitTypeView";
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import EmailIcon from '@mui/icons-material/Email';
import BrevoMailConfigsList from "./components/brevo-mail-configs/BrevoMailConfigsList";
import BrevoMailConfigsView from "./components/brevo-mail-configs/BrevoMailConfigsView";
import UserCustomView from "./components/users/UserCustomView";
import { UserSectionRoutes } from "../layout/routes/UserSectionRoutes";
import UserCustomList from "./components/users/UserCustomList";
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import QuoteResaCalendar from "./components/quotes/QuoteResaCalendar";
import ReminderTagList from "./components/reminder-tags/ReminderTagList";
import ReminderTagView from "./components/reminder-tags/ReminderTagView";
import TagList from "./components/tags/TagList";
import TagView from "./components/tags/TagView";
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import ChecktaskList from "./components/checktasks/ChecktaskList";
import ChecktaskView from "./components/checktasks/ChecktaskView";
import FolderCounter from "./components/folders/FolderCounter";

//SURCHARGE STOCK
let _stockSectionRoutes = StockSectionRoutes;
_stockSectionRoutes.children = _stockSectionRoutes.children.filter((r) => {
    return r.index !== "SUPPLIER_ORDERS";
}).map((r) => {
    if (r.path === "/commercial/suppliers/add-edit/:id?") {
        r.element = () => <SupplierCustomView />;
    }
    if (r.path === "/commercial/suppliers") {
        r.element = () => <SupplierCustomList />;
    }
    return r;
});

//SURCHARGE COMPTA
let _comptaSectionRoutes = ComptaSectionRoutes;
_comptaSectionRoutes.children = _comptaSectionRoutes.children.filter((r) => {
    return r.index !== "ORDERS";
}).map((r) => {
    if (r.index === "PAYMENTS") {
        r.title = "Paiements clients";
    }
    if (r.path === "/commercial/bills/add/:id/credit-note") {
        r.element = () => <CreditNoteView modeCalcul="TTC" />;
    }
    if (r.path === "/commercial/bills") {
        r.element = () => <BillCustomList />
    }
    if (r.path === "/commercial/payments") {
        r.element = () => <PaymentCustomList />
    }

    return r;
})

//SURCHARGE CUSTOMER
let _customerSectionRoutes = CustomerSectionRoutes;
_customerSectionRoutes.children.map((r) => {
    if (r.path === "/commercial/customers/add-edit/:id?") {
        r.element = () => <CustomerCustomView />;
    }
    return r;
});

//SURCHARGE USERS
let _userSectionRoutes = UserSectionRoutes;
_userSectionRoutes.children.map((r) => {
    if (r.path === "/users") {
        r.element = () => <UserCustomList />;
    }
    if (r.path === "/users/add-edit/:id?") {
        r.element = () => <UserCustomView />;
    }
    return r;
});

export const HeaderSearch = () => {
    return (
        <>
            <FolderCounter />
            <UserTimetracker />
            <InputSearchGlobal />
        </>
    )
}

export const Routes: SectionItem[] = [
    {
        ...UserSectionRoutes,
        children: [
            ...UserSectionRoutes.children,
            {
                index: "TIME_LIST",
                path: "/user-timetrackers",
                title: "Saisie des temps",
                element: () => <UserTimetrackerList />,
                menuVisible: true,
                menuIcon: <AccessTimeIcon />,
                authorization: {
                    controller: "UserTimetrackers",
                    action: "index"
                }
            },
            {
                index: "USERS_WORKED",
                path: "/users-worked",
                title: "Activité",
                element: () => <UsersWorked />,
                menuVisible: true,
                menuIcon: <WorkIcon />,
                authorization: {
                    controller: "UserTimetrackers",
                    action: "getUsersWorked"
                }
            },
            {
                index: "TIME_LIST",
                path: "/user-timetrackers/add-edit/:id?",
                title: "Saisie des temps",
                element: () => <UserTimetrackerView />,
                menuVisible: false,
                authorization: {
                    controller: "UserTimetrackers",
                    action: "addEdit"
                }
            },
            {
                index: "KUMOSPACE",
                path: "/kumospace",
                title: "Kumospace",
                element: () => <>
                    <iframe
                        style={{ flex: 1 }}
                        title="Kumospace"
                        src="https://www.kumospace.com/laurene"
                        allow="camera; microphone; fullscreen; clipboard-write"
                    > </iframe>
                </>,
                menuVisible: true,
                menuIcon: <WorkIcon />,
                authorization: null
            },
        ]
    },
    {
        title: "Statistiques",
        index: "STATS",
        children: [
            {
                index: "FOLDER_STATS",
                path: "/quotations-stats",
                title: "Réservations",
                element: () => <DashboardOrders />,
                menuVisible: true,
                menuIcon: <QueryStatsIcon />,
                authorization: {
                    controller: "Folders",
                    action: "index"
                }
            },
            {
                index: "TIME_STATS",
                path: "/users-timetracker-stats",
                title: "Temps des utilisateurs",
                element: () => <UserTimetrackerStats />,
                menuVisible: true,
                menuIcon: <AccessTimeIcon />,
                authorization: {
                    controller: "UserTimetrackers",
                    action: "index"
                }
            },
        ]
    },
    {
        ..._customerSectionRoutes,
        children: [
            {
                index: "CUSTOMER_ADD",
                path: "/commercial/customers/add-edit",
                title: "Créer client",
                element: () => <CustomerCustomView />,
                menuVisible: true,
                menuIcon: <AddIcon />,
                authorization: {
                    controller: "Customers",
                    action: "addEdit"
                }
            },
            ..._customerSectionRoutes.children
        ]
    },
    _stockSectionRoutes,
    {
        title: "Dossiers",
        index: "FOLDER",
        children: [
            {
                index: "LEADS",
                path: "/leads",
                title: "Leads",
                element: () => <FolderLeadList />,
                menuVisible: true,
                menuIcon: <CreateNewFolderIcon />,
                authorization: {
                    controller: "Folders",
                    action: "lead"
                }
            },
            {
                index: "FOLDERS",
                path: "/folders",
                title: "Dossiers",
                element: () => <FolderList />,
                menuVisible: true,
                menuIcon: <FolderIcon />,
                authorization: {
                    controller: "Folders",
                    action: "index"
                }
            },
            {
                index: "FOLDERS_RESA",
                path: "/quotes-resa",
                title: "Dossiers Résa",
                element: () => <QuoteResaList />,
                menuVisible: true,
                menuIcon: <PageviewIcon />,
                authorization: {
                    controller: "Quotations",
                    action: "indexResa"
                }
            },
            {
                index: "FOLDERS_RESA_CALENDAR",
                path: "/quotes-resa-calendar",
                title: "Dossiers Résa",
                element: () => <QuoteResaCalendar />,
                menuVisible: true,
                menuIcon: <WorkHistoryIcon />,
                authorization: {
                    controller: "Quotations",
                    action: "indexResaCalendar"
                }
            },
            {
                index: "FOLDERS",
                path: "/folders/add/:customerId",
                title: "Dossiers",
                element: () => <FolderCreateView />,
                menuVisible: false,
                menuIcon: <FolderIcon />,
                authorization: {
                    controller: "Folders",
                    action: "addEdit"
                }
            },
            {
                index: "FOLDERS",
                path: "/folders/add-edit",
                title: "Dossiers",
                element: () => <FolderCreateView />,
                menuVisible: false,
                authorization: {
                    controller: "Folders",
                    action: "addEdit"
                }
            },
            {
                index: "FOLDERS",
                path: "/folders/add-edit/:id",
                title: "Dossiers",
                element: () => <FolderView />,
                menuVisible: false,
                menuIcon: <FolderIcon />,
                authorization: {
                    controller: "Folders",
                    action: "addEdit"
                }
            },
            {
                index: "FOLDERS",
                path: "/folders/:folderId/quotes/add-edit/:quoteId?",
                title: "Devis",
                element: () => <QuoteView />,
                menuVisible: false,
                authorization: {
                    controller: "Orders",
                    action: "addEdit"
                }
            },
            {
                index: "FOLDERS",
                path: "/commercial/orders/add-edit/:id",
                title: "Devis",
                element: () => <OrderRedirect />,
                menuVisible: false,
                menuIcon: <FolderIcon />,
            },
            {
                index: "FOLDER_REMINDERS",
                path: "/folder-reminders",
                title: "Agenda",
                element: () => <FolderReminderGlobalList />,
                menuVisible: true,
                menuIcon: <PendingActionsIcon />,
                authorization: {
                    controller: "FolderReminders",
                    action: "index"
                }
            },
            {
                index: "FOLDERS_FORGOTTEN",
                path: "/folders-forgotten",
                title: "Dossiers oubliés",
                element: () => <FolderForgottenList />,
                menuVisible: true,
                menuIcon: <FolderIcon />,
                authorization: {
                    controller: "Folders",
                    action: "forgotten"
                }
            },
        ]
    } as SectionItem,
    {
        ..._comptaSectionRoutes,
        children: [
            {
                index: "PAYMENTS_SUPPLIER",
                path: "/suppliers-payments",
                title: "Paiements fournisseurs",
                element: () => <FolderOrderSupplierPaymentGlobalList />,
                menuVisible: true,
                menuIcon: <CreditCardIcon />,
                authorization: {
                    controller: "FolderOrderSupplierPayments",
                    action: "index"
                }
            },
            {
                index: "BILLS",
                path: "/commercial/bills/add/:id/deposit",
                title: "Acompte",
                element: () => <DepositCreditNoteView modeCalcul="TTC" type="deposit" />,
                menuVisible: false,
                authorization: {
                    controller: "Bills",
                    action: "add"
                }
            },
            {
                index: "BILLS",
                path: "/commercial/bills/add/:id/deposit-credit-note",
                title: "Avoir d'acompte",
                element: () => <DepositCreditNoteView modeCalcul="TTC" type="deposit-credit-note" />,
                menuVisible: false,
                authorization: {
                    controller: "Bills",
                    action: "add"
                }
            },
            ..._comptaSectionRoutes.children
        ]
    },

    {
        ...SettingsSectionRoutes,
        children: [
            {
                index: "CIRCUIT_TYPES",
                path: "/circuit-types",
                title: "Types de circuit",
                element: () => <CircuitTypeList />,
                menuVisible: true,
                menuIcon: <AirportShuttleIcon />,
                authorization: {
                    controller: "CircuitTypes",
                    action: "index"
                }
            },
            {
                index: "CIRCUIT_TYPES",
                path: "/circuit-types/add-edit/:id?",
                title: "Types de circuit",
                element: () => <CircuitTypeView />,
                menuVisible: false,
                authorization: {
                    controller: "CircuitTypes",
                    action: "addEdit"
                }
            },
            {
                index: "CHECKTASKS",
                path: "/checktasks",
                title: "Checklist",
                element: () => <ChecktaskList />,
                menuVisible: true,
                menuIcon: <LibraryAddCheckIcon />,
                authorization: {
                    controller: "Checktasks",
                    action: "index"
                }
            },
            {
                index: "CHECKTASKS",
                path: "/checktasks/add-edit/:id?",
                title: "Checklist",
                element: () => <ChecktaskView />,
                menuVisible: false,
                authorization: {
                    controller: "Checktasks",
                    action: "addEdit"
                }
            },
            {
                index: "REMINDER_TAGS",
                path: "/reminder-tags",
                title: "Tags d'agenda",
                element: () => <ReminderTagList />,
                menuVisible: true,
                menuIcon: <TagIcon />,
                authorization: {
                    controller: "ReminderTags",
                    action: "index"
                }
            },
            {
                index: "REMINDER_TAGS",
                path: "/reminder-tags/add-edit/:id?",
                title: "Tags d'agenda",
                element: () => <ReminderTagView />,
                menuVisible: false,
                authorization: {
                    controller: "ReminderTags",
                    action: "addEdit"
                }
            },
            {
                index: "TAGS",
                path: "/tags",
                title: "Tags de dossier",
                element: () => <TagList />,
                menuVisible: true,
                menuIcon: <BookmarkIcon />,
                authorization: {
                    controller: "Tags",
                    action: "index"
                }
            },
            {
                index: "TAGS",
                path: "/tags/add-edit/:id?",
                title: "Tags de dossier",
                element: () => <TagView />,
                menuVisible: false,
                authorization: {
                    controller: "Tags",
                    action: "addEdit"
                }
            },
            {
                index: "BREVO_MAIL_CONFIGS",
                path: "/brevo-mail-configs",
                title: "Brevo",
                element: () => <BrevoMailConfigsList />,
                menuVisible: true,
                menuIcon: <EmailIcon />,
                authorization: {
                    controller: "BrevoMailConfigs",
                    //C'est fait exprès pour que la liste ne soit vu que par une personne qui peut la modifier
                    action: "addEdit"
                }
            },
            {
                index: "BREVO_MAIL_CONFIGS",
                path: "/brevo-mail-configs/add-edit/:id?",
                title: "Brevo",
                element: () => <BrevoMailConfigsView />,
                menuVisible: false,
                authorization: {
                    controller: "BrevoMailConfigs",
                    action: "addEdit"
                }
            },
            ...SettingsSectionRoutes.children
        ]
    }
    ,
];