import { useEffect, useState } from "react";
import { Tag } from "../../classes/FolderTagModel";
import { Autocomplete, Box, Button, Chip, TextField, Typography } from "@mui/material";

interface FolderTagAutoCompleteProps {
	initialValue?: Tag[],
	options: Tag[],
	onChange?: (tags: Tag[]) => void,
	onValidate?: (tags: Tag[]) => void,
	disabled?: boolean,
	label?: string,
}
const FolderTagAutoComplete = (props: FolderTagAutoCompleteProps) => {

	const { initialValue, options, onChange, onValidate, disabled, label } = props;

	const [selected, setSelected] = useState<Tag[]>(initialValue ?? []);

	useEffect(() => {
		if (onChange) {
			onChange(selected);
		}
	}, [selected, onChange]);

	return (
		<Box display="flex">
			<Autocomplete
				disabled={disabled}
				fullWidth
				id="folder-tag-autocomplete"
				sx={onValidate ? { width: 300 } : {}}
				options={options}
				value={selected}
				onChange={(e, n) => setSelected(n)}
				multiple
				autoHighlight
				getOptionLabel={(option) => option.name}
				groupBy={(option) => option.commercial ? "Commercial" : "Gestionnaire"}
				renderGroup={(params) => (
					<Box>
						<Box px={2} py={1}>
							<Typography variant="body1"><b><u>{params.group}</u></b></Typography>
						</Box>
						<Box>
							{params.children}
						</Box>
					</Box>
				)}
				renderTags={(value, getTagProps) =>
					value.map((option, index) => (
						<Chip
							sx={{
								backgroundColor: option.backcolor,
								color: option.color
							}}
							variant="outlined"
							label={option.name}
							size="small"
							{...getTagProps({ index })}
						/>
					))
				}
				renderOption={(props, option) => (
					<Box component="li" sx={{
						color: option.color,
						backgroundColor: option.backcolor + " !important",
						"&:hover": {
							color: option.color,
							backgroundColor: option.backcolor + " !important",
							border: "1px solid black"
						}
					}} {...props}>
						{option.name}
					</Box>
				)}
				renderInput={(params) => (
					<TextField
						{...params}
						label={label ?? "Choisir un tag"}
						disabled={disabled}
						inputProps={{
							...params.inputProps
						}}
					/>
				)}
			/>
			{onValidate &&
				<Box display="flex" alignItems="center" ml={2}>
					<Button disabled={selected.length === 0 || disabled} variant="contained" color="info" onClick={() => { onValidate(selected); setSelected([]); }}>Valider</Button>
				</Box>
			}
		</Box>

	);
}

export default FolderTagAutoComplete;