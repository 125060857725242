import { SelectOptionsProps } from "../../components/common/form/SelectField";
import { EnumFolderOrderSupplierStatus, FolderOrderSupplierModel, getLabelOfFolderOrderSupplierStatus } from "../classes/FolderOrderSupplierModel";
import { atom, selector } from "recoil";

export const FolderOrderSupplierListState = atom<FolderOrderSupplierModel[]>({
    key: 'FolderOrderSupplierListKey',
    default: []
});

export const FolderOrderSupplierValidatedState = selector({
    key: 'FolderOrderSupplierValidatedKey',
    get: ({ get }) => {
        const folderOrderSupplierList = get(FolderOrderSupplierListState);
        return folderOrderSupplierList.filter((item) => 
            (
                item.status === EnumFolderOrderSupplierStatus.CONFIRMED ||
                item.status === EnumFolderOrderSupplierStatus.CANCELED
            ) &&
            item.order.order_status.unique_key === "quote_validate" 
        );
    }
});

export const FolderOrderSupplierValidatedOptionsState = selector({
    key: 'FolderOrderSupplierValidatedOptionsKey',
    get: ({ get }) => {
        const folderOrderSupplierList = get(FolderOrderSupplierListState);
        let options: SelectOptionsProps[] = [];
        folderOrderSupplierList.forEach((item) => {
            if (
                (
                    item.status === EnumFolderOrderSupplierStatus.CONFIRMED ||
                    item.status === EnumFolderOrderSupplierStatus.VALIDATED ||
                    item.status === EnumFolderOrderSupplierStatus.CANCELED
                ) &&
                item.order.order_status.unique_key !== "quote_inprogress") {  
                    options.push({
                        value: item.id,
                        label: `${item.supplier.name} (${getLabelOfFolderOrderSupplierStatus(item.status)}) - Devis ${item.order.id} (${item.order.order_status.label})`
                    });
            }
        });
        return options;
    }
});

export const FolderOrderSupplierSelectionModalState = atom<{ open: boolean, onValidate: () => void }>({
    key: 'FolderOrderSupplierSelectionModalKey',
    default: {
        open: false,
        onValidate: () => { }
    }
});

export const FolderOrderSupplierCommentModalState = atom<{ id:number, open: boolean}>({
    key: 'FolderOrderSupplierCommentModalKey',
    default: {
        id: 0,
        open: false
    }
});