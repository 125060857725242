import { useNavigate, useParams } from "react-router-dom";
import { useAddEditProductType, useGetProductType } from "../../stores/ProductTypesStore";
import AddEditFormPanel, { useAddEditForm } from "../common/form/AddEditFormPanel";
import AddEditFormSubmit from "../common/form/AddEditFormSubmit";
import { ReqStatus } from "../../stores/core/UseApi";
import { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import InputField from "../common/form/InputField";
import { useAddEditCore } from "../../stores/core/StoreCore";
import AddEditFormList from "../common/form/AddEditFormList";
import AddEditForm from "../common/form/AddEditForm";
import AddEditFormCard from "../common/form/AddEditFormCard";

const PaymentMethodView = () => {
    const listRoute = "/commercial/payment-methods";
    const { getData, addEditData } = useAddEditCore<any, { paymentMethod: any }>(`${listRoute}/add-edit`);

    let { id } = useParams();
    const navigate = useNavigate();

    const onSubmit = (request: any) => {
        addEditData(request, id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                navigate(listRoute);
            }
        })
    }

    const { handleSubmit, resetInitialValues, isDirty } = useAddEditForm({ onSubmit, initialValues: { label: "", unique_key: "" } });
    useEffect(() => {
        getData(id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                if (id) {
                    const _data = res.data.paymentMethod;
                    resetInitialValues({
                        label: _data.label,
                        unique_key: _data.unique_key
                    });
                }
            } else {
                navigate(listRoute);
            }
        })
    }, [id]);

    return (

        <AddEditForm formId="paymentMethodForm" formHandleCancel={resetInitialValues} formHandleSubmit={handleSubmit} isDirty={isDirty}>
            <AddEditFormCard title={(!id ? "Ajouter " : "Modifier") + " un moyen de paiement"}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <InputField name="label" label="Label" type="text" required />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputField name="unique_key" label="Clé unique" type="text" required disabled={!!id} />
                    </Grid>
                </Grid>
            </AddEditFormCard>
        </AddEditForm>
    )
}

export default PaymentMethodView;