import { GridColDef } from "@mui/x-data-grid";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useGetListCore } from "../../../stores/core/StoreCore";
import ListCore from "../../../components/common/list/ListCore";
import { CreateColumnDate } from "../../../components/common/list/column/ColumnDate";
import { ListModelCore } from "../../../classes/list/ListModel";
import { UserTimetrackerModel } from "../../classes/UserTimetrackerModel";
import { CreateColumnAvatar } from "../../../components/common/list/column/ColumnAvatar";
import { CurrentUserState } from "../../../states/AuthState";
import { useRecoilValue } from "recoil";

interface UserTimetrackerListModel extends ListModelCore {
	userTimetrackers: UserTimetrackerModel[]
}

const UserTimetrackerList = () => {
	const intl = useIntl();
	const columns = useCallback(() => {
		return [
			CreateColumnAvatar({
				field: 'user_id',
				headerName: "Utilisateur",
				getId: (row) => row.user_id,
				getValue: (row) => `${row.user.first_name} ${row.user.last_name}`
			}),
			CreateColumnDate({
				field: 'start',
				headerName: "Début",
				getValue: (row) => row.start,
				type: "dateTime"
			}),
			CreateColumnDate({
				field: 'end',
				headerName: "Fin",
				getValue: (row) => row.end,
				type: "dateTime"
			}),
			{
				field: 'calcul_time',
				headerName: "Temps",
				editable: false,
				filterable: false,
				width: 120,
				valueGetter: ({ row }) => {
					if (!row.end) { return ""; }

					// Dates de départ et d'arrivée
					const dateDebut = new Date(row.start);
					const dateFin = new Date(row.end);

					// Calcul de la différence en millisecondes
					const diff = dateFin.getTime() - dateDebut.getTime();

					// Calcul des heures et minutes
					const hours = Math.floor(diff / (1000 * 60 * 60));
					const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

					return `${hours}h ${minutes}m`
				}
			}
		] as GridColDef[];
	}, [intl]);

	const routeController = "project-dahan/dahan/user-timetrackers";
	const [get] = useGetListCore<UserTimetrackerListModel>(`/${routeController}/index`);

	const currentUser = useRecoilValue(CurrentUserState);
	const userCanWrite = useMemo(() => {
		return (currentUser && (currentUser.user_group_id === 1 || currentUser.user_group_id === 2)) ?? false;
	}, [])

	return (
		<>
			<ListCore<UserTimetrackerListModel, any>
				getDatas={get}
				defineDatasToSet={(data) => data.userTimetrackers}
				getRowId={(row) => row.id}
				columns={columns()}
				actions={{
					defaultRoute: 'user-timetrackers',
					add: userCanWrite ? { title: 'Ajouter un temps' } : undefined,
					edit: userCanWrite,
					remove: {
						enable: userCanWrite,
						route: "project-dahan/dahan/user-timetrackers"
					}
				}}
			/>
		</>
	);
}

export default UserTimetrackerList;