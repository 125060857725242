import { useParams } from "react-router-dom";
import AddEditForm from "../../../components/common/form/AddEditForm"
import AddEditFormCard from "../../../components/common/form/AddEditFormCard";
import InputField from "../../../components/common/form/InputField";
import { Alert, Box, Button, Grid, Skeleton } from "@mui/material";
import NumberField from "../../../components/common/form/NumberField";
import CheckboxField from "../../../components/common/form/CheckboxField";
import QuoteProducts from "./QuoteProducts";
import OrderProductsView, { OrderProductsFieldNames } from "../../../components/orders/OrderProductsView";
import { useAddEditCore } from "../../../stores/core/StoreCore";
import { useEffect, useMemo, useState } from "react";
import AutocompleteField, { AutocompleteOption } from "../../../components/common/form/AutocompleteField";
import { NavigateState } from "../../../states/MenuState";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { ReqStatus } from "../../../stores/core/UseApi";
import { createOptionsForAutocomplete, createOptionsForSelect, parseDataForForm } from "../../../utils/DataUtils";
import { useAddEditForm } from "../../../components/common/form/AddEditFormPanel";
import { useOrderCalcul, useOrderCalculLines } from "../../../components/orders/OrderCalcul";
import OrderTotalView from "../../../components/orders/OrderTotalView";
import SelectField, { SelectOptionsProps } from "../../../components/common/form/SelectField";
import OrderAddressesView from "../../../components/orders/OrderAddressesView";
import { useSnackbar } from "notistack";
import OrderPaymentsView from "../../../components/orders/OrderPaymentsView";
import { useFolderView } from "../folders/FolderView";
import CustomerCard from "../customers/CustomerCard";
import FolderDetails from "../folders/FolderDetails";
import JsonFormView from "../../../components/common/debug/JsonFormView";
import JsonView from "../../../components/common/debug/JsonView";
import DateField from "../../../components/common/form/DateField";
import OrderBillsView from "../../../components/orders/OrderBillsView";
import dayjs from "dayjs";
import { QuoteFormSubmitForcedState } from "../../states/QuoteState";
import OrderBillsCustomView from "../bills/OrderBillsCustomView";
import QuotePaymentsView from "./QuotePaymentsView";
import { QuoteCircuitTypesState } from "../../states/QuoteCircuitState";
import { IsNotUserCommercialSelector } from "../../states/UserState";
import CustomOrderAddressesView from "../orders/CustomOrderAddressesView";

interface OrderResponse {
    order: any,
    products: { [id: string]: string },
    billed: boolean,
    statusLists: {
        orders: {
            order: { [id: string]: string },
            payment: { [id: string]: string },
            shipping: { [id: string]: string }
        },
        payment_methods: { [id: string]: { [id: string]: string } },
    },
    paymentMethods: { [id: string]: string },
    circuitTypes: { [id: string]: string },
}
const QuoteView = () => {

    let { folderId, quoteId } = useParams();

    const { folderReady, folder, commercialName } = useFolderView(folderId);

    const listRoute = "/commercial/orders";

    const setCircuitTypes = useSetRecoilState(QuoteCircuitTypesState);
    const [products, setProducts] = useState<AutocompleteOption[]>([]);
    const [quoteStatus, setQuoteStatus] = useState<SelectOptionsProps[]>([]);

    //Section Paiement
    const [paymentMethods, setPaymentMethods] = useState<SelectOptionsProps[]>([]);
    const [methodPaymentStatus, setMethodPaymentStatus] = useState<{ id: string, options: SelectOptionsProps[] }[]>([]);

    const [ready, setReady] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const setNavigateState = useSetRecoilState(NavigateState);

    const [quoteEnabled, setQuoteEnabled] = useState(false);
    const [paymentsVisible, setPaymentsVisible] = useState(false);
    const [refresh, setRefresh] = useState(0);

    const { getData, addEditData } = useAddEditCore<any, OrderResponse>(`${listRoute}/add-edit`);

    const [billed, setBilled] = useState(false);
    const [bills, setBills] = useState<any[]>([]);
    const [creditNotes, setCreditNotes] = useState<any[]>([]);

    const deposits = useMemo(() => {
        return [
            ...bills.filter((x) => x.dahan_is_deposit),
            ...creditNotes.filter((x) => x.dahan_is_deposit),
        ]
    }, [bills, creditNotes]);

    const onSubmit = (request: any) => {
        const _request = JSON.parse(JSON.stringify(request));
        let travel_infos: any[] = [];
        if (Array.isArray(_request.products)) {
            _request.products.forEach((p: any) => {
                if (p._joinData.order_product_attribute) {
                    p._joinData.order_product_attribute.order_product_id = p._joinData.id;
                    p._joinData._joinData = p._joinData.order_product_attribute;
                    travel_infos.push(p._joinData);
                }
            });
            //_request.products = _request.products.filter((p:any) => !p._joinData.order_product_attribute );
        }

        if (Array.isArray(_request.newProducts)) {
            _request.newProducts.forEach((p: any) => {
                if (p._joinData.order_product_attribute) {
                    travel_infos.push(
                        {
                            ...p._joinData,
                            order_product_attribute: {
                                ...p._joinData.order_product_attribute,
                                //order_product_id: p._joinData.id
                            }
                        }
                    );
                }
            });
            //_request.newProducts = _request.newProducts.filter((p: any) => !p._joinData.order_product_attribute);
        }

        if (!_request.products || _request.products.length === 0) {
            delete _request.products;
        }
        if (!_request.newProducts || _request.newProducts.length === 0) {
            delete _request.newProducts;
        }

        //_request.travels_infos = travel_infos;

        addEditData(_request, quoteId).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                setNavigateState(`/folders/add-edit/${folderId}`);
                //setRefresh(v => v + 1); for reload quote view
            }
        })

        return request;
    }
    const { handleSubmit, resetInitialValues, isDirty } = useAddEditForm({
        onSubmit,
        initialValues: {}
    });

    const [quoteFormSubmitForced, setQuoteFormSubmitForced] = useRecoilState(QuoteFormSubmitForcedState);
    useEffect(() => {
        if (quoteFormSubmitForced) {
            handleSubmit();
        }

        return () => {
            setQuoteFormSubmitForced(false);
        }
    }, [quoteFormSubmitForced, handleSubmit]);

    const title = useMemo(() => {
        if (quoteId) {
            return `Modification devis #${quoteId}`;
        } else {
            return "Nouveau devis"
        }
    }, [quoteId])

    useEffect(() => {
        if (!folderReady) { return; }
        setReady(false);
        getData(quoteId).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {

                const _quote = JSON.parse(JSON.stringify(res.data.order));

                //On vérifie que l'id du devis existant est bien lié au dossier
                if (quoteId && (!_quote.order_attribute || _quote.order_attribute.folder_id != folderId)) {
                    setNavigateState(`/folders/add-edit/${folderId}`);
                    enqueueSnackbar(`Le devis ${quoteId} n'est pas lié au dossier ${folderId}`, { variant: "error" });
                    return;
                }

                setCircuitTypes(createOptionsForSelect(res.data.circuitTypes ?? []));
                setProducts(createOptionsForAutocomplete(res.data.products));
                const _statusLists = res.data.statusLists;
                setQuoteStatus(createOptionsForSelect(_statusLists.orders.order));

                if (_statusLists.payment_methods) {
                    let _methodPaymentStatusList: { id: string, options: SelectOptionsProps[] }[] = [];
                    Object.entries(_statusLists.payment_methods).forEach(([key, value]) => {
                        _methodPaymentStatusList.push({
                            id: key,
                            options: createOptionsForSelect(value)
                        })
                    });
                    setMethodPaymentStatus(_methodPaymentStatusList);
                }
                setPaymentMethods(createOptionsForSelect(res.data.paymentMethods));

                const formValues = parseDataForForm(
                    _quote,
                    {
                        reference: "",
                        order_attribute: { folder_id: folderId, quote_date: dayjs(new Date()).format("YYYY-MM-DD") },
                        customer_id: folder?.customer_id,
                        order_status_id: Object.entries(_statusLists.orders.order)[0][0],
                        payment_status_id: Object.entries(_statusLists.orders.payment)[0][0],
                        shipping_status_id: Object.entries(_statusLists.orders.shipping)[0][0],
                        discount_percent: quoteId ? _quote.discount_percent : 0,
                        billing_address_id: undefined,
                        shipping_address_id: undefined,
                        products: quoteId ? {
                            id: undefined,
                            _joinData: {
                                id: undefined,
                                product_id: undefined,
                                quantity: 0,
                                unit_tax_excl: 0,
                                total_tax_excl: 0,
                                total_tax: 0,
                                total_tax_incl: 0,
                                vat: 0,
                                label: '',
                            }
                        } : undefined,
                        payments: (quoteId && Array.isArray(_quote.payments) && _quote.payments.length > 0) ? {
                            id: null,
                            order_id: null,
                            payment_method_id: null,
                            status_id: null,
                            reference: null,
                            total_amount: null,
                            comment: "",
                            dahan_payment_date: null,
                            dahan_deposit_id: null
                        } : undefined,
                        discount_tax_excl: 0,
                        discount_tax: 0,
                        discount_tax_incl: 0,
                        total_minus_discount_tax_excl: 0,
                        total_minus_discount_tax: 0,
                        total_minus_discount_tax_incl: 0,
                        shipping_tax_excl: 0,
                        shipping_tax: 0,
                        shipping_tax_incl: 0,
                        shipping_vat: 0,
                        total_tax_excl: 0,
                        total_tax: 0,
                        total_tax_incl: 0,
                        total_tax_excl_plus_shipping_tax_excl: quoteId ? (_quote.total_minus_discount_tax_excl + _quote.shipping_tax_excl) : 0,
                        total_tax_plus_shipping_tax: quoteId ? (_quote.total_minus_discount_tax + _quote.shipping_tax) : 0,
                        total_tax_incl_plus_shipping_tax_incl: quoteId ? (_quote.total_minus_discount_tax_incl + _quote.shipping_tax_incl) : 0,
                        addElements: "1"
                    }
                );

                const _products = formValues.products;
                const _travels = res.data.order.travels;

                if (Array.isArray(_products) && Array.isArray(_travels) && _travels.length > 0) {
                    _products.forEach((product: any) => {
                        _travels.forEach((travel: any) => {
                            if (product._joinData.id === travel.id) {
                                product._joinData = travel;
                                delete product._joinData.order_id;
                            }
                        })
                    })
                }

                setCreditNotes(_quote.credit_notes ?? []);
                setBills(_quote.bills ?? []);
                setBilled(res.data.billed);

                let _quoteEnabled = true;
                if (_quote.billed) {
                    _quoteEnabled = false;
                } else {
                    _quoteEnabled = quoteId ? _quote.order_status.unique_key === "quote_inprogress" : true
                }
                setQuoteEnabled(_quoteEnabled);

                setPaymentsVisible(quoteId ? _quote.order_status.unique_key === "quote_validate" : false);

                resetInitialValues(formValues);
                setReady(true);

            } else {
                setNavigateState(`/folders/add-edit/${folderId}`);
            }
        });
    }, [quoteId, refresh, folderReady]);

    useOrderCalculLines(ready, [...OrderProductsFieldNames, "_joinData.order_product_attribute", "_joinData.label"], 'TTC');
    useOrderCalcul(ready);

    const isNotUserCommercial = useRecoilValue(IsNotUserCommercialSelector);

    return (
        <AddEditForm
            formId="quoteForm"
            formHandleCancel={resetInitialValues}
            formHandleSubmit={handleSubmit}
            isDirty={isDirty}
            returnAction={{
                title: "Retour au dossier",
                event: () => {
                    setNavigateState(`/folders/add-edit/${folderId}`);
                }
            }}
        >
            {!ready && <Box m={2}><Skeleton variant="rectangular" height={300} /></Box>}
            {ready &&
                <>
                    <Grid container display="flex" >
                        <Grid item xs={12} md={4}>
                            {folderReady && folder && <>
                                <Box m={2}>
                                    <CustomerCard customerId={folder.customer_id} />
                                    <FolderDetails folder={folder} commercialName={commercialName} />
                                </Box>
                            </>}
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <AddEditFormCard title={title}>

                                <Grid container spacing={2}>

                                    <Grid item xs={12} >
                                        <InputField
                                            name="reference"
                                            label="Titre"
                                            type="text"
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <SelectField
                                            name="order_status_id"
                                            label="Statut de devis"
                                            options={quoteStatus}
                                            required
                                        />
                                    </Grid>
                                    <Grid item sm={6}>
                                        <DateField
                                            type="date"
                                            label="Date du devis"
                                            name="order_attribute.quote_date"
                                            required
                                            disabled={!quoteEnabled}
                                        />
                                    </Grid>
                                    <CustomOrderAddressesView  required={false} />
                                </Grid>
                            </AddEditFormCard>
                        </Grid>
                    </Grid>

                    <AddEditFormCard title="Voyage / Lignes" withExpand>
                        <QuoteProducts products={products} disabled={!quoteEnabled} />
                    </AddEditFormCard>

                    <OrderTotalView discount={true} shipping={false} disabled={!quoteEnabled} />

                    <AddEditFormCard title="Paiements" withExpand={paymentsVisible}>
                        <QuotePaymentsView deposits={deposits} methodPaymentStatus={methodPaymentStatus} paymentMethods={paymentMethods} disabled={!paymentsVisible || !isNotUserCommercial} />
                        {
                            (!paymentsVisible ) && (<Box mt={1}><Alert color="info">Le devis doit être validé pour saisir des paiements</Alert></Box>)
                        }
                    </AddEditFormCard>



                    <AddEditFormCard title="Facture / Avoirs" withExpand>
                        {quoteId &&
                            <OrderBillsCustomView id={quoteId} billed={billed} bills={bills} creditNotes={creditNotes} sendPdf={false} disabled={!paymentsVisible || !isNotUserCommercial} />
                        }
                        {
                            (!paymentsVisible ) && (<Box mt={1}><Alert color="info">Le devis doit être validé</Alert></Box>)
                        }
                    </AddEditFormCard>

                </>
            }
        </AddEditForm >
    )
}

export default QuoteView;
