import { useNavigate, useParams } from "react-router-dom";
import { useAddEditCore } from "../../../stores/core/StoreCore";
import { useEffect, useMemo, useState } from "react";
import { ReqStatus } from "../../../stores/core/UseApi";
import { useAddEditForm } from "../../../components/common/form/AddEditFormPanel";
import AddEditForm from "../../../components/common/form/AddEditForm";
import AddEditFormCard from "../../../components/common/form/AddEditFormCard";
import { Box, Card, Divider, Grid, Paper, Skeleton, Typography } from "@mui/material";
import InputField from "../../../components/common/form/InputField";
import BrevoMailConfigsTabs from "./BrevoMailConfigsTabs";
import CheckboxField from "../../../components/common/form/CheckboxField";
import SelectField from "../../../components/common/form/SelectField";
import { BrevoMailConfigEntityEnum, BrevoMailConfigEntityOptions, BrevoMailConfigQuoteStatusEnum, BrevoMailConfigRecipientType, BrevoMailConfigRecipientTypeOptions, BrevoMailConfigVisibilityOptions } from "../../classes/BrevoMailConfig";
import RadioField from "../../../components/common/form/RadioField";
import AutocompleteMultipleField from "../common/AutocompleteMultipleField";
import { useField, useForm, useFormContext, useFormValues } from "react-recoil-form";
import { isValidEmail } from "../../../utils/FormUtils";
import { NavigateState } from "../../../states/MenuState";
import { useSetRecoilState } from "recoil";
import BrevoMailConfigsParamsView from "./BrevoMailConfigsParamsView";
import { BrevoMailConfigsState } from "../../states/MailState";

const BrevoMailConfigsView = () => {
    const routeProject = "project-dahan/dahan/";
    const routeController = "brevo-mail-configs";
    const listRoute = `${routeProject}${routeController}`;
    const { getData, addEditData } = useAddEditCore<any, { brevoMailConfig: any }>(`${listRoute}/add-edit`);
    const [htmlContent, setHtmlContent] = useState("");
    const [tabId, setTabId] = useState(0);
    let { id } = useParams();
    const setNavigateState = useSetRecoilState(NavigateState);
    const setTemplates = useSetRecoilState(BrevoMailConfigsState);

    const [ready, setReady] = useState(false);

    const onSubmit = (request: any) => {
        addEditData(request, id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                setTemplates([]);
                setNavigateState('/brevo-mail-configs');
            }
        })
    }

    const { handleSubmit, resetInitialValues, isDirty } = useAddEditForm({ onSubmit });
    const { setValue } = useFormContext();

    useEffect(() => {
        getData(id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                if (id) {
                    const _data = res.data.brevoMailConfig;
                    setHtmlContent(_data.html_content);
                    resetInitialValues(_data);
                }
            } else {
                setNavigateState('/brevo-mail-configs');
            }
        }).finally(() => {
            setTimeout(() => setReady(true), 500);
        })
    }, [id]);

    const getHtmlContent = useMemo(() => {
        return htmlContent;
    }, [htmlContent]);

    const { fieldValue: name } = useField({ name: 'name' });
    const { fieldValue: entity } = useField({ name: 'entity' });
    const { fieldValue: recipientType } = useField({ name: 'recipient_type' });

    const { setFieldValue: setPdfQuote } = useField({ name: 'pdf_quote' });
    const { setFieldValue: setPdfProforma } = useField({ name: 'pdf_proforma' });
    const { setFieldValue: setPdfBill } = useField({ name: 'pdf_bill' });
    const { setFieldValue: setPdfRoadmap } = useField({ name: 'pdf_roadmap' });
    const { setFieldValue: setPdfTravelInfos } = useField({ name: 'pdf_travelinfos' });
    const { setFieldValue: setLinkQuoteValide } = useField({ name: 'link_quote_valide' });
    const { setFieldValue: setLinkTravelinfosConfirm } = useField({ name: 'link_travelinfos_confirm' });
    const { setFieldValue: setQuoteStatusRequired } = useField({ name: 'quote_status_required' });
    const { setFieldValue: setTravelIsRequired } = useField({ name: 'travel_is_required' });
    const { setFieldValue: setSenderType } = useField({ name: 'sender_type' });

    useEffect(() => {

        if (isNaN(entity)) { return; }
        if (entity === BrevoMailConfigEntityEnum.ENTITY_FOLDER) {
            //PDFS
            setValue('pdf_quote', { value: false });
            setValue('pdf_proforma', { value: false });
            setValue('pdf_roadmap', { value: false });
            setValue('pdf_travelinfos', { value: false });
            //LINKS
            setValue('link_quote_valide', { value: false });
            setValue('link_travelinfos_confirm', { value: false });
            //Required datas
            setValue('quote_status_required', { value: 0 });
            setValue('travel_is_required', { value: false });
        }
        if (entity !== BrevoMailConfigEntityEnum.ENTITY_BILL) {
            setValue('pdf_bill', { value: false });
        }
    }, [entity])

    return (
        <>
            {!ready && <Box m={2} height={"100%"}><Skeleton variant="rectangular" height={"90%"} /></Box>}
            {ready &&

                <AddEditForm
                    formId="circuitTypeForm"
                    formHandleCancel={resetInitialValues}
                    formHandleSubmit={handleSubmit}
                    isDirty={isDirty}
                >

                    <BrevoMailConfigsTabs tabId={tabId} onClickEvent={(id) => setTabId(id)} />
                    <Box sx={tabId === 2 ? undefined : { display: "none" }}>
                        <BrevoMailConfigsParamsView />
                    </Box>

                    <Box sx={tabId === 1 ? undefined : { display: "none" }}>
                        <AddEditFormCard maxWidth="md" title={`Template ${name}`} >
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <InputField name="name" label="Nom" type="text" disabled={true} />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputField name="subject" label="Sujet" type="text" disabled={true} />
                                </Grid>
                                <Grid item xs={12}>
                                    <CheckboxField name="is_active" label="Actif" disabled={true} />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputField name="sender_name" label="Nom de l'expéditeur" type="text" disabled={true} />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputField name="sender_email" label="Email de l'expéditeur" type="text" disabled={true} />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputField name="reply_to" label="Répondre à" type="text" disabled={true} />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputField name="tag" label="etiqueter" type="text" disabled={true} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1">Mail</Typography>
                                    <Card variant="outlined">
                                        <Box m={1}><div dangerouslySetInnerHTML={{ __html: getHtmlContent }} />
                                        </Box>
                                    </Card>
                                </Grid>
                            </Grid>
                        </AddEditFormCard>
                    </Box>

                    <Box sx={tabId === 0 ? undefined : { display: "none" }}>
                        <AddEditFormCard maxWidth="md" title={<Box>{`Configurer le template ${name}`}</Box>}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <InputField name="name_internal" label="Nom interne" type="text" />
                                </Grid>

                                <Grid item xs={6}>
                                    <SelectField name="entity" label="Entité" options={BrevoMailConfigEntityOptions} />
                                </Grid>

                                <Grid item xs={6} >
                                    <SelectField
                                        name="visibility_by_role"
                                        label="Modale mail - Visibilité par rôle"
                                        options={BrevoMailConfigVisibilityOptions}
                                    />
                                    <Typography variant="caption">Si commercial ou gestionnaire est coché, l'admin le verra également.</Typography>
                                </Grid>

                                <Grid item xs={6}>
                                    <SelectField
                                        name="quote_status_required"
                                        label="Devis - Statut requis"
                                        options={[
                                            {
                                                value: BrevoMailConfigQuoteStatusEnum.NONE,
                                                label: "Aucun"
                                            },
                                            {
                                                value: BrevoMailConfigQuoteStatusEnum.INPROGRESS,
                                                label: "En cours"
                                            }, {
                                                value: BrevoMailConfigQuoteStatusEnum.VALIDATED,
                                                label: "Validé"
                                            },
                                        ]}
                                        disabled={entity === BrevoMailConfigEntityEnum.ENTITY_FOLDER}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <CheckboxField name="travel_is_required" label="Voyage obligatoire" disabled={entity === BrevoMailConfigEntityEnum.ENTITY_FOLDER} />
                                </Grid>

                            </Grid>
                            <Box my={2}>
                                <Divider />
                            </Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6">Expéditeur / Destinataires</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectField
                                        name="sender_type"
                                        label="Expéditeur"
                                        options={[
                                            {
                                                value: 0,
                                                label: "Par défaut"
                                            },
                                            {
                                                value: 1,
                                                label: "Email du commercial rattaché au devis"
                                            }
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputField name="sender_email" label="Email de l'expéditeur par défaut (BREVO)" type="text" disabled={true} />
                                </Grid>
                                <Grid item xs={6} flexDirection={"column"} display={"flex"}>
                                    <SelectField
                                        name="recipient_type"
                                        label="Destinataires"
                                        options={BrevoMailConfigRecipientTypeOptions}
                                    />
                                    <Typography variant="caption">Un bouton permet de rajouter les emails dans la modale.</Typography>
                                    {recipientType === BrevoMailConfigRecipientType.SUPPLIER &&
                                        (entity !== BrevoMailConfigEntityEnum.ENTITY_QUOTE &&
                                            entity !== BrevoMailConfigEntityEnum.ENTITY_FOLDER_ORDER_SUPPLIER
                                        ) &&
                                        <Typography variant="caption" color="Orange">Fournisseur fonctionne uniquement avec l'entité Devis ou Demande fournisseur.</Typography>
                                    }
                                </Grid>

                                <Grid item xs={6}>
                                    <AutocompleteMultipleField
                                        name="recipient_custom_emails"
                                        label="Destinataires Custom"
                                        validateOption={isValidEmail}
                                        options={[]}
                                        disabled={recipientType != BrevoMailConfigRecipientType.CUSTOM}
                                    />
                                </Grid>

                            </Grid>

                            <Box my={2}>
                                <Divider />
                            </Box>
                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    <Typography variant="h6">Pièces jointes</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <CheckboxField name="pdf_quote" label="Pdf Devis" disabled={entity === BrevoMailConfigEntityEnum.ENTITY_FOLDER} />
                                </Grid>

                                <Grid item xs={6}>
                                    <CheckboxField name="pdf_proforma" label="Pdf Proforma" disabled={entity === BrevoMailConfigEntityEnum.ENTITY_FOLDER} />
                                </Grid>
                                <Grid item xs={6}>
                                    <CheckboxField name="pdf_travelinfos" label="Pdf Infos voyage" disabled={entity === BrevoMailConfigEntityEnum.ENTITY_FOLDER} />
                                </Grid>
                                <Grid item xs={6}>
                                    <CheckboxField name="pdf_roadmap" label="Pdf Feuille de route" disabled={entity === BrevoMailConfigEntityEnum.ENTITY_FOLDER} />
                                </Grid>

                                <Grid item xs={6}>
                                    <CheckboxField name="pdf_bill" label="Pdf Facture" disabled={entity !== BrevoMailConfigEntityEnum.ENTITY_BILL} />
                                </Grid>
                            </Grid>

                            <Box my={2}>
                                <Divider />
                            </Box>

                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    <Typography variant="h6">Liens</Typography>
                                </Grid>
                                <Grid item xs={6} flexDirection={"column"} display={"flex"}>
                                    <CheckboxField name="link_quote_valide" label="Lien signature du devis" disabled={entity === BrevoMailConfigEntityEnum.ENTITY_FOLDER} />
                                    <Typography variant="caption">Le devis doit être en cours.</Typography>
                                </Grid>
                                <Grid item xs={6} flexDirection={"column"} display={"flex"}>
                                    <CheckboxField name="link_travelinfos_confirm" label="Lien confirmation infos voyage" disabled={entity === BrevoMailConfigEntityEnum.ENTITY_FOLDER} />
                                    <Typography variant="caption">Le devis doit être validé.</Typography>
                                </Grid>
                            </Grid>


                        </AddEditFormCard>
                    </Box >
                </AddEditForm>
            }
        </>

    );
};

export default BrevoMailConfigsView;