import { useCallback, useState } from "react";
import { useFieldArray } from "react-recoil-form";
import { AutocompleteOption } from "../common/form/AutocompleteField";
import AutocompleteCore from "../common/input/AutocompleteCore";
import { Box, Button, Grid } from "@mui/material";
import { useGetProductInformations } from "../../stores/OrdersStore";
import { ReqStatus } from "../../stores/core/UseApi";
import { OrderProductsFieldNames } from "./OrderProductsView";

const OrderProductAdd = (props: { title: string, products: any[], afterSuccessAdd?: () => void }) => {

    const [newProduct, setNewProduct] = useState<AutocompleteOption | null>(null);

    const newProductsFieldArray = useFieldArray({
        name: "newProducts",
        fieldNames: OrderProductsFieldNames,
    });

    const getProductInformations = useGetProductInformations();
    const [addProductVisible, setAddProductVisible] = useState(false);

    const setProductInfos = useCallback((id: number) => {
        getProductInformations(id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                newProductsFieldArray.append(
                    {
                        id: id,
                        _joinData: {
                            product_id: id,
                            quantity: 1,
                            unit_tax_excl: res.data.unitTaxExcl,
                            vat: res.data.vat,
                        }
                    }
                );
                if (props.afterSuccessAdd) {
                    props.afterSuccessAdd();
                }
            }
        }).finally(() => {
            setNewProduct(null);
            setAddProductVisible(false);
        });
    }, [getProductInformations]);

    const confirmProduct = useCallback(() => {
        if (!newProduct) {
            setNewProduct(null);
            setAddProductVisible(false);
            return;
        }
        setProductInfos(+newProduct.id);
    }, [setProductInfos, newProductsFieldArray, newProduct]);

    const addEvent = useCallback(() => {
        if (props.products.length === 1) {
            setProductInfos(+props.products[0].id);
        } else {
            setAddProductVisible(true);
        }
    }, [props.products, setProductInfos]);

    return (
        <>
            {props.products.length > 0 &&
            <>
                {addProductVisible ?
                    <Grid container display="flex" alignItems={"center"}>
                        <Grid item xs={2}>
                            <AutocompleteCore
                                label="Nouveau produit"
                                options={props.products}
                                value={newProduct}
                                onChange={(v) => setNewProduct(v)}
                            />
                        </Grid>
                        <Grid item xs={"auto"} display="flex">
                            <Box ml={2}>
                                <Button variant="contained" color="info" disabled={!newProduct} onClick={() => confirmProduct()}>Confirmer</Button>
                            </Box>
                            <Box ml={2}>
                                <Button variant="contained" color="warning" onClick={() => { setNewProduct(null); setAddProductVisible(false) }}>Annuler</Button>
                            </Box>
                        </Grid>
                    </Grid>
                    :
                    <Button variant="contained" color="info" onClick={() => addEvent()}>{props.title}</Button>
                }
            </>}
        </>
    )
};

export default OrderProductAdd;