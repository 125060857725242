import { GridColDef } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { useGetListCore } from "../../../stores/core/StoreCore";
import ListCore from "../../../components/common/list/ListCore";
import {TagItem} from "../folders/FolderTagItem";
import { ChecktaskListModel } from "../../classes/ChecktaskModel";


const columns: GridColDef[] = [
    {
        field: 'id',
        headerName: "Id",
        width: 100,
    },
    {
        field: 'name',
        headerName: "Nom",
        width: 300,
        renderCell: ({ row }) => (
            <Box display="flex" flexDirection="column">
                <TagItem tag={row}/>
            </Box>
        )
    },
    {
        field: 'sort_order',
        headerName: "Ordre",
        width: 300,
        renderCell: ({ row }) => (
            <Box display="flex" flexDirection="column">
                {row.sort_order}
            </Box>
        )
    }
];

const ChecktaskList = () => {

    const routeController = "project-dahan/dahan/checktasks";
    const [get] = useGetListCore<ChecktaskListModel>(`${routeController}/index`);

    return (
        <ListCore<ChecktaskListModel, any>
            getDatas={get}
            defineDatasToSet={(data) => data.checktasks}
            getRowId={(row) => row.id}
            columns={columns}
            actions={{
                defaultRoute: 'checktasks',
                add: {
                    title: "Ajouter une tâche"
                },
                edit: true,
                remove: {
                    enable: true,
                    route: routeController
                }
            }}
            paginationsOptions={[100]}
        />
    );
}

export default ChecktaskList;