import { Autocomplete, Box, Button, Container, Grid, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import AutocompleteField, { AutocompleteOption } from "../../../components/common/form/AutocompleteField";
import AddEditFormPanel, { useAddEditForm } from "../../../components/common/form/AddEditFormPanel";
import AddEditFormSubmit from "../../../components/common/form/AddEditFormSubmit";
import { useAdduserOnEntity } from "../../stores/FoldersStore";
import { ReqStatus } from "../../../stores/core/UseApi";
import { createOptionsForAutocomplete } from "../../../utils/DataUtils";
import { addUserOnEntityType } from "../../states/FoldersState";
import { folderOriginEnum } from "../../classes/FolderModel";
interface AddUserOnEntityFormProps {
	ids: number[],
	onClose: () => void,
	onValidate: () => void,
	type: addUserOnEntityType
}

interface UserFolderStat {
	id: number,
	name: string,
	day_total: number,
	day_direct: number,
	day_lead: number,
	day_mail: number,
	day_prestabus: number,
	month_total: number,
	month_direct: number,
	month_lead: number,
	month_mail: number,
	month_prestabus: number,
}

const AddUserOnEntityForm = (props: AddUserOnEntityFormProps) => {

	const { ids, type, onClose, onValidate } = props;
	const [options, setOptions] = useState<AutocompleteOption[]>([]);


	const [foldersToday, setFoldersToday] = useState<any[]>([]);
	const [foldersMonth, setFoldersMonth] = useState<any[]>([]);
	const [userFolderStats, setUserFolderStats] = useState<UserFolderStat[]>([]);

	const { getData, addEditData } = useAdduserOnEntity(type);


	const onSubmit = (request: any) => {
		if (Array.isArray(ids) && ids.length > 0) {
			addEditData(request, undefined).then((res) => {
				if (res.status === ReqStatus.SUCCESS) {
					onValidate();
				}
			})
		}
	}

	const { handleSubmit, resetInitialValues, isDirty } = useAddEditForm({
		onSubmit
	});

	useEffect(() => {
		getData(undefined).then((res) => {
			if (res.status === ReqStatus.SUCCESS && res.data) {
				const _users = createOptionsForAutocomplete(res.data.users);
				setOptions(_users);
				setFoldersToday(res.data.foldersToday ?? []);
				setFoldersMonth(res.data.foldersMonth ?? []);
			console.log(res.data.foldersMonth)
				const _userFolderStats: UserFolderStat[] = [];

				//complexity N
				_users.forEach((user) => {
					// console.log( foldersMonth);				
					const _userId = +user.id;
					_userFolderStats[_userId] = {
						id: _userId,
						name: user.label,
						day_direct: 0,
						day_lead: 0,
						day_mail: 0,
						day_prestabus: 0,
						day_total: 0,
						month_direct: 0,
						month_lead: 0,
						month_mail: 0,
						month_prestabus: 0,
						month_total: 0,
					};
				});

				if (Array.isArray(res.data.foldersToday)) {
					//complexity N
					res.data.foldersToday.forEach((ft: any) => {
						//Sinon non défini, je passe au suivant
						if (!_userFolderStats[ft.commercial_id]) { return; }
						if (ft.origin == folderOriginEnum.DIRECT) {
							_userFolderStats[ft.commercial_id].day_direct += ft.count;
						}
						if (ft.origin == folderOriginEnum.MAIL) {
							_userFolderStats[ft.commercial_id].day_mail += ft.count;
						}
						if (ft.origin == folderOriginEnum.LEAD || ft.origin == folderOriginEnum.PRESTABUS){
							_userFolderStats[ft.commercial_id].day_lead += ft.count;
						}
						if (_userFolderStats[ft.commercial_id]) { 
							_userFolderStats[ft.commercial_id].day_total += ft.count; 
						}
						
					});

				}

				if (Array.isArray(res.data.foldersMonth)) {

					res.data.foldersMonth.forEach((ft: any) => {
						if (!_userFolderStats[ft.commercial_id]) { return; }

						if (ft.origin == folderOriginEnum.DIRECT) {
							_userFolderStats[ft.commercial_id].month_direct += ft.count;
						}
						if (ft.origin == folderOriginEnum.MAIL) {
							_userFolderStats[ft.commercial_id].month_mail += ft.count;
						}
						if (ft.origin == folderOriginEnum.LEAD || ft.origin == folderOriginEnum.PRESTABUS){
							_userFolderStats[ft.commercial_id].month_lead += ft.count;
						}
						if (_userFolderStats[ft.commercial_id]) { 
							_userFolderStats[ft.commercial_id].month_total += ft.count; 
						}
						
					});
					
				}

				setUserFolderStats(_userFolderStats);

				resetInitialValues({ ids: ids });
			}
		})
	}, [])

	const entityName = useMemo(() => {
		if (type === addUserOnEntityType.COMMERCIAL_FOLDER) {
			return ids.length > 1 ? "les dossiers" : "le dossier";
		} else {
			return ids.length > 1 ? "les devis" : "le devis";
		}
	}, [type, ids]);

	const title = useMemo(() => {
		return `Affecter ${entityName} ${ids.map((x) => ` #${x}`)}`;
	}, [ids, entityName]);

	const autocompleteLabel = useMemo(() => {
		if (type === addUserOnEntityType.GESTIONNER_QUOTE) {
			return "Gestionnaires";
		} else {
			return "Commercial";
		}
	}, [type]);
	console.log(ids)

	return (
		<AddEditFormPanel
			title={title}
		>
			<form id={"AddUserOnEntityForm"} onSubmit={handleSubmit}>
				<Grid container spacing={3} mt={1}>
					<Grid item xs={12} >
						<AutocompleteField
							name="user_id"
							label={autocompleteLabel}
							required
							options={options}
						/>
					</Grid>
				</Grid>
				<Box mt={2}>
					<AddEditFormSubmit
						returnAction={{ disabled: true }}
						cancelEvent={() => { resetInitialValues(); onClose(); }}
						disabled={!isDirty}
					/>
				</Box>
			</form>

			{type === addUserOnEntityType.COMMERCIAL_FOLDER &&
				<Box>
					<Typography variant="h6">Dossiers affectés aux commerciaux</Typography>
					<TableContainer component={Paper}>
						<Table size="small" aria-label="a dense table">
							<TableHead>
								<TableRow>
									<TableCell>Commercial</TableCell>
									<TableCell>D</TableCell>
									<TableCell>L</TableCell>
									<TableCell>M</TableCell>
									<TableCell>Jour</TableCell>
									<TableCell>|</TableCell>

									<TableCell>D</TableCell>
									<TableCell>L</TableCell>
									<TableCell>M</TableCell>
									<TableCell>Mois</TableCell>

								</TableRow>
							</TableHead>
							<TableBody>
								{userFolderStats.map((userFolderStat) => (
									<TableRow
										key={userFolderStat.id}

									>
										<TableCell component="th" scope="row">
											{userFolderStat.name}
										</TableCell>
										<TableCell>{userFolderStat.day_direct}</TableCell>
										<TableCell>{userFolderStat.day_lead}</TableCell>
										<TableCell>{userFolderStat.day_mail}</TableCell>
										<TableCell>{userFolderStat.day_total}</TableCell>
										<TableCell>|</TableCell>
										<TableCell>{userFolderStat.month_direct}</TableCell>
										<TableCell>{userFolderStat.month_lead}</TableCell>
										<TableCell>{userFolderStat.month_mail}</TableCell>
										<TableCell>{userFolderStat.month_total}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
			}
		</AddEditFormPanel >
	)
}

export default AddUserOnEntityForm;