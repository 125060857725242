import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { ConfirmModalState } from "../states/ModalState";


export const ConfirmModal = () => {
    const [{open, title, desc, validEvent}, setState] = useRecoilState(ConfirmModalState);
    const handleClose = useCallback(() => {
        setState((s) => { return {...s, title:"", desc: "", confirmEvent: () => {}, open: false}; });
    }, [setState]);

    const handleConfirm = useCallback(() => {
        validEvent();
        handleClose();
    }, [validEvent, handleClose]);

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {desc}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>
                    Annuler
                </Button>
                <Button onClick={handleConfirm} variant="contained" color="primary">
                    Valider
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmModal;