import { useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { useAddEditSupplier, useGetSupplier } from "../../../stores/SuppliersStore";
import { SupplierModel } from "../../../classes/suppliers/SupplierModel";
import { ReqStatus } from "../../../stores/core/UseApi";
import { useAddEditForm } from "../../../components/common/form/AddEditFormPanel";
import { useEffect, useState } from "react";
import AddEditForm from "../../../components/common/form/AddEditForm";
import AddEditFormCard from "../../../components/common/form/AddEditFormCard";
import { Autocomplete, Box, Chip, Grid, TextField } from "@mui/material";
import InputField from "../../../components/common/form/InputField";
import TextAreaField from "../../../components/common/form/TextAreaField";
import { NavigateState } from "../../../states/MenuState";
import { useField } from "react-recoil-form";
import { isValidEmail, isValidPhone } from "../../../utils/FormUtils";
import AutocompleteMultipleField from "../common/AutocompleteMultipleField";
import { SupplierCustomModel } from "../../classes/SupplierCustomModel";
import CheckboxField from "../../../components/common/form/CheckboxField";

const SupplierCustomView = () => {

	let { id } = useParams();

	const setNavigateState = useSetRecoilState(NavigateState);
	const listRoute = "/commercial/suppliers";
	const [get] = useGetSupplier();
	const [addOrEdit] = useAddEditSupplier();

	const [categories, setCategories] = useState<string[]>([]);


	const onSubmit = (request: SupplierCustomModel) => {

		let _request = JSON.parse(JSON.stringify(request)) as SupplierCustomModel;

		if (!_request.products_suppliers) {
			delete _request.products_suppliers;
		}
		if (!_request.newProductsSuppliers) {
			delete _request.newProductsSuppliers;
		}

		addOrEdit(_request, id).then((res) => {
			if (res.status === ReqStatus.SUCCESS && res.data) {
				setNavigateState("/commercial/suppliers/");
			}
		});
	}

	const { handleSubmit, resetInitialValues, isDirty } = useAddEditForm({
		onSubmit
	});

	useEffect(() => {
		get(id ? +id : undefined).then((res) => {
			if (res.status === ReqStatus.SUCCESS && res.data) {

				const _supplier = res.data.supplier as SupplierCustomModel;
				setCategories((res.data as any).categories ?? []);

				if (id) {
					resetInitialValues({
						name: _supplier.name,
						email: _supplier.email,
						phone: _supplier.phone,
						address: _supplier.address,
						comment: _supplier.comment,
						active: _supplier.active,
						dahan_categories: _supplier.dahan_categories,
						dahan_emails_compl: _supplier.dahan_emails_compl,
						dahan_phones_compl: _supplier.dahan_phones_compl,
						dahan_phone_astreinte: _supplier.dahan_phone_astreinte,
						dahan_frais: _supplier.dahan_frais,
						dahan_siret: _supplier.dahan_siret,
					});
				} else {
					resetInitialValues({
						name: "",
						email: "",
						phone: "",
						address: "",
						comment: "",
						active: 1,
						dahan_categories: "",
						dahan_emails_compl: "",
						dahan_phones_compl: "",
						dahan_phone_astreinte: "",
						dahan_frais: 0,
						dahan_siret: ""
					});
				}
			} else {
				setNavigateState(listRoute);
			}
		})
	}, [id]);

	return (

		<AddEditForm formId="supplierForm" formHandleCancel={resetInitialValues} formHandleSubmit={handleSubmit} isDirty={isDirty}>

			<AddEditFormCard title={`${(!id ? "Ajouter " : "Modifier")} un fournisseur`}>
				<Grid container spacing={3}>

					<Grid item xs={12} sm={6} display="flex" alignItems="center">
						<InputField
							name="name"
							label="Nom"
							required
							type="text"
						/>
						<Box ml={2}>
							<CheckboxField
								name="active"
								label="Actif"
							/>
						</Box>
						<Box ml={2}>
							<CheckboxField
								name="dahan_frais"
								label="Frais"
							/>
						</Box>
					</Grid>

					<Grid item xs={12} sm={6}>
						<AutocompleteMultipleField
							name="dahan_categories"
							options={categories}
							label="Catégories"
						/>
					</Grid>

					<Grid item xs={12} sm={6}>
						<InputField
							name="email"
							label="Email principal"
							type="text"
							isEmail
						/>
					</Grid>

					<Grid item xs={12} sm={6}>
						<AutocompleteMultipleField
							name="dahan_emails_compl"
							options={[]}
							label="Emails supplémentaires"
							validateOption={isValidEmail}
						/>
					</Grid>


					<Grid item xs={12} sm={6}>
						<InputField
							name="phone"
							label="Téléphone principal"
							type="text"
						/>
					</Grid>


					<Grid item xs={12} sm={6}>
						<AutocompleteMultipleField
							name="dahan_phones_compl"
							options={[]}
							label="Téléphones supplémentaires"
						/>
					</Grid>

					<Grid item xs={12} sm={6}>
						<InputField
							name="dahan_phone_astreinte"
							label="Téléphone astreinte"
							type="text"
						/>
					</Grid>

					<Grid item xs={12} sm={6}>
						<TextAreaField
							name="address"
							label="Adresse"
						/>
					</Grid>

					<Grid item xs={12} sm={6}>
						<InputField
							name="dahan_siret"
							label="Siret"
							type="text"
						/>

					
					</Grid>
					<Grid item xs={12} sm={6}>
							<TextAreaField
								name="comment"
								label="Commentaire"
							/>
						</Grid>
				</Grid>


			</AddEditFormCard>
		</AddEditForm>

	);
}

export default SupplierCustomView;