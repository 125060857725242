import { Box, IconButton, Tooltip } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { ConfirmModalState } from "../../../states/ModalState";
import { useSetRecoilState } from "recoil";
import { useCallback } from "react";
import { ReqStatus } from "../../../stores/core/UseApi";
import { useFolderReminderConfirm } from "../../stores/FolderRemindersStore";
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';

const FolderReminderButtonConfirm = (props: { id: number, active: boolean, afterSuccess: () => void, size?: "small" | "medium" | "large" }) => {

    const confirmAjax = useFolderReminderConfirm();
    const event = useCallback(() => {
        confirmAjax(props.id).then((res) => {
            if (res.status === ReqStatus.SUCCESS) {
                props.afterSuccess();
            }
        })
    }, [confirmAjax, props]);

    return (
        <Box>
            <Tooltip title={props.active ? "Désactivation de la tâche" : "Activation de la tâche"}>
                <IconButton size={props.size ?? "medium"} onClick={() => event()}>
                    { props.active ? <ToggleOnIcon style={{ color: "green" }} /> : <ToggleOffIcon /> }
                </IconButton>
            </Tooltip>
        </Box>
    )
}

export default FolderReminderButtonConfirm;