import { GridColDef } from "@mui/x-data-grid";
import { dateFormat, datetimeFormat } from "../../../../utils/DateUtils";
import { Box } from "@mui/material";
import AvatarIcon from "../../icon/AvatarIcon";

interface CreateColumnAvatarProps {
	headerName: string
	field: string,
	minWidth?: number,
	type?: "string" | "number"
	getId: (row: any) => any
	getValue: (row: any) => any
}
export const CreateColumnAvatar = (props : CreateColumnAvatarProps) => {
	return {
		field: props.field,
		headerName: props.headerName,
		editable: false,
		filterable: false,
		align: "center",
		type: props.type ?? "number",
		width: props.minWidth ?? 90,
		valueGetter: ({ row }) => props.getId(row),
		renderCell: ({ row }) => (
			<>
				{props.getId(row) &&
					<AvatarIcon value={props.getValue(row)} />
				}
			</>
		)
	} as GridColDef
}