import { atom } from "recoil";

export const FolderOrderSupplierContactModalState = atom<{open: boolean, id?: number | undefined,  onValidate: () => void}>({
    key: 'FolderOrderSupplierContactModalKey',
    default: {
        open: false,
        id: undefined,
        onValidate: () => {}
    }
});
