import { Box, Card, Grid, Link, Typography, styled } from "@mui/material";
import { PropsWithChildren } from "react";
import { FormattedMessage } from "react-intl";
import { useRecoilValue } from "recoil";
import { CompanyNameState } from "../../states/MenuState";

const Copyright = (props: { companyName : string }) => {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="">
                {props.companyName}
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

const RootBloc = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: 'lightskyblue'
});

const LoginCard = (props: PropsWithChildren) => {

    const companyName = useRecoilValue(CompanyNameState);

    return (
        <RootBloc>
            <Card sx={{ minWidth: 400, padding: 2 }}>
                <Grid container component="main" display={"inline"}>
                    <Box textAlign={'center'}>
                        <Typography variant="h5">
                            {companyName}
                        </Typography>
                    </Box>
                    {props.children}
                    <Box pt={2}>
                        <Copyright companyName={companyName} />
                    </Box>
                </Grid>
            </Card>
        </RootBloc >
    );
}

export default LoginCard;