import { Box, Button, TableCell, TableRow } from "@mui/material";
import { datetimeFormat } from "../../utils/DateUtils";

const OrderBillActions = (props: { id: string, sendPdf: boolean }) => {

    const route = "commercial/bills";
    const { id, sendPdf } = props;
    const openOtherWindow = (url: string) => {
        window.open(`${process.env.REACT_APP_API}${url}`, '_blank');
    }

    return (
        <Box display="flex">
            <Box>
                <Button variant="contained" color="info" onClick={() => openOtherWindow(`${route}/view-pdf/${id}.pdf`)}>Voir</Button>
            </Box>
            <Box ml={2}>
                <Button variant="contained" color="success" onClick={() => openOtherWindow(`${route}/download-pdf/${id}.pdf`)}>Télécharger</Button>
            </Box>
            {sendPdf &&
                <Box ml={2}>
                    <Button variant="contained" color="info" onClick={() => openOtherWindow(`${route}/send-pdf/${id}.pdf`)}>Envoyer client</Button>
                </Box>
            }

        </Box>
    );
}

const OrderBillRow = (props: { data: any, sendPdf: boolean  }) => {
    const { data, sendPdf } = props;
    return (
        <TableRow>
            <TableCell>
                {data.is_credit_note ? "Avoir" : "Facture"} N°{data.id}
            </TableCell>
            <TableCell>
                {datetimeFormat(data.created)}
            </TableCell>
            <TableCell>
                {data.total_minus_discount_tax_excl}
            </TableCell>
            <TableCell>
                {data.total_minus_discount_tax_incl}
            </TableCell>
            <TableCell>
                <OrderBillActions id={data.id} sendPdf={sendPdf} />
            </TableCell>
        </TableRow>
    );
}

export default OrderBillRow;