import { Box, Grid } from "@mui/material";
import AddEditFormCard from "../common/form/AddEditFormCard";
import { useFormValues } from "react-recoil-form";

const BillCustomerAddress = ( props: {pref: string, values : any} ) => {
	const { pref, values } = props;
	return (
		<>
			<Box>{values[pref + '_last_name']} {values[pref + '_first_name']}</Box>
			<Box>{values[pref + '_email']}</Box>
			<Box>
				<Box>
					{values[pref + '_address_1']}
				</Box>
				<Box>
					{values[pref + '_address_2']}
				</Box>
				<Box>
					{values[pref + '_postcode']} {values[pref + '_city']} {values[pref + '_fr']}
				</Box>
			</Box>
		</>
	)
}

const BillCustomerInfo = () => {
	const values = useFormValues();
	return (
		<Grid container>
			<Grid item xs={12} sm={6}>
				<AddEditFormCard title={'Adresse livraison'} withExpand>			
					<BillCustomerAddress values={values} pref={'shipping'}/>
				</AddEditFormCard>
			</Grid>
			<Grid item xs={12} sm={6}>
				<AddEditFormCard title={'Adresse facturation'} withExpand>
					<BillCustomerAddress values={values} pref={'billing'}/>
				</AddEditFormCard>
			</Grid>
		</Grid>

	)
}

export default BillCustomerInfo;