import { useCallback } from "react";
import { useApi } from "../../stores/core/UseApi";

export const useTravelInfosConfirmInternal = () => {
    const [callCore] = useApi<any, any>(
        {
            route:  `project-dahan/dahan/quotations/travel-infos-confirm-internal`,
            instanceAxiosMethod: "POST"
        }
    );
    const call = useCallback((id : string, travel: any) => {
        return callCore( travel, `/${id ?? ""}` );
    }, [callCore]);
    return call;
};

export const useDuplicateQuote = () => {
    const [callCore] = useApi<undefined, undefined>(
        {
            route:  `project-dahan/dahan/quotations/duplicate`,
            instanceAxiosMethod: "POST"
        }
    );
    const call = useCallback((id : number | string) => {
        return callCore( undefined, `/${id ?? ""}` );
    }, [callCore]);
    return call;
};

export const useGetOrderByAddressId = () => {
    const [callCore] = useApi<any, undefined>(
        {
            route:  `project-dahan/dahan/quotations/get-order-by-address-id`,
            instanceAxiosMethod: "GET"
        }
    );
    const call = useCallback((id: number) => {
        return callCore(undefined, `/${id ?? ""}`);
    }, [callCore]);
    return call;
};

export const useGetLinkTravelInfosConfirm = () => {
    const [callCore] = useApi<any, undefined>(
        {
            route:  `project-dahan/dahan/quotations/get-link-travel-infos-confirm`,
            instanceAxiosMethod: "GET"
        }
    );
    const call = useCallback((id: number) => {
        return callCore(undefined, `/${id ?? ""}`);
    }, [callCore]);
    return call;
};

