import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const AddEditFormHeader = (props: { listRoute: string | undefined, title: string }) => {
    const { listRoute, title } = props;
    const navigate = useNavigate();
    return (
        <Box display="flex" justifyContent={listRoute ? "space-between" : "center"}>
            {listRoute &&
                <Box>
                    <Button color="info" variant="contained" onClick={() => navigate(listRoute)}>Retour</Button>
                </Box>
            }
            <Typography component="h4" variant="h4" align="center">{title}</Typography>
            {listRoute &&
                <Box sx={{
                    width: {
                        xs: "0px",
                        md: "90px"
                    }
                }} />
            }
        </Box>
    );
}
export default AddEditFormHeader;