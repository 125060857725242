import { useNavigate, useParams } from "react-router-dom";
import { useAddEditProductType, useGetProductType } from "../../stores/ProductTypesStore";
import AddEditFormPanel, { useAddEditForm } from "../common/form/AddEditFormPanel";
import AddEditFormSubmit from "../common/form/AddEditFormSubmit";
import { ReqStatus } from "../../stores/core/UseApi";
import { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import InputField from "../common/form/InputField";
import AddEditForm from "../common/form/AddEditForm";
import AddEditFormCard from "../common/form/AddEditFormCard";
import SelectField, { SelectOptionsProps } from "../common/form/SelectField";
import { useAddEditCore } from "../../stores/core/StoreCore";
import { createOptionsForSelect } from "../../utils/DataUtils";

interface CategoryResponse{
    category: any,
    categories: { [id: string]: string },
}
const CategoryView = () => {

    const listRoute = "/commercial/categories";
    let { id } = useParams();
    const navigate = useNavigate();
    const [categories, setCategories] = useState<SelectOptionsProps[]>([]);
    const { getData, addEditData } = useAddEditCore<any, CategoryResponse>(`${listRoute}/add-edit`);

    const onSubmit = (request: any) => {
        addEditData(request, id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                navigate(listRoute);
            }
        })
    }

    const { handleSubmit, resetInitialValues, isDirty } = useAddEditForm({ onSubmit, initialValues: { name: "", parent_categories: [] } });

    useEffect(() => {
        getData(id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                setCategories(createOptionsForSelect(res.data.categories));
                if (id) {
                    resetInitialValues({
                        name: res.data.category.name,
                        parent_categories: res.data.category.parent_categories?.map((cat: any) => cat.id.toString())  ?? []
                    });
                }
            } else {
                navigate(listRoute);
            }
        })
    }, [id]);

    return (
        <AddEditForm formId="categoryForm" formHandleCancel={resetInitialValues} formHandleSubmit={handleSubmit} isDirty={isDirty}>
            <AddEditFormCard title={`${!id ? "Ajouter" : "Modifier"} une catégorie de produit`} maxWidth="md" >
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <InputField
                            name="name"
                            label="Nom"
                            type="text"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SelectField multiple name="parent_categories" label="Catégorie(s) parente(s)" options={categories}/>
                    </Grid>
                </Grid>
            </AddEditFormCard>
        </AddEditForm>
    )
}

export default CategoryView;