import { useNavigate, useParams } from "react-router-dom";
import { useAddEditForm } from "../common/form/AddEditFormPanel";
import { ReqStatus } from "../../stores/core/UseApi";
import { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import InputField from "../common/form/InputField";
import { useAddEditCore } from "../../stores/core/StoreCore";
import AddEditForm from "../common/form/AddEditForm";
import AddEditFormCard from "../common/form/AddEditFormCard";

const CustomerTypeView = () => {
    const listRoute = "/commercial/customer-types";
    const { getData, addEditData } = useAddEditCore<any, { customerType: any }>(`${listRoute}/add-edit`);

    let { id } = useParams();
    const navigate = useNavigate();

    const onSubmit = (request: any) => {
        addEditData(request, id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                navigate(listRoute);
            }
        })
    }

    const { handleSubmit, resetInitialValues, isDirty } = useAddEditForm({ onSubmit, initialValues: { label: "" } });
    useEffect(() => {
        getData(id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                if (id) {
                    const _customerType = res.data.customerType;
                    resetInitialValues({
                        label: _customerType.label
                    });
                }
            } else {
                navigate(listRoute);
            }
        })
    }, [id]);

    return (
        <AddEditForm  formId={"customerTypeForm"} formHandleSubmit={handleSubmit} formHandleCancel={resetInitialValues} isDirty={isDirty} >
            <AddEditFormCard title={(!id ? "Ajouter " : "Modifier") + " un type de client"} >
                <Grid container spacing={3} >
                    <Grid item xs={12} sm={6}>
                        <InputField name="label" label="Label" type="text" required />
                    </Grid>
                </Grid>

            </AddEditFormCard>
        </AddEditForm>
    )
}

export default CustomerTypeView;