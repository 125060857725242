import React, { useCallback, useEffect, useRef, useState } from 'react';
import '@geoapify/geocoder-autocomplete/styles/round-borders.css';
import { Box, TextField } from '@mui/material';

import { TomTomAddressActions, TomTomAddressOptions, useAddressDebounce, useTomTomAddressApi } from './TomTomAddressCommon';

interface SelectedOption {
	label: string;
	coordinates: [number, number];
}
interface TomTomAddressFieldProps {
	label: string,
	size: "small" | "medium" ,
	value: string,
	onChange: (value: string) => void,
	disabled?: boolean,
	required?: boolean,
	onCoordinatesSelect?: (coords: [number, number]) => void;
}

const TomTomAddressInput = (props: TomTomAddressFieldProps) => {
	const { onCoordinatesSelect, value, size, onChange, label, disabled, required } = props;
	const anchorRef = useRef<HTMLDivElement>(null);

	
	const [isSelecting, setIsSelecting] = useState(false);
	const [ready, setReady] = useState(false);

	const { 
		open,
		setOpen,
		options,
		loading,
		noResult,
		searchEvent
	} = useTomTomAddressApi();

	const selectOption = useCallback((option: any) => {
		
		setIsSelecting(true);
		onChange(option.label);
		setOpen(false);

		setTimeout(() =>{
			setIsSelecting(false);
		}, 1000);
	}, []);


	useEffect(() => {
		setTimeout(() =>{
			setReady(true);
		}, 1000);
	},[])

	/*
	//Evenement de recherche après avoir saisi une adresse
	const debouncedFieldValue = useAddressDebounce(value, 500);
	useEffect(() => {
		if(!ready){ return; }
		if(!isSelecting && debouncedFieldValue){
			searchEvent(debouncedFieldValue);
		}
	},[debouncedFieldValue]);
	*/

	return (
		<Box width={"100%"} ref={anchorRef}>

			<TextField
				size={size}
				label="Lieu"
				value={value}
				onChange={(e) => onChange(e.target.value)}
				fullWidth
				InputProps={{
					endAdornment: <TomTomAddressActions loading={loading} open={open} setOpen={setOpen} optionsLength={options.length} searchEvent={searchEvent} value={value} disabled={disabled} />
				}}
			/>
			
			<TomTomAddressOptions 
				disabled={disabled}
				options={options}
				open={open}
				anchorRef={anchorRef}
				noResult={noResult}
				selectOption={selectOption}
			/>
				
		</Box>
	);
};
export default TomTomAddressInput;