import { useCallback } from "react";
import { ListModelCore, ListSearchModelCore, getParamsForListRequest } from "../classes/list/ListModel";
import { GenericResponse, ReqStatus, useApi } from "./core/UseApi";
import { useGetListCore } from "./core/StoreCore";

interface ProductTypeListModel extends ListModelCore{
    productTypes: any[]
}
export const useGetProductTypeList = () => {
    return useGetListCore<ProductTypeListModel>('commercial/product-types/index');
};

export const useGetProductType = function (): [(id?: number) => Promise<GenericResponse<{ productType : any }>>, ReqStatus] {
    const [callCore, status] = useApi<{ productType : any }, undefined>(
        {
            route: 'commercial/product-types/add-edit',
            instanceAxiosMethod: "GET"
        }
    );
    const call = useCallback((id? : number) => {
        return callCore( undefined, `/${id ?? ""}` );
    }, [callCore]);
    return [call, status];
};


export const useAddEditProductType = function (): [(data: any, id : string | undefined) => Promise<GenericResponse<any>>, ReqStatus] {
    const [callCore, status] = useApi<any, any>(
        {
            route: 'commercial/product-types/add-edit',
            instanceAxiosMethod: "POST"
        }
    );
    const call = useCallback((data: any, id : string | undefined) => {
        return callCore(data, id ? `/${id}` : '');
    }, [callCore]);
    return [call, status];
};