
import { Alert, Box, Button, Grid, TextField } from '@mui/material';
import React, { startTransition, useCallback, useMemo, useState } from 'react';
import { DragDropContext, Draggable, DraggableProvided, Droppable } from 'react-beautiful-dnd';
import AddEditFormCard from '../../../../components/common/form/AddEditFormCard';
import { QuoteCircuitDragIcon } from './QuoteCircuitCommon';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { QuoteCircuitDaySelector, QuoteCircuitState } from '../../../states/QuoteCircuitState';
import { v4 as uuidv4 } from 'uuid';
import { QuoteCircuitDayModel, QuoteCircuitDayStepAddressModel, QuoteCircuitDayStepModel } from '../../../classes/QuoteModel';
import QuoteCircuitDayStep from './QuoteCircuitDayStep';
import { ConfirmModalState } from '../../../../states/ModalState';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface QuoteCircuitDayProps {
	id: string,
	provided: DraggableProvided,
	addCircuitDayEvent: () => void,
	duplicateCircuitDayEvent: () => void,
	deleteEvent: () => void,
	isExpandDefault?: boolean,
}

const QuoteCircuitDayTitle = (props: { id: string }) => {
	const [quoteCircuitDay, setQuoteCircuitDay] = useRecoilState(QuoteCircuitDaySelector(props.id));
	return (
		<TextField size="small" fullWidth label="Titre" defaultValue={quoteCircuitDay?.title} onBlur={(e) =>
			startTransition(() => {
				setQuoteCircuitDay((day) => {
					if (!day) { return day; }
					return {
						...day,
						title: e.target.value
					};
				})
			})
		}
		/>
	)
}

const QuoteCircuitDay = (props: QuoteCircuitDayProps) => {
	const { id, provided, deleteEvent, addCircuitDayEvent, duplicateCircuitDayEvent, isExpandDefault } = props;

	const [quoteCircuitDay, setQuoteCircuitDay] = useRecoilState(QuoteCircuitDaySelector(id));

	const [cardIsExpand, setCardIsExpand] = useState<boolean | undefined>(isExpandDefault ?? undefined);

	const circuit = useRecoilValue(QuoteCircuitState);

	const steps = useMemo(() => {
		if (!quoteCircuitDay) { return []; }
		return quoteCircuitDay.steps;
	}, [quoteCircuitDay?.steps]);

	const maxSort = useMemo(() => {
		return steps.length > 0 ? steps.reduce((max, currentDay) => currentDay.sort > max ? currentDay.sort : max, steps[0].sort) : -1;
	}, [steps])

	const onDragEnd = useCallback((result: any) => {
		if (!result.destination) {
			return;
		}

		setQuoteCircuitDay((d) => {
			if (!d) { return d; }
			const newSteps = JSON.parse(JSON.stringify(d.steps)) as QuoteCircuitDayStepModel[];
			const startIndex = result.source.index;
			const endIndex = result.destination.index;
			// Trouver l'élément déplacé
			const [removed] = newSteps.splice(startIndex, 1);
			// Insérer l'élément déplacé à sa nouvelle position
			newSteps.splice(endIndex, 0, removed);
			// Mise à jour des valeurs de 'sort' pour refléter le nouvel ordre
			newSteps.forEach((step, index) => {
				step.sort = index;
			});
			return { ...d, steps: newSteps };
		})
	}, []);

	// Récupérer la dernière adresse
	const getLastAddress = useCallback((day: QuoteCircuitDayModel, index?: number) => {

		let addresses: QuoteCircuitDayStepAddressModel[] | undefined = undefined;
		if (day?.steps.length == 0) {
			// Il n'y a pas d'étape dans le jour, regarder si il y a un jour avant
			const sort = day?.sort;
			if (sort && sort > 0) {
				const previousDay = circuit.days[sort - 1];
				if(previousDay?.steps?.length > 0){
					addresses = previousDay?.steps[previousDay.steps.length - 1]?.addresses; // Dernière étape
				}
			}
		} else if (index === undefined) {
			// Il y a des étapes mais pas d'index, regarder la dernière étape du jour
			addresses = day?.steps[day.steps.length - 1].addresses; // Dernière étape
		} else {
			// Il y a des étapes et un index, regarder l'étape avant
			addresses = day?.steps[index].addresses; // Etape d'avant
		}

		let lastAddress: QuoteCircuitDayStepAddressModel | undefined = undefined;
		if (addresses && addresses.length > 0) {
			lastAddress = {
				...addresses[addresses.length - 1],
				id: uuidv4() 
			};
		}

		return lastAddress;
	}, [circuit.days]);

	const addCircuitDayStepEvent = useCallback((index?: number, duplicate?: boolean) => {

		const addStepJustAfter = (index !== undefined && !isNaN(index) && index !== maxSort);

		setQuoteCircuitDay((d) => {
			if (!d) { return d; }

			let newStep: QuoteCircuitDayStepModel;
			if (index !== undefined && !isNaN(index) && duplicate) {
				newStep = JSON.parse(JSON.stringify(d.steps[index])) as QuoteCircuitDayStepModel;
				newStep.sort = (addStepJustAfter) ? index + 0.5 : maxSort + 1;
				newStep.id = uuidv4();
				newStep.addresses?.forEach((address) => {
					address.id = uuidv4();
				});
			} else {
				const _lastAddress = getLastAddress(d, index);
				newStep = {
					id: uuidv4(),
					sort: (addStepJustAfter) ? index + 0.5 : maxSort + 1,
					pax: 0,
					distance: 0,
					cars: 0,
					addresses: _lastAddress ? [
						{
							..._lastAddress,
							type: 3 //Ramassage
						}
					] : [],
					hour: ''
				}
			}

			const newSteps = [
				...(JSON.parse(JSON.stringify(d.steps)) as QuoteCircuitDayStepModel[]),
				newStep
			];

			if (addStepJustAfter) {
				newSteps.sort((a, b) => a.sort - b.sort);
				newSteps.forEach((step, index) => {
					step.sort = index;
				});
			}

			return {
				...d,
				steps: newSteps
			};
		});

		setCardIsExpand(true);
		//HACK - Pour que la valeur boolean puisse être de nouveau interprété
		setTimeout(() => {
			setCardIsExpand(undefined);
		}, 1000);
	}, [maxSort, getLastAddress]);

	const setConfirmModalState = useSetRecoilState(ConfirmModalState);

	const deleteStepEvent = useCallback((id: string) => {
		setConfirmModalState({
			open: true,
			title: "Suppression",
			desc: "Êtes-vous sûr de vouloir supprimer cette étape ?",
			validEvent: () => {
				setQuoteCircuitDay((d) => {
					if (!d) { return d; }
					let newSteps = JSON.parse(JSON.stringify(d.steps)) as QuoteCircuitDayStepModel[];
					newSteps = newSteps.filter((s) => s.id !== id);
					newSteps.forEach((step, index) => {
						step.sort = index;
					});
					return {
						...d,
						steps: newSteps
					}
				});
			}
		})
	}, []);

	const onChangeDay = useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, property: "date" | "title") => {
		setQuoteCircuitDay((day) => {
			if (!day) { return day; }
			return {
				...day,
				[property]: event.target.value ?? ""
			};
		})
	}, [])
	const onChangeDate = useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		onChangeDay(event, "date");
	}, []);


	const StepsContent = useMemo(() => {
		return (
			<>
				{steps.length == 0 && <Alert color="info">Aucune étape</Alert>}
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId="QuoteCircuitDay" direction="vertical">

						{(provided, snapshot) => (
							<Box {...provided.droppableProps} ref={provided.innerRef} display="flex" flexDirection={"column"} >

								{steps.map((step, index) => (
									<Draggable key={step.id} draggableId={step.id.toString()} index={step.sort}>
										{(provided, snapshot) => (
											<QuoteCircuitDayStep
												id={step.id}
												dayId={id}
												provided={provided}
												addCircuitDayStepEvent={() => addCircuitDayStepEvent(step.sort)}
												duplicateCircuitDayStepEvent={() => addCircuitDayStepEvent(step.sort, true)}
												deleteEvent={() => deleteStepEvent(step.id)}
												isExpandDefault={isExpandDefault}
											/>
										)}
									</Draggable>
								))}

								{provided.placeholder}
							</Box>
						)}
					</Droppable>
				</DragDropContext>
			</>
		)
	}, [id, steps, addCircuitDayStepEvent, deleteStepEvent, onDragEnd, isExpandDefault])

	return (
		<Box
			ref={props.provided.innerRef}
			{...props.provided.draggableProps}
			mb={1}
		>
			<AddEditFormCard
				title={
					<Box display="flex" alignItems={"center"}>
						<QuoteCircuitDragIcon
							dragHandleProps={provided.dragHandleProps}
							iconColor='primary'
						/>
						<Grid container alignItems={"center"} mx={1}>
							<Grid item xs={12} display={"flex"}>
								<Box mr={2} >
									<TextField type="date" size="small" label="Date" defaultValue={quoteCircuitDay?.date} onBlur={onChangeDate} />
								</Box>
								<QuoteCircuitDayTitle id={id} />
							</Grid>
						</Grid>
					</Box>
				}
				withExpand={true}
				withExpandByTitle={false}
				isExpand={cardIsExpand}
				isExpandRecompileChildren
				action={

					<Box display="flex">
						<Button size="small" variant="contained" color="primary" onClick={() => addCircuitDayEvent()}>+ jour</Button>
						<Box ml={2}>
							<Button size="small" variant="contained" color="primary" onClick={() => duplicateCircuitDayEvent()}><ContentCopyIcon /><Box ml={1}>jour</Box></Button>
						</Box>
						<Box ml={2}>
							<Button size="small" variant="contained" color="success" onClick={() => addCircuitDayStepEvent()}>+ étape</Button>
						</Box>
						<Box ml={2}>
							<Button size="small" color="error" variant="outlined" onClick={() => deleteEvent()}>Supprimer</Button>
						</Box>
					</Box>

				}
				sx={{
					paper: {
						backgroundColor: "ghostwhite"
					}
				}}
			>
				{StepsContent}
			</AddEditFormCard >

		</Box>
	)
}

export default QuoteCircuitDay;


