import { useCallback, useEffect, useMemo, useState } from "react";
import AddEditFormCard from "../../../components/common/form/AddEditFormCard";
import { ReqStatus } from "../../../stores/core/UseApi";
import { SelectOptionsProps } from "../../../components/common/form/SelectField";

import { useDeleteFolderOrderSupplier, useGetFolderOrderSupplierList, useUpdateFolderOrderSupplierStatus, useUpdateFolderOrderSupplierAmount } from "../../stores/FolderOrderSuppliersStore";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { ConfirmModalState } from "../../../states/ModalState";
import { EnumFolderOrderSupplierStatus, FolderOrderSupplierModel } from "../../classes/FolderOrderSupplierModel";
import FolderOrderSupplierItem from "./FolderOrderSupplier";
import { FolderOrderSupplierListState, FolderOrderSupplierSelectionModalState } from "../../states/FolderOrderSuppliersState";
import { Box, Button, Divider, TextField } from "@mui/material";
import FolderOrderSupplierSelection from "./FolderOrderSupplierSelection";
import { Mail2ModalState } from "../../states/MailState";
import FolderOrderSupplierModal from "./FolderOrderSupplierModal";
import { BrevoTemplateEnum } from "../../classes/BrevoMailConfig";
import { IsNotUserCommercialSelector, UserExtenalSelector } from "../../states/UserState";

const FolderOrderSupplierListFilter = (props: { onChange: (search: string) => void }) => {
	const [search, setSearch] = useState("");
	useEffect(() => {
		props.onChange(search);
	}, [search])

	return (
		<TextField size="small" label="Rechercher" type="search" value={search} onChange={(e) => setSearch(e.target.value ?? "")} />
	)
}

const FolderOrderSupplierList = (props: { folderId: string }) => {
	const { folderId } = props;

	const [list, setList] = useRecoilState(FolderOrderSupplierListState);

	const getAll = useGetFolderOrderSupplierList()
	const deleteAjax = useDeleteFolderOrderSupplier();
	const updateStatusAjax = useUpdateFolderOrderSupplierStatus();
	const updateAmountAjax = useUpdateFolderOrderSupplierAmount();
	const setConfirmModalState = useSetRecoilState(ConfirmModalState);
	const setModalState = useSetRecoilState(FolderOrderSupplierSelectionModalState);

	const refresh = useCallback(() => {
		getAll(folderId).then((res) => {
			if (res.status === ReqStatus.SUCCESS && res.data) {
				setList(res.data.folderOrderSuppliers);
			}
		}).catch(() => {

		})
	}, [folderId, getAll, setList]);

	useEffect(() => {
		refresh();

		//cleanUp
		return () => {
			setList([]);
		}
	}, [])

	const deleteEvent = useCallback((id: number) => {
		setConfirmModalState({
			open: true,
			title: "Suppression",
			desc: "Êtes-vous sûr de vouloir supprimer cette demande fournisseur ?",
			validEvent: () => {
				deleteAjax(id).then((res) => {
					if (res.status === ReqStatus.SUCCESS) {
						setList((l) => {
							return l.filter((i) => i.id !== id);
						})
					}
				})
			}
		})
	}, [deleteAjax, setConfirmModalState, setList]);

	const updateStatusEvent = useCallback((id: number, status: EnumFolderOrderSupplierStatus) => {
		updateStatusAjax(id, status).then((res) => {
			if (res.status === ReqStatus.SUCCESS) {
				setList((l) => {
					return l.map((i) => i.id === id ? { ...i, status: status } : i);
				})
			}
		});
	}, [updateStatusAjax, setList])

	const setMailModalState = useSetRecoilState(Mail2ModalState);
	const sendSupplierRequestMailEvent = useCallback((id: number) => {
		const item = list.find((x) => x.id === id);
		const emails = (item?.supplier.email) ? [item.supplier.email] : [];
		setMailModalState({
			open: true,
			emails: emails,
			folderId: +folderId,
			templateIdDefault: BrevoTemplateEnum.BREVO_TPL_22_ENVOI_DEMANDE_FOURNISSEUR,
			entityId: id,
			onValidate: () => {
				setList((l) => {
					return l.map((i) => i.id === id ? {
						...i,
						email_send: true
					} : i);
				})
			}
		});
	}, [setMailModalState, folderId, list, setList]);


	const [search, setSearch] = useState("");

	const listFiltered = useMemo(() => {
		if (search === "") { return list; }
		return list.filter((x =>
			x.supplier.name?.toLowerCase().includes(search.toLowerCase()) ||
			x.supplier.email?.toLowerCase().includes(search.toLowerCase()) ||
			x.order_id?.toString().includes(search.toLowerCase()) ||
			x.supplier.id?.toString().includes(search.toLowerCase()) ||
			x.reference?.toLowerCase().includes(search.toLowerCase()) ||
			x.amount?.toString().toLowerCase().includes(search.toLowerCase())
		));
	}, [list, search])

	const isNotUserCommercial = useRecoilValue(IsNotUserCommercialSelector);
	return (
		<>
			<FolderOrderSupplierModal />
			<FolderOrderSupplierSelection folderId={folderId} />
			<AddEditFormCard title="Demande fournisseur" withExpand>
				{isNotUserCommercial &&
					<Box mb={1} display="flex" justifyContent="space-between">
						<Button variant="contained" color="info" onClick={() => {
							setModalState({
								open: true, onValidate: () => { refresh(); }
							})
						}}>
							Sélectionner
						</Button>
						<FolderOrderSupplierListFilter onChange={(search) => setSearch(search)} />
					</Box>
				}
				{listFiltered.map((item) => (
					<Box key={item.id}>
						<FolderOrderSupplierItem
							folderOrderSupplier={item}
							deleteEvent={deleteEvent}
							updateStatusEvent={updateStatusEvent}
							emailEvent={sendSupplierRequestMailEvent}
						/>
						<Divider />
					</Box>
				))}
			</AddEditFormCard>
		</>
	)
}

export default FolderOrderSupplierList;