import { Box, Button } from "@mui/material";
import { useCallback, useEffect, useMemo } from "react";
import { IAncestorInput, useField } from "react-recoil-form";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { QuoteFormSubmitForcedState, QuoteTravelHasAddedState, QuoteTravelModalState } from "../../states/QuoteState";
import InputField from "../../../components/common/form/InputField";
import NumberField from "../../../components/common/form/NumberField";

const QuoteProductsTravelOrLabelField = (props: { ancestors: IAncestorInput[], disabled?: boolean}) => {

    const { ancestors, disabled } = props;
    const productIdField = useField({ ancestors, name: "_joinData.product_id" });
    const totalTaxIncl = useField({ ancestors, name: "_joinData.total_tax_incl" });
    const travelField = useField({ ancestors, name: "_joinData.order_product_attribute" });
    const referenceField = useField({ name: "reference" });

    const isTravel = useMemo(() => {
        return productIdField.fieldValue === 1;
    }, [productIdField.fieldValue])

    const setModalState = useSetRecoilState(QuoteTravelModalState);
    const setQuoteFormSubmitForced = useSetRecoilState(QuoteFormSubmitForcedState);

    const editTravelEvent = useCallback(() => {
        let travel = JSON.parse(JSON.stringify(travelField.fieldValue ?? {}));
        if (travel) {
            travel.amount_tax_incl = totalTaxIncl.fieldValue;
            travel.reference = referenceField.fieldValue;
        }
        setQuoteFormSubmitForced(false);
        setModalState({
            travel: travel,
            open: true,
            onValidate: (travel: any) => {
                travelField.setFieldValue(travel);
                totalTaxIncl.setFieldValue(travel.amount_tax_incl ?? 0);
                referenceField.setFieldValue(travel.reference ?? "");
                setTimeout(() => {
                    setQuoteFormSubmitForced(true);
                },500);
            }
        })
    }, [travelField, setModalState, totalTaxIncl, referenceField]);

    const [quoteTravelHasAdded, setQuoteTravelHasAdded] = useRecoilState(QuoteTravelHasAddedState);

    useEffect(() => {
        if (isTravel && quoteTravelHasAdded) {
            editTravelEvent();
            setQuoteTravelHasAdded(false);
        }
    }, [editTravelEvent, isTravel, quoteTravelHasAdded, setQuoteTravelHasAdded])

    return (
        <>
            {isTravel && <Button variant="contained" color="info" onClick={() => editTravelEvent()}>{disabled ? "Voir voyage" : "Saisir voyage"}</Button>}
            {!isTravel &&
                <Box display="flex">
                    <InputField
                        ancestors={ancestors}
                        name="_joinData.label"
                        label="Description"
                        type="text"
                        required
                        disabled={disabled}
                        onBlurActive={true}
                    />
                    <Box ml={2} width={100}>
                        <NumberField
                            ancestors={ancestors}
                            name="_joinData.quantity"
                            label="Quantité"
                            required
                            disabled={disabled}
                        />
                    </Box>
                </Box>}

        </>
    )
}

export default QuoteProductsTravelOrLabelField;
