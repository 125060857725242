import { atom, selector, useRecoilState, useSetRecoilState } from "recoil";
import { ListSearchModelCore } from "../classes/list/ListModel";
import { useCallback, useMemo } from "react";

export const ListCoreFilterState = atom<ListSearchModelCore | undefined>({
    key: 'ListCoreFilterState',
    default: undefined
})

interface ListFilterItem {
    id: string,
    filter: ListSearchModelCore | undefined,
    page : number
}
export const ListCoreFiltersState = atom<ListFilterItem[]>({
    key: 'ListFiltersState',
    default: []
})

export const useListCoreFilters = (id: string) => {
    const [list, setList] = useRecoilState(ListCoreFiltersState);

    const search = useMemo(() => {
        return list.find(item => item.id === id)?.filter;
    }, [list]);

    const setSearch = ( callback : (value: ListSearchModelCore | undefined) => ListSearchModelCore | undefined ) => {
        setList((oldList) => {          
            const newList = [...oldList];
            const index = newList.findIndex(item => item.id === id);

            if (index !== -1) {
                // Écraser l'élément existant
                const newValue = callback( JSON.parse(JSON.stringify(newList[index].filter)));
                newList[index] = {
                    ...newList[index],
                    filter: newValue
                }
            } else {
                // Ajouter le nouvel élément si aucun élément correspondant n'a été trouvé
                newList.push({
                    id: id,
                    filter: callback({}),
                    page: 0
                });
            }
            return newList;
        });
    }

    const page = useMemo(() => {
        return list.find(item => item.id === id)?.page;
    }, [list]);

    const setPage = ( page: number ) => {
        setList((oldList) => {        
            const newList = [...oldList];
            const index = newList.findIndex(item => item.id === id);
            if (index !== -1) {
                newList[index] = {
                    ...newList[index],
                    page: page
                }
            } else {
                // Ajouter le nouvel élément si aucun élément correspondant n'a été trouvé
                newList.push({
                    id: id,
                    filter: {},
                    page: page
                });
            }
            return newList;
        });
    }

    return { search, setSearch, page, setPage };
}