import { DataGrid } from "@mui/x-data-grid";
import { useGetUsersWorked } from "../../stores/UserTimetrackersStore";
import { useCallback, useEffect, useState } from "react";
import { ReqStatus } from "../../../stores/core/UseApi";
import { UserWorked } from "../../classes/UserTimetrackerModel";
import { Alert, Button, Chip, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import AddEditFormCard from "../../../components/common/form/AddEditFormCard";
import AvatarIcon from "../../../components/common/icon/AvatarIcon";
import RefreshIcon from '@mui/icons-material/Refresh';

const UsersWorked = () => {

	const getUsersWorked = useGetUsersWorked();

	const [users, setUsers] = useState<UserWorked[]>([]);

	const callAjax = useCallback(() => {
		getUsersWorked.call().then((req) => {
			if (req.status === ReqStatus.SUCCESS && req.data) {
				setUsers(req.data.usersWorked)
			}
		})
	},[])

	useEffect(() => {
		callAjax();
	}, [])

	return (

		<AddEditFormCard title={"Activité des utilisateurs"} maxWidth="md" action={<>
			<IconButton onClick={() => callAjax()}>
				<RefreshIcon color="primary" />
			</IconButton>
		</>}>

			<TableContainer>
				<Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
					<TableHead>
						<TableRow>
							<TableCell>Utilisateur</TableCell>
							<TableCell></TableCell>
							<TableCell></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{users.map((user) => (
							<TableRow
								key={user.id}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								
								<TableCell component="th" scope="row">
									{user.last_name} {user.first_name}
								</TableCell>
								<TableCell component="th" scope="row">
									<AvatarIcon value={`${user.last_name} ${user.first_name}`} />
								</TableCell>
								<TableCell>
									{user.status == "1" ? <Chip color="success" label="Actif" variant="filled"/> : <Chip color="error" label="Inactif" variant="filled" />}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</AddEditFormCard>
	)
}

export default UsersWorked;