import { Alert, Box, IconButton, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import { useSetRecoilState } from "recoil";
import { NavigateState } from "../../states/MenuState";
import { FormattedMessage, useIntl } from "react-intl";
import { useCallback } from "react";
import { datetimeFormat } from "../../utils/DateUtils";
import AddEditFormCard from "../common/form/AddEditFormCard";

const CustomerOrdersView = (props: { orders: any[] }) => {

    const { orders } = props;
    const setNavigateState = useSetRecoilState(NavigateState);
    const intl = useIntl();
    const columns = useCallback(() => {
        return [
            {
                field: 'id',
                headerName: "N°",
                editable: false,
                filterable: false,
                minWidth: 100,
                flex: 1,
                valueGetter: ({ row }) => `${intl.formatMessage({ id: "Label_Order_Number" })}${row.id}`
            },
            {
                field: 'reference',
                headerName: "Référence",
                editable: false,
                filterable: false,
                minWidth: 100,
                flex: 1,
            },
            {
                field: 'created',
                headerName: "Date",
                editable: false,
                filterable: false,
                minWidth: 100,
                flex: 1,
                valueGetter: ({ row }) => datetimeFormat(row.created)
            },
            {
                field: 'total_minus_discount_tax_excl_euro',
                headerName: "Prix HT",
                editable: false,
                filterable: false,
                minWidth: 60,
                flex: 1,
                valueGetter: ({ row }) => `${row.total_minus_discount_tax_excl} €`
            },
            {
                field: 'total_minus_discount_tax_incl_euro',
                headerName: "Prix TTC",
                editable: false,
                filterable: false,
                minWidth: 60,
                flex: 1,
                valueGetter: ({ row }) => `${row.total_minus_discount_tax_incl} €`
            },
            {
                field: 'order_status_label',
                headerName: intl.formatMessage({ id: "Label_Order_Status" }),
                editable: false,
                filterable: false,
                minWidth: 60,
                flex: 1,
                valueGetter: ({ row }) => `${row.order_status.label}`
            },
            {
                field: 'actions',
                headerName: "",
                sortable: false,
                minWidth: 50,
                flex: 1,
                renderCell: ({ row }) => {
                    return (<>
                        <Box display="flex" justifyContent={"flex-start"} sx={{ width: "100%" }}>
                            <IconButton color="primary" onClick={(e) => { setNavigateState(`/commercial/orders/add-edit/${row.id}`); }}><EditIcon /></IconButton>
                        </Box>
                    </>
                    );
                }
            }
        ] as GridColDef[];
    }, [setNavigateState, intl])

    return (
        <>
            {(!orders || orders.length === 0) && <Alert severity="info"><FormattedMessage id="Label_Order_NoResults" /></Alert>}
            {orders.length > 0 &&
                <Box display={"flex"} flexDirection={"column"}>
                    <DataGrid
                        disableColumnFilter
                        disableRowSelectionOnClick
                        filterMode="client"
                        rows={orders}
                        columns={columns()}
                        getRowId={(row) => row.id}
                    />
                </Box >
            }
        </>
    )
}

export default CustomerOrdersView;