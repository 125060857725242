import { Box, Button, Card, Grid } from "@mui/material";
import AddEditFormCard from "../../../components/common/form/AddEditFormCard";
import { AutocompleteOption } from "../../../components/common/form/AutocompleteField";
import RadioField from "../../../components/common/form/RadioField";
import SelectField, { SelectOptionsProps } from "../../../components/common/form/SelectField";
import InputField from "../../../components/common/form/InputField";
import DateField from "../../../components/common/form/DateField";
import TextAreaField from "../../../components/common/form/TextAreaField";
import AutocompleteMultipleField from "../common/AutocompleteMultipleField";
import { isValidEmail, isValidPhone } from "../../../utils/FormUtils";
import CustomerAddressAdd from "../../../components/customer/CustomerAddressAdd";
import { useCallback, useState } from "react";
import { ConfirmModalState } from "../../../states/ModalState";
import { useSetRecoilState } from "recoil";


interface CustomerCustomInfosViewProps {
    id: string | undefined,
    genderOptions: SelectOptionsProps[],
    customerTypesOptions: SelectOptionsProps[],
    countryOptions: AutocompleteOption[]
}
const CustomerCustomInfosView = (props: CustomerCustomInfosViewProps) => {
    const { id, genderOptions, customerTypesOptions, countryOptions } = props;

    const [addressVisible, setAddressVisible] = useState(false);
    const setConfirmModalState = useSetRecoilState(ConfirmModalState);

    const addressButtonEvent = useCallback(() => {
        if (addressVisible) {
            setConfirmModalState({
                open: true,
                title: "Adresse principale",
                desc: "Etes vous sûr de vouloir cacher l'adresse ? Vous perdrez les données saisies.",
                validEvent: () => {
                    setAddressVisible(false);
                }
            })
        } else {
            setAddressVisible(true);
        }

    }, [addressVisible, setConfirmModalState]);

    return (
        <>
            <AddEditFormCard title={`${(!id ? "Ajouter " : "Modifier")} un client`}>
                <Grid container spacing={3}>

                    <Grid item xs={12} sm={6}>
                        <InputField
                            name="user.last_name"
                            label="Nom"
                            required
                            type="text"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputField
                            name="user.first_name"
                            label="Prénom"
                            required
                            type="text"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <InputField
                            name="user.email"
                            label="Email principal"
                            required
                            type="text"
                            isEmail
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <AutocompleteMultipleField
                            name="dahan_emails_compl"
                            options={[]}
                            label="Emails supplémentaires"
                            validateOption={isValidEmail}
                        />
                    </Grid>


                    <Grid item xs={12} sm={6}>
                        <InputField
                            name="user.phone"
                            label="Téléphone principal"
                            type="text"
                        />
                    </Grid>


                    <Grid item xs={12} sm={6}>
                        <AutocompleteMultipleField
                            name="dahan_phones_compl"
                            options={[]}
                            label="Téléphones supplémentaires"
                        //validateOption={isValidPhone}
                        />
                    </Grid>



                    <Grid item xs={12} sm={6}>
                        <SelectField
                            name="customer_type_id"
                            label="Type de client"
                            required
                            options={customerTypesOptions}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <DateField
                            name="birthday"
                            label="Date de naissance"
                            type="date"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <InputField
                            name="dahan_company"
                            label="Société"
                            type="text"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextAreaField
                            name="comment"
                            label="Commentaire"
                        />
                    </Grid>

                    {id &&
                        <Grid item xs={12} sm={6}>
                            <TextAreaField
                                name="dahan_auxiliaire"
                                label="Compte auxiliaire"
                                disabled={true}
                            />
                        </Grid>
                    }

                </Grid>
            </AddEditFormCard >

            {!id &&
                <Grid item xs={12} lg={6}>
                    <Box ml={2}>
                        <Button variant="contained" color="info" onClick={() => addressButtonEvent()}>{!addressVisible ? "Définir l'adresse principale" : "Ne pas définir l'adresse principale"}</Button>
                    </Box>
                    {addressVisible &&
                        <Box m={2}>
                            <Card>
                                <Box p={2}>
                                    <CustomerAddressAdd countryOptions={countryOptions} />
                                </Box>
                            </Card>
                        </Box>
                    }
                </Grid>
            }
        </>
    )
}

export default CustomerCustomInfosView;