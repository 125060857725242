import { useCallback } from "react";
import { useApi } from "../../stores/core/UseApi";
import { FolderTag, Tag } from "../classes/FolderTagModel";
import { useAddEditCore } from "../../stores/core/StoreCore";
import { addUserOnEntityType } from "../states/FoldersState";


const routeController = 'project-dahan/dahan/folders';

interface GetCounterOfUserResponse {
    count_folders_not_attributed: number,
    count_folders: number,
    count_orders: number,
}
export const useGetCounterOfUser = () => {
    const [callCore] = useApi<GetCounterOfUserResponse, undefined>(
        {
            route: `${routeController}/get-counter-of-user`,
            instanceAxiosMethod: "GET"
        }
    );
    const call = useCallback(() => {
        return callCore();
    }, [callCore]);
    return call;
};

interface GetQuoteListResponse {
    quotes: any[]
}
export const useGetQuoteList = () => {
    const [callCore] = useApi<GetQuoteListResponse, undefined>(
        {
            route: `${routeController}/get-quote-list`,
            instanceAxiosMethod: "GET"
        }
    );
    const call = useCallback((id?: string) => {
        return callCore(undefined, `/${id ?? ""}`);
    }, [callCore]);
    return call;
};

interface GetBillListResponse {
    bills: any[],
    payments: any[]
}
export const useGetBillList = () => {
    const [callCore] = useApi<GetBillListResponse, undefined>(
        {
            route: `${routeController}/get-bill-list`,
            instanceAxiosMethod: "GET"
        }
    );
    const call = useCallback((id?: string) => {
        return callCore(undefined, `/${id ?? ""}`);
    }, [callCore]);
    return call;
};

interface GetReminderListResponse {
    reminders: any[]
}
export const useGetReminderList = () => {
    const [callCore] = useApi<GetReminderListResponse, undefined>(
        {
            route: `${routeController}/get-reminder-list`,
            instanceAxiosMethod: "GET"
        }
    );
    const call = useCallback((id?: string) => {
        return callCore(undefined, `/${id ?? ""}`);
    }, [callCore]);
    return call;
};

interface GetChecktaskListResponse {
    checktasks: any[],
    folder_checktasks: any[]
}
export const useGetChecktaskList = () => {
    const [callCore] = useApi<GetChecktaskListResponse, undefined>(
        {
            route: `${routeController}/get-checktask-list`,
            instanceAxiosMethod: "GET"
        }
    );
    const call = useCallback((id?: string) => {
        return callCore(undefined, `/${id ?? ""}`);
    }, [callCore]);
    return call;
};

interface GetNoteListResponse {
    notes: any[]
}
export const useGetNoteList = () => {
    const [callCore] = useApi<GetNoteListResponse, undefined>(
        {
            route: `${routeController}/get-note-list`,
            instanceAxiosMethod: "GET"
        }
    );
    const call = useCallback((id?: string) => {
        return callCore(undefined, `/${id ?? ""}`);
    }, [callCore]);
    return call;
};

export const useGetProformaList = () => {
    const [callCore] = useApi<GetQuoteListResponse, undefined>(
        {
            route: `${routeController}/get-proforma-list`,
            instanceAxiosMethod: "GET"
        }
    );
    const call = useCallback((id?: string) => {
        return callCore(undefined, `/${id ?? ""}`);
    }, [callCore]);
    return call;
};

export const useGetMargins = () => {
    const [callCore] = useApi<GetQuoteListResponse, undefined>(
        {
            route: `${routeController}/get-margins`,
            instanceAxiosMethod: "GET"
        }
    );
    const call = useCallback((id?: string) => {
        return callCore(undefined, `/${id ?? ""}`);
    }, [callCore]);
    return call;
};

export const useGetTravelInformationList = () => {
    const [callCore] = useApi<GetQuoteListResponse, undefined>(
        {
            route: `${routeController}/get-travel-infos-list`,
            instanceAxiosMethod: "GET"
        }
    );
    const call = useCallback((id?: string) => {
        return callCore(undefined, `/${id ?? ""}`);
    }, [callCore]);
    return call;
};

interface GetPaymentListResponse {
    payments: any[]
}
export const useGetPaymentList = () => {
    const [callCore] = useApi<GetPaymentListResponse, undefined>(
        {
            route: `${routeController}/get-payment-list`,
            instanceAxiosMethod: "GET"
        }
    );
    const call = useCallback((id?: string) => {
        return callCore(undefined, `/${id ?? ""}`);
    }, [callCore]);
    return call;
};

interface GetFolderByCustomerIdResponse {
    folders: any[],
    folderStatus: any[]
}
export const useGetFoldersByCustomerId = () => {
    const [callCore] = useApi<GetFolderByCustomerIdResponse, undefined>(
        {
            route: `${routeController}/get-folders-by-customer-id`,
            instanceAxiosMethod: "GET"
        }
    );
    const call = useCallback((id: number) => {
        return callCore(undefined, `/${id}`);
    }, [callCore]);
    return call;
};



export const useUpdateFolderStatus = () => {
    const [callCore] = useApi<undefined, { statusId: number, is_closure: boolean, closure_pattern: string }>(
        {
            route: `${routeController}/update-folder-status`,
            instanceAxiosMethod: "POST"
        }
    );
    const call = useCallback((id: number, statusId: number, is_closure?: boolean, closure_pattern?: string) => {
        return callCore({ 
            statusId: statusId,
            is_closure: is_closure ?? false,
            closure_pattern: closure_pattern ?? ""
         }, `/${id}`);
    }, [callCore]);
    return call;
};

interface GetTagListResponse {
    folderTags: FolderTag[],
    tags: Tag[]
}
export const useGetTagList = () => {
    const [callCore] = useApi<GetTagListResponse, undefined>(
        {
            route: `${routeController}/get-tag-list`,
            instanceAxiosMethod: "GET"
        }
    );
    const call = useCallback((id?: string) => {
        return callCore(undefined, `/${id ?? ""}`);
    }, [callCore]);
    return call;
};

export const useAdduserOnEntity = (type: addUserOnEntityType) => {
    let route = "";
    if (type === addUserOnEntityType.COMMERCIAL_FOLDER) {
        route = "add-commercial";
    }
    else if (type === addUserOnEntityType.COMMERCIAL_QUOTE) {
        route = "add-commercial-on-quote";
    }
    else {
        route = "add-gestionnaire-on-quote";
    }
    return useAddEditCore<any, any>(`${routeController}/${route}`);
};

export const useCommentFolder = () => {
    return useAddEditCore<undefined, undefined>(`${routeController}/comment`);
};


interface GetFolderHistoryItemsResponse {
    folderHistoryItems: any[]
}
export const useGetFolderHistoryItems = () => {
    const [callCore] = useApi<GetFolderHistoryItemsResponse, undefined>(
        {
            route: `${routeController}/get-folder-history-items`,
            instanceAxiosMethod: "GET"
        }
    );
    const call = useCallback((id: number) => {
        return callCore(undefined, `/${id}`);
    }, [callCore]);
    return call;
};