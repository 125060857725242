import { Box, Card, Grid } from "@mui/material";
import AddEditFormCard from "../common/form/AddEditFormCard";
import InputField from "../common/form/InputField";
import TextAreaField from "../common/form/TextAreaField";
import AutocompleteField, { AutocompleteOption } from "../common/form/AutocompleteField";

const CustomerAddressAdd = (props: { countryOptions: AutocompleteOption[] }) => {

	return (

		<Grid container spacing={1} >
			<Grid item xs={12}>
				<InputField
					name="addresses.0.label"
					label="Label"
					type="text"
				/>
			</Grid>
			<Grid item xs={12}>
				<TextAreaField
					name="addresses.0.address_1"
					label="Adresse"
					required
				/>
			</Grid>

			<Grid item xs={12}>
				<InputField
					name="addresses.0.address_2"
					label="Complément d'adresse"
					type="text"
				/>
			</Grid>

			<Grid item xs={12} display={"flex"}>
				<InputField
					name="addresses.0.postcode"
					label="Code postal"
					required
					type="text"
				/>
				<InputField
					name="addresses.0.city"
					label="Ville"
					required
					type="text"
				/>
			</Grid>

			<Grid item xs={12} >
				<AutocompleteField
					name="addresses.0.country_id"
					label="Pays"
					required
					options={props.countryOptions}
				/>
			</Grid>
		</Grid>
	)
};

export default CustomerAddressAdd;