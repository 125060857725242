import React, { useCallback, useMemo } from "react";
import { Box, Divider } from "@mui/material";
import { useTheme } from '@mui/material';

import AddEditFormSubmit from "./AddEditFormSubmit";

interface AddEditFormProps {
  actions?: "bottom" | "top";
  children: React.ReactNode;
  formId: string,
  formHandleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => any,
  formHandleCancel: () => void,
  isDirty: boolean,
  returnAction?: {
    disabled?: boolean,
    event?: () => void,
    title?: string
  },
  enterKeyDisabled? :boolean,
}

const AddEditForm = (props: AddEditFormProps) => {

  const { actions, children, formId, formHandleSubmit, formHandleCancel, isDirty, returnAction, enterKeyDisabled } = props;

  const _actions = useMemo(() => {
    return actions ?? "top";
  }, [actions]);

  const marginValue = useMemo(() => {
    return _actions === "bottom" ? "0 0 0 0" : "0 0 0 0";
  }, [_actions]);

  const flexDirection = useMemo(() => {
    return _actions === "bottom" ? "column" : "column-reverse";
  }, [_actions]);

  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLFormElement>) => {
    if ( enterKeyDisabled && e.key === 'Enter') {
      e.preventDefault(); 
    }
  },[enterKeyDisabled]);

  //const isWeb = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <form id={formId} onSubmit={formHandleSubmit} style={{ height: "100%" }}  onKeyDown={handleKeyDown}>
      <Box
        display="flex"
        flexDirection={flexDirection}
        justifyContent="flex-end"
        height="100%"
      >
        <Box style={{ overflowY: "auto", margin: marginValue, height: "100%" }}>
          {children}
        </Box>
        <Divider />
        <Box display="flex" justifyContent="flex-end" alignItems="flex-end" overflow="visible" >
          <AddEditFormSubmit
            returnAction={returnAction}
            cancelEvent={formHandleCancel}
            disabled={!isDirty}
          />
        </Box>
      </Box>
    </form>
  );
};

export default AddEditForm;
