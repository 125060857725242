import {
  FormControl,
  FormHelperText,
  TextField,
} from "@mui/material";
import { IAncestorInput, useField } from "react-recoil-form";


interface IFieldProps {
  name: string;
  ancestors?: IAncestorInput[];
  label?: string;
  required?: boolean;
  disabled?: boolean;
  minRows? : number,
  maxRows? : number,
  size? : 'small' | 'medium'
}

export default function TextAreaField(props: IFieldProps) {
  const { name, ancestors, label, required, disabled, minRows, maxRows, size } = props;
  const { fieldValue, setFieldValue, error } = useField({
    ancestors,
    name,
    validate: (value) => {
      if (required && !value) {
        return `Le champ ${label} est requis`;
      }
    }
  });

  return (
    <FormControl error={!!error} fullWidth >
      <TextField
        id={name}
        value={fieldValue || ""}
        onChange={(e) => {
          setFieldValue(e.target.value ? e.target.value : '');
        }}
        size={size}
        disabled={disabled ?? false}
        label={label}
        required={required}
        variant={"outlined"}
        multiline
        fullWidth
        minRows={minRows ? minRows : 1}
        maxRows={maxRows ? maxRows : 3}
      />
      {error && <FormHelperText id="my-helper-text">{error}</FormHelperText>}
    </FormControl>
  );
}
