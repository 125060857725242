import { useRecoilValue } from "recoil";
import { CurrentUserState } from "../../../states/AuthState";
import AutocompleteField, { AutocompleteOption } from "../../../components/common/form/AutocompleteField";
import { useMemo } from "react";
import { BrevoMailConfigsState } from "../../states/MailState";
import { BrevoMailConfigVisibility } from "../../classes/BrevoMailConfig";
import { Box } from "@mui/material";

interface SendMailFormTemplateProps {
	templateIdDefault: number | undefined,
}

const SendMailFormTemplate = (props: SendMailFormTemplateProps) => {

	const { templateIdDefault } = props;
	const currentUser = useRecoilValue(CurrentUserState);
	const templates = useRecoilValue(BrevoMailConfigsState);

	const templatesOptions: AutocompleteOption[] = useMemo(() => {
		if (!currentUser) { return []; }

		let templatesVisible = templates;
		if (!templateIdDefault) {



			templatesVisible = templates.filter(x =>
				//x.entity > 0 &&
				x.is_active &&
				(
					(x.visibility_by_role === BrevoMailConfigVisibility.ALL) ||
					// 1 - SuperAdmin / 2 - Admin / 3 - Commercial
					(x.visibility_by_role === BrevoMailConfigVisibility.COMMERCIAL && [1, 2, 3].includes(currentUser.user_group_id)) ||
					// 1 - SuperAdmin / 2 - Admin / 5 - Gestionnaire
					(x.visibility_by_role === BrevoMailConfigVisibility.GESTIONNAIRE && [1, 2, 5].includes(currentUser.user_group_id)) ||
					// 1 - SuperAdmin / 2 - Admin 
					(x.visibility_by_role === BrevoMailConfigVisibility.ADMIN && [1, 2].includes(currentUser.user_group_id))
				)
			);
		}

		return templatesVisible
			.map((tpl) => ({ id: tpl.id, label: tpl.name_internal ? tpl.name_internal : tpl.name }))
			.sort((a, b) => {
				if (a.label < b.label) { return -1;	}
				if (a.label > b.label) { return 1; }
				return 0;
			});
	}, [templates, currentUser])


	return (
		<Box mt={2}>
			<AutocompleteField
				name="template_id"
				label={"Template email"}
				options={templatesOptions}
				required
				disabled={!!templateIdDefault}
			/>
		</Box>
	)
}

export default SendMailFormTemplate;