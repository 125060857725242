
import { Button, IconButton } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { AddUserOnEntityModalState, addUserOnEntityType } from "../../states/FoldersState";
import { CurrentUserState } from "../../../states/AuthState";
import GroupAddIcon from '@mui/icons-material/GroupAdd';

interface AddUserOnEntityButtonProps {
	ids: number[],
	onValidate: () => void,
	disabled?: boolean,
	type: addUserOnEntityType
}

const AddUserOnEntityButton = (props: AddUserOnEntityButtonProps) => {

	const { ids, onValidate, disabled, type } = props;

	const setModalState = useSetRecoilState(AddUserOnEntityModalState);

	const onClickEvent = useCallback(() => {
		setModalState({
			ids: ids,
			onValidate: onValidate,
			open: true,
			type: type
		})
	}, [setModalState, onValidate, type, ids]);

	return (
		<>
			{type === addUserOnEntityType.COMMERCIAL_QUOTE ?
				<IconButton
					disabled={disabled}
					onClick={() => onClickEvent()}>
					<GroupAddIcon />
				</IconButton>
				:
				<Button disabled={disabled} variant="contained" color="info" onClick={() => onClickEvent()}>Affecter</Button>
			}
		</>
	)
}

export default AddUserOnEntityButton;