import { Box, Grid } from "@mui/material";
import AddEditFormCard from "../common/form/AddEditFormCard";
import RadioField from "../common/form/RadioField";
import InputField from "../common/form/InputField";
import SelectField, { SelectOptionsProps } from "../common/form/SelectField";
import TextAreaField from "../common/form/TextAreaField";
import AutocompleteField, { AutocompleteOption } from "../common/form/AutocompleteField";
import DateField from "../common/form/DateField";
import CustomerAddressAdd from "./CustomerAddressAdd";

interface CustomerInfosViewProps {
    id: string | undefined,
    genderOptions: SelectOptionsProps[],
    customerTypesOptions: SelectOptionsProps[],
    userGroupsOptions: SelectOptionsProps[],
    countryOptions: AutocompleteOption[],

}
const CustomerInfosView = (props: CustomerInfosViewProps) => {
    const { id, genderOptions, customerTypesOptions, userGroupsOptions, countryOptions } = props;
    return (
        <>
            <AddEditFormCard title={`${(!id ? "Ajouter " : "Modifier")} un client`}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <RadioField
                            name="gender_id"
                            label="Genre"
                            required
                            disabled={!!id}
                            options={genderOptions} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputField
                            name="user.email"
                            label="Email"
                            required
                            type="text"
                            isEmail
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputField
                            name="user.last_name"
                            label="Nom"
                            required
                            type="text"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputField
                            name="user.first_name"
                            label="Prénom"
                            required
                            type="text"
                        />
                    </Grid>


                    <Grid item xs={12} sm={6}>
                        <InputField
                            name="user.phone"
                            label="Téléphone"
                            type="text"
                            isPhone
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <DateField
                            name="birthday"
                            label="Date de naissance"
                            type="date"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <SelectField
                            name="customer_type_id"
                            label="Type de client"
                            required
                            options={customerTypesOptions}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <SelectField
                            name="user.user_group_id"
                            label="Rôle utilisateur"
                            required
                            options={userGroupsOptions}
                        />
                    </Grid>
                </Grid>
            </AddEditFormCard >
            {!id &&
                <AddEditFormCard title={"Adresse principale"} >
                    <CustomerAddressAdd countryOptions={countryOptions} />
                </AddEditFormCard>
            }
        </>
    )
}

export default CustomerInfosView;