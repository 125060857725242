import { Container, Modal } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useRecoilState } from "recoil";
import { AddUserOnEntityModalState } from "../../states/FoldersState";
import { FormProvider } from "react-recoil-form";
import AddUserOnEntityForm from "./AddUserOnEntityForm";

const AddUserOnEntityModal = () => {

	const [modalState, setModalState] = useRecoilState(AddUserOnEntityModalState);

	const handleClose = useCallback(() => {
		setModalState((a) => {
			return { ...a, open: false };
		});
	}, [setModalState]);


	const handleValidate = useCallback(() => {
		modalState.onValidate();
		setModalState((a) => {
			return { ...a, open: false };
		});
	}, [modalState, setModalState]);

	return (
		<Modal
			open={modalState.open}
			aria-labelledby="add-user-on-entity-modal"
			sx={{
				overflow: 'auto'
			}}
		>
			<Container component="main" maxWidth="md" sx={{ height: "100%", py: 2 }}>
				<FormProvider options={{ formId: "AddUserOnEntityForm", skipRecoilRoot: true }}>
					{modalState.type !== undefined && 
					
					<AddUserOnEntityForm
						ids={modalState.ids}
						type={modalState.type}
						onClose={handleClose}
						onValidate={handleValidate}
					/>
					}
				</FormProvider>
			</Container>
		</Modal>
	);
}

export default AddUserOnEntityModal;