
import { GridColDef } from "@mui/x-data-grid";
import { ListModelCore } from "../../../classes/list/ListModel";
import { useGetListCore } from "../../../stores/core/StoreCore";
import ListCore from "../../../components/common/list/ListCore";
import { useCallback, useEffect, useMemo, useState } from "react";

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { EnumFolderOrderSupplierStatus, getColorOfFolderOrderSupplierStatus } from "../../classes/FolderOrderSupplierModel";
import { datetimeFormat } from "../../../utils/DateUtils";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useNavigate } from "react-router-dom";
import { folderStatusList, getLabelOfStatusByFolder } from "../folders/FolderStepper";
import moment from "moment";
import { CreateColumnDate } from "../../../components/common/list/column/ColumnDate";
import FormattedAmount from "../../../components/common/formatted/FormattedAmount";
import { IsNotUserCommercialSelector, UserExtenalSelector } from "../../states/UserState";
import { useRecoilValue } from "recoil";
import { EnumQuoteStatus, getLastMarginOfQuote } from "../../classes/QuoteModel";

const DashboardCard = (props: any) => {
    const { price, title, applyColorStyling } = props;
    const textColor = applyColorStyling && price > 0 ? 'green' : (applyColorStyling ? 'red' : 'black');
    return (
        <Grid item xs>
            <Card variant="outlined">
                <Box display={"flex"} justifyContent="center" mt={2}>
                    <CardHeader title={<FormattedAmount amount={price} symbol="€" />} style={{ padding: '0', color: textColor }} />
                </Box>
                <Box display={"flex"} justifyContent="center" mb={2}>
                    <Typography>{title}</Typography>
                </Box>
            </Card>
        </Grid>
    );
}

const DashboardRecap = (props: { quotationsStats: any[] }) => {
    const { quotationsStats } = props;

    const salesAmount = useMemo(() => {
        let _salesAmount = 0;
        if (!Array.isArray(quotationsStats)) {
            return _salesAmount;
        }
        quotationsStats.forEach((q) => {
            _salesAmount += +q.total_minus_discount_tax_incl;
        })

        return _salesAmount;
    }, [quotationsStats]);

    const marginTotalDetails = useMemo(() => {

        let marginBrutTotal = 0;
        let marginNetTotal = 0;
        let marginDiffTotal = 0;

        quotationsStats.forEach((q) => {
            const lastMargin = getLastMarginOfQuote(q);
            if (lastMargin) {
                if(lastMargin.already_com) { return; }
                marginBrutTotal += lastMargin.margin_brut ?? 0;
                marginNetTotal += lastMargin.margin_net ?? 0;
                marginDiffTotal += lastMargin.margin_net_diff ?? 0;
            }
        });

        return {
            brut: marginBrutTotal,
            net: marginNetTotal,
            diff: marginDiffTotal
        }
    }, [quotationsStats])

    const purchaseAmount = useMemo(() => {
        let _purchaseAmount = 0;
        if (!Array.isArray(quotationsStats)) {
            return _purchaseAmount;
        }
        quotationsStats.forEach((q) => {
            if (Array.isArray(q.order_attribute.folder_order_suppliers)) {
                q.order_attribute.folder_order_suppliers.forEach((fos: any) => {
                    if (fos.status === EnumFolderOrderSupplierStatus.CONFIRMED) {
                        _purchaseAmount += +fos.amount;
                    }
                })
            }
        })
        return _purchaseAmount;
    }, [quotationsStats]);

    const paymentAmount = useMemo(() => {
        let _paymentsAmount = 0;
        if (!Array.isArray(quotationsStats)) {
            return _paymentsAmount;
        }
        quotationsStats.forEach((q) => {
            if (Array.isArray(q.payments)) {
                q.payments.forEach((payment: any) => {
                    _paymentsAmount += payment.total_amount;
                });
            }
        });

        return _paymentsAmount;
    }, [quotationsStats]);

    const cashToGetBack = useMemo(() => {
        return salesAmount - paymentAmount;
    }, [salesAmount, paymentAmount])

    const isExternal = useRecoilValue(UserExtenalSelector);

    return (
        <Box m={2}>
            <Grid container spacing={2}>
                <DashboardCard price={salesAmount} title="Ventes" applyColorStyling={true} />
                {!isExternal &&
                    <>
                        <DashboardCard price={purchaseAmount} title="Achats" applyColorStyling={true} />
                        <DashboardCard price={marginTotalDetails.brut} title="Marge HT BRUT" applyColorStyling={true} />
                        <DashboardCard price={marginTotalDetails.net} title="Marge HT NET" applyColorStyling={true} />
                        <DashboardCard price={marginTotalDetails.diff} title="Marge HT NET DIFF" applyColorStyling={true} />
                    </>
                }
                <DashboardCard price={cashToGetBack} title="A encaisser" applyColorStyling={true} />
            </Grid>
        </Box >
    )
}

const useListcolumns = () => {

    const navigate = useNavigate();
    const isNotUserCommercial = useRecoilValue(IsNotUserCommercialSelector);
    const isExternal = useRecoilValue(UserExtenalSelector);

    const columns: GridColDef[] = [];

    const getQuoteMarginColor = useCallback((quote : any) => {

        if (quote.order_status.unique_key === EnumQuoteStatus.CANCELED) {
            return "lightpink";
        }

        const lastMargin = getLastMarginOfQuote(quote);
        
        if( lastMargin){

            if(lastMargin.already_com){
                return "mediumpurple";
            }

            if( lastMargin.margin_net != 0 ){
                return "lightblue";
            }
            if(lastMargin.margin_brut != 0 ){
                return "lightgreen";
            }
        }

        return "lightgray";
    },[]);

    columns.push({
        field: 'id',
        headerName: "Devis",
        editable: false,
        filterable: false,
        width: 120,
        renderCell: ({ row }) => (
            <Box px={1} sx={{ backgroundColor: getQuoteMarginColor(row) }}>
                <Box>Dossier #{row.order_attribute?.folder_id}</Box>
                <Box>Devis #{row.id}</Box>
            </Box>
        )
    });

    columns.push({
        field: 'order_attribute.folder.status',
        headerName: "Statut dossier",
        editable: false,
        filterable: false,
        width: 150,
        renderCell: ({ row }) => (
            <Box>
                <Box width={150} display="flex" flexWrap="wrap">
                    <p style={{ flex: 1, overflowWrap: 'break-word', whiteSpace: 'normal' }}>
                        {getLabelOfStatusByFolder(row.order_attribute.folder)}
                    </p>

                </Box>
            </Box>
        )
    });

    columns.push({
        field: 'customer',
        headerName: "Client",
        editable: false,
        filterable: false,
        width: 140,
        valueGetter: ({ row }) => `${row.customer.user.first_name} ${row.customer.user.last_name}`
    });

    columns.push({
        field: 'total_minus_discount_tax_incl',
        headerName: "PV/PA",
        editable: false,
        filterable: false,
        width: 160,
        renderCell: ({ row }) => {

            let order_pa = 0;
            if (Array.isArray(row.order_attribute.folder_order_suppliers)) {
                row.order_attribute.folder_order_suppliers.forEach((fos: any) => {
                    if (fos.status === EnumFolderOrderSupplierStatus.CONFIRMED) {
                        order_pa += +fos.amount;
                    }
                })
            }

            let daysDifference = 0;
            let order_acc = 0;
            if (Array.isArray(row.travels) && row.travels.length > 0 && row.travels[0].order_product_attribute && row.travels[0].order_product_attribute.departure_date) {
                const departure_date = moment(row.travels[0].order_product_attribute.departure_date);
                const date_now = moment();
                daysDifference = departure_date.diff(date_now, 'days');

                if (daysDifference > 50) {
                    order_acc = (row.total_minus_discount_tax_incl * 30 / 100);
                } else {
                    order_acc = row.total_minus_discount_tax_incl;
                }
            } else {
                order_acc = row.total_minus_discount_tax_incl;
            }

            return (

                <Box width={"100%"} >
                    <Box display={"flex"} justifyContent={"space-between"}>
                        <Box>PV : </Box>
                        <FormattedAmount amount={row.total_minus_discount_tax_incl} symbol="€" />
                    </Box>
                    {!isExternal &&
                        <Box display={"flex"} justifyContent={"space-between"}>
                            <Box>PA : </Box>
                            <FormattedAmount amount={order_pa} symbol="€" />
                        </Box>
                    }
                    <Box display={"flex"} justifyContent={"space-between"}>
                        <Box>AC : </Box>
                        <FormattedAmount amount={order_acc} symbol="€" />
                    </Box>
                </Box>
            );
        }
    });

    if (!isExternal) {
        columns.push({
            field: 'marge',
            headerName: "Marge HT",
            editable: false,
            filterable: false,
            width: 140,
            renderCell: ({ row }) => {
                const lastMargin = getLastMarginOfQuote(row);
                return (
                    <>
                        {lastMargin &&
                            <Box width={"100%"} >
                                <Box display={"flex"} justifyContent={"space-between"}>
                                    <Box>Brut : </Box>
                                    <FormattedAmount amount={lastMargin.already_com ? 0 : lastMargin.margin_brut} symbol="€" />
                                </Box>
                                <Box display={"flex"} justifyContent={"space-between"}>
                                    <Box>Net : </Box>
                                    <FormattedAmount amount={lastMargin.already_com ? 0 : lastMargin.margin_net} symbol="€" />
                                </Box>
                                <Box display={"flex"} justifyContent={"space-between"}>
                                    <Box>Diff : </Box>
                                    <FormattedAmount amount={lastMargin.margin_net_diff} symbol="€" />
                                </Box>
                            </Box>
                        }
                    </>
                );
            }
        });
    }

    columns.push({
        field: 'payments',
        headerName: "A encaisser",
        editable: false,
        filterable: false,
        width: 120,
        renderCell: ({ row }) => {

            let payments_amount = 0;
            if (Array.isArray(row.payments)) {
                row.payments.forEach((payment: any) => {
                    payments_amount += payment.total_amount;
                })
            }

            return (
                <Box width={"100%"}>
                    <Box display={"flex"} justifyContent={"flex-end"}><FormattedAmount amount={(row.total_minus_discount_tax_incl - payments_amount)} symbol="€" /></Box>
                </Box>
            );
        }
    });

    columns.push({
        field: 'supplier',
        headerName: "Fournisseur",
        editable: false,
        filterable: false,
        width: 200,
        renderCell: ({ row }) => (
            <Box height={70} width={200} overflow={"auto"} >
                {row.order_attribute.folder_order_suppliers?.map((fos: any) => (
                    <>
                        {(fos.status === EnumFolderOrderSupplierStatus.CONFIRMED ||
                            fos.status === EnumFolderOrderSupplierStatus.VALIDATED ||
                            fos.status === EnumFolderOrderSupplierStatus.CANCELED
                        ) &&
                            <Box width={160} overflow={"hidden"} textOverflow={"ellipsis"} my={0.1} px={0.5} style={getColorOfFolderOrderSupplierStatus(fos.status)}>
                                {isNotUserCommercial ? fos.supplier.name : `#${fos.supplier.id}`}
                            </Box>
                        }
                    </>
                ))}
            </Box>
        )
    });

    columns.push(CreateColumnDate({
        field: 'travels.order_product_attribute.departure_date',
        headerName: "Départ le",
        type: "dateTime",
        getValue: (row) => {
            if (Array.isArray(row.travels) && row.travels.length > 0 && row.travels[0].order_product_attribute && row.travels[0].order_product_attribute.departure_date) {
                return row.travels[0].order_product_attribute.departure_date;
            }
            return null;
        }
    }));

    columns.push(CreateColumnDate({
        field: 'travels.order_product_attribute.return_date',
        headerName: "Retour le",
        type: "dateTime",
        getValue: (row) => {
            if (Array.isArray(row.travels) && row.travels.length > 0 && row.travels[0].order_product_attribute && row.travels[0].order_product_attribute.return_date) {
                return row.travels[0].order_product_attribute.return_date;
            }
            return null;
        }
    }));

    columns.push(CreateColumnDate({
        field: 'order_attribute.quote_validated_date',
        headerName: "Signé le",
        type: "dateTime",
        getValue: (row) => {
            return row.order_attribute.quote_validated_date;
        }
    }));

    columns.push({
        field: 'actions_bis',
        headerName: "Actions",
        editable: false,
        filterable: false,
        width: 120,
        renderCell: ({ row }) => (
            <Box>
                <IconButton onClick={() => navigate(`/folders/add-edit/${row.order_attribute.folder_id}`)}>
                    <RemoveRedEyeIcon />
                </IconButton>
            </Box>
        )
    });


    /* {
         field: 'customer_id',
         headerName: "Salaire Diff",
         editable: false,
         filterable: false,
         width: 120,
         renderCell: ({ row }) => (<>
             0.00€
         </>)
     },
     {
         field: 'type_journey',
         headerName: "Fournisseur --",
         editable: false,
         filterable: false,
         width: 120,
         renderCell: ({ row }) => (<>
             Azur Travel
         </>)
     },
    

     {
         field: 'return_place',
         headerName: "A encaisser",
         editable: false,
         filterable: false,
         width: 120,

         renderCell: ({ row }) => (<>
             0.00€
         </>)
     }*/

    return {
        list: columns
    };
};


export interface OrderListModal extends ListModelCore {
    quotationsStats: any[]
}

const DashboardOrders = () => {

    const [forceRefresh, setForceRefresh] = useState(0);
    const routeApiController = "project-dahan/dahan/quotations-stats";

    const dashboardListcolumns = useListcolumns();
    const [get] = useGetListCore<OrderListModal>(`/${routeApiController}/index`);

    const [quotationsStats, setQuotationsStats] = useState<any[]>([]);
    return (
        <Box height={"calc(100% - 120px)"}>

            <DashboardRecap quotationsStats={quotationsStats} />

            <ListCore<OrderListModal, any>
                key={forceRefresh}
                getDatas={get}
                defineDatasToSet={(data) => {
                    setQuotationsStats(data.quotationsStats ?? []);
                    return data.quotationsStats;
                }}
                getRowId={(row) => row.id}
                columns={dashboardListcolumns.list}
                rowHeight={70}
                paginationOptionDefault={100}
                paginationsOptions={[100]}
                filterDateRange={{
                    period: "month",
                    fieldName: "quote_margin_date"
                }}
            />
        </Box>
    );
}

export default DashboardOrders;