import { Container, Modal } from "@mui/material";
import { FormProvider } from "react-recoil-form";
import { useCallback } from "react";
import { useRecoilState } from "recoil";
import FolderNoteForm from "./FolderOrderSupplierForm";
import { FolderOrderSupplierCommentModalState } from "../../states/FolderOrderSuppliersState";

const FolderOrderSupplierModal = () => {

    const [modalState, setModalState] = useRecoilState(FolderOrderSupplierCommentModalState);

    const handleClose = useCallback(() => {
        setModalState((a) => {
            return { ...a, open: false };
        });
    }, [setModalState]);

    return (
        <Modal
            open={modalState.open}
            aria-labelledby="folder-order-supplier-comment-modal"
            sx={{
                overflow: 'auto'
            }}
        >
            <Container component="main" maxWidth="md" sx={{ height: "100%", py: 2 }}>
                <FormProvider options={{ formId: "FolderOrderSupplierForm", skipRecoilRoot: true }}>
                    <FolderNoteForm
                        id={modalState.id}
                        onClose={handleClose}
                    />
                </FormProvider>
            </Container>
        </Modal>
    );
}

export default FolderOrderSupplierModal;