import { Box, FormControl, FormHelperText, Typography} from "@mui/material";
import { IAncestorInput, useField } from "react-recoil-form";
import { useEffect } from "react";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";

interface IFieldProps {
  name: string;
  ancestors?: IAncestorInput[];
  label?: string;
  required?: boolean;
  disabled?: boolean;
}

export default function ColorPickerField(props: IFieldProps) {
  const { name, ancestors, label, required, disabled } = props;
  const { fieldValue, setFieldValue, error } = useField({
    ancestors,
    name,
    validate: (value) => {
      if (required && !value) {
        return `Le champ ${label} est requis`;
      }
    }
  });

  const [color, setColor] = useColor(fieldValue ?? "");
  useEffect(() => {
    setFieldValue(color.hex);
  }, [color]);

  return (
    <FormControl error={!!error} fullWidth >
      <Box mb={1}><Typography>{label}</Typography></Box>
      <ColorPicker color={color} onChange={setColor} />
      {error && <FormHelperText id="my-helper-text">{error}</FormHelperText>}
    </FormControl>
  );
}

/*
    <FormControl error={!!error} fullWidth sx={type === "hidden" ? { display: "none" } : undefined}>
      <TextField
        inputRef={inputRef}
        id={name}
        type={type}

        disabled={disabled ?? false}
        label={label}
        required={required}
        variant={"outlined"}
        size={size}
        InputProps={{
          inputProps: {
            style: { 
              textAlign: textAlign ?? "left"
            },
          },
          endAdornment: (endAdornment ? 
            <InputAdornment position="end" sx={endAdornmentSx} >
              {endAdornment}
            </InputAdornment>
            : <></>
          ),
        }}

        {...(!onBlurActive && {
          value: fieldValue || "",
          onChange: onChangeEvent,
        })}

        {...(onBlurActive && {
          defaultValue: fieldValue || "",
          onBlur: (e) => setFieldValue(e.target.value ? e.target.value : setDefaultValue()),
        })}

      />
      {error && <FormHelperText id="my-helper-text">{error}</FormHelperText>}
    </FormControl>
      */