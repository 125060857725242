import { Box, Button, TextField } from "@mui/material";
import AddEditFormCard from "../../../components/common/form/AddEditFormCard";
import TextAreaField from "../../../components/common/form/TextAreaField";
import AddEditFormSubmit from "../../../components/common/form/AddEditFormSubmit";
import { useCommentFolder } from "../../stores/FoldersStore";
import { useCallback, useEffect, useState } from "react";
import { useAddEditForm } from "../../../components/common/form/AddEditFormPanel";
import { useParams } from "react-router-dom";
import { ReqStatus } from "../../../stores/core/UseApi";
import { useAddEditCore } from "../../../stores/core/StoreCore";
import { FolderModel } from "../../classes/FolderModel";
import { FormattedMessage } from "react-intl";

interface FolderResponse {
    folder: FolderModel,
}

const QuoteTravelGlobalNoteCommercialForm = () => {
    const routeApiController = "project-dahan/dahan/folders";
    const { getData, addEditData } = useAddEditCore<any, FolderResponse>(`/${routeApiController}/add-edit`);
    let { folderId } = useParams();

    const [comment, setComment] = useState<string>("");

    const onSubmit = useCallback(() => {
        addEditData(
            { comment_commercial: comment }, 
            folderId
        ).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
            }
        })
    }, [addEditData, folderId, comment]);

    useEffect(() => {
        getData(folderId).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                setComment(res.data.folder.comment_commercial || '');
            }
        })
    }, [folderId])

    return (
        <AddEditFormCard title="Dossier - À retenir commercial" withExpand isExpand={false} sx={{ paper: { my: 2} }}>
            <Box mt={1}>

                <TextField
                    value={comment || ""}
                    onChange={(e) => {
                        setComment(e.target.value ? e.target.value : '');
                    }}
                    label="À retenir commercial"
                    variant={"outlined"}
                    multiline
                    fullWidth
                    maxRows={8}
                />
            </Box>
            <Box display="flex" justifyContent={"flex-end"} mt={1}>
                <Button color="info" variant="contained" onClick={onSubmit}>
                    <FormattedMessage id={"Common_Validate"} />
                </Button>
            </Box>
        </AddEditFormCard>
    )
}

export default QuoteTravelGlobalNoteCommercialForm;
