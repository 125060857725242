import { useCallback } from "react";
import { GenericResponse, ReqStatus, useApi } from "./core/UseApi";
import { SettingEditModel, SettingModel } from "../classes/settings/SettingModel";

interface SettingsResponse{
    settings: SettingModel[]
}
export const useGetSettings = function (): [() => Promise<GenericResponse<SettingsResponse>>, ReqStatus] {
    const [callCore, status] = useApi<SettingsResponse, undefined>(
        {
            route: 'settings/index',
            instanceAxiosMethod: "GET"
        }
    );
    const call = useCallback(() => {
        return callCore();
    }, [callCore]);
    return [call, status];
};

export const useEditSettings = function (): [(settings: SettingEditModel[]) => Promise<GenericResponse<SettingsResponse>>, ReqStatus] {
    const [callCore, status] = useApi<SettingsResponse, SettingEditModel[]>(
        {
            route: 'settings/index',
            instanceAxiosMethod: "POST",
            contentType: "form"
        }
    );
    const call = useCallback((settings: SettingEditModel[]) => {
        return callCore(settings);
    }, [callCore]);
    return [call, status];
};

interface GetCompanyInfosResponse {
    logo: SettingModel,
    companyName: SettingModel
}
export const useGetCompanyInfo = function (): [() => Promise<GenericResponse<GetCompanyInfosResponse>>, ReqStatus] {
    const [callCore, status] = useApi<GetCompanyInfosResponse, undefined>(
        {
            route: 'settings/get-company-infos',
            instanceAxiosMethod: "GET",
        }
    );
    const call = useCallback(() => {
        return callCore();
    }, [callCore]);
    return [call, status];
};
