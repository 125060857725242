import { useNavigate, useParams } from "react-router-dom";
import { useAddEditCore } from "../../../stores/core/StoreCore";
import { ReqStatus } from "../../../stores/core/UseApi";
import { useAddEditForm } from "../../../components/common/form/AddEditFormPanel";
import { useEffect, useMemo } from "react";
import AddEditForm from "../../../components/common/form/AddEditForm";
import AddEditFormCard from "../../../components/common/form/AddEditFormCard";
import { Box, Grid, Typography } from "@mui/material";
import InputField from "../../../components/common/form/InputField";
import { useField } from "react-recoil-form";
import { TagItem } from "../folders/FolderTagItem";
import CheckboxField from "../../../components/common/form/CheckboxField";
import { ColorPicker, useColor } from "react-color-palette";
import ColorPickerField from "../../../components/common/form/ColorPickerField";
import TagForm from "./TagForm";

const TagView = () => {
    const listRoute = "/project-dahan/dahan/tags";
    const listRouteCustom = '/tags';
    const { getData, addEditData } = useAddEditCore<any, { tag: any }>(`${listRoute}/add-edit`);

    let { id } = useParams();
    const navigate = useNavigate();

    const onSubmit = (request: any) => {
        addEditData(request, id).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                navigate(listRouteCustom);
            }
        })
    }

    const { handleSubmit, resetInitialValues, isDirty } = useAddEditForm({ onSubmit, initialValues: { name: "" } });
    useEffect(() => {
        if (id) {
            getData(id).then((res) => {
                if (res.status === ReqStatus.SUCCESS && res.data) {

                    const _data = res.data.tag;
                    resetInitialValues({
                        name: _data.name,
                        color: _data.color,
                        backcolor: _data.backcolor,
                        systeme: _data.systeme,
                        commercial: _data.commercial,
                    });

                } else {
                    navigate(listRouteCustom);
                }
            })
        }
    }, [id]);

    const nameField = useField({ name: 'name' });
    const colorField = useField({ name: 'color' });
    const backcolorField = useField({ name: 'backcolor' });


    const tag = useMemo(() => {
        return {
            name: nameField.fieldValue,
            color: colorField.fieldValue,
            backcolor: backcolorField.fieldValue
        };
    }, [nameField.fieldValue, colorField.fieldValue, backcolorField.fieldValue])


    return (

        <AddEditForm
            formId="tagForm"
            formHandleCancel={resetInitialValues}
            formHandleSubmit={handleSubmit}
            isDirty={isDirty}
        >
            <AddEditFormCard maxWidth="md" title={(!id ? "Ajouter " : "Modifier") + " un tag de dossier"}>
                <TagForm
                    systeme
                    commercial
                />
            </AddEditFormCard>
        </AddEditForm>
    )
}

export default TagView;