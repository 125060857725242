import { Box, Button, Container, Divider, Grid, Paper, Typography } from "@mui/material";
import InputField from "../common/form/InputField";
import { FormattedMessage, useIntl } from "react-intl";
import { useEditProfile, useGetProfile } from "../../stores/UsersStore";
import { useEffect } from "react";
import { ReqStatus } from "../../stores/core/UseApi";
import { useForm, useIsDirty } from "react-recoil-form";
import { ProfileModel } from "../../classes/users/ProfileModel";
import PasswordField from "../common/form/PasswordField";
import AddEditForm from "../common/form/AddEditForm";
import AddEditFormCard from "../common/form/AddEditFormCard";

const ProfileView = () => {

    const intl = useIntl();

    const [get] = useGetProfile();
    const [edit] = useEditProfile();


    const onSubmit = (request: ProfileModel) => {
        edit(request).then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                resetInitialValues(res.data.user);
            }
        });
    }

    const isDirty = useIsDirty();
    const { handleSubmit, resetInitialValues } = useForm({
        onSubmit,
    });

    useEffect(() => {
        get().then((res) => {
            if (res.status === ReqStatus.SUCCESS && res.data) {
                resetInitialValues(res.data.user);
            }
        })
    }, [])

    return (
        <>
            <AddEditForm formId="profileForm" formHandleCancel={resetInitialValues} formHandleSubmit={handleSubmit} isDirty={isDirty}>
                <AddEditFormCard title="Mon profil">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <InputField
                                name="last_name"
                                label={intl.formatMessage({ id: "Common_Name" })}
                                type="text"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputField
                                name="first_name"
                                label={intl.formatMessage({ id: "Common_FirstName" })}
                                type="text"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputField
                                name="email"
                                label={intl.formatMessage({ id: "Common_Email" })}
                                type="text"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputField
                                name="phone"
                                label={intl.formatMessage({ id: "Common_Tel" })}
                                type="text"
                                isPhone
                            />
                        </Grid>
                    </Grid>
                </AddEditFormCard>
                <AddEditFormCard title="Modifier mon mot de passe">
                    <Grid container spacing={3} >
                        <Grid item xs={12} sm={6}>
                            <PasswordField
                                name="previous_password"
                                label="Ancien mot de passe"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}></Grid>
                        <Grid item xs={12} sm={6}>
                            <PasswordField
                                name="new_password"
                                label="Nouveau mot de passe"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <PasswordField
                                name="new_password_confirm"
                                label="Confirmer nouveau mot de passe"
                            />
                        </Grid>

                    </Grid>
                </AddEditFormCard>
            </AddEditForm>
        </>
    )
}

export default ProfileView;