import { Box, Button, Grid } from "@mui/material";
import { isValidEmail } from "../../../utils/FormUtils";
import AutocompleteMultipleField from "../common/AutocompleteMultipleField";
import { useField } from "react-recoil-form";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { ConfirmModalState } from "../../../states/ModalState";
import { useCallback, useMemo } from "react";
import { BrevoMailConfigEntityEnum, BrevoMailConfigRecipientType } from "../../classes/BrevoMailConfig";
import { FolderOrderSupplierListState,  FolderOrderSupplierValidatedState } from "../../states/FolderOrderSuppliersState";
import SelectField from "../../../components/common/form/SelectField";
import { EnumFolderOrderSupplierStatus, FolderOrderSupplierModel } from "../../classes/FolderOrderSupplierModel";

interface SendMailFormRecipientProps {
	templateSelected: any,
	customer: any
}

const SendMailFormRecipient = (props: SendMailFormRecipientProps) => {

	const { templateSelected, customer } = props;

	const { fieldValue: emailsForm, setFieldValue: setEmailsForm } = useField({ name: "emails" })
	const { fieldValue: entityId } = useField({ name: 'entity_id' });
	const { fieldValue: folderOrderSupplierIdForEmails } = useField({ name: 'folder_order_supplier_id_for_email' });

	const folderOrderSuppliers = useRecoilValue(FolderOrderSupplierListState);
	const folderOrderSuppliersValidated = useRecoilValue(FolderOrderSupplierValidatedState);

	const setConfirmModalState = useSetRecoilState(ConfirmModalState);
	const addEmailsTitle = useMemo(() => {
		if (!templateSelected) { return ""; }

		switch (templateSelected.recipient_type) {
			case BrevoMailConfigRecipientType.CUSTOMER:
				return "Ajouter les emails du client";
			case BrevoMailConfigRecipientType.SUPPLIER:
				if (templateSelected.entity === BrevoMailConfigEntityEnum.ENTITY_QUOTE ||
					templateSelected.entity === BrevoMailConfigEntityEnum.ENTITY_FOLDER_ORDER_SUPPLIER
				) {
					return "Ajouter les emails du fournisseur";
				} else {
					return "";
				}
			case BrevoMailConfigRecipientType.CUSTOM:
				return "Ajouter les emails custom";
			default:
				return "";
		}

	}, [templateSelected]);

	const folderOrderSuppliersValidatedByOrderId = useMemo(() => {
		if (
			!templateSelected ||
			!entityId ||
			templateSelected.recipient_type !== BrevoMailConfigRecipientType.SUPPLIER ||
			templateSelected.entity !== BrevoMailConfigEntityEnum.ENTITY_QUOTE
		) {
			return [];
		}
		return folderOrderSuppliersValidated.filter((x) => x.order_id === entityId).map((item) => {
			return {
				value: item.id,
				label: `#${item.id} - ${item.supplier.name} (${item.status === EnumFolderOrderSupplierStatus.CONFIRMED ? "BPA" : "Annulé"}) - Devis ${item.order.id} validé`
			}
		});
	}, [folderOrderSuppliersValidated, templateSelected, entityId])

	const addEmailsDisabled = useMemo(() => {

		if (!templateSelected) {
			return true;
		}

		if (templateSelected.entity === BrevoMailConfigEntityEnum.ENTITY_FOLDER_ORDER_SUPPLIER && !entityId) {
			return true;
		}

		if (templateSelected.entity === BrevoMailConfigEntityEnum.ENTITY_QUOTE &&
			templateSelected.recipient_type === BrevoMailConfigRecipientType.SUPPLIER &&
			!folderOrderSupplierIdForEmails
		) {
			return true;
		}

		return false;
	}, [templateSelected, entityId, folderOrderSupplierIdForEmails])

	const addEmailsEvent = useCallback(() => {
		if (!templateSelected) { return; }
		let _customerEmails = "";
		switch (templateSelected.recipient_type) {
			case BrevoMailConfigRecipientType.CUSTOMER:
				_customerEmails = customer.user.email;
				if (customer.dahan_emails_compl) {
					_customerEmails += `;${customer.dahan_emails_compl}`;
				}
				break;
			case BrevoMailConfigRecipientType.SUPPLIER:

				let folderOrderSupplier: FolderOrderSupplierModel | undefined;
				if (templateSelected.entity === BrevoMailConfigEntityEnum.ENTITY_FOLDER_ORDER_SUPPLIER) {
					if (!entityId) {
						break;
					}
					folderOrderSupplier = folderOrderSuppliers.find((x) => x.id === entityId);
				} else {

					if (!folderOrderSupplierIdForEmails) {
						break;
					}
					folderOrderSupplier = folderOrderSuppliers.find((x) => x.id === folderOrderSupplierIdForEmails);
				}

				if (!folderOrderSupplier) {
					break;
				}

				_customerEmails = folderOrderSupplier.supplier.email ?? "";
				if (folderOrderSupplier.supplier.dahan_emails_compl) {
					if (_customerEmails) {
						_customerEmails += ';';
					}
					_customerEmails += folderOrderSupplier.supplier.dahan_emails_compl;
				}

				break;
			case BrevoMailConfigRecipientType.CUSTOM:
				_customerEmails = templateSelected.recipient_custom_emails;
				break;
		}

		if (!emailsForm) {
			setEmailsForm(_customerEmails);
			return;
		}
		setConfirmModalState({
			open: true,
			title: addEmailsTitle,
			desc: "Le champ Destinaire(s) est déjà renseigné, cela va effacer les emails en cours.",
			validEvent: () => {
				setEmailsForm(_customerEmails);
			}
		})
	}, [emailsForm, setEmailsForm, templateSelected, addEmailsTitle, customer, entityId, folderOrderSupplierIdForEmails]);

	return (
		<>
			<Box mt={2}>
				<AutocompleteMultipleField
					name="emails"
					options={[]}
					label="Destinataire(s)"
					validateOption={isValidEmail}
					required
				/>
			</Box>

			{addEmailsTitle &&
				<Grid container display={"flex"} alignItems={"center"}>
					{folderOrderSuppliersValidatedByOrderId.length > 0 &&
						<Grid xs={6} mt={2} pr={2}>
							<SelectField
								label="Choix du fournisseur pour l'ajout des emails"
								name="folder_order_supplier_id_for_email"
								options={folderOrderSuppliersValidatedByOrderId}
								size="small"
							/>
						</Grid>
					}
					<Grid xs={6} mt={2}>
						<Button
							variant="contained"
							color="secondary"
							onClick={() => addEmailsEvent()}
							disabled={addEmailsDisabled}
						>{addEmailsTitle}</Button>
					</Grid>
				</Grid>
			}
		</>
	)
}

export default SendMailFormRecipient;