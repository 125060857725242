import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { useField, useFieldArray } from "react-recoil-form";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMemo } from "react";
import SelectField, { SelectOptionsProps } from "../../../components/common/form/SelectField";
import InputField from "../../../components/common/form/InputField";
import NumberField from "../../../components/common/form/NumberField";
import TextAreaField from "../../../components/common/form/TextAreaField";
import DateField from "../../../components/common/form/DateField";
import JsonView from "../../../components/common/debug/JsonView";

interface QuotePaymentItemProps {
    prefixeName: string,
    disabled?: boolean,
    id: number,
    paymentMethodOptions: SelectOptionsProps[],
    methodPaymentStatus: { id: string, options: SelectOptionsProps[] }[],
    deleteEvent: () => void,
    depositOptions: SelectOptionsProps[]
}
const QuotePaymentItem = (props: QuotePaymentItemProps) => {
    const { prefixeName, disabled, id, paymentMethodOptions, methodPaymentStatus, deleteEvent, depositOptions } = props;

    const paymentMethodIdField = useField({ ancestors: [{ name: prefixeName, rowId: id }], name: "payment_method_id" });
    const statusIdField = useField({ ancestors: [{ name: prefixeName, rowId: id }], name: "status_id" });

    const statusOptions = useMemo(() => {
        if (!paymentMethodIdField.fieldValue) {
            statusIdField.setFieldValue(undefined);
            return [];
        }
        const found = methodPaymentStatus.find(x => x.id == paymentMethodIdField.fieldValue);

        if (found) {
            const optionFound = found.options.find(o => o.value == statusIdField.fieldValue);
            if (!optionFound) { statusIdField.setFieldValue(undefined); }
            return found.options;
        } else {
            return [];
        }
    }, [paymentMethodIdField.fieldValue, methodPaymentStatus, statusIdField.fieldValue]);

    return (
        <>
            <Grid key={`${prefixeName}_payment_method_id_${id}`} item xs={1}>
                <SelectField
                    ancestors={[{ name: prefixeName, rowId: id }]}
                    options={paymentMethodOptions ?? []}
                    name={"payment_method_id"}
                    required
                    label={"Moyen de paiement"}
                    disabled={disabled}
                />
            </Grid>
            <Grid key={`${prefixeName}_line_status_id_${id}`} item xs={1}>
                <SelectField
                    ancestors={[{ name: prefixeName, rowId: id }]}
                    options={statusOptions}
                    name={"status_id"}
                    required
                    label={"Statut de paiement"}
                    disabled={disabled}
                />
            </Grid>
            <Grid key={`${prefixeName}_line_reference_${id}`} item xs={2}>
                <InputField
                    ancestors={[{ name: prefixeName, rowId: id }]}
                    name={"reference"}
                    label={"Référence du paiement"}
                    type="text"
                    disabled={disabled}
                    required={true}
                />
            </Grid>
            <Grid key={`${prefixeName}_line_total_amount_${id}`} item xs={1}>
                <NumberField
                    ancestors={[{ name: prefixeName, rowId: id }]}
                    name={"total_amount"}
                    label={"Montant total"}
                    required
                    disabled={disabled}
                />
            </Grid>
            <Grid key={`${prefixeName}_line_comment_${id}`} item xs={2}>
                <TextAreaField
                    ancestors={[{ name: prefixeName, rowId: id }]}
                    name={"comment"}
                    label={"Commentaire interne"}
                    disabled={disabled}
                />
            </Grid>
            <Grid key={`${prefixeName}_line_payment_date_${id}`} item xs={2}>
                <DateField
                    ancestors={[{ name: prefixeName, rowId: id }]}
                    name={"dahan_payment_date"}
                    label={"Date"}
                    required
                    type="datetime"
                    disabled={disabled}
                />
            </Grid>
            <Grid key={`${prefixeName}_line_deposit_id_${id}`} item xs={2}>
                <SelectField
                    ancestors={[{ name: prefixeName, rowId: id }]}
                    options={depositOptions}
                    name={"dahan_deposit_id"}
                    label={"Acompte lié"}
                    disabled={disabled}
                    noChoice={true}
                />
            </Grid>
            {!disabled ?
                <Grid item xs={1}>
                    <IconButton color="error" onClick={() => deleteEvent()}><DeleteIcon /></IconButton>
                </Grid>
                : <Grid item xs={1}></Grid>}
        </>
    )
}

interface QuotePaymentsViewProps {
    deposits: any[],
    paymentMethods: SelectOptionsProps[],
    methodPaymentStatus: { id: string, options: SelectOptionsProps[] }[],
    disabled?: boolean
}

const fieldNames = ["id", "order_id", "payment_method_id", "status_id", "reference", "total_amount", "comment", "dahan_payment_date", "dahan_deposit_id"];

const QuotePaymentsView = (props: QuotePaymentsViewProps) => {
    const entityListField = useFieldArray({ name: "payments", fieldNames: fieldNames, });
    const newEntityListField = useFieldArray({ name: "newPayments", fieldNames: [...fieldNames, 'status_history'] });

    const { disabled, paymentMethods, methodPaymentStatus, deposits } = props;

    const depositOptions: SelectOptionsProps[] = useMemo(() => {
        return deposits.map((d: any) => {
            const label = `${d.is_credit_note ? "Avoir d'acompte n° " : "Facture d'acompte n° "} ${d.id}`;
            return {
                value: d.id,
                label: label
            }
        })
    }, [deposits])

    return (
        <Box display={"flex"} flexDirection={"column"} >
            <Grid container spacing={2} display={"flex"} alignItems={"flex-start"}>
                <>
                    {entityListField.fieldArrayProps.rowIds.map((rowId, rowIndex) => (
                        <QuotePaymentItem
                            key={rowId}
                            id={rowId}
                            prefixeName={"payments"}
                            deleteEvent={() => entityListField.remove(rowIndex)}
                            disabled={disabled}
                            paymentMethodOptions={paymentMethods}
                            methodPaymentStatus={methodPaymentStatus}
                            depositOptions={depositOptions}
                        />
                    ))}
                    {!disabled && newEntityListField.fieldArrayProps.rowIds.map((rowId, rowIndex) => (
                        <QuotePaymentItem
                            key={rowId}
                            id={rowId}
                            prefixeName={"newPayments"}
                            deleteEvent={() => newEntityListField.remove(rowIndex)}
                            disabled={disabled}
                            paymentMethodOptions={paymentMethods}
                            methodPaymentStatus={methodPaymentStatus}
                            depositOptions={depositOptions}
                        />
                    ))}
                </>
            </Grid>
            {!disabled &&
                <Box mt={2}>
                    <Button variant="contained" color="primary" onClick={() => newEntityListField.append({ status_history: '', })}>{"Ajouter un paiement"}</Button>
                </Box>
            }
        </Box>

    );
}

export default QuotePaymentsView;