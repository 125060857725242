import { AutocompleteOption } from "../components/common/form/AutocompleteField";
import { SelectOptionsProps } from "../components/common/form/SelectField";

export const createOptionsForSelect = (datas: { [id: string]: string }) => {

    let result: SelectOptionsProps[] = [];
    if (!datas) { return result };

    const _options = Object.entries(datas).map(([key, value]) => {
        return { label: value, value: key ?? "" } as SelectOptionsProps;
    });

    return _options;
}

export const createOptionsForAutocomplete = (datas: { [id: string]: string }, type? : "number" | "string") => {

    let result: AutocompleteOption[] = [];
    if (!datas) { return result };

    Object.entries(datas).forEach(([id, label]) => {
        result.push({ id: type === "string" ? id : +id, label: label });
    });

    return result;
}

export const mapStringValueForForm = (value: string | null | undefined, defaultValue: string | null | undefined) => {
    if(value === null || value === undefined || value.trim() === ""){
        return defaultValue ?? "";
    }
    return value;
}

export const mapArrayForForm = <T>(array: T[] | undefined, propertyName: string, mappingFunction: (data: T) => any, sortProp?: string) => {
    if (Array.isArray(array) && array.length > 0) {

        if(sortProp){
            array = array.sort((a:any, b:any) => a[sortProp] - b[sortProp]);
        }

        const mappedArray = array.map(mappingFunction);
        return { [propertyName]: mappedArray };
    }
    return {};
}

export const parseDataPropForForm = (dataInitial: any, dataDefault: any) => {
    let dataParsed: any = undefined;
    if (Array.isArray(dataInitial)) {
        const dataPropArray = dataInitial;
        if (dataPropArray.length > 0) {
            dataParsed = ([] as any[]);
            dataPropArray.forEach((i: any) => {
                dataParsed.push(parseDataForForm(i, (dataDefault === true) ? true : dataDefault));
            })
        } else {
            if (dataDefault !== true) {
                dataParsed = dataDefault;
            }
        }
    }
    else if (dataInitial === null || dataInitial === undefined) {
        dataParsed = dataInitial;
    }
    else if (typeof dataInitial === "object") {
        const dataParsedObj = dataInitial as any;
        dataParsed = parseDataForForm(dataParsedObj, (dataDefault === true) ? true : dataDefault);
    } else {
        dataParsed = dataInitial;
    }
    return dataParsed;
}

export const parseDataForForm = (
    data: any,
    dataDefault: any | boolean
) => {
    let dataParsed = {} as any;

    if (dataDefault === true) {
        for (const prop in data) {
            if (data.hasOwnProperty(prop)) {
                dataParsed[prop] = parseDataPropForForm(data[prop], true);
            }
        }
    } else {
        for (const prop in dataDefault) {
            if (data.hasOwnProperty(prop) && (dataDefault.hasOwnProperty(prop))) {
                dataParsed[prop] = parseDataPropForForm(data[prop], dataDefault[prop])
            } else if (dataDefault.hasOwnProperty(prop)) {

                dataParsed[prop] = dataDefault[prop];
            }
        }
    }

    return dataParsed;
}