import { FormControl, MenuItem, FormHelperText, TextField, Select, InputLabel } from "@mui/material";
import React, { useCallback, useMemo, useEffect } from "react";
import { useIntl } from "react-intl";
import { IAncestorInput, useField } from "react-recoil-form";

export interface SelectOptionsProps {
    value: string | number;
    label: string;
    formatMessage?: boolean;
    isImage?: boolean;
}

interface IFieldProps {
    name: string;
    ancestors?: IAncestorInput[];
    label?: string;
    required?: boolean;
    disabled?: boolean;
    multiple?: boolean;
    options: SelectOptionsProps[];
    handleChange?: (value?: any) => void;
    optionType?: "string" | "number",
    noChoice?: boolean,
    noChoiceLabel?: string,
    size?: "small" | "medium"
    className?: string;
    defaultIndex?: number;
}

const SelectField = (props: IFieldProps) => {
    const { name, ancestors, label, required, disabled, className, options, handleChange, optionType, multiple, noChoice, noChoiceLabel, size, defaultIndex } = props;
    const intl = useIntl();
    const { fieldValue, setFieldValue, error } = useField({
        ancestors,
        name,
        validate: (value) => {
            if (value === 0) { return; }
            if (required && !value) {
                return `Le champ ${label} est requis`;
            }
        }
    });

    const getLabelOfOption = useCallback((label: string, formatMessage?: boolean) => {
        return (formatMessage === true) ? intl.formatMessage({ id: label }) : label;
    }, [intl]);

    const _value = useMemo(() => {
        if (!multiple) {
            return fieldValue === 0 ? fieldValue : (fieldValue ? fieldValue : "");
        } else {
            return fieldValue ?? [];
        }
    }, [multiple, fieldValue]);

    useEffect(() => {
        if (defaultIndex !== undefined && options[defaultIndex]) {
            setFieldValue(options[defaultIndex].value);
        }
    }, [defaultIndex, options, setFieldValue]);

    const menuItemStyle = {
        display: 'grid',
        placeContent: 'center',
    };

    return (
        <FormControl error={!!error} size="medium" variant="outlined" fullWidth >
            <InputLabel size={size == "medium" ? "normal" : size} >{label} {required && "*"}</InputLabel>
            <Select
                size={size}
                disabled={disabled}
                label={label}
                value={_value}
                onChange={(e) => {
                    handleChange?.();
                    if (!multiple) {
                        var v = (optionType === "number" && !isNaN(e.target.value) && e.target.value !== null) ? +e.target.value : e.target.value;
                        setFieldValue(v);
                    } else {
                        setFieldValue(e.target.value);
                    }
                }}
                required={required}
                multiple={multiple}
                className={className}
            >
                {noChoice &&
                    <MenuItem>{noChoiceLabel ? noChoiceLabel : "Aucun"}</MenuItem>
                }
                {options.map((option, i) => (
                    <MenuItem
                        key={i}
                        value={option.value}
                        style={option.isImage ? menuItemStyle : {}}
                    >
                        {option.isImage ? (
                            <img
                                src={`${process.env.REACT_APP_API}${option.label.replace(/\\/g, '/')}`}
                                alt="option"
                                style={{ height: '1.25rem', marginRight: '0.5rem' }}
                            />
                        ) : (
                            getLabelOfOption(option.label, option.formatMessage)
                        )}
                    </MenuItem>
                ))}
            </Select>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    );
};

export default SelectField;